export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/

    GET_ORG_LIST_REQUEST: 'GET_ORG_LIST_REQUEST',
    GET_ORG_LIST_SUCCESS: 'GET_ORG_LIST_SUCCESS',
    GET_ORG_LIST_FAILURE: 'GET_ORG_LIST_FAILURE',
};
