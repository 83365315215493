import styled from "styled-components";
import React, { useState, useEffect } from "react";

const SolInputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #BCBCCD;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  background-color: #FFFFFF;
  padding: 2px 12px;
  min-height: ${(props) => props.height || "36px"};  // Default to 36px if no height prop is provided

  &:focus-within {
    border-color: #F18D00;
    box-shadow: 0px 0px 0px 3.2px rgba(241, 141, 0, 0.1);
    outline: none;
  }
`;

const Tag = styled.span`
  background-color: #F18D00;
  color: #FFFFFF;
  padding: 1px 8px;
  border-radius: 4px;
  margin: 2px 0px 0px 4px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;

  &:first-child {
    margin-left: 0;
  }
`;

const RemoveButton = styled.button`
  background: transparent;
  border: none;
  color: #FFFFFF;
  margin-left: 4px;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0 0 0 1px;
  line-height: 1;

  &:hover {
    opacity: 0.8;
  }
`;

const SolInputTag = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
  background: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  &:hover,
  &:focus {
    cursor: text;
  }
`;

/**
 * SolTagInput - A React component that transforms a text input into a space-separated tag input field.
 * Each tag is displayed with a styled UI, allowing for addition and removal of tags.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} [props.placeholder=""] - Placeholder text for the input field.
 * @param {boolean} [props.disabled=false] - Disables the input field when set to `true`.
 * @param {function} props.onChange - Callback function called when tags are added or removed.
 *                                     Receives a comma-separated string of tags as an argument.
 * @param {string} [props.value=""] - Initial comma-separated string of tags to display on mount.
 * @param {string} [props.height="36px"] - Height of the input container; defaults to 36px.
 *
 * @example
 * <SolTagInput
 *     placeholder="Enter app versions, separated by spaces. Ex: 1.1 1.2"
 *     disabled={false}
 *     onChange={(value) => console.log(value)} // Logs comma-separated tags
 *     value="1.0, 2.0" // Default tags
 *     height="50px"    // Custom height
 * />
 **/
export const SolTagInput = ({ placeholder, disabled, onChange, value, height }) => {
    const [tags, setTags] = useState([]);         // All displayed tags (including duplicates)
    const [inputValue, setInputValue] = useState("");

    /**
     * Initializes the `tags` state with a comma-separated list of tags
     * provided through the `value` prop when the component mounts or when `value` changes.
     *
     * @param {string} value - Comma-separated string of tags to initialize the tags array.
     */
    useEffect(() => {
        if (value) {
            const initialTags = value.split(",").map(tag => tag.trim());
            setTags(initialTags);
        }
    }, [value]);

    /**
     * Handles keyboard input and adds a tag when a space is pressed, if the input is non-empty.
     * @param {React.KeyboardEvent<HTMLInputElement>} e - Keyboard event from the input field.
     */
    const handleKeyDown = (e) => {
        if (e.key === " " && inputValue.trim() !== "") {
            const trimmedValue = inputValue.trim();
            // Check if the tag already exists
            if (!tags.includes(trimmedValue)) {
                const newTags = [...tags, trimmedValue];
                setTags(newTags);
                setInputValue("");
                onChange(newTags.join(","));  // Update the form state with a formatted string
            }
        }
    };

    /**
     * Handles input field changes and updates the `inputValue` state.
     * @param {React.ChangeEvent<HTMLInputElement>} e - Change event from the input field.
     */
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    /**
     * Removes a specified tag from the tags array and updates the form state.
     * @param {string} tagToRemove - The tag to be removed from the tags list.
     */
    const removeTag = (tagToRemove) => {
        const newTags = tags.filter((tag) => tag !== tagToRemove);
        setTags(newTags);
        onChange(newTags.join(", "));  // Update the form state with a formatted string

        // If all tags are removed, clear inputValue to show the placeholder
        if (newTags.length === 0) {
            setInputValue("");
        }
    };

    return (
        <SolInputContainer height={height}>
            {tags.map((tag, index) => (
                <Tag key={index}>
                    {tag}
                    <RemoveButton onClick={() => removeTag(tag)}>×</RemoveButton>
                </Tag>
            ))}
            <SolInputTag
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={tags.length === 0 ? placeholder : ""}
                disabled={disabled}
            />
        </SolInputContainer>
    );
};