import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../../../smartDongles/views/components";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import DataTable from "../../../../components/dataTable/DataTable";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {Badge, Button, Spinner} from "react-bootstrap";
import {useSubheader} from "../../../../../_metronic/layout";
import {useLocation} from 'react-router';
import moment from "moment";
import {Form} from "react-bootstrap";
import Select from 'react-select';
import {Controller, useForm} from "react-hook-form";
import Box from '@mui/material/Box';
import {showNotifications} from "../../../../utils/notification";
import {otaReducer} from "../../reducer";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {DataTableContainerForDongleList} from "../../utils";
import Skeleton from "@mui/material/Skeleton";
import {makeStyles} from "@material-ui/styles";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {GenericModal} from "../../../../components";
import {DataTableContainer} from "../../../rentLog/utils";
import Typography from "@mui/material/Typography";
import {useMediaQuery} from "react-responsive";
import Stack from "@mui/material/Stack";


const FwCompatibilities = ({ fwVersion, ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [authorization, setAuthorization] = useState(null)
    const [language, setLanguage] = useState(null)
    // Note that, in other places of the project we set 'max-width' to '1224px' but here we have set it to '1023px'
    // Because, while testing at the Chrome browser for Nest Hub (1024 * 600) settings (mobile view), "Compatible dongles view" modal filter form design gets broken
    // Setting max-width to 1023 ensure that Nest Hub users or users with view port of more than 1023 width, gets represented with the desktop filter form design
    // -- Noor Reza, 15th October, 2024 11:57 am
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })
    const subHeader = useSubheader()

    // Tab styling and selection related
    const useStyles = makeStyles((theme) => ({
        tab: {
            '&:hover': {
                color: '#F18D00'
            },
            '&:focus': {
                color: '#F18D00'
            },
            '&.Mui-selected': {
                color: '#F18D00'
            },
            textTransform: 'none',
            ...theme.typography.h6
        },
        firstTab: {
            marginRight: isTabletOrMobile? theme.spacing(2):'20%', // Add margin-right for the first tab only
        },
        indicator: {
            backgroundColor: '#F18D00'
        }
    }));
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0)
    const TabPanel = ({...props}) => {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}
                 {...other}>
                {value === index && (
                    <Box p={2}>{children}</Box>
                )}
            </div>
        );
    }
    const [activeDongleListLabel, setActiveDongleListLabel] = useState('Active SOLdongles')
    const [allowedDongleListLabel, setAllowedDongleListLabel] = useState('Allowed SOLdongles')

    // Dongle list related
    const [showCurrentFwColumn, setShowCurrentFwColumn] = useState(false)
    const [activeDongles, setActiveDongles] = useState(undefined)
    const [allowedDongles, setAllowedDongles] = useState(undefined)

    // Filter related
    const [dongleForFilter, setDongleForFilter] = useState("")

    const dongleListColumns = [
        {
            field: 'serial_number',
            title: 'SOLdongle',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.serial_number}</strong></h6>
                    {rowData.hardware_version? <><span className={"text-nowrap"}>HW v{rowData.hardware_version}</span></>:''}
                </>
            }
        },
        {
            field: showCurrentFwColumn? 'firmware_version':'old_firmware_version',
            title: showCurrentFwColumn? 'Current F.W.':'Old F.W.',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'center'
            },
            headerStyle: {
                textAlign: 'center'
            },
            render: (rowData)=>{
                return   <>
                    <span className={"text-nowrap"}>{showCurrentFwColumn? rowData.firmware_version:rowData.old_firmware_version}</span>
                </>
            }
        },
        // {
        //     field: 'ota_updated_at',
        //     title: 'Updated at',
        //     emptyValue: ()=>{
        //         return "-"
        //     },
        //     cellStyle: {
        //         paddingLeft: '0',
        //         textAlign: 'left'
        //     },
        //     headerStyle: {
        //         paddingLeft: '0',
        //         textAlign: 'left'
        //     },
        //     render: (rowData)=>{
        //         return   <>
        //             <span className={"text-nowrap"}>{moment(rowData.ota_updated_at).format("MMM DD, YYYY hh:mm a")}</span>
        //         </>
        //     }
        // },
        // {
        //     field: 'updated_by',
        //     title: 'Updated by',
        //     emptyValue: ()=>{
        //         return "-"
        //     },
        //     cellStyle: {
        //         textAlign: 'right'
        //     },
        //     headerStyle: {
        //         paddingRight: '0',
        //         textAlign: 'right'
        //     },
        //     render: (rowData)=>{
        //         return   <>
        //             <span className={"text-nowrap"}>{rowData.updated_by}</span>
        //         </>
        //     }
        // }
    ]


    const {
        GET_COMPATIBILITIES_OF_A_FIRMWARE,
        RETURN_TO_INITIAL_STATE_OF_FW_COMPATIBILITIES
    } = props

    useEffect(()=>{
        setAuthorization(props.authorization)
        setLanguage(props.language)
        subHeader.setBreadCrumbComponent(null)
        GET_COMPATIBILITIES_OF_A_FIRMWARE({"firmware_version": fwVersion, "tab_identifier": "active_dongles"})
        return ()=>{
            RETURN_TO_INITIAL_STATE_OF_FW_COMPATIBILITIES()
        }
    },[])

    /**
     * Save compabilities list from API response each time a new FW compatibilities list API call has been made.
     *
     */
    useEffect(()=> {
        if (props.compatibilities) {
            // JavaScript considers 0 as false, so we don't need to do checking for 0 and 1 value
            if (tabValue) {
                setAllowedDongles(props.compatibilities)
                setAllowedDongleListLabel("Allowed Dongles (" + props.compatibilities.length + ")")
            } else {
                setActiveDongles(props.compatibilities)
                setActiveDongleListLabel("Active Dongles (" + props.compatibilities.length + ")")
            }
        } else {
            setActiveDongles(undefined)
            setActiveDongleListLabel("Active Dongles")

            setAllowedDongles(undefined)
            setAllowedDongleListLabel("Allowed Dongles")
        }
    },[props.compatibilities]) // Run each time props.compatibilities gets updated or during each FW compatibilities list collection API call

    /**
     * Show error message related to FW compabilities list API call, if any
     *
     */
    useEffect(() => {
        if (props.errorRelatedToCompatibilitiesCollection) {
            showNotifications('error', props.errorRelatedToCompatibilitiesCollection)
            // Passing empty array to show "No data available" message, when an error occurs
            tabValue? setAllowedDongles([]):setActiveDongles([])
        }
    }, [props.errorRelatedToCompatibilitiesCollection]) // Run each time props.errorRelatedToCompatibilitiesCollection gets updated, normally during each FW compabilities collection API call

    // ----- Begin: Filtering process related ---- //
    const filterForm = () => {
        return <>
            <Form onSubmit={handleSubmit(collectFilteredDongles)}>
                {isTabletOrMobile? <>
                    {/* Mobile view */}
                    <div className={"row g-3"}>
                        <div className={"col-md-4"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>SOLdongle ID</Form.Label>
                                </div>
                                <SolInput
                                    name={'serial_number'}
                                    type={'number'}
                                    min={'1'}
                                    step={'1'}
                                    placeholder={'Type SOLdongle ID'}
                                    autoComplete={"off"}
                                    ref={register()}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className={"row g-3"}>
                        <div className={"col-md-12"}>
                            <Button variant="warning" size={'sm'} type="submit"
                                    disabled={props.collectingCompatibilities}>
                                Apply filter
                            </Button>
                            <Button variant="outline-dark" size={'sm'}
                                    disabled={props.collectingCompatibilities}
                                    onClick={resetFilteredDongles} className={'ml-3'}>
                                Reset filter
                            </Button>
                        </div>
                    </div>
                </>:<>
                    {/* Desktop view */}
                    <Stack direction="row" spacing={2} alignItems="center">
                        <div>
                            <Form.Group>
                                <div>
                                    <Form.Label>SOLdongle ID</Form.Label>
                                </div>
                                <SolInput
                                    name={'serial_number'}
                                    type={'number'}
                                    min={'1'}
                                    step={'1'}
                                    placeholder={'Type SOLdongle ID'}
                                    autoComplete={"off"}
                                    ref={register()}
                                />
                            </Form.Group>
                        </div>
                        <div>
                            <Button variant="warning" size={'sm'} type="submit"
                                    disabled={props.collectingCompatibilities}>
                                Apply filter
                            </Button>
                        </div>
                        <div>
                            <Button variant="outline-dark" size={'sm'}
                                    disabled={props.collectingCompatibilities}
                                    onClick={resetFilteredDongles}>
                                Reset filter
                            </Button>
                        </div>
                    </Stack>
                </>}
            </Form>
        </>
    }

    const collectFilteredDongles = (data) => {
        if (data.serial_number) {
            let serialNumber = data.serial_number
            tabValue? GET_COMPATIBILITIES_OF_A_FIRMWARE({"serial_number": serialNumber, "firmware_version": fwVersion, "tab_identifier": "allowed_dongles"}):
                GET_COMPATIBILITIES_OF_A_FIRMWARE({"serial_number": serialNumber, "firmware_version": fwVersion, "tab_identifier": "active_dongles"})
            setDongleForFilter(serialNumber)
        }
    }

    const resetFilteredDongles = () => {
        setValue("serial_number", "")
        if (dongleForFilter) {
            tabValue? GET_COMPATIBILITIES_OF_A_FIRMWARE({"firmware_version": fwVersion, "tab_identifier": "allowed_dongles"}):
                GET_COMPATIBILITIES_OF_A_FIRMWARE({"firmware_version": fwVersion, "tab_identifier": "active_dongles"})
            setDongleForFilter("")
        }
    }

    // ----- End: Filtering process related ---- //

    // ----- Begin: Tab and SOLdongle list related ---- //
    const handleTabSelection = (event, newValue) => {
        setTabValue(newValue)
        setDongleForFilter("")
        setValue("serial_number", "")
        if (newValue === 1) {
            // Allowed SOLdongles
            setShowCurrentFwColumn(true)
            GET_COMPATIBILITIES_OF_A_FIRMWARE({"firmware_version": fwVersion, "tab_identifier": "allowed_dongles"})
        } else {
            // Active SOLdongles
            setShowCurrentFwColumn(false)
            GET_COMPATIBILITIES_OF_A_FIRMWARE({"firmware_version": fwVersion, "tab_identifier": "active_dongles"})
        }
    }
    // ----- End: Tab and SOLdongle list related ---- //

    return (
        <>
            {filterForm()}
            {/* Table */}
            <div className={isTabletOrMobile? "row mt-4":"row"}>
                <div className={"col-md-12"}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabSelection}
                        aria-label="operational logs"
                        classes={{ indicator: classes.indicator}}
                        centered={!isTabletOrMobile}
                    >
                        <Tab label={activeDongleListLabel} className={`${classes.tab} ${classes.firstTab}`}/>
                        <Tab label={allowedDongleListLabel} className={classes.tab}/>
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                {!props.collectingCompatibilities && activeDongles? <>
                                    <DataTableContainerForDongleList>
                                        <DataTable
                                            language={language}
                                            noDataAvailableMessageInEnglish={'No dongles available'}
                                            columns={dongleListColumns}
                                            data={activeDongles}
                                            showToolbar={false}
                                            asyncPagination={false}
                                            pagination={true}
                                            pageSize={5}
                                        />
                                    </DataTableContainerForDongleList>
                                </>:<>
                                    <Typography variant="h5">
                                        <Skeleton className={"d-flex justify-content-center align-items-center"}
                                                  variant="rect" sx={{ marginTop: '5px', color: '#F18D00' }} width={'100%'}
                                                  height={364}>
                                            Collecting active dongles ...
                                        </Skeleton>
                                    </Typography>
                                </>}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                {!props.collectingCompatibilities && allowedDongles? <>
                                    <DataTableContainerForDongleList>
                                        <DataTable
                                            language={language}
                                            noDataAvailableMessageInEnglish={'No dongles available'}
                                            columns={dongleListColumns}
                                            data={allowedDongles}
                                            showToolbar={false}
                                            asyncPagination={false}
                                            pagination={true}
                                            pageSize={5}
                                        />
                                    </DataTableContainerForDongleList>
                                </>:<>
                                    <Typography variant="h5">
                                        <Skeleton className={"d-flex justify-content-center align-items-center"}
                                                  variant="rect" sx={{ marginTop: '5px', color: '#F18D00' }} width={'100%'}
                                                  height={364}>
                                            Collecting allowed dongles ...
                                        </Skeleton>
                                    </Typography>
                                </>}
                            </div>
                        </div>
                    </TabPanel>
                </div>
            </div>
        </>
    );
};

FwCompatibilities.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        collectingCompatibilities: state.otaReducer.collectingCompatibilities,
        compatibilities: state.otaReducer.compatibilities,
        errorRelatedToCompatibilitiesCollection: state.otaReducer.errorRelatedToCompatibilitiesCollection
    }
}

export default connect(mapStateToProps, actions)(FwCompatibilities);