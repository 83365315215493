import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */

    COLLECT_SETTLEMENT_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/payment_settlements`, filter),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_SETTLEMENT_LIST_REQUEST,
                actionTypes.COLLECT_SETTLEMENT_LIST_SUCCESS,
                actionTypes.COLLECT_SETTLEMENT_LIST_FAILURE,
            ]
        }
    }),

    COLLECT_SETTLEMENT_LIST_FOR_EXPORT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/payment_settlements`, filter),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_SETTLEMENT_LIST_FOR_EXPORT_REQUEST,
                actionTypes.COLLECT_SETTLEMENT_LIST_FOR_EXPORT_SUCCESS,
                actionTypes.COLLECT_SETTLEMENT_LIST_FOR_EXPORT_FAILURE,
            ]
        }
    }),

    COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/invoice_payments`, filter),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT_REQUEST,
                actionTypes.COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT_SUCCESS,
                actionTypes.COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT_FAILURE,
            ]
        }
    }),

    SETTLE: (payload)=>({
        [CALL_API]: {
            endpoint: `/payment_settlements`,
            method: 'POST',
            backend_slug: 'transaction_management',
            body: payload,
            types: [
                actionTypes.SETTLE_REQUEST,
                actionTypes.SETTLE_SUCCESS,
                actionTypes.SETTLE_FAILURE,
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
