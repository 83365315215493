export const dataTableStyle = {
    "dataTableCardStyle": {
        maxWidth: "100%",
        paddingLeft: '20px',
        paddingRight: '20px',
        backgroundColor: "#ffffff",
        alignSelf: "center",
        boxShadow: 'none',
        backgroundBlendMode: true,
        borderRadius: '15px',
        padding: 0,
        zIndex: 0,
    }
}