export const qaTestActions = {


    FETCH_PVB_Q4_TEST_RESULTS_REQUEST: "FETCH_PVB_Q4_TEST_RESULTS_REQUEST",
    FETCH_PVB_Q4_TEST_RESULTS_SUCCESS: "FETCH_PVB_Q4_TEST_RESULTS_SUCCESS",
    FETCH_PVB_Q4_TEST_RESULTS_FAILURE: "FETCH_PVB_Q4_TEST_RESULTS_FAILURE",


    UPLOAD_PCBA_TESTS_REQUEST: "UPLOAD_PCBA_TESTS_REQUEST",
    UPLOAD_PCBA_TESTS_SUCCESS: "UPLOAD_PCBA_TESTS_SUCCESS",
    UPLOAD_PCBA_TESTS_FAILURE: "UPLOAD_PCBA_TESTS_FAILURE",


    RETURN_TO_INITIAL: "RETURN_TO_INITIAL"
}
