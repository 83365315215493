import React, { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router";
import { Button, Form, Popover, Spinner } from "react-bootstrap";
import Select from "react-select";
import { DayPickerSingleDateController} from "react-dates";
import { SolInput } from "../../../../components/SolStyledComponents/components";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import { actions } from "../../actions";
import { actions as commonActions } from "../../../commonReduxActions/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { requestCycle } from "../../../../utils/utilityFunctions";
import { Toolbar } from "../components";
import { showNotifications } from "../../../../utils/notification";
import { useSubheader } from "../../../../../_metronic/layout";
import Overlay from "react-bootstrap/Overlay";


const CreateBattery = props => {
    const { register, errors, control, clearErrors, handleSubmit, reset, setValue } = useForm();
    const subHeader = useSubheader();
    const location = useLocation();

    const [language, setLanguage] = useState('EN');
    const [edit, setEdit] = useState(!!sessionStorage.getItem('edit'));
    const [wStartDate, setWStartDate] = useState(moment());
    const [targetForWarrantyStartDatePicker, setTargetForWarrantyStartDatePicker] = useState(null);
    const [showWarrantyStartDatePicker, setShowWarrantyStartDatePicker] = useState(false);
    const [wEndDate, setWEndDate] = useState(moment());
    const [targetForWarrantyEndDatePicker, setTargetForWarrantyEndDatePicker] = useState(null);
    const [showWarrantyEndDatePicker, setShowWarrantyEndDatePicker] = useState(false);
    const [wStartDisplayDate, setwStartDisplayDate] = useState("");
    const [wEndDisplayDate, setwEndDisplayDate] = useState("");
    const [serialNumber, setSerialNumber] = useState(sessionStorage.getItem('serialNumber') ? sessionStorage.getItem('serialNumber') : "");

    const [batteryVendors, setBatteryVendors] = useState([]);
    const [batteryModels, setBatteryModels] = useState([]);
    const [bmsVendors, setBmsVendors] = useState([]);
    const [bmsModels, setBmsModels] = useState([]);
    const [focusedForWarrantyStartDate, setFocusedForWarrantyStartDate] = useState(true);
    const [focusedForWarrantyEndDate, setFocusedForWarrantyEndDate] = useState(true);
    const batteryVoltages = [
        { 'label': '51.2', 'value': '51.2' },
        { 'label': '64.0', 'value': '64.0' }
    ]

    const generalErrorMessage = language === "EN" ? "Please provide above information!" : "দয়া করে উপরের তথ্যটি দিন!"

    const {
        GET_BATTERY_MODEL_LIST,
        GET_BATTERY_VENDOR_LIST,
        GET_BMS_VENDOR_LIST,
        GET_BMS_MODEL_LIST,
        UPDATE_BATTERY,
        ADD_BATTERY,
    } = props;

    useEffect(() => {
        setLanguage(props.language)
        if (location.purpose && location.purpose === 'create') {
            setEdit(false)
            sessionStorage.setItem('edit', false)
        } else if (!location.purpose && sessionStorage.getItem('edit') !== 'true') {
            props.history.push({
                pathname: '/batteries/create',
                purpose: 'create'
            })
        }

        if (location.batteryData) {
            sessionStorage.setItem('edit', true)
            setEdit(true)

            // Battery Serial Number
            let serialNumber = location.batteryData.batterySerial
            sessionStorage.setItem('serialNumber', serialNumber)
            setSerialNumber(serialNumber)

            // Nameplate capacity
            let nameplateCapacity = location.batteryData.nameplate_capacity
            sessionStorage.setItem('nameplateCapacity', nameplateCapacity)
            setValue('nameplate_capacity', nameplateCapacity)

            // Reference capacity
            let referenceCapacity = location.batteryData.reference_capacity
            sessionStorage.setItem('referenceCapacity', referenceCapacity)
            setValue('reference_capacity', referenceCapacity)

            // Claim event reference capacity level (%)
            let claimEventReferenceCapacity = location.batteryData.claim_reference_capacity
            sessionStorage.setItem('claimEventReferenceCapacity', claimEventReferenceCapacity)
            setValue('claim_reference_capacity', claimEventReferenceCapacity)

            // Battery Cost
            let batteryCost = location.batteryData.battery_cost
            sessionStorage.setItem('batteryCost', batteryCost)
            setValue('battery_cost', batteryCost)


            // Battery Cost Including dongle
            let batteryCostIncludingDongle = location.batteryData.battery_cost_including_dongle
            sessionStorage.setItem('batteryCostIncludingDongle', batteryCostIncludingDongle)
            setValue('battery_cost_including_dongle', batteryCostIncludingDongle)

            // BMS Vendor
            let bmsVendor = location.batteryData.bms_vendor_guid
            sessionStorage.setItem('bmsVendor', bmsVendor)

            // BMS Model
            let bmsModel = location.batteryData.bms_model_guid
            sessionStorage.setItem('bmsModel', bmsModel)

            // Manufacturer battery identification number
            let manufacturerIdentificationNumber = location.batteryData.manufacturer_identification_number
            sessionStorage.setItem('manufacturerIdentificationNumber', manufacturerIdentificationNumber)
            setValue('manufacturer_identification_number', manufacturerIdentificationNumber)

            // Battery Nominal Voltage
            let batteryNominalVoltage = location.batteryData.nameplate_voltage
            sessionStorage.setItem('batteryNominalVoltage', batteryNominalVoltage)
            batteryVoltages.map((voltage) => {
                if (voltage.value === parseFloat(batteryNominalVoltage).toFixed(1)) {
                    setValue('nameplate_voltage', {
                        label: voltage.label,
                        value: voltage.value
                    })
                }
            })

            // Guaranteed Discharge Delivery
            let guaranteedDischargeDelivery = location.batteryData.guaranteed_discharge_delivery
            sessionStorage.setItem('guaranteedDischargeDelivery', guaranteedDischargeDelivery)
            setValue('guaranteed_discharge_delivery', guaranteedDischargeDelivery)

            // Warranty Start Date
            let warrantyStartDate = location.batteryData.warranty_start_date
            sessionStorage.setItem('warrantyStartDate', warrantyStartDate)
            onStartDatesChange(moment(warrantyStartDate, "YYYY-MM-DD"))

            // Warranty End Date
            let warrantyEndDate = location.batteryData.warranty_end_date
            sessionStorage.setItem('warrantyEndDate', warrantyEndDate)
            onEndDatesChange(moment(warrantyEndDate, "YYYY-MM-DD"))

            // Battery Vendor
            let batteryVendor = location.batteryData.battery_vendor_guid
            sessionStorage.setItem('batteryVendor', batteryVendor)

            // Battery Model
            let batteryModel = location.batteryData.battery_model_guid
            sessionStorage.setItem('batteryModel', batteryModel)
        }

        if (sessionStorage.getItem('edit') === 'true') {
            // Dealing with reloading the update page
            // Nameplate capacity
            setValue('nameplate_capacity', sessionStorage.getItem('nameplateCapacity'))
            // Reference capacity
            setValue('reference_capacity', sessionStorage.getItem('referenceCapacity'))
            // Claim event reference capacity level (%)
            setValue('claim_reference_capacity', sessionStorage.getItem('claimEventReferenceCapacity'))
            // Battery Cost
            setValue('battery_cost', sessionStorage.getItem('batteryCost'))
            // Battery Cost Including Dongle
            setValue('battery_cost_including_dongle', sessionStorage.getItem('batteryCostIncludingDongle'))
            // Manufacturer battery identification number
            setValue('manufacturer_identification_number', sessionStorage.getItem('manufacturerIdentificationNumber'))
            // Battery Nominal Voltage
            if (sessionStorage.getItem('batteryNominalVoltage')) {
                batteryVoltages.map((voltage) => {
                    // Battery Nominal Voltage will always be number
                    if (voltage.value === parseFloat(sessionStorage.getItem('batteryNominalVoltage')).toFixed(1)) {
                        setValue('nameplate_voltage', {
                            label: voltage.label,
                            value: voltage.value
                        })
                    }
                })
            }
            // Guaranteed Discharge Delivery
            setValue('guaranteed_discharge_delivery', sessionStorage.getItem('guaranteedDischargeDelivery'))
            // Warranty Start Date
            onStartDatesChange(moment(sessionStorage.getItem('warrantyStartDate'), "YYYY-MM-DD"))
            // Warranty End Date
            onEndDatesChange(moment(sessionStorage.getItem('warrantyEndDate'), "YYYY-MM-DD"))
        }

        GET_BATTERY_MODEL_LIST()
        GET_BATTERY_VENDOR_LIST()
        GET_BMS_VENDOR_LIST()
        GET_BMS_MODEL_LIST()
        subHeader.setActionButtons(null)

        return () => {
            props.RETURN_TO_INITIAL_STATE();
        }

    }, [])

    useEffect(() => {
        if (sessionStorage.getItem('edit') === 'false') {
            clearValues()
            clearSessionStorages()
        }
    }, [edit])

    const onStartDatesChange=(sel_date)=>{
        setWStartDate(sel_date)
        setwStartDisplayDate(moment.utc(sel_date).local().format('YYYY-MM-DD'))
        setShowWarrantyStartDatePicker(false)
        clearErrors("warranty_start_date")
    }

    const onEndDatesChange = (sel_date) => {
        setWEndDate(sel_date)
        setwEndDisplayDate(moment.utc(sel_date).local().format('YYYY-MM-DD'))
        setShowWarrantyEndDatePicker(false)
        clearErrors("warranty_end_date")
    }


    const strtPopover = () => {
        return <>
            <Overlay show={showWarrantyStartDatePicker} target={targetForWarrantyStartDatePicker} placement="bottom-start">
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerSingleDateController
                            date={wStartDate}
                            onDateChange={onStartDatesChange}
                            numberOfMonths={1}
                            focused={focusedForWarrantyStartDate}
                            onFocusChange={(e) => {
                                setFocusedForWarrantyStartDate(e.focused)
                            }}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "warranty_start_date") {
                                    setShowWarrantyStartDatePicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }


    const endPopover = () => {
        return <>
            <Overlay show={showWarrantyEndDatePicker} target={targetForWarrantyEndDatePicker} placement="bottom-start">
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerSingleDateController
                            date={wEndDate}
                            onDateChange={onEndDatesChange}
                            numberOfMonths={1}
                            focused={focusedForWarrantyEndDate}
                            onFocusChange={(e) => {
                                setFocusedForWarrantyEndDate(e.focused)
                            }}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "warranty_end_date") {
                                    setShowWarrantyEndDatePicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }

    const clearValues = () => {
        // Battery Serial Number
        setSerialNumber("")
        // Nameplate capacity
        setValue('nameplate_capacity', '100')
        // Reference capacity
        setValue('reference_capacity', '')
        // Claim event reference capacity level (%)
        setValue('claim_reference_capacity', '80')
        // Battery Cost
        setValue('battery_cost', '')
        // BMS Vendor
        setValue('bmsVendor', '')
        // BMS Model
        setValue('bmsModel', '')
        // Manufacturer battery identification number
        setValue('manufacturer_identification_number', '')
        // Battery Nominal Voltage
        setValue('nameplate_voltage', '')
        // Guaranteed Discharge Delivery
        setValue('guaranteed_discharge_delivery', '200000')
        // Warranty Start Date
        setValue('warranty_start_date', '')
        // Warranty End Date
        setValue('warranty_end_date', '')
        // Battery Vendor
        setValue('batteryVendor', '')
        // Battery Model
        setValue('batteryModel', '')
    }

    const clearSessionStorages = () => {
        sessionStorage.removeItem('edit')
        sessionStorage.removeItem('serialNumber')
        sessionStorage.removeItem('nameplateCapacity')
        sessionStorage.removeItem('referenceCapacity')
        sessionStorage.removeItem('claimEventReferenceCapacity')
        sessionStorage.removeItem('batteryCost')
        sessionStorage.removeItem('bmsVendor')
        sessionStorage.removeItem('bmsModel')
        sessionStorage.removeItem('manufacturerIdentificationNumber')
        sessionStorage.removeItem('batteryNominalVoltage')
        sessionStorage.removeItem('guaranteedDischargeDelivery')
        sessionStorage.removeItem('warrantyStartDate')
        sessionStorage.removeItem('warrantyEndDate')
        sessionStorage.removeItem('batteryVendor')
        sessionStorage.removeItem('batteryModel')
    }

    useEffect(() => {
        if (props.batteryCreated === requestCycle.success) {
            props.history.push('/batteries/list');
        }
    }, [props.batteryCreated])

    useEffect(() => {
        if (props.batteryUpdated === requestCycle.success) {
            clearValues()
            clearSessionStorages()
            props.history.goBack()
        }
    }, [props.batteryUpdated])

    useEffect(() => {
        const batteryModels = props.batteryModels;
        if (batteryModels && batteryModels.length >= 0) {
            setBatteryModels(batteryModels.map((batteryModel) => {
                if (edit && sessionStorage.getItem('batteryModel') && batteryModel.pk === sessionStorage.getItem('batteryModel')) {
                    setValue('batteryModel', {
                        ...batteryModel,
                        label: batteryModel.model_name,
                        value: batteryModel.pk
                    })
                }
                return {
                    ...batteryModel,
                    label: batteryModel.model_name,
                    value: batteryModel.pk,
                }
            }));
        }
    }, [props.batteryModels])

    useEffect(() => {
        const batteryVendors = props.batteryVendors;
        if (batteryVendors && batteryVendors.length >= 0) {
            setBatteryVendors(batteryVendors.map((batteryVendor) => {
                if (edit && sessionStorage.getItem('batteryVendor') && batteryVendor.pk === sessionStorage.getItem('batteryVendor')) {
                    setValue('batteryVendor', {
                        ...batteryVendor,
                        label: batteryVendor.name,
                        value: batteryVendor.pk
                    })
                }
                return {
                    ...batteryVendor,
                    label: batteryVendor.name,
                    value: batteryVendor.pk
                }
            }));
        }
    }, [props.batteryVendors])

    useEffect(() => {
        const bmsVendors = props.bmsVendors;
        if (bmsVendors && bmsVendors.length >= 0) {
            setBmsVendors(bmsVendors.map((bmsVendor) => {
                if (edit && sessionStorage.getItem('bmsVendor') && bmsVendor.pk === sessionStorage.getItem('bmsVendor')) {
                    setValue('bmsVendor', {
                        ...bmsVendor,
                        label: bmsVendor.name,
                        value: bmsVendor.pk
                    })
                }
                return {
                    ...bmsVendor,
                    label: bmsVendor.name,
                    value: bmsVendor.pk
                }
            }));
        }
    }, [props.bmsVendors])

    useEffect(() => {
        const bmsModels = props.bmsModels;
        if (bmsModels && bmsModels.length >= 0) {
            setBmsModels(bmsModels.map((bmsModel) => {
                if (edit && sessionStorage.getItem('bmsModel') && bmsModel.pk === sessionStorage.getItem('bmsModel')) {
                    setValue('bmsModel', {
                        ...bmsModel,
                        label: bmsModel.model_name,
                        value: bmsModel.pk
                    })
                }
                return {
                    ...bmsModel,
                    label: bmsModel.model_name,
                    value: bmsModel.pk
                }
            }));
        }
    }, [props.bmsModels])

    const submit = (data) => {
        let warranty_start_date = moment(data.warranty_start_date);
        let warranty_end_date = moment(data.warranty_end_date);

        if(warranty_start_date.diff(warranty_end_date, 'days') > 0){
            showNotifications('error', { msg: "Warranty start date cannot be greater than warranty end date" })
            return false;
        }

        const payload = {
            "entity_type": "battery",
            "nameplate_capacity": data.nameplate_capacity,
            "reference_capacity": data.reference_capacity,
            "claim_reference_capacity": data.claim_reference_capacity,
            "nameplate_voltage": data.nameplate_voltage.value,
            "guaranteed_discharge_delivery": data.guaranteed_discharge_delivery,
            "warranty_start_date": data.warranty_start_date,
            "warranty_end_date": data.warranty_end_date,
            "battery_vendor_guid": data.batteryVendor.pk,
            "battery_model_guid": data.batteryModel.pk,
            "bms_vendor_guid": data.bmsVendor.pk,
            "bms_model_guid": data.bmsModel.pk,
            "battery_vendor": data.batteryVendor.name,
            "battery_model": data.batteryModel.model_name,
            "bms_vendor": data.bmsVendor.name,
            "bms_model": data.bmsModel.model_name,
            "created_by": props.user.user_guid,
            "created_by_name": props.user.name,
            "manufacturer_identification_number": data.manufacturer_identification_number,
            "battery_cost": parseFloat(data.battery_cost),
            "battery_cost_including_dongle": parseFloat(data.battery_cost_including_dongle),
        }
        if (edit) {
            UPDATE_BATTERY(payload, serialNumber)
        } else {
            ADD_BATTERY(payload)
        }
    }

    useEffect(() => {
        if (props.successMessage !== undefined) {
            language === 'EN' ? showNotifications('success', props.successMessage) : toast.success("সফলভাবে ব্যাটারি নিবন্ধন সম্পন্ন হয়েছে!")
        } else if (props.errorMessage !== undefined) {
            showNotifications('error', props.errorMessage)
        }
    }, [props.successMessage, props.errorMessage])

    useEffect(() => {
        if (props.successMessageForUpdate !== undefined) {
            language === 'EN' ? showNotifications('success', props.successMessageForUpdate) : toast.success("সফলভাবে ব্যাটারী তথ্য সম্পাদনা হয়েছে!")
        } else if (props.errorMessageForUpdate !== undefined) {
            showNotifications('error', props.errorMessageForUpdate)
        }
    }, [props.successMessageForUpdate, props.errorMessageForUpdate])


    return (
        <ContentWrapper showCardHeader={false} pageTitle={'Add Battery'} showBackButton={false} isLoading={false}>
            <div className={"row mb-6"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>{!edit ? (language === 'EN' ? 'Battery Registration' : 'ব্যাটারি নিবন্ধন করুন') : (language === 'EN' ? 'Edit Battery ' + serialNumber : 'ব্যাটারি সম্পাদনা করুন ' + serialNumber)}</b></h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
            </div>

            <Form onSubmit={handleSubmit(submit)}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === "EN" ? "Nameplate capacity (Ah)" : "সক্ষমতা (অ্যাম্পিয়ার/ঘন্টা)"}<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type={"text"}
                                        name={"nameplate_capacity"}
                                        placeholder={language === "EN" ? "Capacity of the battery in Ah" : "ব্যাটারির সক্ষমতা এম্পিয়ার/ঘন্টায়"}
                                        autoComplete={"off"}
                                        defaultValue={100}
                                        ref={register({
                                            required: generalErrorMessage,
                                            pattern: {
                                                value: /^(0|[1-9][0-9]*)$/i,
                                                message: language === "EN" ? "Please Enter Numbers Only!" : "ব্যাটারির সক্ষমতার মান হিসেবে শুধু সংখ্যা গ্রহণযোগ্য!"
                                            }
                                        })}
                                    />
                                    {errors.nameplate_capacity && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.nameplate_capacity.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === "EN" ? "Reference capacity by supplier (Ah)" : "Reference capacity by supplier (Ah)"}<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type={"text"}
                                        name={"reference_capacity"}
                                        placeholder={language === "EN" ? "Reference capacity by supplier" : "Reference capacity by supplier"}
                                        autoComplete={"off"}
                                        ref={register({
                                            required: generalErrorMessage,
                                            pattern: {
                                                value: /^(0|[1-9][0-9]*)$/i,
                                                message: language === "EN" ? "Please Enter Numbers Only!" : "শুধু সংখ্যা গ্রহণযোগ্য!"
                                            }
                                        })}
                                    />
                                    {errors.reference_capacity && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.reference_capacity.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === "EN" ? "Claim event reference capacity level (%)" : "ভোল্টেজ"}<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type={"text"}
                                        name={"claim_reference_capacity"}
                                        placeholder={language === "EN" ? "Claim event reference capacity level (%)" : "Claim event reference capacity level (%)"}
                                        autoComplete={"off"}
                                        defaultValue={80}
                                        ref={register({
                                            required: generalErrorMessage,
                                            pattern: {
                                                value: /^(0|[1-9][0-9]*)$/i,
                                                message: language === "EN" ? "Please Enter Numbers Only!" : "শুধু সংখ্যা গ্রহণযোগ্য!"
                                            }
                                        })}
                                    />
                                    {errors.claim_reference_capacity && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.claim_reference_capacity.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === 'EN' ? 'Battery Cost' : 'Battery Cost'}<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type="number"
                                        name={"battery_cost"}
                                        ref={register({
                                            required: generalErrorMessage,
                                        })}
                                        placeholder={language === 'EN' ? "Battery Cost" : "Warranty end date"}
                                        autoComplete={"off"}

                                    />
                                    {errors.battery_cost && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.battery_cost.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === 'EN' ? 'Battery Cost Including Dongle price' : 'Battery Cost'}<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type="number"
                                        name={"battery_cost_including_dongle"}
                                        ref={register({
                                            required: "Required",
                                        })}
                                        placeholder={language === 'EN' ? "Battery Cost Including Dongle price" : "Warranty end date"}
                                        autoComplete={"off"}

                                    />
                                    {errors.battery_cost_including_dongle && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.battery_cost_including_dongle.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === "EN" ? "BMS vendor" : "বিএমএস বিক্রেতা"}<span className="required text-danger">*</span></Form.Label>
                                    </div>

                                    <Controller
                                        control={control}
                                        name={"bmsVendor"}
                                        rules={{ required: generalErrorMessage }}
                                        render={({ onChange, onBlur, value, name, ref },
                                                 { invalid, isTouched, isDirty }) => (
                                            <Select
                                                name={`bmsVendor`}
                                                placeholder={language === 'EN' ? 'Select a BMS vendor' : 'বিএমএস বিক্রেতা বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.bmsVendorListDropDownLoading}
                                                isLoading={props.bmsVendorListDropDownLoading}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={bmsVendors}
                                                isSearchable={true}
                                                onChange={(selected, { action }) => {
                                                    if (action === "clear") {
                                                        setValue('bmsVendor', "")
                                                    }
                                                    onChange(selected)
                                                    if (selected) {
                                                        setValue('bmsVendor', selected)
                                                    } else {
                                                        setValue('bmsVendor', "")
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                    {errors.bmsVendor && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.bmsVendor.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === "EN" ? "BMS model" : "বিএমএস মডেল"}<span className="required text-danger">*</span></Form.Label>
                                    </div>

                                    <Controller
                                        control={control}
                                        name={"bmsModel"}
                                        rules={{ required: generalErrorMessage }}
                                        render={({ onChange, onBlur, value, name, ref },
                                                 { invalid, isTouched, isDirty }) => (
                                            <Select
                                                name={`bmsModel`}
                                                placeholder={language === 'EN' ? 'Select a BMS model' : 'বিএমএস মডেল বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.bmsModelListDropDownLoading}
                                                isLoading={props.bmsModelListDropDownLoading}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={bmsModels}
                                                isSearchable={true}
                                                onChange={(selected, { action }) => {
                                                    if (action === "clear") {
                                                        setValue('bmsModel', "")
                                                    }
                                                    onChange(selected)
                                                    if (selected) {
                                                        setValue('bmsModel', selected)
                                                    } else {
                                                        setValue('bmsModel', "")
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                    {errors.bmsModel && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.bmsModel.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                               <div className={"col-md-10"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>{language === "EN"? "Manufacturer Identification Number":"ম্যানুফ্যাকচারার আইডেন্টিফিকেশান নাম্বার"}<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                    <SolInput
                                        type={"text"}
                                        name={"manufacturer_identification_number"}
                                        placeholder={language === "EN" ? "Enter Manufacturer Identification Number" : "ম্যানুফ্যাকচারার আইডেন্টিফিকেশান নাম্বার দিন"}
                                        autoComplete={"off"}
                                        ref={register({
                                            required: generalErrorMessage
                                        })}
                                    />
                                       {errors.manufacturer_identification_number && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.manufacturer_identification_number.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>
                    </div>


                    <div className={"col-md-6"}>
                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === "EN" ? "Battery nominal voltage (V)" : "Battery nominal voltage (V)"} <span className="required text-danger">*</span></Form.Label>

                                    <Controller
                                        control={control}
                                        name={"nameplate_voltage"}
                                        rules={{ required: generalErrorMessage }}
                                        render={({ onChange, onBlur, value, name, ref },
                                                 { invalid, isTouched, isDirty }) => (
                                            <Select
                                                name={`nameplate_voltage`}
                                                placeholder={language === 'EN' ? 'Enter Battery nominal voltage (V)' : 'Enter Battery nominal voltage (V)'}
                                                classNamePrefix="react-select-sol-style"
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={batteryVoltages}
                                                isSearchable={true}
                                                onChange={(selected, { action }) => {
                                                    if (action === "clear") {
                                                        setValue('nameplate_voltage', "")
                                                    }
                                                    onChange(selected)
                                                    if (selected) {
                                                        setValue('nameplate_voltage', selected)
                                                    } else {
                                                        setValue('nameplate_voltage', "")
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.nameplate_voltage && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.nameplate_voltage.message}</div>}

                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === "EN" ? "Guaranteed discharge delivery (Ah)" : "Guaranteed discharge delivery (Ah)"}<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type={"text"}
                                        name={"guaranteed_discharge_delivery"}
                                        placeholder={language === "EN" ? "Enter guaranteed discharge delivery (Ah)" : "Guaranteed discharge delivery (Ah)"}
                                        autoComplete={"off"}
                                        defaultValue={200000}
                                        ref={register({
                                            required: generalErrorMessage,
                                            pattern: {
                                                value: /^(0|[1-9][0-9]*)$/i,
                                                message: language === "EN" ? "Please Enter Numbers Only!" : "শুধু সংখ্যা গ্রহণযোগ্য!"
                                            }
                                        })}
                                    />
                                    {errors.guaranteed_discharge_delivery && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.guaranteed_discharge_delivery.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === 'EN' ? 'Warranty start date (YYYY-MM-DD)' : 'Warranty start date'}<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        name={'warranty_start_date'}
                                        readOnly={true}
                                        value={wStartDisplayDate}
                                        placeholder={language === 'EN' ? "Warranty start date" : "Warranty start date"}
                                        ref={register({
                                            required: generalErrorMessage
                                        })}
                                        onClick={(e) => {
                                            setTargetForWarrantyStartDatePicker(e.target)
                                            setShowWarrantyStartDatePicker(!showWarrantyStartDatePicker)
                                        }}
                                    />
                                    {errors.warranty_start_date && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.warranty_start_date.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === 'EN' ? 'Warranty end date (YYYY-MM-DD)' : 'Warranty end date'}<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        name={'warranty_end_date'}
                                        readOnly={true}
                                        value={wEndDisplayDate}
                                        placeholder={language === 'EN' ? "Warranty end date" : "Warranty end date"}
                                        ref={register({
                                            required: generalErrorMessage
                                        })}
                                        onClick={(e) => {
                                            setTargetForWarrantyEndDatePicker(e.target)
                                            setShowWarrantyEndDatePicker(!showWarrantyEndDatePicker)
                                        }}
                                    />
                                    {errors.warranty_end_date && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.warranty_end_date.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === "EN" ? "Battery vendor" : "ব্যাটারি বিক্রেতা"}<span className="required text-danger">*</span></Form.Label>
                                    </div>

                                    <Controller
                                        control={control}
                                        name={"batteryVendor"}
                                        rules={{ required: generalErrorMessage }}
                                        render={({ onChange, onBlur, value, name, ref },
                                                 { invalid, isTouched, isDirty }) => (
                                            <Select
                                                name={`batteryVendor`}
                                                placeholder={language === 'EN' ? 'Select a vendor' : 'বিক্রেতা বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.batteryVendorListDropDownLoading}
                                                isLoading={props.batteryVendorListDropDownLoading}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={batteryVendors}
                                                isSearchable={true}
                                                onChange={(selected, { action }) => {
                                                    if (action === "clear") {
                                                        setValue('batteryVendor', "")
                                                    }
                                                    onChange(selected)
                                                    if (selected) {
                                                        setValue('batteryVendor', selected)
                                                    } else {
                                                        setValue('batteryVendor', "")
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                    {errors.batteryVendor && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.batteryVendor.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === "EN" ? "Battery model":"ব্যাটারি মডেল"}<span className="required text-danger">*</span></Form.Label>
                                    </div>

                                    <Controller
                                        control={control}
                                        name={"batteryModel"}
                                        rules={{ required: generalErrorMessage }}
                                        render={({ onChange, onBlur, value, name, ref },
                                                 { invalid, isTouched, isDirty }) => (
                                            <Select
                                                name={`batteryModel`}
                                                placeholder={language === 'EN' ? 'Select a battery model' : 'ব্যাটারি মডেল বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.batteryModelListDropDownLoading}
                                                isLoading={props.batteryModelListDropDownLoading}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={batteryModels}
                                                isSearchable={true}
                                                onChange={(selected, { action }) => {
                                                    if (action === "clear") {
                                                        setValue('batteryModel', "")
                                                    }
                                                    onChange(selected)
                                                    if (selected) {
                                                        setValue('batteryModel', selected)
                                                    } else {
                                                        setValue('batteryModel', "")
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                    {errors.batteryModel && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.batteryModel.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                    </div>


                    <div className={'row'}>
                        <div className={"col-md-12"}>
                            <Button variant={"dark"} size={'md'}
                                onClick={() => {
                                    clearValues()
                                    clearSessionStorages()
                                    // props.history.goBack() --- go backs to the previous state (ex: last form submit,
                                    // input etc.), not exactly to the previous page all the time. Hence the following
                                    // has been used - Noor Reza, 20th September, 5:45 PM
                                    props.history.push({
                                        pathname: '/batteries/list'
                                    })
                                }} style={{ backgroundColor: '#8C8C9B', outline: '#8C8C9B', border: '#8C8C9B' }}>
                                <i className='fa fa-times' aria-hidden='true' />&nbsp;{language === "EN" ? "Cancel" : "বাতিল করুন"}
                            </Button>
                            <Button variant={"warning"} type={"submit"} size={"md"} className={"ml-3"}
                                disabled={(props.batteryModelListDropDownLoading || props.batteryVendorListDropDownLoading
                                    || props.bmsVendorListDropDownLoading || props.bmsModelListDropDownLoading ||
                                    props.batteryInfoSubmitting || props.batteryUpdateInfoSubmitting)}>
                                {props.batteryUpdateInfoSubmitting? <><Spinner animation={'border'} size={'sm'} variant={'light'}/></> : null}
                                <i className='fa fa-paper-plane' aria-hidden='true' />&nbsp;{language === "EN"? "Submit" : "সংরক্ষণ করুন"}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>

            {strtPopover()}
            {endPopover()}

            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
        </ContentWrapper>
    );
};

CreateBattery.propTypes = {};


const mapStateToProps = (state) => {

    return {
        isLoading: state.batteryReducer.isLoading,
        language: state.auth.language,
        user: state.auth.user,
        batteryModelListDropDownLoading: state.batteryReducer.batteryModelListDropDownLoading,
        batteryModels: state.batteryReducer.batteryModels,
        batteryVendorListDropDownLoading: state.batteryReducer.batteryVendorListDropDownLoading,
        batteryVendors: state.batteryReducer.batteryVendors,
        bmsVendorListDropDownLoading: state.batteryReducer.bmsVendorListDropDownLoading,
        bmsVendors: state.batteryReducer.bmsVendors,
        bmsModelListDropDownLoading: state.batteryReducer.bmsModelListDropDownLoading,
        bmsModels: state.batteryReducer.bmsModels,
        batteryCreated: state.batteryReducer.batteryCreated,
        batteryUpdated: state.batteryReducer.batteryUpdated,
        batteryInfoSubmitting: state.batteryReducer.batteryInfoSubmitting,
        batteryUpdateInfoSubmitting: state.batteryReducer.batteryUpdateInfoSubmitting,
        successMessage: state.batteryReducer.successMessage,
        errorMessage: state.batteryReducer.errorMessage,
        successMessageForUpdate: state.batteryReducer.successMessageForUpdate,
        errorMessageForUpdate: state.batteryReducer.errorMessageForUpdate
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(CreateBattery);
