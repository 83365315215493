import React, {useState} from 'react';
import {connect} from 'react-redux';
import "../customStyle.css"
import AutoCompleteComponent from "./autoCompleteComponent";


const SearchFieldForDesktop = ({...props }) => {
    const [language, setLanguage] = useState("EN");

    const {
        COLLECT_EV_LIST
    } = props;

    return (
        <>
            <div className={'customStyleForSearchInDesktop'}>
                <AutoCompleteComponent fieldId={'desktop-search-field'}
                                       placeholder={'Search with EV number'}/>
            </div>
        </>
    );
};

SearchFieldForDesktop.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations
    }
}

export default connect(mapStateToProps)(SearchFieldForDesktop);