import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    COLLECT_FINANCIERS: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/organizations', filter),
            method: 'GET',
            types: [
                actionTypes.COLLECT_FINANCIERS_REQUEST,
                actionTypes.COLLECT_FINANCIERS_SUCCESS,
                actionTypes.COLLECT_FINANCIERS_FAILURE,
            ]
        }
    }),

    COLLECT_BRANCHES_OF_A_FINANCIER: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/branches', filter),
            method: 'GET',
            // backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_BRANCHES_OF_A_FINANCIER_REQUEST,
                actionTypes.COLLECT_BRANCHES_OF_A_FINANCIER_SUCCESS,
                actionTypes.COLLECT_BRANCHES_OF_A_FINANCIER_FAILURE,
            ]
        }
    }),

    COLLECT_DIVISIONS_FOR_FINANCIER: ()=>({
        [CALL_API]: {
            endpoint: '/geo/divisions',
            method: 'GET',
            types: [
                actionTypes.COLLECT_DIVISIONS_FOR_FINANCIER_REQUEST,
                actionTypes.COLLECT_DIVISIONS_FOR_FINANCIER_SUCCESS,
                actionTypes.COLLECT_DIVISIONS_FOR_FINANCIER_FAILURE,
            ]
        }
    }),

    COLLECT_DISTRICTS_FOR_FINANCIER: (divisionId)=>({
        [CALL_API]: {
            endpoint: '/geo/districts/' + divisionId,
            method: 'GET',
            types: [
                actionTypes.COLLECT_DISTRICTS_FOR_FINANCIER_REQUEST,
                actionTypes.COLLECT_DISTRICTS_FOR_FINANCIER_SUCCESS,
                actionTypes.COLLECT_DISTRICTS_FOR_FINANCIER_FAILURE,
            ]
        }
    }),

    COLLECT_SUB_DISTRICTS_FOR_FINANCIER: (districtId)=>({
        [CALL_API]: {
            endpoint: '/geo/upazilas/' + districtId,
            method: 'GET',
            types: [
                actionTypes.COLLECT_SUB_DISTRICTS_FOR_FINANCIER_REQUEST,
                actionTypes.COLLECT_SUB_DISTRICTS_FOR_FINANCIER_SUCCESS,
                actionTypes.COLLECT_SUB_DISTRICTS_FOR_FINANCIER_FAILURE,
            ]
        }
    }),

    COLLECT_ZONE_AREA_FOR_FINANCIER: (orgGuid)=>({
        [CALL_API]: {
            endpoint: '/organization/zone/' + orgGuid,
            method: 'GET',
            types: [
                actionTypes.COLLECT_ZONE_AREA_FOR_FINANCIER_REQUEST,
                actionTypes.COLLECT_ZONE_AREA_FOR_FINANCIER_SUCCESS,
                actionTypes.COLLECT_ZONE_AREA_FOR_FINANCIER_FAILURE
            ]
        }
    }),

    CREATE_NEW_ORGANIZATION: (payload)=>({
        [CALL_API]: {
            endpoint: '/organizations',
            method: 'POST',
            body: payload,
            types: [
                actionTypes.CREATE_NEW_ORGANIZATION_REQUEST,
                actionTypes.CREATE_NEW_ORGANIZATION_SUCCESS,
                actionTypes.CREATE_NEW_ORGANIZATION_FAILURE
            ]
        }
    }),

    EDIT_NEW_ORGANIZATION: (orgGuid, payload)=>({
        [CALL_API]: {
            endpoint: '/organization/' + orgGuid,
            method: 'PUT',
            body: payload,
            types: [
                actionTypes.EDIT_NEW_ORGANIZATION_REQUEST,
                actionTypes.EDIT_NEW_ORGANIZATION_SUCCESS,
                actionTypes.EDIT_NEW_ORGANIZATION_FAILURE
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
