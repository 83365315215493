import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import RoleList from "./views/roleList/roleList";
import CreateRole from "./views/newRole/CreateRole";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const RolesRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/roles"}
                to={"/roles/list"}
            />

            <SecuredRoute
                path={"/roles/list"}
                component={RoleList}
                service={getServiceName('userService')}
                feature={getFeatureName('roleFeature')}
                action={getActionName('listAction')}
            />

            <SecuredRoute
                path={"/roles/create"}
                component={CreateRole}
                service={getServiceName('userService')}
                feature={getFeatureName('roleFeature')}
                action={getActionName('createAction')}
            />

            <SecuredRoute
                path={"/roles/update"}
                component={CreateRole}
                service={getServiceName('userService')}
                feature={getFeatureName('roleFeature')}
                action={getActionName('updateAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
