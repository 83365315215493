import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../router/SecuredRoute";
import { actions as commonActions } from '../../features/commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import Stack from '@mui/material/Stack';
import {Button, Form, Spinner} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ReactSmartScroller } from 'react-smart-scroller';
import moment from "moment";
import {LoadingSpinner} from "../LoadingSpinnerForDataTable";
import parse from 'html-react-parser';
import {commonReducer} from "../../features/commonReduxActions/commonReducer";
import {requestCycle} from "../../features/rentLog/utils";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../features/auth/authorization";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    }
});

const EventsHistory = ({ data, numberOfThings, feature, ...props }) => {
    const [language, setLanguage] = useState("EN");
    const { control, setValue, handleSubmit } = useForm();
    const classes = useStylesIconButton();
    const [selectedThings, setSelectedThings] = useState([]);
    const [singleThingSelected, setSingleThingSelected] = useState(false);
    const [events, setEvents] = useState([]);
    const [showRequiredMessage, setShowRequiredMessage] = useState(false);
    const [numberOfEventHistories, setNumberOfEventHistories] = useState(0);
    const tableHeaderFontStyling = {color: '#8C8C9B', 'fontWeight': 500, 'fontSize': '1.1rem'}

    // Permission related
    const deviceServiceName = getServiceName('deviceService')
    const eventHistoryFeature = getFeatureName('eventHistoryFeature')
    const createActionName = getActionName('createAction')
    const listActionName = getActionName('listAction')
    const [createAccessHasGiven, setCreateAccessHasGiven] = useState(true);
    const [listViewAccessHasGiven, setListViewAccessHasGiven] = useState(true);

    // Scrollbar related
    const [sliderForEvents, setSliderForEvents] = useState(null);
    const [eventFormHeight, setEventFormHeight] = useState(0);
    const eventForm = useRef(null);

    // Multiple things selection message related
    const [marginTopForText, setMarginTopForText] = useState(0);
    const addEventButton = useRef(null);

    // Rich text editor related
    const [description, setDescription] = useState('');
    const modules = {
        toolbar: [
            ['link']
        ],
    }
    const formats = [
        'link'
    ]

    const eventCategoryOptions =  [
        {
            label: 'Integration Event',
            value: 'integration-event'
        },
        {
            label: 'After Sales Event',
            value: 'after-sales-event'
        },
        {
            label: 'SOLshare Event',
            value: 'solshare-event'
        },
        {
            label: 'Location Change',
            value: 'location-change'
        },
        {
            label: 'General Event',
            value: 'general-event'
        },
        {
            label: 'Installed in EV',
            value: 'installed-in-ev'
        },
        {
            label: 'Replacement',
            value: 'replacement'
        }
    ]

    const {
        COLLECT_EVENTS_HISTORY,
        SAVE_EVENT,
        CLEAN_UP_STATES_RELATED_TO_EVENTS_HISTORY_MODAL
    } = props;

    useEffect(()=>{
        setLanguage(props.language)

        setCreateAccessHasGiven(checkAuthorization(props.authorization, deviceServiceName, eventHistoryFeature, [createActionName]))
        setListViewAccessHasGiven(checkAuthorization(props.authorization, deviceServiceName, eventHistoryFeature, [listActionName]))

        setSelectedThings(data.map((thingData) => {
            if (feature === 'smartDongle') {
                return thingData.serial_number
            } else {
                return thingData.dongle_serial_number
            }
        }))
        if (numberOfThings === 1) {
            setSingleThingSelected(true)
            if (feature === 'smartDongle') {
                COLLECT_EVENTS_HISTORY({'dongle_serial_number': data[0].serial_number})
            } else {
                COLLECT_EVENTS_HISTORY({'device_serial_number': data[0].pk})
            }
        }
        setEventFormHeight(eventForm.current.clientHeight)
        setMarginTopForText((eventForm.current.clientHeight - addEventButton.current.clientHeight)/2)
        return () => {
            CLEAN_UP_STATES_RELATED_TO_EVENTS_HISTORY_MODAL()
        }
    }, [])

    useEffect(() => {
        if (props.events) {
            const events = props.events
            const numberOfEventHistories = events.length
            setNumberOfEventHistories(props.events.length)
            if (events && numberOfEventHistories > -1) {
                setEvents(events.map((event) => {
                    return (
                        <Stack direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                            <h6><strong>{moment(event.created_at).format("MMM D, YYYY hh:mm A")}</strong></h6>
                            <strong>{event.event_category_info?.name}</strong>
                            <strong>By {event.created_by_name}</strong>
                            <span>{parse(event.event_description)}</span>
                        </Stack>
                    )
                }))
            }
        }
    }, [props.events]);

    useEffect(() => {
        if (events && numberOfEventHistories > 0) {
            setSliderForEvents((
                <ReactSmartScroller vertical spacing={24} style={{height: eventFormHeight + 'px'}}>
                    {events}
                </ReactSmartScroller>
            ))
        }
    }, [events, numberOfEventHistories]);

    const saveEvents = (data) => {
        if (data.eventCategory && description) {
            let payload = {
                "event_description" : description,
                "dongle_serial_numbers" : selectedThings,
                "event_category_slug" : data.eventCategory.value
            }
            SAVE_EVENT(payload)
        } else {
            setShowRequiredMessage(true)
        }
    }

    useEffect(() => {
        if (props.eventSaved === requestCycle.success) {
            if (numberOfThings === 1) {
                if (feature === 'smartDongle') {
                    COLLECT_EVENTS_HISTORY({'dongle_serial_number': data[0].serial_number})
                } else {
                    COLLECT_EVENTS_HISTORY({'device_serial_number': data[0].pk})
                }
            }
        }
    }, [props.eventSaved])

    return (
        <div>
            <div className={'row'}>
                {(createAccessHasGiven && listViewAccessHasGiven)? <>
                    <div className={'col-lg-6'} ref={eventForm}>
                        <Stack direction={'column'} spacing={2}>
                            <Form onSubmit={handleSubmit(saveEvents)}>
                                <div className={'row g-3'}>
                                    <div className={'col-lg-12'}>
                                        <Form.Group>
                                            <Form.Label>Events Category</Form.Label>
                                            <Controller
                                                control={control}
                                                name={"eventCategory"}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        placeholder={'Select category ...'}
                                                        classNamePrefix="react-select-sol-style-access-management"
                                                        maxMenuHeight={200}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        isDisabled={props.savingEvent}
                                                        control={control}
                                                        options={eventCategoryOptions}
                                                        inputRef={ref}
                                                        onChange={(selected, {action}) => {
                                                            onChange(selected)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className={'row g-3'}>
                                    <div className={'col-lg-12'}>
                                        <Form.Group>
                                            <div data-text-editor="description">
                                                <ReactQuill theme="snow" modules={modules} formats={formats}
                                                            value={description} onChange={setDescription}
                                                            bounds={`[data-text-editor="description"]`}/>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                {showRequiredMessage? <>
                                    <div className={'row g-3'}>
                                        <div className={'col-lg-12'}>
                                            <div className="text-danger float-left">
                                                <i className="flaticon-warning kt-font-brand"></i>
                                                &nbsp;&nbsp;{'Please provide all of above information!'}</div>
                                        </div>
                                    </div>
                                </>:null}
                                <div className={'row g-3'}>
                                    <div className={'col-lg-12'}>
                                        <Button type={"submit"} ref={addEventButton} variant="warning"
                                                size={'lg'} className={'float-left'}
                                                disabled={props.savingEvent || props.collectingEventHistory}>
                                            {props.savingEvent? <><Spinner animation={'border'} size={'md'} variant={'light'}/>
                                            </>:null}&nbsp;&nbsp;Add Event
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </Stack>
                    </div>
                    <div className={'col-lg-6'}>
                        {singleThingSelected? <>
                            {props.collectingEventHistory? <>
                                <LoadingSpinner loadingSubText={language === 'EN'? 'Collecting events histories ..': 'ঘটনার ইতিহাস প্রস্তুত হচ্ছে ..'} language={language}/>
                            </>:<>
                                {/* Why -1 value has been checked here ? I (or anyone who are seeing this message) may check it, if any time available
                                 - Noor Faizur Reza, 25th July, 2023, 11:57 am */}
                                {numberOfEventHistories === 0 || numberOfEventHistories === -1? <>
                                    <div className={'row'} style={{...tableHeaderFontStyling, marginTop: marginTopForText + 'px',
                                        whiteSpace: 'nowrap'}}>
                                        <div className={'col-lg-3'}></div>
                                        <div className={'col-lg-9'}>No events available</div>
                                    </div>
                                </>:numberOfEventHistories === 1? <>
                                    <Stack direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                                        {events[0]}
                                    </Stack>
                                </>:<>
                                    {sliderForEvents}
                                </>}
                            </>}
                        </>:<>
                            <div className={'row'} style={{...tableHeaderFontStyling, marginTop: marginTopForText + 'px',
                                whiteSpace: 'nowrap'}}>
                                <div className={'col-lg-3'}></div>
                                <div className={'col-lg-9'}>Multiple {feature === 'smartDongle'? 'SOLdongles':'Smart Batteries'} Selected</div>
                            </div>
                        </>}
                    </div>
                </>:(createAccessHasGiven && !listViewAccessHasGiven)? <>
                    <div className={'col-lg-12'} ref={eventForm}>
                        <Stack direction={'column'} spacing={2}>
                            <Form onSubmit={handleSubmit(saveEvents)}>
                                <div className={'row g-3'}>
                                    <div className={'col-lg-12'}>
                                        <Form.Group>
                                            <Form.Label>Events Category</Form.Label>
                                            <Controller
                                                control={control}
                                                name={"eventCategory"}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        placeholder={'Select category ...'}
                                                        classNamePrefix="react-select-sol-style-access-management"
                                                        maxMenuHeight={200}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        isDisabled={props.savingEvent}
                                                        control={control}
                                                        options={eventCategoryOptions}
                                                        inputRef={ref}
                                                        onChange={(selected, {action}) => {
                                                            onChange(selected)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className={'row g-3'}>
                                    <div className={'col-lg-12'}>
                                        <Form.Group>
                                            <div data-text-editor="description">
                                                <ReactQuill theme="snow" modules={modules} formats={formats}
                                                            value={description} onChange={setDescription}
                                                            bounds={`[data-text-editor="description"]`}/>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                {showRequiredMessage? <>
                                    <div className={'row g-3'}>
                                        <div className={'col-lg-12'}>
                                            <div className="text-danger float-left">
                                                <i className="flaticon-warning kt-font-brand"></i>
                                                &nbsp;&nbsp;{'Please provide all of above information!'}</div>
                                        </div>
                                    </div>
                                </>:null}
                                <div className={'row g-3'}>
                                    <div className={'col-lg-12'}>
                                        <Button type={"submit"} ref={addEventButton} variant="warning"
                                                size={'lg'} className={'float-left'}
                                                disabled={props.savingEvent || props.collectingEventHistory}>
                                            {props.savingEvent? <><Spinner animation={'border'} size={'md'} variant={'light'}/>
                                            </>:null}&nbsp;&nbsp;Add Event
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </Stack>
                    </div>
                </>:<>
                    <div className={'col-lg-12'}>
                        {singleThingSelected? <>
                            {props.collectingEventHistory? <>
                                <LoadingSpinner loadingSubText={language === 'EN'? 'Collecting events histories ..': 'ঘটনার ইতিহাস প্রস্তুত হচ্ছে ..'} language={language}/>
                            </>:<>
                                {numberOfEventHistories === 0 || numberOfEventHistories === -1? <>
                                    <div className={'row'} style={{...tableHeaderFontStyling, marginTop: marginTopForText + 'px',
                                        whiteSpace: 'nowrap'}}>
                                        <div className={'col-lg-3'}></div>
                                        <div className={'col-lg-9'}>No events available</div>
                                    </div>
                                </>:numberOfEventHistories === 1? <>
                                    <Stack direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                                        {events[0]}
                                    </Stack>
                                </>:<>
                                    {sliderForEvents}
                                </>}
                            </>}
                        </>:<>
                            <div className={'row'} style={{...tableHeaderFontStyling, marginTop: marginTopForText + 'px',
                                whiteSpace: 'nowrap'}}>
                                <div className={'col-lg-3'}></div>
                                <div className={'col-lg-9'}>Multiple {feature === 'smartDongle'? 'SOLdongles':'Smart Batteries'} Selected</div>
                            </div>
                        </>}
                    </div>
                </>}
            </div>
        </div>
    );
};

EventsHistory.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        savingEvent: state.commonReducer.savingEvent,
        collectingEventHistory: state.commonReducer.collectingEventHistory,
        events: state.commonReducer.events,
        eventSaved: state.commonReducer.eventSaved
    }
}

export default connect(mapStateToProps, { ...commonActions })(withRoles(EventsHistory));