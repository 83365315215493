/*
* Server Error Component
* v.1.0.0
* */

import React from "react";
import {Button, Spinner} from "react-bootstrap";
import PropTypes from "prop-types";

export const PermissionDenied =(props)=> {
    return <>
        <div className={'col-md-12'}>

            <div className={'mt-4 mb-4'}>
                <h3>
                    Oops! Looks like you do not have access to this feature. Please contact support team.
                </h3>
            </div>
            <div className={'mb-4'}>
                <img src={require('../utils/asset/locked.svg')} alt={"Server Error"} width={300} height={250}/>
                {props.reloadFunction && typeof props.reloadFunction === "function" && <div>
                    <Button variant="secondary"  onClick={props.reloadFunction}>
                        {props.disableFlashButton ? <Spinner animation="border" variant="secondary" />:<i className="flaticon2-refresh-button"/>}
                        &nbsp;Reload
                    </Button>
                </div>}
            </div>

        </div>
    </>
}



PermissionDenied.propTypes = {
    disableFlashButton: PropTypes.bool.isRequired,
    reloadFunction: PropTypes.func,
}