import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_TOKEN_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/tokens`, filter),
            method: 'GET',
            backend_slug: 'token_management',
            types: [
                actionTypes.GET_TOKEN_LIST_REQUEST,
                actionTypes.GET_TOKEN_LIST_SUCCESS,
                actionTypes.GET_TOKEN_LIST_FAILURE
            ]
        }
    }),

    GENERATE_TOKEN: (payload)=>({
        [CALL_API]: {
            endpoint: '/tokens',
            method: 'POST',
            body : payload,
            backend_slug: 'token_management',
            types: [
                actionTypes.GENERATE_TOKEN_REQUEST,
                actionTypes.GENERATE_TOKEN_SUCCESS,
                actionTypes.GENERATE_TOKEN_FAILURE
            ]
        }
    }),

    CANCEL_TOKEN: (payload)=>({
        [CALL_API]: {
            endpoint: '/tokens/cancel',
            method: 'POST',
            body : payload,
            backend_slug: 'token_management',
            types: [
                actionTypes.CANCEL_TOKEN_REQUEST,
                actionTypes.CANCEL_TOKEN_SUCCESS,
                actionTypes.CANCEL_TOKEN_FAILURE
            ]
        }
    }),
};
