import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../../router/SecuredRoute";
import { transactionActions } from '../actions';
import { actions as commonActions } from '../../commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import {Button, Form, OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {
    DataTable,
    DataTableContainer,
    GenericModal,
    LoadingSpinner,
    SolInput,
    SolTypography
} from "../../../components";
import moment from "moment";
import {useMediaQuery} from "react-responsive";
import {showNotifications} from "../../../utils/notification";
import Select from "react-select";
import {commonReducer} from "../../commonReduxActions/commonReducer";
import {invoicesReducer} from "../reducer";
import {customStylesForSingleSelectField} from "../../../utils/reactSelectRelated/customStylesForSingleSelect";
import {customStylesForMultiSelectField} from '../../../utils/reactSelectRelated/customStylesForMultiSelect'
import {groupSelectedOptions} from '../../../utils/reactSelectRelated/groupSelectedOptionsForMultiSelect'
import {formulateApiRequestDataFromSelectedItems} from '../../../utils/multiSelectValueFormation/formatForApiRequest'
import {ValueContainer} from "../../../utils/reactSelectRelated/showCustomizedValueLabelForMultiSelect";
import {Option} from "../../../utils/reactSelectRelated/showOptionsWithCheckbox";
import {DayPickerRangeController} from "react-dates";
import Typography from "@mui/material/Typography";
import Skeleton from '@mui/material/Skeleton';
import Stack from "@mui/material/Stack";
import './customStyleForBulkInvoiceGeneration.css'
import {SolTextArea} from "../../../components/SolStyledComponents/components";
import Alert from '@mui/material/Alert';

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const BulkInvoiceExtension = ({ cancelProcess, handleExtensionProcess, ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError, getValues} = useForm();
    const [language, setLanguage] = useState("EN");
    const [showEntitySelectionField, setShowEntitySelectionField] = useState(false);
    const [entitySelectionFieldLabel, setEntitySelectionFieldLabel] = useState('');
    const [financiers, setFinanciers] = useState([]);
    const [garages, setGarages] = useState([]);
    const [borrowers, setBorrowers] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [entity, setEntity] = useState('');

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');

    const [errorMessageIsShownForInvoiceCollection, setErrorMessageIsShownForInvoiceCollection] = useState(false);
    const [selectedInvoiceStatusLabel, setSelectedInvoiceStatusLabel] = useState('');
    const [invoices, setInvoices] = useState(undefined);
    const [showInvoiceTable, setShowInvoiceTable] = useState(false);
    const [numberOfInvoicesAvailableForExtension, setNumberOfInvoicesAvailableForExtension] = useState(0);
    const [selectedInvoicesForExtension, setSelectedInvoicesForExtension] = useState([]);
    const [showErrorForNonSelection, setShowErrorForNonSelection] = useState(false);
    const [showErrorForExceedingMaxInvoiceNumbers, setShowErrorForExceedingMaxInvoiceNumbers] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [disableConfirmationModalButtons, setDisableConfirmationModalButtons] = useState(false);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const classes = useStylesIconButton();

    const informationIsRequired = 'Above information is required!'

    const invoicesCollectionCriteriaOptions = [
        {
            label: 'Financier',
            value: 'financier'
        },
        {
            label: 'Garage',
            value: 'garage'
        },
        {
            label: 'Borrower',
            value: 'borrower'
        },
        {
            label: 'Contract',
            value: 'contract'
        }
    ]

    const invoicesStatusOptions = [
        {
            label: 'Overdue',
            value: 'overdue'
        },
        {
            label: 'Upcoming',
            value: 'upcoming'
        }
    ]

    const {
        GET_ORGANISATIONS,
        COLLECT_GARAGES_FOR_BULK_EXTENSION,
        COLLECT_BORROWERS_FOR_BULK_EXTENSION,
        COLLECT_CONTRACTS_FOR_BULK_EXTENSION,
        COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION,
        COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER,
        RETURN_TO_INITIAL_STATE_FOR_BULK_INVOICE_EXTENSION
    } = props;

    useEffect(()=>{
        setLanguage(props.language)

        return ()=>{
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
            RETURN_TO_INITIAL_STATE_FOR_BULK_INVOICE_EXTENSION()
        }
    }, [])

    //---------------------- Begin: Financier list collection related ----------------------//
    useEffect(()=>{
        if (props.organisations && props.organisations.length > -1) {
            const financiers = props.organisations
            setFinanciers(financiers.map((financier) => {
                return {
                    ...financier,
                    label: financier.name,
                    value: financier.pk
                }
            }))
        } else {
            setFinanciers([])
        }
    }, [props.organisations])
    //---------------------- End: Financier list collection related ----------------------//

    //---------------------- Begin: Garage list collection related ----------------------//
    useEffect(()=>{
        if (props.garages && props.garages.length > -1) {
            const garageList = props.garages
            setGarages(garageList.map((garage) => {
                return {
                    ...garage,
                    label: garage.name,
                    value: garage.pk
                }
            }))
        } else {
            setGarages([])
        }
    }, [props.garages])

    useEffect(() => {
        if (props.errorMessageForGarageCollection) {
            showNotifications('error', props.errorMessageForGarageCollection)
        }
    }, [props.errorMessageForGarageCollection])
    //---------------------- End: Garage list collection related ----------------------//

    //---------------------- Begin: Borrower list collection related ----------------------//
    useEffect(()=>{
        if (props.borrowers && props.borrowers.length > -1) {
            const borrowerList = props.borrowers
            setBorrowers(borrowerList.map((borrower) => {
                return {
                    ...borrower,
                    label: borrower.name,
                    value: borrower.pk
                }
            }))
        } else {
            setBorrowers([])
        }
    }, [props.borrowers])

    useEffect(() => {
        if (props.errorMessageForBorrowerCollection) {
            showNotifications('error', props.errorMessageForBorrowerCollection)
        }
    }, [props.errorMessageForBorrowerCollection])
    //---------------------- End: Borrower list collection related ----------------------//

    //---------------------- Begin: Contract list collection related ----------------------//
    useEffect(()=>{
        if (props.contracts && props.contracts.length > -1) {
            const contractList = props.contracts
            setContracts(contractList.map((contract) => {
                return {
                    ...contract,
                    label: 'Contract #' + contract.contract_id + ', SB #' + contract.device_serial_number,
                    value: contract.contract_id
                }
            }))
        } else {
            setContracts([])
        }
    }, [props.contracts])

    useEffect(() => {
        if (props.errorMessageForContractsCollection) {
            showNotifications('error', props.errorMessageForContractsCollection)
        }
    }, [props.errorMessageForContractsCollection])
    //---------------------- End: Contract list collection related ----------------------//

    //---------------------- Begin: Date Range Selection related ----------------------//
    const onDatesChange = ({startDate, endDate}) => {
        if (startDate && endDate) {
            setStartDate(startDate)
            setEndDate(endDate)
        } else if (startDate && !endDate) {
            setStartDate(startDate)
            setEndDate(startDate)
        }
    }

    useEffect(() => {
        if (startDate && endDate) {
            setDateRange(moment(startDate).format("MMMM DD, YYYY") + ' - ' + moment(endDate).format("MMMM DD, YYYY"))
            clearErrors("date_range")
        } else {
            setDateRange('')
        }
    }, [startDate, endDate])

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'} className={'custom-style'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    startDate={startDate}
                    endDate={endDate}
                    numberOfMonths={1}
                    minimumNights={0}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                    firstDayOfWeek={0}
                    isDayHighlighted={(day) => moment().isSame(day, 'day')}
                    // initialVisibleMonth={() => initialDateForSelection? initialDateForSelection:moment()}
                />
            </Popover.Content>
        </Popover>
    )
    //---------------------- End: Date Range Selection related ----------------------//

    //---------------------- Begin: Invoice collection and invoice table data load related ----------------------//
    const collectInvoicesForExtension = (data) => {
        setErrorMessageIsShownForInvoiceCollection(false)
        setShowErrorForNonSelection(false)
        setSelectedInvoiceStatusLabel(getValues('invoice_status').label)
        if (!entity) {
            setError('entity', {'message': informationIsRequired})
        } else {
            let payload = {}
            if (data.invoices_for.value === 'financier') {
                // Financier based filter
                if (data.invoice_status.value === 'overdue') {
                    payload['financier_guid'] = formulateApiRequestDataFromSelectedItems(entity)
                } else {
                    payload['organization_guid'] = formulateApiRequestDataFromSelectedItems(entity)
                }
            } else if (data.invoices_for.value === 'garage') {
                // Garage based filter
                payload['garage_guid'] = formulateApiRequestDataFromSelectedItems(entity)
            } else if (data.invoices_for.value === 'borrower') {
                // Borrower based filter
                payload['borrower_guid'] = formulateApiRequestDataFromSelectedItems(entity)
            } else {
                // Contract based filter
                payload['contract_id'] = formulateApiRequestDataFromSelectedItems(entity, 'value')
            }
            if (data.invoice_status.value === 'overdue') {
                // Collecting overdue invoices
                payload["invoice_due_from"] = moment(startDate).format("YYYY-MM-DD")
                payload["invoice_due_to"] = moment(endDate).format("YYYY-MM-DD")
                COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION(payload)
                setShowInvoiceTable(true)
            } else {
                // Collecting upcoming invoices
                payload["start_date"] = moment(startDate).format("YYYY-MM-DD")
                payload["end_date"] = moment(endDate).format("YYYY-MM-DD")
                COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION(payload)
                setShowInvoiceTable(true)
            }
        }
    }

    useEffect(()=>{
        if (props.invoicesForExtension && props.invoicesForExtension.length > -1) {
            const invoiceList = props.invoicesForExtension
            setNumberOfInvoicesAvailableForExtension(invoiceList.length)
            setInvoices(invoiceList.map((invoice) => {
                return {
                    ...invoice
                }
            }))
        } else {
            setInvoices(undefined)
        }
    }, [props.invoicesForExtension])

    useEffect(() => {
        if (props.errorMessageForInvoicesForExtensionCollection) {
            showNotifications('error', props.errorMessageForInvoicesForExtensionCollection)
            setErrorMessageIsShownForInvoiceCollection(true)
        }
    }, [props.errorMessageForInvoicesForExtensionCollection])

    const overdueInvoicesTableColumn = [
        {
            field: 'contract_id',
            title: "Details",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: "nowrap"}}>SB #{rowData.device_sl}, Inv #{rowData.invoice_id}</span><br/>
                    <span style={{whiteSpace: "nowrap"}}>Contract #{rowData.contract_id}</span>
                </>
            }
        },
        {
            field: 'created_at',
            title: "Payment Date",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return "-"
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: "nowrap"}}>
                        {rowData.contract_type === 'rent'? <>
                            {moment(rowData.created_at).format("MMM DD, YYYY")}
                        </>:<>
                            {rowData.installment_due_on? moment(rowData.installment_due_on).format("MMM DD, YYYY"):"-"}
                        </>}
                    </span>
                </>
            }
        },
        {
            field: 'payable_amount',
            title: "Payable (Tk)",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: 'nowrap'}}>{new Intl.NumberFormat('en-IN').format(rowData.payable_amount.toFixed(2))}</span>
                </>
            }
        },
        {
            field: 'invoice_status',
            title: 'Status',
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            render: (rowData)=> {
                return <>
                    <span className={'text-danger'} style={{whiteSpace: 'nowrap'}}>Overdue</span>
                </>
            }
        }
    ]

    const upcomingInvoicesTableColumn = [
        {
            field: 'contract_id',
            title: "Details",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: "nowrap"}}>SB #{rowData.smart_battery}, Installment #{rowData.installment_no}</span><br/>
                    <span style={{whiteSpace: "nowrap"}}>Contract #{rowData.contract_id}</span>
                </>
            }
        },
        {
            field: 'due_date',
            title: "Payment Date",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return "-"
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: "nowrap"}}>{moment(rowData.due_date).format("MMM DD, YYYY")}</span>
                </>
            }
        },
        {
            field: 'payable_amount',
            title: "Payable (Tk)",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: 'nowrap'}}>{new Intl.NumberFormat('en-IN').format(rowData.payable_amount.toFixed(2))}</span>
                </>
            }
        },
        {
            field: 'invoice_status',
            title: 'Status',
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            render: (rowData)=> {
                return <>
                    <span style={{whiteSpace: 'nowrap'}}>Upcoming</span>
                </>
            }
        }
    ]
    //---------------------- End: Invoice collection and invoice table data load related ----------------------//

    //---------------------- Begin: Actions related to bulk invoices extension ----------------------//
    const onInvoiceSelection = (data) => {
        if (data.length > 30) {
            setShowErrorForExceedingMaxInvoiceNumbers(true)
        } else {
            setShowErrorForExceedingMaxInvoiceNumbers(false)
        }
        setShowErrorForNonSelection(false)
        setSelectedInvoicesForExtension(data)
    }

    const initiateExtensionProcess = () => {
        if (selectedInvoicesForExtension.length === 0) {
            setShowErrorForNonSelection(true)
        } else {
            setShowConfirmationModal(true)
        }
    }

    const hideConfirmationModal = () => {
        setShowConfirmationModal(false)
    }

    const renderExtensionProcessConfirmationModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideConfirmationModal}
                modal={showConfirmationModal}
                title={<h1><strong>Extending <span style={{color: "#F18D00"}}>{selectedInvoicesForExtension.length}</span> invoice{selectedInvoicesForExtension.length > 1? 's':''}</strong></h1>}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <h4>Are you sure?</h4>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Button variant="warning" size={'md'} type="button" className={'ml-3 float-right'}
                                onClick={extendInvoices} disabled={disableConfirmationModalButtons}>
                            Yes
                        </Button>
                        <Button variant="outline-dark" size={'md'} onClick={hideConfirmationModal}
                                className={'float-right'} disabled={disableConfirmationModalButtons}>
                            No
                        </Button>
                    </div>
                </div>
            </GenericModal>
        </>
    }

    const extendInvoices = () => {
        setDisableConfirmationModalButtons(true)
        let contractIds = []
        let payload = []
        for (let i=0; i<selectedInvoicesForExtension.length; i++) {
            if (contractIds.length === 0 || !contractIds.includes(selectedInvoicesForExtension[i].contract_id)) {
                contractIds.push(selectedInvoicesForExtension[i].contract_id)
                payload.push({
                    "contract_id": selectedInvoicesForExtension[i].contract_id,
                    "installment_no": [selectedInvoicesForExtension[i].installment_no],
                    "reason": getValues("reason")
                })
            } else {
                payload[payload.length - 1]["installment_no"].push(selectedInvoicesForExtension[i].installment_no)
            }
        }
        setShowConfirmationModal(false)
        setDisableConfirmationModalButtons(false)
        handleExtensionProcess(getValues('invoice_status').value, payload)
    }
    //---------------------- Begin: Actions related to bulk invoices extension ----------------------//

    return (
        <>
            <div>
                <Form onSubmit={handleSubmit(collectInvoicesForExtension)}>
                    <div className={'row g-3'}>
                        <div className={'col-md-6'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Invoices for&nbsp;<span className="required text-danger">*</span></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"invoices_for"}
                                    defaultValue={""}
                                    rules={{ required:informationIsRequired }}
                                    render={( { onChange, onBlur, value, name, ref },
                                              { invalid, isTouched, isDirty }) => (
                                        <Select
                                            placeholder={'Select an option'}
                                            classNamePrefix="react-select-sol-style"
                                            isDisabled={props.orgListLoading || props.garageCollectionInProgress ||
                                                props.borrowerCollectionInProgress || props.contractsCollectionInProgress}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={invoicesCollectionCriteriaOptions}
                                            isSearchable={true}
                                            noOptionsMessage={() => "No option available"}
                                            styles={customStylesForSingleSelectField}
                                            onChange={(selected, {action}) => {
                                                if (action === 'clear') {
                                                    setShowEntitySelectionField(false)
                                                    setEntitySelectionFieldLabel('')
                                                }
                                                onChange(selected)
                                                setEntity('')
                                                clearErrors('entity')
                                                if (selected) {
                                                    if (selected.value === 'financier') {
                                                        GET_ORGANISATIONS()
                                                        setEntitySelectionFieldLabel('Financier')
                                                    } else if (selected.value === 'garage') {
                                                        COLLECT_GARAGES_FOR_BULK_EXTENSION()
                                                        setEntitySelectionFieldLabel('Garage')
                                                    } else if (selected.value === 'borrower') {
                                                        COLLECT_BORROWERS_FOR_BULK_EXTENSION()
                                                        setEntitySelectionFieldLabel('Borrower')
                                                    } else if (selected.value === 'contract') {
                                                        COLLECT_CONTRACTS_FOR_BULK_EXTENSION()
                                                        setEntitySelectionFieldLabel('Contract')
                                                    }
                                                    setShowEntitySelectionField(true)
                                                }
                                            }}
                                        />
                                    )}
                                />
                                {errors.invoices_for && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.invoices_for.message}</div>}
                            </Form.Group>
                        </div>
                        {showEntitySelectionField? <>
                            <div className={'col-md-6'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{entitySelectionFieldLabel}&nbsp;<span className="required text-danger">*</span></Form.Label>
                                    </div>
                                    {/* Following select field has not been registered using useHook form for two reasons:
                                        1. We can't use Controller to control the field component are applying custom style for multi-select feature
                                        2. And also in (the uncontrolled) field (component), we are setting value manually
                                        - Noor Reza, 4th July,2024 4:41 PM */}
                                    <Select
                                        name={entitySelectionFieldLabel === 'Financier'? 'financier':
                                            entitySelectionFieldLabel === 'Garage'? 'garage':
                                                entitySelectionFieldLabel === 'Borrower'? 'borrower': 'contract'}
                                        placeholder={'Select ' + entitySelectionFieldLabel.toLowerCase()}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.orgListLoading || props.garageCollectionInProgress ||
                                            props.borrowerCollectionInProgress || props.contractsCollectionInProgress}
                                        isLoading={props.orgListLoading || props.garageCollectionInProgress ||
                                            props.borrowerCollectionInProgress || props.contractsCollectionInProgress}
                                        maxMenuHeight={200}
                                        isClearable={true}
                                        value={entity}
                                        options={entitySelectionFieldLabel === 'Financier'? financiers:
                                            entitySelectionFieldLabel === 'Garage'? garages:
                                                entitySelectionFieldLabel === 'Borrower'? borrowers:contracts}
                                        isSearchable={true}
                                        isMulti={true}
                                        hideSelectedOptions={false}
                                        styles={customStylesForMultiSelectField}
                                        components={{ ValueContainer, Option }}
                                        noOptionsMessage={() => "No option available"}
                                        onChange={(selected, { action }) => {
                                            if (action === "clear") {
                                                setEntity('')
                                            }
                                            clearErrors('entity') // Clearing errors manually as it is an uncontrolled component
                                            if (selected) {
                                                setEntity(selected)
                                                let selectedEntityType = getValues('invoices_for').value
                                                if (selectedEntityType === 'financier') {
                                                    setFinanciers(groupSelectedOptions(selected, financiers))
                                                } else if (selectedEntityType === 'garage') {
                                                    setGarages(groupSelectedOptions(selected, garages))
                                                } else if (selectedEntityType === 'borrower') {
                                                    setBorrowers(groupSelectedOptions(selected, borrowers))
                                                } else {
                                                    setContracts(groupSelectedOptions(selected, contracts))
                                                }
                                            }
                                        }}
                                    />
                                    {errors.entity && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.entity.message}</div>}
                                </Form.Group>
                            </div>
                        </>:''}
                    </div>
                    <div className={'row g-3'}>
                        <div className={'col-md-6'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Payment Date Range&nbsp;<span className="required text-danger">*</span></Form.Label>
                                </div>
                                <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                overlay={popover}>
                                    <SolInput
                                        name={'date_range'}
                                        readOnly={true}
                                        value={dateRange}
                                        ref={register({
                                            required: informationIsRequired
                                        })}
                                        placeholder={"Select a date"}
                                    />
                                </OverlayTrigger>
                                {errors.date_range && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.date_range.message}</div>}
                            </Form.Group>
                        </div>
                        <div className={'col-md-6'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Invoices Status&nbsp;<span className="required text-danger">*</span></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"invoice_status"}
                                    defaultValue={""}
                                    rules={{ required:informationIsRequired }}
                                    render={( { onChange, onBlur, value, name, ref },
                                              { invalid, isTouched, isDirty }) => (
                                        <Select
                                            placeholder={'Select an option'}
                                            classNamePrefix="react-select-sol-style"
                                            isDisabled={false}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={invoicesStatusOptions}
                                            isSearchable={true}
                                            noOptionsMessage={() => "No option available"}
                                            styles={customStylesForSingleSelectField}
                                            onChange={(selected, {action}) => {
                                                onChange(selected)
                                            }}
                                        />
                                    )}
                                />
                                {errors.invoice_status && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.invoice_status.message}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    {showInvoiceTable? <>
                        {!props.invoicesForExtensionCollectionInProgress && invoices && invoices.length > 0? <>
                            <div className={'row g-3 test'}>
                                <div className={'col-md-12'}>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <span><h5><strong>Invoice List</strong></h5></span>
                                        <span className={'mb-2'}><strong>(Showing <span style={{color: "#F18D00"}}>
                                            {numberOfInvoicesAvailableForExtension} {selectedInvoiceStatusLabel} {numberOfInvoicesAvailableForExtension>1? 'invoices':'invoice'}
                                        </span> from <span style={{color: "#F18D00"}}>
                                            {moment(startDate).format('MMMM DD,YYYY')} to {moment(endDate).format('MMMM DD,YYYY')}
                                        </span>)</strong></span>
                                    </Stack>
                                </div>
                            </div>
                        </>:''}
                        <div className={'row g-3'}>
                            <div className={'col-md-12'}>
                                {!props.invoicesForExtensionCollectionInProgress && invoices? <>{invoices.length>0? <>
                                    <DataTableContainer>
                                        <DataTable
                                            noDataAvailableMessageInEnglish={"No invoices available"}
                                            language={language}
                                            columns={getValues('invoice_status') && getValues('invoice_status').value === 'upcoming'? upcomingInvoicesTableColumn:overdueInvoicesTableColumn}
                                            data={invoices}
                                            asyncPagination={false}
                                            pagination={true}
                                            pageSize={5}
                                            // Selection related
                                            selection={true}
                                            showSelectAllCheckbox={true}
                                            onSelectionChange={onInvoiceSelection}
                                        />
                                    </DataTableContainer>
                                    </>:<>
                                    <span className={'text-center'}><h3>No invoices available</h3></span>
                                    </>}</> : <>
                                    {errorMessageIsShownForInvoiceCollection? <>
                                        <span className={'text-center'}><h3>Invoices collection process has failed</h3></span>
                                    </>:<>
                                        <Typography variant="h5">
                                            {/* The height of the Skeleton component is determined by adding "the height of the table while showing 5 invoices" and "the height of the title (Invoices List)" */}
                                            {/* So that between the loading period and the invoice table data representation period, height of the modal doesn't get changed that much */}
                                            {/* - Noor Reza, July 8th, 2024 7:06 PM */}
                                            <Skeleton animation="wave" className={"d-flex justify-content-center align-items-center custom-loader"}
                                                      variant="rectangular" width={'100%'} height={369}>
                                                Collecting invoices ...
                                            </Skeleton>
                                        </Typography>
                                    </>}
                                </>}
                            </div>
                        </div>
                        <div className={'row g-3'}>
                            <div className={'col-md-12'}>
                                <div>
                                    <Form.Label>Reason</Form.Label>
                                </div>
                                <SolTextArea
                                    name={"reason"}
                                    placeholder={"Reason for extension..."}
                                    autoComplete={"off"}
                                    rows={"5"}
                                    ref={register()}
                                />
                                {errors.reason && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.reason.message}</div>}
                            </div>
                        </div>
                    </>:''}
                    {showErrorForNonSelection? <>
                        <div className={'row'}>
                            <div className={"col-md-12"}>
                                <Alert severity="error">Please select at least one invoice!</Alert>
                            </div>
                        </div>
                    </>:''}
                    {showErrorForExceedingMaxInvoiceNumbers? <>
                        <div className={'row'}>
                            <div className={"col-md-12"}>
                                <Alert severity="error">You can select maximum <strong>30</strong> invoices!</Alert>
                            </div>
                        </div>
                    </>:''}
                    <hr/>
                    <div className={'row'}>
                        <div className={"col-md-12"}>
                            {showInvoiceTable? <>
                                <Button variant="warning" size={isTabletOrMobile? 'sm':'lg'} type="button"
                                        disabled={props.invoicesForExtensionCollectionInProgress || !numberOfInvoicesAvailableForExtension || props.invoiceExtensionInProgress || props.installmentExtensionInProgress || showErrorForExceedingMaxInvoiceNumbers}
                                        className={isTabletOrMobile? 'ml-1 float-right':'ml-3 float-right'} onClick={initiateExtensionProcess}>
                                    Proceed to Extend
                                </Button>
                            </>:''}
                            <Button variant="warning" size={isTabletOrMobile? 'sm':'lg'} type="submit"
                                    disabled={props.invoicesForExtensionCollectionInProgress || props.invoiceExtensionInProgress || props.installmentExtensionInProgress}
                                    className={isTabletOrMobile? 'ml-1 float-right':'ml-3 float-right'}>
                                {showInvoiceTable? 'Update invoice list':'Collect Invoices'}
                            </Button>
                            <Button variant="outline-dark" size={isTabletOrMobile? 'sm':'lg'} onClick={cancelProcess}
                                    className={'float-right'} disabled={props.invoiceExtensionInProgress || props.installmentExtensionInProgress}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Form>

                {renderExtensionProcessConfirmationModal()}
            </div>
        </>
    );
};

BulkInvoiceExtension.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        organisations: state.commonReducer.organisations,
        orgListLoading: state.commonReducer.orgListLoading,
        garages: state.invoicesReducer.garages,
        garageCollectionInProgress: state.invoicesReducer.garageCollectionInProgress,
        errorMessageForGarageCollection: state.invoicesReducer.errorMessageForGarageCollection,
        borrowers: state.invoicesReducer.borrowers,
        borrowerCollectionInProgress: state.invoicesReducer.borrowerCollectionInProgress,
        errorMessageForBorrowerCollection: state.invoicesReducer.errorMessageForBorrowerCollection,
        invoicesForExtension: state.invoicesReducer.invoicesForExtension,
        invoicesForExtensionCollectionInProgress: state.invoicesReducer.invoicesForExtensionCollectionInProgress,
        errorMessageForInvoicesForExtensionCollection: state.invoicesReducer.errorMessageForInvoicesForExtensionCollection,
        contracts: state.invoicesReducer.contracts,
        errorMessageForContractsCollection: state.invoicesReducer.errorMessageForContractsCollection,
        contractsCollectionInProgress: state.invoicesReducer.contractsCollectionInProgress,
        invoiceExtensionInProgress: state.invoicesReducer.invoiceExtensionInProgress,
        installmentExtensionInProgress: state.invoicesReducer.installmentExtensionInProgress
    }
}

export default connect(mapStateToProps, { ...transactionActions, ...commonActions })(withRoles(BulkInvoiceExtension));