import React, { useState, useEffect, useRef } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import { actions } from '../../actions';
import moment from "moment/moment";
import {convertToAnotherLang} from "../../../../utils/convertLanguageOfNumbersWithCharacter";
import DataTable from "../../../../components/dataTable/DataTable";
import {LoadingSpinner} from "../../../../components";
import {DataTableContainer} from "../../utils";
import {Button} from "react-bootstrap";
import {showNotifications} from "../../../../utils/notification";
import './Calendar_custom.css';
import Calendar from "react-calendar";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import Stack from "@mui/material/Stack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {exportInExcel} from "../../../../utils/excelExport";


const InactiveBatteries = ({ fromDate, toDate, totalInactiveBatteries, hideModalEvent, ...props }) => {
    const [startOfDateRange, setStartOfDateRange] = useState("");
    const [endOfDateRange, setEndOfDateRange] = useState("");
    const [inactiveBatteries, setInactiveBatteries] = useState(undefined);
    const [garagesForValidation, setGaragesForValidation] = useState([]);
    const [language, setLanguage] = useState("EN");

    // Active days calendar
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [smartBatteryForActivitySummary, setSmartBatteryForActivitySummary] = useState('');
    const [monthOfActivitySummary, setMonthOfActivitySummary] = useState('');
    const [viewRelatedValueOfTheCalendar, setViewRelatedValueOfTheCalendar] = useState(new Date());
    const [activeDays, setActiveDays] = useState(new Set());
    const [inactiveDays, setInactiveDays] = useState(new Set());
    const [currentMonthIsSelected, setCurrentMonthIsSelected] = useState(true); // By default, current month is shown
    const [value, setCalenderValue] = useState();
    const calendarRef = useRef(null);
    // Saving 281 as initial value, as we have seen the height of the calendar div as 281 at the laptop screen
    const [heightOfTheCalendar, setHeightOfTheCalendar] = useState(281)

    const {
        COLLECT_INACTIVE_BATTERY_LIST,
        COLLECT_GARAGE_LIST,
        COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY
    } = props;

    const inactiveBatteriesTable = [
        {
            field: 'serial_number',
            title: 'Smart Battery',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    <h6><strong>SB-{rowData.serial_number}</strong></h6>
                    <small><strong>SOLdongle</strong></small><br/>
                    {rowData.dongle_details? <>
                        <small style={{whiteSpace: 'nowrap'}}><strong>#{rowData.dongle_serial_number},&nbsp;FW&nbsp;v{rowData.dongle_details.firmware_version},&nbsp;HW&nbsp;{rowData.dongle_details.hardware_version}</strong></small><br/>
                    </>:<>
                        <small style={{whiteSpace: 'nowrap'}}><strong>#{rowData.dongle_serial_number}</strong></small><br/>
                    </>}
                    <small><strong>Battery&nbsp;#{rowData.battery_serial_number}</strong></small><br/>
                    {rowData.battery_details? <>
                        {rowData.battery_details.battery_vendor && rowData.battery_details.nameplate_voltage && rowData.battery_details.nameplate_capacity? <>
                            <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.battery_vendor}&nbsp;{rowData.battery_details.nameplate_voltage}V,&nbsp;{rowData.battery_details.nameplate_capacity}Ah</strong></small>
                        </>:<>
                            {rowData.battery_details.battery_vendor && rowData.battery_details.nameplate_voltage && !rowData.battery_details.nameplate_capacity? <>
                                <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.battery_vendor}&nbsp;{rowData.battery_details.nameplate_voltage}V</strong></small>
                            </>:<>
                                {rowData.battery_details.battery_vendor && !rowData.battery_details.nameplate_voltage && rowData.battery_details.nameplate_capacity? <>
                                    <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.battery_vendor}&nbsp;{rowData.battery_details.nameplate_capacity}Ah</strong></small>
                                </>:<>
                                    {!rowData.battery_details.battery_vendor && rowData.battery_details.nameplate_voltage && rowData.battery_details.nameplate_capacity? <>
                                        <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.nameplate_voltage}V,&nbsp;{rowData.battery_details.nameplate_capacity}Ah</strong></small>
                                    </>:<>
                                        {rowData.battery_details.battery_vendor && !rowData.battery_details.nameplate_voltage && !rowData.battery_details.nameplate_capacity? <>
                                            <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.battery_vendor}</strong></small>
                                        </>:<>
                                            {!rowData.battery_details.battery_vendor && rowData.battery_details.nameplate_voltage && !rowData.battery_details.nameplate_capacity? <>
                                                <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.nameplate_voltage}V</strong></small>
                                            </>:<>
                                                {!rowData.battery_details.battery_vendor && !rowData.battery_details.nameplate_voltage && rowData.battery_details.nameplate_capacity? <>
                                                    <small style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.nameplate_capacity}Ah</strong></small>
                                                </>:''}
                                            </>}
                                        </>}
                                    </>}
                                </>}
                            </>}
                        </>}
                    </>:''}
                </>
            }
        },
        {
            field: 'garageName',
            title: 'Borrower',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    {rowData.contract_details? <>
                        <p className="mb-0">{rowData.contract_details.borrower_name}</p>
                    </>:''}
                    <p className="mb-0">{rowData.garageName}</p>
                </>
            }
        },
        {
            field: 'last_rent_created_at',
            title: 'Last Rented On',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return   <>
                    <span style={{whiteSpace: 'nowrap'}}>{moment(rowData.last_rent_created_at).format("MMM D, YYYY hh:mma")}</span>
                </>
            }
        },
        {
            field: 'created_at',
            title: 'Activity Trend',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return   <>
                    <Button variant={'warning'} size={'sm'} onClick={() => {showActivityTrend(rowData)}}>View</Button>
                </>
            }
        }
    ]

    useEffect(()=>{
        setLanguage(props.language)
        let from_date = language === 'EN'? moment(fromDate).format("YYYY-MM-DDTHH:mm:ss"):convertToAnotherLang(moment(fromDate).format("YYYY-MM-DDTHH:mm:ss"))
        let to_date = language === 'EN'? moment(toDate).format("YYYY-MM-DDTHH:mm:ss"):convertToAnotherLang(moment(toDate).format("YYYY-MM-DDTHH:mm:ss"))
        setStartOfDateRange(moment(from_date).format("Do MMMM, YYYY"))
        setEndOfDateRange(moment(to_date).format("Do MMMM, YYYY"))
        COLLECT_INACTIVE_BATTERY_LIST({ from_date: from_date, to_date: to_date, financier_guid: props.organizationGuid, garage_guid: props.garageGuid, branch_guid: props.branchGuid })
        COLLECT_GARAGE_LIST()
    }, [])

    useEffect(() => {
        if (props.inactiveBatteries) {
            const batteries = props.inactiveBatteries
            setInactiveBatteries(batteries.map((battery) => {
                let garageName = battery.garage_name
                if (garagesForValidation && garagesForValidation.length > 0) {
                    let filteredData = garagesForValidation.filter(garage => garage.pk === battery.garage_guid)
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                    }
                }
                return {
                    ...battery,
                    garageName: garageName
                }
            }))
        } else {
            setInactiveBatteries(undefined)
        }
    }, [props.inactiveBatteries, garagesForValidation])

    useEffect(() => {
        if (props.garages) {
            const garages = props.garages;
            setGaragesForValidation(garages.map((garage) => {
                return {
                    ...garage
                }
            }))
        } else {
            setGaragesForValidation([])
        }
    }, [props.garages])

    useEffect(() => {
        if (props.errorMessageForInactiveBatteryListCollection) {
            showNotifications('error', props.errorMessageForInactiveBatteryListCollection)
        }
    }, [props.errorMessageForInactiveBatteryListCollection])

    // ---------------------- Download ------------------------ //
    const downloadInactiveBatteryInfo = () => {
        let presentTime = moment()
        let totalInactiveBatteryText = "Total Inactive Batteries: " + totalInactiveBatteries
        let exportText = "Exported At: " + presentTime.format("Do MMMM,YYYY hh:mm A")
        let excelRows = [
            ["Inactive Batteries", "", "", "", "", "", "", "", ""],
            [totalInactiveBatteryText, "", "", "", "", "", "", "", ""],
            [exportText, "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", ""],
            ["Smart Battery", "Borrower", "Garage", "Last Rented On", "SOLdongle", "Battery", "Battery Vendor", "Namplated Voltage (V)", "Nameplate Capacity (Ah)"]
        ]

        let rangeText = startOfDateRange + " - " + endOfDateRange
        excelRows.splice(1, 0, [rangeText, "", "", "", "", "", "", "", ""])

        for (let i=0; i<inactiveBatteries.length; i++) {
            excelRows.push([
                inactiveBatteries[i].serial_number,
                inactiveBatteries[i].contract_details? (inactiveBatteries[i].contract_details.borrower_name && inactiveBatteries[i].contract_details.borrower_username?
                    inactiveBatteries[i].contract_details.borrower_name + ' (' + inactiveBatteries[i].contract_details.borrower_username + ')':inactiveBatteries[i].contract_details.borrower_name? inactiveBatteries[i].contract_details.borrower_name:''):'',
                inactiveBatteries[i].garageName,
                inactiveBatteries[i].last_rent_created_at? moment(inactiveBatteries[i].last_rent_created_at).format("MMM D, YYYY hh:mma"):'',
                inactiveBatteries[i].dongle_serial_number,
                inactiveBatteries[i].battery_serial_number,
                inactiveBatteries[i].battery_details?.battery_vendor? inactiveBatteries[i].battery_details.battery_vendor:'',
                inactiveBatteries[i].battery_details?.nameplate_voltage? inactiveBatteries[i].battery_details.nameplate_voltage:'',
                inactiveBatteries[i].battery_details?.nameplate_capacity? inactiveBatteries[i].battery_details.nameplate_capacity:''
            ])
        }

        let noDataAvailableText =  "No inactive batteries within the date range"
        inactiveBatteries.length > 0? excelRows.push(
            ["", "", "", "", "", "", "", "", ""],
        ):excelRows.push(["", "", "", "", noDataAvailableText, "", "", "", ""])
        let fileName = 'inactive-batteries' + '-' + moment(startOfDateRange, "Do MMMM, YYYY").format("MMM-DD-YYYY") + '-' + moment(endOfDateRange, "Do MMMM, YYYY").format("MMM-DD-YYYY")
        exportInExcel(excelRows, fileName)
    }

    // --------------- Activity Trend related ----------------- //
    const showActivityTrend = (data) => {
        setShowActivityModal(true)
        let deviceSerial = data.serial_number
        setSmartBatteryForActivitySummary(deviceSerial)
        let dateFrom = moment().startOf('month').format("YYYY-MM-DD")
        if (moment().format("DD") !== '01') {
            let dateTo = moment().subtract(1, 'days').format("YYYY-MM-DD")
            setMonthOfActivitySummary(moment(dateFrom, "YYYY-MM-DD").format("MMMM, YYYY"))
            COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY(deviceSerial, {'date_from': dateFrom, 'date_to': dateTo})
        }
    }

    const hideActivityTrend = () => {
        setShowActivityModal(false)
        setViewRelatedValueOfTheCalendar(new Date())
        setSmartBatteryForActivitySummary('')
    }

    const onCalenderViewUpdate = (action, view, startDateOfTheCalendar) => {
        if (['next', 'prev', 'drillDown'].includes(action) && view === 'month') {
            let dateFrom = moment(startDateOfTheCalendar).format("YYYY-MM-DD")
            let dateTo = ''
            if (moment().format("MM") === moment(startDateOfTheCalendar).format("MM")) {
                // User has selected the current month
                dateTo = moment().subtract(1, 'days').format("YYYY-MM-DD")
                setCurrentMonthIsSelected(true)
            } else {
                dateTo = moment(startDateOfTheCalendar).endOf('month').format("YYYY-MM-DD")
                setCurrentMonthIsSelected(false)
            }
            if (moment().format("DD") !== '01') {
                setMonthOfActivitySummary(moment(dateFrom, "YYYY-MM-DD").format("MMMM, YYYY"))
                COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY(smartBatteryForActivitySummary, {
                    'date_from': dateFrom,
                    'date_to': dateTo
                })
            }
            setViewRelatedValueOfTheCalendar(startDateOfTheCalendar)
        } else if (view === 'year') {
            setViewRelatedValueOfTheCalendar(startDateOfTheCalendar)
        }
    }

    useEffect(() => {
        if (props.activities) {
            const activityInfo = props.activities
            setActiveDays(new Set(activityInfo.active_days))
            setInactiveDays(new Set(activityInfo.inactive_days))
            if (calendarRef.current) {
                setHeightOfTheCalendar(document.getElementsByClassName('calendar')[0].clientHeight)
                let daysText = activityInfo.active_days_total > 1 ? 'days' : 'day'
                let activeDaysWholeText = activityInfo.active_days_total > 0 || activityInfo.inactive_days_total > 0 ?
                    'Active for ' + activityInfo.active_days_total + ' ' + daysText : 'No data available'
                let actualHeader = document.getElementsByClassName('react-calendar__navigation__label__labelText')[0].innerText
                // As two API calls are happening due to usage of 'onViewChange' and 'onActiveStartDateChange'
                // Hence, we are preventing loading two p tags
                // We have tried to prevent it by excluding 'onViewChange' but it was generating an error (First month of a year selection was not being caught by 'onActiveStartDateChange')
                if (document.querySelector('span.react-calendar__navigation__label__labelText p') === null) {
                    document.getElementsByClassName('react-calendar__navigation__label__labelText')[0].innerHTML = '<p>' + actualHeader +
                        '</p>' + '<p style="font-size: 70% !important;">' + activeDaysWholeText + '</p>'
                }
                if (currentMonthIsSelected) {
                    let allWeekDaysElements = document.getElementsByClassName('react-calendar__month-view__days__day--weekend')
                    for (let i = 0; i < allWeekDaysElements.length; i++) {
                        if (allWeekDaysElements[i].hasAttribute('disabled')) {
                            let styleValues = allWeekDaysElements[i].getAttribute('style')
                            styleValues += 'color: #6d6d6d !important'
                            allWeekDaysElements[i].setAttribute('style', styleValues)
                        }
                    }
                }
            }
        }
    }, [props.activities])

    const currentDate = moment().format("YYYY-MM-DD")
    const renderActiveDaysDetailsModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideActivityTrend}
                centered={false}
                modal={showActivityModal}
                title={<>
                    <h1><strong>Smart Battery #{smartBatteryForActivitySummary} - Activity Trend</strong></h1>
                </>}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Stack justifyContent="center" alignItems="center" spacing={3}>
                            {props.collectingSmartBatteryActivityCollection ? <Stack
                                    style={{height: heightOfTheCalendar}} justifyContent="center" alignItems="center">
                                    <LoadingSpinner
                                        loadingSubText={'Active days of ' + monthOfActivitySummary}
                                        language={language}/>
                                </Stack> :
                                <div className={"calendar"}>
                                    <Calendar
                                        onChange={setCalenderValue}
                                        activeStartDate={viewRelatedValueOfTheCalendar}
                                        onActiveStartDateChange={(actionObject) => {
                                            onCalenderViewUpdate(actionObject.action, actionObject.view, actionObject.activeStartDate)
                                        }}
                                        onViewChange={(actionObject) => {
                                            onCalenderViewUpdate(actionObject.action, actionObject.view, actionObject.activeStartDate)
                                        }}
                                        value={value}
                                        tileClassName={({date, view}) => {
                                            if (view === 'month') {
                                                if (currentDate === moment(date).format("YYYY-MM-DD")) {
                                                    return 'bold'
                                                } else if (activeDays.has(moment(date).format("YYYY-MM-DD"))) {
                                                    return 'highlight'
                                                } else if (inactiveDays.has(moment(date).format("YYYY-MM-DD"))) {
                                                    return 'inActiveDays'
                                                }
                                            }
                                        }}
                                        maxDate={new Date()}
                                        nextLabel={<ArrowForwardIosIcon/>}
                                        prevLabel={<ArrowBackIosIcon/>}
                                        calendarType={"hebrew"}
                                        ref={calendarRef}
                                    />
                                    <Stack direction="row"
                                           justifyContent="center"
                                           alignItems="center"
                                           spacing={2}>
                                        <span style={{color: '#0b6e4f'}}><div className={'custom-box green'}></div>
                                            &nbsp;Rented</span>
                                        <span style={{color: '#cb152b'}}><div className={'custom-box red'}></div>
                                            &nbsp;Inactive</span>
                                    </Stack>
                                </div>}
                        </Stack>
                    </div>
                </div>
            </GenericModal>
        </>
    }

    useEffect(() => {
        if (props.errorMessageForSmartBatteryActivityCollection) {
            showNotifications('error', props.errorMessageForSmartBatteryActivityCollection)
        }
    }, [props.errorMessageForSmartBatteryActivityCollection])

    return (
        <>
            <div className={'row ml-1'}>{startOfDateRange} - {endOfDateRange}</div>
            <div className={'row ml-1'}><strong>Total Inactive Batteries: {totalInactiveBatteries}</strong></div>
            <div className={'row ml-1'}>
                <div className={'col-lg-12 p-0'}>
                    <DataTableContainer>
                        {!props.collectingInactiveBatteries && inactiveBatteries? <>
                            <DataTable
                                language={language}
                                noDataAvailableMessageInEnglish={'No inactive batteries to show'}
                                columns={inactiveBatteriesTable}
                                data={inactiveBatteries}
                                showToolbar={false}
                                asyncPagination={false}
                                pagination={true}
                                pageSize={5}/>
                        </> : <LoadingSpinner loadingSubText={'Collecting inactive battery information ..'} language={language}/>}
                    </DataTableContainer>
                </div>
            </div>
            <div className={'row ml-1 mr-1 mt-1 d-flex justify-content-between'}>
                <Button style={{backgroundColor: '#6c757d', border: 'none'}} size={'md'} onClick={hideModalEvent}>
                    Close
                </Button>
                <Button style={{backgroundColor: '#F18D00', border: 'none'}} size={'md'}
                        disabled={props.collectingInactiveBatteries || !inactiveBatteries} onClick={downloadInactiveBatteryInfo}>
                    Download
                </Button>
            </div>
            {renderActiveDaysDetailsModal()}
        </>
    );
};

InactiveBatteries.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        inactiveBatteries: state.rentLogReducer.inactiveBatteries,
        errorMessageForInactiveBatteryListCollection: state.rentLogReducer.errorMessageForInactiveBatteryListCollection,
        collectingInactiveBatteries: state.rentLogReducer.collectingInactiveBatteries,
        garages: state.rentLogReducer.garages,
        activities: state.rentLogReducer.activities,
        errorMessageForSmartBatteryActivityCollection: state.rentLogReducer.errorMessageForSmartBatteryActivityCollection,
        collectingSmartBatteryActivityCollection: state.rentLogReducer.collectingSmartBatteryActivityCollection
    }
}

export default connect(mapStateToProps, { ...actions})(withRoles(InactiveBatteries));