import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import {Controller, useForm} from 'react-hook-form';
import {Form, Button, Spinner, Badge, OverlayTrigger, Popover} from 'react-bootstrap';
import { SolInput, DataTableContainer, DataTable, SolTypography, LoadingSpinner } from '../../../../components';
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {Toolbar} from "../components";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/styles";
import Stack from '@mui/material/Stack';
import {GenericModal} from "../../../../components/genericModal/genericModal";
import UpdateDueLimitOfAGarage from "./dueLimitUpdate";
import Select from "react-select";
import moment from "moment/moment";
import {DayPickerRangeController} from "react-dates";
import {toBengaliNumber} from "bengali-number";
import {exportInExcel} from "../../../../utils/excelExport";

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});


const TransactionsOfAGarage = ({ data, dueLimitUpdateTracker, ...props }) => {
    const { control, register, setError, errors, setValue, clearErrors, handleSubmit } = useForm();
    const inputElement = React.useRef()
    const [language, setLanguage] = useState("EN");
    const classes = useStylesIconButton();
    const [garageGuid, setGarageGuid] = useState('');
    const [transactions, setTransactions] = useState([]);

    // Difference between 'garageBalance' and 'totalBalance' is that
    // garageBalance is used to show 'Current Balance' of the garage and totalBalance is used at the total section
    // Both are holding values from 'balance_total' of the API response, but garageBalance has not been updated when filter is applied
    const [garageBalance, setGarageBalance] = useState('');
    const [totalBalance, setTotalBalance] = useState('');

    const [totalDeduction, setTotalDeduction] = useState('');
    const [totalDeposit, setTotalDeposit] = useState('');
    const [pageSize, setPageSize] = useState(10);

    // Filter
    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);
    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const transactionTypes =[
        {
            label: 'All',
            value: 'all'
        },
        {
            label: 'Recharge',
            value: 'recharge'
        },
        {
            label: 'Cash',
            value: 'cash'
        }
    ]
    const [transactionType, setTransactionType] = useState();
    const [smartBattery, setSmartBattery] = useState();
    const [dueLimit, setDueLimit] = useState();
    const invalidSerial = language === 'EN'? 'Invalid ID!':'ভুল আইডি!'

    // Due limit update modal
    const [showDueLimitUpdateModal, setShowDueLimitUpdateModal] = useState(false);
    const [requiredInfoForUpdatingDueLimit, setRequiredInfoForUpdatingDueLimit] = useState('');

    const {
        COLLECT_TRANSACTIONS_OF_A_GARAGE,
        COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT
    } = props;

    useEffect(()=>{
        setLanguage(props.language)
        setPage(1)
        // TODO: Need to use a new action
        COLLECT_TRANSACTIONS_OF_A_GARAGE({'page': 1, 'garage_guid': data.pk, 'payment_for':'', 'page_size': pageSize})
        setGarageGuid(data.pk)
        setTransactionType(transactionTypes[0])
        setValue('transactionType', transactionTypes[0])
        setDueLimit(new Intl.NumberFormat('en-IN').format(data.total_due_limit))
        return ()=>{
            props.RETURN_TO_INITIAL_STATE_FOR_TRANSACTIONS_AND_ACTIVITIES_MODAL()
        }
    }, [])

    const TRANSACTIONS_COLUMNS = [
        {
            field: 'created_at',
            title: language === 'EN' ? 'Date' : 'তারিখ',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: 'nowrap'}}>{moment(rowData.created_at).format("MMM DD, YYYY hh:mm a")}</span>
                </>
            }
        },
        {
            field: 'title',
            title: language === 'EN' ? 'Title' : 'শিরোনাম',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                if (rowData.payment_for === 'recharge') {
                    return <>
                        <span style={{whiteSpace: 'nowrap'}}><strong>Recharge</strong></span><br/>
                        {/* <span style={{ whiteSpace: 'nowrap' }}>bKash Ref #{rowData.bkash_transaction_id}</span> */}
                        <span style={{ whiteSpace: 'nowrap' }}>bKash</span>
                    </>
                } else if (rowData.payment_for === 'rent') {
                    return <>
                        <span style={{whiteSpace: 'nowrap'}}><strong>Rented smart battery {rowData.rent_details? rowData.rent_details? rowData.rent_details.ev_details? rowData.rent_details.ev_details.device_sl? rowData.rent_details.ev_details.device_sl:'':'':'':''}</strong></span><br/>
                        <span style={{whiteSpace: 'nowrap'}}>Driver: {rowData.driver_name}</span>
                    </>
                }
            }
        },
        {
            field: 'deposit',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            title: language === 'EN' ? 'Deposit' : 'জমা',
            render: (rowData) => {
                return <>
                    <h4>{rowData.payment_for === 'recharge'? new Intl.NumberFormat('en-IN').format(rowData.pay_amount):''}</h4>
                </>
            },
        },
        {
            field: 'deduction',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            title: language === 'EN' ? 'Deduction' : 'কেটে নেওয়া হয়েছে',
            render: (rowData) => {
                return <>
                    <h4 style={{color: '#cb152b'}}>{rowData.payment_for === 'rent'? new Intl.NumberFormat('en-IN').format(rowData.pay_amount):''}</h4>
                </>
            },
        },
        {
            field: 'garage_balance',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            title: language === 'EN' ? 'Garage Balance (Tk)' : 'গ্যারেজের ব্যাল্যান্স (টাকায়)',
            render: (rowData) => {
                return <>
                    <h4>{new Intl.NumberFormat('en-IN').format(rowData.garage_balance)}</h4>
                </>
            },
        },
    ]

    useEffect(()=>{
        if (props.transactions) {
            const returnedResponse = props.transactions
            if (returnedResponse.balance_total || returnedResponse.balance_total === 0) {
                setTotalBalance(returnedResponse.balance_total)
                if (!filterApplied) {
                    setGarageBalance(returnedResponse.balance_total)
                }
            }
            if (returnedResponse.deduction_total || returnedResponse.deduction_total === 0) {
                setTotalDeduction(returnedResponse.deduction_total)
            }
            if (returnedResponse.recharge_total || returnedResponse.recharge_total === 0) {
                setTotalDeposit(returnedResponse.recharge_total)
            }
            const transactions = props.transactions.results
            if (transactions.length > -1) {
                let garage_balance = 0
                setTransactions(transactions.map((transaction) => {
                    if (transaction.payment_for === 'rent') {
                        garage_balance -= transaction.pay_amount
                    } else if (transaction.payment_for === 'recharge') {
                        garage_balance += transaction.pay_amount
                    }
                    return {
                        ...transaction,
                        garage_balance: garage_balance
                    }
                }))
            }
        }
    }, [props.transactions])

    // ------  Pagination  ------- //
    const onChangePage = (event, newPage) => {
        setPage(newPage)
        let filter = {'page': newPage, 'garage_guid': data.pk, 'page_size': pageSize}
        if (filterApplied) {
            if (smartBattery) {
                filter['device_sl'] = smartBattery
            }
            if (transactionType) {
                if (transactionType.value === 'all') {
                    filter['payment_for'] = ''
                } else if (transactionType.value === 'recharge') {
                    filter['payment_for'] = 'recharge'
                } else if (transactionType.value === 'cash') {
                    filter['payment_for'] = 'rent'
                }
            }
        }
        COLLECT_TRANSACTIONS_OF_A_GARAGE(filter)
    }
    // ------  Pagination  ------- //



    // ------ Top section related ----- //
    const renderTopSection = () => {
        return <>
            <Toolbar>
                <Toolbar.Title>
                    <Stack spacing={2}>
                        <span>Garage: {data.name}, {data.upazila}</span>
                        {garageBalance? <><span>Current Balance: Tk {new Intl.NumberFormat('en-IN').format(garageBalance)}</span></>:null}
                        <Stack direction={'row'} spacing={1}>
                            <span>Due Limit: Tk {dueLimit}</span>
                            <Button type={"button"} variant="outline-warning"
                                    size={'sm'} justifyContent={'flex-start'}
                                    onClick={() => updateDueLimit(data)}>Edit</Button>
                        </Stack>
                    </Stack>
                </Toolbar.Title>
                <Toolbar.ToolbarContainer>
                    <Toolbar.ToolbarContainer.Items>
                        <IconButton classes={classes} onClick={exportData}
                                    disabled={props.collectingTransactions || props.collectingTransactionsForExport}>
                            {props.collectingTransactionsForExport === true ? <Spinner animation={"grow"} variant={'warning'} size={"sm"}/>:
                                <img src={require('../../../../utils/asset/download-Button.svg')} alt="Download"/>}
                        </IconButton>
                    </Toolbar.ToolbarContainer.Items>
                </Toolbar.ToolbarContainer>
            </Toolbar>
        </>
    }

    const exportData = () => {
        let filter = {'garage_guid': data.pk}
        if (filterApplied) {
            if (smartBattery) {
                filter['device_sl'] = smartBattery
            }
            if (transactionType) {
                if (transactionType.value === 'all') {
                    filter['payment_for'] = ''
                } else if (transactionType.value === 'recharge') {
                    filter['payment_for'] = 'recharge'
                } else if (transactionType.value === 'cash') {
                    filter['payment_for'] = 'rent'
                }
            }
        }
        COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT(filter)
    }

    useEffect(()=>{
        if (props.transactionsForExport) {
            const transactions = props.transactionsForExport
            let presentTime = moment()
            let transactionsLength = transactions.length
            let totalTransactionText = language === 'EN'? "Number of Transactions: " + transactionsLength:"লেনদেনের সংখ্যা: " + toBengaliNumber(transactionsLength)
            let exportText = language === 'EN'? "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A"):"এক্সেল ফাইল ডাউনলোডের সময়: " + presentTime.format("Do MMMM,YYYY hh:mm A")
            let excelRows = [
                ["Transactions of " + data.name, "", "", "", "", "", ""],
                [totalTransactionText, "", "", "", "", "", ""],
                [exportText, "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", ""],
                ["Date", "Title", "Driver", "Deposit", "Deduction", "Garage Balance (Tk)"]
            ]
            if (transactions.length > 0) {
                if (filterApplied) {
                    if (transactionType) {
                        let text = 'Transaction Type: ' + transactionType.label
                        excelRows.splice(1, 0, [text, "", "", "", "", "", ""])
                    }
                    if (smartBattery) {
                        let text = 'Smart Battery: ' + smartBattery
                        excelRows.splice(1, 0, [text, "", "", "", "", "", ""])
                    }
                } else {
                    let totalBalanceText = 'Total Balance: Tk ' + new Intl.NumberFormat('en-IN').format(totalBalance)
                    excelRows.splice(2, 0, [totalBalanceText, "", "", "", "", "", ""])
                    let totalDeductionText = 'Total Deduction: Tk ' + new Intl.NumberFormat('en-IN').format(totalDeduction)
                    excelRows.splice(2, 0, [totalDeductionText, "", "", "", "", "", ""])
                    let totalDepositText = 'Total Deposit: Tk ' + new Intl.NumberFormat('en-IN').format(totalDeposit)
                    excelRows.splice(2, 0, [totalDepositText, "", "", "", "", "", ""])
                }
                let garageBalance = 0
                for (let i=0; i<transactionsLength; i++) {
                    let title = ''
                    let bKashRef = ''
                    let driver = ''
                    let deposit = ''
                    let deduction = ''
                    if (transactions[i].payment_for === 'recharge') {
                        title = 'Recharge'
                        bKashRef = transactions[i].bkash_transaction_id
                        deposit = new Intl.NumberFormat('en-IN').format(transactions[i].pay_amount)
                        garageBalance += transactions[i].pay_amount
                    } else if (transactions[i].payment_for === 'rent') {
                        title = 'Rented smart battery ' + transactions[i].rent_details.ev_details.device_sl
                        driver = transactions[i].driver_name
                        deduction = new Intl.NumberFormat('en-IN').format(transactions[i].pay_amount)
                        garageBalance -= transactions[i].pay_amount
                    }
                    excelRows.push(
                        [
                            moment(transactions[i].created_at).format("MMM DD, YYYY hh:mm a"),
                            title,
                            driver,
                            deposit,
                            deduction,
                            new Intl.NumberFormat('en-IN').format(garageBalance)
                        ])
                }
            } else {
                let noDataAvailableText =  "No data available"
                excelRows.push(["", "", "", noDataAvailableText, "", "", ""])
            }
            let fileName = 'transactions_of_a_garage_'
            exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
        }
    }, [props.transactionsForExport])
    // ------ Top section related ----- //

    // ------ Filter related ----- //
    const filterTransactionList = () => {
        let filter = {'page': 1, 'garage_guid': garageGuid}
        let makeApiCall = true
        if (smartBattery) {
            if ((/^(0|[1-9][0-9]*)$/i.test(smartBattery))) {
                filter['device_sl'] = smartBattery
            } else {
                makeApiCall = false
                setError("smart_battery_id", {"message": invalidSerial})
            }
        }
        if (transactionType) {
            if (transactionType.value === 'all') {
                filter['payment_for'] = ''
            } else if (transactionType.value === 'recharge') {
                filter['payment_for'] = 'recharge'
            } else if (transactionType.value === 'cash') {
                filter['payment_for'] = 'rent'
            }
        }
        if (!smartBattery && transactionType.value === 'all') {
            setFilterApplied(false)
        } else {
            setFilterApplied(true)
        }
        if (makeApiCall) {
            COLLECT_TRANSACTIONS_OF_A_GARAGE(filter)
        }
    }

    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const onDatesChange = ({startDate, endDate}) => {
        // TODO: Need to add behavior similar to the summary page
        setStartDate(startDate)
        setEndDate(endDate)
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start}
                    endDate={end}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    )

    const renderFilterForm = () => {
        return <>
            <Form onSubmit={handleSubmit(filterTransactionList)}>
                <div className={'row g-3'}>
                    {/*<div className={'col-md-3'}>*/}
                    {/*<Form.Group>*/}
                    {/*    <Form.Label>Transaction date</Form.Label>*/}
                    {/*</Form.Group>*/}
                    {/*<OverlayTrigger rootClose trigger="click" placement="bottom-start"*/}
                    {/*                overlay={popover}>*/}
                    {/*    <SolInput*/}
                    {/*        name={'dateRangePickerInput'}*/}
                    {/*        readOnly={true}*/}
                    {/*        value={dateRange}*/}
                    {/*        placeholder={language === 'EN'? 'Select a date range':'তারিখের সীমা বাছাই করুন'}*/}
                    {/*    />*/}
                    {/*</OverlayTrigger>*/}
                    {/*</div>*/}
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <Form.Label>Smart Battery ID</Form.Label>
                            <SolInput
                                type={"text"}
                                name={"smart_battery_id"}
                                placeholder={"Type Smart Battery ID..."}
                                autoComplete={"off"}
                                ref={register({
                                    validate: {
                                        isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || invalidSerial
                                    }
                                })}
                                onChange={(e) => {
                                    setSmartBattery(e.target.value)
                                    if (errors) {
                                        clearErrors()
                                    }
                                }}
                            />
                            {errors.smart_battery_id && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.smart_battery_id.message}</div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <Form.Group>
                                <Form.Label>Transaction Type</Form.Label>
                                <Controller
                                    control={control}
                                    name={"transactionType"}
                                    render={( { onChange, onBlur, value, name, ref },
                                              { invalid, isTouched, isDirty }) => (
                                        <Select
                                            placeholder={'Select Type ...'}
                                            classNamePrefix="react-select-sol-style"
                                            maxMenuHeight={200}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            value={transactionType}
                                            options={transactionTypes}
                                            isSearchable={true}
                                            onChange={(selected, {action}) => {
                                                if (action === "clear") {
                                                    setValue('transactionType', "")
                                                    setTransactionType("")
                                                }
                                                onChange(selected)
                                                if (selected) {
                                                    setValue('transactionType', selected)
                                                    setTransactionType(selected)
                                                } else {
                                                    setValue('transactionType', "")
                                                    setTransactionType("")
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Form.Group>
                    </div>
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <Button variant="outline-warning" size={'md'} type="button" disabled={props.collectingTransactions}
                                    className={"mt-8"} onClick={filterTransactionList}>
                                {language === 'EN'? 'Apply filter':'ফিল্টার করুন'}
                            </Button>
                        </Form.Group>
                    </div>
                </div>
            </Form>
        </>
    }
    // ------ Filter related ----- //

    // ------ Due Limit update Modal ----- //
    const updateDueLimit = (data) => {
        setShowDueLimitUpdateModal(true)
        setRequiredInfoForUpdatingDueLimit(data)
    }

    const hideDueLimitModal = (data) => {
        setShowDueLimitUpdateModal(false)
    }

    const updateDueLimitRepresentation = (data) => {
        setDueLimit(new Intl.NumberFormat('en-IN').format(data))
        dueLimitUpdateTracker(true)
    }

    const renderDueLimitUpdateModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideDueLimitModal}
                centered={true}
                modal={showDueLimitUpdateModal}
                title={<h1><strong>Edit Due Limit</strong></h1>}>
                <UpdateDueLimitOfAGarage data={requiredInfoForUpdatingDueLimit} updateDueLimitRepresentation={updateDueLimitRepresentation}/>
            </GenericModal>
        </>
    }
    // ------ Due Limit update Modal ----- //

    // ------ Total information section ------ //
    const renderTotalInformationSection = () => {
        return <>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={5}
                style={{color: "inherit"}}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={0}
                >
                    <span>TOTAL DEPOSIT</span>
                    <span><strong>{totalDeposit || totalDeposit === 0? 'TK ' + new Intl.NumberFormat('en-IN').format(totalDeposit):'N/A'}</strong></span>
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={0}
                >
                    <span>TOTAL DEDUCTION</span>
                    <span><strong>{totalDeduction || totalDeduction === 0? 'TK ' + new Intl.NumberFormat('en-IN').format(totalDeduction):'N/A'}</strong></span>
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={0}
                >
                    <span>TOTAL BALANCE</span>
                    <span><strong>{totalBalance || totalBalance === 0? 'TK ' + new Intl.NumberFormat('en-IN').format(totalBalance):'N/A'}</strong></span>
                </Stack>
            </Stack>
        </>
    }
    // ------ Total information section ------ //

    return (
        <div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    {renderTopSection()}
                </div>
            </div>
            <div className={'row mt-5'}>
                <div className={'col-md-12'}>
                    <Form onSubmit={handleSubmit(filterTransactionList)}>
                        {renderFilterForm()}
                    </Form>
                </div>
            </div>
            {!filterApplied && !props.collectingTransactions && transactions.length > -1? <>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        {renderTotalInformationSection()}
                    </div>
                </div>
            </>:null}
            <div className={'row'}>
                <div className={'col-md-12'}>
                    {!props.collectingTransactions? <>
                        <DataTable
                            language={language}
                            columns={TRANSACTIONS_COLUMNS}
                            data={transactions}
                            showToolbar={false}
                            asyncPagination={true}
                            isLoading={props.tableLoading}
                            pagination={true}
                            count={props.transactions?.count}
                            itemsPerPage={props.transactions?.page_size}
                            onChangePage={onChangePage}
                            page={page}
                        />
                    </>:<LoadingSpinner
                        loadingSubText={language === 'EN' ? 'Collecting transactions ..' : 'লেনদেনের তালিকা প্রস্তুত হচ্ছে ..'}
                        language={language} />}
                </div>
            </div>
            {renderDueLimitUpdateModal()}
        </div>
    );
};

TransactionsOfAGarage.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        providedSmartBatteries: state.borrowersReducer.providedSmartBatteries,
        smartBatteryInfoCollectionInProgress: state.borrowersReducer.smartBatteryInfoCollectionInProgress,
        transactions: state.borrowersReducer.transactions,
        collectingTransactions: state.borrowersReducer.collectingTransactions,
        transactionsForExport: state.borrowersReducer.transactionsForExport,
        collectingTransactionsForExport: state.borrowersReducer.collectingTransactionsForExport
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(TransactionsOfAGarage));