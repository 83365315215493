import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import {actions} from "../actions";
import {firebaseSignout} from "../../auth/firebase_v9"
import { toast } from "react-toastify";
import { object } from "prop-types";
// import * as auth from "../_redux/authRedux";

class Logout extends Component {
  componentDidMount() {
    
    if (this.props.user.google_login){
      return firebaseSignout().then((res) => {
        console.log("Logged out in firebase")
        this.props.LOGOUT();
      }).catch(() => {
        toast.error("Could not log out")
      })
    }

    this.props.LOGOUT();


  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.access_token), user: auth.user }),
  actions
)(Logout);
