import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
};

/*Reducer for Grid's redux store*/
export const organizationsReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_ORG_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                orgList: undefined,
                statusCode: undefined
            };

        case actionTypes.GET_ORG_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                orgList: action.payload
            };

        case actionTypes.GET_ORG_LIST_FAILURE:
            return {
                ...state,
                listLoading: false,
                statusCode: undefined
            };

        default:
            return state;
    }
};
