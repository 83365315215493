import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
    successMessage: undefined,
    errorMessage: undefined,
    successMessageForUpdate: undefined,
    errorMessageForUpdate: undefined,
    firmwares: undefined,
    hardwares: undefined,
    dongleCreated: undefined,
    dongleUpdated: undefined,
    bulkSmartDongleUpdated: undefined,
    successMessageForBulkSmartDongleUpdate: undefined,
    errorMessageForBulkSmartDongleUpdate: undefined,
    serialNumberGenerated: undefined,
    successMessageForSerialNumberGeneration: undefined,
    errorMessageForSerialNumberGeneration: undefined,
    changeHistories: undefined,
    organizationalAccessIsUpdated: undefined,
    successMessageForOrganizationalAccessUpdateProcess: undefined,
    errorMessageForOrganizationalAccessUpdateProcess: undefined
};

/*Reducer for Device's redux store*/
export const smartDongleReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_SMART_DONGLE_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                smartDongleList: undefined
            };
        case actionTypes.GET_SMART_DONGLE_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                smartDongleList: action.payload
            };
        case actionTypes.GET_SMART_DONGLE_LIST_FAILURE:
            toast.error("Can't load the device list! Please contact with admin.");
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.GET_FIRMWARE_LIST_REQUEST:
            return {
                ...state,
                firmwareListLoading: true
            };
        case actionTypes.GET_FIRMWARE_LIST_SUCCESS:
            return {
                ...state,
                firmwareListLoading: false,
                firmwares: action.payload
            };
        case actionTypes.GET_FIRMWARE_LIST_FAILURE:
            toast.error("Failed to collect the firmware list!");
            return {
                ...state,
                firmwareListLoading: false
            };

        case actionTypes.GET_HARDWARE_LIST_REQUEST:
            return {
                ...state,
                hardwareListLoading: true
            };
        case actionTypes.GET_HARDWARE_LIST_SUCCESS:
            return {
                ...state,
                hardwareListLoading: false,
                hardwares: action.payload
            };
        case actionTypes.GET_HARDWARE_LIST_FAILURE:
            toast.error("Failed to collect the firmware list!");
            return {
                ...state,
                hardwareListLoading: false
            };

            //post Data
        case actionTypes.ADD_DONGLE_REQUEST:
            return {
                ...state,
                dongleCreated: undefined,
                dongleInfoSubmitting: true
            };
        case actionTypes.ADD_DONGLE_SUCCESS:
            return {
                ...state,
                dongleCreated: requestCycle.success,
                successMessage: action.payload,
                dongleInfoSubmitting: false
            };
        case actionTypes.ADD_DONGLE_FAILURE:
            return {
                ...state,
                dongleCreated: requestCycle.failed,
                errorMessage: action.payload.error,
                dongleInfoSubmitting: false
            };

        case actionTypes.UPDATE_DONGLE_REQUEST:
            return {
                ...state,
                dongleUpdated: undefined,
                dongleInfoSubmitting: true
            };
        case actionTypes.UPDATE_DONGLE_SUCCESS:
            return {
                ...state,
                dongleUpdated: requestCycle.success,
                successMessageForUpdate: action.payload,
                dongleInfoSubmitting: false
            };
        case actionTypes.UPDATE_DONGLE_FAILURE:
            return {
                ...state,
                dongleUpdated: requestCycle.failed,
                errorMessageForUpdate: action.payload.error,
                dongleInfoSubmitting: false
            };

        case actionTypes.UPDATE_BULK_SMART_DONGLE_INFO_REQUEST:
            return {
                ...state,
                bulkSmartDongleUpdated: undefined,
                bulkSmartDongleInfoSubmitting: true
            };
        case actionTypes.UPDATE_BULK_SMART_DONGLE_INFO_SUCCESS:
            return {
                ...state,
                bulkSmartDongleUpdated: requestCycle.success,
                successMessageForBulkSmartDongleUpdate: action.payload,
                bulkSmartDongleInfoSubmitting: false
            };
        case actionTypes.UPDATE_BULK_SMART_DONGLE_INFO_FAILURE:
            return {
                ...state,
                bulkSmartDongleUpdated: requestCycle.failed,
                errorMessageForBulkSmartDongleUpdate: action.payload.error,
                bulkSmartDongleInfoSubmitting: false
            };

        case actionTypes.GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES_REQUEST:
            return {
                ...state,
                serialNumberGenerated: undefined,
                successMessageForSerialNumberGeneration: undefined,
                errorMessageForSerialNumberGeneration: undefined,
                numberGenerationRelatedInfoSubmitting: true
            };
        case actionTypes.GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES_SUCCESS:
            return {
                ...state,
                serialNumberGenerated: requestCycle.success,
                successMessageForSerialNumberGeneration: action.payload,
                numberGenerationRelatedInfoSubmitting: false
            };
        case actionTypes.GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES_FAILURE:
            return {
                ...state,
                serialNumberGenerated: requestCycle.failed,
                errorMessageForSerialNumberGeneration: action.payload.error,
                numberGenerationRelatedInfoSubmitting: false
            };

        case actionTypes.COLLECT_CHANGE_HISTORY_REQUEST:
            return {
                ...state,
                collectingChangeHistory: true,
                changeHistories: undefined
            };
        case actionTypes.COLLECT_CHANGE_HISTORY_SUCCESS:
            return {
                ...state,
                collectingChangeHistory: false,
                changeHistories: action.payload
            };
        case actionTypes.COLLECT_CHANGE_HISTORY_FAILURE:
            toast.error("Failed to collect change history!");
            return {
                ...state,
                collectingChangeHistory: false
            };

        case actionTypes.UPDATE_ORGANIZATIONAL_ACCESS_REQUEST:
            return {
                ...state,
                updatingOrganizationalAccess: true,
                organizationalAccessIsUpdated: undefined
            };
        case actionTypes.UPDATE_ORGANIZATIONAL_ACCESS_SUCCESS:
            return {
                ...state,
                updatingOrganizationalAccess: false,
                organizationalAccessIsUpdated: requestCycle.success,
                successMessageForOrganizationalAccessUpdateProcess: action.payload
            };
        case actionTypes.UPDATE_ORGANIZATIONAL_ACCESS_FAILURE:
            return {
                ...state,
                updatingOrganizationalAccess: false,
                organizationalAccessIsUpdated: requestCycle.failed,
                errorMessageForOrganizationalAccessUpdateProcess: action.payload.error
            };

        case actionTypes.CLEAN_UP_STATES_RELATED_TO_ACCESS_MANAGEMENT_MODAL:
            return {
                ...state,
                changeHistories: undefined,
                organizationalAccessIsUpdated: undefined,
                successMessageForOrganizationalAccessUpdateProcess: undefined,
                errorMessageForOrganizationalAccessUpdateProcess: undefined
            }

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
