import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import VerifiedIcon from '@mui/icons-material/Verified';
import PendingIcon from '@mui/icons-material/Pending';
import {theme, useStylesForDatePicker} from "../../../utils/muiPickerCustomStyle";
import 'moment/locale/bn';
import 'moment/locale/en-gb';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContent from "react-bootstrap/TabContent";

import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {manualTransactionActions} from "../actions";
import ApprovedTransactions from './approvedTransactions'
import PendingTransactions from './pendingTransactions'
import {useSubheader} from "../../../../_metronic/layout";
import {Button} from "react-bootstrap";
import {checkAuthorization} from "../../auth/authorization";
import {getServiceName} from "../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../utils/roleRelatedValues/actionNames";



const ManualTransactions = props => {
    const classes = useStylesForDatePicker();
    const [language, setLanguage] = useState(null);
    const [key, setKey] = useState("approved");
    const subheader= useSubheader();

    const transactionServiceName = getServiceName('transactionService')
    const manualTransactionFeatureName = getFeatureName('manualTransactionFeature')
    const listActionName  = getFeatureName('listAction')
    const createActionName = getActionName('createAction')
    const updateActionName = getActionName('updateAction')

    const {
        RETURN_TO_INITIAL,
        FETCH_MANUAL_TRANSACTION_BATCH
    } = props;

    const toggleTransactionProfileTab = (key) => {
        setKey(key);
    }

    const uploadTransactions = () => {
        props.history.push('/transactions/create')
    }

    useEffect(()=>{
        setLanguage(props.language)
        if (checkAuthorization(props.authorization, transactionServiceName, manualTransactionFeatureName, [createActionName])) {
            subheader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={uploadTransactions} classes={classes} className={'ml-1'}>
                        <i className="fa fa-file-upload"/>{props.language === 'EN'? 'Upload transactions': 'লেনদেনের তথ্য জমা দিন'}
                    </Button>
                </div>
            )
        } else {
            subheader.setActionButtons(null)
        }

        return ()=>{
            RETURN_TO_INITIAL()
        }
    },[])

    return (
        <>
            {checkAuthorization(props.authorization, transactionServiceName, manualTransactionFeatureName, [updateActionName])? <>
                <ContentWrapper isLoading={false} pageTitle={"Manual transactions"} showBackButton={false} showCardHeader={false}>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(key) => toggleTransactionProfileTab(key)}
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >
                        <Tab
                            eventKey="approved"
                            title={
                                <React.Fragment>
                                    <VerifiedIcon style={{color: key === "approved"? "#F18D00":"#00FF00"}}/>
                                    <span style={{color: key === "approved"? "#F18D00":"#000000"}}>{language === 'EN'? 'Approved':'অনুমোদিত'}</span>
                                </React.Fragment>}
                            tabClassName={key === "approved" ? "approved-transactions" : ""}

                        >
                            <TabContent bsPrefix="approved-transactions">
                                <ApprovedTransactions {...props}></ApprovedTransactions>
                            </TabContent>
                        </Tab>
                        <Tab
                            eventKey="pending"
                            title={
                                <React.Fragment>
                                    <PendingIcon style={{color: key === "pending"? "#F18D00":"#FF0000"}}/>
                                    <span style={{color: key === "pending"? "#F18D00":"#000000"}}>{language === 'EN'? 'Pending': 'অননুমোদিত'}</span>
                                </React.Fragment>}
                            tabClassName={
                                key === "pending" ? "pending-transactions" : ""}
                        >
                            <TabContent bsPrefix="pending-transactions">
                                <PendingTransactions {...props}></PendingTransactions>
                            </TabContent>
                        </Tab>
                    </Tabs>
                </ContentWrapper>
            </>:<>
                <ApprovedTransactions {...props}></ApprovedTransactions>
            </>}
        </>
    );
};

ManualTransactions.propTypes = {
    
};

export default connect((state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.manualTransactionsReducer.isLoading,
        eventList: state.manualTransactionsReducer.events,
        listLoading: state.manualTransactionsReducer.listLoading,
        iconLoading: state.manualTransactionsReducer.iconLoading,
        payments: state.manualTransactionsReducer.payments
    }
}, {...manualTransactionActions}) (ManualTransactions);
