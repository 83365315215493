import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {reduxBatch} from "@manaflair/redux-batch";
import {persistStore} from "redux-persist";
import {rootReducer} from "./rootReducer";
import http from '../utils/http';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import {mySaga} from '../features/auth/reducer'
// import refreshMiddleware from "../utils/refreshMiddleware";
const sagaMiddleware = createSagaMiddleware();
const middleware = process.env.REACT_APP_APP_ENV!=='prod'? [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  }),
  http,
  sagaMiddleware,
  createLogger() ] : [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  }),
  http,
  sagaMiddleware
]

const store = configureStore({
  reducer: rootReducer,
  middleware,
  enhancers: [reduxBatch],
  devTools: false,
});

sagaMiddleware.run(mySaga)
/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

export default store;
