import { utils, writeFileXLSX } from 'xlsx'

/**
 * Export data in excel format
 * @param {array} data - Data to be exported
 * @param {string} exportedFileName - Name of the exported excel file
 */
export function exportInExcel (data, exportedFileName) {
    const workbook = utils.book_new()
    const worksheet = utils.aoa_to_sheet(data)
    utils.book_append_sheet(workbook, worksheet, "Emobility")
    writeFileXLSX(workbook, exportedFileName + ".xlsx")
}
