export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',
    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',

    GET_HISTORICAL_DATA_REQUEST: 'GET_HISTORICAL_DATA_REQUEST',
    GET_HISTORICAL_DATA_SUCCESS: 'GET_HISTORICAL_DATA_SUCCESS',
    GET_HISTORICAL_DATA_FAILURE: 'GET_HISTORICAL_DATA_FAILURE',

    COLLECT_CODE_DESCRIPTION_REQUEST: 'COLLECT_CODE_DESCRIPTION_REQUEST',
    COLLECT_CODE_DESCRIPTION_SUCCESS: 'COLLECT_CODE_DESCRIPTION_SUCCESS',
    COLLECT_CODE_DESCRIPTION_FAILURE: 'COLLECT_CODE_DESCRIPTION_FAILURE'
};
