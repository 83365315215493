import React, {useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../components/dataTable/DataTable";
import {Filter, Toolbar} from "./components";
import {connect} from "react-redux";
import {actions} from "../actions";
import {Button, Form, Spinner, OverlayTrigger, Popover, ListGroup} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {SolInput} from "../../../components";
import {makeStyles, ThemeProvider} from "@material-ui/styles";
import {DataTableContainer} from '../utils'
import {LoadingSpinner} from '../../../components';
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../_metronic/layout";
import {withRoles} from "../../../router/SecuredRoute";
import Select from "react-select";
import {GenericModal} from "../../../components/genericModal/genericModal";
import moment from "moment";
import {toast} from "react-toastify";
import {DayPickerRangeController} from "react-dates";
import {exportInExcel} from "../../../utils/excelExport";
import {convertToAnotherLang} from "../../../utils/convertLanguageOfNumbersWithCharacter";
import {toBengaliNumber} from "bengali-number";
import {isNullorUndefined, validateValue} from "../../../utils/utilityFunctions";
import {JsonToUriSerialize, parseParams, processFilterParams} from "../../../utils/utilityFunctions";
import {Controller, useForm} from "react-hook-form";
import Stack from '@mui/material/Stack';
import Calendar from 'react-calendar';
import './Calendar_custom.css';



const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
    highlight: {
        color: "red"
    }
});

const SummaryReports = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const [showFilter, setShowFilter] = useState(true);
    const [requiredErrorMessage, setRequiredErrorMessage] = useState(false);
    const [totalCollectedAmount, setTotalCollectedAmount] = useState(0);
    const [urlIsEdited, setUrlIsEdited] = useState(false);

    const [garages, setGarages] = useState([]);
    const [garage, setGarage] = useState("");
    const [garageFromUrl, setGarageFromUrl] = useState("");
    const [rentStatus, setRentStatus] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const subHeader = useSubheader();


    // Garage details modal
    const [showGarageDetailsModal, setShowGarageDetailsModal] = useState(false);

    // Last rent details modal
    const [showLastRentDetailsModal, setShowLastRentDetailsModal] = useState(false);

    // Inactive days details modal
    const [showInactiveDaysDetailsModal, setShowInactiveDaysDetailsModal] = useState(false);

    const [totalPayable, setTotalPayable] = useState(['1,000']); // TODO: Added for test
    const [rentList, setRentList] = useState([{}]); // TODO: {} added for test
    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [authorization, setAuthorization] = useState(null);
    const [language, setLanguage] = useState(null);
    const [queryParams, setQueryParams] = useState(null);
    const [initialfilterParam, setInitialfilterParam] = useState(null);
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null);

    const invalidSerial = language === 'EN'? 'Invalid ID!':'ভুল আইডি!'
    const invalidDriver = language === 'EN'? 'Invalid name!':'ভুল নাম!'

    const pageTitle = language === 'EN'? 'Summary':'সারাংশ'
    const representRentStatus = (language, status) => {
        let represent = ''
        if (status === 'active') {
            represent = language === 'EN'? 'Active':'সক্রিয়'
        } else {
            represent = language === 'EN'? 'Completed':'শেষ হয়েছে'
        }
        return represent
    }

    const representPaymentRentStatus = (language, status) => {
        let represent = ''
        if (status === 'due') {
            represent = language === 'EN'? 'Due':'বাকি রয়েছে'
        } else {
            represent = language === 'EN'? 'Paid':'শোধ হয়েছে'
        }
        return represent
    }

    const column1 = language === 'EN'? 'Rent ID': 'ভাড়ার আইডি'
    const column2 = language === 'EN'? 'Smart Battery': 'স্মার্ট ব্যাটারী'
    const column3 = language === 'EN'? 'Garage': 'গ্যারেজ'
    const column4 = language === 'EN'? 'Last Rent': 'সর্বশেষ ভাড়া'
    const column5 = language === 'EN'? '# of Rents': 'ভাড়ার সংখ্যা'
    const column6 = language === 'EN'? 'Inactive Days': 'নিষ্ক্রিয় দিনের সংখ্যা'
    const column7 = language === 'EN'? 'KM Driven (Estimated)': 'কিমি গিয়েছে (আনুমানিক)'
    const column8 = language === 'EN'? 'Per rent payable(Tk)': 'প্রতি ভাড়ায় প্রাপ্য (টাকা)'
    // TODO: Add another column
    const column9 = language === 'EN'? 'Rent Payable by Garage/Dealer (Tk)': 'গ্যারেজ/ডিলার এর নিকট হতে প্রাপ্য'

    const rentFee = language === 'EN'? 'Rent Fee': 'রেন্ট ফী'
    const garageName = language === 'EN'? 'Garage': 'গ্যারেজ'
    const driverPhone = language === 'EN'? 'Phone': 'ফোন'
    const evName = language === 'EN'? 'EV': 'ইভি'
    const deviceName = language === 'EN'? 'Device': 'যন্ত্র'
    const dongleName = language === 'EN'? 'Dongle': 'ডঙ্গল'
    const batteryName = language === 'EN'? 'Battery': 'ব্যাটারী'
    const batteryStartChange = language === 'EN'? 'Battery Start Charge': 'শুরু ব্যাটারী চার্জ'
    const batteryEndChange = language === 'EN'? 'Battery End Charge': 'শেষ ব্যাটারী চার্জ'
    const batteryStartVoltage = language === 'EN'? 'Battery Start Voltage': 'শুরু ব্যাটারী ভোল্টেজ'
    const batteryEndVoltage = language === 'EN'? 'Battery End Voltage': 'শেষ ব্যাটারী ভোল্টেজ'

    // TODO: Update the following columns
    const rentListColumns =  [
        {
            field: 'battery_details',
            title: column2,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                return   <>
                    <h6><strong>5</strong></h6>
                    <span style={{whiteSpace: 'nowrap'}}>SOLdongle #44000014</span><br/>
                    <span style={{whiteSpace: 'nowrap'}}>Battery #55000014</span><br/>
                </>
            }
        },
        {
            field: 'garage_details',
            title: column3,
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    {/* TODO: Need to use something instead of Link (customized css?), as 'to' is a mandatory prop for Link*/}
                    {/* TODO: 'SolTypography.SubTitle primary' has been used in Smart Battery */}
                    <Link style={{color: "#F18D00"}} onClick={() => showGarageDetails(rowData)}>
                        Rupom Garage
                    </Link><br/>
                    <span>Tongi</span>
                </>
            }
        },
        {
            field: 'last_rent',
            title: column4,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    {/* TODO: Need to use something instead of Link (customized css?), as 'to' is a mandatory prop for Link*/}
                    {/* TODO: 'SolTypography.SubTitle primary' has been used in Smart Battery */}
                    <Link style={{color: "#F18D00", whiteSpace: 'nowrap'}} onClick={() => showLastRentDetails(rowData)}>
                        Sep 25, 2022 9:23am
                    </Link><br/>
                    <span style={{whiteSpace: 'nowrap'}}>by Sabbir Ahmed</span><br/>
                </>
            }
        },
        {
            field: 'rent_days',
            title: column5,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            disableClick: true,
            render: (rowData) => {
                return   <>
                    <h2><strong>05</strong></h2>
                </>
            }
        },
        {
            field: 'inactive_days',
            title: column6,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            disableClick: true,
            render: (rowData) => {
                return   <>
                    {/* TODO: Need to use something instead of Link (customized css?), as 'to' is a mandatory prop for Link*/}
                    {/* TODO: 'SolTypography.SubTitle primary' has been used in Smart Battery */}
                    <Link style={{color: "#F18D00"}} onClick={() => inActiveDaysDetails(rowData)}>
                        <h2><strong>02</strong></h2>
                    </Link>
                </>
            }
        },
        {
            field: 'km_driven',
            title: column7,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            disableClick: true,
            render: (rowData)=> {
                return <>
                    <h4>2,300</h4>
                </>
            }
        },
        {
            field: 'per_rent_payable',
            title: column8,
            disableClick: true,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=> {
                return <>
                    <h4>100</h4>
                </>
            }
        },
        {
            field: 'per_rent_payable_by_garage_dealer',
            title: column9,
            disableClick: true,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=> {
                return <>
                    <h4>500</h4>
                </>
            }
        }
    ]

    const {
        COLLECT_SUMMARY_INFO,
        COLLECT_SUMMARY_INFO_TO_EXPORT,
        COLLECT_GARAGE_LIST
    } = props;
    const classes = useStylesIconButton();

    const collectThirtyDaysData = () => {
        setRentStatus("active")
        setPage(1)
        let searchText = 'page=1'
        let startOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD')
        let endOfCurrentMonth = moment().endOf('month').format('YYYY-MM-DD')
        setStartDate(moment(startOfCurrentMonth))
        setEndDate(moment(endOfCurrentMonth))
        setCalenderValue(moment(startOfCurrentMonth).toDate())
        let from_date = props.language === 'EN'? startOfCurrentMonth:convertToAnotherLang(startOfCurrentMonth)
        let to_date = props.language === 'EN'? endOfCurrentMonth:convertToAnotherLang(endOfCurrentMonth)
        searchText += '&from_date=' + from_date + '&to_date=' + to_date
        setFilterApplied(true)
        props.history.push({
            search: searchText
        })
    }

    useEffect(() => {
        setAuthorization(props.authorization)
        setLanguage(props.language)
        subHeader.setActionButtons(null)
        if (!props.history.location.search) {
            collectThirtyDaysData()
        }
        COLLECT_GARAGE_LIST()
        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
        }
    }, []);

    const showGarageDetails = (data) => {
        setShowGarageDetailsModal(true)
    }

    const showLastRentDetails = (data) => {
        setShowLastRentDetailsModal(true)
    }

    const inActiveDaysDetails = (data) => {
        setShowInactiveDaysDetailsModal(true)
    }

    const hideGarageDetailsModal = () => {
        setShowGarageDetailsModal(false)
    }

    const hideLastRentDetailsModal = () => {
        setShowLastRentDetailsModal(false)
    }

    const hideInactiveDaysDetailsModal = () => {
        setShowInactiveDaysDetailsModal(false)
    }

    const resetFilterValues = () => {
        setValue('garage', '')
        setStartDate(null)
        setEndDate(null)
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        if (props.driverListForExport) {
            let rents = props.driverListForExport.results
            let presentTime = moment()
            // let totalPayment = language === 'EN'? totalCollectedAmount:convertToAnotherLang(totalCollectedAmount, 'BN')
            let totalRentText = language === 'EN'? "Total rent: " + rents.length:"মোট ভাড়া দেওয়া হয়েছে: " + toBengaliNumber(rents.length) + " বার"
            // let totalPaidText = language === 'EN'? "Total Paid: " + totalPayment + " BDT":"সর্বমোট প্রদানকৃত: " + totalPayment + " টাকা"
            let exportText = language === 'EN'? "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A"):"এক্সেল ফাইল ডাউনলোডের সময়: " + presentTime.format("Do MMMM,YYYY hh:mm A")
            let dongleColumn = language === 'EN'? 'SOLdongle':'ডঙ্গল'
            let batteryColumn = language === 'EN'? 'Battery':'ব্যাটারী'
            let garageColumn = language === 'EN'? 'Garage':'গ্যারেজ'
            let garageAddressColumn = language === 'EN'? 'Garage address':'গ্যারেজের ঠিকানা'
            let rentStartTimeSocColumn = language === 'EN'? 'Rent start time SOC(%)':'ভাড়া দেওয়ার সময় SOC(%)'
            let rentStartTimeVoltageColumn = language === 'EN'? 'Rent start time voltage(v)':'ভাড়া দেওয়ার সময় ভোল্টেজ(v)'
            let rentEndedByColumn = language === 'EN'? 'Rent Ended by':'ভাড়া বন্ধ করেছেন'
            let rentEndTimeSocColumn = language === 'EN'? 'Rent end time SOC(%)':'ভাড়া বন্ধ করার সময় SOC(%)'
            let rentEndTimeVoltageColumn = language === 'EN'? 'Rent end time voltage(v)':'ভাড়া বন্ধ করার সময় ভোল্টেজ(v)'
            let paymentStatusColumn = language === 'EN'? 'Payment status':'পেমেন্টের অবস্থা'
            let excelRows = [
                [pageTitle, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                [totalRentText, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                [exportText, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                [column1, column8, column2, dongleColumn, batteryColumn, column3, garageColumn, garageAddressColumn,
                    column4, rentStartTimeSocColumn, rentStartTimeVoltageColumn, column5, column6, rentEndedByColumn,
                    rentEndTimeSocColumn, rentEndTimeVoltageColumn, paymentStatusColumn]
            ]
            if (filterApplied) {
                if (start && end) {
                    let from_date = language === 'EN'? moment(start).format("MMM DD, YYYY"):convertToAnotherLang(moment(start).format("MMM DD, YYYY"))
                    let to_date = language === 'EN'? moment(end).format("MMM DD, YYYY"):convertToAnotherLang(moment(end).format("MMM DD, YYYY"))
                    let text = "From " + from_date + " To " + to_date
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filterParamForApiCall.garage_phone) {
                    let text = "Garage contact number: " + filterParamForApiCall.garage_phone
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
                }
            }
            let totalActive = 0
            let totalCompleted = 0

            for (let i=0; i<rents.length; i++) {
                let status = representRentStatus(language, rents[i].status)
                rents[i].status === 'active'? totalActive += 1:totalCompleted += 1
                // let start_time = moment(rentLog[i].start_time).format("DD MMM,YYYY hh:mm A")
                // let end_time = rentLog[i].end_time? moment(rentLog[i].end_time).format("DD MMM,YYYY hh:mm A"):"-"

                excelRows.push(
                    [
                        rents[i].rent_id,
                        status,
                        rents[i].ev_details && rents[i].ev_details.device_sl? rents[i].ev_details.device_sl:'',
                        rents[i].dongle_details && rents[i].dongle_details.serial_number? rents[i].dongle_details.serial_number:'',
                        rents[i].battery_details && rents[i].battery_details.serial_number? rents[i].battery_details.serial_number:'',
                        rents[i].driver_details && rents[i].driver_details.driver_name? rents[i].driver_details.driver_name:'',
                        rents[i].garage_details && rents[i].garage_details.garage_name? rents[i].garage_details.garage_name:'',
                        rents[i].garage_details.garage_upazila && rents[i].garage_details.garage_district? rents[i].garage_details.garage_upazila + ", " + rents[i].garage_details.garage_district:rents[i].garage_details.garage_district,
                        moment(rents[i].start_time).format("MMM D, YYYY hh:mm a"),
                        rents[i].start_battery_charge,
                        rents[i].start_battery_voltage,
                        rents[i].rent_amount,
                        moment(rents[i].end_time).format("MMM D, YYYY hh:mma"),
                        rents[i].last_user_name,
                        rents[i].end_battery_charge,
                        rents[i].end_battery_voltage,
                        representPaymentRentStatus(language, rents[i].rent_payment_status)
                    ])
                }

            let activeRentText = language === 'EN'? "Active Rent: " + totalActive.toString():"সক্রিয় ভাড়া: " + toBengaliNumber(totalActive)
            let completedRentText = language === 'EN'? "Completed Rent: " + totalCompleted.toString():"শেষ হওয়া ভাড়া: " + toBengaliNumber(totalCompleted)
            let noDataAvailableText =  language === 'EN'? "No data available":"কোন তথ্য নেই"
            rents.length > 0? excelRows.push(
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                [activeRentText, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                [completedRentText, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
            ):excelRows.push(["", "", "", "", "", "", "", "", noDataAvailableText, "", "", "", "", "", "", "", ""])
            let fileName = language === 'EN'? 'battery_rent_log_':'ব্যাটারী_ভাড়া_দেওয়ার_লগ_'
            exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
        }
    }, [props.driverListForExport]);

    useEffect(() => {
        if (props.totalPaid) {
            const totalPaid = new Intl.NumberFormat('en-IN').format(props.totalPaid['total_paid'])
            setTotalCollectedAmount(totalPaid)
        }
    }, [props.totalPaid]);

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        let searchText = 'page=' + newPage
        if (filterApplied) {
            if (start && end) {
                let from_date = language === 'EN'? moment(start).format("YYYY-MM-DD"):convertToAnotherLang(moment(start).format("YYYY-MM-DD"))
                let to_date = language === 'EN'? moment(end).format("YYYY-MM-DD"):convertToAnotherLang(moment(end).format("YYYY-MM-DD"))
                searchText += '&from_date=' + from_date + '&to_date=' + to_date
            }
            if (filterParamForApiCall.garage_phone) {
                searchText += '&garage_phone=' + filterParamForApiCall.garage_phone
            }
        }
        props.history.push({
            search: searchText
        })
    }

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            // Page will always be there
            let filter = {page: page}
            // TODO: Following can be taken into a function as it has been reused in the export API call also
            // Sometimes there will not be dates if user doesn't select dates after "Reset Filter" button click
            if (start && end) {
                let from_date = language === 'EN'? moment(start).format("YYYY-MM-DD"):convertToAnotherLang(moment(start).format("YYYY-MM-DD"))
                let to_date = language === 'EN'? moment(end).format("YYYY-MM-DD"):convertToAnotherLang(moment(end).format("YYYY-MM-DD"))
                filter['from_date'] = from_date
                filter['to_date'] = to_date
            }
            if (filterParamForApiCall.garage_phone) {
                filter['garage_phone'] = filterParamForApiCall.garage_phone
            }
            // COLLECT_SUMMARY_INFO(filter)
            setFilterApplied(true)
            setUrlIsEdited(false)
        }
    }, [start, end, urlIsEdited]);

    const handleChangeRentStatus = (event) => {
        setRentStatus(event.target.value)
    }

    const handlePaymentStatus = (event) => {
        setPaymentStatus(event.target.value)
    }

    const filterTheList = () => {
        setPage(1)
        let searchText = 'page=1'
        let from_date = moment(start).format("YYYY-MM-DD")
        let to_date = moment(end).format("YYYY-MM-DD")
        let from_date_month = from_date.split("-")[1]
        let to_date_month = to_date.split("-")[1]
        let firstDay = start.startOf('month').format('YYYY-MM-DD')
        let lastDay = start.endOf('month').format('YYYY-MM-DD')
        let month = moment(firstDay).format("MMMM, YYYY")
        setCalenderValue(moment(firstDay, "YYYY-MM-DD").toDate())
        if ((from_date !== firstDay) || (to_date !== lastDay) || (from_date_month !== to_date_month) || (from_date_month !== to_date_month) && (to_date !== lastDay)) {
            from_date = firstDay
            to_date = lastDay
            setStartDate(moment(firstDay, "YYYY-MM-DD"))
            setEndDate(moment(lastDay, "YYYY-MM-DD"))
            toast.info("Collecting summary for " + month + "!")
        }
        searchText += '&from_date=' + from_date + '&to_date=' + to_date
        if (garage) {
            searchText += '&garage_phone=' + garage.garage_phone_number
        }
        setFilterApplied(true)
        props.history.push({
            search: searchText
        })
    }

    const resetFilter = () => {
        resetFilterValues()
        clearErrors()
        if (filterApplied) {
            // Resetting the checked out driver list
            setPage(1)
            collectThirtyDaysData()
        }
        setFilterApplied(false)
    }

    const validateIfFuture = (keyword) => {
        const date = moment(keyword, 'YYYY-MM-DD');
        if (date.isValid()) {
            const todayDate = moment();
            if (todayDate.isAfter(date)) {
                return true;
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const validateIfToDateIsOlderThanFromDate = (from, to) => {
        const fromDate = moment(from, 'YYYY-MM-DD')
        const toDate = moment(to, 'YYYY-MM-DD')
        if (fromDate.isValid() && toDate.isValid()) {
            if (from === to) {
                return true
            } else if (toDate.isAfter(fromDate)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const validationKeyArray = ['page', 'garage_phone', 'from_date', 'to_date']

    useEffect(()=>{
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (!isNullorUndefined(initialfilterParam)) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            if (initialfilterParam.garage_phone) {
                setGarageFromUrl(initialfilterParam.garage_phone)
            }
            if (initialfilterParam.from_date && initialfilterParam.to_date) {
                let firstDay = moment(initialfilterParam.from_date, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD')
                let lastDay = moment(initialfilterParam.from_date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')
                setStartDate(moment(firstDay))
                setEndDate(moment(lastDay))
                setCalenderValue(moment(firstDay).toDate())
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])

    useEffect(() => {
        if (props.garages && props.garages.length > -1) {
            const garages = props.garages;
            setGarages(garages.map((garage) => {
                if (garageFromUrl && garageFromUrl === garage.garage_phone_number) {
                    let selectedGarage = garage
                    selectedGarage['label'] = garage.name
                    selectedGarage['value'] = garage.pk
                    setValue('garage', selectedGarage)
                }
                return {
                    ...garage,
                    label: garage.name,
                    value: garage.pk
                }
            }))
        } else {
            setGarages([])
        }
    }, [props.garages])

    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    },[start, end])

    const exportData = () => {
        let filter = {}
        if (filterApplied) {
            // TODO: Following can be taken into a function as it has been used in collecting paginated response
            // Sometimes there will not be dates if user doesn't select dates after "Reset Filter" button click
            if (start && end) {
                let from_date = language === 'EN'? moment(start).format("YYYY-MM-DD"):convertToAnotherLang(moment(start).format("YYYY-MM-DD"))
                let to_date = language === 'EN'? moment(end).format("YYYY-MM-DD"):convertToAnotherLang(moment(end).format("YYYY-MM-DD"))
                filter['from_date'] = from_date
                filter['to_date'] = to_date
            }
            if (filterParamForApiCall.garage_phone) {
                filter['garage_phone'] = filterParamForApiCall.garage_phone
            }
        }
        COLLECT_SUMMARY_INFO_TO_EXPORT(filter)
    }


    const [previousStart, setPreviousStartDate] = useState(null);
    const [previousEnd, setPreviousEndDate] = useState(null);
    const onDatesChange = ({startDate, endDate}) => {
        if (previousEnd && previousEnd.isSame(endDate)) {
            setPreviousStartDate(endDate)
            setPreviousEndDate(endDate)

            setStartDate(endDate)
            setEndDate(endDate)
        } else {
            setPreviousStartDate(startDate)
            setPreviousEndDate(endDate)

            setStartDate(startDate)
            setEndDate(endDate)
        }
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start}
                    endDate={end}
                    numberOfMonths={1}
                    minimumNights={0}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                    firstDayOfWeek={0}
                    // onClose={onClose}
                    // startDateOffset={startDateOffset}
                    // endDateOffset={endDateOffset}
                />
            </Popover.Content>
        </Popover>
    )

    const renderGarageDetailsModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideGarageDetailsModal}
                centered={true}
                modal={showGarageDetailsModal}
                title={<h1><strong>Rupom Garage</strong></h1>}>
                <div className={'row'}>
                    <div className={'col-md-5'}>
                        <ListGroup variant={"flush"}>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small className={'text-muted'}>Garage ID</small><br/>
                                <h4><strong>11298</strong></h4>
                            </ListGroup.Item>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small className={'text-muted'}>Registered on</small><br/>
                                <h4><strong>Jan 12, 2023</strong></h4>
                            </ListGroup.Item>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small className={'text-muted'}>Address</small><br/>
                                <h4><strong>Joar Sahara, Dhaka</strong></h4>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className={'col-md-7'}>
                        <ListGroup variant={"flush"}>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small className={'text-muted'}>Point of Contact</small><br/>
                                <h4><strong>Md Abdur Rahim</strong></h4>
                                <h4><strong>017182981982</strong></h4>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
            </GenericModal>
        </>
    }

    const renderLastRentDetailsModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideLastRentDetailsModal}
                centered={true}
                modal={showLastRentDetailsModal}
                title={<h1><strong>Rent #1129</strong></h1>}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Stack spacing={5}>
                            <DataTable
                                columns={[
                                    {
                                        field: 'battery_details',
                                        title: column2,
                                        cellStyle: {
                                            paddingLeft: '0',
                                            textAlign: 'left'
                                        },
                                        headerStyle: {
                                            paddingLeft: '0',
                                            textAlign: 'left'
                                        },
                                        disableClick: true,
                                        render: (rowData)=>{
                                            return   <>
                                                <h6><strong>5</strong></h6>
                                                <small><strong>SOLdongle</strong></small><br/>
                                                <small style={{whiteSpace: 'nowrap'}}><strong>#44000014, FW v1.5, HW v1.34</strong></small><br/>
                                                <small style={{whiteSpace: 'nowrap'}}><strong>Battery #55000014</strong></small><br/>
                                                <small style={{whiteSpace: 'nowrap'}}><strong>Vikli 60V, 80Ah</strong></small><br/>
                                            </>
                                        }
                                    },
                                    {
                                        field: 'garage_details',
                                        title: column3,
                                        cellStyle: {
                                            paddingLeft: '0',
                                            textAlign: 'left'
                                        },
                                        headerStyle: {
                                            paddingLeft: '0',
                                            textAlign: 'left'
                                        },
                                        disableClick: true,
                                        render: (rowData)=>{
                                            return   <>
                                                <span>Rupom Garage</span><br/>
                                                <small style={{whiteSpace: 'nowrap'}}><strong>Tongi</strong></small>
                                            </>
                                        }
                                    },
                                    {
                                        field: 'driver_details',
                                        title: language === 'EN'? 'Driver':'চালক',
                                        cellStyle: {
                                            paddingLeft: '0',
                                            textAlign: 'left'
                                        },
                                        headerStyle: {
                                            paddingLeft: '0',
                                            textAlign: 'left'
                                        },
                                        disableClick: true,
                                        render: (rowData)=>{
                                            return   <>
                                                <span>Sabbir Ahmed</span><br/>
                                                <span style={{whiteSpace: 'nowrap'}}>EV #6</span>
                                            </>
                                        }
                                    }
                                ]}
                                data={[{}]}
                                pagination={false}
                            />
                            <h6 className={'text-muted'}><strong>LAST RENT SCHEDULE</strong></h6>
                        </Stack>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTable
                            columns={[
                                {
                                    field: 'rent_time',
                                    title: language === 'EN'? 'Rented on':'ভাড়া দেওয়া হয়েছে',
                                    cellStyle: {
                                        paddingLeft: '0',
                                        textAlign: 'left'
                                    },
                                    headerStyle: {
                                        paddingLeft: '0',
                                        textAlign: 'left'
                                    },
                                    disableClick: true,
                                    render: (rowData)=>{
                                        return   <>
                                            <span>Oct 15, 2022 9:23am</span><br/>
                                            <small style={{whiteSpace: 'nowrap'}}><strong>100% SOC, 53V, -4.32A</strong></small><br/>
                                        </>
                                    }
                                },
                                {
                                    field: 'rented_for',
                                    title: language === 'EN'? 'Rented for':'ভাড়ার সময়',
                                    cellStyle: {
                                        textAlign: 'right'
                                    },
                                    headerStyle: {
                                        paddingRight: '0',
                                        textAlign: 'right'
                                    },
                                    disableClick: true,
                                    render: (rowData)=>{
                                        return   <>
                                            <h4><strong>24h</strong></h4>
                                        </>
                                    }
                                },
                                {
                                    field: 'rent_ended_on',
                                    title: language === 'EN'? 'Rent Ended On':'ভাড়া দেওয়া হয়েছে',
                                    cellStyle: {
                                        paddingLeft: '0',
                                        textAlign: 'left'
                                    },
                                    headerStyle: {
                                        paddingLeft: '0',
                                        textAlign: 'left'
                                    },
                                    disableClick: true,
                                    render: (rowData)=>{
                                        return   <>
                                            <span>Oct 16, 2022 1:00am</span><br/>
                                            <span>By System</span><br/>
                                            <small style={{whiteSpace: 'nowrap'}}><strong>75% SOC, 53V, -4.32A</strong></small>
                                        </>
                                    }
                                },
                                {
                                    field: 'status',
                                    title: language === 'EN'? 'Status':'বর্তমান অবস্থা',
                                    cellStyle: {
                                        paddingLeft: '0',
                                        textAlign: 'left'
                                    },
                                    headerStyle: {
                                        paddingLeft: '0',
                                        textAlign: 'left'
                                    },
                                    disableClick: true,
                                    render: (rowData)=>{
                                        let status = 'done'
                                        if (status === 'done') {
                                            return <>
                                                <Button variant={'outline-success'} size={'sm'}>Completed</Button>
                                            </>
                                        } else {
                                            return <>
                                                <Button variant={'outline-danger'} size={'sm'}>Active</Button>
                                            </>
                                        }
                                    }
                                }
                            ]}
                            data={[{}]}
                            pagination={false}
                        />
                    </div>
                </div>
            </GenericModal>
        </>
    }

    const [value, setCalenderValue] = useState();
    const activeDays = new Set(['05-11-2022', '07-11-2022'])
    const inactiveDays = new Set(['08-11-2022'])
    const renderInactiveDaysDetailsModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideInactiveDaysDetailsModal}
                centered={true}
                modal={showInactiveDaysDetailsModal}
                title={<>
                    <h1><strong>Smart Battery #5 - Rent Days Overview</strong></h1>
                    <h6>SOLdongle #44000014</h6>
                    <h6>Battery #55000014</h6>
                </>}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Stack spacing={3}>
                            <Calendar
                                style={{ height: 500 }}
                                onChange={setCalenderValue}
                                value={value}
                                tileClassName={({ date, view }) => {
                                    if(activeDays.has(moment(date).format("DD-MM-YYYY"))){
                                        return  'highlight'
                                    } else if(inactiveDays.has(moment(date).format("DD-MM-YYYY"))){
                                        return  'inActiveDays'
                                    }
                                }}
                                tileDisabled={({ date }) => date.getDay() === 0}
                                minDetail="month"
                                maxDetail="month"
                                defaultView="month"
                            >
                            </Calendar>
                            <Stack direction="row"
                                   justifyContent="flex-start"
                                   alignItems="center"
                                   spacing={2}>
                                <span style={{color: '#0b6e4f'}}><div className={'custom-box green'}></div>&nbsp;Rented</span>
                                <span style={{color: '#cb152b'}}><div className={'custom-box red'}></div>&nbsp;Inactive</span>
                            </Stack>
                        </Stack>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-12'}>

                    </div>
                </div>
            </GenericModal>
        </>
    }


    return (
        <div>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={pageTitle}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{pageTitle}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                    {/*<IconButton classes={classes} onClick={exportData}*/}
                                    {/*            disabled={props.listLoading || props.isLoading || props.iconLoading}>*/}
                                    {/*    {props.iconLoading? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/> :*/}
                                    {/*        <img src={require('../../../utils/asset/download-Button.svg')}*/}
                                    {/*             alt="Download"/>}*/}
                                    {/*</IconButton>*/}
                                    {/* {checkAuthorization(authorization, driverServiceName, checkOutFeatureName, [createActionName])? <>
                                        <Button variant="warning" type="button" size={'sm'} style={{
                                            backgroundColor: '#F18D00 !important',
                                        }} onClick={gotToCheckOutDriver} classes={classes} >
                                            <i className="fa fa-toggle-on"/>{language === 'EN'? 'Activate battery':'ব্যাটারী ভাড়া দিন'}
                                        </Button>
                                    </>:null} */}
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {/* TODO: Also reorganize the position of the "Apply filter" and "Reset filter"*/}
                {showFilter?
                    <>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <Form onSubmit={handleSubmit(filterTheList)}>
                                <div className={'row g-3'}>
                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Garage':'গ্যারেজ'}</Form.Label>
                                            </div>
                                            <Controller
                                                control={control}
                                                name={"garage"}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        placeholder={language === 'EN'? 'Select Garage':'গ্যারেজ বাছাই করুন'}
                                                        classNamePrefix="react-select-sol-style"
                                                        isDisabled={props.garageCollectionInProgress}
                                                        isLoading={props.garageCollectionInProgress}
                                                        maxMenuHeight={200}
                                                        value={value}
                                                        isClearable={true}
                                                        control={control}
                                                        inputRef={ref}
                                                        options={garages}
                                                        isSearchable={true}
                                                        onChange={(selected, {action}) => {
                                                            if (action === "clear") {
                                                                setGarage("")
                                                            }
                                                            onChange(selected)
                                                            if (selected) {
                                                                setGarage(selected)
                                                            } else {
                                                                setGarage("")
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Rent Schedule':'ভাড়ার শিডিউল'}</Form.Label>
                                            </div>
                                            <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                            overlay={popover}>
                                                <SolInput
                                                    name={'dateRangePickerInput'}
                                                    readOnly={true}
                                                    value={dateRange}
                                                    placeholder={language === 'EN'? 'Select a date range':'তারিখের সীমা বাছাই করুন'}
                                                />
                                            </OverlayTrigger>
                                            {errors.date_range && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.date_range.message}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={"col-md-12"}>
                                        {/* TODO: Need to update disable and spinner show logic */}
                                        <Button variant="warning" size={'sm'} type="submit"
                                                disabled={false}>
                                            {props.deviceInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}
                                            {language === 'EN'? 'Apply filter':'ফিল্টার করুন'}
                                        </Button>
                                        <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}} className={'ml-3'}>
                                            {language === 'EN'? 'Reset filter':'ফিল্টার করার তথ্য পরিষ্কার করুন'}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <hr/></>:null}
                {!props.listLoading? <>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            {/* TODO: Add a block span here and make the icon filled */}
                            {/*<IconButton classes={classes} className={'float-left'}>*/}
                            {/*    <img src={require('../../../../utils/asset/takaaaa.svg')}*/}
                            {/*         alt="Taka"/>*/}
                            {/*</IconButton>*/}
                            <span className={'float-left'}>
                            <p><h2>ট{totalPayable}</h2></p>
                            <p>Estimated Payable by Garage/Dealer</p>
                        </span>
                        </div>
                    </div>
                </>:''}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.listLoading? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন ভাড়া নেই'}
                                columns={rentListColumns}
                                data={rentList}
                                showToolbar={false}
                                // asyncPagination={true}
                                // count={props.rentList?.count}
                                // itemsPerPage={props.rentList?.page_size}
                                // onChangePage={onChangePage}
                                // page={page}
                            /> : <LoadingSpinner loadingSubText={language == 'EN'? 'Generating battery rent log ..': 'ব্যাটারী ভাড়া দেওয়ার লগ প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

                {renderGarageDetailsModal()}
                {renderLastRentDetailsModal()}
                {renderInactiveDaysDetailsModal()}
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

SummaryReports.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.summaryReportReducer.isLoading,
        iconLoading: state.summaryReportReducer.iconLoading,
        totalPaid: state.summaryReportReducer.totalPaid,
        collectingPaymentInfo: state.summaryReportReducer.collectingPaymentInfo,
        rentList: state.summaryReportReducer.rentList,
        driverListForExport: state.summaryReportReducer.driverListForExport,
        listLoading: state.summaryReportReducer.listLoading,
        successMessageForCheckIn: state.summaryReportReducer.successMessageForCheckIn,
        errorMessageForCheckIn: state.summaryReportReducer.errorMessageForCheckIn,
        garages: state.summaryReportReducer.garages,
        garageCollectionInProgress: state.summaryReportReducer.garageCollectionInProgress,
        driverDetails: state.summaryReportReducer.driverDetails,
        driverDetailsCollectionInProgress: state.summaryReportReducer.driverDetailsCollectionInProgress
    }
}

export default connect(mapStateToProps, actions)(withRoles(SummaryReports));
