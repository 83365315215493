import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "../rentLog/actionTypes";
import {appEventActionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";

export const appEventActions = {
    FETCH_EVENT_LIST:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/app-events',payload),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                appEventActionTypes.FETCH_EVENT_LIST_REQUEST,
                appEventActionTypes.FETCH_EVENT_LIST_SUCCESS,
                appEventActionTypes.FETCH_EVENT_LIST_FAILURE,
            ]
        }
    }),

    FETCH_EVENT_LIST_FOR_EXPORT:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/app-events',payload),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                appEventActionTypes.FETCH_EVENT_LIST_FOR_EXPORT_REQUEST,
                appEventActionTypes.FETCH_EVENT_LIST_FOR_EXPORT_SUCCESS,
                appEventActionTypes.FETCH_EVENT_LIST_FOR_EXPORT_FAILURE,
            ]
        }
    }),

    RETURN_TO_INITIAL: ()=>({
        type: appEventActionTypes.RETURN_TO_INITIAL
    })

}
