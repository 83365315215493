export const formulateTextFromSelectedItems = (itemType, items) => {
    let names = ''
    for (let i=0; i< items.length; i++) {
        if (i === 0) {
            names += items[i].label
        } else {
            names += ', ' + items[i].label
        }
    }
    // Ex: Garages: X, Y, Z (itemType: Garage, items: [{X}, {Y}, {Z}])
    return `${itemType}${items.length > 1? itemType === 'Branch'? 'es':'s':''}: ` + names
}