import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import {DataTable, LoadingSpinner} from '../../../../components';
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import Stack from '@mui/material/Stack';
import moment from "moment/moment";
import {Button, Form, Spinner} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import {smartDongleReducer} from "../../reducer";
import {toast} from "react-toastify";

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    }
});

const AccessManagementForSmartDongles = ({ data, numberOfDongles, ...props }) => {
    const [language, setLanguage] = useState("EN");
    const { control, register, setError, errors, setValue, clearErrors, handleSubmit } = useForm();
    const classes = useStylesIconButton();
    const [currentOrganizations, setCurrentOrganizations] = useState([]);
    const [selectedSmartDongles, setSelectedSmartDongles] = useState([]);
    const [userHasUpdatedOrgSelection, setUserHasUpdatedOrgSelection] = useState(false);
    const [selectedOrganizations, setSelectedOrganizations] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [changeHistoryForTable, setChangeHistoryForTable] = useState([]);
    const tableHeaderFontStyling = {color: '#8C8C9B', 'fontWeight': 500, 'fontSize': '1.1rem'}
    const solshareGuid = '31f936cf-4bf9-4323-91e6-0a8de3c27718'

    const {
        GET_ORGANISATIONS,
        COLLECT_CHANGE_HISTORY,
        UPDATE_ORGANIZATIONAL_ACCESS,
        CLEAN_UP_STATES_RELATED_TO_ACCESS_MANAGEMENT_MODAL
    } = props;

    useEffect(()=>{
        setLanguage(props.language)
        let organizationGuids = []
        setSelectedSmartDongles(data.map((dongleData) => {
            organizationGuids = organizationGuids.concat(dongleData.organization_guids)
            return dongleData.serial_number
        }))
        setCurrentOrganizations([...new Set(organizationGuids)])
        GET_ORGANISATIONS()

        return () => {
            CLEAN_UP_STATES_RELATED_TO_ACCESS_MANAGEMENT_MODAL()
        }
    }, [])

    useEffect(()=>{
        let numberOfSelectedSmartDongles = selectedSmartDongles.length
        if (numberOfSelectedSmartDongles > 0) {
            let dongleString = '['
            selectedSmartDongles.map((dongle, index) => {
                if (index !== numberOfSelectedSmartDongles - 1) {
                    dongleString += '"' + dongle + '",'
                } else {
                    dongleString += '"' + dongle + '"]'
                }
            })
            COLLECT_CHANGE_HISTORY({'serial_number_list': dongleString,
                'entity_for': 'dongle_org_assignment'})
        }
    }, [selectedSmartDongles])

    useEffect(() => {
        if (props.changeHistories) {
            const changeHistories = props.changeHistories
            if (changeHistories.length > -1) {
                setChangeHistoryForTable(changeHistories.map((history) => {
                    return {
                        ...history
                    }
                }))
            }
        }
    }, [props.changeHistories])

    useEffect(() => {
        if (props.organisations) {
            const organisations = props.organisations
            if (organisations && organisations.length > -1) {
                let orgsForSelection = []
                setOrganizations(organisations.map((org) => {
                    let isFixed = false
                    if (org.pk === solshareGuid) {
                        isFixed = true
                        orgsForSelection.push({...org, label:org.name, value:org.pk, isFixed:isFixed})
                    } else if (currentOrganizations.includes(org.pk)) {
                        orgsForSelection.push({...org, label:org.name, value:org.pk, isFixed:isFixed})
                    }
                    return {
                        ...org,
                        label: org.name,
                        value: org.pk,
                        isFixed: isFixed
                    }
                }))
                setSelectedOrganizations(orgsForSelection)
            }
        }
    }, [props.organisations])

    // Styling for the fixed value (we are hiding the cross button shown beside the selected option)
    const selectStyle = {
        multiValueRemove: (base, state) => {
            return {
                ...(state.data?.isFixed ? { ...base, display: 'none'} : base),
            }
        }
    };

    const CHANGE_HISTORY_COLUMNS = [
        {
            field: 'created_at',
            title: language === 'EN' ? 'Date' : 'তারিখ',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>{moment(rowData.created_at).format("MMM DD, YYYY hh:mm a")}</>
            }
        },
        {
            field: 'old_data',
            title: language === 'EN' ? 'Previous' : 'আগের প্রতিষ্ঠানসমূহ',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                let organizationNames = ""
                if (rowData.old_data && rowData.old_data.organization_names) {
                    let returnedOrganizationNames = rowData.old_data.organization_names
                    let numberOfNames = returnedOrganizationNames.length
                    for (let i=0; i<numberOfNames; i++) {
                        if (i !== numberOfNames - 1) {
                            organizationNames += returnedOrganizationNames[i] + ", "
                        } else {
                            organizationNames += returnedOrganizationNames[i]
                        }
                    }
                } else {
                    organizationNames = "N/A"
                }
                return <>{organizationNames}</>
            }
        },
        {
            field: 'new_data',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            title: language === 'EN' ? 'Changed to' : 'পরের প্রতিষ্ঠানসমূহ',
            render: (rowData) => {
                let organizationNames = ""
                if (rowData.new_data && rowData.new_data.organization_names) {
                    let returnedOrganizationNames = rowData.new_data.organization_names
                    let numberOfNames = returnedOrganizationNames.length
                    for (let i=0; i<numberOfNames; i++) {
                        if (i !== numberOfNames - 1) {
                            organizationNames += returnedOrganizationNames[i] + ", "
                        } else {
                            organizationNames += returnedOrganizationNames[i]
                        }
                    }
                } else {
                    organizationNames = "N/A"
                }
                return <>{organizationNames}</>
            },
        },
        {
            field: 'user_name',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            title: language === 'EN' ? 'Changed by' : 'বদল করেছেন',
            render: (rowData) => {
                return <>{rowData.user_name}, {rowData.user_org_name}</>
            },
        }
    ]

    const saveSelectedOrganizations = (data) => {
        let numberOfSelectedOrganizations = selectedOrganizations.length
        if (selectedOrganizations && numberOfSelectedOrganizations > 1) {
            let makeApiCall = true
            // Checking if user has updated the org selection and if the selection is same as initial
            // Preventing access update request if user has not changed the org selection or hasn't updated the org list
            if (userHasUpdatedOrgSelection && numberOfSelectedOrganizations === currentOrganizations.length) {
                let newOrgHasBeenSelected = false
                for (let i=0; i<numberOfSelectedOrganizations; i++)  {
                    if (!currentOrganizations.includes(selectedOrganizations[i].pk)) {
                        newOrgHasBeenSelected = true
                        break
                    }
                }
                if (!newOrgHasBeenSelected) {
                    toast.warning("You are providing access to the same organizations as before!")
                }
                makeApiCall = newOrgHasBeenSelected
            } else if (!userHasUpdatedOrgSelection) {
                makeApiCall = false
                toast.warning("Please update organization selection!")
            }
            if (makeApiCall) {
                let orgGuidList = []
                // If we do the following with filter(), we than need to use map() again to create an array of guid, hence
                // to avoid double-checking into an array of JSON, we are using for loop - Noor Reza, 16th Jan, 2023
                // Considering organizations without "SOLshare"
                for (let i=0; i < numberOfSelectedOrganizations; i++) {
                    if (selectedOrganizations[i].pk !== solshareGuid) {
                        orgGuidList.push(selectedOrganizations[i].pk)
                    }
                }
                UPDATE_ORGANIZATIONAL_ACCESS({
                    "serial_number_list": selectedSmartDongles,
                    "organization_guid_list": orgGuidList
                })
            }
        } else if (numberOfSelectedOrganizations === 1) {
            // 'SOLshare' will always be selected by default
            if (userHasUpdatedOrgSelection) {
                UPDATE_ORGANIZATIONAL_ACCESS({
                    "serial_number_list": selectedSmartDongles,
                    "organization_guid_list": []
                })
            } else {
                toast.warning("SOLshare already has access to the selected dongles!")
            }
        }
    }

    return (
        <div>
            <div className={'row'}>
                <div className={'col-lg-12'}>
                    <Stack direction={'column'} spacing={2}>
                        <span>{numberOfDongles} Smart Dongles selected</span>
                        <Form onSubmit={handleSubmit(saveSelectedOrganizations)}>
                            <div className={'row g-3'}>
                                <div className={'col-lg-12'}>
                                    <Form.Group>
                                        <Form.Label>Selected SOLdongles can be administered by users of</Form.Label>
                                        <Controller
                                            control={control}
                                            name={"organizationsWithAccess"}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select Type ...'}
                                                    classNamePrefix="react-select-sol-style-access-management"
                                                    maxMenuHeight={200}
                                                    styles={selectStyle}
                                                    isClearable={selectedOrganizations.some((v) => !v.isFixed)}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    isDisabled={props.orgListLoading}
                                                    isLoading={props.orgListLoading}
                                                    control={control}
                                                    options={organizations}
                                                    inputRef={ref}
                                                    value={selectedOrganizations}
                                                    onChange={(selected, {action}) => {
                                                        if (action === "clear") {
                                                            setSelectedOrganizations(organizations.filter((v) => v.isFixed))
                                                            // When we have used setValue API, value of the field hasn't
                                                            // Been set when cleared, hence we are solely using
                                                            // "selectedOrganizations" - Noor Reza, 16th Jan, 2023
                                                            setUserHasUpdatedOrgSelection(true)
                                                        }
                                                        if (selected && selected.length <= 4) {
                                                            setSelectedOrganizations(selected)
                                                            setUserHasUpdatedOrgSelection(true)
                                                        }
                                                        onChange(selected)
                                                    }}
                                                />
                                            )}
                                        />
                                        <div style={tableHeaderFontStyling}>Up to 4 organizations can be included</div>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-lg-2'}>
                                    <Button type={"submit"} variant="warning"
                                            size={'lg'} style={{display: 'block', width: '100%'}}
                                            disabled={props.updatingOrganizationalAccess || props.collectingChangeHistory}>
                                        {props.updatingOrganizationalAccess? <><Spinner animation={'border'}
                                                                                        size={'md'} variant={'light'}/>
                                        </>:null}&nbsp;&nbsp;Save
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </Stack>
                </div>
            </div>
            <div className={'row mt-10'}>
                <div className={'col-lg-12'}>
                    <span style={tableHeaderFontStyling}><strong>Change History</strong></span>
                </div>
            </div>
            <div className={'row mt-5'}>
                <div className={'col-md-12'}>
                    {!props.collectingChangeHistory? <>
                        <DataTable
                            columns={CHANGE_HISTORY_COLUMNS}
                            data={changeHistoryForTable}
                            showToolbar={false}
                            asyncPagination={false}
                            pagination={true}
                            pageSize={5}
                        />
                    </> : <LoadingSpinner
                        loadingSubText={language === 'EN' ? 'Fetching change history ..' : 'ইতিহাস সংগ্রহ হচ্ছে ..'}
                        language={language} />}
                </div>
            </div>
        </div>
    );
};

AccessManagementForSmartDongles.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        providedSmartBatteries: state.borrowersReducer.providedSmartBatteries,
        smartBatteryInfoCollectionInProgress: state.borrowersReducer.smartBatteryInfoCollectionInProgress,
        orgListLoading: state.commonReducer.orgListLoading,
        organisations: state.commonReducer.organisations,
        collectingChangeHistory: state.smartDongleReducer.collectingChangeHistory,
        changeHistories: state.smartDongleReducer.changeHistories,
        updatingOrganizationalAccess: state.smartDongleReducer.updatingOrganizationalAccess
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(AccessManagementForSmartDongles));