export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',
    
    FETCH_PAYMENTS_REQUEST: 'FETCH_PAYMENTS_REQUEST',
    FETCH_PAYMENTS_SUCCESS: 'FETCH_PAYMENTS_SUCCESS',
    FETCH_PAYMENTS_FAILURE: 'FETCH_PAYMENTS_FAILURE',


    FETCH_INVOICE_PAYMENTS_REQUEST: 'FETCH_INVOICE_PAYMENTS_REQUEST',
    FETCH_INVOICE_PAYMENTS_SUCCESS: 'FETCH_INVOICE_PAYMENTS_SUCCESS',
    FETCH_INVOICE_PAYMENTS_FAILURE: 'FETCH_INVOICE_PAYMENTS_FAILURE',

    FETCH_INVOICE_PAYMENTS_FOR_EXPORT_REQUEST: 'FETCH_INVOICE_PAYMENTS_FOR_EXPORT_REQUEST',
    FETCH_INVOICE_PAYMENTS_FOR_EXPORT_SUCCESS: 'FETCH_INVOICE_PAYMENTS_FOR_EXPORT_SUCCESS',
    FETCH_INVOICE_PAYMENTS_FOR_EXPORT_FAILURE: 'FETCH_INVOICE_PAYMENTS_FOR_EXPORT_FAILURE',


    FETCH_PAYMENTS_EXPORT_REQUEST: 'FETCH_PAYMENTS_EXPORT_REQUEST',
    FETCH_PAYMENTS_EXPORT_SUCCESS: 'FETCH_PAYMENTS_EXPORT_SUCCESS',
    FETCH_PAYMENTS_EXPORT_FAILURE: 'FETCH_PAYMENTS_EXPORT_FAILURE',

    FETCH_MOBILE_WALLET_PAYMENTS_REQUEST: 'FETCH_MOBILE_WALLET_PAYMENTS_REQUEST',
    FETCH_MOBILE_WALLET_PAYMENTS_SUCCESS: 'FETCH_MOBILE_WALLET_PAYMENTS_SUCCESS',
    FETCH_MOBILE_WALLET_PAYMENTS_FAILURE: 'FETCH_MOBILE_WALLET_PAYMENTS_FAILURE',

    FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT_REQUEST: 'FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT_REQUEST',
    FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT_SUCCESS: 'FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT_SUCCESS',
    FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT_FAILURE: 'FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT_FAILURE',

    FETCH_PAYMENT_DETAILS_REQUEST: 'FETCH_PAYMENT_DETAILS_REQUEST',
    FETCH_PAYMENT_DETAILS_SUCCESS: 'FETCH_PAYMENT_DETAILS_SUCCESS',
    FETCH_PAYMENT_DETAILS_FAILURE: 'FETCH_PAYMENT_DETAILS_FAILURE',

    FETCH_BATTERY_SETTLEMENT_PAYMENTS_REQUEST: "FETCH_BATTERY_SETTLEMENT_PAYMENTS_REQUEST",
    FETCH_BATTERY_SETTLEMENT_PAYMENTS_SUCCESS: "FETCH_BATTERY_SETTLEMENT_PAYMENTS_SUCCESS",
    FETCH_BATTERY_SETTLEMENT_PAYMENTS_FAILURE: "FETCH_BATTERY_SETTLEMENT_PAYMENTS_FAILURE",

    FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_REQUEST: "FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_REQUEST",
    FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_SUCCESS: "FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_SUCCESS",
    FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_FAILURE: "FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_FAILURE",

    SETTLE_PAYMENTS_REQUEST: "SETTLE_PAYMENTS_REQUEST",
    SETTLE_PAYMENTS_SUCCESS: "SETTLE_PAYMENTS_SUCCESS",
    SETTLE_PAYMENTS_FAILURE: "SETTLE_PAYMENTS_FAILURE",

    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE'
};
