import {
    clearRefreshTokenPromise,
    saveRefreshTokenPromise,
    setAccessToken as defaultSetAcessTokenActionCreator,
    CLEAR_COUNTER,
    INCREASE_COUNTER, clearCounter, increaseCounter
} from "./actions";
import {dispatchFSAAction} from "./createFSAConverter";


export default function attemptRefresh(settings) {
    const {
        action,
        failure,
        isRefreshCall = defaultIsRefreshCall,
        next,
        refreshActionCreator,
        refreshReducerKey = "tokenRefresh",
        setAccessTokenActionCreator = defaultSetAcessTokenActionCreator,
        store,
        token
    } = settings;

    return response => {
        // The API call returned unauthorized user (access token is expired)
        if (
            response.error &&
            response.payload.status === 401 &&
            // The refresh endpoint might return 401, so we skip the check here
            // otherwise we get stuck in an infinite loop
            !isRefreshCall(action, refreshActionCreator()) &&
            // We should not run the refresh flow when no token was given to begin with
            // (for instance Forgot Password, Login etc.)
            typeof token === "string" &&
            token.length > 0
        ) {
            // This will ensure that the fail action goes all the way to the bottom
            dispatchFSAAction(next)(response)

            // We check if there is already dispatched a call to refresh the token,
            // if so we can simply queue the call until the refresh request completes
            let refreshPromise = store.getState()[
                refreshReducerKey
                ].refreshTokenPromise;
            let refreshCounter = store.getState()[
                refreshReducerKey
                ].identityWrongCounter;

            if (!refreshPromise && refreshCounter<=2) {
                refreshPromise = requestNewAccessToken({
                    store,
                    next,
                    setAccessTokenActionCreator,
                    refreshActionCreator,
                    token
                });

                next(saveRefreshTokenPromise(refreshPromise));

            }else if (refreshCounter>=2){
                next(clearCounter());
                console.log(store.getState()[
                    refreshReducerKey
                    ].identityWrongCounter)
                return store
                    .dispatch(failure())
            }
            // When the refresh attempt is done, we fire all the actions that have been queued until
            // its completion. If, the refresh promise was unsuccessful we logout the user.
            return refreshPromise? refreshPromise.then(response => {
                if (!response.error) {
                    return store.dispatch(action);
                }
                return (
                    store
                        .dispatch(failure())
                        // Ensure subscribers do not get an empty response, e.g. T512
                        // .then(response => ({ error: true }))
                );
            }): store.dispatch(failure());
        }

        return dispatchFSAAction(next)(response);
    };
};


const requestNewAccessToken = (
    {
        store,
        next,
        setAccessTokenActionCreator,
        refreshActionCreator,token
    }
) => {

    return store.dispatch(refreshActionCreator(token)).then(response => {
        // Refresh was successful
        next(clearRefreshTokenPromise());

        // Refresh was successful
        if (response) {

            next(increaseCounter());
            next(setAccessTokenActionCreator(response?.payload));

            return {
                error: false
            };
        }

        return {
            error: true
        };
    });
};

const defaultIsRefreshCall = (action, refreshAction) => {
    return action["Call API"].endpoint === refreshAction["Call API"].endpoint;
};

