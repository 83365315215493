import { IconButton } from '@material-ui/core';
import FilterListIcon from "@material-ui/icons/FilterList";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {connect } from 'react-redux';
import { Toolbar } from '../../../../components/compoundToolBar/compoundToolBar';
import ContentWrapper from '../../../../components/contentWrapper/contentWrapper';
import { useStylesIconButton } from '../../../../utils/utilityFunctions';
import {LoadingSpinner, DataTableContainer, DataTable} from '../../../../components';
import { Form, Spinner, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import {actions as commonActions} from '../../../commonReduxActions/actions';
import { actions } from '../../actions';
import { withRoles } from '../../../../router/SecuredRoute';
import JSONPretty from 'react-json-prettify';
import { GenericModal } from '../../../../components/genericModal/genericModal';
import { SolTypography } from '../../../../components/utilityComponents/SOlTypography';
import { useSubheader } from '../../../../../_metronic/layout';
import DateTimeFormat from '../../../../utils/dateFormat/DateTimeFormat';
import {checkAuthorization} from "../../../auth/authorization";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";


const SupplierInfo = props => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, getValues} = useForm();
    const type = [
        {value: "bms", label: "BMS"}, {value: "battery", label: "Battery"}
    ]
    const [filter, setFilter] = useState(true);
    const [showBmsVendorColumns, setShowBmsVendorColumns] = useState(true);
    const [vendors, setVendors] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [bmsData, setBmsData] = useState(undefined)
    const [language, setlanguage] = useState('EN');
    const [productType, setProductType] = useState(type[0])
    const classes = useStylesIconButton();
    const subheader = useSubheader()

    // Permission related
    const batteryServiceName = getServiceName('batteryService')
    const vendorFeatureName = getFeatureName('vendorFeature')
    const createActionName = getActionName('createAction')

    const {
        GET_VENDOR_LIST,
        RETURN_TO_INITIAL_STATE,
    } = props;

    useEffect(() => {
        if (checkAuthorization(props.authorization, batteryServiceName, vendorFeatureName, [createActionName])) {
            subheader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} classes={classes} onClick={() => {
                        props.history.push({
                            pathname: '/batteries/vendors/create',
                            purpose: 'vendorCreate'
                        })
                    }}>
                        <i className="fa fa-random" />{props.language === 'EN' ? 'Create Vendor' : 'একাধিক ব্যাটারী নিবন্ধন'}
                    </Button>

                </div>
            )
        }
        subheader.setBreadCrumbComponent(null)
        setValue('productType', type[0])
        GET_VENDOR_LIST({
            type: type[0].value
        })

        return () => {
            subheader.setActionButtons(null)
            subheader.setBreadCrumbComponent(null)
            RETURN_TO_INITIAL_STATE()
        }
    }, [])

    const filterToggle =()=>{
        setFilter(!filter);
    }

    const resetFilter=()=>{
        if (getValues().productType.value !== type[0].value && !showBmsVendorColumns) {
            GET_VENDOR_LIST({
                type: type[0].value
            })
            setShowBmsVendorColumns(true)
        }
        setValue('productType', type[0])
    }

    const hideModal =()=>{
        setShowModal(false)
        setBmsData(null)
    }

    const batteryVendorListColumns = [
        {
            field: 'name',
            title: language === 'EN'? 'Name':'ব্যাটারী সিরিয়াল',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                // color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            // disableClick: true,
        },
        {
            field: 'product_type',
            title: language === 'EN'? 'Vendor type':'ডঙ্গল সিরিয়াল',
            emptyValue: ()=>{
                return "N/A"
            },
            lookup: {
                'bms': 'BMS',
                'battery': "Battery"
            }
            // disableClick: true,
        },
        {
            field: 'created_at',
            title: language === 'EN' ? "Created on" : "আপলোডের তারিখ",
            emptyValue: () => {
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return new DateTimeFormat(rowData.created_at).localDisplayWithoutTime
            
            }
            // disableClick: true,
        },

    ]

    const bmsVendorListColumns = [
        {
            field: 'name',
            title: language === 'EN'? 'Name':'ব্যাটারী সিরিয়াল',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                // color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            // disableClick: true,
        },
        {
            field: 'bms_vendor_code',
            title: language === 'EN'? 'Code':'Code',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                // color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            // disableClick: true,
        },
        {
            field: 'product_type',
            title: language === 'EN'? 'Vendor type':'ডঙ্গল সিরিয়াল',
            emptyValue: ()=>{
                return "N/A"
            },
            lookup: {
                'bms': 'BMS',
                'battery': "Battery"
            }
            // disableClick: true,
        },
        {
            field: 'created_at',
            title: language === 'EN' ? "Created on" : "আপলোডের তারিখ",
            emptyValue: () => {
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return new DateTimeFormat(rowData.created_at).localDisplayWithoutTime

            }
            // disableClick: true,
        },
    ]

    const filterTheList = (data) => {
        GET_VENDOR_LIST({
            type: data.productType.value
        })
        if (data.productType.value === type[0].value) {
            setShowBmsVendorColumns(true)
        } else {
            setShowBmsVendorColumns(false)
        }
    }

    useEffect(() => {
        if (props.vendorList && props.vendorList.length > -1) {
            const vendors = props.vendorList
            setVendors(vendors.map((vendor) => {
                return {
                    ...vendor
                }
            }))
        } else {
            setVendors([])
        }
    }, [props.vendorList])

    return (
        <ContentWrapper showBackButton={true} pageTitle={"Vendor List"} showCardHeader={false}>
            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><strong>Battery Vendors</strong></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            <IconButton onClick={filterToggle} classes={classes}>
                                <FilterListIcon color="disabled" fontSize="large"/>
                            </IconButton>
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>
                <hr/>
            </div>

            {filter? <>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Form onSubmit={handleSubmit(filterTheList)}>
                            <div className={'row g-3'}>
                                <div className={'col-md-4'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Product type':'সরবরাহকারী'}</Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"productType"}
                                            rules={{ required: "Above information is required!" }}
                                            defaultValue={''}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={language === 'EN'? 'Select a Product type':'বিএমএস বিক্রেতা বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    options={type}
                                                    isSearchable={true}
                                                    control={control}
                                                    onChange={(selected, {action}) => {
                                                        onChange(selected)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.productType && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.productType.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={"col-md-12"}>
                                    <Button variant="warning" size={'sm'} type="submit"
                                            disabled={props.listLoading}>
                                        {language === 'EN'? 'Apply filter':'ফিল্টার করুন'}
                                    </Button>
                                    <Button variant="outline-dark" type={"button"} size={'sm'}
                                            onClick={()=> {resetFilter()}} className={'ml-3'}>
                                        {language === 'EN'? 'Reset filter':'ফিল্টার করার তথ্য পরিষ্কার করুন'}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <hr/></>:null}

            <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.listLoading? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন ব্যাটারী নেই'}
                                columns={showBmsVendorColumns? bmsVendorListColumns:batteryVendorListColumns}
                                data={vendors}
                                showToolbar={false}
                                asyncPagination={false}
                                isLoading={props.listLoading}
                                overrideCustomActions={true}
                            /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Generating vendor list ..': 'বিক্রেতার তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
            </div>

            {showModal?
            <GenericModal 
                modal={showModal} 
                onHide={hideModal} 
                animation={true} 
                size={'lg'} 
                centered={"centered"}
                hideModal={hideModal}
                title={"BMS configurations"}
                footer={true}
                footerCloseButtonText={"Close"}
                noSubmitButton={true}
                showModalHeader={true}
                hideCrossButton={false}
            >
                <SolTypography.Title primary>BMS parameters:</SolTypography.Title>
                {bmsData?.bms_parameter? <JSONPretty json={bmsData.bms_parameter} />: "no parameter found" } 

                <br/>

                <br/>


                <SolTypography.Title primary>BMS commands:</SolTypography.Title>
                {bmsData?.bms_command ? <SolTypography.Text > {bmsData.bms_command}</SolTypography.Text>: "No Command Found"} 
            </GenericModal>: null}



            
        </ContentWrapper>
    );
};

SupplierInfo.propTypes = {
    
};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.batteryReducer.isLoading,
        listLoading: state.commonReducer.listLoading,
        vendorList: state.commonReducer.vendorList
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(SupplierInfo));