import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import PropTypes from 'prop-types';
import {Controller, useForm} from 'react-hook-form';
import {Form, Button, Spinner, Badge, OverlayTrigger, Popover} from 'react-bootstrap';
import { SolInput, DataTableContainer, DataTable, SolTypography, LoadingSpinner } from '../../../../components';
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {Toolbar} from "../components";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/styles";
import Stack from '@mui/material/Stack';
import {GenericModal} from "../../../../components/genericModal/genericModal";
import UpdateDueLimitOfAGarage from "./dueLimitUpdate";
import Select from "react-select";
import moment from "moment/moment";
import {DayPickerRangeController} from "react-dates";
import {toBengaliNumber} from "bengali-number";
import {exportInExcel} from "../../../../utils/excelExport";

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});


const ActivitiesOfAGarage = ({ data, dueLimitUpdateTracker, ...props }) => {
    const { control, register, setError, errors, setValue, clearErrors, handleSubmit } = useForm();
    const inputElement = React.useRef()
    const [language, setLanguage] = useState("EN");
    const classes = useStylesIconButton();
    const [garageGuid, setGarageGuid] = useState('');
    const [transactions, setTransactions] = useState([]);

    const [totalDeposit, setTotalDeposit] = useState('');
    const [totalBill, setTotalBill] = useState('');
    const [totalPayment, setTotalPayment] = useState('');
    const [pageSize, setPageSize] = useState(10);

    // Filter
    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);
    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const transactionTypes =[
        {
            label: 'All',
            value: 'all'
        },
        {
            label: 'Invoice',
            value: 'invoice'
        },
        {
            label: 'Payment',
            value: 'payment'
        },
        {
            label: 'TopUp',
            value: 'topup'
        }
    ]
    const [transactionType, setTransactionType] = useState();
    const [smartBattery, setSmartBattery] = useState();
    const [loanOutstanding, setLoanOutstanding] = useState('');
    const [overdue, setOverdue] = useState('');
    const [dueLimit, setDueLimit] = useState('');
    const invalidSerial = language === 'EN'? 'Invalid ID!':'ভুল আইডি!'

    // Due limit update modal
    const [showDueLimitUpdateModal, setShowDueLimitUpdateModal] = useState(false);
    const [requiredInfoForUpdatingDueLimit, setRequiredInfoForUpdatingDueLimit] = useState('');

    const {
        COLLECT_ACTIVITIES_OF_A_GARAGE,
        COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT
    } = props;

    useEffect(()=>{
        setLanguage(props.language)
        setPage(1)
        COLLECT_ACTIVITIES_OF_A_GARAGE({'page': 1, 'garage_guid': data[0].pk})
        setGarageGuid(data[0].pk)
        setTransactionType(transactionTypes[0])
        setValue('transactionType', transactionTypes[0])
        setOverdue(new Intl.NumberFormat('en-IN').format(data[0].overdue_total.toFixed(2)))
        setDueLimit(new Intl.NumberFormat('en-IN').format(data[0].total_due_limit.toFixed(2)))

        return ()=>{
            props.RETURN_TO_INITIAL_STATE_FOR_TRANSACTIONS_AND_ACTIVITIES_MODAL()
        }
    }, [])

    const TRANSACTIONS_COLUMNS = [
        {
            field: 'sk',
            title: language === 'EN' ? 'Date' : 'তারিখ',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: 'nowrap'}}>{rowData.created_at? moment(rowData.created_at).format("MMM DD, YYYY hh:mm a"):
                        moment(rowData.sk).format("MMM DD, YYYY hh:mm a")}</span>
                </>
            }
        },
        {
            field: 'activity_for',
            title: language === 'EN' ? 'Title' : 'শিরোনাম',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                if (rowData.activity_for === 'invoice') {
                    let contactDetails = JSON.parse(rowData.invoice_details.contract_details)
                    return <>
                        {contactDetails.contract_type === 'rent'? <>
                            <span style={{whiteSpace: 'nowrap'}}><strong>{contactDetails.contract_type.charAt(0).toUpperCase() + contactDetails.contract_type.slice(1)} Payment Invoice</strong></span><br/>
                        </>:<>
                            <span style={{whiteSpace: 'nowrap'}}><strong>{rowData.invoice_details.installment_no}/{contactDetails.number_of_installments} {contactDetails.contract_type.charAt(0).toUpperCase() + contactDetails.contract_type.slice(1)} Payment Invoice</strong></span><br/>
                        </>}
                        {rowData.invoice_details? <>
                            <span style={{ whiteSpace: 'nowrap' }}>Invoice #{rowData.invoice_details.invoice_id}</span>
                        </>:''}
                    </>
                } else if (rowData.activity_for === 'payment') {
                    return <>
                        <span style={{whiteSpace: 'nowrap'}}><strong>Invoice Paid</strong></span><br/>
                        {rowData.invoice_details && rowData.payment_details? <>
                            <span style={{ whiteSpace: 'nowrap' }}>Invoice #{rowData.invoice_details.invoice_id}, Payment #{rowData.payment_details.payment_id}</span>
                        </>:<>
                            {rowData.invoice_details? <>
                                <span style={{ whiteSpace: 'nowrap' }}>Invoice #{rowData.invoice_details.invoice_id}</span>
                            </>:<>
                                {rowData.payment_details? <>
                                    <span style={{ whiteSpace: 'nowrap' }}>Invoice #{rowData.payment_details.payment_id}</span>
                                </>:''}
                            </>}
                        </>}
                    </>
                } else if (rowData.activity_for === 'topup') {
                    return <>
                        <span style={{whiteSpace: 'nowrap'}}><strong>Recharge</strong></span><br/>
                        <span style={{ whiteSpace: 'nowrap' }}>{rowData.topup_mode.charAt(0).toUpperCase() + rowData.topup_mode.slice(1)} Ref #{rowData.topup_mode === 'cash'? <>{rowData.topup_details.receipt_reference}</>:<>{rowData.topup_details.bkash_transaction_id}</>}</span>
                    </>
                }
            }
        },
        {
            field: 'amount',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            title: 'Deposit (Tk)',
            render: (rowData) => {
                return <>
                    <h6>{rowData.activity_for === 'topup'? new Intl.NumberFormat('en-IN').format(parseFloat(rowData.amount).toFixed(2)):''}</h6>
                </>
            },
        },
        {
            field: 'amount',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            title: 'Payable (Tk)',
            emptyValue: () => {
                return ""
            },
            render: (rowData) => {
                return <>
                    <h6>{rowData.activity_for === 'invoice'? new Intl.NumberFormat('en-IN').format(parseFloat(rowData.amount).toFixed(2)):''}</h6>
                </>
            },
        },
        {
            field: 'amount',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            title: 'Payment (Tk)',
            render: (rowData) => {
                return <>
                    <h6 style={{color: '#cb152b'}}>{rowData.activity_for === 'payment'? new Intl.NumberFormat('en-IN').format(parseFloat(rowData.amount).toFixed(2)):''}</h6>
                </>
            },
        },
    ]

    useEffect(()=>{
        if (props.transactions) {
            const returnedResponse = props.transactions
            if (returnedResponse.bill_total || returnedResponse.bill_total === 0) {
                setTotalBill(returnedResponse.bill_total)
            }
            if (returnedResponse.deposit_total || returnedResponse.deposit_total === 0) {
                setTotalDeposit(returnedResponse.deposit_total)
            }
            if (returnedResponse.payment_total || returnedResponse.payment_total === 0) {
                setTotalPayment(returnedResponse.payment_total)
            }
            const transactions = props.transactions.results
            if (transactions.length > -1) {
                if (transactions[0] && transactions[0].contract_details) {
                    setLoanOutstanding(new Intl.NumberFormat('en-IN').format(parseFloat(transactions[0].contract_details.outstanding_amount).toFixed(2)))
                }
                setTransactions(transactions.map((transaction) => {
                    return {
                        ...transaction
                    }
                }))
            }
        }
    }, [props.transactions])

    // ------  Pagination  ------- //
    const onChangePage = (event, newPage) => {
        setPage(newPage)
        let filter = {'page': newPage, 'garage_guid': data[0].pk}
        if (filterApplied) {
            if (transactionType) {
                if (transactionType.value !== 'all') {
                    filter['activity_for'] = transactionType.value
                }
            }
        }
        COLLECT_ACTIVITIES_OF_A_GARAGE(filter)
    }
    // ------  Pagination  ------- //



    // ------ Top section related ----- //
    const renderTopSection = () => {
        return <>
            <Toolbar>
                <Toolbar.Title>
                    <Stack spacing={2}>
                        <h4><strong>{data[0].name}, {data[0].district && data[0].upazila? data[0].district + ', ' + data[0].upazila:data[0].district? data[0].district:data[0].upazila? data[0].upazila:null}</strong></h4>
                        <Stack direction={'row'} justifyContent="flex-start" alignItems="flex-start" spacing={3} style={{color: '#8c8c9b'}}>
                            <Stack direction={'column'} justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
                                <span>Loan Outstanding</span>
                                <h6><strong>{props.collectingTransactions? '....':loanOutstanding || loanOutstanding === 0? 'Tk ' + loanOutstanding:'N/A'}</strong></h6>
                            </Stack>
                            <Stack direction={'column'} justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
                                <span>Overdue</span>
                                <h6><strong>{overdue? 'Tk ' + overdue:'N/A'}</strong></h6>
                            </Stack>
                            <Stack direction={'column'} justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
                                <span>Due Limit</span>
                                <h6><strong>Tk {dueLimit}</strong></h6>
                            </Stack>
                            <Button type={"button"} variant="outline-warning"
                                    size={'sm'} justifyContent={'flex-start'} className={'mt-3'}
                                    onClick={() => updateDueLimit(data[0])}>Edit</Button>
                        </Stack>
                    </Stack>
                </Toolbar.Title>
                <Toolbar.ToolbarContainer>
                    <Toolbar.ToolbarContainer.Items>
                        <IconButton classes={classes} onClick={exportData}
                                    disabled={props.collectingTransactions || props.collectingTransactionsForExport}>
                            {props.collectingTransactionsForExport === true ? <Spinner animation={"grow"} variant={'warning'} size={"sm"}/>:
                                <img src={require('../../../../utils/asset/download-Button.svg')} alt="Download"/>}
                        </IconButton>
                    </Toolbar.ToolbarContainer.Items>
                </Toolbar.ToolbarContainer>
            </Toolbar>
        </>
    }

    const exportData = () => {
        let filter = {'garage_guid': data[0].pk}
        if (filterApplied) {
            if (transactionType.value !== 'all') {
                filter['activity_for'] = transactionType.value
            }
        }
        COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT(filter)
    }

    useEffect(()=>{
        if (props.transactionsForExport) {
            const transactions = props.transactionsForExport
            let presentTime = moment()
            let transactionsLength = transactions.length
            let totalTransactionText = language === 'EN'? "Number of Transactions: " + transactionsLength:"লেনদেনের সংখ্যা: " + toBengaliNumber(transactionsLength)
            let exportText = language === 'EN'? "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A"):"এক্সেল ফাইল ডাউনলোডের সময়: " + presentTime.format("Do MMMM,YYYY hh:mm A")
            let address = ''
            if (data[0].upazila && data[0].district) {
                address += data[0].upazila + ', ' + data[0].district
            } else if (data[0].district) {
                address += data[0].district
            } else if (data[0].upazila) {
                address += data[0].upazila
            }
            let deposit = totalDeposit || totalDeposit === 0? 'TK ' + new Intl.NumberFormat('en-IN').format(totalDeposit.toFixed(2)):'N/A'
            let payable = totalBill || totalBill === 0? 'TK ' + new Intl.NumberFormat('en-IN').format(totalBill.toFixed(2)):'N/A'
            let payment = totalPayment || totalPayment === 0? 'TK ' + new Intl.NumberFormat('en-IN').format(totalPayment.toFixed(2)):'N/A'
            let excelRows = [
                ["Transactions of " + data[0].name + " " + address, "", "", "", ""],
                ["Deposit (Tk): " + deposit, "", "", "", ""],
                ["Payable (Tk): " + payable, "", "", "", ""],
                ["Payment (Tk): " + payment, "", "", "", ""],
                [totalTransactionText, "", "", "", ""],
                [exportText, "", "", "", ""],
                ["", "", "", "", ""],
                ["", "", "", "", ""],
                ["Date", "Title", "Deposit (Tk)", "Payable (Tk)", "Payment (Tk)"]
            ]
            if (transactions.length > 0) {
                if (filterApplied && transactionType && transactionType.value !== 'all') {
                    let text = 'Transaction Type: ' + transactionType.label
                    excelRows.splice(1, 0, [text, "", "", "", ""])
                }
                for (let i=0; i<transactionsLength; i++) {
                    let title = ''
                    let depositRef = ''
                    let payableRef = ''
                    let paymentRef = ''
                    let depositAmount = ''
                    let payableAmount = ''
                    let paymentAmount = ''
                    if (transactions[i].activity_for === 'invoice') {
                        payableRef = 'Ref #' + transactions[i].invoice_details.invoice_id
                        let contractDetails = JSON.parse(transactions[i].invoice_details.contract_details)
                        if (contractDetails.contract_type === 'rent') {
                            title = contractDetails.contract_type.charAt(0).toUpperCase() + contractDetails.contract_type.slice(1) + ' Payment Invoice'
                        } else {
                            title = transactions[i].invoice_details.installment_no + '/' + contractDetails.number_of_installments + ' ' + contractDetails.contract_type.charAt(0).toUpperCase() + contractDetails.contract_type.slice(1) + ' Payment Invoice'
                        }
                        title += ' ' + payableRef
                        payableAmount = new Intl.NumberFormat('en-IN').format(parseFloat(transactions[i].amount).toFixed(2))
                    } else if (transactions[i].activity_for === 'payment') {
                        title = 'Invoice Paid'
                        if (transactions[i].invoice_details && transactions[i].payment_details) {
                            paymentRef = 'Invoice #' + transactions[i].invoice_details.invoice_id + ', Payment #' + transactions[i].payment_details.payment_id
                        } else if (transactions[i].invoice_details) {
                            paymentRef = 'Invoice #' + transactions[i].invoice_details.invoice_id
                        } else if (transactions[i].payment_details) {
                            paymentRef = 'Payment #' + transactions[i].payment_details.payment_id
                        }
                        title += ' ' + paymentRef
                        paymentAmount = new Intl.NumberFormat('en-IN').format(parseFloat(transactions[i].amount).toFixed(2))
                    } else if (transactions[i].activity_for === 'topup') {
                        title = 'Recharge'
                        depositRef = transactions[i].topup_mode.charAt(0).toUpperCase() + transactions[i].topup_mode.slice(1) + ' Ref #'
                        if (transactions[i].topup_mode === 'cash') {
                            depositRef += transactions[i].topup_details.receipt_reference
                        } else {
                            depositRef += transactions[i].topup_details.bkash_transaction_id
                        }
                        title += ' ' + depositRef
                        depositAmount = new Intl.NumberFormat('en-IN').format(parseFloat(transactions[i].amount).toFixed(2))
                    }
                    excelRows.push(
                        [
                            moment(transactions[i].sk).format("MMM DD, YYYY hh:mm a"),
                            title,
                            depositAmount,
                            payableAmount,
                            paymentAmount
                        ])
                }
            } else {
                let noDataAvailableText =  "No data available"
                excelRows.push(["", "", noDataAvailableText, "", ""])
            }
            let fileName = 'transactions_of_a_garage_'
            exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
        }
    }, [props.transactionsForExport])
    // ------ Top section related ----- //

    // ------ Filter related ----- //
    const filterTransactionList = () => {
        let filter = {'page': 1, 'garage_guid': garageGuid}
        if (transactionType) {
            if (transactionType.value !== 'all') {
                filter['activity_for'] = transactionType.value
            }
            setFilterApplied(true)
            COLLECT_ACTIVITIES_OF_A_GARAGE(filter)
        } else {
            setFilterApplied(false)
            setError('transactionType', {"message": 'Please provide above information!'})
        }
    }

    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const onDatesChange = ({startDate, endDate}) => {
        // TODO: Need to add behavior similar to the summary page
        setStartDate(startDate)
        setEndDate(endDate)
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start}
                    endDate={end}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    )

    const renderFilterForm = () => {
        return <>
            <Form onSubmit={handleSubmit(filterTransactionList)}>
                <div className={'row g-3'}>
                    {/*<div className={'col-md-3'}>*/}
                    {/*<Form.Group>*/}
                    {/*    <Form.Label>Transaction date</Form.Label>*/}
                    {/*</Form.Group>*/}
                    {/*<OverlayTrigger rootClose trigger="click" placement="bottom-start"*/}
                    {/*                overlay={popover}>*/}
                    {/*    <SolInput*/}
                    {/*        name={'dateRangePickerInput'}*/}
                    {/*        readOnly={true}*/}
                    {/*        value={dateRange}*/}
                    {/*        placeholder={language === 'EN'? 'Select a date range':'তারিখের সীমা বাছাই করুন'}*/}
                    {/*    />*/}
                    {/*</OverlayTrigger>*/}
                    {/*</div>*/}
                    {/*<div className={'col-md-3'}>*/}
                    {/*    <Form.Group>*/}
                    {/*        <Form.Label style={{color: '#8c8c9b'}}>Smart Battery ID</Form.Label>*/}
                    {/*        <SolInput*/}
                    {/*            type={"text"}*/}
                    {/*            name={"smart_battery_id"}*/}
                    {/*            placeholder={"Type Smart Battery ID..."}*/}
                    {/*            autoComplete={"off"}*/}
                    {/*            ref={register({*/}
                    {/*                validate: {*/}
                    {/*                    isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || invalidSerial*/}
                    {/*                }*/}
                    {/*            })}*/}
                    {/*            onChange={(e) => {*/}
                    {/*                setSmartBattery(e.target.value)*/}
                    {/*                if (errors) {*/}
                    {/*                    clearErrors()*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*        {errors.smart_battery_id && <div className="text-danger">*/}
                    {/*            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.smart_battery_id.message}</div>}*/}
                    {/*    </Form.Group>*/}
                    {/*</div>*/}
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <Form.Group>
                                <Form.Label style={{color: '#8c8c9b'}}>Transaction Type</Form.Label>
                                <Controller
                                    control={control}
                                    name={"transactionType"}
                                    render={( { onChange, onBlur, value, name, ref },
                                              { invalid, isTouched, isDirty }) => (
                                        <Select
                                            placeholder={'Select Type ...'}
                                            classNamePrefix="react-select-sol-style"
                                            maxMenuHeight={200}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            value={transactionType}
                                            options={transactionTypes}
                                            isSearchable={true}
                                            onChange={(selected, {action}) => {
                                                if (action === "clear") {
                                                    setTransactionType("")
                                                }
                                                clearErrors()
                                                onChange(selected)
                                                if (selected) {
                                                    setTransactionType(selected)
                                                }
                                            }}
                                        />
                                    )}
                                />
                                {errors.transactionType && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.transactionType.message}</div>}
                            </Form.Group>
                        </Form.Group>
                    </div>
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <Button variant="outline-warning" size={'md'} type="button" disabled={props.collectingTransactions}
                                    className={"mt-8"} onClick={filterTransactionList}>
                                {language === 'EN'? 'Apply filter':'ফিল্টার করুন'}
                            </Button>
                        </Form.Group>
                    </div>
                </div>
            </Form>
        </>
    }
    // ------ Filter related ----- //

    // ------ Due Limit update Modal ----- //
    const updateDueLimit = (data) => {
        setShowDueLimitUpdateModal(true)
        setRequiredInfoForUpdatingDueLimit(data)
    }

    const hideDueLimitModal = (data) => {
        setShowDueLimitUpdateModal(false)
    }

    const updateDueLimitRepresentation = (data) => {
        setDueLimit(new Intl.NumberFormat('en-IN').format(data))
        dueLimitUpdateTracker(true)
    }

    const renderDueLimitUpdateModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideDueLimitModal}
                centered={true}
                modal={showDueLimitUpdateModal}
                title={<h1><strong>Edit Due Limit</strong></h1>}>
                <UpdateDueLimitOfAGarage from={'transactionModal'} data={requiredInfoForUpdatingDueLimit} updateDueLimitRepresentation={updateDueLimitRepresentation}/>
            </GenericModal>
        </>
    }
    // ------ Due Limit update Modal ----- //

    // ------ Total information section ------ //
    const renderTotalInformationSection = () => {
        return <>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={filterApplied && transactionType.value === 'topup'? 13:filterApplied && transactionType.value === 'invoice'? 10:8}
                style={{color: "#8c8c9b"}}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={0.5}
                >
                    <span>DEPOSIT</span>
                    <h6><strong>{totalDeposit || totalDeposit === 0? 'TK ' + new Intl.NumberFormat('en-IN').format(totalDeposit.toFixed(2)):'N/A'}</strong></h6>
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={0.5}
                >
                    <span>BILL</span>
                    <h6><strong>{totalBill || totalBill === 0? 'TK ' + new Intl.NumberFormat('en-IN').format(totalBill.toFixed(2)):'N/A'}</strong></h6>
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={0.5}
                >
                    <span>PAYMENT</span>
                    <h6><strong>{totalPayment || totalPayment === 0? 'TK ' + new Intl.NumberFormat('en-IN').format(totalPayment.toFixed(2)):'N/A'}</strong></h6>
                </Stack>
            </Stack>
        </>
    }
    // ------ Total information section ------ //

    return (
        <div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    {renderTopSection()}
                </div>
            </div>
            <div className={'row mt-10'}>
                <div className={'col-md-12'}>
                    <Form onSubmit={handleSubmit(filterTransactionList)}>
                        {renderFilterForm()}
                    </Form>
                </div>
            </div>
            {!props.collectingTransactions && transactions.length > 0? <>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <div className={'mr-5'}>
                            {renderTotalInformationSection()}
                        </div>
                    </div>
                </div>
            </>:null}
            <div className={transactions.length > 0? 'row':'row mt-5'}>
                <div className={'col-md-12'}>
                    {!props.collectingTransactions? <>
                        <DataTable
                            language={language}
                            columns={TRANSACTIONS_COLUMNS}
                            data={transactions}
                            showToolbar={false}
                            asyncPagination={true}
                            isLoading={props.collectingTransactions}
                            pagination={true}
                            count={props.transactions?.count}
                            itemsPerPage={props.transactions?.page_size}
                            onChangePage={onChangePage}
                            page={page}
                            noDataAvailableMessageInEnglish={"No transactions available"}
                        />
                    </>:<LoadingSpinner
                        loadingSubText={language === 'EN' ? 'Collecting transactions ..' : 'লেনদেনের তালিকা প্রস্তুত হচ্ছে ..'}
                        language={language} />}
                </div>
            </div>
            {renderDueLimitUpdateModal()}
        </div>
    );
};

ActivitiesOfAGarage.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        providedSmartBatteries: state.borrowersReducer.providedSmartBatteries,
        smartBatteryInfoCollectionInProgress: state.borrowersReducer.smartBatteryInfoCollectionInProgress,
        transactions: state.borrowersReducer.transactions,
        collectingTransactions: state.borrowersReducer.collectingTransactions,
        transactionsForExport: state.borrowersReducer.transactionsForExport,
        collectingTransactionsForExport: state.borrowersReducer.collectingTransactionsForExport
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(ActivitiesOfAGarage));