import React from "react";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import '../styles/typographyStyles.css'

export const GenericModal = ({
    modal, 
    hideModal,
    takeAction,
    title,
    footer,
    footerCloseButtonText,
    footerButtonSize,
    footerActionButtonText,
    size,
    noSubmitButton,
    showModalHeader,
    hideCrossButton,
    centered,
    backdropClassName,
    ...props
})=> {

    return <>
        <Modal 
            show={modal} 
            onHide={hideModal} 
            animation={true} 
            size={size || undefined} 
            backdropClassName={backdropClassName} 
            centered={centered}
        >
            {(showModalHeader === true) &&
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title >
                    {hideCrossButton? null:<button type="button" className="close" onClick={hideModal} aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close"/>
                    </button>}
                </Modal.Header>
            }
            <Modal.Body>
                {props.children}
            </Modal.Body>
            {footer ?<>
                <Modal.Footer>
                    <Button style={{
                        backgroundColor: '#6c757d',
                        border: 'none',
                    }} size={footerButtonSize? footerButtonSize: 'md'} disabled={props.disableButton} onClick={hideModal}>
                        <i className="fa fa-window-close"/>
                        &nbsp;{footerCloseButtonText? footerCloseButtonText: 'Close'}
                    </Button>
                    {noSubmitButton?null:<Button variant="primary" size={footerButtonSize? footerButtonSize: 'md'}
                                                 type="submit" disabled={props.disableButton} onClick={takeAction}>
                        {props.disableButton ? <Spinner animation="border" size={footerButtonSize? footerButtonSize: 'md'}
                                                        variant="secondary"/> :
                            <i className="fa fa-paper-plane"/>}
                        &nbsp;{footerActionButtonText}
                    </Button>}
                </Modal.Footer>
            </>:null}
        </Modal>
    </>
}
