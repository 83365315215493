
export const createFSAConverter = (successType, failureType) => {
    return response => {
        if (!response.ok) {
            return {
                error: true,
                payload: {
                    status: response.status,
                    errorsMassage: response.json(),
                },
                type: failureType
            }
        }

        const contentType = response.headers.get("Content-Type");
        const emptyCodes = [204, 205];

        const createSuccessType = payload => ({
            payload,
            type: successType
        })

        if (
            emptyCodes.indexOf(response.status) === -1 &&
            contentType &&
            contentType.indexOf("json") !== -1
        ) {
            return response.json().then(createSuccessType);
        } else {
            return createSuccessType();
        }
    }
};


export function dispatchFSAAction(next) {

    return response => {
        // The API call returned unauthorized user (access token is expired)

        if (response.error){

            response.payload.errorsMassage.then((res)=>{
                next({
                    ...response,
                    payload: {
                        ...response.payload,
                        error: res,
                    }
                })
            })
        } else{
            return next(response);
        }

    };
}
