import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */

    COLLECT_DISTRICTS: (divisionId)=>({
        [CALL_API]: {
            endpoint: '/geo/districts/' + divisionId,
            method: 'GET',
            types: [
                actionTypes.COLLECT_DISTRICTS_REQUEST,
                actionTypes.COLLECT_DISTRICTS_SUCCESS,
                actionTypes.COLLECT_DISTRICTS_FAILURE,
            ]
        }
    }),

    COLLECT_UPAZILAS_FOR_ALL: (districtId)=>({
        [CALL_API]: {
            endpoint: '/geo/upazilas/' + districtId,
            method: 'GET',
            types: [
                actionTypes.COLLECT_UPAZILAS_FOR_ALL_REQUEST,
                actionTypes.COLLECT_UPAZILAS_FOR_ALL_SUCCESS,
                actionTypes.COLLECT_UPAZILAS_FOR_ALL_FAILURE,
            ]
        }
    }),

    COLLECT_UPAZILAS_FOR_BORROWER: (districtId)=>({
        [CALL_API]: {
            endpoint: '/geo/upazilas/' + districtId,
            method: 'GET',
            types: [
                actionTypes.COLLECT_UPAZILAS_FOR_BORROWER_REQUEST,
                actionTypes.COLLECT_UPAZILAS_FOR_BORROWER_SUCCESS,
                actionTypes.COLLECT_UPAZILAS_FOR_BORROWER_FAILURE,
            ]
        }
    }),

    COLLECT_UPAZILAS_FOR_GARAGE: (districtId)=>({
        [CALL_API]: {
            endpoint: '/geo/upazilas/' + districtId,
            method: 'GET',
            types: [
                actionTypes.COLLECT_UPAZILAS_FOR_GARAGE_REQUEST,
                actionTypes.COLLECT_UPAZILAS_FOR_GARAGE_SUCCESS,
                actionTypes.COLLECT_UPAZILAS_FOR_GARAGE_FAILURE,
            ]
        }
    }),

    COLLECT_THANAS_FOR_BORROWER: (cityCorporationId)=>({
        [CALL_API]: {
            endpoint: '/geo/thanas/' + cityCorporationId,
            method: 'GET',
            types: [
                actionTypes.COLLECT_THANAS_FOR_BORROWER_REQUEST,
                actionTypes.COLLECT_THANAS_FOR_BORROWER_SUCCESS,
                actionTypes.COLLECT_THANAS_FOR_BORROWER_FAILURE,
            ]
        }
    }),

    COLLECT_THANAS_FOR_GARAGE: (cityCorporationId)=>({
        [CALL_API]: {
            endpoint: '/geo/thanas/' + cityCorporationId,
            method: 'GET',
            types: [
                actionTypes.COLLECT_THANAS_FOR_GARAGE_REQUEST,
                actionTypes.COLLECT_THANAS_FOR_GARAGE_SUCCESS,
                actionTypes.COLLECT_THANAS_FOR_GARAGE_FAILURE,
            ]
        }
    }),

    COLLECT_BORROWER_LIST_FOR_FILTER_FORM: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/users`, filter),
            method: 'GET',
            types: [
                actionTypes.COLLECT_BORROWER_LIST_FOR_FILTER_FORM_REQUEST,
                actionTypes.COLLECT_BORROWER_LIST_FOR_FILTER_FORM_SUCCESS,
                actionTypes.COLLECT_BORROWER_LIST_FOR_FILTER_FORM_FAILURE,
            ]
        }
    }),

    COLLECT_GARAGE_LIST_FOR_TABLE: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/garages`, filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_GARAGE_LIST_FOR_TABLE_REQUEST,
                actionTypes.COLLECT_GARAGE_LIST_FOR_TABLE_SUCCESS,
                actionTypes.COLLECT_GARAGE_LIST_FOR_TABLE_FAILURE,
            ]
        }
    }),

    COLLECT_GARAGE_LIST_FOR_SELECT_FIELD: ()=>({
        [CALL_API]: {
            endpoint: `/garages`,
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_GARAGE_LIST_FOR_SELECT_FIELD_REQUEST,
                actionTypes.COLLECT_GARAGE_LIST_FOR_SELECT_FIELD_SUCCESS,
                actionTypes.COLLECT_GARAGE_LIST_FOR_SELECT_FIELD_FAILURE,
            ]
        }
    }),

    REGISTER_BORROWER: (payload)=>({
        [CALL_API]: {
            endpoint: '/garages',
            method: 'POST',
            backend_slug: 'garage_management',
            body: payload,
            formDataFlag: true,
            types: [
                actionTypes.REGISTER_BORROWER_REQUEST,
                actionTypes.REGISTER_BORROWER_SUCCESS,
                actionTypes.REGISTER_BORROWER_FAILURE
            ]
        }
    }),

    EDIT_BORROWER: (garageGuid, payload)=>({
        [CALL_API]: {
            endpoint: '/garage/' + garageGuid,
            method: 'PUT',
            backend_slug: 'garage_management',
            body: payload,
            formDataFlag: true,
            types: [
                actionTypes.EDIT_BORROWER_REQUEST,
                actionTypes.EDIT_BORROWER_SUCCESS,
                actionTypes.EDIT_BORROWER_FAILURE
            ]
        }
    }),

    COLLECT_GARAGE_DETAILS: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/garage/detail?garage_guid=', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_GARAGE_DETAILS_REQUEST,
                actionTypes.COLLECT_GARAGE_DETAILS_SUCCESS,
                actionTypes.COLLECT_GARAGE_DETAILS_FAILURE,
            ]
        }
    }),

    COLLECT_PROVIDED_SMART_BATTERIES_INFO: (garageGuid)=>({
        [CALL_API]: {
            endpoint: '/devices?garage_guid=' + garageGuid,
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.COLLECT_PROVIDED_SMART_BATTERIES_INFO_REQUEST,
                actionTypes.COLLECT_PROVIDED_SMART_BATTERIES_INFO_SUCCESS,
                actionTypes.COLLECT_PROVIDED_SMART_BATTERIES_INFO_FAILURE,
            ]
        }
    }),

    COLLECT_ACTIVITIES_OF_A_GARAGE: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/activities`, filter),
            method: 'GET',
            backend_slug: 'transaction_management_v2',
            types: [
                actionTypes.COLLECT_ACTIVITIES_OF_A_GARAGE_REQUEST,
                actionTypes.COLLECT_ACTIVITIES_OF_A_GARAGE_SUCCESS,
                actionTypes.COLLECT_ACTIVITIES_OF_A_GARAGE_FAILURE,
            ]
        }
    }),

    COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/activities`, filter),
            method: 'GET',
            backend_slug: 'transaction_management_v2',
            types: [
                actionTypes.COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT_REQUEST,
                actionTypes.COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT_SUCCESS,
                actionTypes.COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT_FAILURE,
            ]
        }
    }),

    COLLECT_TRANSACTIONS_OF_A_GARAGE: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/payment`, filter),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_TRANSACTIONS_OF_A_GARAGE_REQUEST,
                actionTypes.COLLECT_TRANSACTIONS_OF_A_GARAGE_SUCCESS,
                actionTypes.COLLECT_TRANSACTIONS_OF_A_GARAGE_FAILURE,
            ]
        }
    }),

    COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/payment`, filter),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT_REQUEST,
                actionTypes.COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT_SUCCESS,
                actionTypes.COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT_FAILURE,
            ]
        }
    }),

    UPDATE_GARAGE_ACTIVATION_STATUS: (payload)=>({
        [CALL_API]: {
            endpoint: '/garage_activation',
            method: 'POST',
            backend_slug: 'garage_management',
            body: payload,
            types: [
                actionTypes.UPDATE_GARAGE_ACTIVATION_STATUS_REQUEST,
                actionTypes.UPDATE_GARAGE_ACTIVATION_STATUS_SUCCESS,
                actionTypes.UPDATE_GARAGE_ACTIVATION_STATUS_FAILURE,
            ]
        }
    }),

    UPDATE_DUE_LIMIT: (payload)=>({
        [CALL_API]: {
            endpoint: '/garages/change_garage_due_limit',
            method: 'PUT',
            backend_slug: 'garage_management',
            body: payload,
            types: [
                actionTypes.UPDATE_DUE_LIMIT_REQUEST,
                actionTypes.UPDATE_DUE_LIMIT_SUCCESS,
                actionTypes.UPDATE_DUE_LIMIT_FAILURE,
            ]
        }
    }),

    DUE_LIMIT_CHANGE_HISTORY: (garageGuid)=>({
        [CALL_API]: {
            endpoint: '/garages/due_limit_change_history?garage_guid=' + garageGuid,
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.DUE_LIMIT_CHANGE_HISTORY_REQUEST,
                actionTypes.DUE_LIMIT_CHANGE_HISTORY_SUCCESS,
                actionTypes.DUE_LIMIT_CHANGE_HISTORY_FAILURE,
            ]
        }
    }),

    MANUAL_CASH_PAYMENT: (payload)=>({
        [CALL_API]: {
            endpoint: '/cash_payment',
            method: 'POST',
            backend_slug: 'transaction_management',
            body: payload,
            formDataFlag: true,
            types: [
                actionTypes.MANUAL_CASH_PAYMENT_REQUEST,
                actionTypes.MANUAL_CASH_PAYMENT_SUCCESS,
                actionTypes.MANUAL_CASH_PAYMENT_FAILURE,
            ]
        }
    }),

    COLLECT_INVOICES: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/invoices', filter),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_INVOICES_REQUEST,
                actionTypes.COLLECT_INVOICES_SUCCESS,
                actionTypes.COLLECT_INVOICES_FAILURE,
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE}),

    RETURN_TO_INITIAL_STATE_FOR_TRANSACTIONS_AND_ACTIVITIES_MODAL: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE_FOR_TRANSACTIONS_AND_ACTIVITIES_MODAL}),

    RETURN_TO_INITIAL_STATE_FOR_MANUAL_CASH_PAYMENT: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE_FOR_MANUAL_CASH_PAYMENT})
};
