import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Form, Button, Spinner, Badge } from 'react-bootstrap';
import { SolInput, DataTableContainer, DataTable, SolTypography, LoadingSpinner } from '../../../../components';
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import DateTimeFormat from '../../../../utils/dateFormat/DateTimeFormat';

const RentDetailsModalContent = ({ data, ...props }) => {
    const [language, setLanguage] = useState("EN");
    const { control, register, setError, errors, setValue, handleSubmit } = useForm();

    const {
        GET_LAST_RENT_BY_DEVICE,
        CLEAN_UP_LAST_RENT_MODAL,
    } = props;

    useEffect(()=>{
        GET_LAST_RENT_BY_DEVICE(data.serial_number);

        return ()=>{
            CLEAN_UP_LAST_RENT_MODAL();
        }
    }, [])

    const TOP_COLUMNS = [
        {
            field: 'ev_details',
            title: language === 'EN' ? 'Smart Battery' : 'ডিভাইস আইডি',
            emptyValue: () => {
                return "-"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            width: "20%",
            render: (rowData) => {
                return <>
                    <SolTypography.Title primary>{rowData.serial_number}</SolTypography.Title>
                    <SolTypography.Text>SOLdongle</SolTypography.Text><br />
                    <SolTypography.Text>#{rowData?.ev_details?.dongle_sl}, FW {rowData?.dongle_details?.firmware_version ? `v${rowData?.dongle_details?.firmware_version}`:"--" }, HW {rowData?.dongle_details?.hardware_version ? `v${rowData?.dongle_details?.hardware_version}`:"--" }</SolTypography.Text><br />
                    <SolTypography.Text><b>Battery</b> #{rowData?.ev_details?.battery_sl}</SolTypography.Text><br />
                    <SolTypography.Text>{rowData?.battery_details?.battery_vendor}  {rowData?.battery_details?.nameplate_voltage?`${rowData?.battery_details?.nameplate_voltage}V,`:""} {rowData?.battery_details?.nameplate_capacity?`${rowData?.battery_details?.nameplate_capacity}Ah`:""}</SolTypography.Text><br />

                </>
            }
            // disableClick: true,
        },
        {
            field: 'garage_details',
            title: language === 'EN' ? 'Garage' : 'ডিভাইস আইডি',
            emptyValue: () => {
                return "-"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            render: (rowData) => {
                return <>
                    <SolTypography.SubTitle >{rowData?.garage_details?.garage_name}</SolTypography.SubTitle>
                    {/* <SolTypography.Text>Tongi</SolTypography.Text> */}

                </>
            }
            // disableClick: true,
        },
        {
            field: 'driver_details',
            title: language === 'EN' ? 'Driver' : 'ডংগল সিরিয়াল নম্বর',
            emptyValue: () => {
                return "N/A"
            },
            render: (rowData) => {
                return <>
                    <SolTypography.SubTitle>{rowData?.driver_details?.driver_name}</SolTypography.SubTitle>
                    <SolTypography.Text>EV #{rowData?.ev_details?.ev_sl} </SolTypography.Text>

                </>
            },
        },

    ]
    const BOTTOM_COLUMNS = [
        {
            field: 'start_time',
            title: language === 'EN' ? 'Rented On' : 'ডিভাইস আইডি',
            emptyValue: () => {
                return "-"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            width: "20%",
            render: (rowData) => {
                return <>
                    <SolTypography.Title>{new DateTimeFormat(rowData.start_time).localDisplayTime}</SolTypography.Title>
                    <SolTypography.Text secondary>{rowData.start_battery_charge}% SOC, {rowData.start_battery_voltage}V</SolTypography.Text><br />

                </>
            }
            // disableClick: true,
        },
        {
            field: 'rent_hours',
            title: language === 'EN' ? 'Rented For' : 'ডিভাইস আইডি',
            emptyValue: () => {
                return "-"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            render: (rowData) => {
                return <>
                    <SolTypography.Title >{rowData?.rent_hours.toFixed(2)}h</SolTypography.Title>
                </>
            }
            // disableClick: true,
        },
        {
            field: 'end_time',
            title: language === 'EN' ? 'Rent Ended On' : 'ডংগল সিরিয়াল নম্বর',
            emptyValue: () => {
                return "N/A"
            },
            render: (rowData) => {
                return <>
                    <SolTypography.Title>{new DateTimeFormat(rowData.end_time).localDisplayTime}</SolTypography.Title>
                    <SolTypography.Text secondary>by {rowData.last_user_name}</SolTypography.Text><br />
                    <SolTypography.Text secondary>{rowData.end_battery_charge}% SOC, {rowData.end_battery_voltage}V</SolTypography.Text><br />

                </>
            },
        },
        {
            field: 'status',
            title: language === 'EN' ? 'Status' : 'ডংগল সিরিয়াল নম্বর',
            emptyValue: () => {
                return "N/A"
            },
            lookup: {
                done: <Badge variant='success'>Completed</Badge>,
                active: <Badge variant='warning'>Active</Badge>
            }
        },

    ]

    const helperInfoRenderer = () => {
        if (props.modaldataLoading === true) {
            return <LoadingSpinner loadingSubText={language == 'EN' ? `Fetching last rent information of #${data?.serial_number}` : 'যন্ত্রের তালিকা প্রস্তুত হচ্ছে'} language={language} />
        }
        else {
            return <div className={'d-flex justify-content-center'}>
                <h1>
                    <Badge variant="secondary">No Rent Data Available</Badge>
                </h1>
            </div>
        }
    }


    return (
        <div>

            <div className={'row'}>
                <div className={'col-md-12'}>

                    {props.modaldataLoading !== true && props.lastRentDetails && Object.keys(props.lastRentDetails).length>0 ? <>
                        {/* <SolTypography.Title secondary className={'mb-4'}>Recent History</SolTypography.Title> */}
                        <DataTable
                            language={language}
                            noDataAvailableMessageInBangla={'কোন যন্ত্র নেই'}
                            columns={TOP_COLUMNS}
                            data={[{...props.lastRentDetails}]}
                            showToolbar={false}
                            asyncPagination={false}
                            isLoading={props.tableLoading}
                            pagination={false}
                            pageSize={1}
                        />
                        <div style={{ paddingTop: "5rem" }}>
                            <SolTypography.Title secondary >Last Rent Schedule</SolTypography.Title>
                            <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন যন্ত্র নেই'}
                                columns={BOTTOM_COLUMNS}
                                data={[{...props.lastRentDetails}]}
                                showToolbar={false}
                                asyncPagination={false}
                                isLoading={props.tableLoading}
                                pagination={false}
                                pageSize={1}
                            />
                        </div>
                    </> : helperInfoRenderer()}
                </div>
            </div>


            {/* <div className={'row'}>
                <div className={'col-md-12'}>


                </div>
            </div> */}
        </div>





    );
};

RentDetailsModalContent.propTypes = {

};


const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.devicesReducer.isLoading,
        buttonLoading: state.devicesReducer.buttonLoading,
        lastRentDetails: state.devicesReducer.lastRentDetails,
        modaldataLoading: state.devicesReducer.modaldataLoading,
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(RentDetailsModalContent));