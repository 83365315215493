export const paramsHandler = (endpoint, filterParams={}) => {
    let endPointWithParams = endpoint;
    if (!(Object.entries(filterParams).length < 1)) {
        for (const [key, value] of Object.entries(filterParams)) {
            if (key !== 'searchRequest')
            {
                if (!endPointWithParams.includes("?")) {
                    endPointWithParams += value?`?${key}=${value}`:'';
                } else {
                    endPointWithParams += value?`&${key}=${value}`:'';
                }
            }
        }
    }
    return endPointWithParams;
}
