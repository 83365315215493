import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import DriverList from "./views/driverList/driverList";
import CreateDriver from "./views/newDriver/CreateDriver";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const DriversRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/drivers"}
                to={"/drivers/list"}
            />

            {/* Routes to Driver List*/}
            <SecuredRoute
                path={"/drivers/list"}
                component={DriverList}
                service={getServiceName('garageService')}
                feature={getFeatureName('driverFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
