import React, {useState} from 'react';
import {connect} from 'react-redux';
import "../customStyle.css"
import AutoCompleteComponent from "./autoCompleteComponent";


const SearchFieldForMobile = ({ ...props }) => {
    const [language, setLanguage] = useState("EN");

    const {
        COLLECT_EV_LIST
    } = props;


    return (
        <>
            <div className={'customStyleForSearchInMobile'}>
                <AutoCompleteComponent fieldId={'mobile-search-field'}
                                       placeholder={'Search...'}/>
            </div>
        </>
    );
};

SearchFieldForMobile.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations
    }
}

export default connect(mapStateToProps)(SearchFieldForMobile);