export const httpResponseCode = [
    {
        "code": 400,
        "message": "Sorry, the request could not be understood by the server! Please contact your support team."
    },
    {
        "code": 401,
        "message": "Sorry, for the inconvenience! Please refresh the page."
    },
    {
        "code": 403,
        "message": "Sorry, you do not have access rights to the content!"
    },
    {
        "code": 404,
        "message": "Sorry, the requested resource cannot be found!"
    },
    {
        "code": 500,
        "message": "Sorry, the server cannot process the request for an unknown reason. Please contact your support team."
    },
    {
        "code": 502,
        "message": "Sorry, the server got an invalid response! Please contact your support team."
    },
    {
        "code": 503,
        "message": "Sorry, the server is either overloaded, down or under maintenance! Please contact your support team."
    },
    {
        "code": 504,
        "message": "Sorry, the server did not get any response within allowed time! Please try again later or contact your support team."
    },
];