import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import {DatePicker} from '@material-ui/pickers/'
import {divisionOptions} from "../../../utils/countryData/solDivision";
import {Button} from "react-bootstrap";
import {ThemeProvider} from '@material-ui/styles';
import {toast} from "react-toastify"
import {isNullorUndefined, isNumber, validateValue} from "../../../utils/utilityFunctions";
import moment from "moment";
import {withRoles} from "../../../router/SecuredRoute";

const useStylesForDatePicker = makeStyles({
    root: {
        marginTop: '0',
        marginBottom: '0',
        border: 'none',
        width: '100%',
        '& input': {
            color: 'black',
            padding: "0 10px 0 10px",
            height: "38px",
            outline: "none",
        },
        '& input:hover, input:focus, input:active': {
            border: "1px solid #F18D00",
            boxShadow: "0px 0px 0px 3.2px r gba(241, 141, 0, 0.1)",
            outline: "none",
        }
    },
});


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#ffffff',
        }
    },
});

export const Toolbar = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
`;

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: .40rem .55rem .40rem .55rem;
  background-color: #ffffff;
  /* Global/01. Primary */
  border: ${props => !props.dullComponent ? '1px solid #F18D00' : '1px solid #8C8C9B'};
  box-sizing: border-box;
  border-radius: 4px;


`;

Toolbar.ToolbarContainer = styled.div`
  grid-column: 10/-1;
`;
Toolbar.Title = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1/9;
`
Toolbar.ToolbarContainer.Items = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
`;


export const Filter = styled.div`
  margin: 35px 0 0 0;
  padding: 0;
`;


const FormContainer = ({gridOwners, setListFilters, resetFilter, ...props}) => {
    const {register, handleSubmit, errors, setError, clearErrors, control} = useForm();
    const [calendarView] = useState(['year', 'month']);
    const [monthYear, setMonthYear] = useState(null);
    const [division, setDivision] = useState(null);
    const [org, setOrg] = useState(null);
    const [filter, setFilter] = useState({});
    const [initial1filterParam, setInitial1filterParam] = useState(null);
    const classes = useStylesForDatePicker();


    const validateDate = (validateParams) => {
        const year = parseInt(validateParams.year)
        const month = parseInt(validateParams.month) - 1
        const todayMonth = moment().month();
        const todayYear = moment().year();

        if (isNumber(year) && isNumber(month)) {
            if (todayYear === year && todayMonth >= month && month >= 0) {
            } else if (todayYear > year && month < 12 && month >= 0) {
            } else {
                delete validateParams.year
                delete validateParams.month
            }
        } else {
            delete validateParams.year
            delete validateParams.month
        }
        return validateParams
    }
    const validationKeyArray = [

        {
            parameter: 'organization_guid',
            list: gridOwners,
        },
        {
            parameter: 'division',
            list: divisionOptions,
        }
        ,
        {
            parameter: 'year',
            validationFunction: (keyword) => keyword,
        },
        {
            parameter: 'month',
            validationFunction: (keyword) => keyword,
        },
    ];

    useEffect(() => {
        let keys = null;
        let validParameters = {};

        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(props.projectedParams);
        } catch (e) {
            console.log(e)
            console.log(props.projectedParams)

            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {

            /* Looping through each of the value in the keys. (Essentially This loop will run for every keys in the projected params object) */

            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const currentIndex = validationKeyArray.findIndex((element) => {
                    return element.parameter === item;
                });

                if (currentIndex > -1 && !isNullorUndefined(validateValue(props.projectedParams[item], validationKeyArray[currentIndex]))) {
                    // console.log(validationKeyArray[currentIndex].list);
                    Object.assign(validParameters, {[`${item}`]: validateValue(props.projectedParams[item], validationKeyArray[currentIndex])})
                }
            })
        }


        validParameters = validateDate(validParameters);
        setInitial1filterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)

        /*Now we validated the */


        /* Now if the valid Params object has at least one parameter we set the filter */

    }, [props.projectedParams]);

    useEffect(() => {
        if (!isNullorUndefined(initial1filterParam)) {

            const frontEndFilterPayload = {}

            if (initial1filterParam.organization_guid) {
                setOrg(initial1filterParam.organization_guid);
                Object.assign(frontEndFilterPayload, {organization_guid: initial1filterParam.organization_guid.value,})
            }

            if (initial1filterParam.division) {
                setDivision(initial1filterParam.division);
                Object.assign(frontEndFilterPayload, {division: initial1filterParam.division.value})
            }
            if (initial1filterParam.month && initial1filterParam.year) {

                setMonthYear(new Date(initial1filterParam.year,initial1filterParam.month))
                Object.assign(frontEndFilterPayload, {month: parseInt(initial1filterParam.month)});
                Object.assign(frontEndFilterPayload, {year: initial1filterParam.year})
            }

            setFilter(frontEndFilterPayload)
            setListFilters(frontEndFilterPayload);
            console.log(frontEndFilterPayload);
            setInitial1filterParam(null);
        }
    }, [initial1filterParam])

    const validateForm = (data) => {
        if (!monthYear && !division && !org) {

            setError("validationError", {
                type: "manual",
                message: "Dont Forget Your Username Should Be Cool!"
            });
            toast.error("Please select at least one filter")
            // if (!monthYear) {
            //     setError("time", 'required', 'Required')
            // }
            //
            // if (!division) {
            //     setError("division", 'required', 'Required')
            //
            // }
            //
            // if (!org) {
            //     setError("org", 'required', 'Required')
            //
            // }
            return false
        } else {
            return true
        }
    }

    const submit = (data) => {
        if (validateForm(data) === true) {
            const payload = {
                division: division?.value || undefined,
                organization_guid: org?.value || undefined,
                year: monthYear?.getFullYear() || undefined,
                month: monthYear?.getMonth()  || undefined,
            }
            console.log(payload);
            setFilter({
                ...payload,
                month: isNumber(payload.month)? payload.month: undefined,
            })
            setListFilters({
                ...payload,
                month: isNumber(payload.month)? payload.month: undefined,
            });
        }
    }


    const resetForm = () => {
        if (Object.keys(filter).length > 0) {
            setDivision(null)
            setMonthYear(null)
            setOrg(null)
            resetFilter()
        }
    }


    return <form onSubmit={handleSubmit(submit)}>
        <div className={'col-md-12 no-padding'}>
            {/*<div className="col-md-9 no-padding">*/}
            {/*    {errors.validationError && }*/}
            {/*</div>*/}
            <div className={'row'}>
                <div className={'col-md-3'}>
                    <Select
                        name={`org`}
                        placeholder={`Grid operator`}
                        classNamePrefix="react-select-sol-style"
                        isDisabled={!props.isSolshare}
                        isLoading={false}
                        maxMenuHeight={200}
                        isClearable={true}
                        options={gridOwners}
                        value={org}
                        control={control}
                        isSearchable={true}
                        onChange={(selected) => {
                            setOrg(selected)
                            clearErrors()
                            return selected
                        }}
                    />
                    {errors.org && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{errors.org.message}</div>}
                </div>
                <div className={'col-md-3'}>
                    <Select
                        name={`division`}
                        placeholder={`Division`}
                        classNamePrefix="react-select-sol-style"
                        isDisabled={false}
                        isLoading={false}
                        maxMenuHeight={200}
                        value={division}
                        isClearable={true}
                        control={control}
                        options={divisionOptions}
                        isSearchable={true}
                        onChange={(selected) => {
                            setDivision(selected)
                            clearErrors()
                            return selected
                        }}
                    />
                    {errors.division && <div className={"text-danger"}>
                        <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{errors.division.message}</div>}
                </div>
                <div className={'col-md-3'}>
                    <ThemeProvider theme={theme}>
                        <DatePicker
                            margin='normal'
                            name={'time'}
                            inputVariant={'outlined'}
                            placeholder={"Installation Month"}
                            className={classes.root}
                            value={monthYear}
                            disableFuture={true}
                            views={calendarView}
                            control={control}
                            format={'MMMM, yyyy'}
                            clearable={true}
                            onChange={(value) => {
                                setMonthYear(value)
                                clearErrors()
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        {errors.time && <div className={"text-danger"}>
                            <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{errors.time.message}</div>}
                    </ThemeProvider>

                </div>
                <div className={'col-md-3'}>

                </div>
            </div>

            <div className={'col-md-3 no-padding'} style={{
                marginTop: '1.5rem',
            }}>


                <Button name={'filterSubmit'} className={'btn btn-outline-warning'} size={'sm'} id={'filterSubmit'}
                        type='submit'>
                    Apply Filter
                </Button>

                <Button name={'reset'} id={'reset'} size={'sm'}
                        className={'btn btn-outline-secondary reset-button-style'} type={'button'} dullComponent={true}
                        onClick={resetForm}>
                    Reset Filter
                </Button>
            </div>

        </div>

        <style jsx>{`
          .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active {
            border: 1px solid #F18D00;
            box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
            outline: none;
          }
        `}</style>
    </form>
}



export default withRoles(FormContainer)

