export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',
    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',

    GET_BATTERY_LOG_REQUEST: 'GET_BATTERY_LOG_REQUEST',
    GET_BATTERY_LOG_SUCCESS: 'GET_BATTERY_LOG_SUCCESS',
    GET_BATTERY_LOG_FAILURE: 'GET_BATTERY_LOG_FAILURE'
};
