import React, {useEffect, useState, useRef} from 'react';
import {connect, useSelector} from "react-redux";
import {useSubheader} from "../../../../../_metronic/layout";
import moment from "moment";
import {actions} from "../../actions";
import {withRoles} from "../../../../router/SecuredRoute";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import {useMediaQuery} from "react-responsive";
import { makeStyles } from '@material-ui/styles';
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Pagination from '@mui/material/Pagination';
import Typography from "@mui/material/Typography";
import Plot from "react-plotly.js";
import {DataTable} from "../../../../components";
import {DataTableContainerForLoanDetails} from "../../../contractManagement/utils";
import Skeleton from "@mui/material/Skeleton";
import CardActions from "@mui/material/CardActions";


const BranchWisePerformanceOverview = ({data, ...props}) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    // ============================
    // State variables for Graph
    // These variables hold the current state of the graph data and settings
    // ============================
    const [yAxisForPaidInfo, setYAxisForPaidInfo] = useState([]) // Sample data format: ['BATAKANDI', 'Bakter Munshi', 'Pahartoli', 'Senbagh']
    const [xAxisForPaidInfo, setXAxisForPaidInfo] = useState([]) // Sample data format: [18, 25, 89, 38]
    const [textLabelForPaidInfo, setTextLabelForPaidInfo] = useState([]) // Sample data format: ['18%', '25%', '89%', '38%']
    const [yAxisForOverdueInfo, setYAxisForOverdueInfo] = useState([]) // Sample data format: ['BATAKANDI', 'Bakter Munshi', 'Pahartoli', 'Senbagh']
    const [xAxisForOverdueInfo, setXAxisForOverdueInfo] = useState([]) // Sample data format: [25, 20, 35, 18]
    const [textLabelForOverdueInfo, setTextLabelForOverdueInfo] = useState([]) // Sample data format: ['25%', '20%', '35%', '18%']

    const [yAxisForInvestmentInfo, setYAxisForInvestmentInfo] = useState([]) // Sample data format: ['BATAKANDI', 'Bakter Munshi', 'Pahartoli', 'Senbagh']
    const [xAxisForInvestmentInfo, setXAxisForInvestmentInfo] = useState([]) // Sample data format: [37499, 65000, 120000, 150000]
    const [textLabelForInvestmentInfo, setTextLabelForInvestmentInfo] = useState([]) // Sample data format: ['BDT 37,499', 'BDT 65,000', 'BDT 1,20,000', 'BDT 1,50,000']
    const [yAxisForPortfolioAtRiskInfo, setYAxisForPortfolioAtRiskInfo] = useState([]) // Sample data format: ['BATAKANDI', 'Bakter Munshi', 'Pahartoli', 'Senbagh']
    const [xAxisForPortfolioAtRiskInfo, setXAxisForPortfolioAtRiskInfo] = useState([]) // Sample data format: [35000, 20000, 28000, 20000]
    const [textLabelForPortfolioAtRiskInfo, setTextLabelForPortfolioAtRiskInfo] = useState([]) // Sample data format: ['BDT 35,000', 'BDT 20,000', 'BDT 28,000', 'BDT 20,000']


    // ============================
    // Variables and functions related to the Horizontal Branch bar charts
    // These holds configurations related to the charts
    // ============================
    // Layout of the graphs
    const layout = {
        xaxis: {
            showgrid: false,
            showline: false,
            zeroline: false
        },
        yaxis: {
            showgrid: false,
            showline: false,
            zeroline: false,
            automargin: true,
            autorange: 'reversed', // This will reverse the yaxis values as plotly by default show the highest value at the bottom [we want to show it at the top]
            ticklabelposition: "inside", // Best position to show y-axis label with spacing
            ticklabeloffset: 10 // Adjust this value to set the spacing
        },
        legend: {
            x: 0, // Position at the left
            y: 1.1, // Position above the chart
            xanchor: 'left', // Anchor the legend's x position to the left
            yanchor: 'bottom', // Anchor the legend's y position to the bottom
            orientation: 'h', // Orient the legend horizontally,
            traceorder: 'normal' // Ensure the legend items are displayed in the order they appear in the data array
        },
        autosize: true, // Ensures the chart adjusts its size dynamically
        hovermode: 'closest'
    }
    // Pattern and configuration for the "Collection vs Overdue" graph data
    const paymentRelatedData = [
        {
            y: yAxisForPaidInfo,
            x: xAxisForPaidInfo,
            name: 'Paid',
            type: 'bar',
            orientation: 'h',
            text: textLabelForPaidInfo,    // Text labels for the bars
            textposition: 'auto',  // Let Plotly automatically determine the best position
            cliponaxis: false,      // Prevent text from being clipped
            marker: {
                color: '#0B6E4F'
            },
            hoverinfo: 'x+y+text', // Determines which trace information appear on hover
            hovertemplate: '<b>Branch:</b> %{y}<br><b>Paid:</b> %{x}<br><extra></extra>' // Custom tooltip content
        },
        {
            y: yAxisForOverdueInfo,
            x: xAxisForOverdueInfo,
            name: 'Overdue',
            type: 'bar',
            orientation: 'h',
            text: textLabelForOverdueInfo,    // Text labels for the bars
            textposition: 'auto',  // Let Plotly automatically determine the best position
            cliponaxis: false,      // Prevent text from being clipped
            marker: {
                color: '#BCBCCD'
            },
            hoverinfo: 'x+y+text', // Determines which trace information appear on hover
            hovertemplate: '<b>Branch:</b> %{y}<br><b>Overdue:</b> %{x}<br><extra></extra>' // Custom tooltip content
        }
    ]
    // Function to settle text position at the "Investment vs Portfolio at Risk" graph
    const determineTextPositionForOrangeBar = (dataSet, highestValue, highestNumberOfData) => {
        return dataSet.map((value) => {
            // Formula: highest value of dataset1 and dataset2 / highest number of data within two dataset
            // It helps to determine how many value will be printed at x-axis. We will show text outside if the value is within 0 and very next value
            return value >= (highestValue/highestNumberOfData)? 'inside':'outside'
        })
    }
    // Function to settle colors of the text at the "Investment vs Portfolio at Risk" graph
    const determineColorsForOrangeBar = (textPositions) => {
        return textPositions.map((position) => {
            return position === 'inside'? 'white':'inherit'
        })
    }
    // Pattern and configuration for the "Investment vs Portfolio at Risk" graph data
    const investmentRelatedData = [
        {
            y: yAxisForInvestmentInfo,
            x: xAxisForInvestmentInfo,
            name: 'Total Investment',
            type: 'bar',
            orientation: 'h',
            text: textLabelForInvestmentInfo,    // Text labels for the bars
            textposition: determineTextPositionForOrangeBar(xAxisForInvestmentInfo, xAxisForInvestmentInfo[0], 5),
            cliponaxis: false,  // Prevent text from being clipped
            marker: {
                color: '#F18D00'
            },
            textfont: {
                color: determineColorsForOrangeBar(determineTextPositionForOrangeBar(xAxisForInvestmentInfo, xAxisForInvestmentInfo[0], 5)) // Set the text color to white
            },
            hoverinfo: 'x+y+text', // Determines which trace information appear on hover
            hovertemplate: '<b>Branch:</b> %{y}<br><b>Investment:</b> %{x}<br><extra></extra>', // Custom tooltip content
            hoverlabel: {
                font: {
                    color: '#ffffff' // Set hover text color to white
                }
            }
        },
        {
            y: yAxisForPortfolioAtRiskInfo,
            x: xAxisForPortfolioAtRiskInfo,
            name: 'Total Portfolio at Risk',
            type: 'bar',
            orientation: 'h',
            text: textLabelForPortfolioAtRiskInfo,    // Text labels for the bars
            textposition: 'auto',  // Let Plotly automatically determine the best position
            cliponaxis: false,      // Prevent text from being clipped
            marker: {
                color: '#BCBCCD'
            },
            hoverinfo: 'x+y+text', // Determines which trace information appear on hover
            hovertemplate: '<b>Branch:</b> %{y}<br><b>At risk:</b> %{x}<br><extra></extra>' // Custom tooltip content
        }
    ]

    // ============================
    // Variables related to the "Investment details" table
    // These holds configurations and state variables related to the Investment details table
    // ============================
    // Columns for the investment details table
    const investmentDetailsTableColumns = [
        {
            field: 'branch_name',
            title: "Branches",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <span>{rowData.branch_name}</span><br/>
                    <span className={'small'}>{rowData.financier_name}</span>
                </>
            }
        },
        {
            field: 'total_batteries',
            title: "Total Batteries",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            disableClick: true,
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.total_batteries}</strong></h6>
                </>
            }
        },
        {
            field: 'investment_amount',
            title: "Investment",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.investment_amount}</strong></h6>
                </>
            }
        },
        {
            field: 'collection',
            title: "Collection",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.collection}</strong></h6>
                    <span>{rowData.collection_percentage}</span>
                </>
            }
        },
        {
            field: 'total_overdue',
            title: "Total Overdue",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6 className={'text-danger'}><strong>{rowData.total_overdue}</strong></h6>
                </>
            }
        },
        {
            field: 'risk_amount',
            title: "Portfolio At Risk",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6 className={'text-danger'}><strong>{rowData.risk_amount}</strong></h6>
                    <span>{rowData.risk_percentage}</span>
                </>
            }
        }
    ]
    // Data variable for the table
    const [investmentDetails, setInvestmentDetails] = useState(undefined)

    // ============================
    // Variables and functions related to the "Graph" cards
    // These holds configurations, functions and state variables related to the different cards shown at the UI
    // There are three cards - graph card, table card and skeleton UI card
    // ============================
    // Styles related to the graph card
    const useStyles = makeStyles((theme) => ({
        pagination: {
            '& .Mui-selected': {
                backgroundColor: '#F18D00',
                color: '#fff', // Set text color to white for contrast
                '&:hover': {
                    backgroundColor: '#F18D00', // Keep color constant on hover
                },
            },
        },
    }));
    const classes = useStyles();
    const graphicalCard = (title, data, layout, countOfThePages, pageOfTheGraph, paginationEventHandling) => {
        return <>
            <Card sx={{ width: '100%', minHeight: '120px'}}>
                <CardContent sx={{ paddingBottom: '0px' }}>
                    <Typography variant="subtitle2" component="div">
                        {title}
                    </Typography>
                    <Plot
                        data={data}
                        useResizeHandler={true} // This property makes the Plotly chart responsive to window resizing.
                        style={{width: '100%', height: '100%'}} // setting height to 100% has some effects that can't be noticed instantly
                        layout={{...layout}}
                        config={isTabletOrMobile? {
                            displayModeBar: false  // Make the mode bar (download, zoom etc. icons) inaccessible in mobile view, in desktop view it becomes visible when user scrolls over the graph
                        }:{}}
                    />
                    <CardActions sx={{display: "flex", justifyContent: "flex-end"}} className={'ml-2 mr-2'}>
                        <Pagination
                            size={"small"}
                            className={classes.pagination}
                            count={countOfThePages}
                            page={pageOfTheGraph}
                            onChange={paginationEventHandling}
                        />
                    </CardActions>
                </CardContent>
            </Card>
        </>
    }

    // Skeleton UI card for the graph
    const graphicalCardSkeleton = (title) => {
        return <>
            <Card sx={{ width: '100%', minHeight: '120px'}}>
                <CardContent sx={{ paddingBottom: '0px' }}>
                    <Typography variant="subtitle2" component="div">
                        {title}
                    </Typography>
                    <Skeleton variant="rect" sx={{ marginTop: '10px', width: '100%' }} height={440}/>
                </CardContent>
            </Card>
        </>
    }

    // Card for Investment details
    const investmentDetailsCard = () => {
        return <>
            <Card sx={{ width: '100%', minHeight: '120px'}}>
                <CardContent sx={{ paddingBottom: '0px' }}>
                    <Typography variant="subtitle2" component="div">
                        Branch-wise Investment Details
                    </Typography>
                    <div className={'mt-8'}>
                        {props.collectingBranchPerformanceInfo || !investmentDetails? <>
                            <Skeleton variant="rect" sx={{ width: '100%' }} height={369}/>
                        </>:<>
                            <DataTableContainerForLoanDetails>
                                <DataTable
                                    language={props.language}
                                    noDataAvailableMessageInEnglish={"No investment details information available"}
                                    columns={investmentDetailsTableColumns}
                                    data={investmentDetails}
                                    showToolbar={false}
                                    asyncPagination={false}
                                    pagination={true}
                                    pageSize={5}
                                />
                            </DataTableContainerForLoanDetails>
                        </>}
                    </div>
                </CardContent>
            </Card>
        </>
    }

    // ============================
    // useEffect hooks, state variables and functions related to loading and showing data at the UI
    // These holds hooks, functions and state variables related to showing information at the UI
    // ============================
    // State variables related to the pagination of the graphs
    const [paginatedCollectionVsOverdueData, setPaginatedCollectionVsOverdueData] = useState(undefined) // Sample data format: [{1:{...}, 2:{...}}]
    const [countOfPagesCollectionVsOverdue, setCountOfPagesCollectionVsOverdue] = useState(0)
    const [currentPageCollectionVsOverdue, setCurrentPageCollectionVsOverdue] = useState(1)

    const [paginatedInvestmentVsRiskPortfolioData, setPaginatedInvestmentVsRiskPortfolioData] = useState(undefined) // Sample data format: [{1:{...}, 2:{...}}]
    const [countOfPagesInvestmentVsPortfolioRisk, setCountOfPagesInvestmentVsPortfolioRisk] = useState(0)
    const [currentPageInvestmentVsPortfolioRisk, setCurrentPageInvestmentVsPortfolioRisk] = useState(1)
    // Function to handle pagination at the graphs
    const handlePageChangeCollectionVsOverDue = (event, value) => {
        // Updating data to show in UI
        saveDataForCollectionVsOverdueGraph(paginatedCollectionVsOverdueData, value)
        // Updating page count
        setCurrentPageCollectionVsOverdue(value)
    };
    const handlePageChangeInvestmentVsPortfolioRisk = (event, value) => {
        // Updating data to show in UI
        saveDataForInvetsmentVsRiskPortfolioGraph(paginatedInvestmentVsRiskPortfolioData, value)
        // Updating page count
        setCurrentPageInvestmentVsPortfolioRisk(value)
    };

    // Function to use for mapping data for graphs
    const mapDataForGraphs = (data, yAxisForAll, xAxisForFirstDataOfCollectionGraph, textLabelForFirstDataOfCollectionGraph,
                     xAxisForSecondDataOfCollectionGraph, textLabelForSecondDataOfCollectionGraph,
                     xAxisForFirstDataOfInvestmentGraph, textLabelForFirstDataOfInvestmentGraph,
                     xAxisForSecondDataOfInvestmentGraph, textLabelForSecondDataOfInvestmentGraph) => {
        let branchName = Object.keys(data)[0]
        let paidPercentage = data[branchName]["total_paid_percentage"]? parseFloat(data[branchName]["total_paid_percentage"].toFixed(2)):0
        let overduePercentage = data[branchName]["total_overdue_percentage"]? parseFloat(data[branchName]["total_overdue_percentage"].toFixed(2)):0
        let totalInvestment = data[branchName]["total_investment"]? parseFloat(data[branchName]["total_investment"].toFixed(2)):0
        let totalRiskPortfolio = data[branchName]["portfolio_at_risk"]? parseFloat(data[branchName]["portfolio_at_risk"].toFixed(2)):0
        yAxisForAll.push(branchName)
        // Collection vs Overdue
        xAxisForFirstDataOfCollectionGraph.push(paidPercentage)
        textLabelForFirstDataOfCollectionGraph.push(paidPercentage + "%")
        xAxisForSecondDataOfCollectionGraph.push(overduePercentage)
        textLabelForSecondDataOfCollectionGraph.push(overduePercentage + "%")
        // Investment vs Portfolio At Risk
        xAxisForFirstDataOfInvestmentGraph.push(totalInvestment)
        textLabelForFirstDataOfInvestmentGraph.push('BDT ' + new Intl.NumberFormat('en-IN').format(totalInvestment))
        xAxisForSecondDataOfInvestmentGraph.push(totalRiskPortfolio)
        textLabelForSecondDataOfInvestmentGraph.push('BDT ' + new Intl.NumberFormat('en-IN').format(totalRiskPortfolio))
    }

    // Function which adds line breaks at the y axis labels
    const addLineBreaks = (label, breakAt = 20) => {
        const regex = new RegExp(`.{1,${breakAt}}`, 'g');
        return label.match(regex).join('<br>');
    }

    // Functions to use to save the mapped data at state variables
    const saveDataForCollectionVsOverdueGraph = (paginatedData, page) => {
        // At first checking if paginatedData contains empty object
        // To deal with case {"branch_wise_summary": []}
        if (Object.keys(paginatedData).length > 0) {
            // Updating collection related info
            setXAxisForPaidInfo(paginatedData[page]['xAxisForCollection'])
            setYAxisForPaidInfo(paginatedData[page]['yAxisForCollection'].map(label => addLineBreaks(label)))
            setTextLabelForPaidInfo(paginatedData[page]['textLabelForCollection'])
            // Updating overdue related info
            setXAxisForOverdueInfo(paginatedData[page]['xAxisForOverdue'])
            setYAxisForOverdueInfo(paginatedData[page]['yAxisForOverdue'].map(label => addLineBreaks(label)))
            setTextLabelForOverdueInfo(paginatedData[page]['textLabelForOverdue'])
        } else {
            // Updating collection related info
            setXAxisForPaidInfo([])
            setYAxisForPaidInfo([])
            setTextLabelForPaidInfo([])
            // Updating overdue related info
            setXAxisForOverdueInfo([])
            setYAxisForOverdueInfo([])
            setTextLabelForOverdueInfo([])
        }
    }

    const saveDataForInvetsmentVsRiskPortfolioGraph = (paginatedData, page) => {
        // At first checking if paginatedData contains empty object
        // To deal with case {"branch_wise_summary": []}
        if (Object.keys(paginatedData).length > 0) {
            // Updating investment related info
            setXAxisForInvestmentInfo(paginatedData[page]['xAxisForInvestment'])
            setYAxisForInvestmentInfo(paginatedData[page]['yAxisForInvestment'].map(label => addLineBreaks(label)))
            setTextLabelForInvestmentInfo(paginatedData[page]['textLabelForInvestment'])
            // Updating Risk Portfolio related info
            setXAxisForPortfolioAtRiskInfo(paginatedData[page]['xAxisForRiskPortfolio'])
            setYAxisForPortfolioAtRiskInfo(paginatedData[page]['yAxisForRiskPortfolio'].map(label => addLineBreaks(label)))
            setTextLabelForPortfolioAtRiskInfo(paginatedData[page]['textLabelRiskPortfolio'])
        } else {
            // Updating investment related info
            setXAxisForInvestmentInfo([])
            setYAxisForInvestmentInfo([])
            setTextLabelForInvestmentInfo([])
            // Updating Risk Portfolio related info
            setXAxisForPortfolioAtRiskInfo([])
            setYAxisForPortfolioAtRiskInfo([])
            setTextLabelForPortfolioAtRiskInfo([])
        }
    }

    // Function to use for mapping data for the table
    const mapDataForTable = (arrayToSave, data) => {
        let branchName = Object.keys(data)[0]
        let paidPercentage = data[branchName]["total_paid_percentage"]? parseFloat(data[branchName]["total_paid_percentage"].toFixed(2)):0
        let overduePercentage = data[branchName]["total_overdue_percentage"]? parseFloat(data[branchName]["total_overdue_percentage"].toFixed(2)):0
        let totalInvestment = data[branchName]["total_investment"]? parseFloat(data[branchName]["total_investment"].toFixed(2)):0
        let totalRiskPortfolio = data[branchName]["portfolio_at_risk"]? parseFloat(data[branchName]["portfolio_at_risk"].toFixed(2)):0
        arrayToSave.push({
                'branch_name': branchName,
                'financier_name': data[branchName]["Organization"]? data[branchName]["Organization"]:'',
                'total_batteries': data[branchName]["number_of_contracts"]? data[branchName]["number_of_contracts"]:'',
                'investment_amount': 'BDT ' + new Intl.NumberFormat('en-IN').format(totalInvestment),
                'collection': 'BDT ' + new Intl.NumberFormat('en-IN').format(data[branchName]["total_paid"]),
                'collection_percentage': paidPercentage? paidPercentage + "%":'',
                'total_overdue': 'BDT ' + new Intl.NumberFormat('en-IN').format(data[branchName]["total_overdue"]),
                'risk_amount': 'BDT ' + new Intl.NumberFormat('en-IN').format(totalRiskPortfolio),
                'risk_percentage': overduePercentage? overduePercentage + "%":''
        })
    }

    // Mapping data passed into the component
    useEffect(() => {
        if (data) {
            if (data["branch_wise_summary"]) {
                let unsortedRawData = data["branch_wise_summary"]

                // Sorting based on the investment amount in descending order
                const branchData = unsortedRawData.sort((a, b) => {
                    const totalInvestmentA = Object.values(a)[0].total_investment;
                    const totalInvestmentB = Object.values(b)[0].total_investment;
                    return totalInvestmentB - totalInvestmentA; // Sort descending by total_investment
                });

                // --------------- Begin: Constructing data for graphs and table ---------------- //
                let dataCount = branchData.length
                let pageCount = 0
                if (dataCount % 5 === 0) {
                    pageCount = dataCount / 5
                } else {
                    pageCount = parseInt(dataCount / 5) + 1
                }

                // "Collection vs Overdue" graph
                let paginatedDataForCollectionGraph = {}

                // "Investment vs Portfolio at Risk" graph
                let paginatedDataForInvestmentGraph = {}

                // Branch wise investment details - table
                let branchInvestmentDetails = []

                // Constructing data for UI
                let pageNumber = 0
                for (let i=0; i< branchData.length; i += 5) {
                    let yAxisForGraphs = []
                    // "Collection vs Overdue" graph
                    let xAxisForPaid = []
                    let textLabelForPaid = []
                    let xAxisForOverdue = []
                    let textLabelForOverdue = []

                    // "Investment vs Portfolio at Risk" graph
                    let xAxisForInvestment = []
                    let textLabelForInvestment = []
                    let xAxisForRiskPortfolio = []
                    let textLabelForRiskPortfolio = []

                    // ============================
                    // Saving i-th branch related data
                    // ============================
                    mapDataForGraphs(branchData[i], yAxisForGraphs, xAxisForPaid, textLabelForPaid, xAxisForOverdue, textLabelForOverdue,
                        xAxisForInvestment, textLabelForInvestment, xAxisForRiskPortfolio, textLabelForRiskPortfolio)
                    mapDataForTable(branchInvestmentDetails, branchData[i])
                    // ============================
                    // Saving (i + 1)-th branch related data
                    // ============================
                    if (branchData[i + 1]) {
                        mapDataForGraphs(branchData[i + 1], yAxisForGraphs, xAxisForPaid, textLabelForPaid, xAxisForOverdue, textLabelForOverdue,
                            xAxisForInvestment, textLabelForInvestment, xAxisForRiskPortfolio, textLabelForRiskPortfolio)
                        mapDataForTable(branchInvestmentDetails,branchData[i + 1])
                    }
                    // ============================
                    // Saving (i + 2)-th branch related data
                    // ============================
                    if (branchData[i + 2]) {
                        mapDataForGraphs(branchData[i + 2], yAxisForGraphs, xAxisForPaid, textLabelForPaid, xAxisForOverdue, textLabelForOverdue,
                            xAxisForInvestment, textLabelForInvestment, xAxisForRiskPortfolio, textLabelForRiskPortfolio)
                        mapDataForTable(branchInvestmentDetails,branchData[i +2])
                    }
                    // ============================
                    // Saving (i + 3)-th branch related data
                    // ============================
                    if (branchData[i + 3]) {
                        mapDataForGraphs(branchData[i + 3], yAxisForGraphs, xAxisForPaid, textLabelForPaid, xAxisForOverdue, textLabelForOverdue,
                            xAxisForInvestment, textLabelForInvestment, xAxisForRiskPortfolio, textLabelForRiskPortfolio)
                        mapDataForTable(branchInvestmentDetails,branchData[i + 3])
                    }
                    // ============================
                    // Saving (i + 4)-th branch related data
                    // ============================
                    if (branchData[i + 4]) {
                        mapDataForGraphs(branchData[i + 4], yAxisForGraphs, xAxisForPaid, textLabelForPaid, xAxisForOverdue, textLabelForOverdue,
                            xAxisForInvestment, textLabelForInvestment, xAxisForRiskPortfolio, textLabelForRiskPortfolio)
                        mapDataForTable(branchInvestmentDetails,branchData[i + 4])
                    }

                    pageNumber += 1
                    // Saving paginated data for Collection vs Overdue Graph
                    paginatedDataForCollectionGraph[pageNumber] = {
                        'xAxisForCollection': xAxisForPaid,
                        'yAxisForCollection': yAxisForGraphs,
                        'textLabelForCollection': textLabelForPaid,
                        'xAxisForOverdue': xAxisForOverdue,
                        'yAxisForOverdue': yAxisForGraphs,
                        'textLabelForOverdue': textLabelForOverdue
                    }
                    // Saving paginated data for Investment vs Portfolio at Risk Graph
                    paginatedDataForInvestmentGraph[pageNumber] = {
                        'xAxisForInvestment': xAxisForInvestment,
                        'yAxisForInvestment': yAxisForGraphs,
                        'textLabelForInvestment': textLabelForInvestment,
                        'xAxisForRiskPortfolio': xAxisForRiskPortfolio,
                        'yAxisForRiskPortfolio': yAxisForGraphs,
                        'textLabelRiskPortfolio': textLabelForRiskPortfolio
                    }
                }

                // ============================
                // Collection vs Overdue - graph
                // ============================
                setPaginatedCollectionVsOverdueData(paginatedDataForCollectionGraph)
                setCountOfPagesCollectionVsOverdue(pageCount)
                // Setting first page to view at first
                saveDataForCollectionVsOverdueGraph(paginatedDataForCollectionGraph, 1)

                // ============================
                // Investment vs Portfolio at Risk - graph
                // ============================
                setPaginatedInvestmentVsRiskPortfolioData(paginatedDataForInvestmentGraph)
                setCountOfPagesInvestmentVsPortfolioRisk(pageCount)
                // Setting first page to view at first
                saveDataForInvetsmentVsRiskPortfolioGraph(paginatedDataForInvestmentGraph, 1)

                // Branch wise investment details - table
                setInvestmentDetails(branchInvestmentDetails)
                // --------------- End: Constructing data for graphs and table ---------------- //
            } else {
                // Collection vs Overdue - graph
                setPaginatedCollectionVsOverdueData({})

                // Investment vs Portfolio at Risk - graph
                setPaginatedInvestmentVsRiskPortfolioData({})

                // Branch wise investment details - table
                setInvestmentDetails([])
            }
        }
    },[data])

    return (
        <>
            {/* Branch related graphs */}
            <div className={'row'}>
                {/* Collection vs Overdue graph */}
                <div className={isTabletOrMobile? 'col-md-6':'col-md-6 pl-0'}>
                    {props.collectingBranchPerformanceInfo ||
                    !paginatedCollectionVsOverdueData? <>
                        {graphicalCardSkeleton('Collection vs Overdue')}
                    </>:<>
                        {graphicalCard('Collection vs Overdue', paymentRelatedData, {
                            ...layout,
                            xaxis: {
                                ticksuffix: '%', // Add suffix to text
                            }
                        }, countOfPagesCollectionVsOverdue, currentPageCollectionVsOverdue, handlePageChangeCollectionVsOverDue)}
                    </>}
                </div>

                {/* Investment vs Portfolio At Risk graph */}
                <div className={isTabletOrMobile? 'col-md-6 mt-2':'col-md-6'}>
                    {props.collectingBranchPerformanceInfo ||
                    !paginatedInvestmentVsRiskPortfolioData? <>
                        {graphicalCardSkeleton('Investment vs Portfolio at Risk')}
                    </>:<>
                        {graphicalCard('Investment vs Portfolio at Risk', investmentRelatedData, {
                            ...layout,
                            xaxis: {
                                tickprefix: 'BDT ', // Add prefix to text
                            }
                        }, countOfPagesInvestmentVsPortfolioRisk, currentPageInvestmentVsPortfolioRisk, handlePageChangeInvestmentVsPortfolioRisk)}
                    </>}
                </div>
            </div>

            {/* Investment table */}
            <div className={'row mt-2'}>
                <div className={isTabletOrMobile? 'col-md-12':'col-md-12 pl-0'}>
                    {investmentDetailsCard()}
                </div>
            </div>
        </>
    );
}

BranchWisePerformanceOverview.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        collectingBranchPerformanceInfo: state.overviewReducer.collectingBranchPerformanceInfo
    }
}

export default connect(mapStateToProps, {...actions})(withRoles(BranchWisePerformanceOverview))
