import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Switch} from "react-router-dom";
import SecuredRoute from "../../router/SecuredRoute";
import ManualTransactions from "./view/manualTransactions";
import UploadTransactions from "./view/uploadTransactions";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";

export const ManualTransactionsRouter = props => {

    return (
        <div>
            <Switch>
                <Redirect exact={"true"} from={'/transactions'} to={'/transactions/list'} />

                <SecuredRoute
                    path={'/transactions/list'}
                    component={ManualTransactions}
                    service={getServiceName('transactionService')}
                    feature={getFeatureName('manualTransactionFeature')}
                    action={getActionName('listAction')}
                />

                <SecuredRoute
                    path={"/transactions/create"}
                    component={UploadTransactions}
                    service={getServiceName('transactionService')}
                    feature={getFeatureName('manualTransactionFeature')}
                    action={getActionName('createAction')}
                />
                <Redirect to="/error/error-v1" />
            </Switch>
        </div>
    );
};
