import { Redirect, Switch } from "react-router-dom";
import React from "react";
import CreateTransaction from "./views/createTransaction/createTransaction";
import SecuredRoute from "../../router/SecuredRoute";
import { getServiceName } from "../../utils/roleRelatedValues/serviceNames";
import { getFeatureName } from "../../utils/roleRelatedValues/featureNames";
import { getActionName } from "../../utils/roleRelatedValues/actionNames";
import Transactions from "./views/transactionList/Transactions";
import Payments from "./views/payments";
import TransactionSettlement from "./views/transactionSettlement/TransactionSettlement";
import UploadPayments from "./views/uploadPayments/uploadPayments";
import MobileWalletList from "./views/mobileWalletList/mobileWalletList";


{/*TODO: For now user who has access to the checkout process can access the transaction/bKash payment process*/ }
export const TransactionRouter = (props) => {

    return (
        <Switch>
            <Redirect
                exact={"true"}
                from={"/payments"}
                to={"/payments/list"}
            />

            <SecuredRoute
                path={"/payments/list"}
                component={Payments}
                service={getServiceName('transactionService')}
                feature={getFeatureName('transactionFeature')}
                action={getActionName('listAction')}
            />

            <SecuredRoute
                path={"/payments/v2/list"}
                component={Payments}
                service={getServiceName('transactionService')}
                feature={getFeatureName('transactionFeature')}
                action={getActionName('listAction')}
            />

            <SecuredRoute
                path={"/payments/wallet/list"}
                component={MobileWalletList}
                service={getServiceName('transactionService')}
                feature={getFeatureName('mobileWalletFeature')}
                action={getActionName('listAction')}
            />

            <SecuredRoute
                path={"/payments/create"}
                component={CreateTransaction}
                exact={true}
                service={getServiceName('transactionService')}
                feature={getFeatureName('mobileWalletFeature')}
                action={getActionName('listAction')}
            />

            {/*<SecuredRoute*/}
            {/*    path={"/payments/settlements"}*/}
            {/*    exact={true}*/}
            {/*    component={TransactionSettlement}*/}
            {/*    service={getServiceName('transactionService')}*/}
            {/*    feature={getFeatureName('settlementFeature')}*/}
            {/*    action={getActionName('listAction')}*/}
            {/*/>*/}

            {/*<SecuredRoute*/}
            {/*    path={"/payments/settlements/upload"}*/}
            {/*    component={UploadPayments}*/}
            {/*    exact={true}*/}
            {/*    service={getServiceName('transactionService')}*/}
            {/*    feature={getFeatureName('settlementFeature')}*/}
            {/*    action={getActionName('updateAction')}*/}
            {/*/>*/}

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
