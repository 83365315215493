import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    districts: undefined,
    upazilas: undefined,
    upazilasForBorrower: undefined,
    upazilasForGarage: undefined,
    thanas: undefined,
    thanasForGarage: undefined,
    borrowers: undefined,
    garagesForTable: undefined,
    errorMessageForGaragesForTableCollection: undefined,
    garagesForSelectField: undefined,
    errorMessageForGaragesForSelectFieldCollection: undefined,
    garageDetails: undefined,
    providedSmartBatteries: undefined,
    updateGarageActivation: undefined,
    statusUpdateFailed: false,
    transactions: undefined,
    transactionsForExport: undefined,
    garageDueLimitUpdated: undefined,
    manualCashPaymentSaved: undefined,
    successMessageForManualCashPaymentProcess: undefined,
    errorMessageForManualCashPaymentProcess: undefined,
    borrowerSaved: undefined,
    borrowerEdited: undefined,
    invoices: undefined,
    invoicesCollectionRelatedErrorMessage: undefined
};

const initialStateForTransactionsAndActivitiesModal= {
    transactions: undefined,
    transactionsForExport: undefined
};

const initialStateForManualCashPaymentProcess= {
    invoices: undefined,
    invoicesCollectionRelatedErrorMessage: undefined
};

/*Reducer for Borrower's redux store*/
export const borrowersReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.COLLECT_DISTRICTS_REQUEST:
            return {
                ...state,
                districts: undefined,
                collectingDistricts: true
            };
        case actionTypes.COLLECT_DISTRICTS_SUCCESS:
            return {
                ...state,
                districts: action.payload,
                collectingDistricts: false
            };
        case actionTypes.COLLECT_DISTRICTS_FAILURE:
            toast.error("Can't collect the district list! Please contact with admin.");
            return {
                ...state,
                collectingDistricts: false
            };

        case actionTypes.COLLECT_UPAZILAS_FOR_ALL_REQUEST:
            return {
                ...state,
                upazilas: undefined,
                collectingUpazilas: true
            };
        case actionTypes.COLLECT_UPAZILAS_FOR_ALL_SUCCESS:
            return {
                ...state,
                upazilas: action.payload,
                collectingUpazilas: false
            };
        case actionTypes.COLLECT_UPAZILAS_FOR_ALL_FAILURE:
            return {
                ...state,
                collectingUpazilas: false
            };

        case actionTypes.COLLECT_UPAZILAS_FOR_BORROWER_REQUEST:
            return {
                ...state,
                upazilasForBorrower: undefined,
                collectingUpazilasForBorrower: true
            };
        case actionTypes.COLLECT_UPAZILAS_FOR_BORROWER_SUCCESS:
            return {
                ...state,
                upazilasForBorrower: action.payload,
                collectingUpazilasForBorrower: false
            };
        case actionTypes.COLLECT_UPAZILAS_FOR_BORROWER_FAILURE:
            return {
                ...state,
                collectingUpazilasForBorrower: false
            };

        case actionTypes.COLLECT_UPAZILAS_FOR_GARAGE_REQUEST:
            return {
                ...state,
                upazilasForGarage: undefined,
                collectingUpazilasForGarage: true
            };
        case actionTypes.COLLECT_UPAZILAS_FOR_GARAGE_SUCCESS:
            return {
                ...state,
                upazilasForGarage: action.payload,
                collectingUpazilasForGarage: false
            };
        case actionTypes.COLLECT_UPAZILAS_FOR_GARAGE_FAILURE:
            return {
                ...state,
                collectingUpazilasForGarage: false
            };

        case actionTypes.COLLECT_THANAS_FOR_BORROWER_REQUEST:
            return {
                ...state,
                thanas: undefined,
                collectingThanas: true
            };
        case actionTypes.COLLECT_THANAS_FOR_BORROWER_SUCCESS:
            return {
                ...state,
                thanas: action.payload,
                collectingThanas: false
            };
        case actionTypes.COLLECT_THANAS_FOR_BORROWER_FAILURE:
            toast.error("Can't collect the thana list! Please contact with admin.");
            return {
                ...state,
                collectingThanas: false
            };

        case actionTypes.COLLECT_THANAS_FOR_GARAGE_REQUEST:
            return {
                ...state,
                thanasForGarage: undefined,
                collectingThanasForGarage: true
            };
        case actionTypes.COLLECT_THANAS_FOR_GARAGE_SUCCESS:
            return {
                ...state,
                thanasForGarage: action.payload,
                collectingThanasForGarage: false
            };
        case actionTypes.COLLECT_THANAS_FOR_GARAGE_FAILURE:
            toast.error("Can't collect the thana list! Please contact with admin.");
            return {
                ...state,
                collectingThanasForGarage: false
            };

        case actionTypes.COLLECT_BORROWER_LIST_FOR_FILTER_FORM_REQUEST:
            return {
                ...state,
                borrowers: undefined,
                borrowersCollectionInProgress: true
            };
        case actionTypes.COLLECT_BORROWER_LIST_FOR_FILTER_FORM_SUCCESS:
            return {
                ...state,
                borrowers: action.payload,
                borrowersCollectionInProgress: false
            };
        case actionTypes.COLLECT_BORROWER_LIST_FOR_FILTER_FORM_FAILURE:
            toast.error("Can't collect the borrower list! Please contact with an admin.");
            return {
                ...state,
                borrowersCollectionInProgress: false
            };

        case actionTypes.COLLECT_GARAGE_LIST_FOR_TABLE_REQUEST:
            return {
                ...state,
                garagesForTable: undefined,
                errorMessageForGaragesForTableCollection: undefined,
                garagesForTableCollectionInProgress: true
            };
        case actionTypes.COLLECT_GARAGE_LIST_FOR_TABLE_SUCCESS:
            return {
                ...state,
                garagesForTable: action.payload,
                garagesForTableCollectionInProgress: false
            };
        case actionTypes.COLLECT_GARAGE_LIST_FOR_TABLE_FAILURE:
            return {
                ...state,
                errorMessageForGaragesForTableCollection: action.payload.error,
                garagesForTableCollectionInProgress: false
            };

        case actionTypes.COLLECT_GARAGE_LIST_FOR_SELECT_FIELD_REQUEST:
            return {
                ...state,
                garagesForSelectField: undefined,
                errorMessageForGaragesForSelectFieldCollection: undefined,
                garagesForSelectFieldCollectionInProgress: true
            };
        case actionTypes.COLLECT_GARAGE_LIST_FOR_SELECT_FIELD_SUCCESS:
            return {
                ...state,
                garagesForSelectField: action.payload,
                garagesForSelectFieldCollectionInProgress: false
            };
        case actionTypes.COLLECT_GARAGE_LIST_FOR_SELECT_FIELD_FAILURE:
            return {
                ...state,
                errorMessageForGaragesForSelectFieldCollection: action.payload.error,
                garagesForSelectFieldCollectionInProgress: false
            };

        case actionTypes.COLLECT_GARAGE_DETAILS_REQUEST:
            return {
                ...state,
                garageDetails: undefined,
                garageDetailsCollectionInProgress: true
            };
        case actionTypes.COLLECT_GARAGE_DETAILS_SUCCESS:
            return {
                ...state,
                garageDetails: action.payload,
                garageDetailsCollectionInProgress: false
            };
        case actionTypes.COLLECT_GARAGE_DETAILS_FAILURE:
            toast.error("Can't collect the details! Please contact with an admin.");
            return {
                ...state,
                garageDetailsCollectionInProgress: false
            };

        case actionTypes.COLLECT_PROVIDED_SMART_BATTERIES_INFO_REQUEST:
            return {
                ...state,
                providedSmartBatteries: undefined,
                smartBatteryInfoCollectionInProgress: true
            };
        case actionTypes.COLLECT_PROVIDED_SMART_BATTERIES_INFO_SUCCESS:
            return {
                ...state,
                providedSmartBatteries: action.payload,
                smartBatteryInfoCollectionInProgress: false
            };
        case actionTypes.COLLECT_PROVIDED_SMART_BATTERIES_INFO_FAILURE:
            toast.error("Can't collect provided smart batteries info! Please contact with an admin.");
            return {
                ...state,
                smartBatteryInfoCollectionInProgress: false
            };

        case actionTypes.UPDATE_GARAGE_ACTIVATION_STATUS_REQUEST:
            return {
                ...state,
                updateGarageActivation: undefined,
                garageStatusIsBeingUpdated: true,
                statusUpdateFailed: false
            };
        case actionTypes.UPDATE_GARAGE_ACTIVATION_STATUS_SUCCESS:
            return {
                ...state,
                updateGarageActivation: action.payload,
                garageStatusIsBeingUpdated: false,
                statusUpdateFailed: false
            };
        case actionTypes.UPDATE_GARAGE_ACTIVATION_STATUS_FAILURE:
            toast.error("Process has failed! Please contact with an admin.");
            return {
                ...state,
                garageStatusIsBeingUpdated: false,
                statusUpdateFailed: true
            };

        case actionTypes.COLLECT_ACTIVITIES_OF_A_GARAGE_REQUEST:
            return {
                ...state,
                transactions: undefined,
                collectingTransactions: true
            };
        case actionTypes.COLLECT_ACTIVITIES_OF_A_GARAGE_SUCCESS:
            return {
                ...state,
                transactions: action.payload,
                collectingTransactions: false
            };
        case actionTypes.COLLECT_ACTIVITIES_OF_A_GARAGE_FAILURE:
            toast.error("Can't collect the transactions! Please contact with an admin.");
            return {
                ...state,
                collectingTransactions: false
            };

        case actionTypes.COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT_REQUEST:
            return {
                ...state,
                transactionsForExport: undefined,
                collectingTransactionsForExport: true
            };
        case actionTypes.COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                transactionsForExport: action.payload,
                collectingTransactionsForExport: false
            };
        case actionTypes.COLLECT_ACTIVITIES_OF_A_GARAGE_FOR_EXPORT_FAILURE:
            toast.error("Can't collect the transactions for export! Please contact with an admin.");
            return {
                ...state,
                collectingTransactionsForExport: false
            };

        case actionTypes.COLLECT_TRANSACTIONS_OF_A_GARAGE_REQUEST:
            return {
                ...state,
                transactions: undefined,
                collectingTransactions: true
            };
        case actionTypes.COLLECT_TRANSACTIONS_OF_A_GARAGE_SUCCESS:
            return {
                ...state,
                transactions: action.payload,
                collectingTransactions: false
            };
        case actionTypes.COLLECT_TRANSACTIONS_OF_A_GARAGE_FAILURE:
            toast.error("Can't collect the transactions! Please contact with an admin.");
            return {
                ...state,
                collectingTransactions: false
            };

        case actionTypes.COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT_REQUEST:
            return {
                ...state,
                transactionsForExport: undefined,
                collectingTransactionsForExport: true
            };
        case actionTypes.COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                transactionsForExport: action.payload,
                collectingTransactionsForExport: false
            };
        case actionTypes.COLLECT_TRANSACTIONS_OF_A_GARAGE_FOR_EXPORT_FAILURE:
            toast.error("Can't collect the transactions for export! Please contact with an admin.");
            return {
                ...state,
                collectingTransactionsForExport: false
            };

        case actionTypes.UPDATE_DUE_LIMIT_REQUEST:
            return {
                ...state,
                garageDueLimitUpdated: undefined,
                garageDueLimitIsBeingUpdated: true
            };
        case actionTypes.UPDATE_DUE_LIMIT_SUCCESS:
            return {
                ...state,
                garageDueLimitUpdated: action.payload,
                garageDueLimitIsBeingUpdated: false
            };
        case actionTypes.UPDATE_DUE_LIMIT_FAILURE:
            toast.error("Can't update the due limit! Please contact with an admin.");
            return {
                ...state,
                garageDueLimitIsBeingUpdated: false
            };

        case actionTypes.DUE_LIMIT_CHANGE_HISTORY_REQUEST:
            return {
                ...state,
                garageDueLimitUpdated: undefined,
                changeLogs: undefined,
                collectingChangeLogs: true
            };
        case actionTypes.DUE_LIMIT_CHANGE_HISTORY_SUCCESS:
            return {
                ...state,
                changeLogs: action.payload,
                collectingChangeLogs: false
            };
        case actionTypes.DUE_LIMIT_CHANGE_HISTORY_FAILURE:
            toast.error("Can't update the due limit! Please contact with an admin.");
            return {
                ...state,
                collectingChangeLogs: false
            };

        case actionTypes.MANUAL_CASH_PAYMENT_REQUEST:
            return {
                ...state,
                manualCashPaymentSaved: undefined,
                manualCashPaymentInProgress: true
            };
        case actionTypes.MANUAL_CASH_PAYMENT_SUCCESS:
            return {
                ...state,
                manualCashPaymentSaved: requestCycle.success,
                manualCashPaymentInProgress: false,
                successMessageForManualCashPaymentProcess: action.payload
            };
        case actionTypes.MANUAL_CASH_PAYMENT_FAILURE:
            return {
                ...state,
                manualCashPaymentSaved: requestCycle.failed,
                manualCashPaymentInProgress: false,
                errorMessageForManualCashPaymentProcess: action.payload.error
            };

        case actionTypes.REGISTER_BORROWER_REQUEST:
            return {
                ...state,
                borrowerSaved: undefined,
                borrowerRegistrationInProgress: true
            };
        case actionTypes.REGISTER_BORROWER_SUCCESS:
            return {
                ...state,
                borrowerSaved: requestCycle.success,
                borrowerRegistrationInProgress: false,
                successMessageForBorrowerRegistration: action.payload
            };
        case actionTypes.REGISTER_BORROWER_FAILURE:
            return {
                ...state,
                borrowerSaved: requestCycle.failed,
                borrowerRegistrationInProgress: false,
                errorMessageForBorrowerRegistration: action.payload.error
            };

        case actionTypes.EDIT_BORROWER_REQUEST:
            return {
                ...state,
                borrowerEdited: undefined,
                borrowerEditInProgress: true
            };
        case actionTypes.EDIT_BORROWER_SUCCESS:
            return {
                ...state,
                borrowerEdited: requestCycle.success,
                borrowerEditInProgress: false,
                successMessageForBorrowerEdit: action.payload
            };
        case actionTypes.EDIT_BORROWER_FAILURE:
            return {
                ...state,
                borrowerEdited: requestCycle.failed,
                borrowerEditInProgress: false,
                errorMessageForBorrowerEdit: action.payload.error
            };

        case actionTypes.COLLECT_INVOICES_REQUEST:
            return {
                ...state,
                invoices: undefined,
                invoicesCollectionRelatedErrorMessage: undefined,
                invoiceCollectionInProgress: true
            };
        case actionTypes.COLLECT_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.payload,
                invoiceCollectionInProgress: false
            };
        case actionTypes.COLLECT_INVOICES_FAILURE:
            return {
                ...state,
                invoicesCollectionRelatedErrorMessage: action.payload.error,
                invoiceCollectionInProgress: false
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        case actionTypes.RETURN_TO_INITIAL_STATE_FOR_TRANSACTIONS_AND_ACTIVITIES_MODAL:
            return initialStateForTransactionsAndActivitiesModal

        case actionTypes.RETURN_TO_INITIAL_STATE_FOR_MANUAL_CASH_PAYMENT:
            return initialStateForManualCashPaymentProcess

        default:
            return state;
    }
};
