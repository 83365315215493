import React, {useEffect, useState} from 'react';
import { useLocation } from "react-router";
import PropTypes from 'prop-types';
import {useForm} from "react-hook-form";
import {Button, Form, InputGroup, OverlayTrigger, Spinner} from "react-bootstrap";
import Select from "react-select";
import {SolInput, SolTextArea} from "../../../../components/SolStyledComponents/components";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {DatePicker} from '@material-ui/pickers/'
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {actions} from "../../actions";
import {connect} from "react-redux";
import {upazillas} from "../../../../utils/countryData/3dListBangladesh";
import {toast} from "react-toastify";
import moment from "moment";
import {requestCycle} from "../../utils";
import * as bdData from "../../../../utils/countryData/3dListBangladesh";
import {usersReducer} from "../../reducer";
import {Toolbar} from "../components";
import {showNotifications} from "../../../../utils/notification";
import {isValidPhoneNumber} from "libphonenumber-js";
import {useSubheader} from "../../../../../_metronic/layout";

const useStylesForDatePicker = makeStyles({
    root: {
        marginTop: '0',
        marginBottom: '0',
        border: 'none',
        width: '100%',
        '& input': {
            color: 'black',
            padding: "0 10px 0 10px",
            height: "38px",
            outline: "none",
        },
        '& input:hover, input:focus, input:active': {
            border: "1px solid #F18D00",
            boxShadow: "0px 0px 0px 3.2px r gba(241, 141, 0, 0.1)",
            outline: "none",
        }
    },
});
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#ffffff',
        }
    },
});
const UpdatePassword = props => {
    const {register, handleSubmit, errors, setError, clearErrors,control} = useForm();
    const location = useLocation();
    const subHeader = useSubheader();
    const [language, setLanguage] = useState("EN");
    const [userName, setUserName] = useState(sessionStorage.getItem('username') ? sessionStorage.getItem('username'):"");

    const [errorMessage, setErrorMessage] = useState(false);
    const {
        UPDATE_PASSWORD
    } = props;


    const onSubmit=(data)=>{
        let regex = /[?=.*!@#$&*]/
        let password = data.password
        let passWordValidated = regex.test(password)
        if (password.length < 4) {
            setError('password', {
                type: "manual",
                message: language === "EN"? "Password must be of at least 4 characters":"পাসওয়ার্ড অবশ্যই অন্তত চার ক্যারেক্টারের হতে হবে!"
            });
        } else if (data.password !== data.password_confirmation) {
            setError('password_confirmation', {
                type: "manual",
                message: language === "EN"? "Password does not match":"পাসওয়ার্ড মিলেনি!"
            });
            setError('password', {
                type: "manual",
                message: language === "EN"? "Password does not match":"পাসওয়ার্ড মিলেনি!"
            });
        } else {
            const payload = {
                username: userName,
                new_password: data.password
            }
            props.UPDATE_PASSWORD(payload);
        }
    };

    useEffect(() => {
        if (props.successMessagePassword !== undefined) {
            if (language === 'EN') {
                showNotifications('success', props.successMessagePassword)
            } else {
                toast.success('সফলভাবে পাসওয়ার্ড সম্পাদনা হয়েছে!')
            }
        } else if (props.errorMessagePassword !== undefined) {
            setErrorMessage(showNotifications('error', props.errorMessagePassword))
        }
    }, [props.successMessagePassword, props.errorMessagePassword]);

    useEffect(()=>{
        subHeader.setActionButtons(null)
        setLanguage(props.language)
        if (location.userData)
        {
            sessionStorage.setItem('username', location.userData.username)
            setUserName(location.userData.username)
        }
    },[])

    useEffect(()=>{
        if (props.passwordUpdated === requestCycle.success){
            sessionStorage.removeItem('username')
            props.history.goBack()
        }
    },[props.passwordUpdated])

    const cancelProcess = () => {
        sessionStorage.removeItem('username')
        props.history.goBack()
    }

    return (
        <ContentWrapper showCardHeader={false} pageTitle={'Register user'} showBackButton={false} isLoading={false}>
            <div className={"row mb-6"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>{language === 'EN'? 'Update password':'পাসওয়ার্ড সম্পাদনা করুন'}</b></h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Form onSubmit={handleSubmit(onSubmit)}>

                       <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === 'EN'? 'Password':'পাসওয়ার্ড'}<span className="required text-danger">*</span></Form.Label>
                                    </div>
                                    <SolInput
                                        name={'password'}
                                        type={"password"}
                                        ref={register({
                                            required: language === 'EN'? 'Required':'উপরের তথ্যটি আবশ্যক!',
                                        })}
                                        placeholder={language === 'EN'? 'Password':'পাসওয়ার্ড'}
                                        autoComplete={"off"}
                                    />
                                    {errors.password && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.password.message}</div>}
                                    {errorMessage.password? <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.password}</div>:null}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === 'EN'? 'Password Confirmation':'পাসওয়ার্ড নিশ্চিত করুন'}<span className="required text-danger">*</span></Form.Label>
                                    </div>
                                    <SolInput
                                        name={'password_confirmation'}
                                        type={"password"}
                                        ref={register({
                                            required: language === 'EN'? 'Required':'উপরের তথ্যটি আবশ্যক!',
                                        })}
                                        placeholder={language === 'EN'? 'Password':'পাসওয়ার্ড'}
                                        autoComplete={"new-password"}
                                    />
                                    {errors.password_confirmation && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.password_confirmation.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={"col-md-12"}>
                                <Button variant="warning" type="submit">
                                    {props.userInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}
                                    <i className='fa fa-check' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Update':'সম্পাদনা করুন'}
                                </Button>
                                <Button variant="dark" size={'md'} onClick={()=> cancelProcess()} style={{
                                    backgroundColor: '#8C8C9B',
                                    outline: '#8C8C9B',
                                    border: '#8C8C9B',
                                }} className={"ml-3"}>
                                    <i className='fa fa-times' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Cancel':'বাতিল করুন'}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
        </ContentWrapper>
    );
};

UpdatePassword.propTypes = {};


const mapStateToProps=(state)=>{

    return {
        language: state.auth.language,
        isLoading: state.usersReducer.isLoading,
        passwordUpdated: state.usersReducer.passwordUpdated,
        userInfoSubmitting: state.usersReducer.userInfoSubmitting,
        successMessagePassword: state.usersReducer.successMessagePassword,
        errorMessagePassword: state.usersReducer.errorMessagePassword
    }
}

export default connect(mapStateToProps, actions) (UpdatePassword);
