import React, {Fragment, useEffect} from 'react';
import ContentWrapper from "../../components/contentWrapper/contentWrapper";
import {Spinner} from "react-bootstrap";
import {SolTypography} from "../../components/utilityComponents/SOlTypography";
import {useSelector} from "react-redux";
import {useSubheader} from "../../../_metronic/layout";


const Home = props => {
    const language = useSelector((state) => state.auth.language)
    const subHeader = useSubheader();

    useEffect(() => {
        subHeader.setActionButtons(null);
        subHeader.setBreadCrumbComponent(null);
    },[])

    return (
        <ContentWrapper isLoading={false} showBackButton={false} showCardHeader={false}>
            <Fragment>
                <div align="center" style={{
                    marginTop: "10%"
                }}>
                    {/*<img src={require('../../utils/asset/auto-rickshaw02.svg')} alt={"Auto-Rickshaw"}/>*/}
                    <img src={require('../../utils/asset/auto-rickshaw-04.svg')} alt={"Auto-Rickshaw"}/>
                    {/*<img src={require('../../utils/asset/auto-rickshaw03.svg')} alt={"Auto-Rickshaw"}/>*/}

                    <h1 className={`mb-5 mt-5`}>
                        {language==="BN"?<span><SolTypography.Text primary> সোলমোবিলিটি </SolTypography.Text> ড্যাসবোর্ডে স্বাগতম</span>: null}
                        {language==="EN"?<span>Welcome to the <SolTypography.Text primary> SOLmobility </SolTypography.Text> Dashboard</span>:null}
                    </h1>

                    <SolTypography.SubTitle primary>  </SolTypography.SubTitle>

                </div>

            </Fragment>
        </ContentWrapper>
    );
};

Home.propTypes = {};

export default Home;
