import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Switch} from "react-router-dom";
import SecuredRoute from "../../router/SecuredRoute";
import AppEventList from "./view/appEventList";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";

const AppEventsRouter = props => {

    return (
        <div>
            <Switch>
                <Redirect exact={"true"} from={'/app-events'} to={'/app-events/list'} />

                <SecuredRoute
                    path={'/app-events/list'}
                    component={AppEventList}
                    service={getServiceName('deviceService')}
                    feature={getFeatureName('appEventFeature')}
                    action={getActionName('listAction')}
                />
                <Redirect to="/error/error-v1" />
            </Switch>
        </div>
    );
};

AppEventsRouter.propTypes = {
    
};

export default AppEventsRouter;
