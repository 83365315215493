import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import VerifiedIcon from '@mui/icons-material/Verified';
import PendingIcon from '@mui/icons-material/Pending';
import {theme, useStylesForDatePicker} from "../../../utils/muiPickerCustomStyle";
import 'moment/locale/bn';
import 'moment/locale/en-gb';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContent from "react-bootstrap/TabContent";

import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {manualTransactionActions} from "../actions";
import {useSubheader} from "../../../../_metronic/layout";
import {Button, Form, Spinner} from "react-bootstrap";
import {Toolbar} from "../../evs/views/components";
import {DataTableContainer} from "../../evs/utils";
import DataTable from "../../../components/dataTable/DataTable";
import {LoadingSpinner} from "../../../components/LoadingSpinnerForDataTable";
import Select from "react-select";
import {SolInput} from "../../../components/SolStyledComponents/components";
import {useForm} from "react-hook-form";
import DateTimeFormat from "../../../utils/dateFormat/DateTimeFormat";
import {csvToArray, requestCycle} from "../../../utils/utilityFunctions";
import {toast} from "react-toastify";
import moment from "moment";
import {ExportToCsv} from "export-to-csv";


const UploadQaTestResults = props => {
    const classes = useStylesForDatePicker();
    const [language, setLanguage] = useState(null);
    const [tests, setTests] = useState([]);
    const [processedTests, setProcessedTests] = useState([]);
    const [key, setKey] = useState("approved");
    const subheader= useSubheader();
    const {errors, handleSubmit, setError,register} = useForm()
    const {UPLOAD_PCBA_TESTS} = props;
    const test_result_fields = [
        'Power consumption at 3V(mA)',
        'Power consumption at 15V(mA)',
        'Test point P1 at 3V Input Voltage(V)',
        'Test point P1 at 15V Input Voltage(V)',
        'Test point P4(V)'
    ]

    const wrongFileTypeMessage = language === "EN"? "Please provide a csv file!":"দয়া করে একটি সিএসভি ফাইল দিন!"

    const uploadTestCsv = (payload) => {
        const file_csv = payload.csv_file[0]
        const reader = new FileReader();

        reader.onload = function (event) {
            let battaries = csvToArray(event.target.result);
            setTests(battaries)
        };
        reader.readAsText(file_csv)
    }

    useEffect(()=>{
        setLanguage(props.language)
        // return ()=>{
        //     RETURN_TO_INITIAL()
        // }
    },[])

    useEffect(()=>{
        const processed_data = tests.map((item)=>{
            return  {

                "dongle_serial_number": item["dongle serial number"],
                [`${test_result_fields[0]}`]: item[`${test_result_fields[0]}`]?.trim(),
                [`${test_result_fields[1]}`]: item[`${test_result_fields[1]}`]?.trim(),
                [`${test_result_fields[2]}`]: item[`${test_result_fields[2]}`]?.trim(),
                [`${test_result_fields[3]}`]: item[`${test_result_fields[3]}`]?.trim(),
                [`${test_result_fields[4]}`]: item[`${test_result_fields[4]}`]?.trim(),
                "status": item["status"]?.trim(),
                "error": checkForError(item)

            }
        })
        setProcessedTests(processed_data)
    },[tests])

    const checkForError=(item)=>{
        const valid_keys = [
            "dongle serial number",
            "status",
            `${test_result_fields[0]}`,
            `${test_result_fields[1]}`,
            `${test_result_fields[2]}`,
            `${test_result_fields[3]}`,
            `${test_result_fields[4]}`,
            ]
        const status_type = ["failed", "completed"]
        let msg = ''
        valid_keys.forEach((key)=>{

            if (!item[key]){
                msg =msg.concat(`\n ${key} required`)
            }
            if (key ==="status" && status_type.indexOf(item[key])===-1 ){
                msg = msg.concat(`\n ${key} must be of one of "failed/completed"`)
            }

        })
        return msg

    }

    const columns = [
        {
            field: 'dongle_serial_number',
            title: "Dongle serial number",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            render: (value)=>{
                if (value.error){
                    return  <b style={{color: "red"}}>{value.dongle_serial_number}</b>
                }

                return value.dongle_serial_number
            }
            // disableClick: true,
        },
        {
            field: test_result_fields[0],
            title: language === "EN"? test_result_fields[0]:"নামফলকে থাকা ভোল্টেজ",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return <b style={{color: "red"}}>{"required"}</b>
            },

            // disableClick: true
        },
        {
            field: test_result_fields[1],
            title: language === "EN"? test_result_fields[1]:"নামফলকে থাকা ভোল্টেজ",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return <b style={{color: "red"}}>{"required"}</b>
            },

            // disableClick: true
        },{
            field: test_result_fields[2],
            title: language === "EN"? test_result_fields[2]:"নামফলকে থাকা ভোল্টেজ",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return <b style={{color: "red"}}>{"required"}</b>
            },

            // disableClick: true
        },
        {
            field: test_result_fields[3],
            title: language === "EN"? test_result_fields[3]:"নামফলকে থাকা ভোল্টেজ",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return <b style={{color: "red"}}>{"required"}</b>
            },

            // disableClick: true
        },
        {
            field: test_result_fields[4],
            title: test_result_fields[4],
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return <b style={{color: "red"}}>{"required"}</b>
            },

            // disableClick: true
        },
        {
            field: 'status',
            title: "Status",
            headerStyle: {
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            render: (value)=>{
                if (value.error.includes('must be of one of "failed/completed"')){
                    return  <b style={{color: "red"}}>Must be of one of "failed/completed"</b>
                }

                return value.status
            }

            // disableClick: true
        }


    ]

    const downloadCsvTemplate = () => {
        let currentDate = moment().format("DD_MMM_YYYY")
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: `` ,
            filename: `pcb_qa_test_results_` + currentDate,
            useTextFile: false,
            useBom: true,
            headers: ["dongle serial number", "Power consumption at 3V(mA)", "Power consumption at 15V(mA)",
                "Test point P1 at 3V Input Voltage(V)", "Test point P1 at 15V Input Voltage(V)", "Test point P4(V)", "status"]
        }
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv([{}])
    }

    const uploadTests=()=>{
        if (Array.isArray(processedTests) && processedTests.length>0){

            let errorFlag= false;
            processedTests.forEach((item)=>{
                if (item.error){

                    errorFlag = true;

                }
            });
            if (errorFlag) {
                toast.error("Please resolve the errors !")
                return
            }

            const payload = processedTests.map((item)=>{
                return {
                    test_results: {
                        [`${test_result_fields[0]}`]: item[`${test_result_fields[0]}`]?.trim(),
                        [`${test_result_fields[1]}`]: item[`${test_result_fields[1]}`]?.trim(),
                        [`${test_result_fields[2]}`]: item[`${test_result_fields[2]}`]?.trim(),
                        [`${test_result_fields[3]}`]: item[`${test_result_fields[3]}`]?.trim(),
                        [`${test_result_fields[4]}`]: item[`${test_result_fields[4]}`]?.trim(),
                    },
                    dongle_serial_number: item["dongle_serial_number"],
                    status: item["status"],
                }

            })
            UPLOAD_PCBA_TESTS(payload)
        }
    }

    const renderTable=()=>{
        return <>
            <div className={"row mb-12 mt-12 no-padding"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>
                                <Button variant={'warning'} onClick={uploadTests} disabled={props.buttonLoading}>{language === "EN"? "Upload CSV":"সিএসভি ফরম্যাটে ডাউনলোড করুন"}</Button>
                                </b></h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
                <div className={"col-md-12"}>
                    <DataTableContainer>
                        <DataTable columns={columns} data={processedTests}>

                        </DataTable>
                    </DataTableContainer>
                </div>

            </div>
        </>
    }

    return (
        <ContentWrapper isLoading={false} pageTitle={"Upload Production Logs"} showBackButton={false} showCardHeader={true}>
            <div className={'row pl-5'}>
                <p className={'text-mute'}>A blank csv template can be downloaded from <a href={"#"} style={{'color': '#F18D00'}} onClick={() => {downloadCsvTemplate()}}>here</a>.</p>
            </div>
            <div className={'row pl-5'}>
                <h5>Upload the CSV file</h5>
                <br/>
                <br/>
                <div className={'col-md-12'}>

                    <Form onSubmit={handleSubmit(uploadTestCsv)}>
                        <div className={'row'}>
                            { <div className={'row'}>
                                <div className={"col-md-12"}>
                                    <Form.Group>
                                        <Form.Control
                                            type="file"
                                            name={'csv_file'}
                                            ref={register({
                                                required: language === 'EN'? 'Please upload a file!':'ফাইলটি দিতে হবে!',
                                                validate: {
                                                    isCsv: value => value[0].type === 'text/csv' || wrongFileTypeMessage
                                                }
                                            })}
                                        />
                                        {errors.csv_file && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.csv_file.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>}
                        </div>
                        <div className={'row'}>
                            <Button variant="outline-dark" size={'sm'} onClick={()=> {props.history.goBack()}}>
                                {language === 'EN'? 'Cancel':'বাতিল করুন'}
                            </Button>
                            <Button variant="warning" size={'sm'} type="submit"
                                    disabled={false}
                                    className={'ml-3'}

                            >
                                {props.deviceInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}
                                {language === 'EN'? 'Confirm':'নিশ্চিত করুন'}
                            </Button>
                        </div>
                    </Form>
                </div>



            </div>
            {Array.isArray(processedTests) && processedTests.length>0?renderTable():null}
        </ContentWrapper>
    );
};

UploadQaTestResults.propTypes = {

};

export default connect((state) => {
    return {
        language: state.auth.language,
        isLoading: state.manualTransactionsReducer.isLoading,
        eventList: state.manualTransactionsReducer.events,
        listLoading: state.manualTransactionsReducer.listLoading,
        buttonLoading: state.manualTransactionsReducer.buttonLoading,
        iconLoading: state.manualTransactionsReducer.iconLoading,
        eventListForExport: state.manualTransactionsReducer.eventsForExport
    }
}, {...manualTransactionActions}) (UploadQaTestResults);
