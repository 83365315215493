import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Breadcrumb, Button, Form, OverlayTrigger, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner} from '../../utils';
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import {isValidPhoneNumber} from "libphonenumber-js";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import moment from "moment";
import {checkAuthorization} from "../../../auth/authorization";
import {Controller, useForm} from "react-hook-form";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import {toast} from "react-toastify";
import {showNotifications} from "../../../../utils/notification";

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const UserList = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const [showFilter, setShowFilter] = useState(true);
    const [filterData, setFilterData] = useState(null);

    const [userList, setUserList] = useState(undefined);
    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);
    const [queryParams, setQueryParams] = useState(null)
    const [initialfilterParam, setInitialfilterParam] = useState(null)
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null)
    const [urlIsEdited, setUrlIsEdited] = useState(false)
    const subHeader = useSubheader();

    const [authorization, setAuthorization] = useState(null);
    const [language, setLanguage] = useState(null);
    const [userServiceName, setUserServiceName] = useState(getServiceName('userService'));
    const [userFeatureName, setUserFeatureName] = useState(getFeatureName('userFeature'));
    const [createActionName, setCreateActionName] = useState(getActionName('createAction'));
    const [updateActionName, setUpdateActionName] = useState(getActionName('updateAction'));

    const userListColumns = [
        {
            field: 'user_id',
            title: language === 'EN'? 'User ID':'আইডি',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            // disableClick: true,
        },
        {
            field: 'name',
            title: language === 'EN'? 'Name':'নাম',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
            // disableClick: true,
        },
        {
            field: 'garage',
            title: language === 'EN'? 'Garage':'গ্যারেজ',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
            // disableClick: true,
        },
        {
            field: 'username',
            title: language === 'EN'? 'Username':'লগইন করতে ব্যবহৃত আইডি',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
            // disableClick: true,
        },
        // {
        //     field: 'role',
        //     title: language === 'EN'? 'Role':'ব্যবহারকারীর ধরন',
        //     emptyValue: ()=>{
        //         return "N/A"
        //     },
        //     // disableClick: true,
        // },
        {
            field: 'registeredAt',
            title: language === 'EN'? 'Registered at':'নিবন্ধনের সময়',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
            // disableClick: true,
        },
        {
            field: 'status',
            title: language === 'EN'? 'Status':'বর্তমান অবস্থা',
            emptyValue: ()=>{
                return "N/A"
            },
            disableClick: true,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
            // disableClick: true,
        }
    ]

    const {
        GET_USER_LIST,
        RETURN_TO_INITIAL_USER_REDUCER_STATE
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setAuthorization(props.authorization)
        setLanguage(props.language)
        if (checkAuthorization(props.authorization, userServiceName, userFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" type="submit" style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={gotToCreateuser} classes={classes} >
                        {/*<FilterListIcon color="disabled" fontSize="large"/>*/}
                        {props.language === 'EN'? <i className="fa fa-user-plus"/>:null}
                        {props.language === 'EN'? 'Register User':'ব্যবহারকারী নিবন্ধন করুন'}
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        subHeader.setBreadCrumbComponent(null)
        if (!props.history.location.search) {
            setPage(1)
            props.history.push({
                search: 'page=1'
            })
        }
        return () => {
            RETURN_TO_INITIAL_USER_REDUCER_STATE()
        }
    }, []);

    // ----- Begin: Section related to shareable link/handling query params ---- //
    const validationKeyArray = ['page', 'user_id', 'username']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (initialfilterParam) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            if (initialfilterParam.user_id) {
                let userIdFromUrl = initialfilterParam.user_id
                setValue('user_id', userIdFromUrl)
            }
            if (initialfilterParam.username) {
                let usernameFromUrl = initialfilterParam.username
                setValue('username', usernameFromUrl)
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            // Page will always be there
            let filter = {page: page}
            let filterApplied = false
            if (filterParamForApiCall.user_id) {
                filter['user_id'] = filterParamForApiCall.user_id
                filterApplied = true
            }
            if (filterParamForApiCall.username) {
                filter['username'] = filterParamForApiCall.username
                filterApplied = true
            }
            setFilterApplied(filterApplied)
            GET_USER_LIST({...filter})
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);
    // ----- End: Section related to shareable link/handling query params ---- //

    const resetFilter = () => {
        if (filterApplied) {
            // Resetting the user list
            setPage(1)
            props.history.push({
                search: 'page=1'
            })
        }
        resetFilterValues()
        setFilterApplied(false)
        clearErrors()
    }

    const resetFilterValues = () => {
        setValue('user_id', '')
        setValue('username', '')
    }

    const filterTheList = (data) => {
        setPage(1)
        let searchText = 'page=1'
        if (data.user_id) {
            searchText += '&user_id=' + data.user_id
        }
        if (data.username) {
            searchText += '&username=' + data.username
        }
        props.history.push({
            search: searchText
        })
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        if (props.userList) {
            const userList = props.userList.results;
            if (userList && userList.length > -1) {
                setUserList(userList.map((user, index) => {
                    let status = 'Active'
                    if (user.status === false) {
                        status = 'Inactive'
                    }
                    return {
                        ...user,
                        garage: user.garage_name? user.garage_name:"N/A",
                        // role: user.role_name,
                        registeredAt: moment(user.created_at).format("DD MMMM,YYYY hh:mm a"),
                        status: status
                    }
                }))
            } else {
                setUserList(undefined)
            }
        }
    }, [props.userList]);

    useEffect(() => {
        if (props.errorMessageForUserListCollection) {
            showNotifications('error', props.errorMessageForUserListCollection)
        }
    }, [props.errorMessageForUserListCollection])

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        let searchText = 'page=' + newPage
        if (filterApplied) {
            if (filterParamForApiCall.user_id) {
                searchText += '&user_id=' + filterParamForApiCall.user_id
            }
            if (filterParamForApiCall.username) {
                searchText += '&username=' + filterParamForApiCall.username
            }
        }
        props.history.push({
            search: searchText
        })
    }

    const gotToCreateuser = () => {
        props.history.push({
            pathname: '/users/create',
            purpose: 'create'
        })
    }

    const goToEditUser = (rowData) => {
        props.history.push({
            pathname: '/users/edit/',
            userData: rowData,
            purpose: 'update'
        })
    }

    const goToUpdatePassword = (rowData) => {
        props.history.push({
            pathname: '/users/update-password/',
            userData: rowData
        })
    }

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Grids"}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{language === 'EN'? 'Users':'ব্যবহারকারীর তালিকা'}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <Form onSubmit={handleSubmit(filterTheList)}>
                                            <div className={'row g-3'}>
                                                <div className={'col-md-4'}>
                                                    <Form.Group>
                                                        <div>
                                                            <Form.Label>{language === 'EN'? 'User ID':'ব্যবহারকারীর আইডি'}</Form.Label>
                                                        </div>
                                                        <SolInput
                                                            type={"number"}
                                                            name={"user_id"}
                                                            step={"1"}
                                                            min={"1"}
                                                            placeholder={language === "EN"? "Type User ID...":"ব্যবহারকারীর আইডি লিখুন..."}
                                                            autoComplete={"off"}
                                                            ref={register()}
                                                        />
                                                        {errors.user_id && <div className="text-danger">
                                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.user_id.message}</div>}
                                                    </Form.Group>
                                                </div>
                                                <div className={'col-md-4'}>
                                                    <Form.Group>
                                                        <div>
                                                            <Form.Label>{language === 'EN'? 'Username':'ব্যবহারকারী সংশ্লিষ্ট ইউজার'}</Form.Label>
                                                        </div>
                                                        <SolInput
                                                            type={"text"}
                                                            name={"username"}
                                                            placeholder={language === "EN"? "Type username...":"ব্যবহারকারী সংশ্লিষ্ট ইউজারনেম লিখুন..."}
                                                            autoComplete={"off"}
                                                            ref={register()}
                                                        />
                                                        {errors.username && <div className="text-danger">
                                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.username.message}</div>}
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className={'row'}>
                                                <div className={"col-md-12"}>
                                                    <Button variant="warning" size={'sm'} type="submit"
                                                            disabled={props.listLoading}>
                                                        {language === 'EN'? 'Apply filter':'ফিল্টার করুন'}
                                                    </Button>
                                                    <Button variant="outline-dark" size={'sm'}
                                                            onClick={()=> {resetFilter()}}
                                                            className={'ml-3'} disabled={props.listLoading}>
                                                        {language === 'EN'? 'Reset filter':'ফিল্টার করার তথ্য পরিষ্কার করুন'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/></>:null}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.listLoading && userList? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন ব্যবহারকারী নেই'}
                                noDataAvailableMessageInEnglish={'No users available'}
                                columns={userListColumns}
                                data={userList}
                                showToolbar={false}
                                asyncPagination={true}
                                isLoading={props.tableLoading}
                                count={props.userList?.count}
                                itemsPerPage={props.userList?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                                actionColumnIndex={-1}
                                overrideCustomActions={true}
                                actions={checkAuthorization(authorization, userServiceName, userFeatureName, [updateActionName])? [
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'primary'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }}>
                                                    Edit User
                                                </Button>
                                            },
                                            onClick: (e, row) => goToEditUser(row)
                                        })
                                    },
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'warning'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                                    Update Password
                                                </Button>
                                            },
                                            onClick: (e, row) => goToUpdatePassword(row)
                                        })
                                    }
                                ]:[]}
                            /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Collecting user list ..': 'ব্যবহারকারীদের তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    );
};

UserList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.usersReducer.isLoading,
        userList: state.usersReducer.userList,
        listLoading: state.usersReducer.listLoading,
        errorMessageForUserListCollection: state.usersReducer.errorMessageForUserListCollection
    }
}

export default connect(mapStateToProps, actions)(withRoles(UserList));
