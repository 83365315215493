/*
* Server Error Component
* v.1.2.1
* */

import React, { useState, useEffect } from "react";
import {Badge, Button, Spinner} from "react-bootstrap";
import PropTypes from "prop-types";
import {httpResponseCode} from "./HttpResponseCode.js"

const HttpErrorHandler = ({ statusCode, disableFlashButton, reloadFunction }) => {

    const [httpErrorMessage, setHttpErrorMessage] = useState("");

    useEffect(() => {
        if (statusCode !== undefined && statusCode !== null) {
            httpResponseCode.forEach((element, index) => {
                if (statusCode === element.code) {
                    setHttpErrorMessage(element.message);
                }
            });
        }
    }, [statusCode]);

    return (
        <React.Fragment>
            <div className={'col-md-12'}>
                <div className={'mt-4'} style={{
                    paddingBottom: '2.5rem'
                }}>
                    <Badge variant={'secondary'}>
                        <h3>
                            {(httpErrorMessage !== "") ?
                                httpErrorMessage
                                :
                                "Oops! This is an unusual error, please contact your support team!"
                            }
                        </h3>
                    </Badge>

                </div>
                <div className={'mb-4'}>
                    <img
                         src={require('../utils/asset/server_error.png')}
                         alt={"Server Error"}
                         width={300}
                         height={250}
                         style={{"marginTop": 80}}
                    />
                    {reloadFunction && typeof reloadFunction === "function" && <div>
                        <Button variant="secondary"  onClick={() => reloadFunction()}>
                            {disableFlashButton ? <Spinner animation="border" variant="secondary" />:<i className="flaticon2-refresh-button"/>}
                            &nbsp;Reload
                        </Button>
                    </div>}
                </div>
            </div>
        </React.Fragment>
    );
}

HttpErrorHandler.propTypes = {
    disableFlashButton: PropTypes.bool.isRequired,
    reloadFunction: PropTypes.func,
}

export default HttpErrorHandler;