export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_INVOICES_LIST_REQUEST: 'COLLECT_INVOICES_LIST_REQUEST',
    COLLECT_INVOICES_LIST_SUCCESS: 'COLLECT_INVOICES_LIST_SUCCESS',
    COLLECT_INVOICES_LIST_FAILURE: 'COLLECT_INVOICES_LIST_FAILURE',

    COLLECT_INVOICES_LIST_FOR_EXPORT_REQUEST: 'COLLECT_INVOICES_LIST_FOR_EXPORT_REQUEST',
    COLLECT_INVOICES_LIST_FOR_EXPORT_SUCCESS: 'COLLECT_INVOICES_LIST_FOR_EXPORT_SUCCESS',
    COLLECT_INVOICES_LIST_FOR_EXPORT_FAILURE: 'COLLECT_INVOICES_LIST_FOR_EXPORT_FAILURE',

    COLLECT_FINANCIERS_LIST_REQUEST: 'COLLECT_FINANCIERS_LIST_REQUEST',
    COLLECT_FINANCIERS_LIST_SUCCESS: 'COLLECT_FINANCIERS_LIST_SUCCESS',
    COLLECT_FINANCIERS_LIST_FAILURE: 'COLLECT_FINANCIERS_LIST_FAILURE',

    FETCH_PAYMENTS_EXPORT_REQUEST: 'FETCH_PAYMENTS_EXPORT_REQUEST',
    FETCH_PAYMENTS_EXPORT_SUCCESS: 'FETCH_PAYMENTS_EXPORT_SUCCESS',
    FETCH_PAYMENTS_EXPORT_FAILURE: 'FETCH_PAYMENTS_EXPORT_FAILURE',

    FETCH_BATTERY_SETTLEMENT_PAYMENTS_REQUEST: "FETCH_BATTERY_SETTLEMENT_PAYMENTS_REQUEST",
    FETCH_BATTERY_SETTLEMENT_PAYMENTS_SUCCESS: "FETCH_BATTERY_SETTLEMENT_PAYMENTS_SUCCESS",
    FETCH_BATTERY_SETTLEMENT_PAYMENTS_FAILURE: "FETCH_BATTERY_SETTLEMENT_PAYMENTS_FAILURE",

    FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_REQUEST: "FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_REQUEST",
    FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_SUCCESS: "FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_SUCCESS",
    FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_FAILURE: "FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_FAILURE",

    SETTLE_PAYMENTS_REQUEST: "SETTLE_PAYMENTS_REQUEST",
    SETTLE_PAYMENTS_SUCCESS: "SETTLE_PAYMENTS_SUCCESS",
    SETTLE_PAYMENTS_FAILURE: "SETTLE_PAYMENTS_FAILURE",

    COLLECT_GARAGES_FOR_BULK_EXTENSION_REQUEST: "COLLECT_GARAGES_FOR_BULK_EXTENSION_REQUEST",
    COLLECT_GARAGES_FOR_BULK_EXTENSION_SUCCESS: "COLLECT_GARAGES_FOR_BULK_EXTENSION_SUCCESS",
    COLLECT_GARAGES_FOR_BULK_EXTENSION_FAILURE: "COLLECT_GARAGES_FOR_BULK_EXTENSION_FAILURE",

    COLLECT_BORROWERS_FOR_BULK_EXTENSION_REQUEST: "COLLECT_BORROWERS_FOR_BULK_EXTENSION_REQUEST",
    COLLECT_BORROWERS_FOR_BULK_EXTENSION_SUCCESS: "COLLECT_BORROWERS_FOR_BULK_EXTENSION_SUCCESS",
    COLLECT_BORROWERS_FOR_BULK_EXTENSION_FAILURE: "COLLECT_BORROWERS_FOR_BULK_EXTENSION_FAILURE",

    COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION_REQUEST: "COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION_REQUEST",
    COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION_SUCCESS: "COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION_SUCCESS",
    COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION_FAILURE: "COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION_FAILURE",

    COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION_REQUEST: "COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION_REQUEST",
    COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION_SUCCESS: "COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION_SUCCESS",
    COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION_FAILURE: "COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION_FAILURE",

    COLLECT_CONTRACTS_FOR_BULK_EXTENSION_REQUEST: "COLLECT_CONTRACTS_FOR_BULK_EXTENSION_REQUEST",
    COLLECT_CONTRACTS_FOR_BULK_EXTENSION_SUCCESS: "COLLECT_CONTRACTS_FOR_BULK_EXTENSION_SUCCESS",
    COLLECT_CONTRACTS_FOR_BULK_EXTENSION_FAILURE: "COLLECT_CONTRACTS_FOR_BULK_EXTENSION_FAILURE",

    EXTEND_OVERDUE_INVOICES_REQUEST: "EXTEND_OVERDUE_INVOICES_REQUEST",
    EXTEND_OVERDUE_INVOICES_SUCCESS: "EXTEND_OVERDUE_INVOICES_SUCCESS",
    EXTEND_OVERDUE_INVOICES_FAILURE: "EXTEND_OVERDUE_INVOICES_FAILURE",

    EXTEND_UPCOMING_INSTALLMENTS_REQUEST: "EXTEND_UPCOMING_INSTALLMENTS_REQUEST",
    EXTEND_UPCOMING_INSTALLMENTS_SUCCESS: "EXTEND_UPCOMING_INSTALLMENTS_SUCCESS",
    EXTEND_UPCOMING_INSTALLMENTS_FAILURE: "EXTEND_UPCOMING_INSTALLMENTS_FAILURE",

    RETURN_TO_INITIAL_STATE_FOR_BULK_INVOICE_EXTENSION: 'RETURN_TO_INITIAL_STATE_FOR_BULK_INVOICE_EXTENSION',
    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE'
};
