import {
  CLEAR_COUNTER,
  CLEAR_REFRESH_TOKEN_PROMISE, INCREASE_COUNTER,
  SAVE_REFRESH_TOKEN_PROMISE
} from "./actions";

const initialState = {
  refreshTokenPromise: null,
  identityWrongCounter: 0,
};

export default function refreshReducer(state = initialState, action) {
  switch (action.type) {


    case CLEAR_REFRESH_TOKEN_PROMISE:
      return Object.assign({}, state, {
        refreshTokenPromise: initialState.refreshTokenPromise
      });

    case INCREASE_COUNTER:
      return Object.assign({}, state, {
        identityWrongCounter: state.identityWrongCounter+1
      });
    case CLEAR_COUNTER:
      return Object.assign({}, state, {
        identityWrongCounter: 0
      });


    case SAVE_REFRESH_TOKEN_PROMISE:
      return Object.assign({}, state, {
        refreshTokenPromise: action.promise
      });

      default:
        return state;
  }
}
