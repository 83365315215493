import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import {DatePicker} from '@material-ui/pickers/'
import {divisionOptions} from "../../../utils/countryData/solDivision";
import {Button} from "react-bootstrap";
import {ThemeProvider} from '@material-ui/styles';
import {toast} from "react-toastify"
import {isNullorUndefined, isNumber, validateValue} from "../../../utils/utilityFunctions";
import moment from "moment";
import {withRoles} from "../../../router/SecuredRoute";

export const Toolbar = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
`;

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: .40rem .55rem .40rem .55rem;
  background-color: #ffffff;
  /* Global/01. Primary */
  border: ${props => !props.dullComponent ? '1px solid #F18D00' : '1px solid #8C8C9B'};
  box-sizing: border-box;
  border-radius: 4px;


`;

Toolbar.ToolbarContainer = styled.div`
  grid-column: 10/-1;
`;
Toolbar.Title = styled.div`
  display: flex;
  align-items: center;
  grid-column: 1/9;
`
Toolbar.ToolbarContainer.Items = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
`;


export const Filter = styled.div`
  margin: 35px 0 0 0;
  padding: 0;
`;

