// global notification managar for whole admin panel
import {toast} from "react-toastify";

export function showNotifications(notificitionType, notifications, form=false) {
    let errorObject = {}
    if (form === true) {
        // This happens only in case of error
        if (notifications.hasOwnProperty("msg")) {
            toast.error(notifications.msg)
        } else if (notifications.hasOwnProperty("message")) {
            toast.error(notifications.message)
        } else {
            for (let key in notifications) {
                errorObject[key] = notifications[key][0]
            }
        }
    } else {
        if (notificitionType === 'success') {
            if (notifications.hasOwnProperty("message")) {
                toast.success(notifications.message)
            } else {
                toast.success("Successfully done!")
            }
        } else {
            if (notifications.hasOwnProperty("message")) {
                toast.error(notifications.message)
            } else if (notifications.hasOwnProperty("msg")) {
                toast.error(notifications.msg)
            } else {
                toast.error("Failed!")
            }
        }
    }
    return errorObject
}
