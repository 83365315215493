import React, { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Form, Button, Spinner, Badge } from 'react-bootstrap';
import { SolInput, DataTableContainer, DataTable, SolTypography, LoadingSpinner } from '../../../../components';
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import DateTimeFormat from '../../../../utils/dateFormat/DateTimeFormat';

const GarageDetailsModal = ({ data, ...props }) => {
    const [language, setLanguage] = useState("EN");
    const { control, register, setError, errors, setValue, handleSubmit } = useForm();

    const {
        GET_GARAGE_DETAILS,
    } = props;

    useEffect(() => {
        GET_GARAGE_DETAILS({
            garage_guid: data.garage_guid,
        })
    }, [])

    const helperInfoRenderer = () => {
        if (props.listLoading === true) {
            return <LoadingSpinner loadingSubText={language == 'EN' ? `Fetching garage information of #${data?.serial_number}` : 'যন্ত্রের তালিকা প্রস্তুত হচ্ছে'} language={language} />
        }
        else {
            return <div className={'d-flex justify-content-center'}>
                <h1>
                    <Badge variant="secondary">No Data Available</Badge>
                </h1>
            </div>
        }
    }


    return (
        <div>

            <div className={'row'}>
                <div className={'col-md-12'}>

                    {props.listLoading !== true && props.garage? <>
                        {/* <SolTypography.Title secondary className={'mb-4'}>Recent History</SolTypography.Title> */}

                        <div className='row col-md-12 no-padding' style={{ maxWidth: '600px' }}>
                            <div className='col-md-6'>
                                <div className='mb-6'>
                                    <SolTypography.Text secondary>Garage ID</SolTypography.Text>
                                    <SolTypography.Title>{props.garage?.garage_id || "n/a"}</SolTypography.Title>
                                </div>
                                <div className='mb-6'>
                                    <SolTypography.Text secondary>Registered On</SolTypography.Text>
                                    <SolTypography.Title>{new DateTimeFormat(props.garage?.sk).localDisplayWithoutTime}</SolTypography.Title>
                                </div>
                                <div className='mb-6'>
                                    <SolTypography.Text secondary>Address</SolTypography.Text>
                                    <SolTypography.Title>{`${props.garage?.upazila ? props.garage?.upazila : ""}${props.garage?.upazila ? ", " : ""}${props.garage?.upazila ? props.garage?.district : ""}` || "n/a"}</SolTypography.Title>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='mb-6'>
                                    <SolTypography.Text secondary>Point of Contact</SolTypography.Text>
                                    <SolTypography.Title>{props.garage?.garage_owner_name || "n/a"}</SolTypography.Title>
                                    <SolTypography.SubTitle>{props.garage?.garage_phone_number || "n/a"}</SolTypography.SubTitle>
                                </div>
                            </div>
                        </div>

                    </> : helperInfoRenderer()}
                </div>
            </div>


            {/* <div className={'row'}>
                <div className={'col-md-12'}>


                </div>
            </div> */}
        </div>





    );
};

GarageDetailsModal.propTypes = {

};


const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        garage: state.commonReducer.garage,
        listLoading: state.commonReducer.listLoading,
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(GarageDetailsModal));