import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    deposits: undefined,
    errorMessageRegardingDepositCollection: undefined,
    depositsForExport: undefined,
    errorMessageRegardingDepositExport: undefined,
    depositDetails: undefined,
    errorMessageForDepositDetailsCollection: undefined,
    garagesToShowUpdatedInfo: undefined,
    borrowersForCashDeposit: undefined,
    errorMessageForBorrowersCollection: undefined,
    smartBatteriesForCashDeposit: undefined,
    errorMessageForSmartBatteriesCollection: undefined,
    invoicesForCashDeposit: undefined,
    errorMessageForInvoicesCollection: undefined,
    cashDeposited: undefined,
    errorMessageForCashDeposit: undefined,
    currentBalance: undefined,
    errorMessageForBalanceCollection: undefined
};

const initialStateForNewCashDeposit= {
    borrowersForCashDeposit: undefined,
    errorMessageForBorrowersCollection: undefined,
    smartBatteriesForCashDeposit: undefined,
    errorMessageForSmartBatteriesCollection: undefined,
    invoicesForCashDeposit: undefined,
    errorMessageForInvoicesCollection: undefined,
    cashDeposited: undefined,
    errorMessageForCashDeposit: undefined,
    currentBalance: undefined,
    errorMessageForBalanceCollection: undefined
};

/*Reducer for Borrower's redux store*/
export const depositsReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.COLLECT_DEPOSITS_REQUEST:
            return {
                ...state,
                deposits: undefined,
                errorMessageRegardingDepositCollection: undefined,
                collectingDeposits: true
            };
        case actionTypes.COLLECT_DEPOSITS_SUCCESS:
            return {
                ...state,
                deposits: action.payload,
                collectingDeposits: false
            };
        case actionTypes.COLLECT_DEPOSITS_FAILURE:
            return {
                ...state,
                errorMessageRegardingDepositCollection: action.payload.error,
                collectingDeposits: false
            };

        case actionTypes.COLLECT_DEPOSITS_FOR_EXPORT_REQUEST:
            return {
                ...state,
                depositsForExport: undefined,
                errorMessageRegardingDepositExport: undefined,
                collectingDepositsForExport: true
            };
        case actionTypes.COLLECT_DEPOSITS_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                depositsForExport: action.payload,
                collectingDepositsForExport: false
            };
        case actionTypes.COLLECT_DEPOSITS_FOR_EXPORT_FAILURE:
            return {
                ...state,
                errorMessageRegardingDepositExport: action.payload.error,
                collectingDepositsForExport: false
            };

        case actionTypes.COLLECT_DEPOSITS_DETAILS_REQUEST:
            return {
                ...state,
                depositDetails: undefined,
                errorMessageForDepositDetailsCollection: undefined,
                collectingDepositDetails: true
            };
        case actionTypes.COLLECT_DEPOSITS_DETAILS_SUCCESS:
            return {
                ...state,
                depositDetails: action.payload,
                collectingDepositDetails: false
            };
        case actionTypes.COLLECT_DEPOSITS_DETAILS_FAILURE:
            return {
                ...state,
                errorMessageForDepositDetailsCollection: action.payload.error,
                collectingDepositDetails: false
            };

        case actionTypes.COLLECT_GARAGE_LIST_REQUEST:
            return {
                ...state,
                garagesToShowUpdatedInfo: undefined
            };
        case actionTypes.COLLECT_GARAGE_LIST_SUCCESS:
            return {
                ...state,
                garagesToShowUpdatedInfo: action.payload
            };
        case actionTypes.COLLECT_GARAGE_LIST_FAILURE:
            return {
                ...state
            };

        case actionTypes.COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT_REQUEST:
            return {
                ...state,
                borrowersForCashDeposit: undefined,
                errorMessageForBorrowersCollection: undefined,
                collectingBorrowersForCashDeposit: true
            };
        case actionTypes.COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT_SUCCESS:
            return {
                ...state,
                borrowersForCashDeposit: action.payload,
                collectingBorrowersForCashDeposit: false
            };
        case actionTypes.COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT_FAILURE:
            return {
                ...state,
                errorMessageForBorrowersCollection: action.payload.error,
                collectingBorrowersForCashDeposit: false
            };

        case actionTypes.COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT_REQUEST:
            return {
                ...state,
                smartBatteriesForCashDeposit: undefined,
                errorMessageForSmartBatteriesCollection: undefined,
                collectingSmartBatteriesForCashDeposit: true
            };
        case actionTypes.COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT_SUCCESS:
            return {
                ...state,
                smartBatteriesForCashDeposit: action.payload,
                collectingSmartBatteriesForCashDeposit: false
            };
        case actionTypes.COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT_FAILURE:
            return {
                ...state,
                errorMessageForSmartBatteriesCollection: action.payload.error,
                collectingSmartBatteriesForCashDeposit: false
            };

        case actionTypes.COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT_REQUEST:
            return {
                ...state,
                invoicesForCashDeposit: undefined,
                errorMessageForInvoicesCollection: undefined,
                collectingInvoicesForCashDeposit: true
            };
        case actionTypes.COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT_SUCCESS:
            return {
                ...state,
                invoicesForCashDeposit: action.payload,
                collectingInvoicesForCashDeposit: false
            };
        case actionTypes.COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT_FAILURE:
            return {
                ...state,
                errorMessageForInvoicesCollection: action.payload.error,
                collectingInvoicesForCashDeposit: false
            };

        case actionTypes.SAVE_NEW_CASH_DEPOSIT_REQUEST:
            return {
                ...state,
                cashDeposited: undefined,
                errorMessageForCashDeposit: undefined,
                cashDepositProcessInProgress: true
            };
        case actionTypes.SAVE_NEW_CASH_DEPOSIT_SUCCESS:
            return {
                ...state,
                cashDeposited: requestCycle.success,
                cashDepositProcessInProgress: false
            };
        case actionTypes.SAVE_NEW_CASH_DEPOSIT_FAILURE:
            return {
                ...state,
                cashDeposited: requestCycle.failed,
                errorMessageForCashDeposit: action.payload.error,
                cashDepositProcessInProgress: false
            };

        case actionTypes.COLLECT_CURRENT_BALANCE_REQUEST:
            return {
                ...state,
                currentBalance: undefined,
                errorMessageForBalanceCollection: undefined,
                collectingCurrentBalance: true
            };
        case actionTypes.COLLECT_CURRENT_BALANCE_SUCCESS:
            return {
                ...state,
                currentBalance: action.payload,
                collectingCurrentBalance: false
            };
        case actionTypes.COLLECT_CURRENT_BALANCE_FAILURE:
            return {
                ...state,
                errorMessageForBalanceCollection: action.payload.error,
                collectingCurrentBalance: false
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        case actionTypes.RETURN_TO_INITIAL_STATE_FOR_NEW_CASH_DEPOSIT:
            return {
                ...state,
                ...initialStateForNewCashDeposit
            }

        default:
            return state;
    }
};
