import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {actions} from "../../../actions";
import {actions as commonActions} from "../../../../commonReduxActions/actions";
import {makeStyles} from "@material-ui/styles";
import {withRoles} from "../../../../../router/SecuredRoute";
import Alert from '@mui/material/Alert';
import {useMediaQuery} from "react-responsive";
import Avatar from '@mui/material/Avatar';
import {GenericModal} from "../../../../../components/genericModal/genericModal";
import {ListGroup} from "react-bootstrap";
import {LoadingSpinner} from "../../../../../components";
import moment from "moment/moment";


const BorrowerProfile = ({title, name, forComponent, profileData, ...props}) => {
    const [garages, setGarages] = useState(undefined);
    const [language, setLanguage] = useState(null);
    const [overDue, setOverDue] = useState(0);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    // Garage details
    const [showGarageDetailsModal, setShowGarageDetailsModal] = useState(false);
    const [garageName, setGarageName] = useState('');
    const [registeredOn, setRegisteredOn] = useState('');
    const [parentOrganization, setParentOrganization] = useState('');
    const [tradeLicense, setTradeLicense] = useState('');
    const [district, setDistrict] = useState('');
    const [subDistrict, setSubDistrict] = useState('');
    const [thana, setThana] = useState('');
    const [address, setAddress] = useState('');
    const [garageOwnerName, setGarageOwnerName] = useState('');
    const [garageOwnerContactNumber, setGarageOwnerContactNumber] = useState('');
    const [dueLimit, setDueLimit] = useState('');
    const [batteryWisePaymentEnabled, setBatteryWisePaymentEnabled] = useState(false);

    useEffect(() => {
        setLanguage(props.language)
    }, []);

    useEffect(() => {
        if (profileData && profileData.hasOwnProperty('garage_info')) {
            setOverDue(profileData['garage_info'].overdue_total)
        }
    }, [profileData]);

    const openGarageDetailsModal = () => {
        setGarageName(profileData.garage_info.name)
        setRegisteredOn(moment(profileData.garage_info.sk).format("MMM DD, YYYY"))
        setParentOrganization(profileData.organization_name)
        setDistrict(profileData.garage_info.district)
        setSubDistrict(profileData.garage_info.upazila)
        setThana(profileData.garage_info.thana)
        setAddress(profileData.garage_info.address)

        setGarageOwnerName(profileData.garage_info.garage_owner_name)
        setGarageOwnerContactNumber(profileData.garage_info.garage_phone_number)
        setTradeLicense(profileData.garage_info.trade_licence_number)
        setDueLimit(profileData.garage_info.total_due_limit)
        setBatteryWisePaymentEnabled(profileData.garage_info.battery_wise_payment)
        setShowGarageDetailsModal(true)
    }

    const hideGarageDetailsModal = () => {
        setShowGarageDetailsModal(false)
    }

    const renderGarageDetailsModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideGarageDetailsModal}
                centered={true}
                modal={showGarageDetailsModal}
                title={<h1><strong>{garageName}</strong></h1>}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <ListGroup variant={"flush"}>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small><strong>Registered on</strong></small><br/>
                                <h4><strong>{registeredOn}</strong></h4>
                            </ListGroup.Item>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small><strong>Organization</strong></small><br/>
                                <h4><strong>{parentOrganization}</strong></h4>
                            </ListGroup.Item>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small><strong>District</strong></small><br/>
                                <h4><strong>{district}</strong></h4>
                            </ListGroup.Item>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small><strong>Sub District / Upazila / City Corporation</strong></small><br/>
                                <h4><strong>{subDistrict}</strong></h4>
                            </ListGroup.Item>
                            {thana? <>
                                <ListGroup.Item className={"border-bottom-0"}>
                                    <small><strong>Thana</strong></small><br/>
                                    <h4><strong>{thana}</strong></h4>
                                </ListGroup.Item>
                            </>:''}
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small><strong>Address</strong></small><br/>
                                <h4><strong>{address}</strong></h4>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className={'col-md-6'}>
                        <ListGroup variant={"flush"}>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small><strong>Garage owner</strong></small><br/>
                                <h5><strong>{garageOwnerName}</strong></h5>
                            </ListGroup.Item>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small><strong>Contact number</strong></small><br/>
                                <h5><strong>{garageOwnerContactNumber}</strong></h5>
                            </ListGroup.Item>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small><strong>Trade license</strong></small><br/>
                                <h5><strong>{tradeLicense}</strong></h5>
                            </ListGroup.Item>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small><strong>Due Limit</strong></small><br/>
                                <h5><strong>{dueLimit || dueLimit === 0 ? 'Tk ' + new Intl.NumberFormat('en-IN').format(dueLimit.toFixed(2)):'N/A'}</strong></h5>
                            </ListGroup.Item>
                            <ListGroup.Item className={"border-bottom-0"}>
                                <small><strong>Battery-wise payment enabled</strong></small><br/>
                                <h5><strong>{batteryWisePaymentEnabled? 'Yes':'No'}</strong></h5>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
            </GenericModal>
        </>
    }

    // Source of the letter Avatar (with following two functions): https://mui.com/material-ui/react-avatar/ - Noor Reza, 4th October, 6:48 PM
    // Though We have updated it by adding a checking for the one word name
    const stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const stringAvatar = (name) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1]? name.split(' ')[1][0]:''}`,
        }
    }

    return (
        <>
            <div className={'row'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}><h6><strong>{title}</strong></h6></div>
            <div className={forComponent === 'driver'? 'row mt-4':'row'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                <Avatar size={"lg"} variant="square"
                        {...stringAvatar(profileData.borrower_info? profileData.borrower_info.name? profileData.borrower_info.name:'N A':'N A')}
                        ref={el => {
                            if (el) {
                                el.style.setProperty('width', '100px', 'important')
                                el.style.setProperty('height', '100px', 'important')
                                el.style.setProperty('font-size', '70px', 'important')
                            }
                        }}/>
            </div>
            <div className={'row mt-2'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}><h5><strong>{profileData.borrower_info? <>{profileData.borrower_info.name? <>{profileData.borrower_info.name}</>:'N/A'}</>:'N/A'}</strong></h5></div>
            {profileData.garage_info && (profileData.garage_info.name || profileData.garage_info.district)? <>
                <div className={'row'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                    <a onClick={() => {openGarageDetailsModal()}}>
                        {profileData.garage_info.name && profileData.garage_info.district? <>
                            {profileData.garage_info.name}, {profileData.garage_info.district}
                        </>:<>
                            {profileData.garage_info.name? <>{profileData.garage_info.name}</>:<>{profileData.garage_info.district}</>}
                        </>}
                    </a>
                </div>
            </>:''}
            {profileData.contract_info && (profileData.contract_info.lease_payment_interval || profileData.contract_info.per_installment_payable || profileData.device_serial_number)? <>
                <div className={'row mt-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                    {profileData.contract_info? <>
                        <a href={"contract-management/details/" + profileData.contract_info.contract_id} target={"_blank"}>
                            {profileData.contract_info.lease_payment_interval && profileData.contract_info.per_installment_payable && profileData.device_serial_number? <>
                                Tk {new Intl.NumberFormat('en-IN').format(profileData.contract_info.per_installment_payable.toFixed(2))}/{profileData.contract_info.lease_payment_interval === 'weekly'? 'Week':'Month'} for Smart Battery #{profileData.device_serial_number}
                            </>:<>
                                {profileData.contract_info.lease_payment_interval && profileData.contract_info.per_installment_payable && !profileData.device_serial_number? <>
                                    Tk {new Intl.NumberFormat('en-IN').format(profileData.contract_info.per_installment_payable.toFixed(2))}/{profileData.contract_info.lease_payment_interval === 'weekly'? 'Week':'Month'}
                                </>:<>
                                    {profileData.contract_info.lease_payment_interval && !profileData.contract_info.per_installment_payable && profileData.device_serial_number? <>
                                        {profileData.contract_info.lease_payment_interval === 'weekly'? 'Weekly lease':'Monthly lease'} for Smart Battery #{profileData.device_serial_number}
                                    </>:<>
                                        {!profileData.contract_info.lease_payment_interval && profileData.contract_info.per_installment_payable && profileData.device_serial_number? <>
                                            Tk {new Intl.NumberFormat('en-IN').format(profileData.contract_info.per_installment_payable.toFixed(2))} for Smart Battery #{profileData.device_serial_number}
                                        </>:<>
                                            {profileData.contract_info.lease_payment_interval && !profileData.contract_info.per_installment_payable && !profileData.device_serial_number? <>
                                                {profileData.contract_info.lease_payment_interval === 'weekly'? 'Weekly lease':'Monthly lease'}
                                            </>:<>
                                                {!profileData.contract_info.lease_payment_interval && profileData.contract_info.per_installment_payable && !profileData.device_serial_number? <>
                                                    Tk {new Intl.NumberFormat('en-IN').format(profileData.contract_info.per_installment_payable.toFixed(2))}
                                                </>:<>
                                                    Smart Battery #{profileData.device_serial_number}
                                                </>}
                                            </>}
                                        </>}
                                    </>}
                                </>}
                            </>}
                        </a>
                    </>:<>
                        {profileData.device_serial_number? <>
                            <span>Smart Battery #{profileData.device_serial_number}</span>
                        </>:''}
                    </>}
                </div>
            </>:''}
            {profileData.garage_info && profileData.garage_info.total_due_limit? <>
                <div className={'row mt-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>Due Limit: Tk {new Intl.NumberFormat('en-IN').format(profileData.garage_info.total_due_limit.toFixed(2))}</div>
            </>:''}
            {overDue? <>
                <div className={'row'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}><Alert severity="error" icon={false}>Tk {new Intl.NumberFormat('en-IN').format(overDue.toFixed(2))} Overdue</Alert></div>
            </>:''}
            {isTabletOrMobile? <hr style={{marginLeft: '0.1px'}}/>:null}
            {renderGarageDetailsModal()}
        </>
    );
};

BorrowerProfile.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(BorrowerProfile));
