import {combineReducers} from "redux";
import { authReducer } from "../features/auth/reducer";
import { dashboard } from "../pages/DashboardPage";
import refreshReducer from '../utils/refreshToken/reducer';
import { usersReducer } from "../features/users/reducer";
import { rolesReducer } from "../features/roles/reducer";
import { organizationsReducer } from "../features/organizations/reducer";
import { driversReducer } from "../features/drivers/reducer";
import { smartDongleReducer } from "../features/smartDongles/reducer";
import { batteryReducer } from "../features/battery/reducer";
import { batteryLogReducer } from "../features/batteryLog/reducer";
import { devicesReducer } from "../features/devices/reducer";
import { evsReducer } from "../features/evs/reducer";
import { rentLogReducer } from "../features/rentLog/reducer";
import { transactionReducer } from "../features/transactions/reducer";
import { tokensReducer } from "../features/tokens/reducer";
import {appEventReducer} from "../features/appEvents/reducer";
import {otaReducer} from "../features/ota/reducer";
import {commonReducer} from "../features/commonReduxActions/commonReducer";
import {manualTransactionsReducer} from "../features/manualTransactions/reducer";
import {pcbQATestingResultsReducer} from "../features/pcbQaTestingResults/reducer";
import {historicalDataReducer} from "../features/historicalLog/reducer";
import {summaryReportReducer} from "../features/summary/reducer";
import {borrowersReducer} from "../features/borrowers/reducer";
import {contractManagementReducer} from "../features/contractManagement/reducer";
import {invoicesReducer} from "../features/invoices/reducer";
import {settlementReducer} from "../features/settlements/reducer";
import {overviewReducer} from "../features/overview/reducer";
import {searchResultReducer} from "../features/search/reducer";
import {depositsReducer} from "../features/deposits/reducer";
import {notificationsReducer} from "../features/notifications/reducer";
import {financierReducer} from "../features/financiers/reducer";

export const rootReducer = combineReducers({
  tokenRefresh: refreshReducer,
  auth: authReducer,
  dashboard: dashboard,
  usersReducer: usersReducer,
  appEventReducer: appEventReducer,
  rolesReducer: rolesReducer,
  otaReducer: otaReducer,
  organizationsReducer: organizationsReducer,
  driversReducer: driversReducer,
  smartDongleReducer: smartDongleReducer,
  batteryReducer: batteryReducer,
  batteryLogReducer: batteryLogReducer,
  devicesReducer: devicesReducer,
  rentLogReducer: rentLogReducer,
  transactionReducer: transactionReducer,
  tokensReducer: tokensReducer,
  commonReducer: commonReducer,
  evsReducer: evsReducer,
  manualTransactionsReducer: manualTransactionsReducer,
  pcbQATestingResultsReducer: pcbQATestingResultsReducer,
  historicalDataReducer: historicalDataReducer,
  summaryReportReducer: summaryReportReducer,
  borrowersReducer: borrowersReducer,
  contractManagementReducer: contractManagementReducer,
  invoicesReducer: invoicesReducer,
  settlementReducer: settlementReducer,
  overviewReducer: overviewReducer,
  searchResultReducer: searchResultReducer,
  depositsReducer: depositsReducer,
  notificationsReducer: notificationsReducer,
  financierReducer: financierReducer
});
