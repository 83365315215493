import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {actions} from "../../actions";
import {withRoles} from "../../../../router/SecuredRoute";
import { useMediaQuery } from 'react-responsive'
import {checkAuthorization} from "../../../auth/authorization";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Skeleton from "@mui/material/Skeleton";
import "../customStyleForOverview.css";


const SummaryInfoForOverview = ({showRentedForToday, showLatePayment, showTravelledKm, showCo2Emission,
                                    showOverdue, showPerformance, summaryDataFromGarage, summaryDataFromTransaction, ...props}) => {
    const [totalDeployedEv, setTotalDeployedEv] = useState(0)
    const [weeklyDeployedEvPercentage, setWeeklyDeployedEvPercentage] = useState(undefined)
    const [batteryRentedForToday, setBatteryRentedForToday] = useState(undefined)
    const [weeklyBatteryRentPercentage, setWeeklyBatteryRentPercentage] = useState(undefined)
    const [weeklyLatePayment, setWeeklyLatePayment] = useState(undefined)
    const [weeklyLatePaymentChangePercentage, setWeeklyLatePaymentChangePercentage] = useState(undefined)
    const [weeklyTravellingInKm, setWeeklyTravellingInKm] = useState(undefined)
    const [weeklyTravellingChangePercentage, setWeeklyTravellingChangePercentage] = useState(undefined)
    const [co2EmissionSaved, setCo2EmissionSaved] = useState(undefined)
    const [weeklyCo2EmissionSaveChangePercentage, setWeeklyCo2EmissionSaveChangePercentage] = useState(undefined)
    const [weeklyOverDue, setWeeklyOverDue] = useState(undefined)
    const [weeklyOverDueChangePercentage, setWeeklyOverDueChangePercentage] = useState(undefined)

    const [showTransactionCards, setShowTransactionCards] = useState(false)
    const [showGarageCards, setShowGarageCards] = useState(false)
    const [showKmDrivenTooltip, setShowKmDrivenTooltip] = useState(false)
    const [showCo2EmissionTooltip, setShowCo2EmissionTooltip] = useState(false)
    const [canSeeAllTransactions, setCanSeeAllTransactions] = useState(false)
    const transactionServiceName = getServiceName('transactionService')
    const transactionFeatureName = getFeatureName('transactionFeature')
    const listActionName = getActionName('listAction')
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const successStyleForPercentageText = {
        fontSize: '50%',
        color: '#0B6E4F' // This color code has been taken from Figma
    }
    const failureStyleForPercentageText = {
        fontSize: '50%',
        color: '#E71D36' // This color code has been taken from Figma
    }
    const upwardIcon = (success=true) => {
        return <SvgIcon>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 15.8333L15.8333 5M15.8333 5V15.4M15.8333 5H5.43333"
                      stroke={success? "#0B6E4F":"#E71D36"} strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    }
    const downwardIcon = (failed=true) => {
        return <SvgIcon>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 5L15.8333 15.8333M15.8333 15.8333V5.43333M15.8333 15.8333H5.43333"
                      stroke={failed? "#E71D36":"#0B6E4F"} strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    }

    // Reference for the following: https://mui.com/material-ui/react-tooltip/#customization
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: '0.875rem',
            fontWeight: 400
        }
    }));

    const toolTipTextForCo2EmissionSaved = 'This is an approximate information based on battery performance trend' +
        ' and statistical analysis of distance travelled by the vehicles.'

    const toolTipTextForTravelledKm = 'This is an approximate information based on battery performance trend, ' +
        'statistical analysis of expected battery discharge rate and manually collected mileage data of the vehicles.'

    useEffect(() => {
        setCanSeeAllTransactions(checkAuthorization(props.authorization, transactionServiceName,
            transactionFeatureName, [listActionName]))
    },[])

    useEffect(() => {
        if (summaryDataFromGarage && Object.keys(summaryDataFromGarage).length > 0) {
            // Total Deployed
            setTotalDeployedEv(summaryDataFromGarage.deployed_ev_total)
            // setWeeklyDeployedEvPercentage(summaryDataFromGarage.weekly_deployed_ev_change_in_percent)
            // Rented For Today
            setBatteryRentedForToday(summaryDataFromGarage.daily_active_ev_total)
            setWeeklyBatteryRentPercentage(summaryDataFromGarage.weekly_active_ev_change_in_percent)
            // Travelled (km)
            setWeeklyTravellingInKm(summaryDataFromGarage.travelled_km_total)
            setWeeklyTravellingChangePercentage(summaryDataFromGarage.weekly_travelled_km_change_in_percent)
            // Co2 emission saved (kg)
            setCo2EmissionSaved(summaryDataFromGarage.co2_emission_saved_total)
            setWeeklyCo2EmissionSaveChangePercentage(summaryDataFromGarage.weekly_co2_emission_saved_change_in_percent)

            setShowGarageCards(true)
        }
    },[summaryDataFromGarage])

    useEffect(() => {
        if (summaryDataFromTransaction && Object.keys(summaryDataFromTransaction).length > 0) {
            // Late Payment %
            setWeeklyLatePayment(summaryDataFromTransaction.weekly_late_payment_in_percent)
            setWeeklyLatePaymentChangePercentage(summaryDataFromTransaction.weekly_late_payment_change_in_percent)
            // Overdue this week (Tk)
            setWeeklyOverDue(summaryDataFromTransaction.weekly_overdue_amount_total)
            setWeeklyOverDueChangePercentage(summaryDataFromTransaction.weekly_overdue_amount_change_in_percent)

            setShowTransactionCards(true)
        }
    },[summaryDataFromTransaction])

    const totalBatteriesDeployedCard = () => {
        return <>
            <Card sx={{ width: '100%', height: '100%', minHeight: '113px'}}>
                {props.collectingSummaryDataFromGarage? <>
                    <Skeleton animation="wave" className={"d-flex justify-content-center align-items-center custom-loading-screen-style"} variant="rectangular" width={'100%'} height={'100%'}>
                        Total Batteries deployed (Till Date)
                    </Skeleton>
                </>:<>
                    <CardContent>
                        <Typography variant="subtitle2" component="div">
                            Total Batteries Deployed (Till Date)
                        </Typography>
                        <Typography variant="h4" component="div">
                            {!totalDeployedEv && totalDeployedEv !==0? 'N/A':totalDeployedEv}
                        </Typography>
                        {/*<Typography variant="h4" component="div">*/}
                        {/*    {!totalDeployedEv && totalDeployedEv !==0? 'N/A':totalDeployedEv}{weeklyDeployedEvPercentage? <>*/}
                        {/*    &nbsp;&nbsp;<span style={weeklyDeployedEvPercentage>0? successStyleForPercentageText:failureStyleForPercentageText}>{Math.abs(weeklyDeployedEvPercentage.toFixed(2))}%&nbsp;*/}
                        {/*    {weeklyDeployedEvPercentage > 0? <>*/}
                        {/*        {upwardIcon()}*/}
                        {/*    </>:<>*/}
                        {/*        {downwardIcon()}*/}
                        {/*    </>}</span>*/}
                        {/*</>:null}*/}
                        {/*</Typography>*/}
                    </CardContent>
                    <CardActions className={'ml-2'}>
                        <img src={require('../../../../utils/asset/LiBAT.svg')} alt={"battery"}/>
                    </CardActions>
                </>}
            </Card>
        </>
    }

    const rentedForTodayCard = () => {
        return <>
            <Card sx={{ width: '100%', height: '100%', minHeight: '113px' }}>
                {props.collectingSummaryDataFromGarage? <>
                    <Skeleton animation="wave" className={"d-flex justify-content-center align-items-center custom-loading-screen-style"} variant="rectangular" width={'100%'} height={'100%'}>
                        Rented For Today
                    </Skeleton>
                </>:<>
                    <CardContent>
                        <Typography variant="subtitle2" component="div">
                            Rented For Today
                        </Typography>
                        <Typography variant="h4" component="div">
                            {!batteryRentedForToday && batteryRentedForToday !==0? 'N/A':batteryRentedForToday}{weeklyBatteryRentPercentage? <>&nbsp;&nbsp;<span
                            style={weeklyBatteryRentPercentage > 0? successStyleForPercentageText:failureStyleForPercentageText}>{Math.abs(weeklyBatteryRentPercentage.toFixed(2))}%&nbsp;
                            {weeklyBatteryRentPercentage > 0? <>
                                {upwardIcon()}
                            </>:<>
                                {downwardIcon()}
                            </>}</span>
                        </>:null}
                        </Typography>
                        {/*<Typography variant="body2" gutterBottom className={'mt-2'}>*/}
                        {/*    <a style={{color: "#F18D00"}} onClick={() => {console.log("here")}}>*/}
                        {/*        See Idle Batteries List*/}
                        {/*    </a>*/}
                        {/*</Typography>*/}
                    </CardContent>
                    <CardActions className={'ml-2'}>
                        <img src={require('../../../../utils/asset/LIBAT_rented.svg')} alt={"rented battery"}/>
                    </CardActions>
                </>}
            </Card>
        </>
    }

    const travelledKmCard = () => {
        return <>
            <Card sx={{ width: '100%', height: '100%', minHeight: '113px'}}>
                {props.collectingSummaryDataFromGarage? <>
                    <Skeleton animation="wave" className={"d-flex justify-content-center align-items-center custom-loading-screen-style"} variant="rectangular" width={'100%'} height={'100%'}>
                        Travelled (km)
                    </Skeleton>
                </>:<>
                    <CardContent>
                        <Typography variant="subtitle2" component="div">
                            Travelled (km)
                        </Typography>
                        <Typography variant="h4" component="div">
                            {props.isSolshareUser? <>
                                {!weeklyTravellingInKm && weeklyTravellingInKm !==0? 'N/A':new Intl.NumberFormat('en-IN').format(Math.abs(weeklyTravellingInKm.toFixed(2)))}{weeklyTravellingChangePercentage? <>
                                &nbsp;&nbsp;<span style={successStyleForPercentageText}>{Math.abs(weeklyTravellingChangePercentage.toFixed(2))}%&nbsp;
                                {weeklyTravellingChangePercentage > 0? <>
                                    {upwardIcon()}
                                </>:<>
                                    {downwardIcon()}
                                </>}</span>
                            </>:null}
                            </>:<>N/A</>}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{display: "flex", justifyContent: "flex-end"}} className={'ml-2 mr-2'}>
                        <img src={require('../../../../utils/asset/material-symbols_speed-outline-rounded.svg')} alt={"travelled"} style={{marginRight: "auto"}}/>
                        <LightTooltip title={toolTipTextForTravelledKm}
                                      placement="bottom-end" arrow componentsProps={{
                            tooltip: {
                                sx: {
                                    '& .MuiTooltip-arrow': {
                                        color: 'common.white'
                                    }
                                }
                            }}}
                                      open={showKmDrivenTooltip}
                                      onOpen={() => setShowKmDrivenTooltip(true)}
                                      onClose={() => setShowKmDrivenTooltip(false)}
                        >
                            <img src={require('../../../../utils/asset/information.svg')} alt={"information"}
                                 style={{marginLeft: "auto"}}
                                 onClick={() => {
                                     if (isTabletOrMobile) {
                                         setShowKmDrivenTooltip(!showKmDrivenTooltip)
                                     }
                                 }}
                            />
                        </LightTooltip>
                    </CardActions>
                </>}
            </Card>
        </>
    }

    const co2EmissionCard = () => {
        return <>
            <Card sx={{ width: '100%', height: '100%', minHeight: '113px' }}>
                {props.collectingSummaryDataFromGarage? <>
                    <Skeleton animation="wave" className={"d-flex justify-content-center align-items-center custom-loading-screen-style"} variant="rectangular" width={'100%'} height={'100%'}>
                        CO2 Emission Saved (kg)
                    </Skeleton>
                </>:<>
                    <CardContent>
                        <Typography variant="subtitle2" component="div">
                            CO2 Emission Saved (kg)
                        </Typography>
                        <Typography variant="h4" component="div">
                            {props.isSolshareUser? <>
                                {!co2EmissionSaved && co2EmissionSaved !==0? 'N/A':new Intl.NumberFormat('en-IN').format(Math.abs(co2EmissionSaved.toFixed(2)))}{weeklyCo2EmissionSaveChangePercentage? <>
                                &nbsp;&nbsp;<span style={successStyleForPercentageText}>{Math.abs(weeklyCo2EmissionSaveChangePercentage.toFixed(2))}%&nbsp;
                                {weeklyCo2EmissionSaveChangePercentage > 0? <>
                                    {upwardIcon()}
                                </>:<>
                                    {downwardIcon()}
                                </>}</span>
                            </>:null}
                            </>:<>N/A</>}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{display: "flex", justifyContent: "flex-end"}} className={'ml-2 mr-2'}>
                        <img src={require('../../../../utils/asset/material-symbols_temp-preferences-eco-outline.svg')} alt={"battery"} style={{marginRight: "auto"}}/>
                        <LightTooltip title={toolTipTextForCo2EmissionSaved}
                                      placement="bottom-end" arrow componentsProps={{
                            tooltip: {
                                sx: {
                                    '& .MuiTooltip-arrow': {
                                        color: 'common.white'
                                    }
                                }
                            }}}
                                      open={showCo2EmissionTooltip}
                                      onOpen={() => setShowCo2EmissionTooltip(true)}
                                      onClose={() => setShowCo2EmissionTooltip(false)}
                        >
                            <img src={require('../../../../utils/asset/information.svg')} alt={"information"}
                                 style={{marginLeft: "auto"}}
                                 onClick={() => {
                                     if (isTabletOrMobile) {
                                         setShowCo2EmissionTooltip(!showCo2EmissionTooltip)
                                     }
                                 }}
                            />
                        </LightTooltip>
                    </CardActions>
                </>}
            </Card>
        </>
    }

    const latePaymentCard = () => {
        return <>
            <Card sx={{ width: '100%', height: '100%', minHeight: '113px' }} size={'lg'}>
                {props.collectingSummaryDataFromTransaction? <>
                    <Skeleton animation="wave" className={"d-flex justify-content-center align-items-center custom-loading-screen-style"} variant="rectangular" width={'100%'} height={'100%'}>
                        Late Payment % (This Week)
                    </Skeleton>
                </>:<>
                    <CardContent>
                        <Typography variant="subtitle2" component="div">
                            Late Payment % (This Week)
                        </Typography>
                        <Typography variant="h4" component="div">
                            {!weeklyLatePayment && weeklyLatePayment !==0? 'N/A':Math.abs(weeklyLatePayment.toFixed(2))}{weeklyLatePaymentChangePercentage? <>
                            &nbsp;&nbsp;<span style={weeklyLatePaymentChangePercentage > 0? failureStyleForPercentageText:successStyleForPercentageText}>{Math.abs(weeklyLatePaymentChangePercentage.toFixed(2))}%&nbsp;
                            {weeklyLatePaymentChangePercentage > 0? <>
                                {upwardIcon(false)}
                            </>:<>
                                {downwardIcon(false)}
                            </>}</span>
                        </>:null}
                        </Typography>
                    </CardContent>
                    <CardActions className={'ml-2'}>
                        <img src={require('../../../../utils/asset/Clock history.svg')} alt={"payment history"}/>
                    </CardActions>
                </>}
            </Card>
        </>
    }

    const overDueCard = () => {
        return <>
            <Card sx={{ width: '100%', height: '100%', minHeight: '113px' }}>
                {props.collectingSummaryDataFromTransaction? <>
                    <Skeleton animation="wave" className={"d-flex justify-content-center align-items-center custom-loading-screen-style"} variant="rectangular" width={'100%'} height={'100%'}>
                        Overdue This Week (Tk)
                    </Skeleton>
                </>:<>
                    <CardContent>
                        <Typography variant="subtitle2" component="div">
                            Overdue This Week (Tk)
                        </Typography>
                        <Typography variant="h4" component="div">
                            {!weeklyOverDue && weeklyOverDue !==0? 'N/A':new Intl.NumberFormat('en-IN').format(weeklyOverDue.toFixed(2))}{weeklyOverDueChangePercentage? <>
                            &nbsp;&nbsp;<span style={weeklyOverDueChangePercentage > 0? failureStyleForPercentageText:successStyleForPercentageText}>{Math.abs(weeklyOverDueChangePercentage.toFixed(2))}%&nbsp;
                            {weeklyOverDueChangePercentage > 0? <>
                                {upwardIcon(false)}
                            </>:<>
                                {downwardIcon(false)}
                            </>}</span>
                        </>:null}
                        </Typography>
                        {/*<Typography variant="body2" gutterBottom className={'mt-2'}>*/}
                        {/*    <a style={{color: "#F18D00"}} onClick={() => {console.log("here")}}>*/}
                        {/*        Who Has Overdue?*/}
                        {/*    </a>*/}
                        {/*</Typography>*/}
                        {canSeeAllTransactions? <>
                            <Typography variant="body2" gutterBottom className={'mt-2'}>
                                <a style={{color: "#F18D00"}} target={!isTabletOrMobile? "_blank":""} href={"/payments/list"}>
                                    See All Transactions
                                </a>
                            </Typography>
                        </>:null}
                    </CardContent>
                </>}
            </Card>
        </>
    }

    return (
        <>
            <div onClick={() => {
                if (isTabletOrMobile && showKmDrivenTooltip) {
                    setShowKmDrivenTooltip(false)
                } else if (isTabletOrMobile && showCo2EmissionTooltip) {
                    setShowCo2EmissionTooltip(false)
                }
            }}>
                {showRentedForToday && showLatePayment? <>
                    {/* At top section: "Rented for today" and "Late Payment" both are not visible */}
                    <div className={"row"}>
                        <div className={'col-lg-4'}>
                            {totalBatteriesDeployedCard()}
                        </div>
                        <div className={'col-lg-4'}>
                            {rentedForTodayCard()}
                        </div>
                        <div className={'col-lg-4'}>
                            {latePaymentCard()}
                        </div>
                    </div>
                    {showTravelledKm || showCo2Emission || showOverdue? <>
                        <div className={"row mt-5"}>
                            {showTravelledKm? <>
                                <div className={'col-lg-4'}>
                                    {travelledKmCard()}
                                </div>
                            </>:''}
                            {showCo2Emission? <>
                                <div className={'col-lg-4'}>
                                    {co2EmissionCard()}
                                </div>
                            </>:''}
                            {showOverdue? <>
                                <div className={'col-lg-4'}>
                                    {overDueCard()}
                                </div>
                            </>:''}
                        </div>
                    </>:''}
                </>:<>
                    {!showRentedForToday && !showLatePayment? <>
                        {/* At top section: with "Batteries deployed", "Rented for today" and "Late Payment" both are not visible */}
                        {showTravelledKm && showCo2Emission && showOverdue? <>
                            <div className={"row"}>
                                <div className={'col-lg-4'}>
                                    {totalBatteriesDeployedCard()}
                                </div>
                                <div className={'col-lg-4'}>
                                    {travelledKmCard()}
                                </div>
                                <div className={'col-lg-4'}>
                                    {co2EmissionCard()}
                                </div>
                            </div>
                            <div className={"row mt-5"}>
                                <div className={'col-lg-4'}>
                                    {overDueCard()}
                                </div>
                            </div>
                        </>:<>
                            {showTravelledKm && showCo2Emission && !showOverdue? <>
                                <div className={"row"}>
                                    <div className={'col-lg-4'}>
                                        {totalBatteriesDeployedCard()}
                                    </div>
                                    <div className={'col-lg-4'}>
                                        {travelledKmCard()}
                                    </div>
                                    <div className={'col-lg-4'}>
                                        {co2EmissionCard()}
                                    </div>
                                </div>
                            </>:<>
                                {showTravelledKm && !showCo2Emission && showOverdue? <>
                                    <div className={"row"}>
                                        <div className={'col-lg-4'}>
                                            {totalBatteriesDeployedCard()}
                                        </div>
                                        <div className={'col-lg-4'}>
                                            {travelledKmCard()}
                                        </div>
                                        <div className={'col-lg-4'}>
                                            {overDueCard()}
                                        </div>
                                    </div>
                                </>:<>
                                    {!showTravelledKm && showCo2Emission && showOverdue? <>
                                        <div className={"row"}>
                                            <div className={'col-lg-4'}>
                                                {totalBatteriesDeployedCard()}
                                            </div>
                                            <div className={'col-lg-4'}>
                                                {co2EmissionCard()}
                                            </div>
                                            <div className={'col-lg-4'}>
                                                {overDueCard()}
                                            </div>
                                        </div>
                                    </>:<>
                                        {showTravelledKm && !showCo2Emission && !showOverdue? <>
                                            <div className={'row'}>
                                                <div className={'col-lg-6'}>
                                                    {totalBatteriesDeployedCard()}
                                                </div>
                                                <div className={'col-lg-6'}>
                                                    {travelledKmCard()}
                                                </div>
                                            </div>
                                        </>:<>
                                            {!showTravelledKm && showCo2Emission && !showOverdue? <>
                                                <div className={'row'}>
                                                    <div className={'col-lg-6'}>
                                                        {totalBatteriesDeployedCard()}
                                                    </div>
                                                    <div className={'col-lg-6'}>
                                                        {co2EmissionCard()}
                                                    </div>
                                                </div>
                                            </>:<>
                                                {!showTravelledKm && !showCo2Emission && showOverdue? <>
                                                    <div className={'row'}>
                                                        <div className={'col-lg-6'}>
                                                            {totalBatteriesDeployedCard()}
                                                        </div>
                                                        <div className={'col-lg-6'}>
                                                            {overDueCard()}
                                                        </div>
                                                    </div>
                                                </>:<>
                                                    {/* Only Total Batteries deployed */}
                                                    <div className={'row'}>
                                                        <div className={showPerformance? 'col-lg-12':'col-lg-4'}>
                                                            {totalBatteriesDeployedCard()}
                                                        </div>
                                                    </div>
                                                </>}
                                            </>}
                                        </>}
                                    </>}
                                </>}
                            </>}
                        </>}
                    </>:<>
                        {showRentedForToday? <>
                            {/* At top section: with "Batteries deployed" only Rented for today visible */}
                            {showTravelledKm && showCo2Emission && showOverdue? <>
                                <div className={"row"}>
                                    <div className={'col-lg-4'}>
                                        {totalBatteriesDeployedCard()}
                                    </div>
                                    <div className={'col-lg-4'}>
                                        {rentedForTodayCard()}
                                    </div>
                                    <div className={'col-lg-4'}>
                                        {travelledKmCard()}
                                    </div>
                                </div>
                                <div className={"row mt-5"}>
                                    <div className={'col-lg-4'}>
                                        {co2EmissionCard()}
                                    </div>
                                    <div className={'col-lg-4'}>
                                        {overDueCard()}
                                    </div>
                                </div>
                            </>:<>
                                {showTravelledKm && showCo2Emission && !showOverdue? <>
                                    <div className={"row"}>
                                        <div className={'col-lg-4'}>
                                            {totalBatteriesDeployedCard()}
                                        </div>
                                        <div className={'col-lg-4'}>
                                            {rentedForTodayCard()}
                                        </div>
                                        <div className={'col-lg-4'}>
                                            {travelledKmCard()}
                                        </div>
                                    </div>
                                    <div className={"row mt-5"}>
                                        <div className={'col-lg-4'}>
                                            {co2EmissionCard()}
                                        </div>
                                    </div>
                                </>:<>
                                    {showTravelledKm && !showCo2Emission && showOverdue? <>
                                        <div className={"row"}>
                                            <div className={'col-lg-4'}>
                                                {totalBatteriesDeployedCard()}
                                            </div>
                                            <div className={'col-lg-4'}>
                                                {rentedForTodayCard()}
                                            </div>
                                            <div className={'col-lg-4'}>
                                                {travelledKmCard()}
                                            </div>
                                        </div>
                                        <div className={"row mt-5"}>
                                            <div className={'col-lg-4'}>
                                                {overDueCard()}
                                            </div>
                                        </div>
                                    </>:<>
                                        {!showTravelledKm && showCo2Emission && showOverdue? <>
                                            <div className={"row"}>
                                                <div className={'col-lg-4'}>
                                                    {totalBatteriesDeployedCard()}
                                                </div>
                                                <div className={'col-lg-4'}>
                                                    {rentedForTodayCard()}
                                                </div>
                                                <div className={'col-lg-4'}>
                                                    {co2EmissionCard()}
                                                </div>
                                            </div>
                                            <div className={"row mt-5"}>
                                                <div className={'col-lg-4'}>
                                                    {overDueCard()}
                                                </div>
                                            </div>
                                        </>:<>
                                            {showTravelledKm && !showCo2Emission && !showOverdue? <>
                                                <div className={"row"}>
                                                    <div className={'col-lg-4'}>
                                                        {totalBatteriesDeployedCard()}
                                                    </div>
                                                    <div className={'col-lg-4'}>
                                                        {rentedForTodayCard()}
                                                    </div>
                                                    <div className={'col-lg-4'}>
                                                        {travelledKmCard()}
                                                    </div>
                                                </div>
                                            </>:<>
                                                {!showTravelledKm && showCo2Emission && !showOverdue? <>
                                                    <div className={"row"}>
                                                        <div className={'col-lg-4'}>
                                                            {totalBatteriesDeployedCard()}
                                                        </div>
                                                        <div className={'col-lg-4'}>
                                                            {rentedForTodayCard()}
                                                        </div>
                                                        <div className={'col-lg-4'}>
                                                            {co2EmissionCard()}
                                                        </div>
                                                    </div>
                                                </>:<>
                                                    {!showTravelledKm && !showCo2Emission && showOverdue? <>
                                                        <div className={"row"}>
                                                            <div className={'col-lg-4'}>
                                                                {totalBatteriesDeployedCard()}
                                                            </div>
                                                            <div className={'col-lg-4'}>
                                                                {rentedForTodayCard()}
                                                            </div>
                                                            <div className={'col-lg-4'}>
                                                                {overDueCard()}
                                                            </div>
                                                        </div>
                                                    </>:<>
                                                        {/* Only Total Batteries deployed and rented for today */}
                                                        <div className={"row"}>
                                                            <div className={'col-lg-6'}>
                                                                {totalBatteriesDeployedCard()}
                                                            </div>
                                                            <div className={'col-lg-6'}>
                                                                {rentedForTodayCard()}
                                                            </div>
                                                        </div>
                                                    </>}
                                                </>}
                                            </>}
                                        </>}
                                    </>}
                                </>}
                            </>}
                        </>:<>
                            {/* At top section: with "Batteries deployed" Late payment today visible */}
                            {showTravelledKm && showCo2Emission && showOverdue? <>
                                <div className={"row"}>
                                    <div className={'col-lg-4'}>
                                        {totalBatteriesDeployedCard()}
                                    </div>
                                    <div className={'col-lg-4'}>
                                        {latePaymentCard()}
                                    </div>
                                    <div className={'col-lg-4'}>
                                        {travelledKmCard()}
                                    </div>
                                </div>
                                <div className={"row mt-5"}>
                                    <div className={'col-lg-4'}>
                                        {co2EmissionCard()}
                                    </div>
                                    <div className={'col-lg-4'}>
                                        {overDueCard()}
                                    </div>
                                </div>
                            </>:<>
                                {showTravelledKm && showCo2Emission && !showOverdue? <>
                                    <div className={"row"}>
                                        <div className={'col-lg-4'}>
                                            {totalBatteriesDeployedCard()}
                                        </div>
                                        <div className={'col-lg-4'}>
                                            {latePaymentCard()}
                                        </div>
                                        <div className={'col-lg-4'}>
                                            {travelledKmCard()}
                                        </div>
                                    </div>
                                    <div className={"row mt-5"}>
                                        <div className={'col-lg-4'}>
                                            {co2EmissionCard()}
                                        </div>
                                    </div>
                                </>:<>
                                    {showTravelledKm && !showCo2Emission && showOverdue? <>
                                        <div className={"row"}>
                                            <div className={'col-lg-4'}>
                                                {totalBatteriesDeployedCard()}
                                            </div>
                                            <div className={'col-lg-4'}>
                                                {latePaymentCard()}
                                            </div>
                                            <div className={'col-lg-4'}>
                                                {travelledKmCard()}
                                            </div>
                                        </div>
                                        <div className={"row mt-5"}>
                                            <div className={'col-lg-4'}>
                                                {overDueCard()}
                                            </div>
                                        </div>
                                    </>:<>
                                        {!showTravelledKm && showCo2Emission && showOverdue? <>
                                            <div className={"row"}>
                                                <div className={'col-lg-4'}>
                                                    {totalBatteriesDeployedCard()}
                                                </div>
                                                <div className={'col-lg-4'}>
                                                    {latePaymentCard()}
                                                </div>
                                                <div className={'col-lg-4'}>
                                                    {co2EmissionCard()}
                                                </div>
                                            </div>
                                            <div className={"row mt-5"}>
                                                <div className={'col-lg-4'}>
                                                    {overDueCard()}
                                                </div>
                                            </div>
                                        </>:<>
                                            {showTravelledKm && !showCo2Emission && !showOverdue? <>
                                                <div className={"row"}>
                                                    <div className={'col-lg-4'}>
                                                        {totalBatteriesDeployedCard()}
                                                    </div>
                                                    <div className={'col-lg-4'}>
                                                        {latePaymentCard()}
                                                    </div>
                                                    <div className={'col-lg-4'}>
                                                        {travelledKmCard()}
                                                    </div>
                                                </div>
                                            </>:<>
                                                {!showTravelledKm && showCo2Emission && !showOverdue? <>
                                                    <div className={"row"}>
                                                        <div className={'col-lg-4'}>
                                                            {totalBatteriesDeployedCard()}
                                                        </div>
                                                        <div className={'col-lg-4'}>
                                                            {latePaymentCard()}
                                                        </div>
                                                        <div className={'col-lg-4'}>
                                                            {co2EmissionCard()}
                                                        </div>
                                                    </div>
                                                </>:<>
                                                    {!showTravelledKm && !showCo2Emission && showOverdue? <>
                                                        <div className={"row"}>
                                                            <div className={'col-lg-4'}>
                                                                {totalBatteriesDeployedCard()}
                                                            </div>
                                                            <div className={'col-lg-4'}>
                                                                {latePaymentCard()}
                                                            </div>
                                                            <div className={'col-lg-4'}>
                                                                {overDueCard()}
                                                            </div>
                                                        </div>
                                                    </>:<>
                                                        {/* Only Total Batteries deployed and Late payment today */}
                                                        <div className={"row"}>
                                                            <div className={'col-lg-6'}>
                                                                {totalBatteriesDeployedCard()}
                                                            </div>
                                                            <div className={'col-lg-6'}>
                                                                {latePaymentCard()}
                                                            </div>
                                                        </div>
                                                    </>}
                                                </>}
                                            </>}
                                        </>}
                                    </>}
                                </>}
                            </>}
                        </>}
                    </>}
                </>}
                {showGarageCards || showTransactionCards? <>
                    <div className={"row mt-5"}>
                        <div className={"col-lg-12"}>
                            <Typography variant="body2" gutterBottom>
                                % amount denotes week over week change
                            </Typography>
                        </div>
                    </div>
                </>:''}
            </div>
        </>
    )
}

SummaryInfoForOverview.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        collectingSummaryDataFromGarage: state.overviewReducer.collectingSummaryDataFromGarage,
        collectingSummaryDataFromTransaction: state.overviewReducer.collectingSummaryDataFromTransaction
    }
}

export default connect(mapStateToProps, {...actions})(withRoles(SummaryInfoForOverview))
