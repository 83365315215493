import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    // TODO: Update the initiate state
    rentList: undefined,
    driverDetails: undefined,
    driverCheckout: undefined,
    successMessage: undefined,
    errorMessage: undefined,
    successMessageForCheckIn: undefined,
    errorMessageForCheckIn: undefined,
    driverCheckIn: undefined,
    checkInProcessInProgress: undefined,
    garages: undefined
};

/*Reducer for Driver's redux store*/
export const summaryReportReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.COLLECT_SUMMARY_INFO_REQUEST:
            return {
                ...state,
                listLoading: true,
                rentList: undefined,
                driverCheckout: undefined,
                driverVerified: undefined,
                deviceVerified: undefined,
                successMessage: undefined,
                errorMessage: undefined,
                successMessageForCheckIn: undefined,
                errorMessageForCheckIn: undefined,
                driverCheckIn: undefined
            };
        case actionTypes.COLLECT_SUMMARY_INFO_SUCCESS:
            return {
                ...state,
                listLoading: false,
                rentList: action.payload
            };
        case actionTypes.COLLECT_SUMMARY_INFO_FAILURE:
            toast.error("Can't show the summary info! Please contact with admin.");
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.COLLECT_SUMMARY_INFO_TO_EXPORT_REQUEST:
            return {
                ...state,
                iconLoading: true,
                driverListForExport: undefined,
            };
        case actionTypes.COLLECT_SUMMARY_INFO_TO_EXPORT_SUCCESS:
            return {
                ...state,
                iconLoading: false,
                driverListForExport: action.payload
            };
        case actionTypes.COLLECT_SUMMARY_INFO_TO_EXPORT_FAILURE:
            toast.error("Can't export the summary info! Please contact with admin.");
            return {
                ...state,
                iconLoading: false
            };

        case actionTypes.COLLECT_GARAGE_LIST_REQUEST:
            return {
                ...state,
                garages: undefined,
                garageCollectionInProgress: true
            };
        case actionTypes.COLLECT_GARAGE_LIST_SUCCESS:
            return {
                ...state,
                garages: action.payload,
                garageCollectionInProgress: false
            };
        case actionTypes.COLLECT_GARAGE_LIST_FAILURE:
            toast.error("Can't collect the garage list! Please contact with an admin.");
            return {
                ...state,
                garageCollectionInProgress: false
            };

        case actionTypes.COLLECT_DRIVER_DETAILS_REQUEST:
            return {
                ...state,
                driverDetails: undefined,
                driverDetailsCollectionInProgress: true
            };
        case actionTypes.COLLECT_DRIVER_DETAILS_SUCCESS:
            return {
                ...state,
                driverDetails: action.payload,
                driverDetailsCollectionInProgress: false
            };
        case actionTypes.COLLECT_DRIVER_DETAILS_FAILURE:
            toast.error("Can't collect details of the driver! Please contact with an admin.");
            return {
                ...state,
                driverDetailsCollectionInProgress: false
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
