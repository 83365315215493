import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import HistoricalLogs from "./views/historicalLogs/historicalLogs";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const HistoricalLogRouter =(props)=> {

    return (
        <Switch>
            <Redirect
                exact={"true"}
                from={"/historical-data"}
                to={"/historical-data/list"}
            />

            {/* Routes to Historical Log List*/}
            <SecuredRoute
                path={"/historical-data/list"}
                component={HistoricalLogs}
                service={getServiceName('batteryService')}
                feature={getFeatureName('historicalDataFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
