import React, {Fragment} from "react";
import DateTimeFormat from "../../utils/dateFormat/DateTimeFormat";
import styled from "styled-components";
import {Spinner} from "react-bootstrap";
import {SolTypography} from "../../components/utilityComponents/SOlTypography";
import {isNumber} from "../../utils/utilityFunctions";


export const DataTableContainer = styled.div`
  padding: 4rem 0 0 0;
  min-height: 25rem;
  height: auto;
`

export const LoadingSpinner=({loadingSubText, size})=>{
    return <Fragment>
        <div className="align-content-center mt-5"  align="center">
            <h2 className={`mb-5`}>
                <span>{`Fetching Data`}</span>
            </h2>
            <Spinner animation="border"  size={size || 'sm'} variant="primary" />
            <br/>
            <SolTypography.SubTitle primary> {loadingSubText} </SolTypography.SubTitle>

        </div>

    </Fragment>
}


export const requestCycle = {
    success: 'success',
    pending: 'pending',
    failed: 'failed',
}
