import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for transactions*/
const initialState= {
    settlementPayments: undefined,
    settlementPaymentsForExport: undefined,
    invoices: undefined,
    invoicesForExport: undefined,
    financiers: undefined,
    paymentsForExport: undefined,
    errorMessage: undefined,
    garages: undefined,
    errorMessageForGarageCollection: undefined,
    borrowers: undefined,
    errorMessageForBorrowerCollection: undefined,
    invoicesForExtension: undefined,
    errorMessageForInvoicesForExtensionCollection: undefined,
    contracts: undefined,
    errorMessageForContractsCollection: undefined,
    invoiceExtended: undefined,
    errorMessageForInvoiceExtension: undefined,
    installmentExtended: undefined,
    errorMessageForInstallmentExtension: undefined
};

const initialStateForBulkExtension= {
    garages: undefined,
    errorMessageForGarageCollection: undefined,
    borrowers: undefined,
    errorMessageForBorrowerCollection: undefined,
    invoicesForExtension: undefined,
    errorMessageForInvoicesForExtensionCollection: undefined,
    contracts: undefined,
    errorMessageForContractsCollection: undefined,
    invoiceExtended: undefined,
    errorMessageForInvoiceExtension: undefined,
    installmentExtended: undefined,
    errorMessageForInstallmentExtension: undefined
};

/*Reducer for Driver's redux store*/
export const invoicesReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {

        case actionTypes.COLLECT_INVOICES_LIST_REQUEST:
            return {
                ...state,
                collectingInvoices: true,
                invoices: undefined
            }
        case actionTypes.COLLECT_INVOICES_LIST_SUCCESS:
            return {
                ...state,
                collectingInvoices: false,
                invoices: action.payload,
            }
        case actionTypes.COLLECT_INVOICES_LIST_FAILURE:
            toast.error("Failed to collect invoices! Please contact with an admin.")
            return {
                ...state,
                collectingInvoices: false
            }

        case actionTypes.COLLECT_INVOICES_LIST_FOR_EXPORT_REQUEST:
            return {
                ...state,
                collectingInvoicesForExport: true,
                invoicesForExport: undefined
            }
        case actionTypes.COLLECT_INVOICES_LIST_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                collectingInvoicesForExport: false,
                invoicesForExport: action.payload,
            }
        case actionTypes.COLLECT_INVOICES_LIST_FOR_EXPORT_FAILURE:
            toast.error("Failed to collect data for export! Please contact with an admin.")
            return {
                ...state,
                collectingInvoicesForExport: false
            }

        case actionTypes.COLLECT_FINANCIERS_LIST_REQUEST:
            return {
                ...state,
                collectingFinanciers: true,
                financiers: undefined
            }
        case actionTypes.COLLECT_FINANCIERS_LIST_SUCCESS:
            return {
                ...state,
                collectingFinanciers: false,
                financiers: action.payload,
            }
        case actionTypes.COLLECT_FINANCIERS_LIST_FAILURE:
            toast.error("Failed to collect financiers! Please contact with an admin.")
            return {
                ...state,
                collectingFinanciers: false
            }

        case actionTypes.FETCH_PAYMENTS_EXPORT_REQUEST:
            return {
                ...state,
                collectingDataForExport: true,
                paymentsForExport: undefined
            }
        case actionTypes.FETCH_PAYMENTS_EXPORT_SUCCESS:
            return {
                ...state,
                collectingDataForExport: false,
                paymentsForExport: action.payload
            }
        case actionTypes.FETCH_PAYMENTS_EXPORT_FAILURE:
            toast.error("Failed to collect wallet transactions for export!")
            return {
                ...state,
                collectingDataForExport: false
            }

        case actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_REQUEST:
            return {
                ...state,
                listLoading: true,
                settlementPayments: undefined
            }
        case actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_SUCCESS:
            return {
                ...state,
                listLoading: false,
                settlementPayments: action.payload,
            }
        case actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FAILURE:
            return {
                ...state,
                listLoading: false,
                settlementPayments: undefined
            }

        case actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_REQUEST:
            return {
                ...state,
                collectingTransactionsForExport: true,
                settlementPaymentsForExport: undefined
            }
        case actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                collectingTransactionsForExport: false,
                settlementPaymentsForExport: action.payload,
            }
        case actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_FAILURE:
            return {
                ...state,
                collectingTransactionsForExport: false,
                settlementPaymentsForExport: undefined
            }

        case actionTypes.SETTLE_PAYMENTS_REQUEST:
            return {
                ...state,
                errorMessage: undefined,
            }
        case actionTypes.SETTLE_PAYMENTS_SUCCESS:
            toast.success("Transactions settled")
            return {
                ...state,
                listLoading: false,

            }
        case actionTypes.SETTLE_PAYMENTS_FAILURE:
            toast.error("Something went wrong!")
            return {
                ...state,
                listLoading: false,
                errorMessage: action.error && action.payload?.error?.message ? action.payload?.error?.message : undefined,
            }

        case actionTypes.COLLECT_GARAGES_FOR_BULK_EXTENSION_REQUEST:
            return {
                ...state,
                garages: undefined,
                errorMessageForGarageCollection: undefined,
                garageCollectionInProgress: true
            }
        case actionTypes.COLLECT_GARAGES_FOR_BULK_EXTENSION_SUCCESS:
            return {
                ...state,
                garages: action.payload,
                garageCollectionInProgress: false
            }
        case actionTypes.COLLECT_GARAGES_FOR_BULK_EXTENSION_FAILURE:
            return {
                ...state,
                errorMessageForGarageCollection: action.payload.error,
                garageCollectionInProgress: false
            }

        case actionTypes.COLLECT_BORROWERS_FOR_BULK_EXTENSION_REQUEST:
            return {
                ...state,
                borrowers: undefined,
                errorMessageForBorrowerCollection: undefined,
                borrowerCollectionInProgress: true
            }
        case actionTypes.COLLECT_BORROWERS_FOR_BULK_EXTENSION_SUCCESS:
            return {
                ...state,
                borrowers: action.payload,
                borrowerCollectionInProgress: false
            }
        case actionTypes.COLLECT_BORROWERS_FOR_BULK_EXTENSION_FAILURE:
            return {
                ...state,
                errorMessageForBorrowerCollection: action.payload.error,
                borrowerCollectionInProgress: false
            }

        case actionTypes.COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION_REQUEST:
            return {
                ...state,
                invoicesForExtension: undefined,
                errorMessageForInvoicesForExtensionCollection: undefined,
                invoicesForExtensionCollectionInProgress: true
            }
        case actionTypes.COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION_SUCCESS:
            return {
                ...state,
                invoicesForExtension: action.payload,
                invoicesForExtensionCollectionInProgress: false
            }
        case actionTypes.COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION_FAILURE:
            return {
                ...state,
                errorMessageForInvoicesForExtensionCollection: action.payload.error,
                invoicesForExtensionCollectionInProgress: false
            }

        case actionTypes.COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION_REQUEST:
            return {
                ...state,
                invoicesForExtension: undefined,
                errorMessageForInvoicesForExtensionCollection: undefined,
                invoicesForExtensionCollectionInProgress: true
            }
        case actionTypes.COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION_SUCCESS:
            return {
                ...state,
                invoicesForExtension: action.payload,
                invoicesForExtensionCollectionInProgress: false
            }
        case actionTypes.COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION_FAILURE:
            return {
                ...state,
                errorMessageForInvoicesForExtensionCollection: action.payload.error,
                invoicesForExtensionCollectionInProgress: false
            }

        case actionTypes.COLLECT_CONTRACTS_FOR_BULK_EXTENSION_REQUEST:
            return {
                ...state,
                contracts: undefined,
                errorMessageForContractsCollection: undefined,
                contractsCollectionInProgress: true
            }
        case actionTypes.COLLECT_CONTRACTS_FOR_BULK_EXTENSION_SUCCESS:
            return {
                ...state,
                contracts: action.payload,
                contractsCollectionInProgress: false
            }
        case actionTypes.COLLECT_CONTRACTS_FOR_BULK_EXTENSION_FAILURE:
            return {
                ...state,
                errorMessageForContractsCollection: action.payload.error,
                contractsCollectionInProgress: false
            }

        case actionTypes.EXTEND_OVERDUE_INVOICES_REQUEST:
            return {
                ...state,
                invoiceExtended: undefined,
                errorMessageForInvoiceExtension: undefined,
                invoiceExtensionInProgress: true
            }
        case actionTypes.EXTEND_OVERDUE_INVOICES_SUCCESS:
            return {
                ...state,
                invoiceExtended: requestCycle.success,
                invoiceExtensionInProgress: false
            }
        case actionTypes.EXTEND_OVERDUE_INVOICES_FAILURE:
            return {
                ...state,
                invoiceExtended: requestCycle.failed,
                errorMessageForInvoiceExtension: action.payload.error,
                invoiceExtensionInProgress: false
            }

        case actionTypes.EXTEND_UPCOMING_INSTALLMENTS_REQUEST:
            return {
                ...state,
                installmentExtended: undefined,
                errorMessageForInstallmentExtension: undefined,
                installmentExtensionInProgress: true
            }
        case actionTypes.EXTEND_UPCOMING_INSTALLMENTS_SUCCESS:
            return {
                ...state,
                installmentExtended: requestCycle.success,
                installmentExtensionInProgress: false
            }
        case actionTypes.EXTEND_UPCOMING_INSTALLMENTS_FAILURE:
            return {
                ...state,
                installmentExtended: requestCycle.failed,
                errorMessageForInstallmentExtension: action.payload.error,
                installmentExtensionInProgress: false
            }

        case actionTypes.RETURN_TO_INITIAL_STATE_FOR_BULK_INVOICE_EXTENSION:
            return {
                ...state,
                ...initialStateForBulkExtension
            }

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
