import React, {useEffect, useState} from 'react';
import { useLocation } from "react-router";
import PropTypes from 'prop-types';
import {useForm} from "react-hook-form";
import {Button, Form, InputGroup, OverlayTrigger, Spinner} from "react-bootstrap";
import Select from "react-select";
import {SolInput, SolTextArea} from "../../../../components/SolStyledComponents/components";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {DatePicker} from '@material-ui/pickers/'
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {actions} from "../../actions";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import moment from "moment";
import {requestCycle} from "../../utils";
import * as bdData from "../../../../utils/countryData/3dListBangladesh";
import {usersReducer} from "../../reducer";
import {Toolbar} from "../components";
import {showNotifications} from "../../../../utils/notification";
import {isValidPhoneNumber} from 'libphonenumber-js';
import {toBengaliNumber} from "bengali-number";
import {useSubheader} from "../../../../../_metronic/layout";


const useStylesForDatePicker = makeStyles({
    root: {
        marginTop: '0',
        marginBottom: '0',
        border: 'none',
        width: '100%',
        '& input': {
            color: 'black',
            padding: "0 10px 0 10px",
            height: "38px",
            outline: "none",
        },
        '& input:hover, input:focus, input:active': {
            border: "1px solid #F18D00",
            boxShadow: "0px 0px 0px 3.2px r gba(241, 141, 0, 0.1)",
            outline: "none",
        }
    },
});
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#ffffff',
        }
    },
});
const CreateDriver = props => {
    const {register, handleSubmit, errors, setError, clearErrors,control} = useForm();
    const {toBengaliNumber} = require('bengali-number');
    const location = useLocation();
    const [language, setLanguage] = useState('EN');
    const [edit, setEdit] = useState(sessionStorage.getItem('edit')? true:false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [upazilaOptions, setUpazilaOptions] = useState([]);
    const [disableUpazilaField, setDisableUpazilaField] = useState(true);
    const [showGeneralRequiredMessage, setShowGeneralRequiredMessage] = useState(false);
    const subHeader = useSubheader();

    const [name, setName] = useState(sessionStorage.getItem('name')? sessionStorage.getItem('name'):"");
    const [phoneNumber, setPhoneNumber] = useState(sessionStorage.getItem('phoneNumber')? sessionStorage.getItem('phoneNumber'):"");
    const [homeDistrict, setHomeDistrict] = useState("");
    const [upazila, setUpazila] = useState("");
    const [village, setVillage] = useState(sessionStorage.getItem('village')? sessionStorage.getItem('village'):"");
    const [typeOfVehicle, setTypeOfVehicle] = useState(sessionStorage.getItem('typeOfVehicle')? JSON.parse(sessionStorage.getItem('typeOfVehicle')):"");
    const [dailyIncome, setDailyIncome] = useState(sessionStorage.getItem('dailyIncome')? sessionStorage.getItem('dailyIncome'):"");

    const [phoneNumberInputValidationErrorMessage, setPhoneNumberInputValidationErrorMessage] = useState(false);
    const [phoneNumberLengthErrorMessage, setPhoneNumberLengthErrorMessage] = useState(false);
    const [villageNameInvalid, setVillageNameInvalid] = useState(false);
    const [nameInvalid, setNameInvalid] = useState(false);

    const [dailyIncomeInputInvalid, setDailyIncomeInputInvalid] = useState(false);
    const [disableRegisterButton, setDisableRegisterButton] = useState(false);

    const typeOfVehicleOptions = [
        {
            label: language === 'EN'? "ER":"ইআর",
            value: "ER",
        },
        {
            label: language === 'EN'? "EM":"ইএম",
            value: "EM",
        },
        {
            label: language === 'EN'? "EV":"ইভি",
            value: "EV",
        }
    ]

    const generalErrorMessage = language === 'EN'? "Please provide above information!":"দয়া করে উপরের তথ্যটি দিন!"
    const nonNumericalValueRelatedErrorMessage = language === 'EN'? "Please provide only numbers!":"দয়া করে শুধু সংখ্যা লিখুন!"

    const {
        COLLECT_DISTRICT_LIST,
        COLLECT_UPAZILA_LIST,
        CREATE_DRIVER,
        EDIT_DRIVER
    } = props;

    useEffect(() => {
        if (props.successMessage) {
            if (language === 'EN') {
                showNotifications('success', props.successMessage)
            } else {
                toast.success("সফলভাবে চালক নিবন্ধন সম্পন্ন হয়েছে!")
            }
        } else if (props.errorMessage) {
            if (props.errorMessage.hasOwnProperty('message')) {
                toast.error(props.errorMessage['message'])
            } else {
                setErrorMessage(showNotifications('error', props.errorMessage, true))
            }
        }
    }, [props.successMessage, props.errorMessage]);

    useEffect(() => {
        if (props.successMessageForEdit) {
            if (language === 'EN') {
                showNotifications('success', props.successMessageForEdit)
            } else {
                toast.success("সফলভাবে চালকের তথ্য সম্পাদনা সম্পন্ন হয়েছে!")
            }
        } else if (props.errorMessageForEdit) {
            if (props.errorMessageForEdit.hasOwnProperty('message')) {
                toast.error(props.errorMessageForEdit['message'])
            } else {
                setErrorMessage(showNotifications('error', props.errorMessageForEdit, true))
            }
        }
    }, [props.successMessageForEdit, props.errorMessageForEdit]);

    useEffect(()=>{
        setLanguage(props.language)
        subHeader.setActionButtons(null);
        // TODO: Though 'Driver' feature hasn't been used, commenting here to keep track
        // TODO: As district list API has been updated, if we want to get all district list
        // TODO: We need pass filter param: division_id = 'all' - Noor Reza, 30th Nov, 1:56 PM
        COLLECT_DISTRICT_LIST()
        if (location.purpose && location.purpose === 'create') {
            setEdit(false)
            sessionStorage.setItem('edit', false)
        }
        if (location.driverData) {
            sessionStorage.setItem('edit', true)
            sessionStorage.setItem('name', location.driverData.name)
            sessionStorage.setItem('phoneNumber', location.driverData.phone_number)
            sessionStorage.setItem('district', location.driverData.district)
            sessionStorage.setItem('upazilla', location.driverData.upazila)
            // For some old data, 'village' will be null
            let village = !location.driverData.village? "":location.driverData.village
            sessionStorage.setItem('village', village)
            sessionStorage.setItem('dailyIncome', location.driverData.daily_income)
            let typeOfVehicle = {label: location.driverData.type_of_vehicle,
                value: location.driverData.type_of_vehicle}
            sessionStorage.setItem('typeOfVehicle', JSON.stringify(typeOfVehicle))
            setEdit(true)
            setName(location.driverData.name)
            setPhoneNumber(location.driverData.phone_number)
            setVillage(village)
            setDailyIncome(location.driverData.daily_income)
            setTypeOfVehicle(typeOfVehicle)
        }
    },[])

    useEffect(()=>{
        if (sessionStorage.getItem('edit') === 'false') {
            setName("")
            setPhoneNumber("")
            setVillage("")
            setHomeDistrict("")
            setUpazila("")
            setDailyIncome("")
            setTypeOfVehicle("")
            clearSessionStorages()
        }
    },[edit])

    useEffect(()=>{
        if (props.districts) {
            const districts = props.districts
            setDistrictOptions(districts.map((district) => {
                if (sessionStorage.getItem('district') && district.name === sessionStorage.getItem('district'))
                {
                    setHomeDistrict({
                        id: district.id,
                        label: language === 'EN'? district.name:district.bn_name,
                        value: district.name
                    })
                    COLLECT_UPAZILA_LIST(district.id)
                }
                return {
                    id: district.id,
                    label: language === 'EN'? district.name:district.bn_name,
                    value: district.name
                }
            }))
        }
    },[props.districts])

    useEffect(()=>{
        if (props.upazilas) {
            const upazilas = props.upazilas
            setUpazilaOptions(upazilas.map((upazila) => {
                if (sessionStorage.getItem('upazilla') && upazila.name === sessionStorage.getItem('upazilla'))
                {
                    setUpazila({
                        value: upazila.name,
                        label: language === 'EN'? upazila.name:upazila.bn_name
                    })
                }
                return {
                    value: upazila.name,
                    label: language === 'EN'? upazila.name:upazila.bn_name
                }
            }))
        }
    },[props.upazilas])

    useEffect(()=>{
        if (props.disableUpazilaSelection) {
            setDisableUpazilaField(props.disableUpazilaField)
        }
    },[props.disableUpazilaSelection])

    useEffect(()=>{
        if (props.driverCreated===requestCycle.success || props.driverUpdated===requestCycle.success){
            clearSessionStorages()
            props.history.push('/drivers/list/');
        }
    },[props.driverCreated, props.driverUpdated])

    const clearSessionStorages = () => {
        sessionStorage.removeItem('edit')
        sessionStorage.removeItem('name')
        sessionStorage.removeItem('phoneNumber')
        sessionStorage.removeItem('district')
        sessionStorage.removeItem('upazilla')
        sessionStorage.removeItem('village')
        sessionStorage.removeItem('dailyIncome')
        sessionStorage.removeItem('typeOfVehicle')
    }

    const cancelButton = () => {
        return (
            <Button variant="dark" onClick={()=>{
                clearSessionStorages()
                props.history.goBack()
            }} style={{
                backgroundColor: '#8C8C9B',
                outline: '#8C8C9B',
                border: '#8C8C9B',
            }}>
                <i className='fa fa-times' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Cancel':'বাতিল করুন'}
            </Button>
        )
    }

    const handleTextFieldsChanges = (e) => {
        let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        let value = e.target.value, fieldName = e.target.name;
        if (fieldName === 'name') {
            delete errorMessage["name"]
            setName(value)
            if (value && (!isNaN(value) || format.test(value))) {
                setNameInvalid(true)
            } else {
                setNameInvalid(false)
            }
        } else if (fieldName === 'phoneNumber') {
            delete errorMessage["phone_number"]
            setPhoneNumber(value)
            if (isNaN(value) || value.includes('.')) {
                setPhoneNumberInputValidationErrorMessage(true)
            } else {
                setPhoneNumberLengthErrorMessage(false)
                setPhoneNumberInputValidationErrorMessage(false)
            }
        } else if (fieldName === 'village') {
            delete errorMessage['village']
            setVillage(value)
            if (value && (!isNaN(value) || format.test(value))) {
                setVillageNameInvalid(true)
            } else {
                setVillageNameInvalid(false)
            }
        } else if (fieldName === 'dailyIncome') {
            delete errorMessage['daily_income']
            setDailyIncome(value)
            if (isNaN(value) || value.includes('.')) {
                setDailyIncomeInputInvalid(true)
            } else {
                setDailyIncomeInputInvalid(false)
            }
        }
    }

    const onSubmit = () => {
        if (!name || !phoneNumber || !homeDistrict || !upazila || !village || !typeOfVehicle || !dailyIncome) {
            setShowGeneralRequiredMessage(true)
        } else if (parseInt(dailyIncome) === 0) {
            let err = language === 'EN' ? "Daily income should not be zero!":"প্রতিদিনের উপার্জন কখনই শূন্য হতে পারবে না!"
            setErrorMessage({"daily_income": err})
        } else if (phoneNumber.length !== 11) {
            setPhoneNumberLengthErrorMessage(true)
        } else {
            let phoneIsValidated = isValidPhoneNumber(phoneNumber, 'BD')
            if (phoneIsValidated) {
                let payload = {
                    'name': name,
                    'phone_number': phoneNumber,
                    'district': homeDistrict.value,
                    'upazila': upazila.value,
                    'village': village,
                    'type_of_vehicle': typeOfVehicle.value,
                    'daily_income': parseInt(dailyIncome)
                }
                if (!edit) {
                    CREATE_DRIVER(payload)
                } else {
                    EDIT_DRIVER(payload)
                }
            } else {
                let err = language === 'EN' ? "Invalid phone number!":"ভুল ফোন নাম্বার!"
                setErrorMessage({"phone_number": err})
            }
        }
    }

    return (
       <ContentWrapper showCardHeader={false} pageTitle={'Register driver'} showBackButton={false} isLoading={false}>
           <div className={'row mb-6'}>
               <div className={'col-md-12'}>
                   <Toolbar>
                       <Toolbar.Title>
                           <h1><b>{edit? (language === 'EN'? 'Edit Driver':'চালক সম্পাদনা করুন'):(language === 'EN'? 'Register Driver':'চালক নিবন্ধন করুন')}</b></h1>
                       </Toolbar.Title>
                   </Toolbar>
               </div>
           </div>
           <div className={'row'}>
               <div className={'col-md-12'}>
                   <Form>
                       <div className={'row g-3'}>
                           <div className={"col-md-6"}>
                               <Form.Group>
                                   <Form.Label>{language === 'EN'? 'Name':'নাম'}<span className="required text-danger">*</span></Form.Label>
                                   <SolInput
                                       type="text"
                                       name={"name"}
                                       value={name}
                                       placeholder={language === 'EN'? "Name of the driver":"চালকের নাম"}
                                       autoComplete={"off"}
                                       onChange={(e) => {handleTextFieldsChanges(e)}}
                                   />
                                   {nameInvalid? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Invalid name!':'নাম গ্রহণযোগ্য নয়!'}</div>:null}
                                   {(showGeneralRequiredMessage && !name && !nameInvalid)? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalErrorMessage}</div>:null}
                                   {errorMessage.name? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.name}</div>:null}
                               </Form.Group>
                           </div>
                       </div>

                       <div className={'row g-3'}>
                           <div className={"col-md-6"}>
                               <Form.Group>
                                   <Form.Label>{language === 'EN'? 'Phone':'ফোন নাম্বার'}<span className="required text-danger">*</span></Form.Label>
                                   <SolInput
                                       type="text"
                                       name={"phoneNumber"}
                                       value={phoneNumber}
                                       disabled={edit}
                                       placeholder={language === 'EN'? "Phone number of the driver":"চালকের ফোন নাম্বার"}
                                       autoComplete={"off"}
                                       onChange={(e) => {
                                           setPhoneNumberLengthErrorMessage(false)
                                           if (e.target.value) {
                                               handleTextFieldsChanges(e)
                                           } else {
                                               setPhoneNumberInputValidationErrorMessage(false)
                                               setPhoneNumber("")
                                           }
                                       }}
                                   />
                                   {phoneNumberInputValidationErrorMessage? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Invalid input!':'ভুল লেখা হচ্ছে!'}</div>:null}
                                   {phoneNumberLengthErrorMessage? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Phone number should be of 11 digits!':'ফোন নাম্বার অবশ্যই ১১ সংখ্যার হতে হবে!'}</div>:null}
                                   {(showGeneralRequiredMessage && !phoneNumber && !phoneNumberInputValidationErrorMessage && !phoneNumberLengthErrorMessage)? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalErrorMessage}</div>:null}
                                   {errorMessage.phone_number? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.phone_number}</div>:null}
                               </Form.Group>
                           </div>
                       </div>

                       <div className={'row g-3'}>
                           <div className={"col-md-6"}>
                               <Form.Group>
                                   <div>
                                       <Form.Label>{language === 'EN'? 'District':'জেলা'}<span className="required text-danger">*</span></Form.Label>
                                   </div>
                                   <Select
                                       name={`district`}
                                       placeholder={language === 'EN'? 'Select district of the driver':'চালকের জেলা বাছাই করুন'}
                                       classNamePrefix="react-select-sol-style"
                                       maxMenuHeight={200}
                                       value={homeDistrict}
                                       isClearable={true}
                                       control={control}
                                       isLoading={props.districtListLoading}
                                       options={districtOptions}
                                       isSearchable={true}
                                       onChange={(selected, {action}) => {
                                           if (action === "clear") {
                                               setUpazila("")
                                               setUpazilaOptions([])
                                           }
                                           if (selected) {
                                               setHomeDistrict(selected)
                                               delete errorMessage['district']
                                               setUpazila("")
                                               COLLECT_UPAZILA_LIST(selected.id)
                                           } else {
                                               setHomeDistrict("")
                                           }
                                       }}
                                   />
                                   {(showGeneralRequiredMessage && !homeDistrict)? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalErrorMessage}</div>:null}
                                   {errorMessage.district? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.district}</div>:null}
                               </Form.Group>
                           </div>
                       </div>

                       <div className={'row g-3'}>
                           <div className={"col-md-6"}>
                               <Form.Group>
                                   <div>
                                       <Form.Label>{language === 'EN'? 'Upazila':'থানা'}<span className="required text-danger">*</span></Form.Label>
                                   </div>
                                   <Select
                                       name={`upazila`}
                                       placeholder={language === 'EN'? 'Select upazila of the driver':'চালকের থানা বাছাই করুন'}
                                       classNamePrefix="react-select-sol-style"
                                       maxMenuHeight={200}
                                       value={upazila}
                                       isClearable={true}
                                       control={control}
                                       isLoading={props.upazilaListLoading}
                                       isDisabled={disableUpazilaField}
                                       options={upazilaOptions}
                                       isSearchable={true}
                                       onChange={(selected) => {
                                           if (selected) {
                                               setUpazila(selected)
                                               delete errorMessage['upazila']
                                           } else {
                                               setUpazila("")
                                           }
                                       }}
                                   />
                                   {(showGeneralRequiredMessage && !upazila)? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalErrorMessage}</div>:null}
                                   {errorMessage.upazila? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.upazila}</div>:null}
                               </Form.Group>
                           </div>
                       </div>

                       <div className={'row g-3'}>
                           <div className={"col-md-6"}>
                               <Form.Group>
                                   <Form.Label>{language === 'EN'? 'Village':'গ্রাম'}<span className="required text-danger">*</span></Form.Label>
                                   <SolInput
                                       type="text"
                                       name={"village"}
                                       value={village}
                                       placeholder={language === 'EN'? "Village of the driver":"চালকের গ্রাম"}
                                       autoComplete={"off"}
                                       onChange={(e) => {handleTextFieldsChanges(e)}}
                                   />
                                   {villageNameInvalid? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Invalid village name!':'গ্রামের নাম গ্রহণযোগ্য নয়!'}</div>:null}
                                   {(showGeneralRequiredMessage && !village && !villageNameInvalid)? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalErrorMessage}</div>:null}
                                   {errorMessage.village? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.village}</div>:null}
                               </Form.Group>
                           </div>
                       </div>

                       <div className={'row g-3'}>
                           <div className={"col-md-6"}>
                               <Form.Group>
                                   <Form.Label>{language === 'EN'? 'Daily income':'প্রতিদিনের উপার্জন'}<span className="required text-danger">*</span></Form.Label>
                                   <SolInput
                                       type="text"
                                       name={"dailyIncome"}
                                       value={dailyIncome}
                                       placeholder={language === "EN"? "Enter daily income..":"প্রতিদিনের উপার্জন লিখুন.."}
                                       autoComplete={"off"}
                                       onChange={(e) => {
                                           handleTextFieldsChanges(e)
                                       }}
                                   />
                                   {dailyIncomeInputInvalid? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{nonNumericalValueRelatedErrorMessage}</div>:null}
                                   {(showGeneralRequiredMessage && !dailyIncome && !dailyIncomeInputInvalid)? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalErrorMessage}</div>:null}
                                   {errorMessage.daily_income? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.daily_income}</div>:null}
                               </Form.Group>
                           </div>
                       </div>

                       <div className={'row g-3'}>
                           <div className={"col-md-6"}>
                               <Form.Group>
                                   <div>
                                       <Form.Label>{language === 'EN'? 'Type of vehicle':'গাড়ির ধরন'}<span className="required text-danger">*</span></Form.Label>
                                   </div>
                                   <Select
                                       name={`TypeOfVehicle`}
                                       placeholder={language === 'EN'? 'Select type of vehicle':'গাড়ির ধরন বাছাই করুন'}
                                       classNamePrefix="react-select-sol-style"
                                       maxMenuHeight={200}
                                       value={typeOfVehicle}
                                       isClearable={true}
                                       control={control}
                                       options={typeOfVehicleOptions}
                                       isSearchable={true}
                                       onChange={(selected) => {
                                           if (selected) {
                                               setTypeOfVehicle(selected)
                                               delete errorMessage['type_of_vehicle']
                                           } else {
                                               setTypeOfVehicle("")
                                           }
                                       }}
                                   />
                                   {(showGeneralRequiredMessage && !typeOfVehicle)? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalErrorMessage}</div>:null}
                                   {errorMessage.type_of_vehicle? <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.type_of_vehicle}</div>:null}
                               </Form.Group>
                           </div>
                       </div>
                       {/*----Buttons----*/}
                       <div className={"row g-3"}>
                           <div className={"col-md-12"}>
                               {cancelButton()}
                               <Button variant="warning" type="button" className={"ml-3"}
                                       disabled={(nameInvalid || villageNameInvalid || dailyIncomeInputInvalid || phoneNumberInputValidationErrorMessage || props.driverInfoSubmitting)? true:false}
                                       onClick={()=>onSubmit()}>
                                   {(props.phoneNumberVerifying || props.driverInfoSubmitting)? <>
                                       <Spinner animation={'border'} size={'sm'} variant={'light'}/></>:null}
                                   {edit? <>
                                       <i className='fa fa-check' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Update':'সম্পাদনা করুন'}
                                   </>:<>
                                       <i className='fa fa-registered' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Register':'নিবন্ধন করুন'}
                                   </>}
                               </Button>
                           </div>
                       </div>
                   </Form>
               </div>
           </div>
           <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
       </ContentWrapper>
    );
};

CreateDriver.propTypes = {};


const mapStateToProps=(state)=>{

    return {
        language: state.auth.language,
        districtListLoading: state.driversReducer.districtListLoading,
        districts: state.driversReducer.districts,
        upazilaListLoading: state.driversReducer.upazilaListLoading,
        disableUpazilaSelection: state.driversReducer.disableUpazilaSelection,
        upazilas: state.driversReducer.upazilas,
        driverCreated: state.driversReducer.driverCreated,
        driverUpdated: state.driversReducer.driverUpdated,
        driverInfoSubmitting: state.driversReducer.driverInfoSubmitting,
        successMessage: state.driversReducer.successMessage,
        errorMessage: state.driversReducer.errorMessage,
        successMessageForEdit: state.driversReducer.successMessageForEdit,
        errorMessageForEdit: state.driversReducer.errorMessageForEdit,
        errorInVerification: state.driversReducer.errorInVerification
    }
}

export default connect(mapStateToProps, actions) (CreateDriver);
