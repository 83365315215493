export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/

    GET_ROLE_LIST_REQUEST: 'GET_ROLE_LIST_REQUEST',
    GET_ROLE_LIST_SUCCESS: 'GET_ROLE_LIST_SUCCESS',
    GET_ROLE_LIST_FAILURE: 'GET_ROLE_LIST_FAILURE',

    GET_ORGANISATIONS_REQUEST: 'GET_ORGANISATIONS_REQUEST',
    GET_ORGANISATIONS_SUCCESS: 'GET_ORGANISATIONS_SUCCESS',
    GET_ORGANISATIONS_FAILURE: 'GET_ORGANISATIONS_FAILURE',

    CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
    CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
    CREATE_ROLE_FAILURE: 'CREATE_ROLE_FAILURE',

    UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
    UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
    UPDATE_ROLE_FAILURE: 'UPDATE_ROLE_FAILURE',

    DUPLICATE_ROLE_REQUEST: 'DUPLICATE_ROLE_REQUEST',
    DUPLICATE_ROLE_SUCCESS: 'DUPLICATE_ROLE_SUCCESS',
    DUPLICATE_ROLE_FAILURE: 'DUPLICATE_ROLE_FAILURE',

    COLLECT_FEATURE_LIST_REQUEST: 'COLLECT_FEATURE_LIST_REQUEST',
    COLLECT_FEATURE_LIST_SUCCESS: 'COLLECT_FEATURE_LIST_SUCCESS',
    COLLECT_FEATURE_LIST_FAILURE: 'COLLECT_FEATURE_LIST_FAILURE',

    RETURN_TO_INITIAL_STATE_OF_ROLE_FEATURE: 'RETURN_TO_INITIAL_STATE_OF_ROLE_FEATURE',
    RETURN_TO_INITIAL_STATE_OF_ROLE_CREATE_UPDATE: 'RETURN_TO_INITIAL_STATE_OF_ROLE_CREATE_UPDATE'
};
