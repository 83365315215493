import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Toolbar} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Button, Form, OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner} from '../../utils'
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable'
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import {DayPickerRangeController} from "react-dates";
import moment from "moment";
import {convertToAnotherLang} from "../../../../utils/convertLanguageOfNumbersWithCharacter";
import WarningIcon from "@mui/icons-material/Warning";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {exportInExcel} from "../../../../utils/excelExport";
import {Link} from "react-router-dom";

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const HistoricalLogs = (props) => {
    const subHeader = useSubheader();
    const [showFilter, setShowFilter] = useState(true);
    const [requiredErrorMessage, setRequiredErrorMessage] = useState(false);
    const [invalidBatterySerial, setInvalidBatterySerial] = useState(false);
    const [invalidDongleSerial, setInvalidDongleSerial] = useState(false);

    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');

    const [batterySerial, setBatterySerial] = useState(null);
    const [dongleSerial, setDongleSerial] = useState(null);

    const [historicalData, setHistoricalData] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [language, setLanguage] = useState('EN');
    const [authorization, setAuthorization] = useState(null);
    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);

    // Details modal related data
    const [dataGenerationDateAtModal, setDataGenerationDateAtModal] = useState('');
    const [dongleAtModal, setDongleAtModal] = useState('');
    const [batteryAtModal, setBatteryAtModal] = useState('');
    const [evAtModal, setEvAtModal] = useState('');
    const [timeStampAtModal, setTimeStampAtModal] = useState('');
    const [numberOfCellBmsSupportedAtModal, setNumberOfCellBmsSupportedAtModal] = useState('');
    const [balancingControlFeedbackAtModal, setBalancingControlFeedbackAtModal] = useState('');
    const [centerVoltageAtModal, setCenterVoltageAtModal] = useState('');
    const [vCell1VoltageAtModal, setVCell1VoltageAtModal] = useState('');
    const [vCell2VoltageAtModal, setVCell2VoltageAtModal] = useState('');
    const [vCell3VoltageAtModal, setVCell3VoltageAtModal] = useState('');
    const [vCell4VoltageAtModal, setVCell4VoltageAtModal] = useState('');
    const [vCell5VoltageAtModal, setVCell5VoltageAtModal] = useState('');
    const [vCell6VoltageAtModal, setVCell6VoltageAtModal] = useState('');
    const [vCell7VoltageAtModal, setVCell7VoltageAtModal] = useState('');
    const [vCell8VoltageAtModal, setVCell8VoltageAtModal] = useState('');
    const [vCell9VoltageAtModal, setVCell9VoltageAtModal] = useState('');
    const [vCell10VoltageAtModal, setVCell10VoltageAtModal] = useState('');
    const [vCell11VoltageAtModal, setVCell11VoltageAtModal] = useState('');
    const [vCell12VoltageAtModal, setVCell12VoltageAtModal] = useState('');
    const [vCell13VoltageAtModal, setVCell13VoltageAtModal] = useState('');
    const [vCell14VoltageAtModal, setVCell14VoltageAtModal] = useState('');
    const [vCell15VoltageAtModal, setVCell15VoltageAtModal] = useState('');
    const [vCell16VoltageAtModal, setVCell16VoltageAtModal] = useState('');
    const [vCell17VoltageAtModal, setVCell17VoltageAtModal] = useState('');
    const [vCell18VoltageAtModal, setVCell18VoltageAtModal] = useState('');
    const [vCell19VoltageAtModal, setVCell19VoltageAtModal] = useState('');
    const [vCell20VoltageAtModal, setVCell20VoltageAtModal] = useState('');

    const pageTitle = language === 'EN'? 'Historical Data':'হিষ্টোরিকাল তথ্য'

    const generalRequiredMessage = language === 'EN'? 'Please provide at least one of the above information!':'দয়া করে অন্তত একটি তথ্য দিন!'
    const dataNotAvailableMessage = language === 'EN'? 'N/A':'তথ্য নেই'

    const historicalDataColumns = [
        {
            field: 'dongle_sl',
            title: language === 'EN'? 'Dongle':'ডঙ্গল',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '0'
            },
            // disableClick: true,
        },
        {
            field: 'battery_sl',
            title: language === 'EN'? 'Battery':'ব্যাটারী',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textLeft: 'left'
            },
            // disableClick: true,
            render: rowData => <Link style={{color: "#F18D00"}}
                                     target={'_blank'}
                                     to={`/batteries/battery_graph/${rowData.battery_sl}`}>
                {rowData.battery_sl}
            </Link>
        },
        {
            field: 'ev_sl',
            title: language === 'EN'? 'EV':'ইভি',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textLeft: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            }
            // disableClick: true,
        },
        {
            field: 'generated_at',
            title: language === 'EN'? 'Generated at':'তথ্য উৎপন্নের সময়',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textLeft: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
            render: rowData => rowData.generated_at? moment(rowData.generated_at).format("DD MMM, YYYY hh:mm:ss a"):dataNotAvailableMessage
        },
        {
            field: 'ID_0002',
            title: language === 'EN'? 'Battery voltage (V)':'ব্যাটারি ভোল্টেজ (V)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: rowData => rowData.ID_0002 && rowData.ID_0002 !== 'N/A'? (rowData.ID_0002/1000).toFixed(1):dataNotAvailableMessage
        },
        {
            field: 'ID_0003',
            title: language === 'EN'? 'Battery current (A)':'ব্যাটারী কারেন্ট (A)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: rowData => rowData.ID_0003 && rowData.ID_0003 !== 'N/A'? (rowData.ID_0003/1000).toFixed(1):dataNotAvailableMessage
        },
        {
            field: 'ID_0004',
            title: language === 'EN'? 'SOC':'এসওসি',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
        },
        {
            field: 'ID_0007',
            title: language === 'EN'? 'Lowest cell voltage(V)':'সর্বনিম্ন সেল ভোল্টেজ(V)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: rowData => rowData.ID_0007 && rowData.ID_0007 !== 'N/A'? (rowData.ID_0007/1000).toFixed(1):dataNotAvailableMessage
        },
        {
            field: 'ID_0008',
            title: language === 'EN'? 'Highest cell voltage(V)':'সর্বোচ্চ সেল ভোল্টেজ(V)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: rowData => rowData.ID_0008 && rowData.ID_0008 !== 'N/A'? (rowData.ID_0008/1000).toFixed(1):dataNotAvailableMessage
        },
        {
            field: 'ID_0009',
            title: language === 'EN'? 'Max_temp of cells (°C)':'সেলের সর্বোচ্চ তাপমাত্রা (°C)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
        },
        {
            field: 'ID_0010',
            title: language === 'EN'? 'Max temp of BMS (°C)':'বিএমএস এর সর্বোচ্চ তাপমাত্রা (°C)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
        },
        {
            field: 'ID_0011',
            title: language === 'EN'? 'Error State of the BMS':'বিএমএস এর ত্রুটির অবস্থা',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
        },
        {
            field: 'ID_0051',
            title: language === 'EN'? 'Ah_in_sum over lifetime(Ah)':'জীবনকালে Ah ইনপুটের যোগফল(Ah)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: rowData => rowData.ID_0051 && rowData.ID_0051 !== 'N/A'? (rowData.ID_0051/1000).toFixed(1):dataNotAvailableMessage
        },
        {
            field: 'ID_0052',
            title: language === 'EN'? 'Ah_out_sum over lifetime(Ah)':'জীবনকালে Ah আউটপুটের যোগফল(Ah)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: rowData => rowData.ID_0052 && rowData.ID_0052 !== 'N/A'? (rowData.ID_0052/1000).toFixed(1):dataNotAvailableMessage
        },
        {
            field: 'ID_0054',
            title: language === 'EN'? 'Release version':'রিলিজ ভার্সন',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                if (rowData.ID_0054 && rowData.ID_0054 !== 'N/A') {
                    let version = rowData.ID_0054.toString().split('').join('.')
                    return (
                        <>{version}</>
                    )
                } else {
                    return (
                        <>{dataNotAvailableMessage}</>
                    )
                }
            }
        }
    ]

    // Details modal related label texts
    const timeStampLabel = language === 'EN'? 'Timestamp':'সময়ের স্ট্যাম্প'
    const numberOfSupportedCellLabel = language === 'EN'? 'Number of cell bms supported':'বিএমএস এর সাপোর্ট করা সেলের সংখ্যা'
    const balancingOverFeedbackLabel = language === 'EN'? 'Balancing Control Feedback':'ব্যালান্সিং কন্ট্রোল ফিডব্যাক'
    const centerVoltageLabel = language === 'EN'? 'Center voltage':'কেন্দ্রীয় ভোল্টেজ'
    const vCell1MinSamplingLabel = language === 'EN'? 'V_cell_1 min over sampling period':'ভোল্টেজ সেল ১ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell2MinSamplingLabel = language === 'EN'? 'V_cell_2 min over sampling period':'ভোল্টেজ সেল ২ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell3MinSamplingLabel = language === 'EN'? 'V_cell_3 min over sampling period':'ভোল্টেজ সেল ৩ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell4MinSamplingLabel = language === 'EN'? 'V_cell_4 min over sampling period':'ভোল্টেজ সেল ৪ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell5MinSamplingLabel = language === 'EN'? 'V_cell_5 min over sampling period':'ভোল্টেজ সেল ৫ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell6MinSamplingLabel = language === 'EN'? 'V_cell_6 min over sampling period':'ভোল্টেজ সেল ৬ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell7MinSamplingLabel = language === 'EN'? 'V_cell_7 min over sampling period':'ভোল্টেজ সেল ৭ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell8MinSamplingLabel = language === 'EN'? 'V_cell_8 min over sampling period':'ভোল্টেজ সেল ৮ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell9MinSamplingLabel = language === 'EN'? 'V_cell_9 min over sampling period':'ভোল্টেজ সেল ৯ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell10MinSamplingLabel = language === 'EN'? 'V_cell_10 min over sampling period':'ভোল্টেজ সেল ১০ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell11MinSamplingLabel = language === 'EN'? 'V_cell_11 min over sampling period':'ভোল্টেজ সেল ১১ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell12MinSamplingLabel = language === 'EN'? 'V_cell_12 min over sampling period':'ভোল্টেজ সেল ১২ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell13MinSamplingLabel = language === 'EN'? 'V_cell_13 min over sampling period':'ভোল্টেজ সেল ১৩ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell14MinSamplingLabel = language === 'EN'? 'V_cell_14 min over sampling period':'ভোল্টেজ সেল ১৪ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell15MinSamplingLabel = language === 'EN'? 'V_cell_15 min over sampling period':'ভোল্টেজ সেল ১৫ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell16MinSamplingLabel = language === 'EN'? 'V_cell_16 min over sampling period':'ভোল্টেজ সেল ১৬ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell17MinSamplingLabel = language === 'EN'? 'V_cell_17 min over sampling period':'ভোল্টেজ সেল ১৭ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell18MinSamplingLabel = language === 'EN'? 'V_cell_18 min over sampling period':'ভোল্টেজ সেল ১৮ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell19MinSamplingLabel = language === 'EN'? 'V_cell_19 min over sampling period':'ভোল্টেজ সেল ১৯ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'
    const vCell20MinSamplingLabel = language === 'EN'? 'V_cell_20 min over sampling period':'ভোল্টেজ সেল ২০ নুন্যতম ওভারস্যামপ্লিং পিরিয়ড'

    const {
        GET_HISTORICAL_DATA,
        COLLECT_CODE_DESCRIPTION
    } = props;
    const classes = useStylesIconButton();

    const clearAllErrorMessages = () => {
        setRequiredErrorMessage(false)
    }
    const resetFilter = () => {
        resetFilterValues()
        clearAllErrorMessages()
        if (filterApplied) {
            // Filter will always be applied
            // Resetting the battery log
            setPage(1)
            collectLastTwoDaysData()
            setFilterApplied(false)
        }
    }

    const resetFilterValues = () => {
        setBatterySerial('')
        setDongleSerial('')
    }

    const filterTheList = () => {
        if (!batterySerial && !dongleSerial && !(start && end)) {
            setRequiredErrorMessage(true)
        } else {
            setFilterApplied(true)
            // let filter = {page: 1}
            let filter = {log_type: "historical_log"}
            if (start && end) {
                let from_date = language === 'EN'? moment(start).format("YYYY-MM-DD"):convertToAnotherLang(moment(start).format("YYYY-MM-DD"))
                let to_date = language === 'EN'? moment(end).format("YYYY-MM-DD"):convertToAnotherLang(moment(end).format("YYYY-MM-DD"))
                filter['from_date'] = from_date
                filter['to_date'] = to_date
            }
            if (batterySerial) {
                filter['battery_serial_number'] = batterySerial
            }
            if (dongleSerial) {
                filter['dongle_serial_number'] = dongleSerial
            }
            GET_HISTORICAL_DATA(filter)
        }
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const collectLastTwoDaysData = () => {
        let todaysDate = moment().format("YYYY-MM-DD")
        let twoDaysEarlier = moment(todaysDate).subtract(1, 'd').format("YYYY-MM-DD")
        setStartDate(moment(twoDaysEarlier))
        setEndDate(moment(todaysDate))
        let from_date = props.language === 'EN'? twoDaysEarlier:convertToAnotherLang(twoDaysEarlier)
        let to_date = props.language === 'EN'? todaysDate:convertToAnotherLang(todaysDate)
        // GET_HISTORICAL_DATA({from_date: from_date + " 00:00:00", to_date: to_date + " 23:59:59", page: 1})
        GET_HISTORICAL_DATA({log_type: "historical_log", from_date: from_date, to_date: to_date})
        setFilterApplied(true)
        // TODO: Need to show the following text
        // setFilterText(language === 'EN'? 'between dates ' + moment(todaysDate).format("DD MMM,YYYY") + ' and ' +
        //     moment(todaysDate).format("DD MMM,YYYY"):moment(todaysDate).format("DD MMM,YYYY") + ' এবং ' +
        //     moment(todaysDate).format("DD MMM,YYYY") + ' তারিখের মাঝে')
    }

    useEffect(() => {
        setLanguage(props.language)
        setAuthorization(props.authorization)
        collectLastTwoDaysData()
        subHeader.setActionButtons(null)
        subHeader.setBreadCrumbComponent(null)
        // COLLECT_CODE_DESCRIPTION()

        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
        }
    }, []);

    // useEffect(() => {
    //     if (props.codeDescriptions) {
    //         console.log(props.codeDescriptions)
    //     }
    // }, [props.codeDescriptions]);

    useEffect(() => {
        if (props.historicalData) {
            const historicalData = props.historicalData;
            if (historicalData && historicalData.length > -1) {
                setHistoricalData(historicalData.map((data) => {
                    return {
                        ...data
                    }
                }))
            }
        }
    }, [props.historicalData]);

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        // if (filterCriteria && searchText) {
        //     let filter = {page: newPage}
        //     filter[filterCriteria.value] = searchText
        //     GET_HISTORICAL_DATA(filter)
        // } else {
        //     GET_HISTORICAL_DATA({page: newPage});
        // }
    }

    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const [previousStart, setPreviousStartDate] = useState(null);
    const [previousEnd, setPreviousEndDate] = useState(null);
    const onDatesChange = ({startDate, endDate}) => {
        setRequiredErrorMessage(false)
        if (previousEnd && previousEnd.isSame(endDate)) {
            setPreviousStartDate(endDate)
            setPreviousEndDate(endDate)

            setStartDate(endDate)
            setEndDate(endDate)
        } else {
            setPreviousStartDate(startDate)
            setPreviousEndDate(endDate)

            setStartDate(startDate)
            setEndDate(endDate)
        }
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start}
                    endDate={end}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    )

    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    },[start, end])

    const viewMore = (rowData) => {
        setDataGenerationDateAtModal(moment(rowData.generated_at).format("DD MMM, YYYY hh:mm:ss a"))
        setDongleAtModal(rowData.dongle_sl)
        setBatteryAtModal(rowData.battery_sl)
        setEvAtModal(rowData.ev_sl)
        setTimeStampAtModal(rowData.ID_0001 && rowData.ID_0001 !== 'N/A'? rowData.ID_0001 + ' s':dataNotAvailableMessage)
        setNumberOfCellBmsSupportedAtModal(rowData.ID_0005)
        setBalancingControlFeedbackAtModal(rowData.ID_0015)
        setCenterVoltageAtModal(rowData.ID_0006 && rowData.ID_0006 !== 'N/A'? (rowData.ID_0006/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell1VoltageAtModal(rowData.ID_0031 && rowData.ID_0031 !== 'N/A'? (rowData.ID_0031/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell2VoltageAtModal(rowData.ID_0032 && rowData.ID_0032 !== 'N/A'? (rowData.ID_0032/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell3VoltageAtModal(rowData.ID_0033 && rowData.ID_0033 !== 'N/A'? (rowData.ID_0033/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell4VoltageAtModal(rowData.ID_0034 && rowData.ID_0034 !== 'N/A'? (rowData.ID_0034/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell5VoltageAtModal(rowData.ID_0035 && rowData.ID_0035 !== 'N/A'? (rowData.ID_0035/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell6VoltageAtModal(rowData.ID_0036 && rowData.ID_0036 !== 'N/A'? (rowData.ID_0036/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell7VoltageAtModal(rowData.ID_0037 && rowData.ID_0037 !== 'N/A'? (rowData.ID_0037/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell8VoltageAtModal(rowData.ID_0038 && rowData.ID_0038 !== 'N/A'? (rowData.ID_0038/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell9VoltageAtModal(rowData.ID_0039 && rowData.ID_0039 !== 'N/A'? (rowData.ID_0039/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell10VoltageAtModal(rowData.ID_0040 && rowData.ID_0040 !== 'N/A'? (rowData.ID_0040/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell11VoltageAtModal(rowData.ID_0041 && rowData.ID_0041 !== 'N/A'? (rowData.ID_0041/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell12VoltageAtModal(rowData.ID_0042 && rowData.ID_0042 !== 'N/A'? (rowData.ID_0042/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell13VoltageAtModal(rowData.ID_0043 && rowData.ID_0043 !== 'N/A'? (rowData.ID_0043/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell14VoltageAtModal(rowData.ID_0044 && rowData.ID_0044 !== 'N/A'? (rowData.ID_0044/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell15VoltageAtModal(rowData.ID_0045 && rowData.ID_0045 !== 'N/A'? (rowData.ID_0045/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell16VoltageAtModal(rowData.ID_0046 && rowData.ID_0046 !== 'N/A'? (rowData.ID_0046/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell17VoltageAtModal(rowData.ID_0047 && rowData.ID_0047 !== 'N/A'? (rowData.ID_0047/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell18VoltageAtModal(rowData.ID_0048 && rowData.ID_0048 !== 'N/A'? (rowData.ID_0048/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell19VoltageAtModal(rowData.ID_0049 && rowData.ID_0049 !== 'N/A'? (rowData.ID_0049/1000).toFixed(1) + ' V':dataNotAvailableMessage)
        setVCell20VoltageAtModal(rowData.ID_0050 && rowData.ID_0050 !== 'N/A'? (rowData.ID_0050/1000).toFixed(1) + ' V':dataNotAvailableMessage)

        setShowDetailsModal(true)
    }

    const closeDetailsModal = () => {
        setShowDetailsModal(false)

        setDataGenerationDateAtModal('')
        setDongleAtModal('')
        setBatteryAtModal('')
        setEvAtModal('')
        setTimeStampAtModal('')
        setNumberOfCellBmsSupportedAtModal('')
        setBalancingControlFeedbackAtModal('')
        setCenterVoltageAtModal('')
        setVCell1VoltageAtModal('')
        setVCell2VoltageAtModal('')
        setVCell3VoltageAtModal('')
        setVCell4VoltageAtModal('')
        setVCell5VoltageAtModal('')
        setVCell6VoltageAtModal('')
        setVCell7VoltageAtModal('')
        setVCell8VoltageAtModal('')
        setVCell9VoltageAtModal('')
        setVCell10VoltageAtModal('')
        setVCell11VoltageAtModal('')
        setVCell12VoltageAtModal('')
        setVCell13VoltageAtModal('')
        setVCell14VoltageAtModal('')
        setVCell15VoltageAtModal('')
        setVCell16VoltageAtModal('')
        setVCell17VoltageAtModal('')
        setVCell18VoltageAtModal('')
        setVCell19VoltageAtModal('')
        setVCell20VoltageAtModal('')
    }

    const renderDetailsModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                size={'lg'}
                footer={true}
                footerButtonSize={'md'}
                footerCloseButtonText={props.language === 'EN'? 'Close':'বন্ধ করুন'}
                noSubmitButton={true}
                hideModal={closeDetailsModal}
                centered={false}
                modal={showDetailsModal}
                title={<>{props.language === 'EN'? 'Historical data generated at ':''}: <SolTypography.Text
                    primary>{dataGenerationDateAtModal}</SolTypography.Text>{props.language === 'BN'? ' তারিখের হিষ্টোরিকাল তথ্য':''}</>}>
                <div className={'row'}>
                    <div className={'col-lg-6'}>
                        <ListGroup>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{language === 'EN'? 'Dongle': 'ডংগল'}:</b></span><span className={'float-right'}>{dongleAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{language === 'EN'? 'Battery': 'ব্যাটারী'}:</b></span><span className={'float-right'}>{batteryAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{language === 'EN'? 'EV': 'ইভি'}:</b></span><span className={'float-right'}>{evAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{timeStampLabel}:</b></span><span className={'float-right'}>{timeStampAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{numberOfSupportedCellLabel}:</b></span><span className={'float-right'}>{numberOfCellBmsSupportedAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{balancingOverFeedbackLabel}:</b></span><span className={'float-right'}>{balancingControlFeedbackAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{centerVoltageLabel}:</b></span><span className={'float-right'}>{centerVoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell1MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell1VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell2MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell2VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell3MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell3VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell4MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell4VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell5MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell5VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell6MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell6VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell7MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell7VoltageAtModal}</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className={'col-lg-6'}>
                        <ListGroup>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell8MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell8VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell9MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell9VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell10MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell10VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell11MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell11VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell12MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell12VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell13MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell13VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell14MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell14VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell15MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell15VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell16MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell16VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell17MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell17VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell18MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell18VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell19MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell19VoltageAtModal}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>{vCell20MinSamplingLabel}:</b></span><span className={'float-right'}>{vCell20VoltageAtModal}</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
            </GenericModal>
        </>
    }

    const exportData = () => {
        let excelRows = [
            [pageTitle, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
            [historicalDataColumns[0].title, historicalDataColumns[1].title, historicalDataColumns[2].title,
                historicalDataColumns[3].title, historicalDataColumns[4].title, historicalDataColumns[5].title,
                historicalDataColumns[6].title, historicalDataColumns[7].title, historicalDataColumns[8].title,
                historicalDataColumns[9].title, historicalDataColumns[10].title, historicalDataColumns[11].title,
                historicalDataColumns[12].title, historicalDataColumns[13].title, historicalDataColumns[14].title,
                timeStampLabel, numberOfSupportedCellLabel, balancingOverFeedbackLabel, centerVoltageLabel,
                vCell1MinSamplingLabel, vCell2MinSamplingLabel, vCell3MinSamplingLabel, vCell4MinSamplingLabel,
                vCell5MinSamplingLabel, vCell6MinSamplingLabel, vCell7MinSamplingLabel, vCell8MinSamplingLabel,
                vCell9MinSamplingLabel, vCell10MinSamplingLabel, vCell11MinSamplingLabel, vCell12MinSamplingLabel,
                vCell13MinSamplingLabel, vCell14MinSamplingLabel, vCell15MinSamplingLabel, vCell16MinSamplingLabel,
                vCell17MinSamplingLabel, vCell18VoltageAtModal, vCell19VoltageAtModal, vCell20MinSamplingLabel]
        ]
        if (filterApplied) {
            if (start && end) {
                let filterText = language === 'EN'? "From " + moment(start).format("Do MMMM,YYYY") + " 00:00:00 am to " +
                    moment(end).format("Do MMMM,YYYY") + ' 23:59:59 pm':moment(start).format("Do MMMM,YYYY") +
                    " ০০ঃ০০ঃ০০ থেকে " + moment(end).format("Do MMMM,YYYY") + " ২৩ঃ৫৯ঃ৫৯ পর্যন্ত"
                excelRows.splice(1, 0, [filterText, "", "", "", "", "", "", "", "", "", "", "",
                    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
            }
            if (batterySerial) {
                let filterText = language === 'EN'? "Battery: " + batterySerial:"ব্যাটারী: " + batterySerial
                excelRows.splice(1, 0, [filterText, "", "", "", "", "", "", "", "", "", "", "",
                    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
            }
            if (dongleSerial) {
                let filterText = language === 'EN'? "Dongle: " + dongleSerial:"ডঙ্গল: " + dongleSerial
                excelRows.splice(1, 0, [filterText, "", "", "", "", "", "", "", "", "", "", "",
                    "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
            }
        }
        for (let i=0; i<historicalData.length; i++) {
            let generatedAt = historicalData[i].generated_at? moment(historicalData[i].generated_at).format("DD MMM, YYYY hh:mm:ss a"):dataNotAvailableMessage
            let batteryVoltage = historicalData[i].ID_0002 && historicalData[i].ID_0002 !== 'N/A'? (historicalData[i].ID_0002/1000).toFixed(1):dataNotAvailableMessage
            let batteryCurrent = historicalData[i].ID_0003 && historicalData[i].ID_0003 !== 'N/A'? (historicalData[i].ID_0003/1000).toFixed(1):dataNotAvailableMessage
            let lowestCellVoltage = historicalData[i].ID_0007 && historicalData[i].ID_0007 !== 'N/A'? (historicalData[i].ID_0007/1000).toFixed(1):dataNotAvailableMessage
            let highestCellVoltage = historicalData[i].ID_0008 && historicalData[i].ID_0008 !== 'N/A'? (historicalData[i].ID_0008/1000).toFixed(1):dataNotAvailableMessage
            let ahInSum = historicalData[i].ID_0051 && historicalData[i].ID_0051 !== 'N/A'? (historicalData[i].ID_0051/1000).toFixed(1):dataNotAvailableMessage
            let ahOutSum = historicalData[i].ID_0052 && historicalData[i].ID_0052 !== 'N/A'? (historicalData[i].ID_0052/1000).toFixed(1):dataNotAvailableMessage
            // View more modal data
            let centerVoltage = historicalData[i].ID_0006 && historicalData[i].ID_0006 !== 'N/A'? (historicalData[i].ID_0006/1000).toFixed(1):dataNotAvailableMessage
            let vCell1Voltage = historicalData[i].ID_0031 && historicalData[i].ID_0031 !== 'N/A'? (historicalData[i].ID_0031/1000).toFixed(1):dataNotAvailableMessage
            let vCell2Voltage = historicalData[i].ID_0032 && historicalData[i].ID_0032 !== 'N/A'? (historicalData[i].ID_0032/1000).toFixed(1):dataNotAvailableMessage
            let vCell3Voltage = historicalData[i].ID_0033 && historicalData[i].ID_0033 !== 'N/A'? (historicalData[i].ID_0033/1000).toFixed(1):dataNotAvailableMessage
            let vCell4Voltage = historicalData[i].ID_0034 && historicalData[i].ID_0034 !== 'N/A'? (historicalData[i].ID_0034/1000).toFixed(1):dataNotAvailableMessage
            let vCell5Voltage = historicalData[i].ID_0035 && historicalData[i].ID_0035 !== 'N/A'? (historicalData[i].ID_0035/1000).toFixed(1):dataNotAvailableMessage
            let vCell6Voltage = historicalData[i].ID_0036 && historicalData[i].ID_0036 !== 'N/A'? (historicalData[i].ID_0036/1000).toFixed(1):dataNotAvailableMessage
            let vCell7Voltage = historicalData[i].ID_0037 && historicalData[i].ID_0037 !== 'N/A'? (historicalData[i].ID_0037/1000).toFixed(1):dataNotAvailableMessage
            let vCell8Voltage = historicalData[i].ID_0038 && historicalData[i].ID_0038 !== 'N/A'? (historicalData[i].ID_0038/1000).toFixed(1):dataNotAvailableMessage
            let vCell9Voltage = historicalData[i].ID_0039 && historicalData[i].ID_0039 !== 'N/A'? (historicalData[i].ID_0039/1000).toFixed(1):dataNotAvailableMessage
            let vCell10Voltage = historicalData[i].ID_0040 && historicalData[i].ID_0040 !== 'N/A'? (historicalData[i].ID_0040/1000).toFixed(1):dataNotAvailableMessage
            let vCell11Voltage = historicalData[i].ID_0041 && historicalData[i].ID_0041 !== 'N/A'? (historicalData[i].ID_0041/1000).toFixed(1):dataNotAvailableMessage
            let vCell12Voltage = historicalData[i].ID_0042 && historicalData[i].ID_0042 !== 'N/A'? (historicalData[i].ID_0042/1000).toFixed(1):dataNotAvailableMessage
            let vCell13Voltage = historicalData[i].ID_0043 && historicalData[i].ID_0043 !== 'N/A'? (historicalData[i].ID_0043/1000).toFixed(1):dataNotAvailableMessage
            let vCell14Voltage = historicalData[i].ID_0044 && historicalData[i].ID_0044 !== 'N/A'? (historicalData[i].ID_0044/1000).toFixed(1):dataNotAvailableMessage
            let vCell15Voltage = historicalData[i].ID_0045 && historicalData[i].ID_0045 !== 'N/A'? (historicalData[i].ID_0045/1000).toFixed(1):dataNotAvailableMessage
            let vCell16Voltage = historicalData[i].ID_0046 && historicalData[i].ID_0046 !== 'N/A'? (historicalData[i].ID_0046/1000).toFixed(1):dataNotAvailableMessage
            let vCell17Voltage = historicalData[i].ID_0047 && historicalData[i].ID_0047 !== 'N/A'? (historicalData[i].ID_0047/1000).toFixed(1):dataNotAvailableMessage
            let vCell18Voltage = historicalData[i].ID_0048 && historicalData[i].ID_0048 !== 'N/A'? (historicalData[i].ID_0048/1000).toFixed(1):dataNotAvailableMessage
            let vCell19Voltage = historicalData[i].ID_0049 && historicalData[i].ID_0049 !== 'N/A'? (historicalData[i].ID_0049/1000).toFixed(1):dataNotAvailableMessage
            let vCell20Voltage = historicalData[i].ID_0050 && historicalData[i].ID_0050 !== 'N/A'? (historicalData[i].ID_0050/1000).toFixed(1):dataNotAvailableMessage

            // Pushing into the excel
            let version = historicalData[i].ID_0054.toString().split('').join('.')
            excelRows.push([historicalData[i].dongle_sl, historicalData[i].battery_sl, historicalData[i].ev_sl,
                generatedAt, batteryVoltage, batteryCurrent, historicalData[i].ID_0004, lowestCellVoltage, highestCellVoltage,
                historicalData[i].ID_0009, historicalData[i].ID_0010, historicalData[i].ID_0011, ahInSum, ahOutSum,
                version, historicalData[i].ID_0001, historicalData[i].ID_0005,
                historicalData[i].ID_0015, centerVoltage, vCell1Voltage, vCell2Voltage, vCell3Voltage, vCell4Voltage,
                vCell5Voltage, vCell6Voltage, vCell7Voltage, vCell8Voltage, vCell9Voltage, vCell10Voltage, vCell11Voltage,
                vCell12Voltage, vCell13Voltage, vCell14Voltage, vCell15Voltage, vCell16Voltage, vCell17Voltage,
                vCell18Voltage, vCell19Voltage, vCell20Voltage])
        }
        if (historicalData.length === 0) {
            let noDataAvailableText =  language === 'EN'? "No history available":"কোন তথক্স নেই"
            excelRows.push(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",
                noDataAvailableText, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
        }
        let fileName = language === 'EN'? 'historical_data_':'হিষ্টোরিকাল_তথ্য_'
        let presentTime = moment()
        exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
    }

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Historical data"}
                            statusCode={props.statusCode}>
                <div>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>{pageTitle}</b></h1>
                        </Toolbar.Title>
                        <Toolbar.ToolbarContainer>
                            <Toolbar.ToolbarContainer.Items>
                                <IconButton onClick={filterToggle} classes={classes}>
                                    <FilterListIcon color="disabled" fontSize="large"/>
                                </IconButton>
                                <IconButton classes={classes}
                                            onClick={exportData}
                                            disabled={props.listLoading}>
                                     <img src={require('../../../../utils/asset/download-Button.svg')} alt="Download"/>
                                </IconButton>
                            </Toolbar.ToolbarContainer.Items>
                        </Toolbar.ToolbarContainer>
                    </Toolbar>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Battery':'ব্যাটারী'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'batterySerial'}
                                                    type="text"
                                                    placeholder={language === 'EN'? 'Ex: 55000001':'উদাহরণঃ 55000001'}
                                                    value={batterySerial}
                                                    autoComplete={"off"}
                                                    onChange = {(e) => {
                                                        clearAllErrorMessages()
                                                        if (e.target.value) {
                                                            let value = e.target.value;
                                                            setBatterySerial(value)
                                                            if (isNaN(value) || value.includes('.')) {
                                                                setInvalidBatterySerial(true)
                                                            } else {
                                                                setInvalidBatterySerial(false)
                                                            }
                                                        } else {
                                                            setBatterySerial("")
                                                        }
                                                    }}
                                                />
                                                {invalidBatterySerial? <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>
                                                    &nbsp;&nbsp;{language === 'EN'? 'Invalid battery serial!':'ভুল ' +
                                                    'ব্যাটারী সিরিয়াল!'}</div>:null}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Dongle':'ডঙ্গল'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'dongleSerial'}
                                                    type="text"
                                                    placeholder={language === 'EN'? 'Ex: 44000001':'উদাহরণঃ 44000001'}
                                                    value={dongleSerial}
                                                    autoComplete={"off"}
                                                    onChange = {(e) => {
                                                        clearAllErrorMessages()
                                                        if (e.target.value) {
                                                            let value = e.target.value;
                                                            setDongleSerial(value)
                                                            if (isNaN(value) || value.includes('.')) {
                                                                setInvalidDongleSerial(true)
                                                            } else {
                                                                setInvalidDongleSerial(false)
                                                            }
                                                        } else {
                                                            setDongleSerial("")
                                                        }
                                                    }}
                                                />
                                                {invalidDongleSerial? <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>
                                                    &nbsp;&nbsp;{language === 'EN'? 'Invalid Dongle serial!':'ভুল ' +
                                                    'ডঙ্গল সিরিয়াল!'}</div>:null}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Time':'সময়'}<span className="required text-danger">*</span></Form.Label>
                                                </div>
                                                <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                                overlay={popover}>
                                                    <SolInput
                                                        name={'dateRangePickerInput'}
                                                        readOnly={true}
                                                        value={dateRange}
                                                        placeholder={language === 'EN'? 'Select a time':'একটি সময় বাছাই করুন'}
                                                    />
                                                </OverlayTrigger>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}}>
                                                {language === 'EN'? 'Reset filter':'প্রাথমিক অবস্থায় ফিরে যান'}
                                            </Button>
                                            <Button variant="warning" size={'sm'} type="button"
                                                    disabled={props.listLoading}
                                                    onClick={()=> {filterTheList()}} className={'ml-3'}>
                                                {language === 'EN'? 'Apply filter':'তালিকায় খুঁজুন'}
                                            </Button>
                                            {requiredErrorMessage? <div className="text-danger">
                                                <WarningIcon/>&nbsp;&nbsp;{generalRequiredMessage}</div>:null}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.listLoading? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন তথ্য নেই'}
                                columns={historicalDataColumns}
                                data={historicalData}
                                asyncPagination={false}
                                isLoading={props.tableLoading}
                                pagination={true}
                                pageSize={10}
                                actionColumnIndex={-1}
                                actions={[{}]}
                                actionButtonVariant={['warning']}
                                actionButtonSize={'sm'}
                                actionButtonClickEvent={[viewMore]}
                                actionButtonText={['View more']}
                            /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Generating historical data..': 'হিষ্টরিকাল তথ্য প্রস্তুত হচ্ছে..'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>
                {renderDetailsModal()}

            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    );
};

HistoricalLogs.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        listLoading: state.historicalDataReducer.listLoading,
        historicalData: state.historicalDataReducer.historicalData,
        codeDescriptionCollectionInProgress: state.historicalDataReducer.codeDescriptionCollectionInProgress,
        codeDescriptions: state.historicalDataReducer.codeDescriptions
    }
}

export default connect(mapStateToProps, actions)(withRoles(HistoricalLogs));
