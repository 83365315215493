import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */

    COLLECT_SUMMARY_INFO: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/rents', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_SUMMARY_INFO_REQUEST,
                actionTypes.COLLECT_SUMMARY_INFO_SUCCESS,
                actionTypes.COLLECT_SUMMARY_INFO_FAILURE
            ]
        }
    }),

    COLLECT_SUMMARY_INFO_TO_EXPORT: (filter)=>({
        [CALL_API]: {
            endpoint: typeof filter === 'object'? paramsHandler('/rents', filter): '/rents',
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_SUMMARY_INFO_TO_EXPORT_REQUEST,
                actionTypes.COLLECT_SUMMARY_INFO_TO_EXPORT_SUCCESS,
                actionTypes.COLLECT_SUMMARY_INFO_TO_EXPORT_FAILURE
            ]
        }
    }),

    COLLECT_GARAGE_LIST: ()=>({
        [CALL_API]: {
            endpoint: '/garages',
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_GARAGE_LIST_REQUEST,
                actionTypes.COLLECT_GARAGE_LIST_SUCCESS,
                actionTypes.COLLECT_GARAGE_LIST_FAILURE,
            ]
        }
    }),

    COLLECT_DRIVER_DETAILS: (phoneNumber)=>({
        [CALL_API]: {
            endpoint: '/drivers/' + phoneNumber,
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_DRIVER_DETAILS_REQUEST,
                actionTypes.COLLECT_DRIVER_DETAILS_SUCCESS,
                actionTypes.COLLECT_DRIVER_DETAILS_FAILURE,
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
