import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import {Button, Form, Spinner} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {DataTable, LoadingSpinner, SolInput} from "../../../../components";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import moment from "moment";
import {useMediaQuery} from "react-responsive";
import Alert from '@mui/material/Alert';
import {ExportToCsv} from "export-to-csv";
import {csvToArray} from "../../../../utils/utilityFunctions";
import {showNotifications} from "../../../../utils/notification";
import {checkAuthorization} from "../../../auth/authorization";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import AlertTitle from '@mui/material/AlertTitle';
import Select from "react-select";
import {commonReducer} from "../../../commonReduxActions/commonReducer";
import {depositsReducer} from "../../reducer";
import InfoIcon from "@mui/icons-material/Info";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import Stack from "@mui/material/Stack";

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const NewCashDeposit = ({ cancelProcess, updateTitle, handleNewCashDepositProcess, ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError, getValues} = useForm();
    const [language, setLanguage] = useState("EN");
    const [showRechargeEntitySelectionField, setShowRechargeEntitySelectionField] = useState(false);
    const [rechargeEntitySelectionFieldLabel, setRechargeEntitySelectionFieldLabel] = useState('');
    const [borrowers, setBorrowers] = useState([]);
    const [smartBatteries, setSmartBatteries] = useState([]);
    const [disableFirstStepSaveButton, setDisableFirstStepSaveButton] = useState(false);
    const [decisionForAddingPreviousBalance, setDecisionForAddingPreviousBalance] = useState("no");
    const [invoicePaymentProcess, setInvoicePaymentProcess] = useState("manual");
    const [invoiceCsvName, setInvoiceCsvName] = useState("");
    const [dueInvoicesAreDownloaded, setDueInvoicesAreDownloaded] = useState(false);
    const [payloadForDueInvoicesCollection, setPayloadForDueInvoicesCollection] = useState({});
    const [invoices, setInvoices] = useState(undefined);
    const [showInvoiceTableWhenCsvIsUploaded, setShowInvoiceTableWhenCsvIsUploaded] = useState(false);
    const [showBulkInvoiceUploadField, setShowBulkInvoiceUploadField] = useState(true); // As default value of the invoice payment process is 'manual'
    const [userCanUploadInvoiceCsv, setUserCanUploadInvoiceCsv] = useState(false);

    const [entityBalance, setEntityBalance] = useState(undefined);
    const [rechargingAmount, setRechargingAmount] = useState(0);
    const [nextButtonIsClicked, setNextButtonIsClicked] = useState(false);

    const [secondStepTitle, setSecondStepTitle] = useState("");
    const [paidInvoices, setPaidInvoices] = useState([]);
    const [showInvoices, setShowInvoices] = useState(false);
    const [showError, setShowError] = useState(false);
    const [payableAmount, setPayableAmount] = useState(0);
    const [clearedAmount, setClearedAmount] = useState(0);
    const [residueAmount, setResidueAmount] = useState(0);
    const [paymentDate, setPaymentDate] = useState('');
    const [payload, setPayload] = useState('');
    const [makeInvoiceStatusNotUpdatable, setMakeInvoiceStatusNotUpdatable] = useState(false);
    const [oldCsvIsUsed, setOldCsvIsUsed] = useState(false);
    const [fileIsCorrupted, setFileIsCorrupted] = useState(false);
    const [errorInTheUploadedCsv, setErrorInTheUploadedCsv] = useState(false);
    const [errorRowCountInTheUploadedCsv, setErrorRowCountInTheUploadedCsv] = useState(0);
    const [errorNoInvoiceMatched, setErrorNoInvoiceMatched] = useState(false);

    const transactionServiceName = getServiceName('transactionService')
    const newCashDepositFeatureName = getFeatureName('newDepositFeature')
    const uploadActionName = getActionName('uploadAction')

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const classes = useStylesIconButton();

    const informationIsRequired = 'Above information is required!'
    const wrongFileTypeMessage = 'Wrong file type! Accepted file types are *.doc, *.docx, *.pdf, *.xls, *.xlsx, *.jpeg, *.jpg, *.webp and *.png'
    const wrongFileSizeMessage = 'File size shouldn"t exceed 10MB!'

    // Tooltip for "Document"
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));
    const [showTooltipForDocumentField, setShowTooltipForDocumentField] = useState(false);
    const toolTipTextForDocumentField = <>
        <strong>Accepted file types:</strong> *.doc, *.docx, *.pdf, *.xls, *.xlsx, *.jpeg, '*.jpg, *.webp, *.png<br/>
        <strong>Max accepted size:</strong> 10MB
    </>
    const toolTipForDocumentField = () => {
        return <>
            <HtmlTooltip
                title={toolTipTextForDocumentField} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showTooltipForDocumentField}
                onOpen={() => setShowTooltipForDocumentField(true)}
                onClose={() => setShowTooltipForDocumentField(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowTooltipForDocumentField(!showTooltipForDocumentField)
                    }
                }}/>
            </HtmlTooltip>
        </>
    }

    const rechargingCriteriaOptions = [
        {
            label: 'Borrower',
            value: 'borrower'
        },
        {
            label: 'Smart Battery',
            value: 'battery'
        }
    ]

    const {
        COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT,
        COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT,
        COLLECT_CURRENT_BALANCE,
        COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT,
        RETURN_TO_INITIAL_STATE_FOR_NEW_CASH_DEPOSIT
    } = props;

    useEffect(()=>{
        setLanguage(props.language)

        setDecisionForAddingPreviousBalance("yes")
        setUserCanUploadInvoiceCsv(checkAuthorization(props.authorization, transactionServiceName,
            newCashDepositFeatureName, [uploadActionName]))

        return ()=>{
            RETURN_TO_INITIAL_STATE_FOR_NEW_CASH_DEPOSIT()
        }
    }, [])

    /* --------------------- Begin: First page ------------------------------- */
    useEffect(()=> {
        if (props.borrowersForCashDeposit && props.borrowersForCashDeposit.length > -1) {
            const borrowers = props.borrowersForCashDeposit
            setBorrowers(borrowers.map((borrower) => {
                return {
                    ...borrower,
                    label: borrower.name,
                    value: borrower.pk
                }
            }))
        } else {
            setBorrowers([])
        }
    }, [props.borrowersForCashDeposit])
    useEffect(() => {
        if (props.errorMessageForBorrowersCollection) {
            showNotifications('error', props.errorMessageForBorrowersCollection)
        }
    }, [props.errorMessageForBorrowersCollection])

    useEffect(()=>{
        if (props.smartBatteriesForCashDeposit && props.smartBatteriesForCashDeposit.length > -1) {
            const smartBatteries = props.smartBatteriesForCashDeposit
            setSmartBatteries(smartBatteries.map((smartBattery) => {
                return {
                    ...smartBattery,
                    label: smartBattery.serial_number,
                    value: smartBattery.pk
                }
            }))
        } else {
            setSmartBatteries([])
        }
    }, [props.smartBatteriesForCashDeposit])
    useEffect(() => {
        if (props.errorMessageForSmartBatteriesCollection) {
            showNotifications('error', props.errorMessageForSmartBatteriesCollection)
        }
    }, [props.errorMessageForSmartBatteriesCollection])

    const handleDecisionSelectionForAddingPreviousBalance = (event) => {
        setDecisionForAddingPreviousBalance(event.target.value)
    }

    const handleProcessSelectionForInvoicePayment = (event) => {
        if (event.target.value === 'manual') {
            setShowBulkInvoiceUploadField(true)
        } else {
            setShowBulkInvoiceUploadField(false)
        }
        setInvoicePaymentProcess(event.target.value)
    }
    /* --------------------- End: First page ------------------------------- */

    /*  --------------------- Begin: Due Invoice csv download and upload related ----------------------------- */
    const exportedCsvColumns = ['Invoice_ID', 'SB_Number', 'Payable_amount', 'Status']
    const exportAllDueInvoices = (dataToExport) => {
        let currentDate = moment().format("DD_MMM_YYYY")
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: '',
            filename: 'due_invoices_of_' + invoiceCsvName + '_at_' + currentDate,
            useTextFile: false,
            useBom: true,
            headers: exportedCsvColumns
        }
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(dataToExport)
    }

    const initiateInvoiceDownloadProcess = () => {
        let rechargingFor = getValues("recharging_for")
        if (rechargingFor) {
            let rechargeEntity = getValues("entity_guid")
            if (rechargeEntity) {
                setDueInvoicesAreDownloaded(true)
                COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT({...payloadForDueInvoicesCollection})
            } else {
                setError("entity_guid", {"message": "Please select a " +  rechargingFor.value  + " for deposit!"})
            }
        } else {
            setError("recharging_for", {"message": "Please select an option for deposit!"})
        }
    }

    useEffect(() => {
        if (props.invoicesForCashDeposit && props.invoicesForCashDeposit.length > -1) {
            const invoicesForDeposit = props.invoicesForCashDeposit
            if (dueInvoicesAreDownloaded) {
                setDueInvoicesAreDownloaded(false)
                if (invoicesForDeposit.length === 0) {
                    exportAllDueInvoices({
                        Invoice_ID: "",
                        SB_Number: "No",
                        Payable_amount: "Invoices",
                        Status: "Available"
                    })
                } else {
                    let dataForCsv = invoicesForDeposit.map((invoice) => {
                        return {
                            Invoice_ID: invoice.invoice_id,
                            SB_Number: invoice.device_sl,
                            Payable_amount: invoice.payable_amount - invoice.paid_amount,
                            Status: invoice.invoice_status
                        }
                    })
                    exportAllDueInvoices(dataForCsv)
                }
            }
            let totalDuePayable = 0
            setInvoices(invoicesForDeposit.map((invoice) => {
                let payableAmount = invoice.payable_amount - invoice.paid_amount
                totalDuePayable += payableAmount
                return {
                    ...invoice,
                    serialNumber: invoice.device_sl,
                    payableAmount: payableAmount,
                    invoiceStatus: invoice.invoice_status,
                    paidAmount: 0
                }
            }))
            setPayableAmount(new Intl.NumberFormat('en-IN').format(totalDuePayable.toFixed(2)))
        } else {
            setInvoices(undefined)
        }
    }, [props.invoicesForCashDeposit])

    const parseUploadedCsv = (file) => {
        const reader = new FileReader();

        reader.onload = function (e) {
            let uploadedInvoices = csvToArray(e.target.result)
            let columns = Object.keys(uploadedInvoices[uploadedInvoices.length - 1])
            let corruptedFile = false
            if (columns.length !== exportedCsvColumns.length) {
                corruptedFile = true
                setFileIsCorrupted(true)
            } else {
                for (let i=0; i<exportedCsvColumns.length; i++) {
                    if (exportedCsvColumns[i] !== columns[i]) {
                        corruptedFile = true
                        setFileIsCorrupted(true)
                        break
                    }
                }
            }
            if (!corruptedFile) {
                let updatedClearedAmount = 0
                let updatedPaidInvoicesIds = []
                let errorRowCount = 0
                let updatedInvoices = []
                for (let i=0; i<invoices.length; i++) {
                    let ifThisInvoiceIsUploaded = uploadedInvoices.filter((item) => {
                        let invoiceId = item.Invoice_ID.replace(/['"]+/g, '')
                        if (invoiceId === invoices[i].invoice_id) {
                            return item
                        }
                    })
                    // Including the only correct Invoice IDs
                    if (ifThisInvoiceIsUploaded.length > 0) {
                        let updatedPaidAmount = invoices[i].paidAmount
                        let payableAmount = parseFloat(ifThisInvoiceIsUploaded[0].Payable_amount.replace(/['"]+/g, ''))
                        let smartBattery = ifThisInvoiceIsUploaded[0].SB_Number.replace(/['"]+/g, '')
                        let status = ifThisInvoiceIsUploaded[0].Status.replace(/['"]+/g, '')
                        let errorInStatus = false
                        let errorInSmartBattery = false
                        let errorInPayableAmount = false
                        // TODO: status.toLowerCase() !== 'paid' is added now after copying from the 'Manual Cash Payment'
                        // TODO: Otherwise, 'paid' status is showing error. Is it needed at the Manual Cash Payment process also ?
                        if (status !== invoices[i].invoiceStatus && status.toLowerCase() !== 'paid') {
                            setErrorInTheUploadedCsv(true)
                            errorInStatus = true
                        }
                        if (payableAmount !== invoices[i].payableAmount) {
                            setErrorInTheUploadedCsv(true)
                            errorInPayableAmount = true
                        }
                        if (smartBattery !== invoices[i].device_sl) {
                            setErrorInTheUploadedCsv(true)
                            errorInSmartBattery = true
                        }
                        if (errorInStatus || errorInPayableAmount || errorInSmartBattery) {
                            errorRowCount += 1
                        }
                        if (status.toLowerCase() === 'paid') {
                            updatedPaidAmount = payableAmount
                            updatedClearedAmount += payableAmount
                            updatedPaidInvoicesIds.push(ifThisInvoiceIsUploaded[0].Invoice_ID.replace(/['"]+/g, ''))
                            status = 'paid'
                        } else if (status.toLowerCase() === 'due' || status.toLowerCase() === 'partial_paid') {
                            status = 'due'
                        } else if (status.toLowerCase() === 'overdue') {
                            status = 'overdue'
                        } else {
                            setErrorInTheUploadedCsv(true)
                            if (!errorInStatus) {
                                errorRowCount += 1
                            }
                        }

                        updatedInvoices.push({
                            ...invoices[i],
                            serialNumber: smartBattery,
                            errorInSmartBattery: errorInSmartBattery,
                            payableAmount: payableAmount,
                            errorInPayableAmount: errorInPayableAmount,
                            invoiceStatus: status,
                            errorInStatus: errorInStatus,
                            paidAmount: updatedPaidAmount
                        })
                    }
                }
                if (updatedInvoices && updatedInvoices.length !== 0) {
                    setInvoices(updatedInvoices)
                    setShowInvoiceTableWhenCsvIsUploaded(true)
                } else {
                    setErrorNoInvoiceMatched(true)
                }

                if (updatedClearedAmount === 0) {
                    // When no invoice is cleared, residue amount is set to 0
                    setResidueAmount(0)
                } else if (updatedClearedAmount <= rechargingAmount) {
                    setResidueAmount(rechargingAmount - updatedClearedAmount)
                }
                setErrorRowCountInTheUploadedCsv(errorRowCount)
                setClearedAmount(updatedClearedAmount)
                setPaidInvoices(updatedPaidInvoicesIds)
            }
        };
        reader.readAsText(file)
    }

    useEffect(() => {
        if (invoices && invoices.length > -1 && oldCsvIsUsed) {
            // Dealing with old downloaded csv
            parseUploadedCsv(payload.payment_file)
            setOldCsvIsUsed(false)
        }
    }, [invoices])

    /*  --------------------- End: Due Invoice csv download and upload related ----------------------------- */

    /* ---------------------- Begin: Next button click events ------------------------------ */
    const firstPageFormSubmitActions = (depositData) => {
        setNextButtonIsClicked(true) // Marking that 'Next' button is clicked
        setErrorNoInvoiceMatched(false)
        setFileIsCorrupted(false)
        setErrorInTheUploadedCsv(false)
        let payload = {
            ...depositData
        }
        payload['receipt_file'] = depositData['receipt_file'][0]
        setRechargingAmount(parseInt(payload['amount']))
        setClearedAmount(0)
        setResidueAmount(0)
        setPaymentDate(moment().format("MMM DD, YYYY"))
        if (showBulkInvoiceUploadField && userCanUploadInvoiceCsv && depositData.uploaded_recharge_csv[0]) {
            if (decisionForAddingPreviousBalance === 'yes') {
                COLLECT_CURRENT_BALANCE({'entity_type': payload['recharging_for'].value, 'entity_guid': payload['entity_guid'].value})
            }
            // Manual Invoice Payment when invoice csv has been uploaded
            payload['payment_file'] = depositData['uploaded_recharge_csv'][0]
            if (!invoices || invoices.length === 0) {
                // Dealing with case when user tries to upload an old downloaded csv
                setOldCsvIsUsed(true)
                COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT({...payloadForDueInvoicesCollection})
            } else {
                setOldCsvIsUsed(false)
                parseUploadedCsv(depositData.uploaded_recharge_csv[0])
            }
            setMakeInvoiceStatusNotUpdatable(true)
        } else if (showBulkInvoiceUploadField && userCanUploadInvoiceCsv && !depositData.uploaded_recharge_csv[0]) {
            if (decisionForAddingPreviousBalance === 'yes') {
                COLLECT_CURRENT_BALANCE({'entity_type': payload['recharging_for'].value, 'entity_guid': payload['entity_guid'].value})
            }
            // Manual Invoice Payment when invoice csv has not been uploaded
            delete payload['uploaded_recharge_csv']
            setMakeInvoiceStatusNotUpdatable(false)
            updateTitle("Which Invoices would you like to mark as Paid?")
            COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT({...payloadForDueInvoicesCollection})
        } else {
            // Automatic Invoice Payment
            saveFinalPayload(payload) // Passing internal payload which hasn't been saved in the state till now
        }
        setPayload(payload)
    }
    /* ---------------------- End: Next button click events ------------------------------ */

    /* ---------------------- Begin: showing second page related ------------------------- */
    useEffect(() => {
        if (props.currentBalance) {
            setEntityBalance(props.currentBalance.current_balance)
            setRechargingAmount(props.currentBalance.current_balance + parseInt(payload.amount))
        } else {
            setEntityBalance(undefined)
        }
    }, [props.currentBalance])
    useEffect(() => {
        if (props.errorMessageForBalanceCollection) {
            showNotifications('error', props.errorMessageForBalanceCollection)
        }
    }, [props.errorMessageForBalanceCollection])

    useEffect(() => {
        if (invoices && nextButtonIsClicked) {
            if ((decisionForAddingPreviousBalance === 'yes' && entityBalance !== undefined) || decisionForAddingPreviousBalance === 'no') {
                setShowInvoices(true)
            }
        }
    }, [invoices, entityBalance])
    /* ---------------------- End: showing second page related ------------------------- */

    /* ---------------------- Begin: Second page related ------------------------------ */
    function shortenTheName(name) {
        if (isTabletOrMobile) {
            // For mobile view
            if (name.length <= 21) {
                return name; // If name is 21 characters or less, returning the full name
            } else {
                let firstFive = name.substring(0, 5); // Getting the first five characters
                let lastThirteen = name.substring(name.length - 13); // Getting the last thirteen characters
                return firstFive + "..." + lastThirteen; // Concatenating with "..." in between
            }
        } else {
            // For desktop view
            if (name.length <= 39) {
                return name; // If name is 39 characters or less, returning the full name
            } else {
                let firstFive = name.substring(0, 12); // Getting the first twelve characters
                let lastSixteen = name.substring(name.length - 21); // Getting the last twenty one characters
                return firstFive + "......" + lastSixteen; // Concatenating with "..." in between
            }
        }
    }

    const invoiceListColumnsWithUpdatableStatus = [
        {
            field: 'serialNumber',
            title: 'Smart Battery',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>SB # {rowData.serialNumber}</strong></h6>
                    <div>Contract # {rowData.contract_id}</div>
                    <div>Invoice # {rowData.invoice_id}</div>
                </>
            }
        },
        {
            field: 'payableAmount',
            title: 'Amount Due (Tk)',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                let amount = "Tk " + new Intl.NumberFormat('en-IN').format(rowData.payableAmount.toFixed(2))
                let rangeText = ''
                if (rowData.invoice_for === 'installment') {
                    let paymentDate = moment(rowData.installment_due_on, "YYYY-MM-DD").format("MMM DD YYYY")
                    let startDate = moment(rowData.created_at, "YYYY-MM-DD").format("MMM DD YYYY")
                    let splittedStartDate = startDate.split(" ")
                    let splittedPaymentDate = paymentDate.split(" ")
                    if (splittedStartDate[2] === splittedPaymentDate[2]) {
                        // Year is equal
                        if (splittedStartDate[0] === splittedPaymentDate[0]) {
                            // Both year and month is equal
                            rangeText = splittedStartDate[0] + " " + splittedStartDate[1] + " - " + splittedPaymentDate[1] + ", " + splittedStartDate[2]
                        } else {
                            // Year equal but month is not
                            rangeText = splittedStartDate[0] + " " + splittedStartDate[1] + " - " + splittedPaymentDate[0] + " " + splittedPaymentDate[1] + ", " + splittedStartDate[2]
                        }
                    } else {
                        // Year is not equal
                        rangeText = startDate + ' - ' + paymentDate
                    }
                } else if (rowData.invoice_for === 'rent_payment') {
                    rangeText = moment(rowData.created_at).format("MMM DD, YYYY")
                } else {
                    rangeText = moment(rowData.created_at).format("MMM DD, YYYY")
                }
                return   <>
                    <div>{amount}</div>
                    {rowData.installment_no? <div>Installment # {rowData.installment_no}</div>:''}
                    <div>{rangeText}</div>
                </>
            }
        },
        {
            field: 'paidAmount',
            title: 'Paid (Tk)',
            cellStyle: {
                // paddingRight: '0',
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{new Intl.NumberFormat('en-IN').format(rowData.paidAmount.toFixed(2))}</strong></h6>
                </>
            }
        },
        {
            field: 'invoiceStatus',
            title: 'Paid?',
            cellStyle: {
                textAlign: 'center'
            },
            headerStyle: {
                textAlign: 'center'
            },
            render: (rowData) => {
                return (
                    <>
                        {/* We haven't used external css because, only during inline styling the downward arrow on
                            the right side of the select box has become 'white' - Noor Reza, 24th August, 2023, 6:40 pm */}
                        <Form.Group>
                            <Form.Control as="select" size={"sm"} ref={(el) => {
                                if (el) {
                                    el.style.setProperty('color', '#ffffff', 'important');
                                    if (rowData.invoiceStatus === 'paid') {
                                        el.style.setProperty('background-color', '#0b6e4f', 'important');
                                        el.style.setProperty('border-color', '#0b6e4f', 'important');
                                    } else if (rowData.invoiceStatus === 'overdue') {
                                        el.style.setProperty('background-color', '#E71D36', 'important');
                                        el.style.setProperty('border-color', '#E71D36', 'important');
                                    } else {
                                        el.style.setProperty('background-color', '#F18D00', 'important');
                                        el.style.setProperty('border-color', '#F18D00', 'important');
                                    }
                                    el.style.setProperty('margin-top', '12%', 'important');
                                    if (!isTabletOrMobile) {
                                        el.style.setProperty('width', '60%', 'important');
                                    }
                                }
                            }} onChange={(e) => handleInvoiceStatusUpdate(e.target.value, rowData)}
                                          defaultValue={rowData.invoiceStatus}>
                                <option value={(rowData.invoiceStatus === 'due' || rowData.previousInvoiceStatus === 'due')? 'due':
                                    rowData.invoiceStatus === 'overdue' || rowData.previousInvoiceStatus === 'overdue'? 'overdue':'partial_paid'} ref = {(el) => {
                                    if (el) {
                                        el.style.setProperty('color', '#000000', 'important');
                                        el.style.setProperty('background-color', '#ffffff', 'important');
                                    }
                                }}>{(rowData.invoiceStatus === 'overdue' || rowData.previousInvoiceStatus === 'overdue')? 'Overdue':'Due'}</option>
                                <option value={'paid'} ref = {(el) => {
                                    if (el) {
                                        el.style.setProperty('color', '#000000', 'important');
                                        el.style.setProperty('background-color', '#ffffff', 'important');
                                    }
                                }}>Paid</option>
                            </Form.Control>
                        </Form.Group>
                    </>
                )
            }
        }
    ]
    const invoiceListColumnsWithNotUpdatatbleStatus =  [
        {
            field: 'serialNumber',
            title: 'Smart Battery',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    {!rowData.errorInSmartBattery? <h6><strong>SB # {rowData.serialNumber}</strong></h6>:<>
                        <Alert severity="error" icon={false}><h6>Smart Battery {rowData.serialNumber}</h6></Alert>
                    </>}
                    <div>Contract # {rowData.contract_id}</div>
                    <div>Invoice # {rowData.invoice_id}</div>
                </>
            }
        },
        {
            field: 'payableAmount',
            title: 'Amount Due (Tk)',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                let amount = "Tk " + new Intl.NumberFormat('en-IN').format(rowData.payableAmount.toFixed(2))
                let rangeText = ''
                if (rowData.invoice_for === 'installment') {
                    let paymentDate = moment(rowData.installment_due_on, "YYYY-MM-DD").format("MMM DD YYYY")
                    let startDate = moment(rowData.created_at, "YYYY-MM-DD").format("MMM DD YYYY")
                    let splittedStartDate = startDate.split(" ")
                    let splittedPaymentDate = paymentDate.split(" ")
                    if (splittedStartDate[2] === splittedPaymentDate[2]) {
                        // Year is equal
                        if (splittedStartDate[0] === splittedPaymentDate[0]) {
                            // Both year and month is equal
                            rangeText = splittedStartDate[0] + " " + splittedStartDate[1] + " - " + splittedPaymentDate[1] + ", " + splittedStartDate[2]
                        } else {
                            // Year equal but month is not
                            rangeText = splittedStartDate[0] + " " + splittedStartDate[1] + " - " + splittedPaymentDate[0] + " " + splittedPaymentDate[1] + ", " + splittedStartDate[2]
                        }
                    } else {
                        // Year is not equal
                        rangeText = startDate + ' - ' + paymentDate
                    }
                } else if (rowData.invoice_for === 'rent_payment') {
                    rangeText = moment(rowData.created_at).format("MMM DD, YYYY")
                } else {
                    rangeText = moment(rowData.created_at).format("MMM DD, YYYY")
                }
                return   <>
                    {!rowData.errorInPayableAmount? <div>{amount}</div>:<>
                        <Alert severity="error" icon={false}><h6>{amount}</h6></Alert>
                    </>}
                    {rowData.installment_no? <div>Installment # {rowData.installment_no}</div>:''}
                    <div>{rangeText}</div>
                </>
            }
        },
        {
            field: 'paidAmount',
            title: 'Paid (Tk)',
            cellStyle: {
                // paddingRight: '0',
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                let amount = new Intl.NumberFormat('en-IN').format(rowData.paidAmount.toFixed(2))
                return   <>
                    {!(rowData.errorInPayableAmount && rowData.paidAmount === rowData.payableAmount)? <h6><strong>{amount}</strong></h6>:<>
                        <Alert severity="error" icon={false} ref={(el) => {
                            if (el && !isTabletOrMobile) {
                                el.style.setProperty('float', 'right', 'important')
                            }
                        }}><h6><strong>{amount}</strong></h6></Alert>
                    </>}
                </>
            }
        },
        {
            field: 'invoiceStatus',
            title: 'Paid?',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return <>
                    {rowData.errorInStatus? <>
                        <Alert severity="error" ref={(el) => {
                            if (el) {
                                el.style.setProperty('float', 'right', 'important')
                            }
                        }}><h6>{rowData.invoiceStatus}</h6></Alert>
                    </>:<>
                        {rowData.invoiceStatus === 'paid'? <>
                            <Button size={'sm'} ref={(el) => {
                                if (el) {
                                    el.style.setProperty('color', '#ffffff', 'important')
                                    el.style.setProperty('background-color', '#0b6e4f', 'important')
                                    el.style.setProperty('border-color', '#0b6e4f', 'important')
                                }
                            }}>Paid</Button>
                        </>:<>
                            {rowData.invoiceStatus === 'due'? <>
                                <Button variant={'outline-dark'} size={'sm'} disabled={true}>Due</Button>
                            </>:<>
                                {/* Overdue status */}
                                <Button size={'sm'} variant={'outline-danger'} disabled={true}>Overdue</Button>
                            </>}
                        </>}
                    </>}
                </>
            }
        }
    ]

    const handleInvoiceStatusUpdate = (status, invoiceData) => {
        let updatedClearedAmount = clearedAmount
        setInvoices(invoices.map((invoice) => {
            let updatedStatus = invoice.invoiceStatus
            // When invoice status is set to 'paid', we are preserving the previous status, otherwise not
            // - Noor Reza, 4th January, 2023 2:00 PM
            let previousInvoiceStatus = invoice.invoiceStatus === 'paid'? invoice.previousInvoiceStatus:invoice.invoiceStatus
            let updatedPaidAmount = invoice.paidAmount
            if (invoice.pk === invoiceData.pk) {
                let updatedPaidInvoicesIds = paidInvoices
                if (status === 'paid') {
                    updatedStatus = status
                    updatedPaidAmount += invoiceData.payableAmount
                    updatedClearedAmount += invoiceData.payableAmount
                    if (updatedClearedAmount <= rechargingAmount) {
                        setResidueAmount(rechargingAmount - updatedClearedAmount)
                    }
                    updatedPaidInvoicesIds.push(invoiceData.invoice_id)
                } else {
                    updatedStatus = status
                    updatedPaidAmount -= invoiceData.payableAmount
                    updatedClearedAmount -= invoiceData.payableAmount
                    if (updatedClearedAmount === 0) {
                        // When no invoice is cleared, residue amount is set to 0
                        setResidueAmount(0)
                    } else if (updatedClearedAmount <= rechargingAmount) {
                        setResidueAmount(rechargingAmount - updatedClearedAmount)
                    }
                    updatedPaidInvoicesIds = paidInvoices.filter(item => item !== invoiceData.invoice_id)
                }
                setPaidInvoices(updatedPaidInvoicesIds)
            }

            return {
                ...invoice,
                serialNumber: invoice.device_sl,
                payableAmount: invoice.payableAmount,
                invoiceStatus: updatedStatus,
                previousInvoiceStatus: previousInvoiceStatus,
                paidAmount: updatedPaidAmount
            }
        }))
        setClearedAmount(updatedClearedAmount)
    }

    const returnToCashDepositFirstStep = () => {
        setShowInvoices(false)
        updateTitle("New Cash Deposit")

        setInvoices(undefined)
        setShowInvoiceTableWhenCsvIsUploaded(false)
        setShowError(false)
        setPaidInvoices([])
        setNextButtonIsClicked(false) // Refreshing the next button click tracking
    }
    const saveFinalPayload = (data) => {
        if (parseFloat(data.amount) < clearedAmount) {
            setShowError(true)
        } else {
            setShowError(false)
            let reference = data.recharging_for.value === 'borrower'? '11' + data.entity_guid.user_id:'55' + data.entity_guid.serial_number
            let finalPayload = {
                ...data,
                'add_previous_balance': decisionForAddingPreviousBalance === 'yes',
                'automatic_or_manual_invoice_payment': invoicePaymentProcess,
                'reference': reference,
                'entity_guid': data.entity_guid.value,
                'invoice_ids': paidInvoices,
                'sender': props.user.username
            }
            delete finalPayload['recharging_for']
            handleNewCashDepositProcess(finalPayload)
        }
    }
    /* ---------------------- End: Second page related ------------------------------ */

    return (
        <>
            {/* ------- First Step ---------- */}
            <div style={showInvoices? {display: 'none'}:{}}>
                <Form onSubmit={handleSubmit(firstPageFormSubmitActions)}>
                    <div className={'row g-3'}>
                        <div className={'col-md-6'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><strong>Deposit Amount (Tk)</strong>&nbsp;<span className="required text-danger">*</span></Form.Label>
                                </div>
                                <SolInput
                                    type={"number"}
                                    name={"amount"}
                                    step={"0.01"}
                                    min={"0"}
                                    placeholder={"0.0"}
                                    autoComplete={"off"}
                                    ref={register({
                                        required: informationIsRequired
                                    })}
                                />
                                {errors.amount && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.amount.message}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row g-3'}>
                        <div className={'col-md-6'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Recharging for&nbsp;<span className="required text-danger">*</span></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"recharging_for"}
                                    defaultValue={""}
                                    rules={{ required:informationIsRequired }}
                                    render={( { onChange, onBlur, value, name, ref },
                                              { invalid, isTouched, isDirty }) => (
                                        <Select
                                            placeholder={'Select an option'}
                                            classNamePrefix="react-select-sol-style"
                                            isDisabled={props.collectingBorrowersForCashDeposit || props.collectingSmartBatteriesForCashDeposit}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={rechargingCriteriaOptions}
                                            isSearchable={true}
                                            noOptionsMessage={() => "No option available"}
                                            onChange={(selected, {action}) => {
                                                if (action === 'clear') {
                                                    setShowRechargeEntitySelectionField(false)
                                                    setRechargeEntitySelectionFieldLabel('')
                                                    setValue('entity_guid', '')
                                                }
                                                onChange(selected)
                                                clearErrors("recharging_for")
                                                clearErrors("entity_guid")
                                                if (selected) {
                                                    setValue('entity_guid', '')
                                                    if (selected.value === 'borrower') {
                                                        COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT()
                                                        setRechargeEntitySelectionFieldLabel('Borrower')
                                                    } else {
                                                        COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT()
                                                        setRechargeEntitySelectionFieldLabel('Smart Battery')
                                                    }
                                                    setShowRechargeEntitySelectionField(true)
                                                }
                                            }}
                                        />
                                    )}
                                />
                                {errors.recharging_for && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.recharging_for.message}</div>}
                            </Form.Group>
                        </div>
                        {showRechargeEntitySelectionField? <>
                            <div className={'col-md-6'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{rechargeEntitySelectionFieldLabel}&nbsp;<span className="required text-danger">*</span></Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"entity_guid"}
                                        defaultValue={""}
                                        rules={{ required:informationIsRequired }}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                placeholder={'Select a ' + rechargeEntitySelectionFieldLabel.toLowerCase()}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.collectingBorrowersForCashDeposit || props.collectingSmartBatteriesForCashDeposit}
                                                isLoading={props.collectingBorrowersForCashDeposit || props.collectingSmartBatteriesForCashDeposit}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={rechargeEntitySelectionFieldLabel === 'Borrower'? borrowers:smartBatteries}
                                                isSearchable={true}
                                                noOptionsMessage={() => "No option available"}
                                                onChange={(selected, {action}) => {
                                                    if (action === 'clear') {
                                                        setInvoiceCsvName('')
                                                        setPayloadForDueInvoicesCollection({})
                                                    }
                                                    onChange(selected)
                                                    clearErrors("entity_guid")
                                                    if (selected) {
                                                        let rechargingFor = getValues("recharging_for")
                                                        setInvoiceCsvName(rechargingFor.value + "_" + selected.label)
                                                        setSecondStepTitle(rechargingFor.label + ": " + selected.label);
                                                        let payload = {
                                                            'return_all_due_invoice':'yes'
                                                        }
                                                        if (rechargingFor.value === 'borrower') {
                                                            payload['borrower_guid'] = selected.value
                                                        } else {
                                                            payload['device_sl'] = selected.value
                                                        }
                                                        setPayloadForDueInvoicesCollection(payload)
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.entity_guid && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.entity_guid.message}</div>}
                                </Form.Group>
                            </div>
                        </>:''}
                    </div>
                    <div className={'row g-3'}>
                        <div className={'col-md-6'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Reference No.</Form.Label>
                                </div>
                                <SolInput
                                    type={"text"}
                                    name={"receipt_reference"}
                                    placeholder={"Enter reference no"}
                                    autoComplete={"off"}
                                    ref={register()}
                                />
                                {errors.receipt_reference && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.receipt_reference.message}</div>}
                            </Form.Group>
                        </div>
                        <div className={'col-md-6'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Supporting Document <span className="required text-danger">*</span> {toolTipForDocumentField()}</Form.Label>
                                </div>
                                <SolInput
                                    name={"receipt_file"}
                                    type={"file"}
                                    ref={register({
                                        required: informationIsRequired
                                    })}
                                    onClick={(e) => {
                                        // Clearing the file field value when user tries to re-upload
                                        setValue("receipt_file", "")
                                        clearErrors('receipt_file')
                                        setDisableFirstStepSaveButton(false)
                                    }}
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            let value = e.target.files[0]
                                            if (value) {
                                                if (!["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                    "application/pdf", "application/vnd.ms-excel",
                                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                    "image/jpeg", "image/webp", "image/png"].includes(value.type)) {
                                                    setError('receipt_file', {'message': wrongFileTypeMessage})
                                                    setDisableFirstStepSaveButton(true)
                                                } else if (value.size/(1024 * 1024) > 10) {
                                                    setError('receipt_file', {'message': wrongFileSizeMessage})
                                                    setDisableFirstStepSaveButton(true)
                                                } else {
                                                    clearErrors('receipt_file')
                                                    setDisableFirstStepSaveButton(false)
                                                }
                                            }
                                        }
                                    }}
                                />
                                {errors.receipt_file && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.receipt_file.message}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row g-3'}>
                        <div className={'col-md-6'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Payment Made By</Form.Label>
                                </div>
                                <SolInput
                                    type={"text"}
                                    name={"received_by_person_name"}
                                    placeholder={"Enter name"}
                                    autoComplete={"off"}
                                    ref={register({
                                        validate: {
                                            // We are allowing all bengali characters excluding bengali numeric - Noor Reza 7th November, 2023 3:03 PM
                                            // Unicode source - https://github.com/lifeparticle/Bengali-Alphabet
                                            // Note that, we haven't used unicode range for shortcut (which has been done at the Garage Name validation as numbers are allowed there)
                                            isValidName: value => (/^[a-zA-Z-'. \u0985-\u098B\u098F-\u0990\u0993-\u0994\u0995-\u09A8\u09AA-\u09B0\u09B2\u09B6-\u09B9\u09DC-\u09DD\u09DF\u09CE\u0981-\u0983\u09BE-\u09C3\u09C7-\u09C8\u09CB-\u09CC]+$/.test(value) || !value) || "Invalid Name!"
                                        }
                                    })}
                                />
                                {errors.received_by_person_name && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.received_by_person_name.message}</div>}
                            </Form.Group>
                        </div>
                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Add Prev. Balance</Form.Label>
                                </div>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1.25}
                                    className={"mt-2"}
                                >
                                    <div className="form-check form-check-inline">
                                        <SolRadioInput
                                            {...register("add_previous_balance")}
                                            value={"no"}
                                            handleChange={(event) => handleDecisionSelectionForAddingPreviousBalance(event)}
                                            checked={decisionForAddingPreviousBalance === "no"}
                                            htmlFor="no"
                                            labelName="No"
                                        />
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <SolRadioInput
                                            {...register("add_previous_balance")}
                                            value={"yes"}
                                            handleChange={(event) => handleDecisionSelectionForAddingPreviousBalance(event)}
                                            checked={decisionForAddingPreviousBalance === "yes"}
                                            htmlFor="yes"
                                            labelName="Yes"
                                        />
                                    </div>
                                </Stack>
                            </Form.Group>
                        </div>
                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Invoice Payment</Form.Label>
                                </div>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1.25}
                                    className={"mt-2"}
                                >
                                    <div className="form-check form-check-inline">
                                        <SolRadioInput
                                            {...register("invoice_payment_process")}
                                            value={"manual"}
                                            handleChange={(event) => handleProcessSelectionForInvoicePayment(event)}
                                            checked={invoicePaymentProcess === "manual"}
                                            htmlFor="manual"
                                            labelName="Manual"
                                        />
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <SolRadioInput
                                            {...register("invoice_payment_process")}
                                            value={"automatic"}
                                            handleChange={(event) => handleProcessSelectionForInvoicePayment(event)}
                                            checked={invoicePaymentProcess === "automatic"}
                                            htmlFor="automatic"
                                            labelName="Automatic"
                                        />
                                    </div>
                                </Stack>
                            </Form.Group>
                        </div>
                    </div>
                    {showBulkInvoiceUploadField && userCanUploadInvoiceCsv? <>
                        <div className={'row g-3'}>
                            <div className={'col-md-6'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>Upload Cash Recharge Data (CSV)</Form.Label><br/>
                                    </div>
                                    <SolInput type="file" name={"uploaded_recharge_csv"} ref={register({
                                        validate: {
                                            isValidType: (value) => {if (value && value[0] && value[0].type !== "text/csv") {return 'Wrong file type! Accepted file types is *.csv'}}
                                        }
                                    })}/>
                                    {(props.collectingInvoicesForCashDeposit && !nextButtonIsClicked)? <>
                                        <span style={{color: "#F18D00"}}>Downloading Pending invoice List....</span>
                                    </>:<>
                                        <a style={{color: "#F18D00"}} onClick={initiateInvoiceDownloadProcess}>Download Pending invoice List</a>
                                    </>}
                                    {errors.uploaded_recharge_csv && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.uploaded_recharge_csv.message}</div>}
                                </Form.Group>
                            </div>
                        </div>
                    </>:''}
                    <hr/>
                    <div className={'row'}>
                        <div className={"col-md-12"}>
                            <Button variant="warning" size={'lg'} type="submit"
                                    disabled={disableFirstStepSaveButton || props.collectingInvoicesForCashDeposit || props.collectingCurrentBalance || ((props.collectingInvoicesForCashDeposit || props.collectingCurrentBalance) && nextButtonIsClicked)}
                                    className={'ml-3 float-right'}>
                                {(props.collectingInvoicesForCashDeposit || props.collectingCurrentBalance) && nextButtonIsClicked? <><Spinner animation={'border'} size={'sm'}
                                                                                variant={'light'}/>&nbsp;</>:null}{showBulkInvoiceUploadField? 'Next':'Deposit'}
                            </Button>
                            <Button variant="outline-dark" size={'lg'} onClick={cancelProcess}
                                    className={'float-right'}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
            {/* ------- Second Step --------- */}
            <div style={showInvoices? {}:{display: 'none'}}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <h3><strong>{secondStepTitle}</strong></h3>
                        <hr/>
                    </div>
                </div>
                <div className={'row pt-2'}>
                    <div className={'col-md-12'}>
                        <h6><strong>Recharging Amount: Tk {new Intl.NumberFormat('en-IN').format(rechargingAmount)} | Invoice Payable: Tk {payableAmount} | Cleared Amount: Tk {new Intl.NumberFormat('en-IN').format(clearedAmount.toFixed(2))}</strong></h6>
                    </div>
                </div>
                <div className={'row pt-2'}>
                    <div className={'col-md-12'}>
                        <h6><strong>Residue Amount: Tk {new Intl.NumberFormat('en-IN').format(parseFloat(residueAmount).toFixed(2))} <span style={{color: '#0b6e4f'}}>(Will be added to the {getValues("recharging_for")?.value}'s account)</span></strong></h6>
                    </div>
                </div>
                <div className={'row pt-2'}>
                    <div className={'col-md-12'}>
                        <h6><strong>Deposit Date: {paymentDate} {payload?.received_by_person_name? '| Deposited By: ' + payload?.received_by_person_name:''} {payload?.receipt_reference? '| Reference No: ' + payload.receipt_reference:''}</strong></h6>
                    </div>
                </div>
                {payload?.receipt_file || payload?.payment_file? <>
                    <div className={'row pt-2'}>
                        <div className={'col-md-12'} style={{display: "flex", flexDirection: "row", color: '#F18D00'}}>
                            {payload?.receipt_file?.name? <>
                                <FilePresentIcon/>&nbsp;<strong>{shortenTheName(payload?.receipt_file?.name)}</strong>{payload?.payment_file?.name? <>&nbsp;&nbsp;&nbsp;&nbsp;<FilePresentIcon/>&nbsp;<strong>{shortenTheName(payload?.payment_file?.name)}</strong></>:''}
                            </>:<>
                                <FilePresentIcon/>&nbsp;<strong>{shortenTheName(payload?.payment_file?.name)}</strong>
                            </>}
                        </div>
                    </div>
                </>:''}
                <hr/>
                {/* TODO: Update the following process of showing uploaded invoices */}
                <div className={'row pt-6'}>
                    <div className={'col-md-12'}>
                        {props.collectingInvoicesForCashDeposit? <>
                            <LoadingSpinner language={language} loadingSubText={showBulkInvoiceUploadField && userCanUploadInvoiceCsv && payload?.payment_file? "Verifying uploaded invoices ...":"Collecting due invoices ..."}/>
                        </>:<>
                            {showBulkInvoiceUploadField && userCanUploadInvoiceCsv && payload?.payment_file? <>
                                {fileIsCorrupted? <>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        Uploaded <strong>{payload?.payment_file?.name}</strong> file (for Cash Deposit) should have exactly following four column headers: <strong>Invoice_ID</strong>, <strong>SB_Number</strong>, <strong>Payable_amount</strong>, <strong>Status</strong>
                                    </Alert>
                                </>:errorNoInvoiceMatched? <>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        Uploaded <strong>{payload?.payment_file?.name}</strong> file (for Cash Deposit) contains no invoices for <strong>{secondStepTitle}</strong>
                                    </Alert>
                                </>:<>
                                    {showInvoiceTableWhenCsvIsUploaded && invoices? <>
                                        <DataTable
                                            language={language}
                                            columns={makeInvoiceStatusNotUpdatable? invoiceListColumnsWithNotUpdatatbleStatus:invoiceListColumnsWithUpdatableStatus}
                                            data={invoices}
                                            noDataAvailableMessageInEnglish={'There are no due invoices'}
                                            showToolbar={false}
                                            asyncPagination={false}
                                            isLoading={props.tableLoading}
                                            pagination={true}
                                            pageSize={5}
                                        />
                                    </>:<>
                                        <LoadingSpinner language={language} loadingSubText={"Verifying uploaded invoices ..."}/>
                                    </>}
                                </>}
                            </>:<>
                                {!props.invoicesForCashDeposit? <>
                                    {/* When an error occured regarding invoice collection */}
                                    <DataTable
                                        language={language}
                                        columns={makeInvoiceStatusNotUpdatable? invoiceListColumnsWithNotUpdatatbleStatus:invoiceListColumnsWithUpdatableStatus}
                                        data={[]}
                                        noDataAvailableMessageInEnglish={'There are no due invoices'}
                                        showToolbar={false}
                                        asyncPagination={false}
                                        isLoading={props.tableLoading}
                                        pagination={true}
                                        pageSize={5}
                                    />
                                </>:<>
                                    {invoices? <>
                                        {/* When invoices variable gets loaded */}
                                        <DataTable
                                            language={language}
                                            columns={makeInvoiceStatusNotUpdatable? invoiceListColumnsWithNotUpdatatbleStatus:invoiceListColumnsWithUpdatableStatus}
                                            data={invoices}
                                            noDataAvailableMessageInEnglish={'There are no due invoices'}
                                            showToolbar={false}
                                            asyncPagination={false}
                                            isLoading={props.tableLoading}
                                            pagination={true}
                                            pageSize={5}
                                        />
                                    </>:<>
                                        <LoadingSpinner language={language} loadingSubText={"Generating due invoices list ..."}/>
                                    </>}
                                </>}
                            </>}
                        </>}
                    </div>
                </div>
                {errorInTheUploadedCsv? <>
                    <div className={'row pt-4'}>
                        <div className={'col-md-12'}>
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                There {errorRowCountInTheUploadedCsv === 1? 'is a row which has':'are ' + errorRowCountInTheUploadedCsv + ' rows which have'} wrong inputs in the uploaded <strong>{payload?.payment_file?.name}</strong> file (for Cash Deposit)! {errorRowCountInTheUploadedCsv === 1? 'It is':'They are'} red colored at the above table.
                            </Alert>
                        </div>
                    </div>
                </>:null}
                {showError? <>
                    <div className={'row pt-4'}>
                        <div className={"col-md-12"}>
                            <Alert severity="error" variant={"filled"} onClose={() => {setShowError(false)}}><strong>Cleared amount</strong> should not be greater than the <strong>Recharging amount</strong>!</Alert>
                        </div>
                    </div>
                </>:null}
                <div className={'row pt-2'}>
                    <div className={"col-md-12"}>
                        <Button size={'lg'} onClick={returnToCashDepositFirstStep} className={'float-left'}
                                style={{'backgroundColor': '#8C8C9B', 'borderColor': '#8C8C9B'}}
                                disabled={props.cashDepositProcessInProgress || props.collectingInvoicesForCashDeposit}>
                            Go Back
                        </Button>
                        <Button variant="warning" size={'lg'} type="button"
                                disabled={props.cashDepositProcessInProgress || props.collectingInvoicesForCashDeposit ||
                                    !invoices || (invoices && invoices.length === 0) || paidInvoices.length === 0 ||
                                    errorInTheUploadedCsv}
                                className={'float-right'} onClick={() => saveFinalPayload(payload)}>
                            {props.cashDepositProcessInProgress? <><Spinner animation={'border'} size={'md'}
                                                                           variant={'light'}/>&nbsp;</>:null}Deposit
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

NewCashDeposit.propTypes = {};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        language: state.auth.language,
        authorization: state.auth.authorizations,
        borrowersForCashDeposit: state.depositsReducer.borrowersForCashDeposit,
        collectingBorrowersForCashDeposit: state.depositsReducer.collectingBorrowersForCashDeposit,
        errorMessageForBorrowersCollection: state.depositsReducer.errorMessageForBorrowersCollection,
        smartBatteriesForCashDeposit: state.depositsReducer.smartBatteriesForCashDeposit,
        collectingSmartBatteriesForCashDeposit: state.depositsReducer.collectingSmartBatteriesForCashDeposit,
        errorMessageForSmartBatteriesCollection: state.depositsReducer.errorMessageForSmartBatteriesCollection,
        invoicesForCashDeposit: state.depositsReducer.invoicesForCashDeposit,
        collectingInvoicesForCashDeposit: state.depositsReducer.collectingInvoicesForCashDeposit,
        errorMessageForInvoicesCollection: state.depositsReducer.errorMessageForInvoicesCollection,
        cashDepositProcessInProgress: state.depositsReducer.cashDepositProcessInProgress,
        currentBalance: state.depositsReducer.currentBalance,
        errorMessageForBalanceCollection: state.depositsReducer.errorMessageForBalanceCollection,
        collectingCurrentBalance: state.depositsReducer.collectingCurrentBalance
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(NewCashDeposit));