import React, {useEffect, useState, useRef} from 'react';
import {connect} from "react-redux";
import {actions} from "../../../actions";
import {actions as commonActions} from "../../../../commonReduxActions/actions";
import {makeStyles} from "@material-ui/styles";
import {withRoles} from "../../../../../router/SecuredRoute";
import {useMediaQuery} from "react-responsive";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableContainer from '@mui/material/TableContainer';
import LastTransactions from "../reUsables/lastTransactions"
import BorrowerProfile from "../reUsables/borrowerProfile"



const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const BorrowerSearch = ({borrowerName, ...props}) => {
    const [garages, setGarages] = useState(undefined);
    const [language, setLanguage] = useState(null);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const {
        // COLLECT_DISTRICTS,
        COLLECT_FINANCIERS
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
    }, []);

    // Saving latest garage information
    useEffect(() => {
        if (props.garages && props.garages.length > -1) {
            const garages = props.garages;
            setGarages(garages.map((garage) => {
                return {
                    ...garage
                }
            }))
        }
    }, [props.garages])

    // Table header styling
    const tableHeaderLeftAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        paddingLeft: "0",
        textAlign: "left",
        boxSizing: "borderBox"
    }

    const tableHeaderRightAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        paddingLRight: "0px",
        textAlign: "right",
        boxSizing: "borderBox"
    }

    // Table cell related styling
    const tableLeftAlignedContentStyling = {
        color: "inherit",
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        paddingLeft: "0",
        textAlign: "left"
    }

    const tableRightAlignedContentStyling = {
        color: "inherit",
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        textAlign: "right"
    }

    return (
        <>
            <div className={'row'}>
                <div className={'col-lg-9'}>
                    {isTabletOrMobile? <BorrowerProfile title={'Profile'} forComponent={'driver'} profileData={{}}/>:<LastTransactions/>}
                </div>
                <div className={'col-lg-3'}>
                    {isTabletOrMobile? <LastTransactions/>:<BorrowerProfile title={'Profile'} forComponent={'driver'} profileData={{}}/>}
                </div>
            </div>
            {!isTabletOrMobile? <hr/>:null}
            <div className={'row'}>
                <div className={'col-lg-12'}>
                    <div>
                        <div className={isTabletOrMobile? 'row mt-4':'row ml-1 mt-10'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}><h5><strong>4 Loan Contracts</strong></h5></div>
                        <div className={isTabletOrMobile? 'row':'row ml-1 mt-2'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                            <TableContainer>
                                <Table bordered={true} aria-label="spanning table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={tableHeaderLeftAlignedColumnStyling}>Loan Contract</TableCell>
                                            <TableCell style={tableHeaderLeftAlignedColumnStyling}>Contract Period</TableCell>
                                            <TableCell style={tableHeaderRightAlignedColumnStyling}>Loan Amount (Tk)</TableCell>
                                            <TableCell style={tableHeaderRightAlignedColumnStyling}>Loan Outstanding (Tk)</TableCell>
                                            <TableCell style={tableHeaderRightAlignedColumnStyling}>Invoiced (Tk)</TableCell>
                                            <TableCell style={tableHeaderRightAlignedColumnStyling}>Overdue (Tk)</TableCell>
                                            <TableCell style={tableHeaderRightAlignedColumnStyling}>Next Lease Payment (Tk)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                <a href={"#"}>8827</a><br/>Tk 1470 / Week
                                            </TableCell>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                Jan 01'23 - Jan 31'2<br/>6 months remaining
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>5,00,000</strong></h4>
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>4,00,000</strong></h4>
                                                Paid Tk 1,00,000
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>3,000</strong></h4>
                                                Paid Tk 1,000
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <span className={'text-danger'}>
                                                    <h4><strong>2,000</strong></h4>
                                                    3 days
                                                </span>
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>6,000</strong></h4>
                                                Jan 15, 2023
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                <a href={"#"}>8827</a><br/>Tk 1470 / Week
                                            </TableCell>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                Jan 01'23 - Jan 31'2<br/>6 months remaining
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>5,00,000</strong></h4>
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>4,00,000</strong></h4>
                                                Paid Tk 1,00,000
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>3,000</strong></h4>
                                                Paid Tk 1,000
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <span className={'text-danger'}>
                                                    <h4><strong>2,000</strong></h4>
                                                    3 days
                                                </span>
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>6,000</strong></h4>
                                                Jan 15, 2023
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                <a href={"#"}>8827</a><br/>Tk 1470 / Week
                                            </TableCell>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                Jan 01'23 - Jan 31'2<br/>6 months remaining
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>5,00,000</strong></h4>
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>4,00,000</strong></h4>
                                                Paid Tk 1,00,000
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>3,000</strong></h4>
                                                Paid Tk 1,000
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <span className={'text-danger'}>
                                                    <h4><strong>2,000</strong></h4>
                                                    3 days
                                                </span>
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>6,000</strong></h4>
                                                Jan 15, 2023
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                <a href={"#"}>8827</a><br/>Tk 1470 / Week
                                            </TableCell>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                Jan 01'23 - Jan 31'2<br/>6 months remaining
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>5,00,000</strong></h4>
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>4,00,000</strong></h4>
                                                Paid Tk 1,00,000
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>3,000</strong></h4>
                                                Paid Tk 1,000
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <span className={'text-danger'}>
                                                    <h4><strong>2,000</strong></h4>
                                                    3 days
                                                </span>
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                <h4><strong>6,000</strong></h4>
                                                Jan 15, 2023
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

BorrowerSearch.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        collectingBorrowers: state.commonReducer.collectingBorrowers,
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(BorrowerSearch));
