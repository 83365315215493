import moment from "moment";

/**
 * Add one month by considering exact (28/29/30/31) days of relevant months.
 * @constructor
 * @param {Object} date - Date (moment date object) with which one month will be added.
 * @remarks
 * - There is a limitation of this function (which depends on how we see it)
 * - This function add 28/29/30/31 days considering the provided date's month length, if the provided date is not the last date of its month
 * - And it adds exact next month days, if the provided date is the last date of its month
 * - Case 1: If we add 1 month with 28 February of a leap year (2024), we will get 28 March (2024)
 * - Case 2: If we add 1 month with 28 February of a not leap year (2023), we will get 31 March (2023)
 */
export const addExactOneMonth = (date) => {
    let isLeapYear = date.year() % 4 === 0
    let dateOfTheDate = date.date()
    let monthOfTheDate = date.month()
    const monthJson = {
        0: 31, // Jan
        1: isLeapYear? 29:28, // Feb
        2: 31,  // March
        3: 30,  // April
        4: 31,  // May
        5: 30,  // June
        6: 31,  // July
        7: 31,  // August
        8: 30,  // Sept
        9: 31,  // Oct
        10: 30,  // Nov
        11: 31  // Dec
    }
    let remainingDaysOfTheMonth = monthJson[monthOfTheDate] - dateOfTheDate
    let daysNeededToBeAdded = 0
    if (remainingDaysOfTheMonth) {
        daysNeededToBeAdded = remainingDaysOfTheMonth + dateOfTheDate
    } else {
        // Dealing with the case, if the date is the last day of the month
        let nextMonth = monthOfTheDate + 1
        daysNeededToBeAdded = nextMonth <= 11? monthJson[nextMonth]:monthJson[0]
        // About above process of accessing days of the next month from monthJson,
        // If the nextMonth is 12 or more the formula for getting the month serial is nextMonth % 12
        // But in case of adding one month, we will only get 12 as value, hence we are accessing the month at serial 0
    }
    return moment(date).add(daysNeededToBeAdded, 'days');
}