import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";

/*Initial State for grid*/
const initialState= {
    summaryDataFromGarage: undefined,
    summaryDataCollectionErrorFromGarage: undefined,
    summaryDataFromGarageForNewOverview: undefined,
    summaryDataCollectionErrorFromGarageForNewOverview: undefined,
    summaryDataFromTransaction: undefined,
    summaryDataCollectionErrorFromTransaction: undefined,
    inactiveBatteriesInfo: undefined,
    errorMessageForInactiveBatteryInfoCollection: undefined,
    activitiesOfTheSB: undefined,
    errorMessageForSBActivityCollection: undefined,
    zoneAreaInfo: undefined,
    zoneAreaInfoCollectionError: undefined,
    atAGlanceInfo: undefined,
    atAGlanceInfoCollectionError: undefined,
    projectTrendFromApi: undefined,
    projectTrendCollectionError: undefined,
    branchPerformanceInfo: undefined,
    branchPerformanceInfoCollectionError: undefined,
    lowPerformingBorrowers: undefined,
    lowPerformingBorrowersCollectionError: undefined,
    activeBatteryCounts: undefined,
    activeBatteryCountsCollectionError: undefined
};

/*Reducer for Loan's redux store*/
export const overviewReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_REQUEST:
            return {
                ...state,
                summaryDataFromGarage: undefined,
                summaryDataCollectionErrorFromGarage: undefined,
                collectingSummaryDataFromGarage: true
            };
        case actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_SUCCESS:
            return {
                ...state,
                summaryDataFromGarage: action.payload,
                collectingSummaryDataFromGarage: false
            };
        case actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_FAILURE:
            return {
                ...state,
                summaryDataCollectionErrorFromGarage: action.payload.error,
                collectingSummaryDataFromGarage: false
            };

        case actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_FOR_NEW_OVERVIEW_REQUEST:
            return {
                ...state,
                summaryDataFromGarageForNewOverview: undefined,
                summaryDataCollectionErrorFromGarageForNewOverview: undefined,
                collectingSummaryDataFromGarageForNewOverview: true
            };
        case actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_FOR_NEW_OVERVIEW_SUCCESS:
            return {
                ...state,
                summaryDataFromGarageForNewOverview: action.payload,
                collectingSummaryDataFromGarageForNewOverview: false
            };
        case actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_FOR_NEW_OVERVIEW_FAILURE:
            return {
                ...state,
                summaryDataCollectionErrorFromGarageForNewOverview: action.payload.error,
                collectingSummaryDataFromGarageForNewOverview: false
            };

        case actionTypes.COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_REQUEST:
            return {
                ...state,
                summaryDataFromTransaction: undefined,
                summaryDataCollectionErrorFromTransaction: undefined,
                collectingSummaryDataFromTransaction: true
            };
        case actionTypes.COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_SUCCESS:
            return {
                ...state,
                summaryDataFromTransaction: action.payload,
                collectingSummaryDataFromTransaction: false
            };
        case actionTypes.COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_FAILURE:
            return {
                ...state,
                summaryDataCollectionErrorFromTransaction: action.payload.error,
                collectingSummaryDataFromTransaction: false
            };

        case actionTypes.COLLECT_ZONE_AREA_OF_A_FINANCIER_REQUEST:
            return {
                ...state,
                zoneAreaInfo: undefined,
                zoneAreaInfoCollectionError: undefined,
                collectingZoneAreaInfo: true
            };
        case actionTypes.COLLECT_ZONE_AREA_OF_A_FINANCIER_SUCCESS:
            return {
                ...state,
                zoneAreaInfo: action.payload,
                collectingZoneAreaInfo: false
            };
        case actionTypes.COLLECT_ZONE_AREA_OF_A_FINANCIER_FAILURE:
            return {
                ...state,
                zoneAreaInfoCollectionError: action.payload.error,
                collectingZoneAreaInfo: false
            };

        case actionTypes.COLLECT_AT_A_GLANCE_INFO_REQUEST:
            return {
                ...state,
                atAGlanceInfo: undefined,
                atAGlanceInfoCollectionError: undefined,
                collectingAtAGlanceInfo: true
            };
        case actionTypes.COLLECT_AT_A_GLANCE_INFO_SUCCESS:
            return {
                ...state,
                atAGlanceInfo: action.payload,
                collectingAtAGlanceInfo: false
            };
        case actionTypes.COLLECT_AT_A_GLANCE_INFO_FAILURE:
            return {
                ...state,
                atAGlanceInfoCollectionError: action.payload.error,
                collectingAtAGlanceInfo: false
            };

        case actionTypes.COLLECT_AT_A_GLANCE_INFO_GRAPH_DATA_REQUEST:
            return {
                ...state,
                projectTrendFromApi: undefined,
                projectTrendCollectionError: undefined,
                collectingProjectionTrend: true
            };
        case actionTypes.COLLECT_AT_A_GLANCE_INFO_GRAPH_DATA_SUCCESS:
            return {
                ...state,
                projectTrendFromApi: action.payload,
                collectingProjectionTrend: false
            };
        case actionTypes.COLLECT_AT_A_GLANCE_INFO_GRAPH_DATA_FAILURE:
            return {
                ...state,
                projectTrendCollectionError: action.payload.error,
                collectingProjectionTrend: false
            };

        case actionTypes.COLLECT_BRANCH_WISE_PERFORMANCE_INFO_REQUEST:
            return {
                ...state,
                branchPerformanceInfo: undefined,
                branchPerformanceInfoCollectionError: undefined,
                collectingBranchPerformanceInfo: true
            };
        case actionTypes.COLLECT_BRANCH_WISE_PERFORMANCE_INFO_SUCCESS:
            return {
                ...state,
                branchPerformanceInfo: action.payload,
                collectingBranchPerformanceInfo: false
            };
        case actionTypes.COLLECT_BRANCH_WISE_PERFORMANCE_INFO_FAILURE:
            return {
                ...state,
                branchPerformanceInfoCollectionError: action.payload.error,
                collectingBranchPerformanceInfo: false
            };

        case actionTypes.COLLECT_LOW_PERFORMING_BORROWERS_REQUEST:
            return {
                ...state,
                lowPerformingBorrowers: undefined,
                lowPerformingBorrowersCollectionError: undefined,
                collectingLowPerformingBorrowers: true
            };
        case actionTypes.COLLECT_LOW_PERFORMING_BORROWERS_SUCCESS:
            return {
                ...state,
                lowPerformingBorrowers: action.payload,
                collectingLowPerformingBorrowers: false
            };
        case actionTypes.COLLECT_LOW_PERFORMING_BORROWERS_FAILURE:
            return {
                ...state,
                lowPerformingBorrowersCollectionError: action.payload.error,
                collectingLowPerformingBorrowers: false
            };

        case actionTypes.COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND_REQUEST:
            return {
                ...state,
                activeBatteryCounts: undefined,
                activeBatteryCountsCollectionError: undefined,
                collectingActiveBatteryCounts: true
            };
        case actionTypes.COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND_SUCCESS:
            return {
                ...state,
                activeBatteryCounts: action.payload,
                collectingActiveBatteryCounts: false
            };
        case actionTypes.COLLECT_OVERALL_BATTERY_ACTIVITIES_TREND_FAILURE:
            return {
                ...state,
                activeBatteryCountsCollectionError: action.payload.error,
                collectingActiveBatteryCounts: false
            };

        case actionTypes.COLLECT_INACTIVE_BATTERIES_REQUEST:
            return {
                ...state,
                inactiveBatteriesInfo: undefined,
                errorMessageForInactiveBatteryInfoCollection: undefined,
                collectingInactiveBatteriesInfo: true
            };
        case actionTypes.COLLECT_INACTIVE_BATTERIES_SUCCESS:
            return {
                ...state,
                inactiveBatteriesInfo: action.payload,
                collectingInactiveBatteriesInfo: false
            };
        case actionTypes.COLLECT_INACTIVE_BATTERIES_FAILURE:
            return {
                ...state,
                collectingInactiveBatteriesInfo: false,
                errorMessageForInactiveBatteryInfoCollection: action.payload.error
            };

        case actionTypes.COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY_REQUEST:
            return {
                ...state,
                activitiesOfTheSB: undefined,
                errorMessageForSBActivityCollection: undefined,
                collectingSBActivityCollection: true
            };
        case actionTypes.COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY_SUCCESS:
            return {
                ...state,
                activitiesOfTheSB: action.payload,
                collectingSBActivityCollection: false
            };
        case actionTypes.COLLECT_ACTIVITY_TREND_OF_A_SMART_BATTERY_FAILURE:
            return {
                ...state,
                collectingSBActivityCollection: false,
                errorMessageForSBActivityCollection: action.payload.error
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
