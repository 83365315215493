import React, {useEffect, useState, useRef} from 'react';
import {connect} from "react-redux";
import {actions} from "../../../actions";
import {actions as commonActions} from "../../../../commonReduxActions/actions";
import {makeStyles} from "@material-ui/styles";
import {withRoles} from "../../../../../router/SecuredRoute";
import {useMediaQuery} from "react-responsive";
import EastIcon from '@mui/icons-material/East';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableContainer from '@mui/material/TableContainer';
import moment from "moment";
import {SolTypography} from "../../../../../components";
import {searchResultReducer} from "../../../reducer";
import {showNotifications} from "../../../../../utils/notification";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const LastTransactions = ({transactionData, ...props}) => {
    const [garages, setGarages] = useState(undefined);
    const [language, setLanguage] = useState(null);
    const [paymentFileDownload, setPaymentFileDownload] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const {
        COLLECT_PAYMENT_DETAILS
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
    }, []);

    const getDriverName = (driverDetails) => {
        if(driverDetails){
            return driverDetails.split("#")[5]
        }
        return ''
    }

    const downloadMoneyReceipt = (data) => {
        COLLECT_PAYMENT_DETAILS(data.topup_guid)
    }

    const downloadPaymentReceipt = (data) => {
        setPaymentFileDownload(true)
        COLLECT_PAYMENT_DETAILS(data.topup_guid)
    }

    // TODO: We should show an in progress modal here while downloading the document

    useEffect(() => {
        if (props.topUpDetails) {
            const link = document.createElement('a');

            link.href = !paymentFileDownload? props.topUpDetails?.payment_document : props.topUpDetails?.invoice_payment_document;
            setPaymentFileDownload(false)
            link.setAttribute(
                'download',
                props.topUpDetails?.topup_guid,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();
            link.parentNode.removeChild(link);

        }
    }, [props.topUpDetails])

    useEffect(() => {
        if (props.errorMessageForTopUpDetails) {
            showNotifications('error', props.errorMessageForTopUpDetails)
        }
    }, [props.errorMessageForTopUpDetails])

    // Table header styling
    const tableHeaderLeftAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        paddingLeft: "0",
        textAlign: "left",
        boxSizing: "borderBox"
    }

    const tableHeaderRightAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        paddingLRight: "0px",
        textAlign: "right",
        boxSizing: "borderBox"
    }

    // Table cell related styling
    const tableLeftAlignedContentStyling = {
        color: "inherit",
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        paddingLeft: "0",
        textAlign: "left"
    }

    const tableRightAlignedContentStyling = {
        color: "inherit",
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        textAlign: "right"
    }

    return (
        <>
            <div className={isTabletOrMobile? 'row':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}><h5><strong>Last 5 Transactions</strong></h5></div>
            {transactionData && transactionData.length > 0? <>
                {/* TODO: Following [See All] feature will be implemented when activities page has the url param feature and also ev based filter feature */}
                {/*<div className={isTabletOrMobile? 'row':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>*/}
                {/*    <h5 style={{'color': '#F18D00'}}><strong>See All <EastIcon style={{'color': '#F18D00'}} size={'lg'}/></strong></h5>*/}
                {/*</div>*/}
                <div className={isTabletOrMobile? 'row':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                    <TableContainer>
                        <Table bordered={true} aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Date</TableCell>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Description</TableCell>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Type</TableCell>
                                    <TableCell style={tableHeaderRightAlignedColumnStyling}>Deposit (Tk)</TableCell>
                                    <TableCell style={tableHeaderRightAlignedColumnStyling}>Payable (Tk)</TableCell>
                                    <TableCell style={tableHeaderRightAlignedColumnStyling}>Paid (Tk)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactionData.map((transaction) => {
                                    // Following logics have been directly copied from the "Transaction" feature.
                                    // I have just renamed the variables at the my end - Noor Reza, 8th October, 2023 2:44 PM
                                    let bold = false;
                                    let line1 = "";
                                    let line2 = "";
                                    let line3 = "";
                                    let line4 = "";
                                    let lineForMoneyReceipt = "";
                                    let lineForRecieptFiles = ""
                                    let lineForPaymentFiles = ""
                                    if (transaction.activity_for === 'topup' && (transaction.topup_details?.payment_mood === 'bkash')){
                                        line1 = 'bKash from ' + transaction.topup_details?.sender;
                                    }
                                    if (transaction.activity_for === 'topup' && transaction.topup_details?.payment_mood === 'cash'){
                                        line1 = 'Cash received by ' + transaction.topup_details?.recieved_by_person_name;
                                        lineForMoneyReceipt = 'Money Receipt #' + transaction.topup_details?.receipt_reference;
                                        lineForRecieptFiles = transaction?.topup_details?.receipt_file_name;
                                        lineForPaymentFiles = transaction?.topup_details?.receipt_file_name && transaction?.topup_details?.invoice_payment_file_name ? `, ${transaction?.topup_details?.invoice_payment_file_name}` : transaction?.topup_details?.invoice_payment_file_name

                                    }

                                    if (transaction.activity_for === 'payment'){
                                        if (transaction.contract_details?.contract_type === 'lease'){
                                            line1 = 'Lease payment paid';
                                        }else{
                                            line1 = 'Paid rent fee';
                                        }

                                        let due = transaction.amount - (transaction.invoice_details?.payable_amount - transaction.invoice_details?.paid_amount)

                                        if (due > 0){
                                            line2 = 'Partialy paid';
                                        }
                                        line3 = 'Loan contract #' + transaction.contract_id;
                                    }


                                    if (transaction.activity_for === 'payment' && transaction.invoice_details?.invoice_for === 'downpayment'){
                                        line1 = 'Downpayment paid';
                                        line2 = '';
                                        line4 = ''
                                    }


                                    if (transaction.activity_for === 'invoice' && transaction.contract_details?.contract_type === 'lease'){
                                        line1 = 'Payable Tk '+ new Intl.NumberFormat('en-IN').format(transaction.contract_details?.per_installment_payable);
                                        if (transaction.contract_details?.lease_payment_interval === 'weekly'){
                                            line1 += ' per week'
                                        }else{
                                            line1 += ' per month'
                                        }
                                        if(transaction.invoice_details.installment_no){
                                            line2 = 'Installment #'+ transaction.invoice_details.installment_no;
                                        }
                                        line3 = 'Loan contract #' + transaction.contract_id;
                                        // line4 = 'Invoice #' + transaction.invoice_id;

                                    }

                                    if (transaction.activity_for === 'invoice' && transaction.contract_details?.contract_type === 'rent'){
                                        line1 = 'Payable per rent fee Tk '+ new Intl.NumberFormat('en-IN').format(transaction.contract_details?.per_rent_payable);
                                        line2 = 'Rented by # '+ getDriverName(transaction.rent_details?.driver_details);
                                        line3 = 'Loan contract #' + transaction.contract_id;
                                        // line4 = 'Invoice #' + transaction.invoice_id;
                                    }

                                    if (transaction.activity_for === 'invoice' && transaction.invoice_details?.invoice_for === 'downpayment'){
                                        line1 = 'Downpayment payable';
                                        line2 = '';
                                        line4 = ''
                                    }

                                    if (transaction.activity_for === 'carry_forward_deposit'){
                                        line1 = 'Deposit (Carry Forwarded)';
                                        line2 = '';
                                        line4 = '';
                                        bold = true;
                                    }

                                    if (transaction.activity_for === 'carry_forward_payable'){
                                        line1 = 'Payable (Carry Forwarded)';
                                        line2 = '';
                                        line4 = '';
                                        bold = true;
                                    }

                                    let transactionType = null;
                                    if (transaction.activity_for === 'topup' && transaction.topup_details?.payment_mood === 'bkash'){
                                        transactionType = 'Recharge by MFS';
                                    }
                                    if (transaction.activity_for === 'topup' && transaction.topup_details?.payment_mood === 'cash'){
                                        transactionType = 'Recharge by Cash';
                                    }
                                    if (transaction.activity_for === 'invoice' ){
                                        transactionType = 'Invoice';
                                    }
                                    if (transaction.activity_for === 'payment'){
                                        transactionType = 'Payment';
                                    }

                                    return <>
                                        <TableRow>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                {moment(transactionData.sk).format("DD MMM, YYYY hh:mm a")}
                                            </TableCell>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                { line1 && bold && <SolTypography.SubTitle> <strong>{line1}</strong> </SolTypography.SubTitle> }
                                                { line1 && !bold && <SolTypography.SubTitle> {line1} </SolTypography.SubTitle> }
                                                { line2 && <SolTypography.SubTitle> {line2} </SolTypography.SubTitle> }
                                                { lineForMoneyReceipt && <SolTypography.SubTitle >{lineForMoneyReceipt}</SolTypography.SubTitle>}
                                                { <>
                                                    <SolTypography.Text primary onClick={(e) => { downloadMoneyReceipt(transaction) }}
                                                                        style={{ cursor: 'pointer' }}>{lineForRecieptFiles}</SolTypography.Text><SolTypography.Text primary onClick={(e) => { downloadPaymentReceipt(transaction) }}
                                                                                                                                                                       style={{ cursor: 'pointer' }}>{lineForPaymentFiles}</SolTypography.Text>
                                                </>}
                                                { line3 && <SolTypography.SubTitle> {line3} </SolTypography.SubTitle> }
                                                { line4 && <SolTypography.SubTitle> {line4} </SolTypography.SubTitle> }
                                            </TableCell>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                {transactionType}
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                {transaction.activity_for === 'topup' ||  transaction.activity_for === 'carry_forward_deposit'? <>
                                                    <h6><strong>{new Intl.NumberFormat('en-IN').format(transaction.amount.toFixed(2))}</strong></h6>
                                                </>:''}
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                {transaction.activity_for === 'invoice' ||  transaction.activity_for === 'carry_forward_payable'? <>
                                                    <h6><strong>{new Intl.NumberFormat('en-IN').format(transaction.amount.toFixed(2))}</strong></h6>
                                                </>:''}
                                            </TableCell>
                                            <TableCell style={tableRightAlignedContentStyling}>
                                                {transaction.activity_for === 'payment'? <>
                                                    <h6><strong>{new Intl.NumberFormat('en-IN').format(transaction.amount.toFixed(2))}</strong></h6>
                                                </>:''}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>:<>
                <div className={isTabletOrMobile? 'row':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>No transactions available</div>
            </>}
        </>
    );
};

LastTransactions.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        collectingBorrowers: state.commonReducer.collectingBorrowers,
        topUpDetails: state.searchResultReducer.topUpDetails,
        collectingTopUpDetails: state.searchResultReducer.collectingTopUpDetails,
        errorMessageForTopUpDetails: state.searchResultReducer.errorMessageForTopUpDetails
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(LastTransactions));
