import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
    successMessage: undefined,
    errorMessage: undefined,
    successMessagePassword: undefined,
    errorMessagePassword: undefined,
    userInfoSubmitting: undefined,
    roles: undefined,
    errorMessageRelatedToRoleListCollection: undefined,
    userDetails: undefined,
    userCreated: undefined,
    userUpdated: undefined,
    passwordUpdated: undefined,
    successMessageEdit: undefined,
    errorMessageEdit: undefined,
    organisations: undefined,
    errorMessageForOrgCollection: undefined,
    garages: undefined,
    errorMessageForGarageCollection: undefined,
    userList: undefined,
    errorMessageForUserListCollection: undefined,
    statusCode: undefined
};

/*Reducer for User's redux store*/
export const usersReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_USER_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                userList: undefined,
                errorMessageForUserListCollection: undefined
            };
        case actionTypes.GET_USER_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                userList: action.payload
            };
        case actionTypes.GET_USER_LIST_FAILURE:
            return {
                ...state,
                listLoading: false,
                errorMessageForUserListCollection: action.payload.error
            };

        case actionTypes.GET_ORGANISATIONS_REQUEST:
            return {
                ...state,
                orgListLoading: true,
                errorMessageForOrgCollection: undefined,
                organisations: undefined
            };
        case actionTypes.GET_ORGANISATIONS_SUCCESS:
            return {
                ...state,
                orgListLoading: false,
                organisations: action.payload,
            };
        case actionTypes.GET_ORGANISATIONS_FAILURE:
            return {
                ...state,
                orgListLoading: false,
                errorMessageForOrgCollection: action.payload.error
            };

        case actionTypes.GET_ROLES_REQUEST:
            return {
                ...state,
                roles: undefined,
                roleListLoading: true,
                statusCode: undefined,
                errorMessageRelatedToRoleListCollection: undefined
            };
        case actionTypes.GET_ROLES_SUCCESS:
            return {
                ...state,
                roleListLoading: false,
                roles: action.payload
            };
        case actionTypes.GET_ROLES_FAILURE:
            return {
                ...state,
                roleListLoading: false,
                statusCode: action.payload.status,
                errorMessageRelatedToRoleListCollection: action.payload.error
            };

        case actionTypes.GET_GARAGES_REQUEST:
            return {
                ...state,
                garageListLoading: true,
                garages: undefined,
                errorMessageForGarageCollection: undefined
            };
        case actionTypes.GET_GARAGES_SUCCESS:
            return {
                ...state,
                garageListLoading: false,
                garages: action.payload
            };
        case actionTypes.GET_GARAGES_FAILURE:
            return {
                ...state,
                garageListLoading: false,
                errorMessageForGarageCollection: action.payload.error
            };

            //post Data
        case actionTypes.CREATE_USER_REQUEST:
            return {
                ...state,
                userCreated: undefined,
                userInfoSubmitting: true
            };
        case actionTypes.CREATE_USER_SUCCESS:
            return {
                ...state,
                userCreated: requestCycle.success,
                successMessage: action.payload,
                userInfoSubmitting: false
            };
        case actionTypes.CREATE_USER_FAILURE:
            return {
                ...state,
                userCreated: requestCycle.failed,
                errorMessage: action.payload.error,
                userInfoSubmitting: false
            };

        case actionTypes.EDIT_USER_REQUEST:
            return {
                ...state,
                userUpdated: undefined,
                userInfoSubmitting: true
            };
        case actionTypes.EDIT_USER_SUCCESS:
            return {
                ...state,
                userUpdated: requestCycle.success,
                successMessageEdit: action.payload,
                userInfoSubmitting: false
            };
        case actionTypes.EDIT_USER_FAILURE:
            return {
                ...state,
                userUpdated: requestCycle.failed,
                errorMessageEdit: action.payload.error,
                userInfoSubmitting: false
            };

        case actionTypes.UPDATE_PASSWORD_REQUEST:
            return {
                ...state,
                passwordUpdated: undefined,
                userInfoSubmitting: true
            };
        case actionTypes.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordUpdated: requestCycle.success,
                successMessagePassword: action.payload,
                userInfoSubmitting: false
            };
        case actionTypes.UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                passwordUpdated: requestCycle.failed,
                errorMessagePassword: action.payload.error,
                userInfoSubmitting: false
            };

        case actionTypes.RETURN_TO_INITIAL_USER_REDUCER_STATE:
            return initialState

        default:
            return state;
    }
};
