export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_DEPOSITS_REQUEST: 'COLLECT_DEPOSITS_REQUEST',
    COLLECT_DEPOSITS_SUCCESS: 'COLLECT_DEPOSITS_SUCCESS',
    COLLECT_DEPOSITS_FAILURE: 'COLLECT_DEPOSITS_FAILURE',

    COLLECT_DEPOSITS_FOR_EXPORT_REQUEST: 'COLLECT_DEPOSITS_FOR_EXPORT_REQUEST',
    COLLECT_DEPOSITS_FOR_EXPORT_SUCCESS: 'COLLECT_DEPOSITS_FOR_EXPORT_SUCCESS',
    COLLECT_DEPOSITS_FOR_EXPORT_FAILURE: 'COLLECT_DEPOSITS_FOR_EXPORT_FAILURE',

    COLLECT_DEPOSITS_DETAILS_REQUEST: 'COLLECT_DEPOSITS_DETAILS_REQUEST',
    COLLECT_DEPOSITS_DETAILS_SUCCESS: 'COLLECT_DEPOSITS_DETAILS_SUCCESS',
    COLLECT_DEPOSITS_DETAILS_FAILURE: 'COLLECT_DEPOSITS_DETAILS_FAILURE',

    COLLECT_GARAGE_LIST_REQUEST: 'COLLECT_GARAGE_LIST_REQUEST',
    COLLECT_GARAGE_LIST_SUCCESS: 'COLLECT_GARAGE_LIST_SUCCESS',
    COLLECT_GARAGE_LIST_FAILURE: 'COLLECT_GARAGE_LIST_FAILURE',

    COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT_REQUEST: 'COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT_REQUEST',
    COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT_SUCCESS: 'COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT_SUCCESS',
    COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT_FAILURE: 'COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT_FAILURE',

    COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT_REQUEST: 'COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT_REQUEST',
    COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT_SUCCESS: 'COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT_SUCCESS',
    COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT_FAILURE: 'COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT_FAILURE',

    COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT_REQUEST: 'COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT_REQUEST',
    COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT_SUCCESS: 'COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT_SUCCESS',
    COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT_FAILURE: 'COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT_FAILURE',

    SAVE_NEW_CASH_DEPOSIT_REQUEST: 'SAVE_NEW_CASH_DEPOSIT_REQUEST',
    SAVE_NEW_CASH_DEPOSIT_SUCCESS: 'SAVE_NEW_CASH_DEPOSIT_SUCCESS',
    SAVE_NEW_CASH_DEPOSIT_FAILURE: 'SAVE_NEW_CASH_DEPOSIT_FAILURE',

    COLLECT_CURRENT_BALANCE_REQUEST: 'COLLECT_CURRENT_BALANCE_REQUEST',
    COLLECT_CURRENT_BALANCE_SUCCESS: 'COLLECT_CURRENT_BALANCE_SUCCESS',
    COLLECT_CURRENT_BALANCE_FAILURE: 'COLLECT_CURRENT_BALANCE_FAILURE',

    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',
    RETURN_TO_INITIAL_STATE_FOR_NEW_CASH_DEPOSIT: 'RETURN_TO_INITIAL_STATE_FOR_NEW_CASH_DEPOSIT'
};
