import qs from "query-string";
// import  {
//     createFSAConverter,
//     dispatchFSAAction
// } from "./createFSAConverter";
import attemptRefresh from "./refreshToken";
import  {
    createFSAConverter,
    dispatchFSAAction
} from "./refreshToken/createFSAConverter";

import {actions} from "../features/auth/actions";
import {CALL_API} from "./actionTypes"
import {getBaseUrl} from "./service/getRooturl";

export const TOKEN_REFRESH_REQUEST = 'TOKEN_REFRESH_REQUEST';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
export const TOKEN_REFRESH_FAILURE = 'TOKEN_REFRESH_FAILURE';

let SLUG="default_url";

const API_ROOT = () => {
    return getBaseUrl(SLUG);
};

const REFRESH_TOKEN_URL = process.env.REACT_APP_TOKEN_REFRESH_URL+'?key='+process.env.REACT_APP_API_KEY;

export default store =>
    next =>
        action => {
            // console.log(action);
            // const access_token = store.getState().auth.access_token;
            const refresh_token = store.getState().auth.refresh_token;
            const callAPI = action[CALL_API];

            if (typeof callAPI === "undefined") {
                return next(action);
            }

            if(callAPI.endpoint === '/logout'){
                return next(actions.LOGOUT());
            }

            const user_type = ["garage_owner", "garage_operator"]

            if (user_type.indexOf(store.getState().auth?.user?.user_type) !== -1 && callAPI.endpoint!== "/users/logout") {
                return store.dispatch(actions.LOGOUT());
            }


            if (callAPI['backend_slug']) {
                SLUG = callAPI['backend_slug'];
            } else {
                SLUG="default_url";
            }

            let { endpoint, method, body, query, headers = {}, id_token } = callAPI;
            const { types } = callAPI;



            const token = store.getState().auth.access_token;


            if (typeof token === "string") {
                headers.Authorization = `Bearer ${token}`;
            }

            if(id_token){
                headers.id_token = id_token;
            }

            if(callAPI.endpoint === '/users/token/refresh') {
                headers.Authorization = `Bearer ${refresh_token}`;
            }

            const actionWith = data => {
                const finalAction = Object.assign({}, action, data);
                delete finalAction[CALL_API];
                return finalAction;
            };

            const [requestType, successType, failureType] = types;
            next(actionWith({ type: requestType }));
            let formData = new FormData();
            let bodyClone = body;
            bodyClone = JSON.stringify(body);

            if (body) {
                // body = JSON.stringify(body);
                for ( var key in body ) {
                    let value = body[key];
                    if (typeof(value) === 'object') {
                        // alert('aa')
                        // value = JSON.stringify(value) // nested json object conver to json string
                    }
                    formData.append(key, value);
                }
            } else {
                formData = undefined
            }

            // Display the key/value pairs
            // for (var pair of formData.entries()) {
            // console.log(pair[0]+ ', ' + pair[1]);
            // }

            let querystring = "";

            if (query) {
                querystring += "?" + qs.stringify(query);
            }
            if (callAPI['formDataFlag'])
            {
                // headers["Content-Type"] = "multipart/form-data";
            }else {
                headers["Content-Type"] = "application/json"
            }
            //
            // if (callAPI.endpoint === '/refresh') {
            //     headers["Content-Type"] = "application/json";
            //     body = {
            //         "grant_type": "refresh_token",
            //         "refresh_token": refresh_token
            //     };
            //     bodyClone = JSON.stringify(body);
            // }
            //
            //
            // return (
            //     fetch(`${API_ROOT()}${endpoint}${querystring}`, {
            //         method: method,
            //         body: callAPI['formDataFlag']? formData: bodyClone ,
            //         headers: headers,
            //         credentials: "same-origin"
            //     })
            //         // Reads the body stream into Flux Standard Action
            //         .then(createFSAConverter(successType, failureType))
            //         .then(
            //             dispatchFSAAction(next)
            //         )
            // );

            return (
                fetch(`${API_ROOT()}${endpoint}${querystring}`, {
                    method: method,
                    body: callAPI['formDataFlag']? formData: bodyClone ,
                    headers: headers,
                    credentials: "same-origin"
                })
                    // Reads the body stream into Flux Standard Action
                    .then(createFSAConverter(successType, failureType))
                    .then(
                        attemptRefresh({
                            action,
                            failure: actions.LOGOUT,
                            next,
                            refreshActionCreator: actions.REFRESH_TOKEN,
                            // setAccessTokenActionCreator: actions.SET_JUST_REFRESHED_TOKEN,
                            store,
                            token
                        })
                    )
            );
        };
