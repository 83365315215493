import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import OrganizationList from "./views/organizationList/organizationList";
import CreateOrganization from "./views/newOrganization/CreateOrganization";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";


export const OrganizationsRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/organizations"}
                to={"/organizations/list"}
            />

            {/* Routes to User List*/}
            <SecuredRoute
                path={"/organizations/list"}
                component={OrganizationList}
            />

            <SecuredRoute
                path={"/organizations/create"}
                component={CreateOrganization}
                externalUserPermitted={false}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
