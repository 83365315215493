import { Redirect, Switch } from "react-router-dom";
import React from "react";
import SecuredRoute from "../../router/SecuredRoute";
import { getServiceName } from "../../utils/roleRelatedValues/serviceNames";
import { getFeatureName } from "../../utils/roleRelatedValues/featureNames";
import { getActionName } from "../../utils/roleRelatedValues/actionNames";
import Invoices from "./views/invoices";


export const InvoicesRouter = (props) => {

    return (
        <Switch>
            <Redirect
                exact={"true"}
                from={"/invoices"}
                to={"/invoices/list"}
            />

            <SecuredRoute
                path={"/invoices/list"}
                component={Invoices}
                service={getServiceName('transactionService')}
                feature={getFeatureName('invoiceFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
