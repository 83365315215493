import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import { Toolbar } from "./components";
import Plot from "react-plotly.js";
import { batteryLogsColumns, data, DataTableContainer, layout } from "../utils";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {DayPickerRangeController} from "react-dates";
import {GenericModal} from "../../../components/genericModal/genericModal";
import {SolTypography} from "../../../components/utilityComponents/SOlTypography";
import moment from "moment";
import Table from 'react-bootstrap/Table';

import {Badge, Breadcrumb, Button, Form, Spinner, OverlayTrigger, Popover} from "react-bootstrap";
import Select from "react-select";
import { SolInput } from "../../../components/SolStyledComponents/components";
import DataTable from "../../../components/dataTable/DataTable";
import { LoadingSpinner } from "../../../components/LoadingSpinnerForDataTable";
import batteryLogs from "../../batteryLog/views/batteryLogs/batteryLogs";
import { connect } from "react-redux";
import { actions } from "../actions";
import { withRoles } from "../../../router/SecuredRoute";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import {convertToAnotherLang} from "../../../utils/convertLanguageOfNumbersWithCharacter";
import ListGroup from "react-bootstrap/ListGroup";
const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});
const BatteryGraph = props => {

    // const classes = useStylesIconButton();
    const [showFilter, setShowFilter] = useState(false);
    const [dateRange, setDateRange] = useState('');
    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [language, setLanguage] = useState("EN");
    const [authorization, setAuthorization] = useState(null);
    const [voltageData, setVoltageData] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [lifetimeDischargeData, setLifetimeDischargeData] = useState(null);
    const [representData, setRepresentData] = useState(false);

    const [showBatteryDetailsModal, setShowBatteryDetailsModal] = useState(false);
    const [dongleSerial, setDongleSerial] = useState('');
    const [nameplateCapacity, setNameplateCapacity] = useState('');
    const [batteryNominalVoltage, setBatteryNominalVoltage] = useState('');
    const [referenceCapacity, setReferenceCapacity] = useState('');
    const [dischargeDelivery, setDischargeDelivery] = useState('');
    const [capacityLevel, setCapacityLevel] = useState('');
    const [batterySupplier, setBatterySupplier] = useState('');
    const [bmsVendor, setBmsVendor] = useState('');
    const [bmsModel, setBmsModel] = useState('');
    const [warrantyStartDate, setWarrantyStartDate] = useState('');
    const [warrantyEndDate, setWarrantyEndDate] = useState('');

    const location = useLocation();
    const {
        GET_BATTERY_LOGS,
        GET_BATTERY_LIST,
        RETURN_TO_INITIAL_STATE
    } = props

    const codeMap = {
        voltage: {
            code: "ID_0002",
            formatting_data: {
                "name": "Battery Voltage (V)",
                "line": {
                    "color": "rgb(35,1,244)",
                    "width": 3
                },
                "type": "scatter"
            }
        },
        current:  {
            code: "ID_0003",
            formatting_data: {
                "name": "Battery Current (A)",
                "line": {
                    "color": "rgb(0,159,16)",
                    "width": 3
                },
                "type": "scatter"
            }
        },
        lifetime_discharge:  {
            code: "ID_0052",
            formatting_data: {
                "name": "Total discharge over lifetime (Ah_out)",
                "line": {
                    "color": "rgb(244,1,1)",
                    "width": 3
                },
                "type": "scatter"
            }
        },
    }
    const [batteryLogs, setBatteryLogs] = useState(undefined);
    const [batteryLogsForTable, setBatteryLogsForTable] = useState(undefined);

    const [focusedInput, setFocusedInput] = useState('startDate');

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const processGraphData =(data, code_key)=>{
        if (Array.isArray(data) && data.length>0)
        {
            return data.reduce((previousValue, currentValue, currentIndex, array) => {
                previousValue["x"].push(moment(currentValue["generated_at_time"]).add(6, 'hours').format("DD MMM hh:mm a"))
                // We are passing 'null' when there is no value or N/A, is it okay ?
                let yAxisValue = currentValue[codeMap[code_key]["code"]] && currentValue[codeMap[code_key]["code"]] !== 'N/A'? (currentValue[codeMap[code_key]["code"]]/1000).toFixed(1):currentValue[codeMap[code_key]["code"]]
                previousValue["y"].push(yAxisValue)
                previousValue= {
                    ...previousValue,
                    ...codeMap[code_key]["formatting_data"]
                }
                // TODO: Need to know the source of the "name" key
                previousValue["name"] = ""
                return previousValue
            }, {x: [], y: []})
        }{
            return []
        }

    }


    useEffect(()=>{
        if (Array.isArray(props.batteryLogs) && props.batteryLogs.length >= -1){
            let reversedBatteryLogs = props.batteryLogs.reverse()
            setBatteryLogs(reversedBatteryLogs) // We are reversing data again while initializing DataTable
            setVoltageData(processGraphData(reversedBatteryLogs, "voltage"))
            setCurrentData(processGraphData(reversedBatteryLogs, "current"))
            setLifetimeDischargeData(processGraphData(reversedBatteryLogs, "lifetime_discharge"))
        }
    },[props.batteryLogs])

    useEffect(()=>{
        if ((batteryLogsForTable && batteryLogsForTable.length >= -1) && (voltageData && voltageData.length >= -1) && (currentData && currentData.length >= -1) && (lifetimeDischargeData && lifetimeDischargeData.length >= -1)) {
            setRepresentData(true)
        }
    },[batteryLogsForTable, voltageData, currentData, lifetimeDischargeData])

    useEffect(()=>{
        if ((batteryLogs && batteryLogs.length >= -1)) {
            let updatedBatteryLogs = batteryLogs.reverse()
            setBatteryLogsForTable(updatedBatteryLogs)
        }
    },[batteryLogs])


    const batteryLogsColumns = [
        // {
        //     field: 'battery_sl',
        //     title: language === 'EN'? 'Battery Serial':'ব্যাটারী সিরিয়াল',
        //     emptyValue: ()=>{
        //         return "-"
        //     },
        //     cellStyle: {
        //         paddingLeft: '0',
        //         textAlign: 'left'
        //     },
        //     headerStyle: {
        //         textAlign: 'left',
        //         paddingLeft: '0'
        //     }
        // },
        {
            field: 'generated_at_time',
            title: language === 'EN'? 'Timestamp':'সময়',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '0'
            },
            render: (rowData) => {
                return moment(rowData.generated_at_time).add(6, 'hours').format("DD MMM, YYYY hh:mm:ss a")
            }
        },
        {
            field: 'ID_0002',
            title: language === 'EN'? 'Battery voltage(V)':'ব্যাটারী ক্যাপাসিটি (Ah)',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return rowData.ID_0002 && rowData.ID_0002 !== 'N/A'? (rowData.ID_0002/1000).toFixed(1):rowData.ID_0002
            }
        },
        {
            field: 'ID_0003',
            title: language === 'EN'? 'Battery current (A)':'ব্যাটারী ভোল্টেজ (v)',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return rowData.ID_0003 && rowData.ID_0003 !== 'N/A'? (rowData.ID_0003/1000).toFixed(1):rowData.ID_0003
            }
        },
        {
            field: 'ID_0052',
            title: language === 'EN'? 'Lifetime discharge (Ah_out)':'ব্যাটারী সরবরাহকারী',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return rowData.ID_0052 && rowData.ID_0052 !== 'N/A'? (rowData.ID_0052/1000).toFixed(1):rowData.ID_0052
            }
        },
        {
            field: 'ID_0052',
            title: language === 'EN'? 'Approx. km run':'ব্যাটারী সরবরাহকারী',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return rowData.ID_0052 && rowData.ID_0052 !== 'N/A'? (rowData.ID_0052/1000).toFixed(1):rowData.ID_0052
            }
        }

    ]

    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const filterList=()=>{
        if (start && end){
            setVoltageData(null)
            setCurrentData(null)
            setLifetimeDischargeData(null)
            let formattedStartDate = start.format('YYYY-MM-DD')
            let formattedEndDate = end.format('YYYY-MM-DD')
            let from_date = language === 'EN'? formattedStartDate:convertToAnotherLang(formattedStartDate)
            let to_date = language === 'EN'? formattedEndDate:convertToAnotherLang(formattedEndDate)
            GET_BATTERY_LOGS({
                log_type: "warranty_log",
                battery_serial_number: props.match.params.battery_serial_number,
                from_date: from_date,
                to_date: to_date
            })
        }else {
            toast.error("Please select date range first ")
        }
    }

    const [previousStart, setPreviousStartDate] = useState(null);
    const [previousEnd, setPreviousEndDate] = useState(null);
    const onDatesChange = ({startDate, endDate}) => {
        if (previousEnd && previousEnd.isSame(endDate)) {
            setPreviousStartDate(endDate)
            setPreviousEndDate(endDate)

            setStartDate(endDate)
            setEndDate(endDate)
        } else {
            setPreviousStartDate(startDate)
            setPreviousEndDate(endDate)

            setStartDate(startDate)
            setEndDate(endDate)
        }
    }

    const collectLastTenDaysData = () => {
        let todaysDate = moment().format("YYYY-MM-DD")
        let tenDaysEarlier = moment(todaysDate).subtract(9, 'd').format("YYYY-MM-DD")
        setStartDate(moment(tenDaysEarlier))
        setEndDate(moment(todaysDate))
        let from_date = props.language === 'EN'? tenDaysEarlier:convertToAnotherLang(tenDaysEarlier)
        let to_date = props.language === 'EN'? todaysDate:convertToAnotherLang(todaysDate)
        GET_BATTERY_LOGS({
            log_type: "warranty_log",
            battery_serial_number: props.match.params.battery_serial_number,
            from_date: from_date,
            to_date: to_date
        })
    }

    const resetFilter=()=>{
        RETURN_TO_INITIAL_STATE()
        setRepresentData(false)
        collectLastTenDaysData()
    }

    useEffect(()=>{
        setLanguage("EN")
        setAuthorization(props.authorization)
        collectLastTenDaysData()
        GET_BATTERY_LIST({'page': 1, 'serial_number': props.match.params.battery_serial_number})
        return ()=>{
            RETURN_TO_INITIAL_STATE()
        }

    },[])

    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    },[start, end])

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    startDate={start}
                    endDate={end}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    )

    const viewBatteryDetails = () => {
        setShowBatteryDetailsModal(true)
    }

    useEffect(() => {
        if (props.batteryList) {
            const batteryData = props.batteryList.results
            if (batteryData.length > 0) {
                setDongleSerial(batteryData[0].dongle_serial_number)
                setNameplateCapacity(batteryData[0].nameplate_capacity)
                setBatteryNominalVoltage(batteryData[0].nameplate_voltage)
                setReferenceCapacity(batteryData[0].reference_capacity)
                setDischargeDelivery(batteryData[0].guaranteed_discharge_delivery)
                setCapacityLevel(batteryData[0].claim_reference_capacity)
                setBatterySupplier(batteryData[0].battery_vendor)
                setBmsVendor(batteryData[0].bms_vendor)
                setBmsModel(batteryData[0].bms_model)
                setWarrantyStartDate(batteryData[0].warranty_start_date? moment(batteryData[0].warranty_start_date).format('DD MMMM, YYYY'): '')
                setWarrantyEndDate(batteryData[0].warranty_end_date? moment(batteryData[0].warranty_end_date).format('DD MMMM, YYYY'): '')
            }
        }
    },[props.batteryList])

    const hideBatteryDetailsModal = () => {
        setShowBatteryDetailsModal(false)
    }


    const renderBatteryDetailsModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={true}
                footerButtonSize={'md'}
                noSubmitButton={true}
                footerCloseButtonText={language === 'EN'? 'Close':'বন্ধ করুন'}
                hideModal={hideBatteryDetailsModal}
                centered={true}
                modal={showBatteryDetailsModal}
                title={<>{language === 'EN'? 'Details of the battery ':'ব্যাটারী '}<SolTypography.Text
                    primary>{props.match.params.battery_serial_number}</SolTypography.Text>{language !== 'EN'? ' এর বিস্তারিত তথ্য':null}</>}>
                <div className={'row'}>
                    <div className={'col-lg-6'}>
                        <ListGroup>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>Battery Serial Number:</b></span><span className={'float-right'}>{props.match.params.battery_serial_number}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>Dongle Serial Number:</b></span><span className={'float-right'}>{dongleSerial? dongleSerial:'N/A'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>BMS vendor:</b></span><span className={'float-right'}>{bmsVendor? bmsVendor:'N/A'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>BMS model:</b></span><span className={'float-right'}>{bmsModel? bmsModel:'N/A'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>Battery supplier:</b></span><span className={'float-right'}>{batterySupplier? batterySupplier:'N/A'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>Warranty start date:</b></span><span className={'float-right'}>{warrantyStartDate? warrantyStartDate:'N/A'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>Warranty end date:</b></span><span className={'float-right'}>{warrantyEndDate? warrantyEndDate:'N/A'}</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className={'col-lg-6'}>
                        <ListGroup>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>Battery nominal voltage (V):</b></span><span className={'float-right'}>{batteryNominalVoltage? batteryNominalVoltage:'N/A'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>Nameplate capacity (Ah):</b></span><span className={'float-right'}>{batteryNominalVoltage? batteryNominalVoltage:'N/A'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>Reference capacity by supplier:</b></span><span className={'float-right'}>{referenceCapacity? referenceCapacity:'N/A'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>Claim event reference capacity level(%):</b></span><span className={'float-right'}>{capacityLevel? capacityLevel:'N/A'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><b>Guaranteed discharge delivery:</b></span><span className={'float-right'}>{dischargeDelivery? dischargeDelivery:'N/A'}</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
            </GenericModal>
        </>
    }


    return (
        <div>
            <ContentWrapper isLoading={false} showBackButton={false} showCardHeader={false}>
                <h1><b>Battery graph of - {props.match.params.battery_serial_number}</b></h1>
                <div className={'mt-5 row'}>
                    <div className={'col-md-12'}>
                        <span><img src={require('../../../utils/asset/battery.svg')} alt={'battery'}/></span>
                        <span className={'ml-1 mr-5'} style={{minWidth: "100px", display: "inline-block" , padding: "0 .5rem", borderRadius: '7px'}}> <b style={{fontSize: "1rem"}}>Battery Details:</b></span>
                        <span className={'ml-5 mr-5'} style={{minWidth: "150px", display: "inline-block", background: "#D9D9D9" , padding: ".1rem .5rem", borderRadius: '7px'}}> <strong style={{fontSize: "1rem"}}>Capacity:</strong> {props.detailsInfoLoading? <>...</>:<>{nameplateCapacity? nameplateCapacity + ' Ah':'N/A'}</>}</span>
                        <span className={'ml-5 mr-5'} style={{minWidth: "350px", display: "inline-block", background: "#D9D9D9" , padding: ".1rem .5rem", borderRadius: '7px'}}> <strong style={{fontSize: "1rem"}}>Guaranteed discharge delivery:</strong> {props.detailsInfoLoading? <>...</>:<>{dischargeDelivery? dischargeDelivery + ' Ah':'N/A'}</>}</span>
                        <span className={'ml-5 mr-5'} style={{minWidth: "250px", display: "inline-block", background: "#D9D9D9" , padding: ".1rem .5rem", borderRadius: '7px'}}> <strong style={{fontSize: "1rem"}}>Warranty end date:</strong> {props.detailsInfoLoading? <>...</>:<>{warrantyEndDate? warrantyEndDate:'N/A'}</>}</span>
                        <br/> <span className={'mt-5'}><button style={{marginTop: "10px"}} className={'btn btn-sm btn-warning'} onClick={viewBatteryDetails} disabled={props.listLoading}>View more</button></span>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>

                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>Filter By Date</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    {/*<IconButton onClick={filterToggle} classes={classes}>*/}
                                    {/*  <FilterListIcon color="disabled" fontSize="large"/>*/}
                                    {/*</IconButton>*/}

                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>


                <>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <Form>
                                <div className={"row g-3"}>
                                    <div className={'col-md-6'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Date range':'তারিখের সীমা'}<span className="required text-danger">*</span></Form.Label>
                                            </div>
                                            <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                            overlay={popover}>
                                                <SolInput
                                                    name={'dateRangePickerInput'}
                                                    readOnly={true}
                                                    value={dateRange}
                                                    placeholder={language === 'EN'? 'Select a date range':'তারিখের সীমা বাছাই করুন'}
                                                />
                                            </OverlayTrigger>
                                            {/*{requiredErrorMessage? <><div className="text-danger">*/}
                                            {/*  <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}*/}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <Button variant="warning" size={"sm"} type="button"
                                                onClick={filterList}
                                                disabled={props.listLoading || props.detailsInfoLoading}
                                        >
                                            {language === "EN" ? "Apply filter" : "তালিকায় খুঁজুন"}
                                        </Button>
                                        <Button variant="outline-dark" size={"sm"} onClick={() => {
                                            resetFilter();
                                        }} className={"ml-3"} disabled={props.listLoading || props.detailsInfoLoading}>
                                            {language === "EN" ? "Reset filter" : "প্রাথমিক অবস্থায় ফিরে যান"}
                                        </Button>
                                    </div>
                                </div>
                                {/*{requiredErrorMessage ? <div className="text-danger">*/}
                                {/*  <i className="flaticon-warning kt-font-brand"></i>*/}
                                {/*  &nbsp;&nbsp;{generalRequiredMessage}</div> : null}*/}
                            </Form>
                        </div>
                    </div>
                    <hr />
                </>

                {(!props.listLoading || representData) && batteryLogsForTable && voltageData && lifetimeDischargeData && currentData? <>
                    {voltageData? <><br/>
                        <br/>
                        <Plot
                            data={[voltageData]}
                            layout={ { ...layout, "yaxis": {
                                    "title": {
                                        "text": "Battery Voltage [V]"
                                    },
                                }, }}
                            style={{width: '100%'}}
                        /></>:<>
                        <br/>
                        <br/>
                        <Plot
                            data={[{}]}
                            layout={ { ...layout, "yaxis": {
                                    "title": {
                                        "text": "Battery Voltage [V]"
                                    },
                                }, }}
                            style={{width: '100%'}}
                        />
                    </>}
                    {currentData? <>
                        <br/>
                        <br/>
                        <br/>
                        <Plot
                            data={[currentData]}
                            layout={ { ...layout, "yaxis": {
                                    "title": {
                                        "text": "Battery Current [A]"
                                    },
                                }, }}
                            style={{width: '100%'}}
                        />
                    </>:<>
                        <br/>
                        <br/>
                        <br/>
                        <Plot
                            data={[{}]}
                            layout={ { ...layout, "yaxis": {
                                    "title": {
                                        "text": "Battery Current [A]"
                                    },
                                }, }}
                            style={{width: '100%'}}
                        />
                    </>}
                    {lifetimeDischargeData?<>
                        <br/>
                        <br/>
                        <br/>
                        <Plot
                            data={[lifetimeDischargeData]}
                            layout={{ ...layout, "yaxis": {
                                    "title": {
                                        "text": "Total discharge over lifetime [Ah_out]"
                                    },
                                }, }}
                            style={{width: '100%'}}
                        />
                    </>:<>
                        <br/>
                        <br/>
                        <br/>
                        <Plot
                            data={[{}]}
                            layout={ { ...layout, "yaxis": {
                                    "title": {
                                        "text": "Battery Current [A]"
                                    },
                                }, }}
                            style={{width: '100%'}}
                        />
                    </>}
                    <br/>
                    <br/>
                    {batteryLogsForTable.length > 0? <>
                        <DataTableContainer>
                            <DataTable
                                language={language}
                                columns={batteryLogsColumns}
                                data={batteryLogsForTable? batteryLogsForTable:[]}
                                showToolbar={false}
                                asyncPagination={false}
                                pagination={true}
                                pageSize={5}
                                isLoading={props.tableLoading}
                            />
                        </DataTableContainer>
                    </>:null}
                </>:<LoadingSpinner loadingSubText={language === 'EN'? 'Generating battery graphs ..': 'ব্যাটারীর গ্রাফ প্রস্তুত হচ্ছে'} language={language}/>}

                {renderBatteryDetailsModal()}
            </ContentWrapper>

        </div>
    );
};

BatteryGraph.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.batteryReducer.isLoading,
        tableLoading: state.batteryReducer.tableLoading,
        listLoading: state.batteryReducer.listLoading,
        iconLoading: state.batteryReducer.iconLoading,
        batteryLogs: state.batteryReducer.batteryLogs,
        batteryList: state.batteryReducer.batteryList,
        detailsInfoLoading: state.batteryReducer.detailsInfoLoading
    }
}

export default connect(mapStateToProps, actions)(withRoles(BatteryGraph));
