import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_HISTORICAL_DATA: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/historical_data`, filter),
            method: 'GET',
            backend_slug: 'battery_management',
            types: [
                actionTypes.GET_HISTORICAL_DATA_REQUEST,
                actionTypes.GET_HISTORICAL_DATA_SUCCESS,
                actionTypes.GET_HISTORICAL_DATA_FAILURE
            ]
        }
    }),

    COLLECT_CODE_DESCRIPTION: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/mpower_code`, filter),
            method: 'GET',
            backend_slug: 'battery_management',
            types: [
                actionTypes.COLLECT_CODE_DESCRIPTION_REQUEST,
                actionTypes.COLLECT_CODE_DESCRIPTION_SUCCESS,
                actionTypes.COLLECT_CODE_DESCRIPTION_FAILURE
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
