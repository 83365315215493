import moment from "moment";

class DateTimeFormat {
    constructor(dateString) {
        if (moment.utc(dateString).isValid())
        {
            this.localDisplayTime = moment.utc(dateString).local().format('D MMM, YYYY h:mm a');
            this.displayTime = moment.utc(dateString).format('D MMM, YYYY LT');
            this.localDisplayTimeChart = moment.utc(dateString).local().format('D MMM');
            this.localDisplayTimeWithSeconds = moment.utc(dateString).local().format('D MMM, YYYY h:mm:ss a');
            this.localDisplayTimeForNaming = moment.utc(dateString).local().format('D-MMM-YYYY-hh-mm-a');
            this.localDisplayWithoutTime = moment.utc(dateString).local().format('D MMM, YYYY');
            this.utcDisplayTime = moment.utc(dateString).local().format('D MMM, YYYY LT');
        } else {
            this.localDisplayTime = false;
            this.utcDisplayTime = false;
        }
    }
}

export default DateTimeFormat;



export const calculateTimeRemaining= (dateString)=> {
    // Parse the input date string
    const inputDate = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = inputDate - currentDate;

    // Calculate the number of years, months, and days
    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const years = Math.floor(timeDifference / (365.25 * millisecondsInDay));
    const months = Math.floor((timeDifference % (365.25 * millisecondsInDay)) / (30.44 * millisecondsInDay));
    const days = Math.floor((timeDifference % (30.44 * millisecondsInDay)) / millisecondsInDay);

    // Create the output string
    let output = "";
    if (years > 0) {
        output += years + (years === 1 ? " Year" : " Years");
    }
    if (months > 0) {
        output += (output.length > 0 ? " " : "") + months + (months === 1 ? " Month" : " Months");
    }
    if (days > 0) {
        output += (output.length > 0 ? " " : "") + days + (days === 1 ? " Day" : " Days");
    }

    return output;
}