import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import DeviceList from "./views/deviceList/deviceList";
import CreateDevice from "./views/newDevice/CreateDevice";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const DevicesRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the device if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/devices"}
                to={"/devices/list"}
            />

            {/* Routes to Devices List*/}
            <SecuredRoute
                path={"/devices/list"}
                component={DeviceList}
                service={getServiceName('deviceService')}
                feature={getFeatureName('deviceFeature')}
                action={getActionName('listAction')}
            />

            <SecuredRoute
                path={"/devices/create"}
                component={CreateDevice}
                service={getServiceName('deviceService')}
                feature={getFeatureName('deviceFeature')}
                action={getActionName('createAction')}
            />

            {/*<SecuredRoute*/}
            {/*    path={"/devices/edit/:device_id"}*/}
            {/*    component={CreateDevice}*/}
            {/*/>*/}

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
