import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    financiers: undefined,
    errorMessageForFinanciers: undefined,
    branches: undefined,
    errorMessageForBranchCollection: undefined,
    divisions: undefined,
    districts: undefined,
    subDistricts: undefined,
    zoneAreas: undefined,
    orgCreated: undefined,
    errorMessageForOrgCreation: undefined,
    orgEdited: undefined,
    errorMessageForOrgEdit: undefined
};

/*Reducer for Loan's redux store*/
export const financierReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.COLLECT_FINANCIERS_REQUEST:
            return {
                ...state,
                financiers: undefined,
                errorMessageForFinanciers: undefined,
                collectingFinanciers: true
            };
        case actionTypes.COLLECT_FINANCIERS_SUCCESS:
            return {
                ...state,
                financiers: action.payload,
                collectingFinanciers: false
            };
        case actionTypes.COLLECT_FINANCIERS_FAILURE:
            return {
                ...state,
                collectingFinanciers: false,
                errorMessageForFinanciers: action.payload.error
            };

        case actionTypes.COLLECT_BRANCHES_OF_A_FINANCIER_REQUEST:
            return {
                ...state,
                branches: undefined,
                errorMessageForBranchCollection: undefined,
                branchCollectionInProgress: true
            };
        case actionTypes.COLLECT_BRANCHES_OF_A_FINANCIER_SUCCESS:
            return {
                ...state,
                branches: action.payload,
                branchCollectionInProgress: false
            };
        case actionTypes.COLLECT_BRANCHES_OF_A_FINANCIER_FAILURE:
            return {
                ...state,
                errorMessageForBranchCollection: action.payload.error,
                branchCollectionInProgress: false
            };

        case actionTypes.COLLECT_DIVISIONS_FOR_FINANCIER_REQUEST:
            return {
                ...state,
                divisions: undefined,
                divisionsCollectionInProgress: true
            };
        case actionTypes.COLLECT_DIVISIONS_FOR_FINANCIER_SUCCESS:
            return {
                ...state,
                divisions: action.payload,
                divisionsCollectionInProgress: false
            };
        case actionTypes.COLLECT_DIVISIONS_FOR_FINANCIER_FAILURE:
            toast.error("Failed to collect divisions for financier!")
            return {
                ...state,
                divisionsCollectionInProgress: false
            };

        case actionTypes.COLLECT_DISTRICTS_FOR_FINANCIER_REQUEST:
            return {
                ...state,
                districts: undefined,
                districtsCollectionInProgress: true
            };
        case actionTypes.COLLECT_DISTRICTS_FOR_FINANCIER_SUCCESS:
            return {
                ...state,
                districts: action.payload,
                districtsCollectionInProgress: false
            };
        case actionTypes.COLLECT_DISTRICTS_FOR_FINANCIER_FAILURE:
            toast.error("Failed to collect districts for financier!")
            return {
                ...state,
                districtsCollectionInProgress: false
            };

        case actionTypes.COLLECT_SUB_DISTRICTS_FOR_FINANCIER_REQUEST:
            return {
                ...state,
                subDistricts: undefined,
                subDistrictsCollectionInProgress: true
            };
        case actionTypes.COLLECT_SUB_DISTRICTS_FOR_FINANCIER_SUCCESS:
            return {
                ...state,
                subDistricts: action.payload,
                subDistrictsCollectionInProgress: false
            };
        case actionTypes.COLLECT_SUB_DISTRICTS_FOR_FINANCIER_FAILURE:
            toast.error("Failed to collect sub-districts for financier!")
            return {
                ...state,
                subDistrictsCollectionInProgress: false
            };

        case actionTypes.COLLECT_ZONE_AREA_FOR_FINANCIER_REQUEST:
            return {
                ...state,
                zoneAreas: undefined,
                zoneAreaCollectionInProgress: true
            };
        case actionTypes.COLLECT_ZONE_AREA_FOR_FINANCIER_SUCCESS:
            return {
                ...state,
                zoneAreas: action.payload,
                zoneAreaCollectionInProgress: false
            };
        case actionTypes.COLLECT_ZONE_AREA_FOR_FINANCIER_FAILURE:
            toast.error("Failed to collect zone area information of the financier!")
            return {
                ...state,
                zoneAreaCollectionInProgress: false
            };

        case actionTypes.CREATE_NEW_ORGANIZATION_REQUEST:
            return {
                ...state,
                orgCreated: undefined,
                orgCreationInProgress: true
            };
        case actionTypes.CREATE_NEW_ORGANIZATION_SUCCESS:
            return {
                ...state,
                orgCreated: requestCycle.success,
                orgCreationInProgress: false
            };
        case actionTypes.CREATE_NEW_ORGANIZATION_FAILURE:
            return {
                ...state,
                orgCreated: requestCycle.failed,
                orgCreationInProgress: false,
                errorMessageForOrgCreation: action.payload.error
            };

        case actionTypes.EDIT_NEW_ORGANIZATION_REQUEST:
            return {
                ...state,
                orgEdited: undefined,
                orgEditionInProgress: true
            };
        case actionTypes.EDIT_NEW_ORGANIZATION_SUCCESS:
            return {
                ...state,
                orgEdited: requestCycle.success,
                orgEditionInProgress: false
            };
        case actionTypes.EDIT_NEW_ORGANIZATION_FAILURE:
            return {
                ...state,
                orgEdited: requestCycle.failed,
                orgEditionInProgress: false,
                errorMessageForOrgEdit: action.payload.error
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
