import {appEventActionTypes} from "./actionTypes";

const initialState={
    events: undefined,
    errorForEventListCollection: undefined,
    eventsForExport: undefined,
    errorForEventListCollectionForExport: undefined
}

export const appEventReducer =(state = initialState, currentAction)=>{

    switch (currentAction.type){
        case appEventActionTypes.FETCH_EVENT_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                events: undefined,
                errorForEventListCollection: undefined
            }
        case appEventActionTypes.FETCH_EVENT_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                events: currentAction.payload
            }
        case appEventActionTypes.FETCH_EVENT_LIST_FAILURE:
            return {
                ...state,
                listLoading: false,
                errorForEventListCollection: currentAction.payload.error
            }
        case appEventActionTypes.FETCH_EVENT_LIST_FOR_EXPORT_REQUEST:
            return {
                ...state,
                iconLoading: true,
                eventsForExport: undefined,
                errorForEventListCollectionForExport: undefined
            }
        case appEventActionTypes.FETCH_EVENT_LIST_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                iconLoading: false,
                eventsForExport: currentAction.payload,
            }
        case appEventActionTypes.FETCH_EVENT_LIST_FOR_EXPORT_FAILURE:
            return {
                ...state,
                iconLoading: false,
                errorForEventListCollectionForExport: currentAction.payload.error
            }
        case appEventActionTypes.RETURN_TO_INITIAL:
            return initialState
        default:
            return initialState

    }
}
