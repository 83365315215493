export const actionTypes = {

    GET_DRIVER_LIST_REQUEST: 'GET_DRIVER_LIST_REQUEST',
    GET_DRIVER_LIST_SUCCESS: 'GET_DRIVER_LIST_SUCCESS',
    GET_DRIVER_LIST_FAILURE: 'GET_DRIVER_LIST_FAILURE',

    COLLECT_DISTRICT_LIST_REQUEST: 'COLLECT_DISTRICT_LIST_REQUEST',
    COLLECT_DISTRICT_LIST_SUCCESS: 'COLLECT_DISTRICT_LIST_SUCCESS',
    COLLECT_DISTRICT_LIST_FAILURE: 'COLLECT_DISTRICT_LIST_FAILURE',

    COLLECT_UPAZILA_LIST_REQUEST: 'COLLECT_UPAZILA_LIST_REQUEST',
    COLLECT_UPAZILA_LIST_SUCCESS: 'COLLECT_UPAZILA_LIST_SUCCESS',
    COLLECT_UPAZILA_LIST_FAILURE: 'COLLECT_UPAZILA_LIST_FAILURE',

    VERIFY_PHONE_REQUEST: 'VERIFY_PHONE_REQUEST',
    VERIFY_PHONE_SUCCESS: 'VERIFY_PHONE_SUCCESS',
    VERIFY_PHONE_FAILURE: 'VERIFY_PHONE_FAILURE',

    CREATE_DRIVER_REQUEST: 'CREATE_DRIVER_REQUEST',
    CREATE_DRIVER_SUCCESS: 'CREATE_DRIVER_SUCCESS',
    CREATE_DRIVER_FAILURE: 'CREATE_DRIVER_FAILURE',

    EDIT_DRIVER_REQUEST: 'EDIT_DRIVER_REQUEST',
    EDIT_DRIVER_SUCCESS: 'EDIT_DRIVER_SUCCESS',
    EDIT_DRIVER_FAILURE: 'EDIT_DRIVER_FAILURE',


    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',
};
