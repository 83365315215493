import React, {useEffect, useState} from 'react'
// import React, {useEffect, useState, useRef} from 'react'
import PropTypes from 'prop-types'
import {Accordion, Card, Form} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {toBengaliNumber} from "bengali-number";
import "banglanumber-to-englishnumber";


const AccordionForFeatureRepresentation = props => {
    const [actions, setActions] = useState([]);
    const [totalActionCount, setTotalActionCount] = useState(0);
    const [selectedActionCount, setSelectedActionCount] = useState(0);
    const [language, setLanguage] = useState('EN');
    const [allActionsAreSelected, setAllActionsAreSelected] = useState(false);
    const [showYellowBorder, setShowYellowBorder] = useState(false);
    const {toBengaliNumber} = require('bengali-number');
    const convertToEnglishNumber = require('banglanumber-to-englishnumber');
    // TODO: Remove dependency on above packages by using utils/convertLanguageOfNumbersWithCharacter -> convertToAnotherLang function?
    // TODO: Only negative point is that we will need to convert the number to string before passing it to the function
    // TODO: If we implement this, we should do it in other places also. It will remove two packages, which is beneficial.
    // TODO: Above comment is made at: March 23, 2022, 4:45 PM by Noor Faizur Reza, Senior Sr. Engineer
    // const references = useRef([]);

    useEffect(() => {
        if (props.language) {
            setLanguage(props.language)
        }
    }, [props.language]);

    useEffect(() => {
        setActions(props.actions)
        if (language === 'BN') {
            setTotalActionCount(toBengaliNumber(props.actions.length))
        } else {
            setTotalActionCount(props.actions.length)
        }
    }, [props.actions, language]);

    useEffect(() => {
        if (props.selectedActions) {
            setShowYellowBorder(true)
        }
        if (language === 'BN') {
            setSelectedActionCount(toBengaliNumber(props.selectedActions))
        } else {
            setSelectedActionCount(props.selectedActions)
        }
        if (props.selectedActions === props.actions.length) {
            setAllActionsAreSelected(true)
        } else {
            setAllActionsAreSelected(false)
        }
    }, [props.selectedActions, language]);

    const handleFeatureCheck = (event) => {
        if (event.target.checked === true) {
            // props.onCheck(actions, references.current, true)
            setShowYellowBorder(true)
            props.onCheck(actions, true)
            for (let i=0; i<actions.length; i++) {
                actions[i].selected = true
            }
            setAllActionsAreSelected(true)
            if (language === 'BN') {
                setSelectedActionCount(toBengaliNumber(actions.length))
            } else {
                setSelectedActionCount(actions.length)
            }
        } else {
            // props.onCheck(actions, references.current, false)
            setShowYellowBorder(false)
            props.onCheck(actions, false)
            for (let i=0; i<actions.length; i++) {
                actions[i].selected = false
            }
            setAllActionsAreSelected(false)
            setSelectedActionCount(0)
        }
    }

    return <>
        <Accordion defaultActiveKey={props.keepAccordionOpen? props.accordionEventKey: ""} className={(props.keepAccordionOpen || showYellowBorder)? "border border-warning":""}>
            <Card>
                <Card.Header>
                    <span className={'float-left ml-1 mt-1'}>
                        <Form.Group>
                            <Form.Check inline={true} type="checkbox"
                                        label={props.featureName}
                                        // ref={el => (references.current[0] = el)}
                                        checked={allActionsAreSelected}
                                        onChange={(event) => {handleFeatureCheck(event)}}/><br/><small>{language === 'EN'?
                            (selectedActionCount + '/'+ totalActionCount +' actions are selected'):
                            (totalActionCount + ' টি একশনের মাঝে ' + selectedActionCount + ' টি বাছাই করা হয়েছে')}</small>
                        </Form.Group>
                    </span>
                    <Accordion.Toggle as={IconButton} eventKey={props.accordionEventKey} className={'float-right no-padding'}>
                        <FilterListIcon fontSize="small"/>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={props.accordionEventKey}>
                    <Card.Body>
                        {actions.map((action, index) => <Form.Check type="checkbox"
                                                                    // ref={el => (references.current[index + 1] = el)}
                                                                    label={action.label}
                                                                    checked={action.selected}
                                                                    onChange={(event) => {
                                                                        if (event.target.checked === true) {
                                                                            action.selected = true
                                                                            // props.onCheck([action], [references.current[index + 1]], true)
                                                                            props.onCheck([action], true)
                                                                            let allActionsAreSelectedAfterThis = true
                                                                            for (let i=0; i<actions.length; i++) {
                                                                                if (actions[i].selected === false) {
                                                                                    allActionsAreSelectedAfterThis = false
                                                                                    break
                                                                                }
                                                                            }
                                                                            if (allActionsAreSelectedAfterThis) {
                                                                                setAllActionsAreSelected(true)
                                                                            }
                                                                            // We have used .toString() method here to deal with the conversion issue when the language is english
                                                                            // This can be overcome by checking the language
                                                                            let newSelectionCount
                                                                            if (language === 'BN') {
                                                                                newSelectionCount = parseInt(convertToEnglishNumber(selectedActionCount)) + 1
                                                                                setSelectedActionCount(toBengaliNumber(newSelectionCount))
                                                                            } else {
                                                                                newSelectionCount = selectedActionCount + 1
                                                                                setSelectedActionCount(newSelectionCount)
                                                                            }
                                                                            if (!showYellowBorder) {
                                                                                setShowYellowBorder(true)
                                                                            }
                                                                        } else {
                                                                            action.selected = false
                                                                            // props.onCheck([action], [references.current[index + 1]], false)
                                                                            props.onCheck([action], false)
                                                                            if (allActionsAreSelected) {
                                                                                setAllActionsAreSelected(false)
                                                                            }
                                                                            let newSelectionCount
                                                                            if (language === 'BN') {
                                                                                newSelectionCount = parseInt(convertToEnglishNumber(selectedActionCount)) + 1
                                                                                setSelectedActionCount(toBengaliNumber(newSelectionCount))
                                                                            } else {
                                                                                newSelectionCount = selectedActionCount + 1
                                                                                setSelectedActionCount(newSelectionCount)
                                                                            }

                                                                            let atLeastOneActionIsSelectedAfterThis = false
                                                                            for (let i=0; i<actions.length; i++) {
                                                                                if (actions[i].selected === true) {
                                                                                    atLeastOneActionIsSelectedAfterThis = true
                                                                                    break
                                                                                }
                                                                            }
                                                                            if (!atLeastOneActionIsSelectedAfterThis) {
                                                                                setShowYellowBorder(false)
                                                                            }
                                                                        }
                                                                    }}/>)}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>
};

AccordionForFeatureRepresentation.propTypes = {
    featureName: PropTypes.string.isRequired,
    keepAccordionOpen: PropTypes.bool.isRequired,
    accordionEventKey: PropTypes.number.isRequired,
    actions: PropTypes.array.isRequired,
    selectedActions: PropTypes.number,
    onCheck: PropTypes.func,
    language: PropTypes.string
}
export default AccordionForFeatureRepresentation
