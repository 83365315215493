import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    COLLECT_TOTAL_PAYMENT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/payment', filter),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_TOTAL_PAYMENT_REQUEST,
                actionTypes.COLLECT_TOTAL_PAYMENT_SUCCESS,
                actionTypes.COLLECT_TOTAL_PAYMENT_FAILURE
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
