import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "../rentLog/actionTypes";
import {qaTestActions} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";

export const manualTransactionActions = {

    UPLOAD_PCBA_TESTS:(payload={}, params={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/production_log/pcba_qa',params),
            body: payload,
            method: 'POST',
            backend_slug: 'battery_management',
            types: [
                qaTestActions.UPLOAD_PCBA_TESTS_REQUEST,
                qaTestActions.UPLOAD_PCBA_TESTS_SUCCESS,
                qaTestActions.UPLOAD_PCBA_TESTS_FAILURE,
            ]
        }
    }),

    FETCH_PVB_Q4_TEST_RESULTS:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/production_log',payload),
            method: 'GET',
            backend_slug: 'battery_management',
            types: [
                qaTestActions.FETCH_PVB_Q4_TEST_RESULTS_REQUEST,
                qaTestActions.FETCH_PVB_Q4_TEST_RESULTS_SUCCESS,
                qaTestActions.FETCH_PVB_Q4_TEST_RESULTS_FAILURE,
            ]
        }
    }),



    RETURN_TO_INITIAL: ()=>({
        type: qaTestActions.RETURN_TO_INITIAL
    })

}
