import moment from "moment";


/**
 * Subtract one month by considering exact (28/29/30/31) days of relevant months.
 * @constructor
 * @param {Object} date - Date (moment date object) from which 12 month will be subtracted.
 */
export function subtractExactTwelveMonths(date) {
    const yearOfTheDate = date.year();
    const isLeapYear = (yearOfTheDate % 4 === 0 && yearOfTheDate % 100 !== 0) || (yearOfTheDate % 400 === 0);
    const dateOfTheDate = date.date();
    const monthOfTheDate = date.month() + 1; // Moment.js months are 0-based, so add 1 to keep 1-based indexing.

    const monthDays = {
        1: 31, // Jan
        2: isLeapYear ? 29 : 28, // Feb
        3: 31, // March
        4: 30, // April
        5: 31, // May
        6: 30, // June
        7: 31, // July
        8: 31, // August
        9: 30, // Sept
        10: 31, // Oct
        11: 30, // Nov
        12: 31 // Dec
    };

    let previousMonth = monthOfTheDate - 12;
    let adjustedYear = yearOfTheDate;

    if (previousMonth <= 0) {
        previousMonth += 12; // Wrap around to last year
        adjustedYear = yearOfTheDate - 1;
    }

    const previousYearIsLeapYear = (adjustedYear % 4 === 0 && adjustedYear % 100 !== 0) || (adjustedYear % 400 === 0);
    if (previousMonth === 2) {
        monthDays[2] = previousYearIsLeapYear ? 29 : 28; // Adjust for February in the previous year
    }

    const previousMonthDays = monthDays[previousMonth];
    const currentMonthDays = monthDays[monthOfTheDate];

    if (dateOfTheDate === 1) {
        // First day of the month
        return date.subtract(previousMonthDays, 'days').subtract(11, 'months'); // Go back 12 months in total
    } else if (dateOfTheDate === currentMonthDays) {
        // Last day of the month
        return moment(`${adjustedYear}-${previousMonth}-${previousMonthDays}`, "YYYY-MM-DD");
    } else {
        // Subtract exactly the previous month length
        return date.subtract(currentMonthDays, 'days').subtract(11, 'months');
    }
}
