import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import Search from "./views/search/search";


export const SearchResultRouter =(props)=> {

    return (
        <Switch>
            {/* Routes to search*/}
            <Route path={"/search"} component={Search} />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
