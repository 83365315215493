export const appEventActionTypes = {
    FETCH_EVENT_LIST_REQUEST: "FETCH_EVENT_LIST_REQUEST",
    FETCH_EVENT_LIST_SUCCESS: "FETCH_EVENT_LIST_SUCCESS",
    FETCH_EVENT_LIST_FAILURE: "FETCH_EVENT_LIST_FAILURE",

    FETCH_EVENT_LIST_FOR_EXPORT_REQUEST: "FETCH_EVENT_LIST_FOR_EXPORT_REQUEST",
    FETCH_EVENT_LIST_FOR_EXPORT_SUCCESS: "FETCH_EVENT_LIST_FOR_EXPORT_SUCCESS",
    FETCH_EVENT_LIST_FOR_EXPORT_FAILURE: "FETCH_EVENT_LIST_FOR_EXPORT_FAILURE",

    RETURN_TO_INITIAL: "RETURN_TO_INITIAL"
}
