import React, {useEffect, useState, useRef} from 'react';
import {connect, useSelector} from "react-redux";
import {useSubheader} from "../../../../../_metronic/layout";
import moment from "moment";
import {actions} from "../../actions";
import {withRoles} from "../../../../router/SecuredRoute";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import {useMediaQuery} from "react-responsive";
import { makeStyles } from '@material-ui/styles';
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {DataTable} from "../../../../components";
import {DataTableContainerForLoanDetails} from "../../../contractManagement/utils";
import Skeleton from "@mui/material/Skeleton";


const BorrowerWisePerformanceOverview = ({data, ...props}) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    // Columns for the investment details table
    const lowPerformaningBorrowerTableColumns = [
        {
            field: 'borrower_name',
            title: "Borrower",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <span>{rowData.borrower_name}</span><br/>
                    <span className={'small'}>{rowData.garage_name}</span>
                </>
            }
        },
        {
            field: 'branch_name',
            title: "Branch",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <span>{rowData.branch_name}</span><br/>
                    <span className={'small'}>{rowData.financier_name}</span>
                </>
            }
        },
        {
            field: 'investment_amount',
            title: "Investment",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.investment_amount}</strong></h6>
                </>
            }
        },
        {
            field: 'collection',
            title: "Collection",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.collection}</strong></h6>
                    <span>{rowData.collection_percentage}</span>
                </>
            }
        },
        {
            field: 'total_overdue',
            title: "Total Overdue",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6 className={'text-danger'}><strong>{rowData.total_overdue}</strong></h6>
                </>
            }
        }
    ]
    const [lowPerformingBorrowers, setLowPerformingBorrower] = useState(undefined)

    useEffect(() => {
        if (data) {
            if (data["borrower_wise_summary"]) {
                setLowPerformingBorrower(data["borrower_wise_summary"].map((borrower) => {
                    let borrowerName = Object.keys(borrower)[0]
                    return {
                        ...borrower,
                        borrower_name: borrower[borrowerName]['borrower_name'],
                        garage_name: borrower[borrowerName]['garage_name'],
                        branch_name: borrower[borrowerName]['branch_name'],
                        financier_name: borrower[borrowerName]['Organization']? borrower[borrowerName]['Organization']:'',
                        investment_amount: new Intl.NumberFormat('en-IN').format(parseFloat(borrower[borrowerName]['total_investment'].toFixed(2))),
                        collection: new Intl.NumberFormat('en-IN').format(parseFloat(borrower[borrowerName]['total_paid'].toFixed(2))),
                        collection_percentage: borrower[borrowerName]['total_paid_percentage']? borrower[borrowerName]['total_paid_percentage'].toFixed(2) + '%':'',
                        total_overdue: new Intl.NumberFormat('en-IN').format(parseFloat(borrower[borrowerName]['total_overdue'].toFixed(2)))
                    }
                }))
            } else {
                setLowPerformingBorrower([])
            }
        }
    },[data])

    return (
        <>
            <div className={'row'}>
                <div className={isTabletOrMobile? 'col-md-12':'col-md-12 pl-0'}>
                    <Card sx={{ width: '100%', minHeight: '120px'}}>
                        <CardContent sx={{ paddingBottom: '0px' }}>
                            <Typography variant="subtitle2" component="div">
                                Low Performing Borrowers
                            </Typography>
                            <div className={'mt-8'}>
                                {props.collectingLowPerformingBorrowers || !lowPerformingBorrowers? <>
                                    <Skeleton variant="rect" sx={{ width: '100%' }} height={652}/>
                                </>:<>
                                    <DataTableContainerForLoanDetails>
                                        <DataTable
                                            language={props.language}
                                            noDataAvailableMessageInEnglish={"No low performing borrowers"}
                                            columns={lowPerformaningBorrowerTableColumns}
                                            data={lowPerformingBorrowers}
                                            showToolbar={false}
                                            asyncPagination={false}
                                            pagination={true}
                                            pageSize={10}
                                        />
                                    </DataTableContainerForLoanDetails>
                                </>}
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>
    );
}

BorrowerWisePerformanceOverview.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        collectingLowPerformingBorrowers: state.overviewReducer.collectingLowPerformingBorrowers
    }
}

export default connect(mapStateToProps, {...actions})(withRoles(BorrowerWisePerformanceOverview))
