import React from 'react';
import { components } from "react-select";

// Component to show 'n items selected' text
// Reference for the following solution - https://stackoverflow.com/questions/48323716/how-to-render-n-items-selected-rather-than-the-list-of-n-selected-items-with-r
export const ValueContainer = ({ children, ...props }) => {
    let [values, input] = children

    // Following checking is needed as we are setting empty string ('') as default value
    if (Array.isArray(values)) {
        const plural = values.length === 1 ? "" : input.props.selectProps.name === 'branch'? "es" : "s";
        values = input.props.selectProps.name === 'smart battery'? `${values.length} ${values.length === 1? 'smart battery':'smart batteries'} selected`:
            `${values.length} ${input.props.selectProps.name}${plural} selected`;
    }

    return (
        <components.ValueContainer {...props}>
            {values}
            {input}
        </components.ValueContainer>
    )
}