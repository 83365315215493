import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { SolInput } from '../../../../../components';
import { actions } from '../../../actions';
import { actions as commonActions } from '../../../../commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import moment from "moment/moment";
import {toast} from "react-toastify";
import {Button, Spinner, Form, OverlayTrigger, Popover, Row, Col} from "react-bootstrap";
import Stack from "@mui/material/Stack";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import {requestCycle} from "../../../../borrowers/utils";
import {showNotifications} from "../../../../../utils/notification";
import {SolTextArea} from "../../../../../components/SolStyledComponents/components";
import {DayPickerRangeController} from "react-dates";
import Overlay from "react-bootstrap/Overlay";
import {addExactOneMonth} from "../../../../../utils/monthAdditionSubtraction/exactDateAfterAddingOneMonth";


const RescheduleInstallment = ({ contractDetails, closeReschedulingProcess, ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [language, setLanguage] = useState("EN");
    const [date, setDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');

    const [dueInvoice, setDueInvoice] = useState({});
    const [lastDateForSelection, setLastDateForSelection] = useState('');
    const [initialDateForSelection, setInitialDateForSelection] = useState('');

    const {
        RESCHEDULE_THE_INSTALLMENT,
        COLLECT_LOAN_DETAILS,
        CLEAR_STATES_RELATED_TO_RESCHEDULING_INSTALLMENT_COMPONENT,
        RETURN_TO_INITIAL_STATE
    } = props;

    useEffect(() => {
        setLanguage(props.language)
        let today = moment()
        let loanSchedules = contractDetails.loan_schedules
        // Filtering due invoices that has invoice ID at BE (that means, it is not upcoming)
        // and also BE due date is after today (that means, payment date (BE due date - 1) is at least today or after)
        let dueInvoice = loanSchedules.filter(item => item.status === 'due' && item.invoice_id && moment(item.due_date, "YYYY-MM-DD").isAfter(today))
        // Ideally there will always be a "Due" invoice with invoice ID or not (all are paid or one overdue)
        // But if one due invoice is rescheduled than in next week/month, there might be two due invoices
        if (dueInvoice[0]) {
            let eligibleInvoice = dueInvoice.length === 1? dueInvoice[0]:dueInvoice[dueInvoice.length - 1]
            setDueInvoice(eligibleInvoice)
            setInitialDateForSelection(moment(eligibleInvoice.due_date, "YYYY-MM-DD"))
            let upcomingInstallments = loanSchedules.filter(item => item.status === 'due' && !item.invoice_id)
            if (upcomingInstallments[0]) {
                // Always considering the latest upcoming installment/upcoming payment dates
                setLastDateForSelection(moment(upcomingInstallments[0].due_date, "YYYY-MM-DD").subtract(1, 'days'))
            } else {
                let paymentFrequency = contractDetails.lease_payment_interval
                if (paymentFrequency === 'weekly') {
                    // Weekly
                    setLastDateForSelection(moment(eligibleInvoice.due_date, "YYYY-MM-DD").add(6, 'days'))
                } else {
                    // Monthly
                    setLastDateForSelection(addExactOneMonth(moment(eligibleInvoice.due_date, "YYYY-MM-DD")).subtract(1, 'days'))
                }
            }
        }
        return ()=> {
            CLEAR_STATES_RELATED_TO_RESCHEDULING_INSTALLMENT_COMPONENT()
        }
    }, [])

    const reschedule = (data) => {
        let payload = {
            "contract_id": contractDetails.contract_id,
            "installment_no": dueInvoice?.installment_no,
            "new_due_date": moment(data.rescheduled_to, "MMMM DD, YYYY").format("YYYY-MM-DD"),
            "reason": data.reason
        }
        RESCHEDULE_THE_INSTALLMENT(payload)
    }

    const disableDates = (current) => {
        const today = moment()
        if ((initialDateForSelection && current.isBefore(initialDateForSelection, 'day')) ||
            (lastDateForSelection && current.isAfter(lastDateForSelection, 'day')) || current.isSameOrBefore(today, 'day')) {
            return true
        }
        return false
    }

    const onDatesChange = ({startDate, endDate}) => {
        if (startDate && !endDate) {
            setDate(startDate)
        } else if (startDate && endDate) {
            setDate(endDate)
        }
    }

    useEffect(() => {
        if (date) {
            setDateRange(moment(date).format("MMMM DD, YYYY"))
            clearErrors("recheduled_to")
        } else {
            setDateRange('')
        }
    }, [date])

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'} className={'custom-style'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={date}
                    endDate={date}
                    numberOfMonths={1}
                    minimumNights={0}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                    firstDayOfWeek={0}
                    isOutsideRange={disableDates}
                    initialVisibleMonth={() => initialDateForSelection? initialDateForSelection:moment()}
                />
            </Popover.Content>
        </Popover>
    )

    useEffect(() => {
        if (props.rescheduled && props.rescheduled === requestCycle.success) {
            toast.success("Installment " + dueInvoice.installment_no + " is rescheduled successfully!")
            closeReschedulingProcess()
            COLLECT_LOAN_DETAILS(contractDetails.contract_id)
            RETURN_TO_INITIAL_STATE()
        }
    }, [props.rescheduled])

    useEffect(() => {
        if (props.errorMessageForReschedule) {
            showNotifications('error', props.errorMessageForReschedule)
        }
    }, [props.errorMessageForReschedule])

    return (
        <>
            <Form onSubmit={handleSubmit(reschedule)}>
                {Object.keys(dueInvoice).length > 0? <>
                    <div className={'row g-3'}>
                        <div className={'col-md-12'}>
                            <Form.Group as={Row}>
                                <Form.Label column sm={6}><h6>Reschedule <strong>Installment {dueInvoice.installment_no}</strong> from <strong>{moment(dueInvoice.due_date, "YYYY-MM-DD").subtract(1, 'days').format('MMM DD, YYYY')}</strong> to</h6></Form.Label>
                                <Col sm={6}>
                                    <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                    overlay={popover}>
                                        <SolInput
                                            name={'rescheduled_to'}
                                            readOnly={true}
                                            value={dateRange}
                                            ref={register({
                                                required: 'Above information is required!'
                                            })}
                                            placeholder={"Select a date"}
                                        />
                                    </OverlayTrigger>
                                    {errors.rescheduled_to && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.rescheduled_to.message}</div>}
                                </Col>
                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row g-3'}>
                        <div className={'col-md-12'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><h6>Reason  <span className={'text-danger'}>*</span></h6></Form.Label>
                                </div>
                                <SolTextArea
                                    name={"reason"}
                                    rows={"3"}
                                    placeholder={"Reason for rescheduling"}
                                    autoComplete={"off"}
                                    ref={register({
                                        required: "Above information is required!"
                                    })}
                                />
                                {errors.reason && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.reason.message}</div>}
                            </Form.Group>
                        </div>
                    </div>
                </>:<div className={'row g-3'}>
                    <div className={'col-md-12 d-flex justify-content-center align-items-center'}>
                        <h6><strong>There is no eligible invoice to reschedule</strong></h6>
                    </div>
                </div>}
                <hr/>
                <div className={'row mt-3'}>
                    <div className={"col-md-12"}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Button variant={"outline-dark"} type={"button"} size={'lg'}
                                    disabled={props.reschedulingInProgress}
                                    onClick={closeReschedulingProcess}>
                                Cancel
                            </Button>
                            <Button variant="warning" size={'lg'} type="submit" disabled={props.reschedulingInProgress || Object.keys(dueInvoice).length === 0}>
                                {props.reschedulingInProgress? <><Spinner animation={'border'} size={'sm'} variant={'light'}/></>:null} Reschedule
                            </Button>
                        </Stack>
                    </div>
                </div>
            </Form>
        </>
    )
}

RescheduleInstallment.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        rescheduled: state.contractManagementReducer.rescheduled,
        reschedulingInProgress: state.contractManagementReducer.reschedulingInProgress,
        errorMessageForReschedule: state.contractManagementReducer.errorMessageForReschedule
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(RescheduleInstallment);