import {createMuiTheme, makeStyles} from "@material-ui/core/styles";

export const useStylesForDatePicker = makeStyles({
    root: {
        marginTop: '0',
        marginBottom: '0',
        border: 'none',
        width: '100%',
        '& input': {
            color: 'black',
            padding: "0 10px 0 10px",
            height: "38px",
            outline: "none",
        },
        '& input:hover, input:focus, input:active': {
            border: "1px solid #F18D00",
            boxShadow: "0px 0px 0px 3.2px r gba(241, 141, 0, 0.1)",
            outline: "none",
        }
    },
});
export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#ffffff',
        }
    },
});
