import { IconButton } from '@material-ui/core';
import FilterListIcon from "@material-ui/icons/FilterList";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {connect } from 'react-redux';
import { Toolbar } from '../../../../components/compoundToolBar/compoundToolBar';
import ContentWrapper from '../../../../components/contentWrapper/contentWrapper';
import { useStylesIconButton } from '../../../../utils/utilityFunctions';
import {LoadingSpinner, DataTableContainer, DataTable} from '../../../../components';
import { Form, Spinner, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import {actions as commonActions} from '../../../commonReduxActions/actions';
import { actions } from '../../actions';
import { withRoles } from '../../../../router/SecuredRoute';
import JSONPretty from 'react-json-prettify';
import { GenericModal } from '../../../../components/genericModal/genericModal';
import { SolTypography } from '../../../../components/utilityComponents/SOlTypography';
import { useSubheader } from '../../../../../_metronic/layout';
import DateTimeFormat from '../../../../utils/dateFormat/DateTimeFormat';
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../../auth/authorization";
import { parseParams} from "../../../../utils/utilityFunctions";
import {toast} from "react-toastify";




const Models = props => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const type = [
        {value: "bms", label: "BMS"}, {value: "battery", label: "Battery"},
    ]
    const [filter, seltFilter] = useState(true);
    const [models, setModels] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [bmsData, setBmsData] = useState(undefined)
    const [language, setLanguage] = useState('EN');
    const [page, setPage] = useState(1)
    const [queryParams, setQueryParams] = useState(null)
    const [initialfilterParam, setInitialfilterParam] = useState(null)
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null)
    const [urlIsEdited, setUrlIsEdited] = useState(false)
    const [productType, setProductType] = useState('')
    const classes = useStylesIconButton();
    const subheader = useSubheader()

    // Permission related
    const [modelTypeIsBMS, setModelTypeIsBMS] = useState(false);
    const [hasAccessForUpdate, setHasAccessForUpdate] = useState(false);
    const batteryServiceName = getServiceName('batteryService')
    const modelFeatureName = getFeatureName('modelFeature')
    const createActionName = getActionName('createAction')
    const updateActionName = getActionName('updateAction')

    const {
        GET_MODELS_LIST,
        RETURN_TO_INITIAL_STATE
    } = props;



    const filterToggle =()=>{
        seltFilter(!filter);
    }

    const resetFilter=()=>{

    }

    const viewBmsAdditionalInfo = (rowData) => {
        setShowModal(true)
        setBmsData(rowData)
    }
    const goToUpdate = (rowData) => {
        props.history.push(`/batteries/vendors/model/update/${rowData.pk}`)
    }


    const hideModal =()=>{
        setShowModal(false)
        setBmsData(null)
    }

    useEffect(()=>{
        if (checkAuthorization(props.authorization, batteryServiceName, modelFeatureName, [createActionName])) {
            subheader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} classes={classes} onClick={() => {
                        props.history.push({
                            pathname: '/batteries/vendors/create',
                            purpose: 'modelCreate'
                        })
                    }}>
                        <i className="fa fa-random" />{props.language === 'EN' ? 'Create Model' : 'একাধিক ব্যাটারী নিবন্ধন'}
                    </Button>
                </div>
            )
        }
        subheader.setBreadCrumbComponent(null)

        setHasAccessForUpdate(checkAuthorization(props.authorization, batteryServiceName, modelFeatureName, [updateActionName]))

        if (!props.history.location.search) {
            setProductType(type[0])
            setPage(1)
            props.history.push({
                search: 'type=' + type[0].value
            })
        }

        return ()=>{
            subheader.setActionButtons(null)
            subheader.setBreadCrumbComponent(null)
            RETURN_TO_INITIAL_STATE()
        }
    },[])

    // ----- Begin: Section related to shareable link/handling query params ---- //
    const validationKeyArray = ['page', 'type']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (initialfilterParam) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            if (initialfilterParam.type) {
                let modelTypeFromUrl = initialfilterParam.type
                let selectedModelTypeFirmware = type.filter(item => item.value ===  modelTypeFromUrl)
                setProductType(selectedModelTypeFirmware[0]? selectedModelTypeFirmware[0]:'')
                // setValue('firmware_type', selectedModelTypeFirmware[0]? selectedModelTypeFirmware[0]:'')
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            // Page will always be there
            // let filter = {page: page}
            let filter = {}
            if (filterParamForApiCall.type) {
                filter['type'] = filterParamForApiCall.type
            }
            GET_MODELS_LIST({...filter})
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);
    // ----- End: Section related to shareable link/handling query params ---- //

    useEffect(() => {
        if (props.modelsList && props.modelsList.length > -1) {
            setModels(props.modelsList)
        }
    },[props.modelsList])

    const onChangePage = (newPage) => {
        setPage(newPage)
        let searchText = 'page=' + newPage
        if (filterParamForApiCall.type) {
            searchText += '&type=' + filterParamForApiCall.type
        }
        props.history.push({
            search: searchText
        })
    }

    const vendorListColumns = [
        {
            field: 'model_name',
            title: language === 'EN'? 'Name':'ব্যাটারী সিরিয়াল',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                // color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            // disableClick: true,
        },
        {
            field: 'model_type',
            title: language === 'EN'? 'Product type':'ডঙ্গল সিরিয়াল',
            emptyValue: ()=>{
                return "N/A"
            },
            lookup: {
                'bms': 'BMS',
                'battery': "Battery"
            }
            // disableClick: true,
        },
        {
            field: 'vendor_name',
            title: language === 'EN'? 'Vendor Name':'ডঙ্গল সিরিয়াল',
            emptyValue: ()=>{
                return "N/A"
            },

            // disableClick: true,
        },
        {
            field: 'created_at',
            title: language === 'EN' ? "Created on" : "আপলোডের তারিখ",
            emptyValue: () => {
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return new DateTimeFormat(rowData.created_at).localDisplayWithoutTime

            }
            // disableClick: true,
        },

    ]


    return (
        <ContentWrapper showBackButton={true} pageTitle={"Battery Models"} showCardHeader={false}>
            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>Battery Models</b></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            <IconButton onClick={filterToggle} classes={classes}>
                                <FilterListIcon color="disabled" fontSize="large"/>
                            </IconButton>
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>
                <hr/>
            </div>

            {filter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Product type':'সরবরাহকারী'}</Form.Label>
                                                </div>
                                                <Select
                                                    name={`product_type`}
                                                    placeholder={language === 'EN'? 'Select a Product type':'বিএমএস বিক্রেতা বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={productType}
                                                    isClearable={true}
                                                    options={type}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        if (action === "clear") {
                                                            setProductType('')
                                                            props.history.push({
                                                                search: ''
                                                            })
                                                        }
                                                        if (selected) {
                                                            setProductType(selected)
                                                            props.history.push({
                                                                search: 'type=' + selected.value
                                                            })
                                                        }
                                                        return selected
                                                    }}
                                                />
                                                
                                            </Form.Group>
                                        </div>

                                    </div>
                                    
                                    {/* <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}}>
                                                {language === 'EN'? 'Reset filter':'প্রাথমিক অবস্থায় ফিরে যান'}
                                            </Button>
                                            <Button variant="warning" size={'sm'} type="button"
                                                // disabled={serialNumberNotNumericMessage? true:false}
                                                    disabled={false}
                                                    className={'ml-3'}>
                                                {props.deviceInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}
                                                {language === 'EN'? 'Apply filter':'তালিকায় খুঁজুন'}
                                            </Button>
                                        </div>
                                    </div> */}
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}

            <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.listLoading? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন ব্যাটারী নেই'}
                                columns={vendorListColumns}
                                data={models}
                                showToolbar={false}
                                asyncPagination={false}
                                isLoading={props.listLoading}
                                pagination={true}
                                pageSize={10}
                                actionColumnIndex={-1}
                                overrideCustomActions={true}
                                actions={hasAccessForUpdate? [
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'info'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                                    Additional Info
                                                </Button>
                                            },
                                            onClick: (e, row) => viewBmsAdditionalInfo(row),
                                            hidden: rowData.model_type !== 'bms'
                                        })
                                    },
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'warning'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                                    Update
                                                </Button>
                                            },
                                            onClick: (e, row) => goToUpdate(row),
                                            hidden: rowData.model_type !== 'bms'
                                        })
                                    }
                                ]:[]}
                            /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Generating models list ..': 'ব্যাটারীর তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
            </div>


            {showModal ?
                <GenericModal
                    modal={showModal}
                    onHide={hideModal}
                    animation={true}
                    size={'lg'}
                    centered={"centered"}
                    hideModal={hideModal}
                    title={"BMS configurations"}
                    footer={true}
                    footerCloseButtonText={"Close"}
                    noSubmitButton={true}
                    showModalHeader={true}
                    hideCrossButton={false}
                >
                   

                    <SolTypography.Title primary>Model Name:</SolTypography.Title>
                    {bmsData?.bms_command ? <JSONPretty json={bmsData.model_name} /> : "N/A"}

                    <br />

                    <br />

                    <SolTypography.Title primary>Vendor:</SolTypography.Title>
                    {bmsData?.bms_command ? <JSONPretty json={bmsData.vendor_name} /> : "N/A"}

                    <br />

                    <br />


                    <SolTypography.Title primary>BMS commands:</SolTypography.Title>
                    {bmsData?.bms_command ?<JSONPretty json={bmsData.bms_command} /> : "No Command Found"}
                </GenericModal> : null
            }

            
        </ContentWrapper>
    );
};

Models.propTypes = {
    
};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.batteryReducer.isLoading,
        listLoading: state.commonReducer.listLoading,
        modelsList: state.commonReducer.modelsList
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(Models));