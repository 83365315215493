export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_TOTAL_PAYMENT_REQUEST: 'COLLECT_TOTAL_PAYMENT_REQUEST',
    COLLECT_TOTAL_PAYMENT_SUCCESS: 'COLLECT_TOTAL_PAYMENT_SUCCESS',
    COLLECT_TOTAL_PAYMENT_FAILURE: 'COLLECT_TOTAL_PAYMENT_FAILURE',

    COLLECT_RENT_LIST_V1_REQUEST: 'COLLECT_RENT_LIST_V1_REQUEST',
    COLLECT_RENT_LIST_V1_SUCCESS: 'COLLECT_RENT_LIST_V1_SUCCESS',
    COLLECT_RENT_LIST_V1_FAILURE: 'COLLECT_RENT_LIST_V1_FAILURE',

    COLLECT_RENT_LIST_V3_REQUEST: 'COLLECT_RENT_LIST_V3_REQUEST',
    COLLECT_RENT_LIST_V3_SUCCESS: 'COLLECT_RENT_LIST_V3_SUCCESS',
    COLLECT_RENT_LIST_V3_FAILURE: 'COLLECT_RENT_LIST_V3_FAILURE',

    GET_RENT_LIST_TO_EXPORT_V1_REQUEST: 'GET_RENT_LIST_TO_EXPORT_V1_REQUEST',
    GET_RENT_LIST_TO_EXPORT_V1_SUCCESS: 'GET_RENT_LIST_TO_EXPORT_V1_SUCCESS',
    GET_RENT_LIST_TO_EXPORT_V1_FAILURE: 'GET_RENT_LIST_TO_EXPORT_V1_FAILURE',

    GET_RENT_LIST_TO_EXPORT_V3_REQUEST: 'GET_RENT_LIST_TO_EXPORT_V3_REQUEST',
    GET_RENT_LIST_TO_EXPORT_V3_SUCCESS: 'GET_RENT_LIST_TO_EXPORT_V3_SUCCESS',
    GET_RENT_LIST_TO_EXPORT_V3_FAILURE: 'GET_RENT_LIST_TO_EXPORT_V3_FAILURE',

    VERIFY_DRIVER_REQUEST: 'VERIFY_DRIVER_REQUEST',
    VERIFY_DRIVER_SUCCESS: 'VERIFY_DRIVER_SUCCESS',
    VERIFY_DRIVER_FAILURE: 'VERIFY_DRIVER_FAILURE',

    VERIFY_DEVICE_REQUEST: 'VERIFY_DEVICE_REQUEST',
    VERIFY_DEVICE_SUCCESS: 'VERIFY_DEVICE_SUCCESS',
    VERIFY_DEVICE_FAILURE: 'VERIFY_DEVICE_FAILURE',

    CHECKOUT_DRIVER_REQUEST: 'CHECKOUT_DRIVER_REQUEST',
    CHECKOUT_DRIVER_SUCCESS: 'CHECKOUT_DRIVER_SUCCESS',
    CHECKOUT_DRIVER_FAILURE: 'CHECKOUT_DRIVER_FAILURE',

    COLLECT_GARAGE_LIST_REQUEST: 'COLLECT_GARAGE_LIST_REQUEST',
    COLLECT_GARAGE_LIST_SUCCESS: 'COLLECT_GARAGE_LIST_SUCCESS',
    COLLECT_GARAGE_LIST_FAILURE: 'COLLECT_GARAGE_LIST_FAILURE',

    COLLECT_DRIVER_DETAILS_REQUEST: 'COLLECT_DRIVER_DETAILS_REQUEST',
    COLLECT_DRIVER_DETAILS_SUCCESS: 'COLLECT_DRIVER_DETAILS_SUCCESS',
    COLLECT_DRIVER_DETAILS_FAILURE: 'COLLECT_DRIVER_DETAILS_FAILURE',

    COLLECT_EV_LIST_REQUEST: 'COLLECT_EV_LIST_REQUEST',
    COLLECT_EV_LIST_SUCCESS: 'COLLECT_EV_LIST_SUCCESS',
    COLLECT_EV_LIST_FAILURE: 'COLLECT_EV_LIST_FAILURE',

    COLLECT_INACTIVE_BATTERY_LIST_REQUEST: 'COLLECT_INACTIVE_BATTERY_LIST_REQUEST',
    COLLECT_INACTIVE_BATTERY_LIST_SUCCESS: 'COLLECT_INACTIVE_BATTERY_LIST_SUCCESS',
    COLLECT_INACTIVE_BATTERY_LIST_FAILURE: 'COLLECT_INACTIVE_BATTERY_LIST_FAILURE',

    COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY_REQUEST: 'COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY_REQUEST',
    COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY_SUCCESS: 'COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY_SUCCESS',
    COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY_FAILURE: 'COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY_FAILURE',

    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',
    RETURN_TO_INITIAL_STATE_FOR_DRIVER_DETAILS_MODAL: 'RETURN_TO_INITIAL_STATE_FOR_DRIVER_DETAILS_MODAL'
};
