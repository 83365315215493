import React, {Fragment} from "react";
import DateTimeFormat from "../../utils/dateFormat/DateTimeFormat";
import styled from "styled-components";
import {Spinner} from "react-bootstrap";
import {SolTypography} from "../../components/utilityComponents/SOlTypography";
import {isNumber} from "../../utils/utilityFunctions";


export const DataTableContainer = styled.div`
  padding: 4rem 0 0 0;
  min-height: 25rem;
  height: auto;
`


export const requestCycle = {
    success: 'success',
    pending: 'pending',
    failed: 'failed',
}
