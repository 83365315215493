import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { SolInput } from '../../../../../components';
import { actions } from '../../../actions';
import { actions as commonActions } from '../../../../commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import moment from "moment/moment";
import {toast} from "react-toastify";
import {Button, Spinner, Form} from "react-bootstrap";
import Stack from "@mui/material/Stack";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {requestCycle} from "../../../../borrowers/utils";
import {showNotifications} from "../../../../../utils/notification";
import {useMediaQuery} from "react-responsive";
import InfoIcon from "@mui/icons-material/Info";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {SolTextArea} from "../../../../../components/SolStyledComponents/components";


const TerminateContract = ({ contractID, contractSmartBattery, closeContractTerminationProcess, ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [language, setLanguage] = useState("EN");
    const [disableTerminateButton, setDisableTerminateButton] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const invalidFileType = 'Invalid file type! Accepted file types are *.doc, *.docx, *.pdf, *.jpeg, *.jpg and *.png'
    const invalidSize = 'File size shouldn"t exceed 10MB!'

    const {
        TERMINATE_LOAN_CONTRACT,
        COLLECT_LOAN_DETAILS,
        CLEAR_STATES_RELATED_TO_TERMINATE_CONTRACT_COMPONENT,
        RETURN_TO_INITIAL_STATE
    } = props;

    // Tooltip for "Document"
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));
    const [showTooltipForDocumentField, setShowTooltipForDocumentField] = useState(false);
    const toolTipTextForDocumentField = <>
        <strong>Accepted file types:</strong> *.doc, *.docx, *.pdf, *.jpeg, '*.jpg, *.png<br/>
        <strong>Max accepted size:</strong> 10MB
    </>
    const toolTipForDocumentField = () => {
        return <>
            <HtmlTooltip
                title={toolTipTextForDocumentField} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showTooltipForDocumentField}
                onOpen={() => setShowTooltipForDocumentField(true)}
                onClose={() => setShowTooltipForDocumentField(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowTooltipForDocumentField(!showTooltipForDocumentField)
                    }
                }}/>
            </HtmlTooltip>
        </>
    }

    useEffect(() => {
        setLanguage(props.language)
        return ()=> {
            CLEAR_STATES_RELATED_TO_TERMINATE_CONTRACT_COMPONENT()
        }
    }, [])

    const terminateTheLoan = (data) => {
        let payload = {
            ...data,
            "contract_id": contractID
        }
        data['contract_termination_file'][0]? payload['contract_termination_file'] = data['contract_termination_file'][0]:delete payload['contract_termination_file']
        TERMINATE_LOAN_CONTRACT(payload)
    }

    useEffect(() => {
        if (props.loanTerminated && props.loanTerminated === requestCycle.success) {
            toast.success("Contract has been terminated successfully!")
            closeContractTerminationProcess()
            COLLECT_LOAN_DETAILS(contractID)
            RETURN_TO_INITIAL_STATE()
        }
    }, [props.loanTerminated])

    useEffect(() => {
        if (props.errorMessageForLoanTermination) {
            showNotifications("error", props.errorMessageForLoanTermination)
        }
    }, [props.errorMessageForLoanTermination])

    // ---- Edit: Related to contract edit ---- //

    return (
        <>
            <Form onSubmit={handleSubmit(terminateTheLoan)}>
                <div className={'row g-3'}>
                    <div className={'col-md-12'}>
                        Once the contract is terminated, it cannot be activated. A new contract will need to be created.
                    </div>
                </div>
                <div className={'row g-3 mt-10'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Smart Battery of the Contract <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <SolInput
                                name={"smart_battery_id"}
                                type={"number"}
                                min={"1"}
                                step={"1"}
                                placeholder={"Enter Smart Battery ID"}
                                autoComplete={"off"}
                                ref={register({
                                    required: "Above information is required!",
                                    validate: {
                                        isCorrectSmartBatteryId: value => (parseInt(value) === parseInt(contractSmartBattery) || !value) || "Wrong input!"
                                    }
                                })}
                            />
                            {errors.smart_battery_id && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.smart_battery_id.message}</div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Supporting Document (if any) {toolTipForDocumentField()}</Form.Label>
                            </div>
                            <SolInput
                                name={"contract_termination_file"}
                                type={"file"}
                                ref={register()}
                                onClick={(e) => {
                                    // Clearing the file field value when user tries to re-upload
                                    setValue("contract_termination_file", "")
                                    clearErrors('contract_termination_file')
                                    setDisableTerminateButton(false)
                                }}
                                onChange={(e) => {
                                    if (e.target.files) {
                                        let value = e.target.files[0]
                                        if (value) {
                                            if (!["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                "application/pdf", "image/jpeg", "image/png"].includes(value.type)) {
                                                setError('contract_termination_file', {'message': invalidFileType})
                                                setDisableTerminateButton(true)
                                            } else if (value.size/(1024 * 1024) > 10) {
                                                setError('contract_termination_file', {'message': invalidSize})
                                                setDisableTerminateButton(true)
                                            } else {
                                                clearErrors('contract_termination_file')
                                                setDisableTerminateButton(false)
                                            }
                                        }
                                    }
                                }}
                            />
                            {errors.contract_termination_file && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.contract_termination_file.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Reason <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <SolTextArea
                                name={"terminating_reason"}
                                rows={"3"}
                                placeholder={"Reason for termination"}
                                autoComplete={"off"}
                                ref={register({
                                    required: "Above information is required!"
                                })}
                            />
                            {errors.terminating_reason && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.terminating_reason.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <hr/>
                <div className={'row mt-3'}>
                    <div className={"col-md-12"}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Button variant="danger" size={'lg'} type="submit"
                                    disabled={props.loanTerminationInProgress || disableTerminateButton} style={{
                                backgroundColor: '#cb152b',
                                border: 'none'
                            }}>
                                {props.loanTerminationInProgress? <>
                                    <Spinner animation={'border'} size={'md'} variant={'light'}/>
                                </>:''}Terminate
                            </Button>
                        </Stack>
                    </div>
                </div>
            </Form>
        </>
    )
}

TerminateContract.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        loanTerminated: state.contractManagementReducer.loanTerminated,
        loanTerminationInProgress: state.contractManagementReducer.loanTerminationInProgress,
        errorMessageForLoanTermination: state.contractManagementReducer.errorMessageForLoanTermination
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(TerminateContract);