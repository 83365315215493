import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {theme, useStylesForDatePicker} from "../../../utils/muiPickerCustomStyle";
import 'moment/locale/bn';
import 'moment/locale/en-gb';
import WarningIcon from '@mui/icons-material/Warning';
import Badge from 'react-bootstrap/Badge';

import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {manualTransactionActions} from "../actions";
import {useSubheader} from "../../../../_metronic/layout";
import {Button, Form, OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import {Toolbar} from "../../smartDongles/views/components";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {SolInput} from "../../../components/SolStyledComponents/components";
import {DataTableContainer} from "../../rentLog/utils";
import DataTable from "../../../components/dataTable/DataTable";
import {LoadingSpinner} from "../../../components/LoadingSpinnerForDataTable";
import {Controller, useForm} from "react-hook-form";
import moment from "moment/moment";
import {toast} from "react-toastify";
import {convertToAnotherLang} from "../../../utils/convertLanguageOfNumbersWithCharacter";
import {DayPickerRangeController} from "react-dates";
import {pcbQATestingResultsReducer} from "../reducer";
import Select from "react-select";
import {getServiceName} from "../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../auth/authorization";
import {GenericModal} from "../../../components/genericModal/genericModal";
import {SolTypography} from "../../../components";
import ListGroup from "react-bootstrap/ListGroup";

// TODO: Rename variables, components and routers to make it relevant with "Production Log"

const PCBQaTestingResults = props => {
    const classes = useStylesForDatePicker();
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const [language, setLanguage] = useState(null);
    const subheader= useSubheader();
    const [showFilter, setShowFilter] = useState(true);
    const [filterApplied, setFilterApplied] = useState(false);

    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [requiredMessage, setRequiredMessage] = useState(false);
    const [qaTestResults, setQaTestResults] = useState([]);

    // Details Modal
    const [showProductionLogDetailsModal, setShowProductionLogDetailsModal] = useState(false);
    const [results, setResults] = useState(null);
    const [logType, setLogType] = useState('');
    const [date, setDate] = useState('');
    const [dongle, setDongle] = useState('');
    const [battery, setBattery] = useState('');
    const [status, setStatus] = useState('');
    const [hardware, setHardware] = useState('');
    const [firmware, setFirmware] = useState('');

    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [selectedLogType, setSelectedLogType] = useState('');
    // const [page, setPage] = useState(1);

    const [batteryServiceName, setBatteryServiceName] = useState(getServiceName('batteryService'));
    const [productionLogFeatureName, setProductionLogFeatureName] = useState(getFeatureName('productionLogFeature'));
    const [createActionName, setCreateActionName] = useState(getActionName('createAction'));

    const pageTitle = language === 'EN'? 'Production Logs':'প্রোডাকশন লগসমূহ'
    const column1 = language === 'EN'? 'Dongle':'ডঙ্গল'
    const column2 = language === 'EN'? 'Battery':'ব্যাটারী'
    const column3 = language === 'EN'? 'Log type':'ফলাফলের ধরন'
    const column4 = language === 'EN'? 'Status':'পরীক্ষার বর্তমান অবস্থা'
    const column5 = language === 'EN'? 'Date':'তারিখ'
    const column6 = language === 'EN'? 'Hardware':'হার্ডওয়্যার'
    const column7 = language === 'EN'? 'Firmware':'ফার্মওয়্যার'

    const invalidSerial = language === 'EN'? 'Invalid ID!':'ভুল আইডি!'
    const filterRequiredMessage = language === 'EN'? 'Please select at least one filter criteria!':'অন্তত একটি ফিল্টারের মানদণ্ড বাছাই করুন!'

    const batterySerialFilterKeyName = 'battery_serial_number'
    const dongleSerialFilterKeyName = 'dongle_serial_number'
    const fromDateFilterKeyName = 'created_at_form'
    const toDateFilterKeyName = 'created_at_to'
    const logTypeFilterKeyName = 'type'

    const qaTestResultsColumns = [
        {
            field: 'date',
            title: column5,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            render: (rowData) => {
                return (
                    <div style={{whiteSpace: 'nowrap'}}>{rowData.date}</div>
                )
            }
        },
        {
            field: 'type',
            title: column3,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "-"
            },
            render: (rowData) => {
                return (
                    <div style={{whiteSpace: 'nowrap'}}>{rowData.logType}</div>
                )
            }
        },
        {
            field: 'dongle',
            title: column1,
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            // disableClick: true,
        },
        {
            field: 'battery',
            title: column2,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "-"
            }
        },
        {
            field: 'hardware',
            title: column6,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "-"
            },
        },
        {
            field: 'firmware',
            title: column7,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "-"
            }
        },
        {
            field: 'status',
            title: column4,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "-"
            },
            render: (rowData) => {
                if (rowData.status && rowData.status === "completed") {
                    return (
                        <><Badge pill className="bg-success">Successful</Badge></>
                    )
                } else if (rowData.status && rowData.status === "in_progress") {
                    return (
                        <><Badge pill className="bg-secondary">In progress</Badge></>
                    )
                } else if (rowData.status && rowData.status === "failed") {
                    return (
                        <><Badge pill className="bg-danger">Failed</Badge></>
                    )
                }
            }
        }
    ]

    const logTypes = [
        {
            label: 'All',
            value: 'all'
        },
        {
            label: 'Bootloader Flashing',
            value: 'bootloader'
        },
        {
            label: 'Firmware Testing',
            value: 'test_firmware'
        },
        {
            label: 'Firmware',
            value: 'firmware'
        },
        {
            label: 'QC Firmware',
            value: 'qc_firmware'
        },
        {
            label: 'Initial Firmware',
            value: 'initial_firmware'
        },
        {
            label: 'Firmware Mode Change',
            value: 'firmware_mood_change'
        },
        {
            label: 'BMS Integration Test',
            value: 'bms_integration_test'
        },
        {
            label: 'Capacity Test',
            value: 'capacity_test'
        },
        {
            label: 'Dongle integration test',
            value: 'dongle_integration_testing'
        },
        {
            label: 'Dongle test',
            value: 'dongle_testing'
        },
        {
            label: 'PCBA Test',
            value: 'pcba_qa'
        },
        {
            label: 'BMS Validation',
            value: 'bms_validation'
        }
    ]

    const {
        FETCH_PVB_Q4_TEST_RESULTS,
        RETURN_TO_INITIAL
    } = props

    useEffect(()=>{
        setLanguage(props.language)
        FETCH_PVB_Q4_TEST_RESULTS()
        setSelectedLogType(logTypes[0])
        setValue('logType', logTypes[0])
        setFilterApplied(true)

        if (checkAuthorization(props.authorization, batteryServiceName, productionLogFeatureName, [createActionName])) {
            subheader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={uploadTestResults} classes={classes} className={'ml-1'}>
                        <i className="fa fa-file-upload"/>{props.language === 'EN'? 'Upload Production Logs': 'প্রোডাকশন লগ জমা দিন'}
                    </Button>
                </div>
            )
        } else {
            subheader.setActionButtons(null)
        }
        subheader.setBreadCrumbComponent(null)

        return ()=>{
            RETURN_TO_INITIAL()
        }
    },[])

    useEffect(()=>{
        if (props.testResults) {
            const testResults = props.testResults
            if (testResults && testResults.length > 0) {
                setQaTestResults(testResults.map((result) => {
                    let filteredLogType = logTypes.filter(type => type.value === result.type)
                    let logTypeLabel = filteredLogType[0]?.label? filteredLogType[0].label:''
                    return {
                        ...result,
                        dongle: result.dongle_serial_number,
                        battery: result.battery_serial_number,
                        logType: logTypeLabel,
                        status: result.status,
                        date: moment(result.created_at).format("Do MMM,YYYY hh:mm:ss a"),
                        hardware: result.hardware_version,
                        firmware: result.firmware_version
                    }
                }))
            }
        }
    },[props.testResults])

    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    },[start, end])

    const resetFilterValues = () => {
        setValue('battery_serial', '')
        setValue('dongle_serial', '')
        setStartDate(null)
        setEndDate(null)
        setSelectedLogType(logTypes[0])
        setValue('logType', logTypes[0])
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const onChangePage = (event, newPage) => {
        // setPage(newPage)
        // if (filterApplied) {
        //     FETCH_EVENT_LIST({page: newPage, ...filterData})
        // } else {
        //     FETCH_EVENT_LIST({page: newPage})
        // }
    }

    const filterTheList = (data) => {
        let filter = {}
        if (data.battery_serial) {
            filter[batterySerialFilterKeyName] = data.battery_serial
        }
        if (data.dongle_serial) {
            filter[dongleSerialFilterKeyName] = data.dongle_serial
        }
        if (start && end){
            filter[fromDateFilterKeyName] = start.format('YYYY-MM-DD')
            filter[toDateFilterKeyName] = end.format('YYYY-MM-DD')
        }
        if (data.logType && data.logType.value !== 'all') {
            filter[logTypeFilterKeyName] = data.logType.value
        }
        if (Object.keys(filter).length > 0 || data.logType.value === 'all') {
            FETCH_PVB_Q4_TEST_RESULTS(filter)
            setFilterApplied(true)
        }
    }

    const resetFilter = () => {
        if (filterApplied) {
            // Resetting the production log
            FETCH_PVB_Q4_TEST_RESULTS()
        }
        resetFilterValues()
        clearErrors()
        // We are not setting the value of 'FilterApplied' as true, as logType will always have value 'All'
    }

    const disableFutureDt = (current) => {
        const today = moment().add('1', 'days')
        return current.isAfter(today)
    }

    const [previousStart, setPreviousStartDate] = useState(null);
    const [previousEnd, setPreviousEndDate] = useState(null);
    const onDatesChange = ({startDate, endDate}) => {
        if (previousEnd && previousEnd.isSame(endDate)) {
            setPreviousStartDate(endDate)
            setPreviousEndDate(endDate)

            setStartDate(endDate)
            setEndDate(endDate)
        } else {
            setPreviousStartDate(startDate)
            setPreviousEndDate(endDate)

            setStartDate(startDate)
            setEndDate(endDate)
        }
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start}
                    endDate={end}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    )

    const uploadTestResults = () => {
        props.history.push('/pcb-qa-testing-results/create')
    }

    const showDetailsOfTheLog = (rowData) => {
        setResults(rowData.test_results)
        setLogType(rowData.type)
        setDate(rowData.date)
        setDongle(rowData.dongle_serial_number)
        setBattery(rowData.battery_serial_number)
        setStatus(rowData.status)
        setHardware(rowData.hardware_version)
        setFirmware(rowData.firmware_version)
        setShowProductionLogDetailsModal(true)
    }

    const hideProductionLogModal = () => {
        setResults(null)
        setLogType('')
        setDate('')
        setDongle('')
        setBattery('')
        setStatus('')
        setHardware('')
        setFirmware('')
        setShowProductionLogDetailsModal(false)
    }

    const renderProductionLogDetailsModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                noSubmitButton={true}
                hideModal={hideProductionLogModal}
                centered={true}
                modal={showProductionLogDetailsModal}
                title={<>Details of the <SolTypography.Text primary>{logType}</SolTypography.Text></>}>
                <div className={'row'}>
                    <div className={'col-lg-6'}>
                        <ListGroup>
                            <ListGroup.Item>
                                <span className={'float-left'}><strong>Time:</strong></span><span className={'float-right'}>{date}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><strong>Dongle:</strong></span><span className={'float-right'}>{dongle? dongle:'N/A'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><strong>Battery:</strong></span><span className={'float-right'}>{battery? battery:'N/A'}</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className={'col-lg-6'}>
                        <ListGroup>
                            <ListGroup.Item>
                                <span className={'float-left'}><strong>Status:</strong></span><span className={'float-right'}>{!status? 'N/A':status === 'completed'?
                                <Badge pill className="bg-success">Successful</Badge>:status === 'in_progress'?
                                    <Badge pill className="bg-secondary">In progress</Badge>:status === 'failed'? <Badge pill className="bg-danger">Failed</Badge>:null}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><strong>Hardware:</strong></span><span className={'float-right'}>{hardware? hardware:'N/A'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className={'float-left'}><strong>Firmware:</strong></span><span className={'float-right'}>{firmware? firmware:'N/A'}</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
                <div className={'row mt-3 ml-1'}><div className={'col-lg-12'}><h5><strong>Test results</strong></h5></div></div>
                {results? <>
                    <div className={'row mt-3'}>
                        <div className={'col-lg-12'}>
                            {Array.isArray(results) && results.length > 0? <ListGroup>
                                {results.map((result) => {
                                    let key = Object.keys(result)[0]
                                    let value = result[key]
                                    let updatedKey = key.split('_').join(' ')
                                    let testName = updatedKey.charAt(0).toUpperCase() + updatedKey.slice(1, updatedKey.length)
                                    return (
                                        <ListGroup.Item action>
                                            <span className={'float-left'}><strong>{testName}:</strong></span><span className={'float-right'}>{value}</span>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>:<ListGroup>
                                {Object.keys(results).map((key) => {
                                    let value = results[key]
                                    let updatedKey = key.split('_').join(' ')
                                    let testName = updatedKey.charAt(0).toUpperCase() + updatedKey.slice(1, updatedKey.length)
                                    return (
                                        <ListGroup.Item action>
                                            <span className={'float-left'}><strong>{testName}:</strong></span><span className={'float-right'}>{value}</span>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>}
                        </div>
                    </div>
                </>:<div className={'row mt-3 ml-1'}>
                    <div className={'col-lg-12'}>
                        No test results found
                    </div>
                </div>}
            </GenericModal>
        </>
    }

    return (
        <ContentWrapper isLoading={false} pageTitle={"QA results"} showBackButton={false} showCardHeader={false}>
            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>{pageTitle}</b></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            <IconButton onClick={filterToggle}>
                                <FilterListIcon color="disabled" fontSize="large"/>
                            </IconButton>
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>
                <hr/>
            </div>

            {showFilter?
                <>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <Form onSubmit={handleSubmit(filterTheList)}>
                                <div className={'row g-3'}>
                                    <div className={'col-md-3'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Battery Serial':'ব্যাটারী সিরিয়াল'}</Form.Label>
                                            </div>
                                            <SolInput
                                                type={"text"}
                                                name={"battery_serial"}
                                                placeholder={language === "EN"? "Type Battery Serial...":"ব্যাটারী সিরিয়াল লিখুন..."}
                                                autoComplete={"off"}
                                                ref={register({
                                                    validate: {
                                                        isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || invalidSerial,
                                                        startsWithCorrectCharacter: value => (value.startsWith('5') || !value) || invalidSerial
                                                    }
                                                })}
                                            />
                                            {errors.battery_serial && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.battery_serial.message}</div>}
                                        </Form.Group>
                                    </div>
                                    <div className={'col-md-3'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Dongle Serial':'ডঙ্গল সিরিয়াল'}</Form.Label>
                                            </div>
                                            <SolInput
                                                type={"text"}
                                                name={"dongle_serial"}
                                                placeholder={language === "EN"? "Type SOLdongle Serial...":"ডঙ্গল সিরিয়াল লিখুন..."}
                                                autoComplete={"off"}
                                                ref={register({
                                                    validate: {
                                                        isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || invalidSerial,
                                                        startsWithCorrectCharacter: value => (value.startsWith('4') || !value) || invalidSerial
                                                    }
                                                })}
                                            />
                                            {errors.dongle_serial && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.dongle_serial.message}</div>}
                                        </Form.Group>
                                    </div>
                                    <div className={'col-md-3'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Date range':'তারিখের সীমা'}</Form.Label>
                                            </div>
                                            <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                            overlay={popover}>
                                                <SolInput
                                                    name={'dateRangePickerInput'}
                                                    readOnly={true}
                                                    value={dateRange}
                                                    placeholder={language === 'EN'? 'Select a date range':'তারিখের সীমা বাছাই করুন'}
                                                />
                                            </OverlayTrigger>
                                        </Form.Group>
                                    </div>
                                    <div className={'col-md-3'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Log type':'লগের ধরন'}</Form.Label>
                                            </div>
                                            <Controller
                                                control={control}
                                                name={"logType"}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        placeholder={language === 'EN'? 'Select a type':'একটি ধরন বাছাই করুন'}
                                                        classNamePrefix="react-select-sol-style"
                                                        maxMenuHeight={200}
                                                        value={selectedLogType}
                                                        isClearable={true}
                                                        options={logTypes}
                                                        isSearchable={true}
                                                        onChange={(selected, {action}) => {
                                                            if (action === "clear") {
                                                                setValue('logType', "")
                                                                setSelectedLogType("")
                                                            }
                                                            if (selected) {
                                                                setValue('logType', selected)
                                                                setSelectedLogType(selected)
                                                            } else {
                                                                setValue('logType', "")
                                                                setSelectedLogType("")
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={"col-md-12"}>
                                        <Button variant="warning" size={'sm'} type="submit"
                                                disabled={props.listLoading}>
                                            {language === 'EN'? 'Apply filter':'তালিকায় খুঁজুন'}
                                        </Button>
                                        <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}}
                                                className={'ml-3'}>
                                            {language === 'EN'? 'Reset filter':'প্রাথমিক অবস্থায় ফিরে যান'}
                                        </Button>
                                        {requiredMessage? <><div className="text-danger">
                                            <WarningIcon/>&nbsp;&nbsp;{filterRequiredMessage}</div></>:null}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <hr/></>:null}
            <div>

                <DataTableContainer>
                    {props.listLoading !== true ? <DataTable
                        language={language}
                        noDataAvailableMessageInBangla={'কোন প্রোডাকশন লগ নেই'}
                        columns={qaTestResultsColumns}
                        asyncPagination={false}
                        isLoading={props.listLoading}
                        data={qaTestResults}
                        pagination={true}
                        pageSize={10}
                        actionColumnIndex={-1}
                        actions={[{}]}
                        actionButtonVariant={['warning']}
                        actionButtonSize={'sm'}
                        actionButtonClickEvent={[showDetailsOfTheLog]}
                        actionButtonText={language === 'EN'? ['Details']:['বিস্তারিত']}
                    /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Generating production logs ..': 'প্রোডাকশন লগের তালিকা প্রস্তুত হচ্ছে..'} language={language}/>}
                </DataTableContainer>

            </div>

            {renderProductionLogDetailsModal()}
        </ContentWrapper>
    );
};

PCBQaTestingResults.propTypes = {
    
};

export default connect((state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        listLoading: state.pcbQATestingResultsReducer.listLoading,
        testResults: state.pcbQATestingResultsReducer.testResults
    }
}, {...manualTransactionActions}) (PCBQaTestingResults);
