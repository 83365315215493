import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../../components";
import FormContainer from "../../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Breadcrumb, Button, Form, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner} from '../../utils'
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable'
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import {requestCycle} from "../../utils";
import Select from "react-select";
import moment from "moment";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {showNotifications} from "../../../../utils/notification";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const TokenList = (props) => {
    const [showFilter, setShowFilter] = useState(false);
    const [filterCriteria, setFilterCriteria] = useState(false);
    const [showFilterCriteriaMandatoryMessage, setShowFilterCriteriaMandatoryMessage] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [textFieldLabel, setTextFieldLabel] = useState(defaultTextFieldLabel);
    const [textFieldPlaceholder, setTextFieldPlaceholder] = useState(defaultTextFieldPlaceholder);
    const [requiredErrorMessage, setRequiredErrorMessage] = useState(false);
    const [numberValidationMessage, setNumberValidationMessage] = useState(false);
    const [serialNumberIsInvalid, setSerialNumberIsInvalid] = useState(false);

    const [serialNumber, setSerialNumber] = useState(null);
    const subHeader = useSubheader();

    const [tokenList, setTokenList] = useState([]);

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancellingTokenName, setCancellingTokenName] = useState(null);
    const [cancellingTokenDevice, setCancellingTokenDevice] = useState(null);
    const [cancellingTokenGenerationTime, setCancellingTokenGenerationTime] = useState(null);
    const [cancelProcessIsInProgress, setCancelProcessIsInProgress] = useState(null);

    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);
    const [authorization, setAuthorization] = useState(null);
    const [language, setLanguage] = useState('EN');
    const [tokenServiceName, setTokenServiceName] = useState(getServiceName('tokenService'));
    const [tokenFeatureName, setTokenFeatureName] = useState(getFeatureName('tokenFeature'));
    const [createActionName, setCreateActionName] = useState(getActionName('createAction'));
    const [deleteActionName, setDeleteActionName] = useState(getActionName('deleteAction'));

    const filterCriteriaMandatoryMessage = language === 'EN'? 'Please select a filter criteria first!':'দয়া করে আগে একটি মানদণ্ড বাছাই করুন!'
    const serialNumberFieldLabel = language === 'EN'? 'Serial Number':'যন্ত্রের ক্রমিক নাম্বার'
    const serialNumberFieldPlaceHolder = language === 'EN'? 'Provide device serial number. Ex: 33001001':'যন্ত্রের ক্রমিক নাম্বার লিখুন। উদাহরণঃ 33001001'
    const generalRequiredMessage = language === 'EN'? 'Please provide above information!':'দয়া করে উপরের তথ্যটি দিন!'
    const defaultTextFieldLabel = language === 'EN'? 'Filter value':'মান'
    const defaultTextFieldPlaceholder = language === 'EN'? 'Provide a value to filter the list':'তালিকায় টোকেন খোঁজার জন্য একটি মান লিখুন'

    const tokenListColumns = [
        {
            field: 'token',
            title: language === 'EN'? 'Token':'টোকেন',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            disableClick: true,
        },
        {
            field: 'device',
            title: language === 'EN'? 'Device':'যন্ত্র',
            emptyValue: ()=>{
                return "N/A"
            },
            disableClick: true,
        },
        {
            field: 'tokenCounter',
            title: language === 'EN'? 'Token counter':'টোকেনের ক্রম',
            emptyValue: ()=>{
                return "N/A"
            },
            disableClick: true,
        },
        {
            field: 'tokenDuration',
            title: language === 'EN'? 'Token Duration (hours)':'টোকেনের স্থায়িত্ব (ঘন্টায়)',
            emptyValue: ()=>{
                return "N/A"
            },
            disableClick: true,
        },
        {
            field: 'generatedAt',
            title: language === 'EN'? 'Generated At':'টোকেন উৎপন্নের সময়',
            emptyValue: ()=>{
                return "N/A"
            },
            disableClick: true,
        },
        {
            field: 'generatedBy',
            title: language === 'EN'? 'Generated By':'টোকেন উৎপন্ন করেছেন যিনি',
            emptyValue: ()=>{
                return "N/A"
            },
            disableClick: true,
        }
    ]

    const {
        GET_TOKEN_LIST,
        CANCEL_TOKEN
    } = props;
    const classes = useStylesIconButton();

    const filterCriteriaOptions = [
        {
            'label': language === 'EN'? 'Serial number':'যন্ত্রের ক্রমিক নাম্বার',
            'value': 'serial_number'
        }
    ]

    const clearAllErrorMessages = () => {
        setShowFilterCriteriaMandatoryMessage(false)
        setRequiredErrorMessage(false)
        setNumberValidationMessage(false)
        setSerialNumberIsInvalid(false)
    }
    const resetFilter = () => {
        if (filterApplied) {
            // Resetting the token list
            setPage(1)
            GET_TOKEN_LIST({page: 1})
        }
        setTextFieldLabel(defaultTextFieldLabel)
        setTextFieldPlaceholder(defaultTextFieldPlaceholder)
        resetFilterValues()
        setFilterApplied(false)
        clearAllErrorMessages()
    }

    const resetFilterValues = () => {
        setFilterCriteria(null)
        setSearchText("")
    }

    const filterTheList = () => {
        if (!filterCriteria || !searchText) {
            setRequiredErrorMessage(true)
        } else {
            setRequiredErrorMessage(false)
            if (filterCriteria.value === 'serial_number' && searchText.length !== 8) {
                setSerialNumberIsInvalid(true)
            } else {
                setSerialNumberIsInvalid(false)
                let filter = {page: 1}
                filter[filterCriteria.value] = searchText
                GET_TOKEN_LIST(filter)
                setFilterApplied(true)
            }
        }
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        setAuthorization(props.authorization)
        setLanguage(props.language)
        subHeader.setActionButtons(null)
        GET_TOKEN_LIST({page: 1});
    }, []);

    useEffect(() => {
        setFilterCriteria(filterCriteriaOptions[0])
        setTextFieldLabel(serialNumberFieldLabel)
        setTextFieldPlaceholder(serialNumberFieldPlaceHolder)
    }, [language]);

    useEffect(() => {
        if (props.tokenList) {
            const tokenList = props.tokenList.results;
            if (tokenList && tokenList.length > -1) {
                setTokenList(tokenList.map((token, index) => {
                    return {
                        ...token,
                        token: token.token,
                        device: token.serial_number,
                        generatedAt: moment(token.sk).format("DD MMMM,YYYY hh:mm a"),
                        generatedBy: token.token_generated_by_name? token.token_generated_by_name + " (" + token.token_generated_by_username + ")": "N/A",
                        tokenCounter: token.token_counter,
                        tokenDuration: token.token_duration
                    }
                }))
            }
        }
    }, [props.tokenList]);

    const closeTokenCancelModal = () => {
        setShowCancelModal(false)
    }

    const cancelToken = () => {
        let payload = {
            'serial_number': cancellingTokenDevice
        }
        CANCEL_TOKEN(payload)
    }

    useEffect(()=>{
        if (props.tokenCancelled === requestCycle.success){
            setShowCancelModal(false)
            if (filterCriteria && searchText) {
                let filter = {page: page}
                filter[filterCriteria.value] = searchText
                GET_TOKEN_LIST(filter)
            } else {
                GET_TOKEN_LIST({page: page})
            }
        }
    },[props.tokenCancelled])

    useEffect(()=>{
        if (props.tokenCancelletionInProgress) {
            setCancelProcessIsInProgress(props.tokenCancelletionInProgress)
        } else {
            setCancelProcessIsInProgress(props.tokenCancelletionInProgress)
        }
    },[props.tokenCancelletionInProgress])

    useEffect(() => {
        if (props.successMessageForTokenCancel !== undefined) {
            showNotifications('success', props.successMessageForTokenCancel)
        } else if (props.errorMessageForTokenCancel !== undefined) {
            showNotifications('error', props.errorMessageForTokenCancel)
        }
    }, [props.successMessageForTokenCancel, props.errorMessageForTokenCancel]);

    const renderTokenCancelModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                footer={true}
                footerButtonSize={'sm'}
                footerCloseButtonText={'Close'}
                footerActionButtonText={'Confirm'}
                hideModal={closeTokenCancelModal}
                takeAction={cancelToken}
                centered={true}
                modal={showCancelModal}
                disableButton={cancelProcessIsInProgress}
                title={<>Cancelling token: <SolTypography.Text
                    primary>{cancellingTokenName}</SolTypography.Text></>}>
                <p className={'no-padding'}><strong>Device:</strong>&nbsp;<SolTypography.Text primary>{cancellingTokenDevice}</SolTypography.Text></p>
                <p className={'no-padding'}><strong>Generated at:</strong>&nbsp;{cancellingTokenGenerationTime}</p>
            </GenericModal>
        </>
    }

    const initiateTokenCancellationProcess = (rowData) => {
        setCancellingTokenName(rowData.token)
        setCancellingTokenDevice(rowData.device)
        setCancellingTokenGenerationTime(rowData.generatedAt)
        setShowCancelModal(true)
    }

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        if (filterCriteria && searchText) {
            let filter = {page: newPage}
            filter[filterCriteria.value] = searchText
            GET_TOKEN_LIST(filter)
        } else {
            props.GET_TOKEN_LIST({page: newPage});
        }
    }

    const goToGenerateToken = () => {
        props.history.push('/tokens/generate')
    }

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Tokens"}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{language === 'EN'? 'Tokens':'টোকেনের তালিকা'}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                    {(authorization && authorization.hasOwnProperty(tokenServiceName) && authorization[tokenServiceName].hasOwnProperty(tokenFeatureName) && authorization[tokenServiceName][tokenFeatureName].includes(createActionName))? <>
                                        <Button variant="warning" type="button" size={'sm'} style={{
                                            backgroundColor: '#F18D00 !important',
                                        }} onClick={goToGenerateToken} classes={classes} >
                                            {language === 'EN'? <i className="fa fa-plus" aria-hidden="true"></i>:null}
                                            {language === 'EN'? 'Generate Token':'টোকেন উৎপন্ন করুন'}
                                        </Button>
                                    </>:null}
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form>
                                    <div className={'row g-3'}>
                                        <div className={"col-md-6"}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Filter criteria':'তালিকায় টোকেন খোঁজার মানদণ্ড'}<span className="required text-danger">*</span></Form.Label>
                                                </div>
                                                <Select
                                                    name={`filterCriteria`}
                                                    placeholder={language === 'EN'? 'Select a filter criteria':'একটি মানদণ্ড বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.filterDropdownLoading}
                                                    isLoading={props.filterDropdownLoading}
                                                    maxMenuHeight={200}
                                                    value={filterCriteria}
                                                    isClearable={true}
                                                    options={filterCriteriaOptions}
                                                    isSearchable={true}
                                                    onChange={(selected) => {
                                                        setFilterCriteria(selected)
                                                        clearAllErrorMessages()
                                                        if (selected) {
                                                            setSearchText("")
                                                            setTextFieldLabel(serialNumberFieldLabel)
                                                            setTextFieldPlaceholder(serialNumberFieldPlaceHolder)
                                                        } else {
                                                            setTextFieldLabel(defaultTextFieldLabel)
                                                            setTextFieldPlaceholder(defaultTextFieldPlaceholder)
                                                            setSearchText("")
                                                        }
                                                    }}
                                                />
                                                {(requiredErrorMessage && !filterCriteria)? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}
                                            </Form.Group>
                                        </div>
                                        <div className={"col-md-6"}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{textFieldLabel}<span className="required text-danger">*</span></Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'searchText'}
                                                    type="text"
                                                    placeholder={textFieldPlaceholder}
                                                    value={searchText}
                                                    autoComplete={"off"}
                                                    onChange = {(e) => {
                                                        if (!filterCriteria) {
                                                            setShowFilterCriteriaMandatoryMessage(true)
                                                        } else {
                                                            if (e.target.value) {
                                                                let value = e.target.value;
                                                                setSerialNumberIsInvalid(false)
                                                                setRequiredErrorMessage(false)
                                                                if (isNaN(value) || value.includes('.')) {
                                                                    setNumberValidationMessage(true)
                                                                } else {
                                                                    setNumberValidationMessage(false)
                                                                    setSearchText(value)
                                                                }
                                                            } else {
                                                                setNumberValidationMessage(false)
                                                                setSearchText("")
                                                            }
                                                        }
                                                    }}
                                                />
                                                {showFilterCriteriaMandatoryMessage? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{filterCriteriaMandatoryMessage}</div></>:null}
                                                {requiredErrorMessage? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}
                                                {numberValidationMessage? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Please provide only numbers!':'দয়া করে শুধু সংখ্যা লিখুন!'}</div></>:null}
                                                {serialNumberIsInvalid? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Serial number must be of 8 digits!':'ক্রমিক নাম্বার অবশ্যই ৮ সংখ্যার হতে হবে!'}</div></>:null}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}}>
                                                {language === 'EN'? 'Reset filter':'পুনরায় মানদণ্ড বাছাই করুন'}
                                            </Button>
                                            <Button variant="warning" size={'sm'} type="button"
                                                    disabled={(numberValidationMessage || requiredErrorMessage)? true:false}
                                                    onClick={()=> {filterTheList()}} className={'ml-3'}>
                                                {props.deviceInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}
                                                {language === 'EN'? 'Apply filter':'টোকেন খুঁজুন'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {props.listLoading !== true ? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন টোকেন নেই'}
                                columns={tokenListColumns}
                                data={tokenList}
                                showToolbar={false}
                                asyncPagination={true}
                                isLoading={props.tableLoading}
                                count={props.tokenList?.count}
                                itemsPerPage={props.tokenList?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                                actionColumnIndex={-1}
                                actions={(authorization &&
                                    authorization.hasOwnProperty(tokenServiceName) &&
                                    authorization[tokenServiceName].hasOwnProperty(tokenFeatureName) &&
                                    authorization[tokenServiceName][tokenFeatureName].includes(deleteActionName))? [{}]:[]}
                                actionButtonVariant={['danger']}
                                actionButtonSize={'sm'}
                                actionButtonClickEvent={[initiateTokenCancellationProcess]}
                                actionButtonText={language === 'EN'? ['Cancel']:['বাতিল করুন']}
                                dataToCheckToMakeActionButtonDisable={{'is_latest_token': "True"}}
                            /> : <LoadingSpinner loadingSubText={language == 'EN'? 'Generating token list ..': 'টোকেনের তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>
                {renderTokenCancelModal()}
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}
              
            `}</style>
        </>
    );
};

TokenList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.tokensReducer.isLoading,
        tableLoading: state.tokensReducer.tableLoading,
        statusCode: state.tokensReducer.statusCode,
        tokenList: state.tokensReducer.tokenList,
        listLoading: state.tokensReducer.listLoading,
        tokenCancelled: state.tokensReducer.tokenCancelled,
        successMessageForTokenCancel: state.tokensReducer.successMessageForTokenCancel,
        errorMessageForTokenCancel: state.tokensReducer.errorMessageForTokenCancel,
        tokenCancelletionInProgress: state.tokensReducer.tokenCancelletionInProgress
    }
}

export default connect(mapStateToProps, actions)(withRoles(TokenList));
