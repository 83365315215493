import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Breadcrumb, Button, Form, Spinner, Badge} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner} from '../../utils'
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable'
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import moment from "moment";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../../auth/authorization";



const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const EvList = (props) => {

    const [language, setLanguage] = useState('EN');
    const [authorization, setAuthorization] = useState(null);
    const subHeader = useSubheader();
    const [evList, setEvList] = useState([]);
    const [page, setPage] = useState(1);



    const onChangePage = (event, newPage) => {
        setPage(newPage) 
        props.GET_EV_LIST({page: newPage, status: 'active'});
        
    }


    const evListColumns = [
        {
            field: 'serial_number',
            title: language === 'EN'? 'EV ID': 'ইভি',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
        },
        {
            field: 'device_serial_number',
            title: language === 'EN'? 'Smart Battery ID': 'যন্ত্র',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
        },
        {
            field: 'garage_name',
            title: language === 'EN'? 'Garage Name': 'গ্যারেজ',
            emptyValue: ()=>{
                return "N/A"
            },
            disableClick: true,
        }
    ]

    const {
        GET_EV_LIST
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setAuthorization(props.authorization)
        setLanguage(props.language)
        GET_EV_LIST({page: 1, status: 'active'});
        subHeader.setActionButtons(null)
        subHeader.setBreadCrumbComponent(null)

        // return ()=>{
        //     props.RETURN_TO_INITIAL_STATE();
        // }
    }, []);
 

    useEffect(() => {
        if (props.evList) {
            const evList = props.evList.results;
            setEvList(evList);
        }
    }, [props.evList]);


    return (
        <div>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"EVs"}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{language === 'EN'? 'EVs':'ইভি'}</b></h1>
                            </Toolbar.Title>
                        </Toolbar>
                    </div>
                </div>
                <hr/> 

                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {props.listLoading !== true ? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'No EV Found'}
                                columns={evListColumns}
                                data={evList}
                                showToolbar={false}
                                asyncPagination={true}
                                isLoading={props.tableLoading}
                                count={props.evList?.count}
                                itemsPerPage={props.evList?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                                actionColumnIndex={-1}
                                // actions={checkAuthorization(authorization, driverServiceName, driverFeatureName, [updateActionName])? [{}]:[]}
                                actionButtonVariant={['warning']}
                                actionButtonSize={'sm'}
                            /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Generating ev list ..': 'ইভির তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

EvList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.evsReducer.isLoading,
        tableLoading: state.evsReducer.tableLoading,
        statusCode: state.evsReducer.statusCode,
        evList: state.evsReducer.evList
    }
}

export default connect(mapStateToProps, actions)(withRoles(EvList));
