import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_DRIVER_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/drivers', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.GET_DRIVER_LIST_REQUEST,
                actionTypes.GET_DRIVER_LIST_SUCCESS,
                actionTypes.GET_DRIVER_LIST_FAILURE
            ]
        }
    }),

    // TODO: Though 'Driver' feature hasn't been used, commenting here to keep track
    // TODO: As district list API has been updated, if we want to get all district list
    // TODO: We need pass filter param - Noor Reza, 30th Nov, 1:56 PM
    COLLECT_DISTRICT_LIST: ()=>({
        [CALL_API]: {
            endpoint: '/geo/districts',
            method: 'GET',
            types: [
                actionTypes.COLLECT_DISTRICT_LIST_REQUEST,
                actionTypes.COLLECT_DISTRICT_LIST_SUCCESS,
                actionTypes.COLLECT_DISTRICT_LIST_FAILURE
            ]
        }
    }),

    COLLECT_UPAZILA_LIST: (districtId)=>({
        [CALL_API]: {
            endpoint: '/geo/upazilas/' + districtId,
            method: 'GET',
            types: [
                actionTypes.COLLECT_UPAZILA_LIST_REQUEST,
                actionTypes.COLLECT_UPAZILA_LIST_SUCCESS,
                actionTypes.COLLECT_UPAZILA_LIST_FAILURE
            ]
        }
    }),

    VERIFY_PHONE: (phoneNumber)=>({
        [CALL_API]: {
            endpoint: `/drivers/` + phoneNumber,
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.VERIFY_PHONE_REQUEST,
                actionTypes.VERIFY_PHONE_SUCCESS,
                actionTypes.VERIFY_PHONE_FAILURE,
            ]
        }
    }),

    CREATE_DRIVER: (payload)=>({
        [CALL_API]: {
            endpoint: '/drivers',
            method: 'POST',
            body : payload,
            backend_slug: 'garage_management',
            types: [
                actionTypes.CREATE_DRIVER_REQUEST,
                actionTypes.CREATE_DRIVER_SUCCESS,
                actionTypes.CREATE_DRIVER_FAILURE,
            ]
        }
    }),

    EDIT_DRIVER: (payload)=>({
        [CALL_API]: {
            endpoint: '/drivers',
            method: 'PUT',
            body : payload,
            backend_slug: 'garage_management',
            types: [
                actionTypes.EDIT_DRIVER_REQUEST,
                actionTypes.EDIT_DRIVER_SUCCESS,
                actionTypes.EDIT_DRIVER_FAILURE,
            ]
        }
    }),
    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})

};
