import React, {useEffect, useState} from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import {Toolbar} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {Button, Form, Popover, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer} from '../../utils'
import {LoadingSpinner, SolInput} from '../../../../components';
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import moment from "moment";
import {toast} from "react-toastify";
import {exportInExcel} from "../../../../utils/excelExport";
import {toBengaliNumber} from "bengali-number";
import {isNullorUndefined} from "../../../../utils/utilityFunctions";
import { parseParams} from "../../../../utils/utilityFunctions";
import {Controller, useForm} from "react-hook-form";
import {settlementReducer} from "../../reducer";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {checkAuthorization} from "../../../auth/authorization";
import InfoIcon from "@mui/icons-material/Info";
import Overlay from "react-bootstrap/Overlay";
import {DayPickerRangeController} from "react-dates";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const Settlements = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const [showFilter, setShowFilter] = useState(true);
    const [urlIsEdited, setUrlIsEdited] = useState(false);

    const [financiers, setFinanciers] = useState([]);
    const [financier, setFinancier] = useState("");
    const [financierFromUrl, setFinancierFromUrl] = useState("");

    const [targetForSettlementPeriodField, setTargetForSettlementPeriodField] = useState(null);
    const [showSettlementPeriodDateRangePicker, setShowSettlementPeriodDateRangePicker] = useState(false);
    const [startDateForSettlementPeriod, setStartDateForSettlementPeriod] = useState(null);
    const [endDateForSettlementPeriod, setEndDateForSettlementPeriod] = useState(null);
    const [dateRangeForSettlementPeriod, setDateRangeForSettlementPeriod] = useState('');
    const [focusedInputForSettlementPeriod, setFocusedInputForSettlementPeriod] = useState('startDate');

    const settlementTypes = [
        {label: 'Collection', value: 'collection'},
        {label: 'Disbursement', value: 'disbursement'}
    ]
    const [settlementType, setSettlementType] = useState("");

    const [settlements, setSettlements] = useState([]);
    const transactionServiceName = getServiceName('transactionService')
    const invoiceSettlementFeatureName = getFeatureName('invoiceSettlementFeature')
    const createActionName = getActionName('createAction')
    const subHeader = useSubheader();

    const [page, setPage] = useState(1);
    const [filterCriteria, setFilterCriteria] = useState({});
    const [language, setLanguage] = useState(null);
    const [queryParams, setQueryParams] = useState(null);
    const [initialfilterParam, setInitialfilterParam] = useState(null);
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null);

    const pageTitle = 'Settlements'

    const column1 = 'Settlement ID'
    const column2 = 'Settled On'
    const column3 = 'Settlement Period'
    const column4 = 'Financier'
    const column5 = 'Settlement Type'
    const column6 = 'Total Payment'
    const column7 = 'Charges'
    const column8 = 'SOLshare Revenue'
    const column9 = 'Settled Amount'
    const column10 = 'Remarks'

    const periodTextGeneratorForSettlementPeriod = (fromDate, toDate) => {
        // TODO: (Most of the) Following process has been used at the invoice table at the manual cash payment process
        // TODO: So it can be taken into a global function
        // TODO: Also it can be used while showing period text for date range based filter at the exported excel file generation process
        let periodText = ''
        let startDate = moment(fromDate, "YYYY-MM-DD").format("MMM DD YYYY")
        let endDate = moment(toDate, "YYYY-MM-DD").format("MMM DD YYYY")
        let splittedStartDate = startDate.split(" ")
        let splittedEndDate = endDate.split(" ")
        if (splittedStartDate[2] === splittedEndDate[2]) {
            // Year is equal
            if (splittedStartDate[0] === splittedEndDate[0]) {
                // Both year and month is equal
                periodText = splittedStartDate[0] + " " + splittedStartDate[1] + " - " + splittedEndDate[1] + ", " + splittedStartDate[2]
            } else {
                // Year equal but month is not
                periodText = splittedStartDate[0] + " " + splittedStartDate[1] + " - " + splittedEndDate[0] + " " + splittedEndDate[1] + ", " + splittedStartDate[2]
            }
        } else {
            // Year is not equal
            periodText = moment(fromDate).format("MMM DD, YYYY") + ' - ' + moment(toDate).format("MMM DD, YYYY")
        }
        return periodText
    }

    const settlementListColumns =  [
        {
            field: 'settlement_id',
            title: column1,
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return <>
                    <h6><strong>#{rowData.settlement_id}</strong></h6>
                    <span>{rowData.reference}</span>
                </>
            }
        },
        {
            field: 'created_at',
            title: column2,
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return <>
                    <h6><strong>{moment(rowData.created_at).format("MMM DD, YYYY")}</strong></h6>
                </>
            }
        },
        {
            field: 'settlement_start_date',
            title: column3,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            emptyValue: ()=>{
                return "-"
            },
            render: (rowData)=>{
                return <><h6><strong>{periodTextGeneratorForSettlementPeriod(rowData.settlement_start_date, rowData.settlement_till_date)}</strong></h6></>
            }
        },
        {
            field: 'financier_name',
            title: column4,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            emptyValue: ()=>{
                return "-"
            },
            render: (rowData)=>{
                let settlementType = settlementTypes.filter(item => item.value === rowData.settlement_type)
                return <>
                    <h6><strong>{rowData.financier_name}</strong></h6>
                    {settlementType[0]? <span>{settlementType[0].label}</span>:''}
                </>
            }
        },
        {
            field: 'paid_total',
            title: column6,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "-"
            },
            render: (rowData)=>{
                return <><h6 style={{whiteSpace: 'nowrap'}}><strong>Tk {new Intl.NumberFormat('en-IN').format(rowData.paid_total.toFixed(2))}</strong></h6></>
            }
        },
        {
            field: 'mfs_charge_amount',
            title: column7,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "-"
            },
            render: (rowData) => {
                return (
                    <>
                        <h6 style={{whiteSpace: 'nowrap'}}><strong>Tk {new Intl.NumberFormat('en-IN').format(rowData.mfs_charge_amount.toFixed(2))}</strong></h6>
                    </>
                )
            }
        },
        {
            field: 'platform_fee_amount',
            title: column8,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "-"
            },
            render: (rowData) => {
                return (
                    <>
                        <h6 style={{whiteSpace: 'nowrap'}}><strong>Tk {new Intl.NumberFormat('en-IN').format(rowData.platform_fee_amount)}</strong></h6>
                    </>
                )
            }
        },
        {
            field: 'settled_amount',
            title: column9,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "-"
            },
            render: (rowData) => {
                return (
                    <>
                        <h6 style={{whiteSpace: 'nowrap', color: '#0B6E4F'}}><strong>Tk {new Intl.NumberFormat('en-IN').format(rowData.settled_amount)}</strong></h6>
                    </>
                )
            }
        }
    ]

    const {
        GET_ORGANISATIONS,
        COLLECT_SETTLEMENT_LIST,
        COLLECT_SETTLEMENT_LIST_FOR_EXPORT
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setBreadCrumbComponent(null)
        if (props.isSolshareUser) {
            GET_ORGANISATIONS()
        }
        if (!props.history.location.search) {
            props.history.push({
                search: 'page=1'
            })
        }
        if (checkAuthorization(props.authorization, transactionServiceName, invoiceSettlementFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={goToSettlementCreationPage} classes={classes} className={'ml-1'}>
                        New Settlement
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
            props.RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER();
        }
    }, []);

    // ----- Begin: Section related to filter process ---- //
    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            let filter = {}
            if (filterParamForApiCall.financier) {
                filter['financier_guid'] = filterParamForApiCall.financier
            }
            if (filterParamForApiCall.type) {
                filter['settlement_type'] = filterParamForApiCall.type
            }
            if (startDateForSettlementPeriod && endDateForSettlementPeriod) {
                // Settlement Period
                filter['settlement_start_date'] = moment(startDateForSettlementPeriod).format("YYYY-MM-DD")
                filter['settlement_till_date'] = moment(endDateForSettlementPeriod).format("YYYY-MM-DD")
            }
            setFilterCriteria({...filter})
            filter['page'] = page
            COLLECT_SETTLEMENT_LIST(filter)
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);

    const filterTheList = (data) => {
        setPage(1)
        let searchText = 'page=1'
        if (data.financier) {
            searchText += '&financier=' + data.financier.value
            setFinancier(data.financier)
        }
        if (data.settlementType) {
            searchText += '&type=' + data.settlementType.value
            setSettlementType(data.settlementType)
        }
        if (startDateForSettlementPeriod && endDateForSettlementPeriod) {
            searchText += '&settlement_period_from=' + moment(startDateForSettlementPeriod).format("YYYY-MM-DD")
            searchText += '&settlement_period_to=' + moment(endDateForSettlementPeriod).format("YYYY-MM-DD")
        }
        props.history.push({
            search: searchText
        })
    }

    const resetFilter = () => {
        resetFilterValues()
        clearErrors()
        if (Object.keys(filterCriteria).length > 0) {
            // Resetting
            setPage(1)
            props.history.push({
                search: 'page=1'
            })
        }
    }

    const resetFilterValues = () => {
        setValue('financier', '')
        setFinancier('')
        setFinancierFromUrl('')

        setValue('settlementType', '')
        setSettlementType('')

        setValue('settlementPeriod', '')
        setStartDateForSettlementPeriod(null)
        setEndDateForSettlementPeriod(null)
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        if (props.financiers) {
            const financiers = props.financiers;
            if (financiers.length > -1) {
                setFinanciers(financiers.map((item) => {
                    if (financierFromUrl && financierFromUrl === item.pk) {
                        let selectedFinancier = item
                        selectedFinancier['label'] = item.name
                        selectedFinancier['value'] = item.pk
                        setValue('financier', selectedFinancier)
                        setFinancier(selectedFinancier)
                    }
                    return {
                        ...item,
                        label: item.name,
                        value: item.pk
                    }
                }))
            }
        }
    }, [props.financiers])

    useEffect(() => {
        if (props.settlements?.results?.length > -1) {
            const settlements = props.settlements.results;
            setSettlements(settlements.map((item) => {
                return {
                    ...item
                }
            }))
        }
    }, [props.settlements])

    // Settlement Period
    const disableFutureDatesForSettlementPeriod = (current) => {
        const today = moment()
        return current.isAfter(today)
    }
    const [clickCountForSettlementPeriod, setClickCountForSettlementPeriod] = useState(0)
    const onDatesChangeForSettlementPeriod = ({startDate, endDate}) => {
        setClickCountForSettlementPeriod(clickCountForSettlementPeriod + 1)
        if (startDate && endDate) {
            setStartDateForSettlementPeriod(startDate)
            setEndDateForSettlementPeriod(endDate)
            if (clickCountForSettlementPeriod === 1) {
                setClickCountForSettlementPeriod(0)
                setShowSettlementPeriodDateRangePicker(false)
            }
        } else if (startDate && !endDate) {
            setStartDateForSettlementPeriod(startDate)
            setEndDateForSettlementPeriod(startDate)
        }
    }
    useEffect(() => {
        setDateRangeForSettlementPeriod(startDateForSettlementPeriod && endDateForSettlementPeriod ?
            `${moment(startDateForSettlementPeriod).format("DD MMM, YYYY")} - ${moment(endDateForSettlementPeriod).format("DD MMM, YYYY")}` : '');
    }, [startDateForSettlementPeriod, endDateForSettlementPeriod])
    const handleFocusChangeForSettlementPeriod = (input) => {
        if (!input) {
            setFocusedInputForSettlementPeriod('startDate')
        } else {
            setFocusedInputForSettlementPeriod(input)
        }
    }

    const showHelpfulText = () => {
        return <>
            <div className={'container'} style={{color: "#F18D00"}}><InfoIcon size={'sm'}/> Selection is completed
                after <strong>two clicks on a single date</strong> or <strong>single click on two dates.</strong></div>
        </>
    }
    const popoverForSettlementPeriodField = () => {
        return <>
            <Overlay
                show={showSettlementPeriodDateRangePicker}
                target={targetForSettlementPeriodField}
                placement="bottom-start"
            >
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerRangeController
                            startDate={startDateForSettlementPeriod}
                            endDate={endDateForSettlementPeriod}
                            numberOfMonths={2}
                            minimumNights={0}
                            firstDayOfWeek={0}
                            isOutsideRange={disableFutureDatesForSettlementPeriod}
                            onDatesChange={onDatesChangeForSettlementPeriod}
                            focusedInput={focusedInputForSettlementPeriod}
                            onFocusChange={(focusedInput) => handleFocusChangeForSettlementPeriod(focusedInput)}
                            renderKeyboardShortcutsButton={showHelpfulText}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "settlementPeriod") {
                                    setShowSettlementPeriodDateRangePicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }

    // ----- End: Section related to filter process ---- //


    // ----- Begin: Section related to shareable link/handling query params ---- //
    const checkDateValidity = (dateString) => {
        const date = moment(dateString, 'YYYY-MM-DD')
        return date.isValid()
    }
    const validateIfFuture = (keyword) => {
        const date = moment(keyword, 'YYYY-MM-DD')
        const todayDate = moment()
        return date.isAfter(todayDate, 'day')
    }

    const validateIfToDateIsOlderThanFromDate = (from, to) => {
        const fromDate = moment(from, 'YYYY-MM-DD')
        const toDate = moment(to, 'YYYY-MM-DD')
        return fromDate.isAfter(toDate, 'day')
    }

    const validationKeyArray = ['page', 'financier', 'type', 'settlement_period_from', 'settlement_period_to']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (!isNullorUndefined(initialfilterParam)) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            if (initialfilterParam.financier) {
                let financierGuidFromUrl = initialfilterParam.financier
                setFinancierFromUrl(financierGuidFromUrl)
            }
            if (initialfilterParam.type) {
                let settlementType = settlementTypes.filter(item => item.value === initialfilterParam.type)
                if (settlementType.length > 0) {
                    setValue('settlementType', settlementType[0])
                    setSettlementType(settlementType[0])
                }
            }
            // Settlement Period
            if (initialfilterParam.settlement_period_from && initialfilterParam.settlement_period_to) {
                if (!checkDateValidity(initialfilterParam.settlement_period_from) || !checkDateValidity(initialfilterParam.settlement_period_to)) {
                    setValue('settlementPeriod', '')
                    setError('settlementPeriod', {
                        type: 'custom',
                        message: "Invalid date! (Correct format: YYYY-MM-DD)"
                    })
                } else if (validateIfFuture(initialfilterParam.settlement_period_from) || validateIfFuture(initialfilterParam.settlement_period_to)) {
                    setValue('settlementPeriod', '')
                    setError('settlementPeriod', {
                        type: 'custom',
                        message: "Future date can't be selected!"
                    })
                } else if (validateIfToDateIsOlderThanFromDate(initialfilterParam.from_date, initialfilterParam.to_date)) {
                    setValue('settlementPeriod', '')
                    setError('settlementPeriod', {
                        type: 'custom',
                        message: 'Period start date should be older!'
                    })
                } else {
                    let settlementStartDate = moment(initialfilterParam.settlement_period_from, "YYYY-MM-DD")
                    let settlementEndDate = moment(initialfilterParam.settlement_period_to, "YYYY-MM-DD")
                    setStartDateForSettlementPeriod(settlementStartDate)
                    setEndDateForSettlementPeriod(settlementEndDate)
                    clearErrors('settlementPeriod')
                }
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])
    // ----- End: Section related to shareable link/handling query params ---- //

    // ----- Begin: Section related to export data ---- //
    const exportData = () => {
        COLLECT_SETTLEMENT_LIST_FOR_EXPORT({...filterCriteria})
    }

    useEffect(() => {
        if (props.settlementsForExport && props.settlementsForExport.length > -1) {
            const settlements = props.settlementsForExport
            let presentTime = moment()
            let settlementDataLength = settlements.length
            let totalLoanText = "Total Settlements: " + settlementDataLength
            let exportText = "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A")
            let excelRows = [
                [pageTitle, "", "", "", "", "", "", "", "", ""],
                [totalLoanText, "", "", "", "", "", "", "", "", ""],
                [exportText, "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", ""],
                [column1, column2, column3, column4, column5, column6, column7, column8, column9, column10]
            ]
            if (financier) {
                let text = "Financier: " + financier.label
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
            if (settlementType) {
                let text = "Settlement Type: " + settlementType.label
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
            if (startDateForSettlementPeriod && endDateForSettlementPeriod) {
                let text = "Settlement Period: " + periodTextGeneratorForSettlementPeriod(startDateForSettlementPeriod, endDateForSettlementPeriod)
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
            for (let i=0; i<settlements.length; i++) {
                excelRows.push(
                    [
                        settlements[i].reference? settlements[i].settlement_id + " (Ref: " + settlements[i].reference + ")":settlements[i].settlement_id,
                        moment(settlements[i].created_at).format("MMM DD, YYYY"),
                        periodTextGeneratorForSettlementPeriod(settlements[i].settlement_start_date, settlements[i].settlement_till_date),
                        settlements[i].financier_name,
                        settlementTypes.filter(item => item.value === settlements[i].settlement_type)[0].label,
                        new Intl.NumberFormat('en-IN').format(settlements[i].paid_total.toFixed(2)),
                        new Intl.NumberFormat('en-IN').format(settlements[i].mfs_charge_amount.toFixed(2)),
                        new Intl.NumberFormat('en-IN').format(settlements[i].platform_fee_amount.toFixed(2)),
                        new Intl.NumberFormat('en-IN').format(settlements[i].settled_amount.toFixed(2)),
                        settlements[i].notes? settlements[i].notes:'-'
                    ])
            }
            let noDataAvailableText =  "No settlements available"
            settlements.length > 0? excelRows.push(["", "", "", "", "", "", "", "", "", ""]):
                excelRows.push(["", "", "", "", noDataAvailableText, "", "", "", "", ""])
            let fileName = 'settlements_'
            exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
        }
    }, [props.settlementsForExport])
    // ----- End: Section related to export data ---- //

    // ----- Begin: Section related to pagination ---- //
    const onChangePage = (event, newPage) => {
        setPage(newPage)
        let searchText = 'page=' + newPage
        // As there is only one filter param
        if (financier) {
            searchText += '&financier=' + financier.value
        }
        if (settlementType) {
            searchText += '&type=' + settlementType.value
        }
        if (startDateForSettlementPeriod && endDateForSettlementPeriod) {
            searchText += '&settlement_period_from=' + moment(startDateForSettlementPeriod).format("YYYY-MM-DD")
            searchText += '&settlement_period_to=' + moment(endDateForSettlementPeriod).format("YYYY-MM-DD")
        }
        props.history.push({
            search: searchText
        })
    }
    // ----- End: Section related to pagination ---- //

    // ----- Begin: Section related to new settlement button click event ---- //
    const goToSettlementCreationPage = () => {
        props.history.push({
            pathname: '/settlements/new'
        })
    }
    // ----- End: Section related to new settlement button click event ---- //

    return (
        <div>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={pageTitle}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{pageTitle}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    {/* As there is only a single filter field - Organization */}
                                    {/* We are showing the filter toggle button only if the user is from SOLshare */}
                                    {props.isSolshareUser? <>
                                        <IconButton onClick={filterToggle} classes={classes}>
                                            <FilterListIcon color="disabled" fontSize="large"/>
                                        </IconButton>
                                    </>:null}
                                    <IconButton classes={classes} onClick={exportData}
                                                disabled={props.loanCollectionInProgress || props.exportDataCollectionInProgress}>
                                        {props.exportDataCollectionInProgress? <Spinner animation={"grow"} variant={'warning'}
                                                                                        size={"lg"}/> :
                                            <img src={require('../../../../utils/asset/download-Button.svg')}
                                                 alt="Download"/>}
                                    </IconButton>
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {/* As there is only a single filter field - Organization */}
                {/* We are showing the financier selection field if the filter form is shown and also */}
                {/* if the user is from SOLshare */}
                {showFilter && props.isSolshareUser?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form onSubmit={handleSubmit(filterTheList)}>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-3'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>Financier</Form.Label>
                                                </div>
                                                <Controller
                                                    control={control}
                                                    name={"financier"}
                                                    render={( { onChange, onBlur, value, name, ref },
                                                              { invalid, isTouched, isDirty }) => (
                                                        <Select
                                                            placeholder={'Select a financier'}
                                                            classNamePrefix="react-select-sol-style"
                                                            isDisabled={props.financierListLoading}
                                                            isLoading={props.financierListLoading}
                                                            maxMenuHeight={200}
                                                            value={value}
                                                            isClearable={true}
                                                            control={control}
                                                            inputRef={ref}
                                                            options={financiers}
                                                            isSearchable={true}
                                                            onChange={(selected, {action}) => {
                                                                onChange(selected)
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-3'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>Settlement Period</Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'settlementPeriod'}
                                                    readOnly={true}
                                                    value={dateRangeForSettlementPeriod}
                                                    placeholder={"Select a date range"}
                                                    onClick={(e) => {
                                                        setTargetForSettlementPeriodField(e.target)
                                                        setShowSettlementPeriodDateRangePicker(!showSettlementPeriodDateRangePicker)
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-3'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>Settlement Type</Form.Label>
                                                </div>
                                                <Controller
                                                    control={control}
                                                    name={"settlementType"}
                                                    render={( { onChange, onBlur, value, name, ref },
                                                              { invalid, isTouched, isDirty }) => (
                                                        <Select
                                                            placeholder={'Select a type'}
                                                            classNamePrefix="react-select-sol-style"
                                                            maxMenuHeight={200}
                                                            value={value}
                                                            isClearable={true}
                                                            control={control}
                                                            inputRef={ref}
                                                            options={settlementTypes}
                                                            isSearchable={true}
                                                            onChange={(selected, {action}) => {
                                                                onChange(selected)
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="warning" size={'sm'} type="submit"
                                                    disabled={props.settlementCollectionInProgress}>
                                                {language === 'EN'? 'Apply filter':'ফিল্টার করুন'}
                                            </Button>
                                            <Button variant="outline-dark" size={'sm'}
                                                    disabled={props.settlementCollectionInProgress}
                                                    onClick={()=> {resetFilter()}} className={'ml-3'}>
                                                {language === 'EN'? 'Reset filter':'ফিল্টার করার তথ্য পরিষ্কার করুন'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.settlementCollectionInProgress? <DataTable
                                language={language}
                                noDataAvailableMessageInEnglish={'No settlements available'}
                                columns={settlementListColumns}
                                data={settlements}
                                showToolbar={false}
                                asyncPagination={true}
                                count={props.settlements?.count}
                                itemsPerPage={props.settlements?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                            /> : <LoadingSpinner loadingSubText={'Collecting settlements ..'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

                {popoverForSettlementPeriodField()}
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

Settlements.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        financiers: state.commonReducer.organisations,
        financierListLoading: state.commonReducer.orgListLoading,
        settlements: state.settlementReducer.settlements,
        settlementCollectionInProgress: state.settlementReducer.settlementCollectionInProgress,
        settlementsForExport: state.settlementReducer.settlementsForExport,
        exportDataCollectionInProgress: state.settlementReducer.exportDataCollectionInProgress,
        garages: state.commonReducer.garages
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(Settlements));
