import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useForm} from "react-hook-form";
import {Button, Form, InputGroup, OverlayTrigger, Spinner} from "react-bootstrap";
import Select from "react-select";
import {SolInput, SolTextArea} from "../../../../components/SolStyledComponents/components";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {DatePicker} from '@material-ui/pickers/'
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {actions} from "../../actions";
import {connect} from "react-redux";
import {upazillas} from "../../../../utils/countryData/3dListBangladesh";
import {toast} from "react-toastify";
import moment from "moment";
import {requestCycle} from "../../utils";
import * as bdData from "../../../../utils/countryData/3dListBangladesh";
import {devicesReducer} from "../../reducer";
import {Toolbar} from "../components";
import {showNotifications} from "../../../../utils/notification";
import {useSubheader} from "../../../../../_metronic/layout";

const useStylesForDatePicker = makeStyles({
    root: {
        marginTop: '0',
        marginBottom: '0',
        border: 'none',
        width: '100%',
        '& input': {
            color: 'black',
            padding: "0 10px 0 10px",
            height: "38px",
            outline: "none",
        },
        '& input:hover, input:focus, input:active': {
            border: "1px solid #F18D00",
            boxShadow: "0px 0px 0px 3.2px r gba(241, 141, 0, 0.1)",
            outline: "none",
        }
    },
});
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#ffffff',
        }
    },
});
const CreateDevice = props => {
    const {register, errors, control} = useForm();
    const [dongleSerialNumber, setDongleSerialNumber] = useState(null);
    const [batterySerialNumber, setBatterySerialNumber] = useState(null);
    const [serialNumberInputIsValid, setSerialNumberInputIsValid] = useState(false);
    const [batterySerialNumberInputIsValid, setBatterySerialNumberInputIsValid] = useState(false);
    const subHeader = useSubheader();

    const [requiredErrorMessage, setRequiredErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false);

    const generalErrorMessage = "Please provide above information!"

    const {
        ADD_DEVICE,
        RETURN_TO_INITIAL_STATE_FOR_SMART_BATTERY_CREATION
    } = props;

    const onSubmit=()=>{
        if (!dongleSerialNumber || !batterySerialNumber) {
            setRequiredErrorMessage(true)
            return;
        }
        const payload = {
            dongle_serial_number: dongleSerialNumber,
            battery_serial_number: batterySerialNumber
        }
        ADD_DEVICE(payload);
    };

    useEffect(() => {
        if (props.successMessage !== undefined) {
            showNotifications('success', props.successMessage)
        } else if (props.errorMessage !== undefined) {
            setErrorMessage(showNotifications('error', props.errorMessage, true))
        }
    }, [props.successMessage, props.errorMessage]);

    useEffect(()=> {
        subHeader.setActionButtons(null);

        return () => {
            RETURN_TO_INITIAL_STATE_FOR_SMART_BATTERY_CREATION()
        }
    },[])

    useEffect(()=> {
        if (props.deviceCreated === requestCycle.success) {
            props.history.push('/devices/list/');
        }
    },[props.deviceCreated])
 
    return (
       <ContentWrapper showCardHeader={false} pageTitle={'Add Smart Battery'} showBackButton={false} isLoading={false}>
               <div className={"row mb-6"}>
                   <div className={"col-md-12"}>
                       <Toolbar>
                           <Toolbar.Title>
                               <h1><b>Add Smart Battery</b></h1>
                           </Toolbar.Title>
                       </Toolbar>
                   </div>
               </div>
               <div className={"row"}>
                   <div className={"col-md-12"}>
                       <Form>
                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <Form.Label>Dongle Serial Number<span className="required text-danger">*</span></Form.Label>
                                       <SolInput
                                           type="text"
                                           name={"dongleSerialNumber"}
                                           placeholder={"Serial number of the dongle.."}
                                           autoComplete={"off"}
                                           onChange={(e) => {
                                               let value = e.target.value
                                               if (value) {
                                                   if (isNaN(value) || value.includes('.')) {
                                                       setSerialNumberInputIsValid(true)
                                                   } else {
                                                       setSerialNumberInputIsValid(false)
                                                       setDongleSerialNumber(value)
                                                   }
                                               } else {
                                                   setSerialNumberInputIsValid(false)
                                                   setDongleSerialNumber(null)
                                               }
                                           }}
                                       />
                                       {serialNumberInputIsValid? <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;Please provide numbers only!</div>:null}
                                       {(requiredErrorMessage && !dongleSerialNumber && !serialNumberInputIsValid)? <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalErrorMessage}</div>:null}
                                       {errorMessage.dongleSerialNumber? <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.dongleSerialNumber}</div>:null}
                                   </Form.Group>
                               </div>
                           </div>
                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <Form.Label>Battery Serial Number<span className="required text-danger">*</span></Form.Label>
                                       <SolInput
                                           type="text"
                                           name={"batterySerialNumber"}
                                           placeholder={"Serial number of the battery.."}
                                           autoComplete={"off"}
                                           onChange={(e) => {
                                               let value = e.target.value
                                               if (value) {
                                                    setBatterySerialNumberInputIsValid(false)
                                                    setBatterySerialNumber(value)
                                               } else {
                                                    setBatterySerialNumberInputIsValid(false)
                                                    setBatterySerialNumber(null)
                                               }
                                           }}
                                       />
                                       {batterySerialNumberInputIsValid? <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;Please provide numbers only!</div>:null}
                                       {(requiredErrorMessage && !batterySerialNumber && !batterySerialNumberInputIsValid)? <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalErrorMessage}</div>:null}
                                       {errorMessage.batterySerialNumber? <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.batterySerialNumber}</div>:null}
                                   </Form.Group>
                               </div>
                           </div>
 

                           <div className={'row'}>
                               <div className={"col-md-12"}>
                                   <Button variant="dark" size={'md'} onClick={()=>props.history.goBack()} style={{
                                       backgroundColor: '#8C8C9B',
                                       outline: '#8C8C9B',
                                       border: '#8C8C9B',
                                   }}>
                                       <i className='fa fa-times' aria-hidden='true'/>&nbsp;Cancel
                                   </Button>
                                   <Button variant="warning" type="button" className={"ml-3"}
                                           onClick={()=>onSubmit()}>
                                       {props.deviceInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}
                                       <i className='fa fa-paper-plane' aria-hidden='true'/>&nbsp;Submit
                                   </Button>
                               </div>
                           </div>
                       </Form>
                   </div>
               </div>
           <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
       </ContentWrapper>
    );
};

CreateDevice.propTypes = {};


const mapStateToProps=(state)=>{

    return {
        isLoading: state.devicesReducer.isLoading,
        filterDropdownLoading: state.devicesReducer.filterDropdownLoading,
        firmwares: state.devicesReducer.firmwares,
        deviceCreated: state.devicesReducer.deviceCreated,
        deviceInfoSubmitting: state.devicesReducer.deviceInfoSubmitting,
        successMessage: state.devicesReducer.successMessage,
        errorMessage: state.devicesReducer.errorMessage
    }
}

export default connect(mapStateToProps, actions) (CreateDevice);
