import React, {useEffect, useState} from 'react';
import { useLocation } from "react-router";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {makeStyles} from "@material-ui/styles";
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import {useForm} from "react-hook-form";
import {useMediaQuery} from "react-responsive";
import BranchAddEdit from '../../../../components/branchRelatedComponents/branchAddEditForm';
import {toast} from "react-toastify";
import {requestCycle} from "../../../borrowers/utils";
import {showNotifications} from "../../../../utils/notification";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const BranchCreation = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [language, setLanguage] = useState(null);
    const [orgGuid, setOrgGuid] = useState('');
    const [orgName, setOrgName] = useState('');
    const location = useLocation();
    const subHeader = useSubheader();

    const pageTitle = 'New Branch'

    const {
        CREATE_NEW_BRANCH,
        RETURN_TO_INITIAL_STATE,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setBreadCrumbComponent(null)
        subHeader.setActionButtons(null)
        if (location.orgData) {
            let organizationInfo = location.orgData
            setOrgGuid(organizationInfo.pk)
            setOrgName(organizationInfo.name)
            sessionStorage.setItem('orgData', JSON.stringify(organizationInfo))
        } else if (sessionStorage.getItem('orgData')) {
            let organizationInfo = JSON.parse(sessionStorage.getItem('orgData'))
            setOrgGuid(organizationInfo.pk)
            setOrgName(organizationInfo.name)
        } else {
            toast.warning('Please select a financier at first!')
            props.history.push({
                pathname: '/financiers/list'
            })
        }
        return ()=>{
            RETURN_TO_INITIAL_STATE()
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
        }
    }, []);


    // ---- Begin: Branch add --- //
    const closeBranchAddForm = () => {
        sessionStorage.removeItem('orgData')
        props.history.push({
            pathname: '/financiers/list'
        })
    }

    const saveBranch = (data) => {
        CREATE_NEW_BRANCH({...data, 'division': data.division.label, 'district': data.district.label,
            'upazila': data.upazila.label, 'zone': data.zone?.label, 'area': data.area?.label,
            'organization_guid': orgGuid})
    }

    useEffect(() => {
        if (props.branchCreated && props.branchCreated === requestCycle.success) {
            toast.success("New branch is added successfully!")
            closeBranchAddForm()
        }
    }, [props.branchCreated])

    useEffect(() => {
        if (props.errorMessageForBranchCreation) {
            showNotifications('error', props.errorMessageForBranchCreation)
        }
    }, [props.errorMessageForBranchCreation])
    // ---- End: Branch add --- //

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={pageTitle}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{orgName? orgName + ':':''} {pageTitle}</b></h1>
                            </Toolbar.Title>
                        </Toolbar>
                    </div>
                </div>
                <hr/>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <BranchAddEdit submitFormData={saveBranch} closeForm={closeBranchAddForm} modalFooter={false}
                                       editable={false} branchData={{}} orgGuid={orgGuid}/>
                    </div>
                </div>

            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    );
};

BranchCreation.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        branchCreated: state.commonReducer.branchCreated,
        errorMessageForBranchCreation: state.commonReducer.errorMessageForBranchCreation
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(BranchCreation));
