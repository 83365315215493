export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    GET_DEVICE_LIST_REQUEST: 'GET_DEVICE_LIST_REQUEST',
    GET_DEVICE_LIST_SUCCESS: 'GET_DEVICE_LIST_SUCCESS',
    GET_DEVICE_LIST_FAILURE: 'GET_DEVICE_LIST_FAILURE',

    GET_FIRMWARE_LIST_REQUEST: 'GET_FIRMWARE_LIST_REQUEST',
    GET_FIRMWARE_LIST_SUCCESS: 'GET_FIRMWARE_LIST_SUCCESS',
    GET_FIRMWARE_LIST_FAILURE: 'GET_FIRMWARE_LIST_FAILURE',

    ADD_DEVICE_REQUEST: 'ADD_DEVICE_REQUEST',
    ADD_DEVICE_SUCCESS: 'ADD_DEVICE_SUCCESS',
    ADD_DEVICE_FAILURE: 'ADD_DEVICE_FAILURE',

    GET_HARDWARE_LIST_REQUEST: "GET_HARDWARE_LIST_REQUEST",
    GET_HARDWARE_LIST_SUCCESS: "GET_HARDWARE_LIST_SUCCESS",
    GET_HARDWARE_LIST_FAILURE: "GET_HARDWARE_LIST_FAILURE",

    GET_VENDOR_LIST_REQUEST: 'GET_VENDOR_LIST_REQUEST',
    GET_VENDOR_LIST_SUCCESS: 'GET_VENDOR_LIST_SUCCESS',
    GET_VENDOR_LIST_FAILURE: 'GET_VENDOR_LIST_FAILURE',

    GET_MODELS_LIST_REQUEST: 'GET_MODELS_LIST_REQUEST',
    GET_MODELS_LIST_SUCCESS: 'GET_MODELS_LIST_SUCCESS',
    GET_MODELS_LIST_FAILURE: 'GET_MODELS_LIST_FAILURE',


    GET_GARAGE_LIST_REQUEST: 'GET_GARAGE_LIST_REQUEST',
    GET_GARAGE_LIST_SUCCESS: 'GET_GARAGE_LIST_SUCCESS',
    GET_GARAGE_LIST_FAILURE: 'GET_GARAGE_LIST_FAILURE',

    GET_BRANCH_LIST_REQUEST: 'GET_BRANCH_LIST_REQUEST',
    GET_BRANCH_LIST_SUCCESS: 'GET_BRANCH_LIST_SUCCESS',
    GET_BRANCH_LIST_FAILURE: 'GET_BRANCH_LIST_FAILURE',

    CREATE_NEW_BRANCH_REQUEST: 'CREATE_NEW_BRANCH_REQUEST',
    CREATE_NEW_BRANCH_SUCCESS: 'CREATE_NEW_BRANCH_SUCCESS',
    CREATE_NEW_BRANCH_FAILURE: 'CREATE_NEW_BRANCH_FAILURE',

    // TODO: Fix typo at the followings 'GARAGES'
    GET_GARAGAES_DETAILS_REQUEST: 'GET_GARAGAES_DETAILS_REQUEST',
    GET_GARAGAES_DETAILS_SUCCESS: 'GET_GARAGAES_DETAILS_SUCCESS',
    GET_GARAGAES_DETAILS_FAILURE: 'GET_GARAGAES_DETAILS_FAILURE',

    GET_ORGANISATIONS_REQUEST: 'GET_ORGANISATIONS_REQUEST',
    GET_ORGANISATIONS_SUCCESS: 'GET_ORGANISATIONS_SUCCESS',
    GET_ORGANISATIONS_FAILURE: 'GET_ORGANISATIONS_FAILURE',

    COLLECT_EVENTS_HISTORY_REQUEST: 'COLLECT_EVENTS_HISTORY_REQUEST',
    COLLECT_EVENTS_HISTORY_SUCCESS: 'COLLECT_EVENTS_HISTORY_SUCCESS',
    COLLECT_EVENTS_HISTORY_FAILURE: 'COLLECT_EVENTS_HISTORY_FAILURE',

    SAVE_EVENT_REQUEST: 'SAVE_EVENT_REQUEST',
    SAVE_EVENT_SUCCESS: 'SAVE_EVENT_SUCCESS',
    SAVE_EVENT_FAILURE: 'SAVE_EVENT_FAILURE',

    COLLECT_BORROWERS_REQUEST: 'COLLECT_BORROWERS_REQUEST',
    COLLECT_BORROWERS_SUCCESS: 'COLLECT_BORROWERS_SUCCESS',
    COLLECT_BORROWERS_FAILURE: 'COLLECT_BORROWERS_FAILURE',

    COLLECT_GARAGE_DETAILS_REQUEST: 'COLLECT_GARAGE_DETAILS_REQUEST',
    COLLECT_GARAGE_DETAILS_SUCCESS: 'COLLECT_GARAGE_DETAILS_SUCCESS',
    COLLECT_GARAGE_DETAILS_FAILURE: 'COLLECT_GARAGE_DETAILS_FAILURE',

    COLLECT_DIVISIONS_REQUEST: 'COLLECT_DIVISIONS_REQUEST',
    COLLECT_DIVISIONS_SUCCESS: 'COLLECT_DIVISIONS_SUCCESS',
    COLLECT_DIVISIONS_FAILURE: 'COLLECT_DIVISIONS_FAILURE',

    COLLECT_DISTRICTS_REQUEST: 'COLLECT_DISTRICTS_REQUEST',
    COLLECT_DISTRICTS_SUCCESS: 'COLLECT_DISTRICTS_SUCCESS',
    COLLECT_DISTRICTS_FAILURE: 'COLLECT_DISTRICTS_FAILURE',

    COLLECT_SUB_DISTRICTS_REQUEST: 'COLLECT_SUB_DISTRICTS_REQUEST',
    COLLECT_SUB_DISTRICTS_SUCCESS: 'COLLECT_SUB_DISTRICTS_SUCCESS',
    COLLECT_SUB_DISTRICTS_FAILURE: 'COLLECT_SUB_DISTRICTS_FAILURE',

    COLLECT_ZONE_AREA_FOR_BRANCH_REQUEST: 'COLLECT_ZONE_AREA_FOR_BRANCH_REQUEST',
    COLLECT_ZONE_AREA_FOR_BRANCH_SUCCESS: 'COLLECT_ZONE_AREA_FOR_BRANCH_SUCCESS',
    COLLECT_ZONE_AREA_FOR_BRANCH_FAILURE: 'COLLECT_ZONE_AREA_FOR_BRANCH_FAILURE',

    CLEAN_UP_COMMON_STATES_RELATED_TO_GARAGE_DETAILS_ONLY: 'CLEAN_UP_COMMON_STATES_RELATED_TO_GARAGE_DETAILS_ONLY',
    CLEAN_UP_STATES_RELATED_TO_EVENTS_HISTORY_MODAL: 'CLEAN_UP_STATES_RELATED_TO_EVENTS_HISTORY_MODAL',
    RETURN_TO_INITIAL_STATES_OF_BRANCH_FORM: 'RETURN_TO_INITIAL_STATES_OF_BRANCH_FORM',
    RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER: 'RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER',

    COLLECT_FINANCIERS_LIST_REQUEST: 'COLLECT_FINANCIERS_LIST_REQUEST',
    COLLECT_FINANCIERS_LIST_SUCCESS: 'COLLECT_FINANCIERS_LIST_SUCCESS',
    COLLECT_FINANCIERS_LIST_FAILURE: 'COLLECT_FINANCIERS_LIST_FAILURE',

    COLLECT_SMART_BATTERIES_REQUEST: "COLLECT_SMART_BATTERIES_REQUEST",
    COLLECT_SMART_BATTERIES_SUCCESS: "COLLECT_SMART_BATTERIES_SUCCESS",
    COLLECT_SMART_BATTERIES_FAILURE: "COLLECT_SMART_BATTERIES_FAILURE"
};
