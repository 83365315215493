/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, {useEffect, useState} from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import {connect, useSelector} from "react-redux";
import {showNotifications} from "../utils/notification";
import {toast} from "react-toastify";
import {GenericModal} from "../components/genericModal/genericModal";
import {Form} from "react-bootstrap";
import {SolInput} from "../components/SolStyledComponents/components";
import {actions} from "../features/users/actions";

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

const QuickUser = props => {
  const history = useHistory();
  const {user} = useSelector(state => state.auth);

  const [language, setLanguage] = useState("EN");
  const [showOldPasswordRequiredMessage, setShowOldPasswordRequiredMessage] = useState(false);
  const [showPasswordRequiredMessage, setShowPasswordRequiredMessage] = useState(false);
  const [showInvalidPasswordMessage, setShowInvalidPasswordMessage] = useState(false);
  const [shouldContainSpecialCharacter, setShouldContainSpecialCharacter] = useState(false);
  const [showPasswordIsEqualMessage, setShowPasswordIsEqualMessage] = useState(false);
  const [showPasswordConfirmationRequiredMessage, setShowPasswordConfirmationRequiredMessage] = useState(false);
  const [showInvalidPasswordConfirmationMessage, setShowInvalidPasswordConfirmationMessage] = useState(false);
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState(null);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState(null);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [passwordIsUpdating, setPasswordIsUpdating] = useState(false);

  const {
    UPDATE_PASSWORD,
    RETURN_TO_INITIAL_USER_REDUCER_STATE
  } = props;

  useEffect(()=>{
    setLanguage(props.language)
  },[])

  useEffect(()=>{
    setPasswordIsUpdating(props.userInfoSubmitting)
  },[props.userInfoSubmitting])

  useEffect(() => {
    if (props.successMessagePassword !== undefined) {
      RETURN_TO_INITIAL_USER_REDUCER_STATE()
      setShowUpdatePasswordModal(false)
      if (language === 'EN') {
        showNotifications('success', props.successMessagePassword)
      } else {
        toast.success('সফলভাবে পাসওয়ার্ড সম্পাদনা হয়েছে!')
      }
    } else if (props.errorMessagePassword !== undefined) {
      RETURN_TO_INITIAL_USER_REDUCER_STATE()
      showNotifications('error', props.errorMessagePassword)
    }
  }, [props.successMessagePassword, props.errorMessagePassword]);

  const updatePassword = () => {
    setShowUpdatePasswordModal(true)
  }

  const closeUpdatePasswordModal = () => {
    setShowUpdatePasswordModal(false)
  }

  const initiatePasswordUpdateProcess = () => {
    let regex = /[?=.*!@#$&*]/

    if (!oldPassword) {
      setShowOldPasswordRequiredMessage(true)
    } else if (!newPassword) {
      setShowPasswordRequiredMessage(true)
    } else if (newPassword.length < 5) {
      setShowInvalidPasswordMessage(true)
    } else if (regex.test(!newPassword)) {
      setShouldContainSpecialCharacter(true)
    } else if (newPassword === oldPassword) {
      setShowPasswordIsEqualMessage(true)
    } else if (!confirmedPassword) {
      setShowPasswordConfirmationRequiredMessage(true)
    } else if (newPassword !== confirmedPassword) {
      setShowInvalidPasswordConfirmationMessage(true)
    } else {
      const payload = {
        username: props.user.username,
        reason: 'updating_self_password',
        old_password: oldPassword,
        new_password: newPassword
      }
      UPDATE_PASSWORD(payload)
    }
  }

  const renderUpdatePasswordModal = () => {
    return <>
      <GenericModal
          showModalHeader={true}
          footer={true}
          footerButtonSize={'sm'}
          footerCloseButtonText={language === 'EN'? 'Cancel':'বাতিল করুন'}
          footerActionButtonText={language === 'EN'? 'Confirm':'নিশ্চিত করুন'}
          hideModal={closeUpdatePasswordModal}
          takeAction={initiatePasswordUpdateProcess}
          centered={true}
          modal={showUpdatePasswordModal}
          disableButton={passwordIsUpdating}
          title={<>{language === 'EN'? 'Update password':'পাসওয়ার্ড সম্পাদনা করুন'}</>}>
        <div>
          <Form.Label>{language === 'EN'? 'Current password':'বর্তমান পাসওয়ার্ড'}<span className="required text-danger">*</span></Form.Label>
        </div>
        <div className={"mb-3"}>
          <InputGroup>
            <Form.Control
                name={'password'}
                type={!showOldPassword? 'password':'text'}
                placeholder={language === 'EN'? 'Present password':'বর্তমান পাসওয়ার্ড'}
                autoComplete={"off"}
                onChange={(e) => {
                  setShowOldPasswordRequiredMessage(false)
                  setOldPassword(e.target.value)
                }}
            />
            <Button variant="outline-secondary" onClick={() => {setShowOldPassword(!showOldPassword)}}>
              {!showOldPassword? <i className={"fa fa-eye"}></i>:<i className={"fa fa-eye-slash"}></i>}
            </Button>
          </InputGroup>
          {showOldPasswordRequiredMessage? <><div className="text-danger">
            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? "Please provide current password!":"দয়া করে পুরনো পাসওয়ার্ডটি দিন!"}</div></>:null}
        </div>

        <div>
          <Form.Label>{language === 'EN'? 'New password':'নতুন পাসওয়ার্ড'}<span className="required text-danger">*</span></Form.Label>
        </div>
        <div className={"mb-3"}>
          <InputGroup>
            <Form.Control
                name={'password'}
                type={!showNewPassword? 'password':'text'}
                placeholder={language === 'EN'? 'New password':'নতুন পাসওয়ার্ড'}
                autoComplete={"off"}
                onChange={(e) => {
                  setShowPasswordRequiredMessage(false)
                  setShowPasswordIsEqualMessage(false)
                  setShowInvalidPasswordMessage(false)
                  setShouldContainSpecialCharacter(false)
                  setNewPassword(e.target.value)
                }}
            />
            <Button variant="outline-secondary" onClick={() => {setShowNewPassword(!showNewPassword)}}>
              {!showNewPassword? <i className={"fa fa-eye"}></i>:<i className={"fa fa-eye-slash"}></i>}
            </Button>
          </InputGroup>
          {showPasswordRequiredMessage? <><div className="text-danger">
            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? "Please provide new password!":"দয়া করে নতুন পাসওয়ার্ডটি লিখুন!"}</div></>:null}
          {showInvalidPasswordMessage? <><div className="text-danger">
            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'?
              'Password length must be at least 5!':'পাসওয়ার্ডের দৈর্ঘ্য অন্তত  হওয়া উচিত!'}</div></>:null}
          {shouldContainSpecialCharacter? <><div className="text-danger">
            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'?
              'Password should have at least one special character!':'পাসওয়ার্ডে অন্তত একটি স্পেশাল ক্যারেক্টার থাকতে হবে!'}</div></>:null}
          {showPasswordIsEqualMessage? <><div className="text-danger">
            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? "New password is similar to the old password!":"নতুন পাসওয়ার্ডটি পুরনো পাসওয়ার্ডের সাথে মিলে গেছে!"}</div></>:null}
        </div>


        <div>
          <Form.Label>{language === 'EN'? 'Confirm password':'আবার পাসওয়ার্ড লিখুন'}<span className="required text-danger">*</span></Form.Label>
        </div>
        <div className={"mb-3"}>
          <InputGroup className="mb-3">
            <Form.Control
                name={'password'}
                type={!showConfirmedPassword? 'password':'text'}
                placeholder={language === 'EN'? 'Confirm new password':'নতুন পাসওয়ার্ড আবার লিখুন'}
                autoComplete={"off"}
                onChange={(e) => {
                  setShowPasswordConfirmationRequiredMessage(false)
                  setShowInvalidPasswordConfirmationMessage(false)
                  setConfirmedPassword(e.target.value)
                }}
            />
            <Button variant="outline-secondary" onClick={() => {setShowConfirmedPassword(!showConfirmedPassword)}}>
              {!showConfirmedPassword? <i className={"fa fa-eye"}></i>:<i className={"fa fa-eye-slash"}></i>}
            </Button>
          </InputGroup>
          {showPasswordConfirmationRequiredMessage? <><div className="text-danger">
            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? "Please confirm password!":"দয়া করে পাসওয়ার্ড আবার লিখুন!"}</div></>:null}
          {showInvalidPasswordConfirmationMessage? <><div className="text-danger">
            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'?
              "Password haven't matched!":'পাসওয়ার্ড মিলেনি!'}</div></>:null}
        </div>

      </GenericModal>
    </>
  }

  const getAvatarGenerationURL=()=>{
    const name=user.name?user.name.replace(" ","+"):'Data+Admin';
    return  `https://ui-avatars.com/api/?background=00bd03&name=${name}&color=fff&rounded=false`;
  };

  const logoutClick = () => {
      const toggle = document.getElementById("kt_quick_user_toggle");
      if (toggle) {
        toggle.click();
      }
      history.push("/logout");
  };

  return (
      <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            User Profile
            {/*<small className="text-muted font-size-sm ml-2">12 messages</small>*/}
          </h3>
          <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted"/>
          </a>
        </div>

        <div
            className="offcanvas-content pr-5 mr-n5"
        >
          <div className="d-flex align-items-center mt-5">
            <div
                className="symbol symbol-100 mr-5"
            >
              <div className="symbol-label" style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  user.google_user_profile?.picture ? user.google_user_profile?.picture:
                    user.photoURL? user.photoURL: getAvatarGenerationURL()
                )})`
              }}/>
              <i className="symbol-badge bg-success"/>
            </div>
            <div className="d-flex flex-column">
              <a
                  href="#"
                  className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
              {user?.google_user_profile?.name ? user.google_user_profile.name : user.name}
              </a>
              <div className="text-muted mt-1"></div>
              <div className="navi mt-2">
                <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    {/*<span className="svg-icon-lg svg-icon-primary">*/}
                    {/*  <SVG*/}
                    {/*      src={toAbsoluteUrl(*/}
                    {/*          "/media/svg/icons/General/Notification2.svg"*/}
                    {/*      )}*/}
                    {/*  ></SVG>*/}
                    {/*</span>*/}
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {/*jm@softplus.com*/}
                  </span>
                </span>
                </a>
              </div>
              {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
              <div className={"row"}>
                <div className={"col-sm-5"}>
                  <button className="btn btn-light-warning btn-sm" onClick={updatePassword} style={{whiteSpace: 'nowrap'}}>Update password</button>
                </div>
                <div className={"col-sm-2"}></div>
                <div className={"col-sm-5"}>
                  <button className="btn btn-light-primary btn-sm" onClick={logoutClick} style={{whiteSpace: 'nowrap'}}>Sign out</button>
                </div>
              </div>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5"/>

          {/*<div className="navi navi-spacer-x-0 p-0">*/}
          {/*  <a href="/user/profile" className="navi-item">*/}
          {/*    <div className="navi-link">*/}
          {/*      <div className="symbol symbol-40 bg-light mr-3">*/}
          {/*        <div className="symbol-label">*/}
          {/*        <span className="svg-icon svg-icon-md svg-icon-success">*/}
          {/*          <SVG*/}
          {/*              src={toAbsoluteUrl(*/}
          {/*                  "/media/svg/icons/General/Notification2.svg"*/}
          {/*              )}*/}
          {/*          ></SVG>*/}
          {/*        </span>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="navi-text">*/}
          {/*        <div className="font-weight-bold">My Profile</div>*/}
          {/*        <div className="text-muted">*/}
          {/*          /!*Account settings and more*!/*/}
          {/*          /!*{" "}*!/*/}
          {/*          <a href={"#"} className="label label-light-warning label-inline font-weight-bold">*/}
          {/*          Update password*/}
          {/*        </a>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </a>*/}

            {/*<a href="/user/profile" className="navi-item">*/}
            {/*  <div className="navi-link">*/}
            {/*    <div className="symbol symbol-40 bg-light mr-3">*/}
            {/*      <div className="symbol-label">*/}
            {/*      <span className="svg-icon svg-icon-md svg-icon-warning">*/}
            {/*        <SVG*/}
            {/*            src={toAbsoluteUrl(*/}
            {/*                "/media/svg/icons/Shopping/Chart-bar1.svg"*/}
            {/*            )}*/}
            {/*        ></SVG>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="navi-text">*/}
            {/*      <div className="font-weight-bold">My Messages</div>*/}
            {/*      <div className="text-muted">Inbox and tasks</div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</a>*/}

            {/*<a href="/user/profile" className="navi-item">*/}
            {/*  <div className="navi-link">*/}
            {/*    <div className="symbol symbol-40 bg-light mr-3">*/}
            {/*      <div className="symbol-label">*/}
            {/*      <span className="svg-icon svg-icon-md svg-icon-danger">*/}
            {/*        <SVG*/}
            {/*            src={toAbsoluteUrl(*/}
            {/*                "/media/svg/icons/Files/Selected-file.svg"*/}
            {/*            )}*/}
            {/*        ></SVG>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="navi-text">*/}
            {/*      <div className="font-weight-bold">My Activities</div>*/}
            {/*      <div className="text-muted">Logs and notifications</div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</a>*/}

            {/*<a href="/user/profile" className="navi-item">*/}
            {/*  <div className="navi-link">*/}
            {/*    <div className="symbol symbol-40 bg-light mr-3">*/}
            {/*      <div className="symbol-label">*/}
            {/*      <span className="svg-icon svg-icon-md svg-icon-primary">*/}
            {/*        <SVG*/}
            {/*            src={toAbsoluteUrl(*/}
            {/*                "/media/svg/icons/Communication/Mail-opened.svg"*/}
            {/*            )}*/}
            {/*        ></SVG>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="navi-text">*/}
            {/*      <div className="font-weight-bold">My Tasks</div>*/}
            {/*      <div className="text-muted">latest tasks and projects</div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</a>*/}
          {/*</div>*/}

          {/*<div className="separator separator-dashed my-7"></div>*/}

          {/*<div>*/}
          {/*  <h5 className="mb-5">Recent Notifications</h5>*/}

          {/*  <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">*/}
          {/*  <span className="svg-icon svg-icon-warning mr-5">*/}
          {/*    <SVG*/}
          {/*        src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}*/}
          {/*        className="svg-icon svg-icon-lg"*/}
          {/*    ></SVG>*/}
          {/*  </span>*/}

          {/*    <div className="d-flex flex-column flex-grow-1 mr-2">*/}
          {/*      <a*/}
          {/*          href="#"*/}
          {/*          className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"*/}
          {/*      >*/}
          {/*        Another purpose persuade*/}
          {/*      </a>*/}
          {/*      <span className="text-muted font-size-sm">Due in 2 Days</span>*/}
          {/*    </div>*/}

          {/*    <span className="font-weight-bolder text-warning py-1 font-size-lg">*/}
          {/*    +28%*/}
          {/*  </span>*/}
          {/*  </div>*/}

          {/*  <div className="d-flex align-items-center bg-light-success rounded p-5 gutter-b">*/}
          {/*  <span className="svg-icon svg-icon-success mr-5">*/}
          {/*    <SVG*/}
          {/*        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}*/}
          {/*        className="svg-icon svg-icon-lg"*/}
          {/*    ></SVG>*/}
          {/*  </span>*/}
          {/*    <div className="d-flex flex-column flex-grow-1 mr-2">*/}
          {/*      <a*/}
          {/*          href="#"*/}
          {/*          className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"*/}
          {/*      >*/}
          {/*        Would be to people*/}
          {/*      </a>*/}
          {/*      <span className="text-muted font-size-sm">Due in 2 Days</span>*/}
          {/*    </div>*/}

          {/*    <span className="font-weight-bolder text-success py-1 font-size-lg">*/}
          {/*    +50%*/}
          {/*  </span>*/}
          {/*  </div>*/}

          {/*  <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">*/}
          {/*  <span className="svg-icon svg-icon-danger mr-5">*/}
          {/*    <SVG*/}
          {/*        src={toAbsoluteUrl(*/}
          {/*            "/media/svg/icons/Communication/Group-chat.svg"*/}
          {/*        )}*/}
          {/*        className="svg-icon svg-icon-lg"*/}
          {/*    ></SVG>*/}
          {/*  </span>*/}
          {/*    <div className="d-flex flex-column flex-grow-1 mr-2">*/}
          {/*      <a*/}
          {/*          href="#"*/}
          {/*          className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"*/}
          {/*      >*/}
          {/*        Purpose would be to persuade*/}
          {/*      </a>*/}
          {/*      <span className="text-muted font-size-sm">Due in 2 Days</span>*/}
          {/*    </div>*/}

          {/*    <span className="font-weight-bolder text-danger py-1 font-size-lg">*/}
          {/*    -27%*/}
          {/*  </span>*/}
          {/*  </div>*/}

          {/*  <div className="d-flex align-items-center bg-light-info rounded p-5">*/}
          {/*  <span className="svg-icon svg-icon-info mr-5">*/}
          {/*    <SVG*/}
          {/*        src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}*/}
          {/*        className="svg-icon svg-icon-lg"*/}
          {/*    ></SVG>*/}
          {/*  </span>*/}

          {/*    <div className="d-flex flex-column flex-grow-1 mr-2">*/}
          {/*      <a*/}
          {/*          href="#"*/}
          {/*          className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"*/}
          {/*      >*/}
          {/*        The best product*/}
          {/*      </a>*/}
          {/*      <span className="text-muted font-size-sm">Due in 2 Days</span>*/}
          {/*    </div>*/}

          {/*    <span className="font-weight-bolder text-info py-1 font-size-lg">*/}
          {/*    +8%*/}
          {/*  </span>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {renderUpdatePasswordModal()}
        </div>
      </div>
  );
}

const mapStateToProps=(state)=>{

  return {
    language: state.auth.language,
    user: state.auth.user,
    passwordUpdated: state.usersReducer.passwordUpdated,
    userInfoSubmitting: state.usersReducer.userInfoSubmitting,
    successMessagePassword: state.usersReducer.successMessagePassword,
    errorMessagePassword: state.usersReducer.errorMessagePassword
  }
}

export default connect(mapStateToProps, actions) (QuickUser);
