import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {actions} from "../../../../actions";
import {actions as commonActions} from "../../../../../commonReduxActions/actions";
import {makeStyles} from "@material-ui/styles";
import {withRoles} from "../../../../../../router/SecuredRoute";
import {useMediaQuery} from "react-responsive";
import { useJsApiLoader, GoogleMap, Marker, Polyline, InfoWindow, MarkerF } from '@react-google-maps/api'
import Stack from "@mui/material/Stack";
import SolRadioInput from "../../../../../../components/solradioinput/SolRadioInput";
import { renderToStaticMarkup } from "react-dom/server";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import {Box} from "@mui/system";
import Table from "react-bootstrap/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const LocationInGoogleMap = ({locationInfo, evData, evSerial, width, height, ...props}) => {
    const [language, setLanguage] = useState(null);
    const [uniqueKeyForMapDiv, setUniqueKeyForMapDiv] = useState(0);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [path, setPath] = useState([])
    const [center, setCenter] = useState({'lat': 23.821525, 'lng': 90.41388})
    const [mapData, setMapData] = useState('latest')
    const [showInfoWindow, setShowInfoWindow] = useState(true)
    const [soc, setSoc] = useState(0)
    const [evType, setEvType] = useState('')
    const [markerLocation, setMarkerLocation] = useState({'lat': 23.8167332, 'lng': 90.413751})
    const [dataCollectionTime, setDataCollectionTime] = useState('')
    const [dataCollectionTimeDifference, setDataCollectionTimeDifference] = useState('')
    const [showMap, setShowMap] = useState(true)

    const {
        COLLECT_LOCATIONS
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
    }, []);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    useEffect(() => {
        // evData will have empty object by default (check evDriverSearch.js)
        if (evData && Object.keys(evData).length > 0) {
            let evTypeFromResponse = evData.type_of_vehicle.split("_")
            if (evTypeFromResponse[1]) {
                setEvType(capitalizeFirstLetter(evTypeFromResponse[0]) + ' ' + capitalizeFirstLetter(evTypeFromResponse[1]))
            } else {
                setEvType(capitalizeFirstLetter(evTypeFromResponse[0]))
            }
        }
    }, [evData])

    const convertSeconds = (seconds) => {
        let hours = Math.floor(seconds / 3600)
        let minutes = Math.floor((seconds % 3600) / 60)

        let returnedText = ''

        if (hours !== 0) {
            if (hours >= 24) {
                let days = Math.floor(minutes % 24)
                hours = Math.floor(hours / 24)
                returnedText = minutes !== 0 ? `${days} days ${hours} hours and ${minutes} minutes`:`${days} days and ${hours} hours`
            } else {
                returnedText = minutes !== 0 ? `${hours} hours and ${minutes} minutes`:`${hours} hours`
            }
        } else {
            returnedText = minutes !== 0 ? `${minutes} minutes`:`${seconds} seconds`
        }
        return returnedText
    }

    useEffect(() => {
        if (locationInfo.length > 0) {
            setPath(locationInfo.map((location)=>{
                return {
                    lat: parseFloat(location["latitude"]),
                    lng: parseFloat(location["longitude"]),
                }
            }))
            setMarkerLocation({
                lat: parseFloat(locationInfo[0]["latitude"]),
                lng: parseFloat(locationInfo[0]["longitude"])
            })
            // We are setting center of the Google map at a different location, so that when user closes (or opens) the InfoWindow
            // user's map view doesn't need to adjust to the center. The values are calculated precisely so that
            // the map view initiated automatically adjusting to the center.
            // -- Noor Reza, 7th November, 2023, 12:13 PM
            setCenter({
                lat: parseFloat(locationInfo[0]["latitude"]) + 0.0047918,
                lng: parseFloat(locationInfo[0]["longitude"]) + 0.000129
            })
            if (mapData === 'latest') {
                setDataCollectionTime(moment(locationInfo[0].generated_at).format('Do MMMM, YYYY hh:mm a'))
                setDataCollectionTimeDifference(convertSeconds(moment().diff(locationInfo[0].generated_at, 'seconds')))
                setSoc(locationInfo[0].soc)
            }
        }
    }, [locationInfo])

    useEffect(() => {
        if (Object.keys(markerLocation).length > 0 && Object.keys(center).length > 0) {
            setShowMap(true)
        }
    }, [markerLocation, center])

    const containerStyle = {
        width: width + 'px',
        height: height + 'px'
    };

    const pathP = [
        {
            "lat": 23.8166537,
            "lng": 90.4165167
        },
        {
            "lat": 23.8166694,
            "lng": 90.4164838
        },
        {
            "lat": 23.816657,
            "lng": 90.416498
        },
        {
            "lat": 23.8166615,
            "lng": 90.4164973
        },
        {
            "lat": 23.8166599,
            "lng": 90.4164964
        },
        {
            "lat": 23.816701,
            "lng": 90.4164199
        },
        {
            "lat": 23.8167022,
            "lng": 90.4164267
        },
        {
            "lat": 23.8167029,
            "lng": 90.4164344
        },
        {
            "lat": 23.8167251,
            "lng": 90.4164583
        },
        {
            "lat": 23.8166499,
            "lng": 90.4165227
        },
        {
            "lat": 23.8166555,
            "lng": 90.4165016
        },
        {
            "lat": 23.8166555,
            "lng": 90.4164902
        },
        {
            "lat": 23.8155436,
            "lng": 90.4164304
        },
        {
            "lat": 23.8124638,
            "lng": 90.4190726
        },
        {
            "lat": 23.8166619,
            "lng": 90.4164779
        },
        {
            "lat": 23.8167046,
            "lng": 90.4164242
        },
        {
            "lat": 23.8167013,
            "lng": 90.4164203
        },
        {
            "lat": 23.8167027,
            "lng": 90.4164188
        },
        {
            "lat": 23.8167042,
            "lng": 90.4164237
        },
        {
            "lat": 23.816698,
            "lng": 90.4164209
        },
        {
            "lat": 23.8166973,
            "lng": 90.4164165
        },
        {
            "lat": 23.8166958,
            "lng": 90.4164193
        },
        {
            "lat": 23.8167006,
            "lng": 90.4164171
        },
        {
            "lat": 23.8166974,
            "lng": 90.4164221
        },
        {
            "lat": 23.8166844,
            "lng": 90.416476
        },
        {
            "lat": 23.8166926,
            "lng": 90.4164252
        },
        {
            "lat": 23.8166981,
            "lng": 90.4164332
        }
    ]

    const options = {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 4,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        marker: true,
        radius: 30000,
        zIndex: 1
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBu3a5fnM8oC1ZJ7D_Vv9aSXr9ysoQgtqI"
    })
    const pinSymbol = () => {
        return {
            path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
            fillColor: '#F18D00',
            fillOpacity: 1,
            strokeColor: '#000',
            strokeWeight: 2,
            scale: 1.5
        };
    }

    const tableHeaderLeftAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        // paddingLeft: "0px",
        textAlign: "left",
        // width: 'calc(6.16667px)',
        boxSizing: "borderBox"
    }

    const tableLeftAlignedContentStyling = {
        color: "#000000",
        // width: calc(112.5px),
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        // paddingLeft: "0px",
        // width: 'calc(47.333px)',
        textAlign: "left",
        // whiteSpace: 'nowrap'
    }

    const saveMapDataSelection = (event) => {
        setMapData(event.target.value)
    }

    return (
        <>
            <div className={isTabletOrMobile? 'row mt-4':'row ml-1 mt-10'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}><h5><strong>EV driver location (Latest)</strong></h5></div>
            <div className={isTabletOrMobile? 'row':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0.5}
                >
                    <div className="form-check form-check-inline mt-2">
                        <SolRadioInput
                            id="latest"
                            value={"latest"}
                            handleChange={(event) => saveMapDataSelection(event)}
                            checked={mapData === "latest"}
                            htmlFor="latest"
                            labelName="Latest"
                        />
                    </div>
                    <div className="form-check form-check-inline mt-2">
                        <SolRadioInput
                            id="historical"
                            value={"historical"}
                            handleChange={(event) => saveMapDataSelection(event)}
                            checked={mapData === "historical"}
                            htmlFor="historical"
                            labelName="Historical Data"
                        />
                    </div>
                </Stack>
            </div>
            {isLoaded && showMap? <>
                <div className={isTabletOrMobile? 'row mt-2':'row ml-1 mt-4'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={mapData === 'latest'? {...center}:pathP[0]}
                        zoom={15}
                        options={{
                            styles: [
                                { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                                { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                                { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                                {
                                    featureType: "administrative.locality",
                                    elementType: "labels.text.fill",
                                    stylers: [{ color: "#d59563" }],
                                },
                                {
                                    featureType: "poi",
                                    elementType: "labels.text.fill",
                                    stylers: [{ color: "#d59563" }],
                                },
                                {
                                    featureType: "poi.park",
                                    elementType: "geometry",
                                    stylers: [{ color: "#263c3f" }],
                                },
                                {
                                    featureType: "poi.park",
                                    elementType: "labels.text.fill",
                                    stylers: [{ color: "#6b9a76" }],
                                },
                                {
                                    featureType: "road",
                                    elementType: "geometry",
                                    stylers: [{ color: "#38414e" }],
                                },
                                {
                                    featureType: "road",
                                    elementType: "geometry.stroke",
                                    stylers: [{ color: "#212a37" }],
                                },
                                {
                                    featureType: "road",
                                    elementType: "labels.text.fill",
                                    stylers: [{ color: "#9ca5b3" }],
                                },
                                {
                                    featureType: "road.highway",
                                    elementType: "geometry",
                                    stylers: [{ color: "#746855" }],
                                },
                                {
                                    featureType: "road.highway",
                                    elementType: "geometry.stroke",
                                    stylers: [{ color: "#1f2835" }],
                                },
                                {
                                    featureType: "road.highway",
                                    elementType: "labels.text.fill",
                                    stylers: [{ color: "#f3d19c" }],
                                },
                                {
                                    featureType: "transit",
                                    elementType: "geometry",
                                    stylers: [{ color: "#2f3948" }],
                                },
                                {
                                    featureType: "transit.station",
                                    elementType: "labels.text.fill",
                                    stylers: [{ color: "#d59563" }],
                                },
                                {
                                    featureType: "water",
                                    elementType: "geometry",
                                    stylers: [{ color: "#17263c" }],
                                },
                                {
                                    featureType: "water",
                                    elementType: "labels.text.fill",
                                    stylers: [{ color: "#515c6d" }],
                                },
                                {
                                    featureType: "water",
                                    elementType: "labels.text.stroke",
                                    stylers: [{ color: "#17263c" }],
                                },
                            ]
                        }}
                    >
                        {mapData === 'latest'? <>
                            {showInfoWindow? <>
                                <InfoWindow
                                    position={{...markerLocation}}
                                    onCloseClick={() => setShowInfoWindow(false)}
                                >
                                    {/* Note that, InfoWindow should have only one div within it. Otherwise, it will not work */}
                                    {/* - Noor Reza, 5th November, 3:53 PM */}
                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            <TableContainer>
                                                <Table bordered={false}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={tableHeaderLeftAlignedColumnStyling}>EV</TableCell>
                                                            <TableCell style={tableHeaderLeftAlignedColumnStyling}>BATTERY</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                                {evType} #{evSerial}
                                                            </TableCell>
                                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                                {evData.battery_info? evData.battery_info.battery_vendor? evData.battery_info.battery_vendor:'':''} {evData.battery_info.nameplate_capacity? evData.battery_info.nameplate_capacity + 'Ah':''} {evData.battery_info.nameplate_voltage? evData.battery_info.nameplate_voltage + 'V':''}<br/>
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                    spacing={2}
                                                                >
                                                                    {soc >= 80? <>
                                                                        <Box
                                                                            component={"img"}
                                                                            sx={{
                                                                                height: 40,
                                                                                width: 40
                                                                            }}
                                                                            alt={"Healthy"}
                                                                            src={require("../../../../../../utils/asset/battery_fully_charged.svg")}
                                                                        />
                                                                    </>:<>
                                                                        {(soc < 80 && soc > 30)? <>
                                                                            <Box
                                                                                component={"img"}
                                                                                sx={{
                                                                                    height: 40,
                                                                                    width: 40
                                                                                }}
                                                                                alt={"Fair Charge"}
                                                                                src={require("../../../../../../utils/asset/battery_semi_charged.svg")}
                                                                            />
                                                                        </>:<>
                                                                            <Box
                                                                                component={"img"}
                                                                                sx={{
                                                                                    height: 40,
                                                                                    width: 40
                                                                                }}
                                                                                alt={"Low Charge"}
                                                                                src={require("../../../../../../utils/asset/battery_low_charged.svg")}
                                                                            />
                                                                        </>}
                                                                    </>}
                                                                    {/* Color codes used below is from the image used above */}
                                                                    <span style={soc >= 80? {color: '#5fad56'}:(soc < 80 && soc > 30)? {color: '#f18d00'}:{color: '#e7282b'}}>{soc}%</span>
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                                Collected {dataCollectionTimeDifference} ago
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </InfoWindow>
                            </>:null}
                            <Marker key={1}
                                    position={{...markerLocation}}
                                    tooltip={''}
                                    label={''}
                                    title={"SOC: " + soc  + "%, " + dataCollectionTime}
                                    icon={pinSymbol()}
                                    clickable={true}
                                    onClick={() => setShowInfoWindow(!showInfoWindow)}
                            />
                        </>:<>
                            {pathP.map(({ lat, lng, index, title }) => {

                                return (
                                    <Marker key={index}
                                            position={{ lat: lat, lng: lng}}
                                            tooltip={''}
                                            label={''}
                                            title={"SOC: 78%, 26th July 2023 2:51PM"}
                                            icon={pinSymbol()}
                                    />
                                );
                            })}

                            <Polyline path={pathP} options={options}/>
                        </>}

                    </ GoogleMap>
                </div>
            </>:<>
                <div className={isTabletOrMobile? 'row mt-2':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                    No location data available
                </div>
            </>}
        </>
    );
};

LocationInGoogleMap.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        locationData: state.searchResultReducer.locationData,
        collectingLocationData: state.searchResultReducer.collectingLocationData,
        errorMessageForLocationDataCollection: state.searchResultReducer.errorMessageForLocationDataCollection
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(LocationInGoogleMap));
