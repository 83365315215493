import {Redirect, Switch} from "react-router-dom";
import React from "react";
import Notifications from "./views/notifications/notificationsForDesktop";
import SecuredRoute from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const NotificationsRouter =(props)=> {

    return (
        <Switch>
            {/* Following url/path is added as it has been used in the automated mail throughout the company*/}

            {/*<Redirect*/}
            {/*    exact={"true"}*/}
            {/*    from={"/notifications"}*/}
            {/*    to={"/notifications/details"}*/}
            {/*/>*/}

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
