export const groupSelectedOptions = (selectedItem, itemList) => {
    let remainingItems = itemList.filter((item) => {
        let itemIsSelected = false
        for (let i=0; i<selectedItem.length; i++) {
            if (item.pk === selectedItem[i].pk) {
                itemIsSelected = true
            }
        }
        if (!itemIsSelected) {
            return item
        }
    })
    return selectedItem.concat(remainingItems)
}