import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import { Toolbar } from "./components";
import Plot from "react-plotly.js";
import { batteryLogsColumns, data, DataTableContainer, layout } from "../utils";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {DayPickerRangeController} from "react-dates";
import moment from "moment";

import {Badge, Breadcrumb, Button, Form, Spinner, OverlayTrigger, Popover} from "react-bootstrap";
import Select from "react-select";
import { SolInput } from "../../../components/SolStyledComponents/components";
import DataTable from "../../../components/dataTable/DataTable";
import { LoadingSpinner } from "../../../components/LoadingSpinnerForDataTable";
import batteryLogs from "../../batteryLog/views/batteryLogs/batteryLogs";
import { connect } from "react-redux";
import { actions } from "../actions";
import { withRoles } from "../../../router/SecuredRoute";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { Controller, useForm } from "react-hook-form";
import {convertToAnotherLang} from "../../../utils/convertLanguageOfNumbersWithCharacter";
import {useSubheader} from "../../../../_metronic/layout";
const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});
const BatteryFleetPerformance = props => {

    const classes = useStylesIconButton();
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue} = useForm();
    const [showFilter, setShowFilter] = useState(true);
    const [dateRange, setDateRange] = useState('');
    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [language, setLanguage] = useState("EN");
    const [authorization, setAuthorization] = useState(null);
    const [voltageData, setVoltageData] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [lifetimeDischargeData, setLifetimeDischargeData] = useState(null);
    const location = useLocation();
    const subHeader = useSubheader();
    const {
        GET_BATTERY_PERFORMANCE,
        GET_LIFETIME_AH_OUT,
        RETURN_TO_INITIAL_STATE
    } = props

    const codeMap = {
        0: {
            code: "max_value",
            formatting_data: {
                "name": "Fleet Maximum",
                "line": {
                    "color": "rgb(35,1,244)",
                    "width": 3
                },
                "type": "scatter"
            }
        },
        1:  {
            code: "min_value",
            formatting_data: {
                "name": "Fleet Minimum",
                "line": {
                    "color": "rgb(0,159,16)",
                    "width": 3
                },
                "type": "scatter"
            }
        },
        2:  {
            code: "avg_value",
            formatting_data: {
                "name": "Fleet Average",
                "line": {
                    "color": "rgb(244,1,1)",
                    "width": 3
                },
                "type": "scatter"
            }
        },
    }
    const dayHourMapping =  {
        // TODO: We will add '00' at the left of the time if x-axis labels are shown horizontally
        // 0: '06 am', // + 6
        // 1: '07 am', // + 6
        // 2: '08 am', // + 6
        // 3: '09 am', // + 6
        // 4: '10 am', // + 6
        // 5: '11 am', // + 6
        // 6: '12 pm', // + 6
        // 7: '01 pm', // + 6
        // 8: '02 pm', // + 6
        // 9: '03 pm', // + 6
        // 10: '04 pm', // + 6
        // 11: '05 pm', // + 6
        // 12: '06 pm', // + 6
        // 13: '07 pm', // + 6
        // 14: '08 pm', // + 6
        // 15: '09 pm', // + 6
        // 16: '10 pm', // + 6
        // 17: '11 pm', // + 6
        // 18: '12 am', // + 6
        // 19: '01 am', // + 6
        // 20: '02 am', // + 6
        // 21: '03 am', // + 6
        // 22: '04 am', // + 6
        // 23: '05 am', // + 6
        // 24: '06 am' // + 6 ( Redundant but still included '24' for security)


        0: '12 am',
        1: '01 am',
        2: '02 am',
        3: '03 am',
        4: '04 am',
        5: '05 am',
        6: '06 am',
        7: '07 am',
        8: '08 am',
        9: '09 am',
        10: '10 am',
        11: '11 am',
        12: '12 pm',
        13: '01 pm',
        14: '02 pm',
        15: '03 pm',
        16: '04 pm',
        17: '05 pm',
        18: '06 pm',
        19: '07 pm',
        20: '08 pm',
        21: '09 pm',
        22: '10 pm',
        23: '11 pm',
        24: '12 am' // ( Redundant but still included "'24" for security)
    }
    const [batteryLogs, setBatteryLogs] = useState([]);

    const [focusedInput, setFocusedInput] = useState('startDate');

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const processFleetGraphData =(data)=>{
        if (Array.isArray(data) && data.length>0)
        {
            return data.reduce((previousValue, currentValue, currentIndex, array) => {
                previousValue.forEach((item, index)=>{
                    previousValue[index]["x"].push(dayHourMapping[currentValue["hour"]])
                    previousValue[index]["y"].push(currentValue[codeMap[index]["code"]])
                    previousValue[index]= {
                        ...previousValue[index],
                        ...codeMap[index]["formatting_data"]
                    }
                })
                return previousValue
            }, [{x: [], y: []},{x: [], y: []}, {x: [], y: []}])
        }{
            return null
        }
    }

    const processLifeDishargeData =(data)=>{
        if (typeof data === 'object'&& Object.keys(data).length>0)
        {
            const data_array = Object.keys(data)

            return data_array.reduce((previous, item, index)=>{
                previous.push({
                    x: data[item].map((i,currentIndex)=> currentIndex+1),
                    y: data[item].map((i)=> i),
                    "name": `${item}`,
                    // "line": {
                    //   "color": "rgb(35,1,244)",
                    //   "width": 3
                    // },
                    // "type": "scatter",
                    "mode": "markers"
                })
                return previous

            },[])
        }{
            return null
        }

    }

    useEffect(()=>{

        if (Array.isArray(props.performanceData) && props.performanceData.length>=0){
            // setBatteryLogs(props.performanceData)
            setVoltageData(processFleetGraphData(props.performanceData))
            // setLifetimeDischargeData(processLifeDishargeData(props.performanceData))
        }


    },[props.performanceData])

    useEffect(()=>{
        if (typeof props.dischargeData === 'object'&& Object.keys(props.dischargeData).length>0){
            // setBatteryLogs(props.dischargeData)
            setLifetimeDischargeData(processLifeDishargeData(props.dischargeData))

        }

    },[props.dischargeData])

    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const filterList=(data)=>{
        setVoltageData(null)
        setLifetimeDischargeData(null)
        let from_date = language === 'EN'? moment(start).format("YYYY-MM-DD"):convertToAnotherLang(moment(start).format("YYYY-MM-DD"))
        let to_date = language === 'EN'? moment(end).format("YYYY-MM-DD"):convertToAnotherLang(moment(end).format("YYYY-MM-DD"))
        GET_BATTERY_PERFORMANCE({
            battery_capacity: data?.battery_capacity?.value,
            battery_voltage: data?.battery_voltage?.value,
            from_date: from_date,
            to_date: to_date
        })
        GET_LIFETIME_AH_OUT({
            battery_capacity: data?.battery_capacity?.value,
            battery_voltage: data?.battery_voltage?.value,
            from_date: from_date,
            to_date: to_date
        })
    }

    const [previousStart, setPreviousStartDate] = useState(null);
    const [previousEnd, setPreviousEndDate] = useState(null);
    const onDatesChange = ({startDate, endDate}) => {
        if (previousEnd && previousEnd.isSame(endDate)) {
            setPreviousStartDate(endDate)
            setPreviousEndDate(endDate)

            setStartDate(endDate)
            setEndDate(endDate)
        } else {
            setPreviousStartDate(startDate)
            setPreviousEndDate(endDate)

            setStartDate(startDate)
            setEndDate(endDate)
        }
    }

    const collectLastTenDaysData = () => {
        let todaysDate = moment().format("YYYY-MM-DD")
        let tenDaysEarlier = moment(todaysDate).subtract(9, 'd').format("YYYY-MM-DD")
        setStartDate(moment(tenDaysEarlier))
        setEndDate(moment(todaysDate))
        let from_date = props.language === 'EN'? tenDaysEarlier:convertToAnotherLang(tenDaysEarlier)
        let to_date = props.language === 'EN'? todaysDate:convertToAnotherLang(todaysDate)
        GET_BATTERY_PERFORMANCE({
            from_date: from_date,
            to_date: to_date
        })
        GET_LIFETIME_AH_OUT({
            from_date: from_date,
            to_date: to_date
        })
    }

    const resetFilter=()=>{
        RETURN_TO_INITIAL_STATE()
        collectLastTenDaysData()
        clearErrors()
        reset({
            battery_capacity: null,
            battery_voltage: null,
        })
    }


    useEffect(()=>{
        setLanguage("EN")
        setAuthorization(props.authorization)
        subHeader.setActionButtons(null)
        subHeader.setBreadCrumbComponent(null)
        collectLastTenDaysData()

        return ()=>{
            RETURN_TO_INITIAL_STATE()
        }


    },[])


    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    },[start, end])

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start}
                    endDate={end}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    )
    return (
        <div>
            <ContentWrapper isLoading={false} showBackButton={false} showCardHeader={false}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>Battery fleet performance</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>

                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>


                {showFilter? <>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <Form onSubmit={handleSubmit(filterList)}>
                                <div className={'row g-3'}>
                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Date range':'তারিখের সীমা'}<span className="required text-danger">*</span></Form.Label>
                                            </div>
                                            <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                            overlay={popover}>
                                                <SolInput
                                                    name={'dateRangePickerInput'}
                                                    readOnly={true}
                                                    value={dateRange}
                                                    placeholder={language === 'EN'? 'Select a date range':'তারিখের সীমা বাছাই করুন'}
                                                />
                                            </OverlayTrigger>
                                            {/*{requiredErrorMessage? <><div className="text-danger">*/}
                                            {/*  <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}*/}
                                        </Form.Group>
                                    </div>
                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Capacity':'তারিখের সীমা'}<span className="required text-danger">*</span></Form.Label>
                                            </div>
                                            <Controller
                                                control={control}
                                                name={"battery_capacity"}
                                                // rules= {{ required: "R" }}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        name={`battery_capacity`}
                                                        placeholder={language === 'EN'? 'Select capacity in Ah':'সক্ষমতা বাছাই করুন'}
                                                        classNamePrefix="react-select-sol-style"
                                                        maxMenuHeight={200}
                                                        value={value}
                                                        isClearable={true}
                                                        control={control}
                                                        inputRef={ref}
                                                        options={[{ value: 80, label: 80 }, { value: 100, label: 100 }, { value: 120, label: 120 }]}
                                                        isSearchable={true}
                                                        onChange={(selected, {action}) => {
                                                            onChange(selected)
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.capacity && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"/>&nbsp;&nbsp;{errors.capacity.message}</div>}
                                            {/*{requiredErrorMessage? <><div className="text-danger">*/}
                                            {/*  <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}*/}
                                        </Form.Group>
                                    </div>
                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Voltage':'তারিখের সীমা'}<span className="required text-danger">*</span></Form.Label>
                                            </div>
                                            <Controller
                                                control={control}
                                                name={"battery_voltage"}
                                                // rules= {{ required: "R" }}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        name={`battery_voltage`}
                                                        placeholder={language === 'EN'? 'Select Voltage in V':'সক্ষমতা বাছাই করুন'}
                                                        classNamePrefix="react-select-sol-style"
                                                        maxMenuHeight={200}
                                                        value={value}
                                                        isClearable={true}
                                                        control={control}
                                                        inputRef={ref}
                                                        options={[{ value: 51.2, label: 51.2 }, { value: 64, label: 64} ]}
                                                        isSearchable={true}
                                                        onChange={(selected, {action}) => {
                                                            onChange(selected)
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.voltage && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"/>&nbsp;&nbsp;{errors.voltage.message}</div>}
                                            {/*{requiredErrorMessage? <><div className="text-danger">*/}
                                            {/*  <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}*/}
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-md-12 "}>
                                        <Button variant="warning" size={"sm"} type="submit"
                                                disabled={props.collectingPerformanceData}
                                        >
                                            {language === "EN" ? "Apply filter" : "তালিকায় খুঁজুন"}
                                        </Button>
                                        <Button variant="outline-dark" size={"sm"}
                                                onClick={() => {resetFilter()}} className={"ml-3"}>
                                            {language === "EN" ? "Reset filter" : "প্রাথমিক অবস্থায় ফিরে যান"}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <hr />
                </>:null}

                {!props.collectingPerformanceData? <>
                    <br/>
                    <br/>
                    <br/>
                    <Plot
                        data={lifetimeDischargeData? lifetimeDischargeData:[]}
                        layout={{ ...layout, "yaxis": {
                                "title": {
                                    "text": "Total discharge over lifetime (Ah_out)"
                                },
                            },
                            "xaxis": {
                                "title": {
                                    "text": "<b>Time since warranty start (d)\n</b>",
                                    "font": {
                                        "color": "",
                                        "family": "Arial, sans-serif",
                                        "size": 14
                                    }
                                },

                            },
                            "showlegend": false,
                        }}
                        style={{width: '100%'}}
                    />
                    <br/>
                    <br/>
                    <br/>
                    <Plot
                        data={voltageData? voltageData:[]}
                        layout={ { ...layout, "yaxis": {
                                "title": {
                                    "text": "Battery voltage (V)"
                                },

                            },
                            "xaxis": {
                                "title": {
                                    "text": "<b>Time of day (h)</b>",
                                    "font": {
                                        "color": "",
                                        "family": "Arial, sans-serif",
                                        "size": 14
                                    }
                                },

                            }
                        }}
                        style={{width: '100%'}}
                    />
                </>:<>
                    <LoadingSpinner loadingSubText={language === 'EN'? 'Collecting performance data ..': 'ব্যাটারীর গ্রাফ প্রস্তুত হচ্ছে'} language={language}/>
                </>}
            </ContentWrapper>

        </div>
    );
};

BatteryFleetPerformance.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.batteryReducer.isLoading,
        tableLoading: state.batteryReducer.tableLoading,
        listLoading: state.batteryReducer.listLoading,
        iconLoading: state.batteryReducer.iconLoading,
        performanceData: state.batteryReducer.performanceData,
        dischargeData: state.batteryReducer.dischargeData,
        collectingPerformanceData: state.batteryReducer.collectingPerformanceData
    }
}

export default connect(mapStateToProps, actions)(withRoles(BatteryFleetPerformance));
