import React, { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { SolInput } from "../../../../components/SolStyledComponents/components";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import { actions } from "../../actions";
import { actions as commonActions } from "../../../commonReduxActions/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { requestCycle } from "../../../../utils/utilityFunctions";
import { Toolbar } from "../components";
import { useLocation } from "react-router";


const CreateSupplierInfomation = props => {
    const ftype = [
        { value: "vendor", label: "Create Vendor" }, { value: "model", label: "Create Model" },
    ]
    const productType = [
        { value: "bms", label: "BMS" }, { value: "battery", label: "Battery" },
    ]
    const vendorRegistrationTitle = "Register Vendor"
    const modelRegistrationTitle = "Register Model"
    const { register, errors, control, clearErrors, handleSubmit, reset, setValue} = useForm();
    const [language, setLanguage] = useState('EN');
    const [type, setType] = useState(ftype[0])
    const [vendors, setVendors] = useState([])
    const [product, setproduct] = useState(productType[1])
    const [showBmsVendorCodeField, setShowBmsVendorCodeField] = useState(false)
    const [title, setTitle] = useState(vendorRegistrationTitle)
    const [edit ,setEdit] = useState(false)
    const location = useLocation()

    const {
        RETURN_TO_INITIAL_STATE
    } = props;

    useEffect(() => {
        if (product) {
            props.GET_VENDOR_LIST({
                type: product?.value
            })
        }
    }, [product])

    useEffect(() => {
        if (Array.isArray(props.vendorList)) {
            setVendors(props.vendorList.map((item) => {
                return {
                    ...item,
                    value: item.pk,
                    label: item.name
                }
            }))
        } else {
            setVendors([])
        }
    }, [props.vendorList])

    useEffect(()=>{
        if (props.recordSaved === requestCycle.success){
            sessionStorage.removeItem('purpose')
            props.history.push(type?.value === "model" ?  props.history.goBack(): "/batteries/vendors/list")
        }
    }, [props.recordSaved])


    const submitModel = (data) => {

        data = {
            ...data,
            model_type: data.model_type?.value,
            number_of_cells: parseInt(data.number_of_cells),
            vendor_guid: data.vendor_guid.value
        }
        // Check for valid BMS command if 
        if (product.value === 'bms') {
            try {
                data = {
                    ...data,
                    bms_command: JSON.parse(data.bms_command.trim()),
                }

            } catch (error) {
                toast.error("Enter a valid json")
                return
            }
        }
        delete data["name"]
        // Check if the feature is for BMS Update
        if(edit){
            data = {
                ...data,
                model_type: "bms"
            }
            props.UPDATE_MODEL(props.match.params.pk, data)
        }else{
            props.CREATE_MODEL(data)
        }
        
        
    }

    const submitVendor = (data) => {
        if (!showBmsVendorCodeField) {
            delete data['bms_vendor_code']
        }
        data ={
            ...data,
            product_type: data.product_type.value
        }
        props.CREATE_VENDOR(data);
    }

    const setInitialActionAndTitle = (purpose) => {
        if (purpose === 'vendorCreate') {
            setType(ftype[0])
            setTitle(vendorRegistrationTitle)
        } else if (purpose === 'modelCreate') {
            setType(ftype[1])
            setTitle(modelRegistrationTitle)
        }
    }

    useEffect(()=>{
        if (props.match.params.pk){
            setEdit(true)
            setType(ftype[1])
            setproduct(productType[0])
            
            props.GET_MODEL_DETAILS(props.match.params.pk)
        }

        if (location.purpose) {
            let purpose = location.purpose
            sessionStorage.setItem('purpose', purpose)
            setInitialActionAndTitle(purpose)
        } else if (sessionStorage.getItem('purpose')) {
            setInitialActionAndTitle(sessionStorage.getItem('purpose'))
        }

        return () => {
            RETURN_TO_INITIAL_STATE()
        }

    },[])

    useEffect(()=>{
        if(props.modelDetails){
            reset({
                "vendor_guid": { value: props.modelDetails?.vendor_guid, label: props.modelDetails?.vendor_name },
                "model_name": props.modelDetails?.model_name,
                "bms_command": JSON.stringify(props.modelDetails?.bms_command) ,
                "number_of_cells": parseInt(props.modelDetails?.number_of_cells)
            })
        }
    },[props.modelDetails])

    const VendorCreationForm = <Form onSubmit={handleSubmit(submitVendor)}>
        <div >
            <div className={"col-md-6"}>
                <div className={'row g-3'}>
                    <div className={"col-md-10"}>
                        <Form.Group>
                            <Form.Label>{language === "EN" ? "Name" : "সক্ষমতা (অ্যাম্পিয়ার/ঘন্টা)"}<span className="required text-danger">*</span></Form.Label>
                            <SolInput
                                type={"text"}
                                name={"name"}
                                placeholder={language === "EN" ? "Vendor name" : "ব্যাটারির সক্ষমতা এম্পিয়ার/ঘন্টায়"}
                                autoComplete={"off"}
                                ref={register({
                                    required: 'Required',
                                })}
                            />
                            {errors.name && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.name.message}</div>}
                        </Form.Group>
                    </div>
                </div>

                <div className={'row g-3'}>
                    <div className={"col-md-10"}>
                        <Form.Group>
                            <div>
                                <Form.Label>{language === "EN" ? "Product type" : "বিএমএস মডেল"}<span className="required text-danger">*</span></Form.Label>
                            </div>

                            <Controller
                                control={control}
                                name={"product_type"}
                                rules={{ required: "Required" }}
                                render={({ onChange, onBlur, value, name, ref },
                                    { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={language === 'EN' ? 'Select a product type' : 'বিএমএস মডেল বাছাই করুন'}
                                        classNamePrefix="react-select-sol-style"
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        options={productType}
                                        inputRef={ref}
                                        isSearchable={true}
                                        onChange={(selected, { action }) => {
                                            onChange(selected)
                                            setproduct(selected)
                                            if (selected && selected.value === 'bms') {
                                                setShowBmsVendorCodeField(true)
                                            } else {
                                                setShowBmsVendorCodeField(false)
                                            }
                                            return selected
                                        }}
                                    />
                                )}
                            />

                            {errors.product_type && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.product_type.message}</div>}
                        </Form.Group>
                    </div>
                </div>

                <div className={'row g-3'} style={showBmsVendorCodeField? {}:{display: 'none'}}>
                    <div className={"col-md-10"}>
                        <Form.Group>
                            <Form.Label>{language === "EN" ? "Code" : "Code"}<span className="required text-danger">*</span></Form.Label>
                            <SolInput
                                type={"text"}
                                name={"bms_vendor_code"}
                                placeholder={"BMS vendor code"}
                                autoComplete={"off"}
                                ref={register({
                                    required: showBmsVendorCodeField? 'Required':false,
                                })}
                            />
                            {errors.bms_vendor_code && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.bms_vendor_code.message}</div>}
                        </Form.Group>
                    </div>
                </div>

                <div className={'row'}>
                    <div className={"col-md-12"}>
                        <Button variant="dark" size={'md'}
                            onClick={() => {
                                props.history.goBack()
                                sessionStorage.removeItem('purpose')
                            }} style={{ backgroundColor: '#8C8C9B', outline: '#8C8C9B', border: '#8C8C9B' }}>
                            <i className='fa fa-times' aria-hidden='true' />&nbsp;{language === "EN" ? "Cancel" : "বাতিল করুন"}
                        </Button>
                        <Button variant="warning" type="submit"
                            className={"ml-3"}
                            disabled={(props.batteryModelListDropDownLoading ||
                                props.batteryVendorListDropDownLoading || props.bmsVendorListDropDownLoading
                                || props.bmsModelListDropDownLoading || props.batteryInfoSubmitting)}>
                            {(props.batteryInfoSubmitting || props.buttonLoading)? <><Spinner animation={'border'} size={'sm'} variant={'light'} /> </> : null}
                            <i className='fa fa-paper-plane' aria-hidden='true' />&nbsp;{language === "EN" ? "Submit" : "সংরক্ষণ করুন"}
                        </Button>
                    </div>
                </div>

            </div>
        </div>
    </Form>

    const ModelCreationForm = <Form onSubmit={handleSubmit(submitModel)}>
        <div >
            <div className={"col-md-6"}>

                <div className={'row g-3'}>
                    <div className={"col-md-10"}>
                        <Form.Group>
                            <Form.Label>{language === "EN" ? "Name" : "সক্ষমতা (অ্যাম্পিয়ার/ঘন্টা)"}<span className="required text-danger">*</span></Form.Label>
                            <SolInput
                                type={"text"}
                                name={"model_name"}
                                placeholder={language === "EN" ? "Model Name" : "ব্যাটারির সক্ষমতা এম্পিয়ার/ঘন্টায়"}
                                autoComplete={"off"}
                                ref={register({
                                    required: 'Required',
                                })}

                            />
                            {errors.model_name && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.model_name.message}</div>}
                        </Form.Group>
                    </div>
                </div>




                {!edit && <div className={'row g-3'}>
                    <div className={"col-md-10"}>
                        <Form.Group>
                            <div>
                                <Form.Label>{language === "EN" ? "Product type" : "বিএমএস মডেল"}<span className="required text-danger">*</span></Form.Label>
                            </div>

                            <Controller
                                control={control}
                                name={"model_type"}
                                rules={{ required: "Required" }}
                                render={({ onChange, onBlur, value, name, ref },
                                    { invalid, isTouched, isDirty }) => (
                                    <Select
                                        name={`model_type`}
                                        placeholder={language === 'EN' ? 'Select a product type' : 'বিএমএস মডেল বাছাই করুন'}
                                        classNamePrefix="react-select-sol-style"
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        options={productType}
                                        inputRef={ref}
                                        isSearchable={true}
                                        onChange={(selected, { action }) => {
                                            onChange(selected)
                                            setproduct(selected)
                                            setValue("vendor_guid", null)
                                            return selected
                                        }}
                                    />
                                )}
                            />

                            {errors.model_type && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.model_type.message}</div>}
                        </Form.Group>
                    </div>
                </div>}




                <div className={'row g-3'}>
                    <div className={"col-md-10"}>
                        <Form.Group>
                            <div>
                                <Form.Label>{language === "EN" ? "Select Vendor" : "বিএমএস মডেল"}<span className="required text-danger">*</span></Form.Label>
                            </div>


                            <Controller
                                control={control}
                                name={"vendor_guid"}
                                rules={{ required: "Required" }}
                                render={({ onChange, onBlur, value, name, ref, defaultValue },
                                    { invalid, isTouched, isDirty }) => (
                                    <Select
                                        name={`vendor_guid`}
                                        placeholder={language === 'EN' ? 'Select a Vendor' : 'বিএমএস মডেল বাছাই করুন'}
                                        classNamePrefix="react-select-sol-style"
                                        maxMenuHeight={200}
                                        defaultValue={defaultValue}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={vendors}
                                        isSearchable={true}
                                        onChange={(selected, { action }) => {
                                            onChange(selected)
                                            return selected
                                        }}
                                    />
                                )}
                            />

                            {errors.vendor_guid && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.vendor_guid.message}</div>}
                        </Form.Group>
                    </div>
                </div>


                {product?.value === 'bms' ? <>
                    <div className={'row g-3'}>
                        <div className={"col-md-10"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>{language === "EN" ? "BMS command" : "বিএমএস মডেল"}<span className="required text-danger">*</span></Form.Label>
                                </div>

                                <Form.Control
                                    as={"textarea"}
                                    name={"bms_command"}
                                    placeholder={language === "EN" ? "Enter BMS command" : "ব্যাটারির সক্ষমতা এম্পিয়ার/ঘন্টায়"}
                                    rows="10"
                                    autoComplete={"off"}
                                    ref={register({
                                        required: 'Required',
                                    })}
                                />

                                {errors.bms_command && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.bms_command.message}</div>}
                            </Form.Group>
                        </div>
                    </div>
                </> : null}

                <div className={'row g-3'}>
                    <div className={"col-md-10"}>
                        <Form.Group>
                            <div>
                                <Form.Label>{language === "EN" ? "Number of cells" : "বিএমএস মডেল"}<span className="required text-danger">*</span></Form.Label>
                            </div>

                            <SolInput
                                type={"number"}
                                name={"number_of_cells"}
                                placeholder={language === "EN" ? "Number of cells" : "ব্যাটারির সক্ষমতা এম্পিয়ার/ঘন্টায়"}
                                autoComplete={"off"}
                                ref={register({
                                    required: 'Required',
                                })}
                            />

                            {errors.number_of_cells && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.number_of_cells.message}</div>}
                        </Form.Group>
                    </div>
                </div>



                <div className={'row'}>
                    <div className={"col-md-12"}>
                        <Button variant="dark" size={'md'}
                            onClick={() => {
                                props.history.goBack()
                            }} style={{ backgroundColor: '#8C8C9B', outline: '#8C8C9B', border: '#8C8C9B' }}>
                            <i className='fa fa-times' aria-hidden='true' />&nbsp;{language === "EN" ? "Cancel" : "বাতিল করুন"}
                        </Button>
                        <Button variant="warning" type="submit"
                            className={"ml-3"}
                            disabled={(props.batteryModelListDropDownLoading ||
                                props.batteryVendorListDropDownLoading || props.bmsVendorListDropDownLoading
                                || props.bmsModelListDropDownLoading || props.batteryInfoSubmitting)}>
                            {(props.batteryInfoSubmitting || props.buttonLoading)? <><Spinner animation={'border'} size={'sm'} variant={'light'} /> </> : null}
                            <i className='fa fa-paper-plane' aria-hidden='true' />&nbsp;{language === "EN" ? "Submit" : "সংরক্ষণ করুন"}
                        </Button>
                    </div>
                </div>


            </div>



        </div>
    </Form>


    return (
        <ContentWrapper showCardHeader={true} pageTitle={edit? "Update BMS Model": title} showBackButton={false} isLoading={false}>
            <div className={"row mb-6"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            {/* <h1><b>{(language === 'EN'? 'Create Vendor':'Create Vendor')}</b></h1> */}
                        </Toolbar.Title>
                    </Toolbar>
                </div>
            </div>
            {!edit && <div className={"col-md-6"}>
                <Form.Group>
                    <Form.Label>{language === "EN" ? "Action" : "সক্ষমতা (অ্যাম্পিয়ার/ঘন্টা)"}<span className="required text-danger">*</span></Form.Label>
                    <Select
                        name={`porduct_type`}
                        placeholder={language === 'EN' ? 'Select an action' : 'বিএমএস মডেল বাছাই করুন'}
                        classNamePrefix="react-select-sol-style"
                        maxMenuHeight={200}
                        value={type}
                        options={ftype}
                        isClearable={true}
                        control={control}
                        isSearchable={true}
                        onChange={(selected, { action }) => {
                            setType(selected)
                            if (selected) {
                                if (selected.value === ftype[1].value) {
                                    setTitle(modelRegistrationTitle)
                                } else {
                                    setTitle(vendorRegistrationTitle)
                                }
                            }
                            reset({
                                "product_type": null,
                                "model_type": null,
                                "name": '',
                                "vendor_guid": null,
                                "bms_command": '',
                                "bms_validation": ''
                            })
                        }}
                    />
                    {errors.nameplate_capacity && <div className="text-danger">
                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.nameplate_capacity.message}</div>}
                </Form.Group>
            </div>}


            {type && type.value === 'vendor' ? VendorCreationForm : ModelCreationForm}


            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
        </ContentWrapper>
    );
};

CreateSupplierInfomation.propTypes = {};


const mapStateToProps = (state) => {

    return {
        isLoading: state.batteryReducer.isLoading,
        language: state.auth.language,
        user: state.auth.user,
        vendorList: state.commonReducer.vendorList,
        listLoading: state.commonReducer.listLoading,
        modelDetails: state.batteryReducer.modelDetails,
        buttonLoading: state.batteryReducer.buttonLoading,
        recordSaved: state.batteryReducer.recordSaved



    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(CreateSupplierInfomation);
