import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import Home from "./Home";


export const HomeRouter =(props)=> {

    return (
        <Switch>
            {/* Routes to overview*/}
            <Route path={"/home"} component={Home} />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
