import React, { useEffect, useState } from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {FilterButton, SolInput, Toolbar} from "../../../../components";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DataTable, DataTableContainer, FilterInput, FilterReactSelect } from "../../../../components";
import { transactionActions } from "../../actions";
import { actions as commonActions } from '../../../commonReduxActions/actions';
import { actions as loanActions } from '../../../contractManagement/actions';
import { LoadingSpinner } from '../../../../components/LoadingSpinnerForDataTable'
import { connect } from "react-redux";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import { Form, Popover, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { theme, useStylesForDatePicker } from "../../../../utils/muiPickerCustomStyle";
import moment from "moment";
import 'moment/locale/bn';
import 'moment/locale/en-gb';
import { DayPickerRangeController } from "react-dates";
import { SolTypography } from '../../../../components/utilityComponents/SOlTypography';
import {exportInExcel} from "../../../../utils/excelExport";
import {convertToAnotherLang} from "../../../../utils/convertLanguageOfNumbersWithCharacter";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import { useSubheader } from "../../../../../_metronic/layout";
import Overlay from "react-bootstrap/Overlay";
import InfoIcon from '@mui/icons-material/Info';
import {contractManagementReducer} from "../../../contractManagement/reducer";


const Transactions = props => {
    const classes = useStylesForDatePicker();
    const subHeader = useSubheader();
    const { clearErrors, setError, reset, handleSubmit, control, setValue, errors, register } = useForm()
    const [language, setLanguage] = useState("EN");
    const [showFilter, setShowFilter] = useState(true);
    const [filterApplied, setFilterApplied] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [showTransactionDatepicker, setShowTransactionDatepicker] = useState(false);
    const [targetForTransactionDateField, setTargetForTransactionDateField] = useState(null);

    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [serialNumberNotNumericMessage, setSerialNumberNotNumericMessage] = useState(false);
    const [totalPayable, setTotalPayable] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);
    const [walletTransactions, setWalletTransactions] = useState([]);
    const [count, setCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [garages, setGarages] = useState(undefined);

    const [financiers, setFinanciers] = useState([]);
    const [financier, setFinancier] = useState("");
    const [financierFromUrl, setFinancierFromUrl] = useState("");
    const [borrowers, setBorrowers] = useState([]);
    const [borrower, setBorrower] = useState("");
    const [borrowerFromUrl, setBorrowerFromUrl] = useState("");
    const [transactionType, setTransactionType] = useState("");
    const [newDataIsAdded, setNewDataIsAdded] = useState(false);

    // Tracking filter values
    const [mobileWallet, setMobileWallet] = useState('');


    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [page, setPage] = useState(1);
    const [payment_file_download, set_payment_file_download] = useState(false);
    const defaultFilterParam = {
        page: 1
    }

    const TRANSACTION_TYPES = [
        {label: 'Recharge by MFS', value: 'bkash'},
        {label: 'Recharge by Cash', value: 'cash'},
        {label: 'Invoice', value: 'invoice'},
        {label: 'Payment', value: 'payment'},
    ]

    const get_transaction_description = (rowdata) => {
        let line_1 = null;
        let line_2 = null;
        let line_3 = null;
        let line_4 = null;
        if (rowdata.activity_for === 'topup' && rowdata.topup_details?.payment_mood === 'bkash'){
            line_1 = 'bKash from ' + rowdata.topup_details?.sender;
        }
        if (rowdata.activity_for === 'topup' && rowdata.topup_details?.payment_mood === 'cash'){
            line_1 = 'Cash received by ' + rowdata.topup_details?.recieved_by_person_name;
            line_2 = 'Money Receipt #' + rowdata.topup_details?.receipt_reference;
        }

        if (rowdata.activity_for === 'payment'){
            if (rowdata.contract_details?.contract_type === 'lease'){
                line_1 = 'Lease payment paid';
            }else{
                line_1 = 'Paid rent fee';
            }

            line_2 = 'Partialy paid ????';
            line_3 = 'Loan contract #' + rowdata.contract_id;
        }

        if (rowdata.activity_for === 'payment' && rowdata.invoice_details?.invoice_for === 'downpayment'){
            line_1 = 'Downpayment paid';
            line_2 = '';
            line_4 = ''
        }

        if (rowdata.activity_for === 'invoice' && rowdata.contract_details?.contract_type === 'lease'){
            line_1 = 'Payable Tk '+ new Intl.NumberFormat('en-IN').format(rowdata.contract_details?.per_installment_payable);
            if (rowdata.contract_details?.lease_payment_interval === 'weekly'){
                line_1 += ' per week'
            }else{
                line_1 += ' per month'
            }
            line_2 = 'Installment # ????';
            line_3 = 'Loan contract #' + rowdata.contract_id;
            line_4 = 'Invoice #' + rowdata.invoice_id;
        }

        if (rowdata.activity_for === 'invoice' && rowdata.contract_details?.contract_type === 'rent'){
            line_1 = 'Payable per rent fee Tk '+ new Intl.NumberFormat('en-IN').format(rowdata.contract_details?.per_rent_payable);
            line_2 = 'Rented by # ????';
            line_3 = 'Loan contract #' + rowdata.contract_id;
            line_4 = 'Invoice #' + rowdata.invoice_id;
        }

        if (rowdata.activity_for === 'invoice' && rowdata.invoice_details?.invoice_for === 'downpayment'){
            line_1 = 'Downpayment payable';
            line_2 = '';
            line_4 = ''
        }

        if (rowdata.activity_for === 'carry_forward_deposit'){
            line_1 = 'Deposit (Carry Forwarded)';
            line_2 = '';
            line_4 = ''
        }

        if (rowdata.activity_for === 'carry_forward_payable'){
            line_1 = 'Payable (Carry Forwarded)';
            line_2 = '';
            line_4 = ''
        }
        return line_1;
    }

    const get_transaction_type = (rowdata) => {
        let line_1 = null;
        if (rowdata.activity_for === 'topup' && rowdata.topup_details?.payment_mood === 'bkash'){
            line_1 = 'Recharge by MFS';
        }
        if (rowdata.activity_for === 'topup' && rowdata.topup_details?.payment_mood === 'cash'){
            line_1 = 'Recharge by Cash';
        }
        if (rowdata.activity_for === 'invoice' ){
            line_1 = 'Invoice';
        }
        if (rowdata.activity_for === 'payment'){
            line_1 = 'Lease Payment';
        }

        return line_1;
    }

    const get_driver_name = (driver_details) => {
        if(driver_details){
           return driver_details.split("#")[5]
        }
        return ''
    }

    const downloadMoneyReceipt = (data) => {
        FETCH_PAYMENT_DETAILS(data.topup_guid)
    }

    const download_payment_reciept = (data) => {
        set_payment_file_download(true)
        FETCH_PAYMENT_DETAILS(data.topup_guid)
    }

    const exportData = (data) => {
        let presentTime = moment()
        let dataLength = data.length
        let totalTransactionText = "Total Transactions: " + dataLength
        let exportText = "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A")
        let excelRows = [
            ["Transactions", "", "", "", "", "", "", "", "", ""],
            [totalTransactionText, "", "", "", "", "", "", "", "", ""],
            [exportText, "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", ""],
            ["Date", "Time", "Borrower Name", "Borrower Phone", "Garage", "Description", "Type",
                "Deposit", "Payable", "Paid"]
        ]
        if (filterApplied) {
            if (borrower) {
                let text = "Borrower: " + borrower
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
            if (start && end) {
                let from_date = moment(start).format("MMM DD, YYYY")
                let end_date = moment(end).format("MMM DD, YYYY")
                let text = "Transaction Date: " + from_date + " - " + end_date
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
            if (mobileWallet) {
                let text = "Transaction From: " + mobileWallet
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
        }
        for (let i=0; i<dataLength; i++) {
            let deposit = 0;
            let payable = 0;
            let paid = 0;
            if (data[i].activity_for === 'topup' || data[i].activity_for === 'carry_forward_deposit' ){
                deposit = data[i].amount;
            }
            if (data[i].activity_for === 'invoice'  || data[i].activity_for === 'carry_forward_payable'){
                payable = data[i].amount;
            }
            if (data[i].activity_for === 'payment'){
                paid = data[i].amount;
            }
            let garageName = data[i].garage_details? data[i].garage_details.name:''
            let borrowerName = data[i].borrower_details? data[i].borrower_details.name:''
            let borrowerPhoneNumber = data[i].borrower_details? data[i].borrower_details.username:''
            if (garages && garages.length > -1) {
                let filteredData = garages.filter(garage => garage.pk === data[i].garage_guid)
                if (filteredData.length > 0) {
                    garageName = filteredData[0].name
                    borrowerName = filteredData[0].garage_owner_name
                    borrowerPhoneNumber = filteredData[0].garage_phone_number
                }
            }
            excelRows.push(
                [
                    data[i].sk ? moment(data[i].sk).format("MM/DD/YY") : '',
                    data[i].sk ? moment(data[i].sk).format("hh:mm A") : '',
                    borrowerName,
                    borrowerPhoneNumber,
                    garageName,
                    get_transaction_description(data[i]),
                    get_transaction_type(data[i]),
                    deposit,
                    payable,
                    paid
                ])
        }
        let noDataAvailableText =  "No data available"
        dataLength > 0? excelRows.push(
            ["", "", "", "", "", "", "", "", "", ""]
        ):excelRows.push(["", "", "", "", noDataAvailableText, "", "", "", "", ""])
        let fileName = 'transactions_'
        exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
    }

    const transactionColumn = [
        {
            field: 'sk',
            title: "Date",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return "-"
            },
            render: (rowdata) => {
                return new DateTimeFormat(rowdata.sk).localDisplayTime
            }
        },
        {
            field: 'someDataIsAvailable',
            title: "Account",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            width: '2.5rem',
            emptyValue: () => {
                return "-"
            },
            render: (rowdata) => {
                return <>
                    <b><SolTypography.SubTitle className={"mb-5"}>{rowdata.borrowerName}</SolTypography.SubTitle></b>
                    <SolTypography.SubTitle>{rowdata.garageName}</SolTypography.SubTitle>
                    <SolTypography.SubTitle>{rowdata.borrowerPhoneNumber}</SolTypography.SubTitle>
                </>
            }
        },
        {
            field: 'activity_for',
            title: "Description",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            width: '2.5rem',
            emptyValue: () => {
                return "N/A"
            },
            render: (rowdata) => {
                let bold = false;
                let line_1 = null;
                let line_2 = null;
                let line_3 = null;
                let line_4 = null;
                let line_for_money_receipt = null;
                let line_for_reciept_files = null
                let line_for_payment_files = null
                if (rowdata.activity_for === 'topup' && (rowdata.topup_details?.payment_mood === 'bkash')){
                    line_1 = 'bKash from ' + rowdata.topup_details?.sender;
                }
                if (rowdata.activity_for === 'topup' && rowdata.topup_details?.payment_mood === 'cash'){
                    line_1 = 'Cash received by ' + rowdata.topup_details?.recieved_by_person_name;
                    line_for_money_receipt = 'Money Receipt #' + rowdata.topup_details?.receipt_reference;
                    line_for_reciept_files = rowdata?.topup_details?.receipt_file_name;
                    line_for_payment_files = rowdata?.topup_details?.receipt_file_name && rowdata?.topup_details?.invoice_payment_file_name ? `, ${rowdata?.topup_details?.invoice_payment_file_name}` : rowdata?.topup_details?.invoice_payment_file_name

                }
                
                if (rowdata.activity_for === 'payment'){
                    if (rowdata.contract_details?.contract_type === 'lease'){
                        line_1 = 'Lease payment paid';
                    }else{
                        line_1 = 'Paid rent fee';
                    }

                   let due = rowdata.amount - (rowdata.invoice_details?.payable_amount - rowdata.invoice_details?.paid_amount)
                    
                    if (due > 0){
                        line_2 = 'Partialy paid';
                    }
                    line_3 = 'Loan contract #' + rowdata.contract_id;
                }


                if (rowdata.activity_for === 'payment' && rowdata.invoice_details?.invoice_for === 'downpayment'){
                    line_1 = 'Downpayment paid';
                    line_2 = '';
                    line_4 = ''
                }

                
                if (rowdata.activity_for === 'invoice' && rowdata.contract_details?.contract_type === 'lease'){
                    line_1 = 'Payable Tk '+ new Intl.NumberFormat('en-IN').format(rowdata.contract_details?.per_installment_payable);
                    if (rowdata.contract_details?.lease_payment_interval === 'weekly'){
                        line_1 += ' per week'
                    }else{
                        line_1 += ' per month'
                    }
                    if(rowdata.invoice_details.installment_no){
                        line_2 = 'Installment #'+ rowdata.invoice_details.installment_no;
                    }
                    line_3 = 'Loan contract #' + rowdata.contract_id;
                    // line_4 = 'Invoice #' + rowdata.invoice_id;

                }
                
                if (rowdata.activity_for === 'invoice' && rowdata.contract_details?.contract_type === 'rent'){
                    line_1 = 'Payable per rent fee Tk '+ new Intl.NumberFormat('en-IN').format(rowdata.contract_details?.per_rent_payable);
                    line_2 = 'Rented by # '+ get_driver_name(rowdata.rent_details?.driver_details);
                    line_3 = 'Loan contract #' + rowdata.contract_id;
                    // line_4 = 'Invoice #' + rowdata.invoice_id;
                }

                if (rowdata.activity_for === 'invoice' && rowdata.invoice_details?.invoice_for === 'downpayment'){
                    line_1 = 'Downpayment payable';
                    line_2 = '';
                    line_4 = ''
                }

                if (rowdata.activity_for === 'carry_forward_deposit'){
                    line_1 = 'Deposit (Carry Forwarded)';
                    line_2 = '';
                    line_4 = '';
                    bold = true;
                }

                if (rowdata.activity_for === 'carry_forward_payable'){
                    line_1 = 'Payable (Carry Forwarded)';
                    line_2 = '';
                    line_4 = '';
                    bold = true;
                }
                 
                
                return <>
                     { line_1 && bold && <SolTypography.SubTitle> <b>{line_1}</b> </SolTypography.SubTitle> }
                     { line_1 && !bold && <SolTypography.SubTitle> {line_1} </SolTypography.SubTitle> }
                     { line_2 && <SolTypography.SubTitle> {line_2} </SolTypography.SubTitle> }
                     { line_for_money_receipt && <SolTypography.SubTitle >{line_for_money_receipt}</SolTypography.SubTitle>}
                    { <>
                        <SolTypography.Text primary onClick={(e) => { downloadMoneyReceipt(rowdata) }}
                            style={{ cursor: 'pointer' }}>{line_for_reciept_files}</SolTypography.Text><SolTypography.Text primary onClick={(e) => { download_payment_reciept(rowdata) }}
                                style={{ cursor: 'pointer' }}>{line_for_payment_files}</SolTypography.Text>
                    </>}
                     { line_3 && <SolTypography.SubTitle> {line_3} </SolTypography.SubTitle> }
                     { line_4 && <SolTypography.SubTitle> {line_4} </SolTypography.SubTitle> }
                    </>
            
            }
        },
        {
            field: 'activity_for',
            title: "Type",
            emptyValue: () => {
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            // disableClick: true,
            render: (rowdata) => {
                let line_1 = null;
                if (rowdata.activity_for === 'topup' && rowdata.topup_details?.payment_mood === 'bkash'){
                    line_1 = 'Recharge by MFS';
                }
                if (rowdata.activity_for === 'topup' && rowdata.topup_details?.payment_mood === 'cash'){
                    line_1 = 'Recharge by Cash';
                }
                if (rowdata.activity_for === 'invoice' ){
                    line_1 = 'Invoice';
                }
                if (rowdata.activity_for === 'payment'){
                    line_1 = 'Payment';
                }
                return <>
                    <b><SolTypography.SubTitle>{line_1}</SolTypography.SubTitle></b>
                </>
            }
        },
        {
            field: 'amount',
            title: "Deposit (Tk)",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: () => {
                return "N/A"
            },
            render: (rowdata) => {
                return <>{ rowdata.activity_for === 'topup' ||  rowdata.activity_for === 'carry_forward_deposit' ? new Intl.NumberFormat('en-IN').format(rowdata.amount.toFixed(2)) : null}</>
            }
        },
        {
            field: 'amount',
            title: "Payable (Tk)",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: () => {
                return "N/A"
            },
            render: (rowdata) => {
                return <>{ rowdata.activity_for === 'invoice' ||  rowdata.activity_for === 'carry_forward_payable'  ? new Intl.NumberFormat('en-IN').format(rowdata.amount.toFixed(2)) : null}</>
            }
        },
        {
            field: 'amount',
            title: "Paid (Tk)",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: () => {
                return "-"
            },
            render: (rowdata) => {
                return <>{ rowdata.activity_for === 'payment' ? new Intl.NumberFormat('en-IN').format(rowdata.amount.toFixed(2)) : null}</>
            }
        }
    ]

    const {
        FETCH_PAYMENTS,
        FETCH_PAYMENTS_FOR_EXPORT,
        GET_GARAGE_LIST,
        COLLECT_FINANCIERS,
        COLLECT_BORROWERS,
        FETCH_PAYMENT_DETAILS,
        RETURN_TO_INITIAL_STATE,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER
    } = props


    const collectLastSevenDaysData =  () => {
        setPage(1)
        let filter = {}
        let todaysDate = moment().format("YYYY-MM-DD")
        let sixDaysEarlier = moment(todaysDate).subtract(6, 'd').format("YYYY-MM-DD")
        setStartDate(moment(sixDaysEarlier))
        setEndDate(moment(todaysDate))
        let from_date = props.language === 'EN'? sixDaysEarlier:convertToAnotherLang(sixDaysEarlier)
        let to_date = props.language === 'EN'? todaysDate:convertToAnotherLang(todaysDate)
        filter['created_at_from'] =  from_date
        filter['created_at_to'] =  to_date
        setFilterData(filter)
        setFilterApplied(true)
        FETCH_PAYMENTS({...defaultFilterParam, ...filter})
        GET_GARAGE_LIST()
    }

    useEffect(() => {
        setLanguage(props.language)
        collectLastSevenDaysData()
        COLLECT_BORROWERS()
        if (props.isSolshareUser) {
            COLLECT_FINANCIERS()
        }
        subHeader.setBreadCrumbComponent(null)
        subHeader.setActionButtons(null)
        return () => {
            RETURN_TO_INITIAL_STATE()
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
        }
    }, [])

    useEffect(() => {
        if (Array.isArray(props.garages)) {
            setGarages(
                props.garages.map((item) => {
                    return {
                        ...item,
                        value: item.pk,
                        label: item.name
                    }
                })
            )
        }
    }, [props.garages])

    useEffect(() => {
        if (props.topupDetails) {
            const link = document.createElement('a');

            link.href = payment_file_download? props.topupDetails?.payment_document : props.topupDetails?.invoice_payment_document;
            set_payment_file_download(false)
            link.setAttribute(
              'download',
              props.topupDetails?.topup_guid,
            );
        
            // Append to html link element page
            document.body.appendChild(link);
        
            // Start download
            link.click();
            link.parentNode.removeChild(link);

        }
    }, [props.topupDetails])

    useEffect(() => {
        if (props.borrowers) {
            const borrowers = props.borrowers;
            if (borrowers.length > -1) {
                setBorrowers(borrowers.map((borrower) => {
                    let label = borrower.name
                    let garageName = borrower.garage_name
                    if (garages && garages.length > -1) {
                        let filteredGarage = garages.filter(garage => garage.pk === borrower.garage_guids[0])
                        if (filteredGarage.length > 0) {
                            garageName = filteredGarage[0].name
                        }
                    }
                    label += ' (' + garageName + ')'

                    if (borrowerFromUrl && borrowerFromUrl === borrower.username) {
                        let selectedBorrower = borrower
                        selectedBorrower['label'] = borrower.name
                        selectedBorrower['value'] = borrower.pk
                        setValue('borrower', selectedBorrower)
                        setBorrower(selectedBorrower)
                    }
                    return {
                        ...borrower,
                        label: label,
                        value: borrower.pk
                    }
                }))
            }
        }
    }, [props.borrowers, garages])

    useEffect(() => {
        if (props.financiers) {
            const financiers = props.financiers;
            if (financiers.length > -1) {
                setFinanciers(financiers.map((financier) => {
                    if (financierFromUrl && financierFromUrl === financier.pk) {
                        let selectedFinancier = financier
                        selectedFinancier['label'] = financier.name
                        selectedFinancier['value'] = financier.pk
                        setValue('financier', selectedFinancier)
                        setFinancier(selectedFinancier)
                    }
                    return {
                        ...financier,
                        label: financier.name,
                        value: financier.pk
                    }
                }))
            }
        }
    }, [props.financiers])

    useEffect(() => {
        if (props.transactions) {
            setTotalDeposit(props.transactions.deposit_total)
            setTotalPayable(props.transactions.bill_total)
            setTotalPaid(props.transactions.payment_total)
            let payments = newDataIsAdded? walletTransactions:props.transactions.results
            let countOfItems = newDataIsAdded? count:props.transactions.count
            let countOfItemsPerPage = newDataIsAdded? itemsPerPage:props.transactions.page_size
            if (payments && payments.length > 0) {
                if (borrower && !newDataIsAdded) {
                    payments.unshift({
                        "sk": "",
                        "amount": props.transactions.deposit_carry_forward,
                        "activity_for": "carry_forward_deposit",
                        someDataIsAvailable: true
                    })
                    payments.unshift({
                        "sk": "",
                        "amount": props.transactions.payable_carry_forward,
                        "activity_for": "carry_forward_payable",
                        someDataIsAvailable: true
                    })
                    countOfItems += 2
                    countOfItemsPerPage += 2
                    setNewDataIsAdded(true)
                }
            }

            if (payments && payments.length > -1) {
                setCount(countOfItems)
                setItemsPerPage(countOfItemsPerPage)

                setWalletTransactions(payments.map((payment) => {
                    let someDataIsAvailable = true
                    let garageName = payment.garage_details? payment.garage_details.name:''
                    let borrowerName = payment.borrower_details? payment.borrower_details.name:''
                    let borrowerPhoneNumber = payment.borrower_details? payment.borrower_details.username:''
                    if (garages && garages.length > -1) {
                        let filteredData = garages.filter(garage => garage.pk === payment.garage_guid)
                        if (filteredData.length > 0) {
                            garageName = filteredData[0].name
                            borrowerName = filteredData[0].garage_owner_name
                            borrowerPhoneNumber = filteredData[0].garage_phone_number
                        }
                    }
                    if (!garageName && !borrowerName && !borrowerPhoneNumber) {
                        someDataIsAvailable = ''
                    }
                    return {
                        ...payment,
                        someDataIsAvailable: someDataIsAvailable,
                        garageName: garageName,
                        borrowerName: borrowerName,
                        borrowerPhoneNumber: borrowerPhoneNumber
                    }
                }))
            }
        } else {
            setNewDataIsAdded(false)
        }
    }, [props.transactions, garages])

    const resetFilterValues = () => {
        setStartDate(null)
        setEndDate(null)
        reset({
            "sender": "",
            "borrower": "",
            "transaction_type": "",
            "financier": "",
            "dateRangePickerInput": ""
        })
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        if (filterApplied) {
            FETCH_PAYMENTS({page: newPage, ...filterData})
        } else {
            FETCH_PAYMENTS({page: newPage})
        }
        GET_GARAGE_LIST()
    }

    const filterTheList = (data) => {
        let filter = {}
        if (data?.sender) {
            filter['topup_sender'] = data.sender
            setMobileWallet(data.sender)
        }
        if (data?.borrower?.value) {
            filter['borrower_guid'] = data.borrower.value
            setBorrower(data.borrower.label)
        }
        if (data?.transaction_type?.value) {
            if (data.transaction_type.value === 'cash' || data.transaction_type.value === 'bkash'){
                filter['topup_mode'] = data.transaction_type.value
            }else{
                filter['activity_for'] = data.transaction_type.value
            }
            setTransactionType(data.transaction_type.label)
        }
        if (data?.financier?.value) {
            filter['organization_guid'] = data.financier.value
            setFinancier(data.financier.label)
        }
        if (start && end) {
            filter['created_at_from'] = moment(start).format('YYYY-MM-DD')
            filter['created_at_to'] = moment(end).format('YYYY-MM-DD')
        }
        if (Object.keys(filter).length > 0) {
            setFilterData(filter)
            FETCH_PAYMENTS({...defaultFilterParam, ...filter})
            setFilterApplied(true)
            GET_GARAGE_LIST()
        }
    }

    const resetFilter = () => {
        // We are not setting setFilterApplied(false) as data will always be filtered based on date - Noor Reza, 18th Sept. 2023, 2:28 PM
        if (filterApplied) {
            // Resetting the list
            setPage(1)
            setFilterData({})
            collectLastSevenDaysData()
        }
        resetFilterValues()
    }

    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    }, [start, end])

    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const [clickCount, setClickCount] = useState(0)
    const onDatesChange = ({startDate, endDate}) => {
        setClickCount(clickCount + 1)
        if (startDate && endDate) {
            setStartDate(startDate)
            setEndDate(endDate)
            if (clickCount === 1) {
                setClickCount(0)
                setShowTransactionDatepicker(false)
            }
        } else if (startDate && !endDate) {
            setStartDate(startDate)
            setEndDate(startDate)
        }
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const showHelpfulText = () => {
        return <>
            <div className={'container'} style={{color: "#F18D00"}}><InfoIcon size={'sm'}/> Selection is completed
                after <strong>two clicks on a single date</strong> or <strong>single click on two dates.</strong></div>
        </>
    }

    const CustomDataTableContainer = styled.div`
      padding: 1rem 0 0 0;
      min-height: 25rem;
      height: auto;
    `

    const popover = () => {
        return <>
            <Overlay
                show={showTransactionDatepicker}
                target={targetForTransactionDateField}
                placement="bottom-start"
            >
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerRangeController
                            startDate={start}
                            endDate={end}
                            numberOfMonths={2}
                            minimumNights={0}
                            firstDayOfWeek={0}
                            isOutsideRange={disableFutureDt}
                            onDatesChange={onDatesChange}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                            renderKeyboardShortcutsButton={showHelpfulText}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "dateRangePickerInput") {
                                    setShowTransactionDatepicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }

    const FilterForm = <>
        <h4><SolTypography.SubTitle >Filter</SolTypography.SubTitle></h4>
        <br />

        <div className={'row'}>
            <div className={'col-md-12'}>
                <Form onSubmit={handleSubmit(filterTheList)}>
                    <div className={'row g-6'}>


                        <div className={props.isSolshareUser? 'col-md-2':'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Transaction From</SolTypography.Text></Form.Label>
                                </div>
                                <FilterInput
                                    name={'sender'}
                                    type="text"
                                    placeholder={'Type Mobile Number'}
                                    ref={register()}
                                />
                                {errors.sender ? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.sender.message}</div></>:null}

                            </Form.Group>
                        </div>

                        <div className={props.isSolshareUser? 'col-md-2':'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Transaction Date</SolTypography.Text></Form.Label>
                                </div>
                                <FilterInput
                                    name={'dateRangePickerInput'}
                                    readOnly={true}
                                    value={dateRange}
                                    placeholder={"Select a date range"}
                                    onClick={(e) => {
                                        setTargetForTransactionDateField(e.target)
                                        setShowTransactionDatepicker(!showTransactionDatepicker)
                                    }}
                                />
                            </Form.Group>
                        </div>


                        <div className={props.isSolshareUser? 'col-md-2':'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Borrower</SolTypography.Text></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"borrower"}
                                    rules={{}}
                                    render={({ onChange, onBlur, value, name, ref },
                                        { invalid, isTouched, isDirty }) => (
                                        <FilterReactSelect
                                            name={name}
                                            placeholder={"Select Borrower"}
                                            isDisabled={props.collectingBorrowers}
                                            isLoading={props.collectingBorrowers}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={borrowers}
                                            isSearchable={true}
                                            onChange={(selected, { action }) => {
                                                if (action === "clear") {
                                                    setBorrower("")
                                                }
                                                onChange(selected)
                                                if (selected) {
                                                    setBorrower(selected)
                                                }
                                            }}
                                        />
                                    )}
                                />
                                {serialNumberNotNumericMessage ? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{"Device serial number must contain only numbers!"}</div></> : null}
                            </Form.Group>
                        </div>


                        <div className={props.isSolshareUser? 'col-md-2':'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Transaction Type</SolTypography.Text></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"transaction_type"}
                                    rules={{}}
                                    render={({ onChange, onBlur, value, name, ref },
                                        { invalid, isTouched, isDirty }) => (
                                        <FilterReactSelect
                                            name={name}
                                            placeholder={"Select Type"}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={TRANSACTION_TYPES}
                                            isSearchable={true}
                                            onChange={(selected, { action }) => {
                                                if (action === "clear") {
                                                    setTransactionType("")
                                                }
                                                onChange(selected)
                                                if (selected) {
                                                    setTransactionType(selected)
                                                }
                                            }}
                                        />
                                    )}
                                />
                                {serialNumberNotNumericMessage ? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{"Device serial number must contain only numbers!"}</div></> : null}
                            </Form.Group>
                        </div>

                        {props.isSolshareUser? <>
                            <div className={'col-md-2'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label><SolTypography.Text secondary>{language === 'EN'? 'Financier':'অর্থদাতা'}</SolTypography.Text></Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"financier"}
                                        rules={{}}
                                        render={({ onChange, onBlur, value, name, ref },
                                                 { invalid, isTouched, isDirty }) => (
                                            <FilterReactSelect
                                                name={name}
                                                placeholder={language === 'EN'? 'Select Financier':'অর্থদাতা বাছাই করুন'}
                                                isDisabled={props.collectingFinanciers}
                                                isLoading={props.collectingFinanciers}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={financiers}
                                                isSearchable={true}
                                                onChange={(selected, { action }) => {
                                                    if (action === "clear") {
                                                        setFinancier("")
                                                    }
                                                    onChange(selected)
                                                    if (selected) {
                                                        setFinancier(selected)
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    {serialNumberNotNumericMessage ? <><div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{"Device serial number must contain only numbers!"}</div></> : null}
                                </Form.Group>
                            </div>
                        </>:null}
                    </div>
                    <div className={'row'}>
                        <div className={"col-md-12 no-padding"}>

                            <FilterButton variant="warning" size={'sm'} type="submit"
                                disabled={props.collectingTransactions}
                                className={'ml-3'}>
                                Apply Filter
                            </FilterButton>

                            &nbsp;
                            &nbsp;

                            <FilterButton variant="outline-dark" size={'sm'} disabled={props.collectingTransactions}
                                          onClick={() => { resetFilter() }}>
                                Reset Filter
                            </FilterButton>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
        <hr /></>

    const collectWalletDataForExport = () => {
        FETCH_PAYMENTS_FOR_EXPORT({...filterData})
    }

    useEffect(() => {
        if (props.transactionsForExport) {
            exportData(props.transactionsForExport)
        }
    }, [props.transactionsForExport])

    return (
        <ContentWrapper isLoading={false} pageTitle={"Transactions"} showBackButton={false} showCardHeader={false}>

            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>Transactions</b></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            <IconButton 
                                onClick={collectWalletDataForExport}
                                disabled={props.collectingTransactions}>
                                {props.collectingTransactionsForExport? <Spinner animation={"grow"} variant={'warning'}
                                                                         size={"lg"}/>:<img
                                    src={require('../../../../utils/asset/download-Button.svg')} alt="Download"/>}
                            </IconButton>
                            <IconButton onClick={filterToggle}>
                                <FilterListIcon color="disabled" fontSize="large" />
                            </IconButton>
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>

                <hr />
            </div>


            {showFilter ?
                FilterForm : null}
            <div>
                {props.collectingTransactions !== true ? <>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        style={{color: "inherit"}}
                        className={'mr-3'}
                    >
                        <Stack
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            className={'mr-16'}
                            spacing={2}
                        >
                            <span>TOTAL DEPOSIT   </span>
                            <span><strong>{totalDeposit? 'TK ' + new Intl.NumberFormat('en-IN').format(totalDeposit.toFixed(2)): 'TK 0'}</strong></span>
                        </Stack>

                        <Stack
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            className={'mr-23'}
                            spacing={2}
                        >
                            <span>TOTAL PAYABLE  </span>
                            <span><strong>{totalPayable? 'TK ' + new Intl.NumberFormat('en-IN').format(totalPayable.toFixed(2)): 'TK 0'}</strong></span>
                        </Stack>

                        <Stack
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            className={'mr-5'}
                            spacing={2}
                        >
                            <span>TOTAL PAID</span>
                            <span><strong>{totalPaid? 'TK ' + new Intl.NumberFormat('en-IN').format(totalPaid.toFixed(2)): 'TK 0'}</strong></span>
                        </Stack>
                    </Stack>
                    <CustomDataTableContainer>
                        <DataTable
                            columns={transactionColumn}
                            isLoading={props.collectingTransactions}
                            data={walletTransactions}
                            showToolbar={false}
                            asyncPagination={true}
                            count={count}
                            itemsPerPage={itemsPerPage}
                            onChangePage={onChangePage}
                            page={page}
                        />
                    </CustomDataTableContainer>
                </>:<LoadingSpinner language={language} loadingSubText={"Fetching transactions.."} />}
            </div>

            {popover()}
        </ContentWrapper>
    );
};

Transactions.propTypes = {

};

export default connect((state) => {
    return {
        language: state.auth.language,
        isSolshareUser: state.auth.is_solshare_user,
        isLoading: state.transactionReducer.isLoading,
        collectingTransactions: state.transactionReducer.collectingTransactions,
        transactions: state.transactionReducer.transactions,
        transactionsForExport: state.transactionReducer.transactionsForExport,
        collectingTransactionsForExport: state.transactionReducer.collectingTransactionsForExport,
        topupDetails: state.transactionReducer.topupDetails,
        collectingGarages: state.commonReducer.collectingGarages,
        garages: state.commonReducer.garages,
        borrowers: state.commonReducer.borrowers,
        collectingBorrowers: state.commonReducer.collectingBorrowers,
        financiers: state.contractManagementReducer.financiers,
        collectingFinanciers: state.contractManagementReducer.collectingFinanciers
    }
}, { ...transactionActions, ...commonActions, ...loanActions })(Transactions);
