export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',
    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',

    GET_SMART_DONGLE_LIST_REQUEST: 'GET_SMART_DONGLE_LIST_REQUEST',
    GET_SMART_DONGLE_LIST_SUCCESS: 'GET_SMART_DONGLE_LIST_SUCCESS',
    GET_SMART_DONGLE_LIST_FAILURE: 'GET_SMART_DONGLE_LIST_FAILURE',

    GET_FIRMWARE_LIST_REQUEST: 'GET_FIRMWARE_LIST_REQUEST',
    GET_FIRMWARE_LIST_SUCCESS: 'GET_FIRMWARE_LIST_SUCCESS',
    GET_FIRMWARE_LIST_FAILURE: 'GET_FIRMWARE_LIST_FAILURE',

    GET_HARDWARE_LIST_REQUEST: 'GET_HARDWARE_LIST_REQUEST',
    GET_HARDWARE_LIST_SUCCESS: 'GET_HARDWARE_LIST_SUCCESS',
    GET_HARDWARE_LIST_FAILURE: 'GET_HARDWARE_LIST_FAILURE',

    ADD_DONGLE_REQUEST: 'ADD_DONGLE_REQUEST',
    ADD_DONGLE_SUCCESS: 'ADD_DONGLE_SUCCESS',
    ADD_DONGLE_FAILURE: 'ADD_DONGLE_FAILURE',

    UPDATE_DONGLE_REQUEST: 'UPDATE_DONGLE_REQUEST',
    UPDATE_DONGLE_SUCCESS: 'UPDATE_DONGLE_SUCCESS',
    UPDATE_DONGLE_FAILURE: 'UPDATE_DONGLE_FAILURE',

    UPDATE_BULK_SMART_DONGLE_INFO_REQUEST: 'UPDATE_BULK_SMART_DONGLE_INFO_REQUEST',
    UPDATE_BULK_SMART_DONGLE_INFO_SUCCESS: 'UPDATE_BULK_SMART_DONGLE_INFO_SUCCESS',
    UPDATE_BULK_SMART_DONGLE_INFO_FAILURE: 'UPDATE_BULK_SMART_DONGLE_INFO_FAILURE',

    GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES_REQUEST: 'GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES_REQUEST',
    GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES_SUCCESS: 'GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES_SUCCESS',
    GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES_FAILURE: 'GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES_FAILURE',

    COLLECT_CHANGE_HISTORY_REQUEST: 'COLLECT_CHANGE_HISTORY_REQUEST',
    COLLECT_CHANGE_HISTORY_SUCCESS: 'COLLECT_CHANGE_HISTORY_SUCCESS',
    COLLECT_CHANGE_HISTORY_FAILURE: 'COLLECT_CHANGE_HISTORY_FAILURE',

    UPDATE_ORGANIZATIONAL_ACCESS_REQUEST: 'UPDATE_ORGANIZATIONAL_ACCESS_REQUEST',
    UPDATE_ORGANIZATIONAL_ACCESS_SUCCESS: 'UPDATE_ORGANIZATIONAL_ACCESS_SUCCESS',
    UPDATE_ORGANIZATIONAL_ACCESS_FAILURE: 'UPDATE_ORGANIZATIONAL_ACCESS_FAILURE',

    CLEAN_UP_STATES_RELATED_TO_ACCESS_MANAGEMENT_MODAL: 'CLEAN_UP_STATES_RELATED_TO_ACCESS_MANAGEMENT_MODAL',
};
