import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {theme, useStylesForDatePicker} from "../../../utils/muiPickerCustomStyle";
import 'moment/locale/bn';
import 'moment/locale/en-gb';

import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../components/dataTable/DataTable";
import {manualTransactionActions} from "../actions";
import {DataTableContainer} from "../../rentLog/utils";
import {LoadingSpinner} from '../../../components/LoadingSpinnerForDataTable'
import {Toolbar} from "../../smartDongles/views/components";
import {manualTransactionsReducer} from "../reducer";
import moment from "moment";
import {GenericModal} from "../../../components/genericModal/genericModal";
import {SolTypography} from "../../../components/utilityComponents/SOlTypography";
import {convertToAnotherLang} from "../../../utils/convertLanguageOfNumbersWithCharacter";



const PendingTransactions = props => {
    const classes = useStylesForDatePicker();
    const [language, setLanguage] = useState('EN');
    const [pendingPaymentBatches, setPendingPaymentBatches] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [transactionApprovalIsInProgress, setTransactionApprovalIsInProgress] = useState(false);
    const [uploadDate, setUploadDate] = useState('');
    const [transactionsInABatch, setTransactionsInABatch] = useState([]);
    const [batchMetaDataGuid, setBatchMetaDataGuid] = useState('');
    const [batchTotalTransactions, setBatchTotalTransactions] = useState(0);
    const [batchTotalAmount, setBatchTotalAmount] = useState(0);

    const {
        FETCH_MANUAL_TRANSACTION_PAYMENTS,
        FETCH_MANUAL_TRANSACTION_BATCH,
        APPROVE_TRANSACTION,
        RETURN_TO_INITIAL
    } = props;

    const pendingApprovalColumns = [
        {
            field: 'created_at',
            title: language === 'EN'? "Upload Date":"আপলোডের তারিখ",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
            // disableClick: true,
        },
        {
            field: 'number_of_payments',
            title: language === 'EN'? "Total Transactions":"লেনদেনের সংখ্যা",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
            // disableClick: true,
        },
        {
            field: 'total_amount',
            title: language === 'EN'? "Total Amount (BDT)":"সর্বমোট লেনদেন (টাকায়)",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            // disableClick: true
        },
    ];

    const batchDetailsTableColumns =  [
        {
            field: 'payment_date',
            title: language === 'EN'? "Payment date":"লেনদেনের তারিখ",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return rowData.payment_date? moment(rowData.payment_date).format('Do MMM,YYYY'):''
            }
        },
        {
            field: 'pay_amount',
            title: language === "EN"? "Amount":"পরিমাণ",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            }
        },
        {
            field: 'payment_mode',
            title: language === "EN"? "Mode":"লেনদেনের মোড",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            }
        },
        {
            field: 'bkash_transaction_id',
            title: language === "EN"? "Bkash Id":"বিকাশ আইডি",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'manual_payment_dongle_id',
            title: language === "EN"? "Dongle":"ডঙ্গল",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
        },
        {
            field: 'garage_name',
            title: language === "EN"? "Garage":"গ্যারেজ",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
        },
        {
            field: 'revenue_type',
            title: language === "EN"? "Revenue Type":"রেভেনিউয়ের ধরন",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
        },
        {
            field: 'remarks',
            title: language === "EN"? "Remarks":"মন্তব্য",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            }
        },
        {
            field: 'created_by_name',
            title: language === "EN"? "Uploaded by":"আপলোড করেছেন",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            }
        }
    ]

    const viewDetails = (rowData) => {
        setUploadDate(rowData.created_at)
        setBatchMetaDataGuid(rowData.pk)
        setBatchTotalTransactions(rowData.number_of_payments)
        setBatchTotalAmount(rowData.total_amount)
        setShowDetailsModal(true)
        FETCH_MANUAL_TRANSACTION_PAYMENTS(rowData.pk)
    }

    const closeDetailsModal = () => {
        setShowDetailsModal(false)
    }

    const approveTransactions = () => {
        APPROVE_TRANSACTION(batchMetaDataGuid)
    };

    useEffect(()=>{
        setLanguage(props.language)
        FETCH_MANUAL_TRANSACTION_BATCH({status: 'pending'})
        return ()=>{
            RETURN_TO_INITIAL()
        }
    },[])

    useEffect(() => {
        if (props.pendingTransactions) {
            const batchPendingTransactions = props.pendingTransactions;
            if (batchPendingTransactions && batchPendingTransactions.length > -1) {
                setPendingPaymentBatches(batchPendingTransactions.map((transaction) => {
                    let numberOfPayments = new Intl.NumberFormat('en-IN').format(transaction.number_of_payments)
                    let totalAmount = new Intl.NumberFormat('en-IN').format(transaction.total_amount)
                    return {
                        ...transaction,
                        created_at: moment(transaction.created_at).format("Do MMM YYYY, h:mm a"),
                        number_of_payments: language === 'EN'? numberOfPayments:convertToAnotherLang(numberOfPayments, 'BN'),
                        total_amount: language === 'EN'? totalAmount:convertToAnotherLang(totalAmount, 'BN')
                    }
                }))
            }
        }
    }, [props.pendingTransactions]);


    useEffect(() => {
        if (props.transactionsAreApproved) {
            setShowDetailsModal(false)
            FETCH_MANUAL_TRANSACTION_BATCH({status: 'pending'})
        }
    }, [props.transactionsAreApproved]);

    useEffect(() => {
        setTransactionApprovalIsInProgress(props.transactionsApprovalInProgress)
    }, [props.transactionsApprovalInProgress]);


    useEffect(() => {
        if (props.payments) {
            const transactionsInABatch = props.payments;
            if (transactionsInABatch && transactionsInABatch.length > -1) {
                // setBatchMetaDataGuid(transactionsInABatch[0].manual_transaction_meta_data_guid)
                setTransactionsInABatch(transactionsInABatch.map((transaction) => {
                    return {
                        ...transaction,
                        paymentDate: moment(transaction.payment_date).format("Do MMM YYYY"),
                        bkashId: transaction.bkash_transaction_id,
                        amount: language === 'EN'? transaction.pay_amount:convertToAnotherLang(transaction.pay_amount, 'BN'),
                        dongle: transaction.manual_payment_dongle_id,
                        rentDate: transaction.leasing_date? moment(transaction.leasing_date).format("Do MMM YYYY"):'N/A'
                    }
                }))
            }
        }
    }, [props.payments]);

    const renderDetailsModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                size={'xl'}
                footer={!props.pendingTransactionByBatchListLoading && transactionsInABatch?.length > 0}
                footerButtonSize={'md'}
                footerCloseButtonText={language === 'EN'? 'Close':'বন্ধ করুন'}
                footerActionButtonText={language === 'EN'? 'Approve':'অনুমোদন করুন'}
                hideModal={closeDetailsModal}
                takeAction={approveTransactions}
                centered={true}
                modal={showDetailsModal}
                disableButton={transactionApprovalIsInProgress}
                title={<>{language === 'EN'? 'Transactions uploaded at: ':''}<SolTypography.Text
                    primary>{uploadDate}</SolTypography.Text>{language === 'BN'? ' এ আপলোড হওয়া লেনদেনসমূহ':''}</>}>
                <div className={'row'}>
                    <div className={'col-lg-6'}><b>{language === 'EN'? 'Total transactions:':'সর্বমোট লেনদেন:'}</b>&nbsp;{batchTotalTransactions}</div>
                    <div className={'col-lg-6'}><b>{language === 'EN'? 'Total payments:':'লেনদেনের পরিমাণ'}</b>&nbsp;{batchTotalAmount}&nbsp;{language === 'EN'? 'BDT':'টাকা'}</div>
                </div>
                <DataTableContainer>
                    {props.pendingTransactionByBatchListLoading !== true ? <DataTable
                        language={language}
                        noDataAvailableMessageInBangla={'লেনদেন খুঁজে পাওয়া যায় নি'}
                        columns={batchDetailsTableColumns}
                        data={transactionsInABatch}
                        showToolbar={false}
                        pageSize={5}
                    /> : <LoadingSpinner language={language}/>}
                </DataTableContainer>
            </GenericModal>
        </>
    }

    return (
        <ContentWrapper isLoading={false} pageTitle={"Manual transactions"} showBackButton={false} showCardHeader={false}>
            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>{language === 'EN'? 'Pending transactions':'অননুমোদিত লেনদেনসমূহ'}</b></h1>
                    </Toolbar.Title>
                </Toolbar>
                <hr/>
            </div>
            <DataTableContainer>
                {props.pendingTransactionListLoading !== true ? <DataTable
                    language={language}
                    noDataAvailableMessageInBangla={'কোন অননুমোদিত লেনদেন নেই'}
                    columns={pendingApprovalColumns}
                    data={pendingPaymentBatches}
                    showToolbar={false}
                    // isLoading={props.tableLoading} // It is for pagination
                    actionColumnIndex={-1}
                    actions={[{}]}
                    actionButtonVariant={['warning']}
                    actionButtonSize={'sm'}
                    actionButtonClickEvent={[viewDetails]}
                    actionButtonText={['View Details']}
                /> : <LoadingSpinner language={language}/>}
            </DataTableContainer>

            {renderDetailsModal()}
        </ContentWrapper>
    );
};

PendingTransactions.propTypes = {

};

export default connect((state) => {
    return {
        language: state.auth.language,
        isLoading: state.manualTransactionsReducer.isLoading, // No need of this
        pendingTransactions: state.manualTransactionsReducer.pendingTransactions,
        payments: state.manualTransactionsReducer.payments,
        pendingTransactionListLoading: state.manualTransactionsReducer.pendingTransactionListLoading,
        pendingTransactionByBatchListLoading: state.manualTransactionsReducer.pendingTransactionByBatchListLoading,
        transactionsAreApproved: state.manualTransactionsReducer.transactionsAreApproved,
        transactionsApprovalInProgress: state.manualTransactionsReducer.transactionsApprovalInProgress
    }
}, {...manualTransactionActions}) (PendingTransactions);
