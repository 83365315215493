import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import {PermissionDenied} from "../modules/ErrorsExamples/PermissionDenied";
import {connect} from "react-redux";



/*Injecting roles with HOC*/
export const withRoles=(Component)=>{

    return connect(mapStateToProps)((props)=>{

        return <Component  {...props} b2bOrgGuid={props.organization_guid} isSolshare={props.is_solshare}/>
    })
}


const SecuredRoute = (props) => {
    const [routeIsPermitted, setRouteIsPermitted] = useState(false)

    useEffect(() => {
        if (props.authorizations) {
            let authorizations = props.authorizations
            let service = props.service
            let feature = props.feature
            let action = props.action

            if (authorizations.hasOwnProperty(service) && authorizations[service].hasOwnProperty(feature) && authorizations[service][feature].includes(action)) {
                setRouteIsPermitted(true)
            }
        }
    }, [props.authorizations])

    const {component: Component, path} = props;

    return (
        <Route path={path} component={routeIsPermitted? Component:PermissionDenied } />
    );
};


SecuredRoute.defaultProps = {
    relatedModule: []
}


SecuredRoute.propTypes = {
    component: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    service: PropTypes.string,
    feature: PropTypes.string,
    action: PropTypes.string
};


const mapStateToProps = (state) => {
    return {
        authorizations: state.auth.authorizations
    }
}

export default connect(mapStateToProps)(SecuredRoute);



