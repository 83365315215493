export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    GET_TOKEN_LIST_REQUEST: 'GET_TOKEN_LIST_REQUEST',
    GET_TOKEN_LIST_SUCCESS: 'GET_TOKEN_LIST_SUCCESS',
    GET_TOKEN_LIST_FAILURE: 'GET_TOKEN_LIST_FAILURE',

    GENERATE_TOKEN_REQUEST: 'GENERATE_TOKEN_REQUEST',
    GENERATE_TOKEN_SUCCESS: 'GENERATE_TOKEN_SUCCESS',
    GENERATE_TOKEN_FAILURE: 'GENERATE_TOKEN_FAILURE',

    CANCEL_TOKEN_REQUEST: 'CANCEL_TOKEN_REQUEST',
    CANCEL_TOKEN_SUCCESS: 'CANCEL_TOKEN_SUCCESS',
    CANCEL_TOKEN_FAILURE: 'CANCEL_TOKEN_FAILURE',
};
