import {qaTestActions} from "./actionTypes";
import {toast} from "react-toastify";

const initialState={
    buttonLoading: false,
    listLoading: false,
    testResults: undefined
}

export const pcbQATestingResultsReducer =(state = initialState, currentAction)=>{

    switch (currentAction.type){
        case qaTestActions.FETCH_PVB_Q4_TEST_RESULTS_REQUEST:
            return {
                ...state,
                listLoading: true,
                testResults: undefined
            }
        case qaTestActions.FETCH_PVB_Q4_TEST_RESULTS_SUCCESS:
            return {
                ...state,
                listLoading: false,
                testResults: currentAction.payload,
            }
        case qaTestActions.FETCH_PVB_Q4_TEST_RESULTS_FAILURE:
            return {
                ...state,
                listLoading: false,
                testResults: undefined
            }


        case qaTestActions.UPLOAD_PCBA_TESTS_REQUEST:
            return {
                ...state,
                buttonLoading: true,
            }
        case qaTestActions.UPLOAD_PCBA_TESTS_SUCCESS:
            toast.success("Test data have been uploaded!")
            return {
                ...state,
                buttonLoading: false
            }
        case qaTestActions.UPLOAD_PCBA_TESTS_FAILURE:
            // TODO: There is a common 'showErrorMessage' function that are doing exactly the same
            // TODO: We will use that later
            if (currentAction?.error && currentAction.payload?.error?.message){
                toast.error(currentAction.payload?.error?.message)
            }else {
                toast.error("Failed to upload test data!")
            }
            return {
                ...state,
                buttonLoading: false
            }

        case qaTestActions.RETURN_TO_INITIAL:
            return initialState
        default:
            return initialState

    }
}
