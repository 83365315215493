import React, {useState} from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {actions} from "../actions";
import  { Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import {isValidPhoneNumber} from 'libphonenumber-js';
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import isEmail from "validator/lib/isEmail";

function Login(props) {
  const {register, handleSubmit, errors, clearErrors, setError}= useForm();
  const [invalidErrorMsg, setInvalidErrorMsg]= useState(false);
  const [english, setEnglish]= useState(true);
  const [emailSignInButtonClicked, setEmailSignInButtonClicked]= useState(false);

  const infoRequiredMsg = 'Above information is required!'
  const credentialSubmit=(data)=> {
    setEmailSignInButtonClicked(false)
    if (data.username.includes('@') && !isEmail(data.username)) {
      // Email validation
      setError('username', {'message': 'Invalid input!'})
    } else if (!data.username.includes('@') && !isValidPhoneNumber(data.username, 'BD')) {
      // Phone Number validation
      setError('username', {'message': 'Invalid input!'})
    } else {
      clearErrors()
      data["language"] = english? 'EN':'BN'
      props.LOGIN(data)
    }
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div id="recaptcha-container"/>
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          {/*<FormattedMessage id="AUTH.LOGIN.TITLE" />*/}
          {english? process.env.REACT_APP_APP_NAME_ENGLISH:process.env.REACT_APP_APP_NAME_BANGLA}
        </h3>
        <br/>
        {/*<p className="text-muted font-weight-bold">*/}
        <Form
            onSubmit={handleSubmit(credentialSubmit)}
            className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <div className="form-group fv-plugins-icon-container">
              <div className={'col-md-12 row no-padding mb-3'}>
                <div className={'col-md-12 no-padding'} style={{
                  paddingLeft: '5px'
                }}>
                  <InputGroup>
                    <InputGroup.Text><i className="fa fa-user" aria-hidden="true"/></InputGroup.Text>
                    <Form.Control
                        placeholder={english? "Phone number or email":"ফোন নাম্বার অথবা ইমেইল"}
                        type="text"
                        name="username"
                        autoComplete="off"
                        ref={register({
                          required: infoRequiredMsg,
                        })}
                    />
                  </InputGroup>
                  {errors.username? <div className={"text-danger float-left"}>
                    <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{errors.username.message}</div>:null}
                  {props.isError && props.errorMsg && <div className={"text-danger float-left"}>
                    <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{props.errorMsg}</div>}
                </div>
              </div>

              <div className={'col-md-12 row no-padding mb-3'}>
                <div className={'col-md-12 no-padding'} style={{
                  paddingLeft: '5px'
                }}>
                  <InputGroup className="mb-2">
                    <InputGroup.Text><i className="fa fa-key" aria-hidden="true"/></InputGroup.Text>
                    <Form.Control
                        placeholder={english? "Password":"পাসওয়ার্ড"}
                        type="password"
                        name="password"
                        ref={register({
                          required: infoRequiredMsg,
                        })}
                    />
                  </InputGroup>
                  {(props.authentication_request_response && !props.user)? <div className={"text-danger float-left"}>
                    <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{props.authentication_request_response.error.message}</div>:null}
                  {props.isError && props.errorMsg && <div className={"text-danger float-left"}>
                    <i className={"flaticon-warning kt-font-brand"}/>&nbsp;{props.errorMsg}</div>}
                </div>
              </div>

            <div className={'col-md-12 row no-padding d-flex justify-content-between'}>
              <div className={'d-inline'}>
                <Button
                  disabled={props.requesting_for_auth}
                  variant={'warning'}
                  type={'button'}
                  // className={'float-right'}
                  onClick={() => {
                    setEmailSignInButtonClicked(true)
                    props.LOGGING_IN_WITH_GMAIL();
                  }}
                  style={{backgroundColor: '#F18D00', borderColor: '#F18D00'}}
                >
                  {props.requesting_for_auth && emailSignInButtonClicked? <><Spinner animation="border"  size={'sm'} variant="light"/>&nbsp; &nbsp;</>:''}{english ? 'Sign In With SOLshare' : 'সাইন ইন'}
                </Button>
              </div>
              {/*<div className={'col-md-5 d-inline'}></div>*/}
              <div className={'d-inline'}>
                <Button
                    type="submit"
                    variant={english? 'primary':'warning'}
                    // className={'float-right'}
                    disabled={props.requesting_for_auth}
                >
                  {english? 'Sign In': 'সাইন ইন'}
                  {props.requesting_for_auth && !emailSignInButtonClicked? <>&nbsp; &nbsp;<Spinner animation="border"  size={'sm'} variant="light"/></>:''}
                </Button>
              </div>
            </div>
          </div>

        </Form>
        {/*</p>*/}
      </div>
      {/* end::Head */}

    </div>
  );
}

/*Mapping the store variable to props for this component*/
const mapStateToProps = state =>{
  return {
    firebase: state.auth.firebase,
    user: state.auth.user,
    confirmation: state.auth.confirmation,
    isError: state.auth.isError,
    errorMsg: state.auth.errorMsg,
    buttonLoading: state.auth.loading,
    authentication_request_response: state.auth.authentication_request_response,
    requesting_for_auth: state.auth.requesting_for_auth
  }
};

export default injectIntl(connect(mapStateToProps, actions)(Login));
