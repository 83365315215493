import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { actions } from '../../../actions';
import TableContainer from "@mui/material/TableContainer";
import Table from "react-bootstrap/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import parse from 'html-react-parser';


const ContractSummary = ({ contractDetails, garageName, borrowerName, tableHeaderLeftAlignedColumnStyling,
                             tableHeaderRightAlignedColumnStyling, tableLeftAlignedContentStyling,
                             tableRightAlignedContentStyling,  ...props }) => {
    const [language, setLanguage] = useState("EN");

    const [smartBattery, setSmartBattery] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [batteryVendor, setBatteryVendor] = useState('');
    const [referenceCapacity, setReferenceCapacity] = useState('');
    const [namePlateVoltage, setNamePlateVoltage] = useState('');
    const [contractDate, setContractDate] = useState('');
    const [contractDuration, setContractDuration] = useState('');
    const [contractGracePeriod, setContractGracePeriod] = useState('');
    const [contractAmount, setContractAmount] = useState('');
    const [downPayment, setDownPayment] = useState('');
    const [rePayment, setRePayment] = useState('');

    const {
        // Will add action here, if needed
    } = props;

    useEffect(() => {
        setLanguage(props.language)

        setSmartBattery(contractDetails.device_serial_number)

        let vehicleType = ''
        if (contractDetails.ev_details) {
            vehicleType = contractDetails.ev_details.ev_type.split("_").map((partOfName) => {
                return partOfName.charAt(0).toUpperCase() + partOfName.slice(1)
            }).join(' ')
        }
        setVehicleType(vehicleType)

        setBatteryVendor(contractDetails.device_details?.battery_details?.battery_vendor)
        setReferenceCapacity(contractDetails.device_details?.battery_details?.reference_capacity)
        setNamePlateVoltage(contractDetails.device_details?.battery_details?.nameplate_voltage)
        setContractDate(moment(contractDetails.effective_from).format("MMM DD, YYYY"))

        let contractEffectiveTill = moment(contractDetails.effective_till, "YYYY-MM-DD")
        if (contractEffectiveTill) {
            let contractEffectiveFrom = moment(contractDetails.effective_from, "YYYY-MM-DD")
            let contractDurationText = ''
            let differenceInYears = parseInt(contractEffectiveTill.diff(contractEffectiveFrom, 'years', true))
            if (differenceInYears > 0) {
                contractEffectiveFrom = contractEffectiveFrom.add(differenceInYears, 'years')
                contractDurationText += differenceInYears > 1 ? differenceInYears + ' years' : differenceInYears + ' year'
            }
            let differenceInMonths = parseInt(contractEffectiveTill.diff(contractEffectiveFrom, 'months', true))
            if (differenceInMonths > 0) {
                contractEffectiveFrom = contractEffectiveFrom.add(differenceInMonths, 'months')
                contractDurationText += differenceInMonths > 1 ? ' ' + differenceInMonths + ' months' : ' ' + differenceInMonths + ' month'
            }
            let differenceInDays = parseInt(contractEffectiveTill.diff(contractEffectiveFrom, 'days', true))
            if (differenceInDays > 0) {
                contractDurationText += differenceInDays > 1 ? ' ' + differenceInDays + ' days' : ' ' + differenceInDays + ' day'
            }
            setContractDuration(contractDurationText)
        }

        let gracePeriod = contractDetails.grace_period
        if (gracePeriod && gracePeriod === 1) {
            setContractGracePeriod(' with <strong>' + gracePeriod + ' day grace period</strong>')
        } else if (gracePeriod && gracePeriod > 1) {
            setContractGracePeriod(' with <strong>' + gracePeriod + ' days grace period</strong>')
        }

        setContractAmount(new Intl.NumberFormat('en-IN').format(contractDetails.loan_amount.toFixed(2)))
        setDownPayment(new Intl.NumberFormat('en-IN').format(contractDetails.downpayment_amount.toFixed(2)))

        // Repayment information
        let contractType = contractDetails.contract_type
        let repaymentText = ''
        let leaseType = contractDetails.lease_payment_interval
        if (contractType === 'rent') {
            repaymentText = 'Tk ' + new Intl.NumberFormat('en-IN').format(contractDetails.per_rent_payable.toFixed(2)) + ' / Rent'
        } else {
            if (leaseType === 'weekly') {
                repaymentText = 'Tk ' +
                    new Intl.NumberFormat('en-IN').format(contractDetails.per_installment_payable.toFixed(2)) + ' / Week'
            } else {
                // Monthly lease
                repaymentText = 'Tk ' +
                    new Intl.NumberFormat('en-IN').format(contractDetails.per_installment_payable.toFixed(2)) + ' / Month'
            }
        }
        setRePayment(repaymentText)
    }, [])

    return (
        <>
            <div className={'row pt-0'}>
                <div className={'col-lg-12'}>
                    <TableContainer>
                        <Table bordered={true} aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Assets</TableCell>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Borrower</TableCell>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Contract Date</TableCell>
                                    <TableCell style={tableHeaderRightAlignedColumnStyling}>Contract Amount (Tk)</TableCell>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Repayment</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={tableLeftAlignedContentStyling}>
                                        <h6><strong>Smart Battery #{smartBattery}</strong></h6>
                                        {/* TODO: Need to verify the following checking */}
                                        {/* TODO: As batteryVendor has been loaded from battery_details within the device_details, not exclusively */}
                                        {contractDetails.device_details && contractDetails.battery_details? <>
                                            {vehicleType? <>
                                                <span>{vehicleType} Battery, {batteryVendor}</span><br/>
                                            </>:<>
                                                <span>{batteryVendor}</span><br/>
                                            </>}
                                            <span>{referenceCapacity? referenceCapacity + 'Ah ' + namePlateVoltage + 'v':namePlateVoltage + 'v'}</span>
                                        </>:<>
                                            {vehicleType? <>
                                                <span>{vehicleType}</span><br/>
                                            </>:null}
                                        </>}
                                    </TableCell>
                                    <TableCell style={tableLeftAlignedContentStyling}>
                                        <h6><strong>{borrowerName}</strong></h6>
                                        {garageName? <>
                                            <span>{garageName}</span><br/>
                                        </>:null}
                                    </TableCell>
                                    <TableCell style={tableLeftAlignedContentStyling}>
                                        <h6><strong>{contractDate}</strong></h6>
                                        {contractDuration? <>
                                            <span>{contractDuration} contract{contractGracePeriod? parse(contractGracePeriod):''}</span>
                                        </>:''}
                                    </TableCell>
                                    <TableCell style={tableRightAlignedContentStyling}>
                                        <h6><strong>{contractAmount}</strong></h6>
                                        {downPayment !== '0'? <>
                                            <span>Downpayment: Tk {downPayment}</span>
                                        </>:null}
                                    </TableCell>
                                    <TableCell style={tableLeftAlignedContentStyling}>
                                        <h6><strong>{rePayment}</strong></h6>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </>
    )
}

ContractSummary.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations
    }
}

export default connect(mapStateToProps, { ...actions})(ContractSummary);