import React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Form, Button, Spinner } from 'react-bootstrap';
import { SolInput, DataTableContainer, DataTable, SolTypography, LoadingSpinner } from '../../../../components';
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import DateTimeFormat from '../../../../utils/dateFormat/DateTimeFormat';
import {toast} from "react-toastify";



const RentPerDayModalContent = ({ data, rentIsUpdated, ...props }) => {
    const [language, setLanguage] = useState("EN");
    const { control, register, setError, errors, setValue, handleSubmit } = useForm();

    const {
        CHANGE_RENT_PER_DAY_FOR_DEVICE,
        GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE,
        CLEAN_UP_RENT_CHANGE_MODAL,
    } = props;

    const columns = [

        {
            field: 'created_at',
            title: language === 'EN' ? 'Date' : 'ডিভাইস আইডি',
            emptyValue: () => {
                return "-"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            render: (rowData) => {
                return <>
                    <SolTypography.Text>{new DateTimeFormat(rowData.created_at).localDisplayTime}</SolTypography.Text>
                </>
            }
            // disableClick: true,
        },
        {
            field: 'previous_rent_per_day_amount',
            title: language === 'EN' ? 'Previous Amount (Tk)' : 'ডিভাইস আইডি',
            emptyValue: () => {
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            // disableClick: true,
        },
        {
            field: 'current_rent_per_day_amount',
            title: language === 'EN' ? 'Changed To (Tk)' : 'ডিভাইস আইডি',
            emptyValue: () => {
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            // disableClick: true,
        },
        {
            field: 'changed_by_name',
            title: language === 'EN' ? 'Changed By' : 'ডিভাইস আইডি',
            emptyValue: () => {
                return "-"
            },
            // disableClick: true,
        },

    ]

    const changeRent = (payload) => {
        payload = {
            "amount": payload.amount,
            "device_serial_number": data.serial_number
        }
        CHANGE_RENT_PER_DAY_FOR_DEVICE(payload)
    }

    useEffect(() => {
        GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE({ device_serial_number: data.serial_number });

        setValue("amount", data?.rent_per_day_amount)
        return () => {
            CLEAN_UP_RENT_CHANGE_MODAL();
        }
    }, [])

    useEffect(() => {
        if (props.rentChanged) {
            toast.success("Rent is updated for the Smart Battery!")
            GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE({ device_serial_number: data.serial_number })
            rentIsUpdated(true)
        } else if (props.rentChanged !== undefined) {
            toast.error("Rent can not be updated!")
        }
    }, [props.rentChanged])


    return (
        <div>
            <Form onSubmit={handleSubmit(changeRent)}>
                <div className={'row g-12'}>
                    <div className={"col-md-4"}>
                        <Form.Group >
                            <div>
                                <Form.Label>Per Rent Payable (Tk)<span className="required text-danger">*</span></Form.Label>
                            </div>

                            <SolInput
                                type="number"
                                name={"amount"}
                                step={"any"}
                                ref={register({
                                    required: "Required",
                                    setValueAs: v => parseFloat(v).toFixed(2),
                                    max: {
                                        value: 999.99,
                                        message: "Value can't be more than 999.99!"
                                    },
                                })}
                                placeholder={"100.50"}
                            />
                            
                            {errors.amount ? <><span className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.amount.message}
                            </span></> : <><span className="text-muted">
                                Changed amount will be affected from the next rent onwards
                            </span></>}
                        </Form.Group>
                    </div>

                    <div className={"col-md-6"}>
                        <Form.Group>
                            <div>
                                <Form.Label >&nbsp;</Form.Label>
                            </div>
                            <Button variant="warning" type="submit"
                                    disabled={props.buttonLoading}
                                    className={"ml-3"}>
                                {props.buttonLoading === true ? <><Spinner animation={'border'} size={'sm'} variant={'light'} /> </> : null}
                                <i className='fa fa-paper-plane' aria-hidden='true' />&nbsp;{language === "EN" ? "Change" : "সংরক্ষণ করুন"}
                            </Button>
                        </Form.Group>
                    </div>
                </div>
            </Form>
            <div className={'row'}>
                <div className={'col-md-12'}>

                    <DataTableContainer>
                        {props.modalListLoading !== true ? <>
                            <SolTypography.Title secondary className={'mb-4'}>Recent Rent Change History</SolTypography.Title>
                            <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন যন্ত্র নেই'}
                                columns={columns}
                                data={props.rentChangeHistory}
                                showToolbar={false}
                                asyncPagination={false}
                                isLoading={props.tableLoading}
                            />
                        </> : <LoadingSpinner loadingSubText={language === 'EN' ? 'Generating Change Log ..' : 'যন্ত্রের তালিকা প্রস্তুত হচ্ছে'} language={language} />}
                    </DataTableContainer>
                </div>
            </div>
        </div>
    );
};

RentPerDayModalContent.propTypes = {

};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.devicesReducer.isLoading,
        buttonLoading: state.devicesReducer.buttonLoading,
        rentChanged: state.devicesReducer.rentChanged,
        rentChangeHistory: state.devicesReducer.rentChangeHistory,
        modalListLoading: state.devicesReducer.modalListLoading,
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(RentPerDayModalContent));