import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {actions} from "../../../../actions";
import {actions as commonActions} from "../../../../../commonReduxActions/actions";
import {makeStyles} from "@material-ui/styles";
import {withRoles} from "../../../../../../router/SecuredRoute";
import Alert from '@mui/material/Alert';
import {useMediaQuery} from "react-responsive";
import Avatar from '@mui/material/Avatar';
import BorrowerProfile from "../../reUsables/borrowerProfile";
import moment from "moment/moment";


const EvDetails = ({evData, lastRentData, evNumber, ...props}) => {
    const [garages, setGarages] = useState(undefined);
    const [language, setLanguage] = useState(null);
    const [evType, setEvType] = useState('');
    const [showLastRentInfo, setShowLastRentInfo] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const {
        COLLECT_FINANCIERS
    } = props;

    useEffect(() => {
        if (lastRentData && Object.keys(lastRentData).length > 0) {
            setShowLastRentInfo(true)
        } else {
            setShowLastRentInfo(false)
        }
    }, [lastRentData]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    useEffect(() => {
        // evData by default has empty object assigned
        if (evData && Object.keys(evData).length > 0) {
            let evTypeFromResponse = evData.type_of_vehicle.split("_")
            if (evTypeFromResponse[1]) {
                setEvType(capitalizeFirstLetter(evTypeFromResponse[0]) + ' ' + capitalizeFirstLetter(evTypeFromResponse[1]))
            } else {
                setEvType(capitalizeFirstLetter(evTypeFromResponse[0]))
            }
        }
    }, [evData]);

    // Source of the letter Avatar (with following two functions): https://mui.com/material-ui/react-avatar/ - Noor Reza, 4th October, 6:48 PM
    // Though We have updated it by adding a checking for the one word name
    const stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const stringAvatar = (name) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1]? name.split(' ')[1][0]:''}`,
        }
    }

    const smartBatteryInfo = () => {
        return <>
            <div className={isTabletOrMobile? 'row':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}><strong>SMART BATTERY</strong></div>
            <div className={isTabletOrMobile? 'row mt-1':'row ml-1 mt-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                <Avatar size={"lg"}
                        variant="square"
                        {...stringAvatar(evData.battery_info? evData.battery_info.battery_vendor? evData.battery_info.battery_vendor:'N A':'N A')}
                        ref={el => {
                            if (el) {
                                el.style.setProperty('width', '100px', 'important')
                                el.style.setProperty('height', '100px', 'important')
                                el.style.setProperty('font-size', '70px', 'important')
                            }
                        }}/>
            </div>
            <div className={isTabletOrMobile? 'row mt-2':'row ml-1 mt-2'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}><h5><strong>SMART BATTERY {evData.device_serial_number? '#' + evData.device_serial_number:'-'}</strong></h5></div>
            <div className={isTabletOrMobile? 'row':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                {evType} Battery{evData.battery_info? <>
                {evData.battery_info.nameplate_capacity && evData.battery_info.nameplate_voltage? <>
                    , {evData.battery_info.nameplate_capacity}Ah {evData.battery_info.nameplate_voltage}v
                </>:<>
                    {evData.battery_info.nameplate_capacity && !evData.battery_info.nameplate_voltage? <>, {evData.battery_info.nameplate_capacity}Ah</>:
                        <>{!evData.battery_info.nameplate_capacity && evData.battery_info.nameplate_voltage? <>, {evData.battery_info.nameplate_voltage}v</>:''}</>}
                </>}</>:''}
            </div>
            <div className={isTabletOrMobile? 'row mt-2':'row ml-1 mt-2'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}><h5><strong>SOLdongle</strong></h5></div>
            <div className={isTabletOrMobile? 'row':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>{evData.dongle_info? <>
                {evData.dongle_info.serial_number && evData.dongle_info.firmware_version && evData.dongle_info.hardware_version? <>
                    {evData.dongle_info.serial_number}, FW v{evData.dongle_info.firmware_version}, HW v{evData.dongle_info.hardware_version}
                </>:<>
                    {evData.dongle_info.serial_number && evData.dongle_info.firmware_version && !evData.dongle_info.hardware_version? <>
                        {evData.dongle_info.serial_number}, FW v{evData.dongle_info.firmware_version}
                    </>:<>
                        {evData.dongle_info.serial_number && !evData.dongle_info.firmware_version && evData.dongle_info.hardware_version? <>
                            {evData.dongle_info.serial_number}, HW v{evData.dongle_info.hardware_version}
                        </>:<>
                            {!evData.dongle_info.serial_number && evData.dongle_info.firmware_version && evData.dongle_info.hardware_version? <>
                                FW v{evData.dongle_info.firmware_version}, HW v{evData.dongle_info.hardware_version}
                            </>:<>
                                {evData.dongle_info.serial_number && !evData.dongle_info.firmware_version && !evData.dongle_info.hardware_version? <>
                                    {evData.dongle_info.serial_number}
                                </>:<>
                                    {!evData.dongle_info.serial_number && evData.dongle_info.firmware_version && !evData.dongle_info.hardware_version? <>
                                        FW v{evData.dongle_info.firmware_version}
                                    </>:<>
                                        {!evData.dongle_info.serial_number && !evData.dongle_info.firmware_version && evData.dongle_info.hardware_version? <>
                                            HW v{evData.dongle_info.hardware_version}
                                        </>:'-'}
                                    </>}
                                </>}
                            </>}
                        </>}
                    </>}
                </>}
            </>:'-'}
            </div>
            {isTabletOrMobile? <hr style={{marginLeft: '0.1px'}}/>:null}
        </>
    }

    const borrowerInfo = () => {
        return <>
            <BorrowerProfile title={'BORROWER/LEASER'} forComponent={'ev'} name={'Raihan Ahmed'} profileData={evData}/>
        </>
    }

    const lastRentInfo = () => {
        return <>
            <div className={'row'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}><h6><strong>LAST RENT/ACTIVE RENT DRIVER</strong></h6></div>
            <div className={'row'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                <Avatar size={"lg"}
                        variant="square"
                        {...stringAvatar(lastRentData.driver_details? lastRentData.driver_details.driver_name? lastRentData.driver_details.driver_name:'N A':'N A')}
                        ref={el => {
                            if (el) {
                                el.style.setProperty('width', '100px', 'important')
                                el.style.setProperty('height', '100px', 'important')
                                el.style.setProperty('font-size', '70px', 'important')
                            }
                        }}/>
            </div>
            {lastRentData.driver_details? lastRentData.driver_details.driver_name? <>
                <div className={'row mt-2'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}><h5><strong>{lastRentData.driver_details.driver_name}</strong></h5></div>
            </>:'':''}
            <div className={'row'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>Rented On {moment(lastRentData.start_time).format("MMM D, YYYY hh:mma")} for {moment(lastRentData.expire_time).diff(moment(lastRentData.start_time), 'hours')}h</div>
            <div className={'row mt-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>EV Number {evNumber}</div>
            {lastRentData.rent_version? <>
                <div className={'row mt-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>Cholo Garage App v{lastRentData.rent_version}</div>
            </>:null}
            {/*<div className={'row mt-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>Cholo Garage App v2.1</div>*/}
            {isTabletOrMobile? <hr style={{marginLeft: '0.1px'}}/>:null}
        </>
    }

    const financierInfo = () => {
        return <>
            <div className={'row'} style={{marginLeft: '0.1px'}}><h6><strong>SMART BATTERY FINANCED BY</strong></h6></div>
            <div className={'row'} style={{marginLeft: '0.1px'}}>
                <Avatar size={"lg"}
                        variant="square"
                        {...stringAvatar(evData.contract_info? evData.contract_info.financier_name? evData.contract_info.financier_name:'N A':'N A')}
                        ref={el => {
                            if (el) {
                                el.style.setProperty('width', '100px', 'important')
                                el.style.setProperty('height', '100px', 'important')
                                el.style.setProperty('font-size', '70px', 'important')
                            }
                        }}/>
            </div>
            <div className={'row mt-2'} style={{marginLeft: '0.1px'}}><h5><strong>{evData.contract_info? evData.contract_info.financier_name? evData.contract_info.financier_name:'N/A':'N/A'}</strong></h5></div>
            {evData.branch_info? <>
                {evData.branch_info.name? <>
                    <div className={'row'} style={{marginLeft: '0.1px'}}>{evData.branch_info.name}</div>
                </>:''}
                {evData.branch_info.contact_person_name? <>
                    <div className={'row mt-1'} style={{marginLeft: '0.1px'}}>Shihab Uddin</div>
                </>:''}
            </>:''}
            {isTabletOrMobile? <hr style={{marginLeft: '0.1px'}}/>:null}
        </>
    }

    return (
        <>
            {evData? <>
                <div className={'row'}>
                    {/* Smart Battery */}
                    <div className={showLastRentInfo? 'col-lg-3':'col-lg-4'}>
                        {smartBatteryInfo()}
                    </div>
                    {/* Borrower/Leaser */}
                    <div className={showLastRentInfo? 'col-lg-3':'col-lg-4'}>
                        {borrowerInfo()}
                    </div>
                    {/* Last Rent/ Active Driver */}
                    {showLastRentInfo? <>
                        <div className={'col-lg-3'}>
                            {lastRentInfo()}
                        </div>
                    </>:''}
                    {/* Smart Battery Financed By */}
                    <div className={showLastRentInfo? 'col-lg-3':'col-lg-4'}>
                        {financierInfo()}
                    </div>
                </div>
                {!isTabletOrMobile? <hr/>:null}
            </>:<>
                <div className={'row'} style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <span><h5><strong>No data available</strong></h5></span>
                </div>
            </>}
        </>
    );
};

EvDetails.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        collectingBorrowers: state.commonReducer.collectingBorrowers,
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(EvDetails));
