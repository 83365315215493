import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {actions} from "../../actions";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../../../users/views/components";
import {Button, Form, Spinner, ListGroup} from "react-bootstrap";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {requestCycle} from "../../utils";
import Select from "react-select";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {showNotifications} from "../../../../utils/notification";
import {toast} from "react-toastify";
import {useSubheader} from "../../../../../_metronic/layout";

const GenerateToken = props => {
    const [deviceSerialNumber, setDeviceSerialNumber] = useState(null);
    const [generatedToken, setGeneratedToken] = useState("");
    const [tokenCounter, setTokenCounter] = useState("");
    const [tokenDuration, setTokenDuration] = useState("");
    const [requiredErrorMessage, setRequiredErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [tokenDuraionInputInvalid, setTokenDuraionInputInvalid] = useState(false);
    const [tokenDurationHighestLimitError, setTokenDurationHighestLimitError] = useState(false);
    const [deviceSerialInputInvalid, setDeviceSerialInputInvalid] = useState(false);
    const [deviceSerialLengthInvalid, setDeviceSerialLengthInvalid] = useState(false);
    const [showGeneratedToken, setShowGeneratedToken] = useState(false);

    const generalErrorMessage = 'Please provide above information!'

    const [edit, setEdit] = useState(false);
    const {register, handleSubmit, errors, setError, clearErrors,control} = useForm();
    const subHeader = useSubheader();

    const {
        GENERATE_TOKEN
    } = props;

    useEffect(() => {
        if (props.tokenGenerated === requestCycle.success) {
            setShowGeneratedToken(true)
        }
    }, [props.tokenGenerated]);

    useEffect(() => {
        if (props.successMessage !== undefined) {
            setGeneratedToken(props.successMessage["token"])
            setTokenCounter(props.successMessage["token_counter"])
            setTokenDuration(props.successMessage["token_duration"])
        } else if (props.errorMessageAfterApiCall !== undefined) {
            setErrorMessage(showNotifications('error', props.errorMessageAfterApiCall, true))
        }
    }, [props.successMessage, props.errorMessageAfterApiCall]);

    const generateToken = () => {
        if (!deviceSerialNumber || !tokenDuration) {
            setRequiredErrorMessage(true)
        } else if (tokenDuration > 1488) {
            setRequiredErrorMessage(false)
            setTokenDurationHighestLimitError(true)
        } else {
            setTokenDurationHighestLimitError(false)
            let payLoad = {
                'token_generated_by_name': props.user.name,
                'token_generated_by_username': props.user.username,
                'serial_number': deviceSerialNumber,
                'token_duration': parseInt(tokenDuration)
            }
            GENERATE_TOKEN(payLoad)
        }
    }

    const clearAllErrorMesssages = () => {
        setRequiredErrorMessage(false)
        setErrorMessage(false)
        setTokenDuraionInputInvalid(false)
        setDeviceSerialInputInvalid(false)
        setDeviceSerialLengthInvalid(false)
    }

    const showTokenGenerationForm = () => {
        setDeviceSerialNumber(null)
        setTokenDuration(null)
        clearAllErrorMesssages()
        setShowGeneratedToken(false)
    }

    useEffect(() => {
        subHeader.setActionButtons(null)
        showTokenGenerationForm()
    }, []);

    return (
        <ContentWrapper showCardHeader={false} pageTitle={'Generate Token'} showBackButton={false} isLoading={false}>
            <div className={"row mb-6"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>{showGeneratedToken? 'Generated token':'Generate token'}</b></h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
            </div>

            {!showGeneratedToken? <>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <Form>
                            <div className={'row g-3'}>
                                <div className={"col-md-6"}>
                                    <Form.Group>
                                        <Form.Label>Device<span className="required text-danger">*</span></Form.Label>
                                        <SolInput
                                            type="text"
                                            name={"device"}
                                            placeholder={"Enter device serial number. Ex: 33001116"}
                                            autoComplete={"off"}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value) {
                                                    delete errorMessage['serial_number']
                                                    setRequiredErrorMessage(false)
                                                    setDeviceSerialLengthInvalid(false)
                                                    if (isNaN(value) || value.includes('.')) {
                                                        setDeviceSerialInputInvalid(true)
                                                    } else {
                                                        setDeviceSerialInputInvalid(false)
                                                        setDeviceSerialNumber(value)
                                                    }
                                                } else {
                                                    setDeviceSerialInputInvalid(false)
                                                    setDeviceSerialNumber(null)
                                                }
                                            }}
                                        />
                                        {deviceSerialInputInvalid? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;Please provide numbers only!</div>:null}
                                        {(requiredErrorMessage && !deviceSerialNumber)? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalErrorMessage}</div>:null}
                                        {deviceSerialLengthInvalid? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;Device serial number must be of 8 digits!</div>:null}
                                        {errorMessage.serial_number? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.serial_number}</div>:null}
                                    </Form.Group>
                                </div>
                            </div>

                            <div className={'row g-3'}>
                                <div className={"col-md-6"}>
                                    <Form.Group>
                                        <Form.Label>Token duration (in Hours)<span className="required text-danger">*</span></Form.Label>
                                        <SolInput
                                            type="text"
                                            name={"tokenDuration"}
                                            placeholder={"Enter duration of the token in hours. Ex: 1"}
                                            autoComplete={"off"}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value) {
                                                    delete errorMessage['token_duration']
                                                    setRequiredErrorMessage(false)
                                                    setTokenDurationHighestLimitError(false)
                                                    if (isNaN(value) || value.includes('.')) {
                                                        setTokenDuraionInputInvalid(true)
                                                    } else {
                                                        setTokenDuraionInputInvalid(false)
                                                        setTokenDuration(value)
                                                    }
                                                } else {
                                                    setTokenDuraionInputInvalid(false)
                                                    setTokenDuration("")
                                                }
                                            }}
                                        />
                                        {tokenDuraionInputInvalid? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;Please provide numbers only!</div>:null}
                                        {(requiredErrorMessage && !tokenDuration)? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalErrorMessage}</div>:null}
                                        {tokenDurationHighestLimitError? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;Token duration can not be higher than 1488 hours!</div>:null}
                                        {errorMessage.token_duration? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.token_duration}</div>:null}
                                    </Form.Group>
                                </div>
                            </div>

                            <div className={'row'}>
                                <div className={"col-md-6"}>
                                    <Button variant="dark" size={'sm'} onClick={()=>props.history.goBack()} style={{
                                        backgroundColor: '#8C8C9B',
                                        outline: '#8C8C9B',
                                        border: '#8C8C9B',
                                    }}>
                                        <i className='fa fa-times' aria-hidden='true'/>&nbsp;Cancel
                                    </Button>
                                    <Button variant="warning" type="button" size={'sm'} className={"ml-3"}
                                            disabled={(deviceSerialInputInvalid || tokenDuraionInputInvalid)? true:false}
                                            onClick={()=>generateToken()}>
                                        {props.tokenGenerationInProgress===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}
                                        <i className='fa fa-paper-plane' aria-hidden='true'/>&nbsp;Submit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </>:null}

            {showGeneratedToken? <>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <ListGroup>
                            <ListGroup.Item>Serial number:&nbsp;<SolTypography.Text primary>{deviceSerialNumber}</SolTypography.Text></ListGroup.Item>
                            <ListGroup.Item>Token:&nbsp;<SolTypography.Text primary>{generatedToken}</SolTypography.Text></ListGroup.Item>
                            <ListGroup.Item>Token counter:&nbsp;<SolTypography.Text primary>{tokenCounter}</SolTypography.Text></ListGroup.Item>
                            <ListGroup.Item>Token hours:&nbsp;<SolTypography.Text primary>{tokenDuration}</SolTypography.Text></ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
                <div className={"row mt-2"}>
                    <div className={"col-md-6"}>
                        <Button variant="dark" size={'sm'} className={'mt-1'} onClick={()=>props.history.goBack()} style={{
                            backgroundColor: '#8C8C9B',
                            outline: '#8C8C9B',
                            border: '#8C8C9B',
                        }}>
                            <i className='fa fa-times' aria-hidden='true'/>&nbsp;Close
                        </Button>
                        <Button variant="warning" type="button" size={'sm'} className={"ml-3 mt-1"}
                                onClick={()=>{showTokenGenerationForm()}}>
                            <i className='fa fa-plus' aria-hidden='true'/>&nbsp;Generate another token
                        </Button>
                    </div>
                </div>
            </>:null}
            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
        </ContentWrapper>
    );
};

GenerateToken.propTypes = {};

const mapStateToProps = (state) => {
    return {
        tokenGenerationInProgress: state.tokensReducer.tokenGenerationInProgress,
        tokenGenerated: state.tokensReducer.tokenGenerated,
        successMessage: state.tokensReducer.successMessage,
        errorMessageAfterApiCall: state.tokensReducer.errorMessage,
        user: state.auth.user
    }
}

export default connect(mapStateToProps, actions)(GenerateToken);
