import {Redirect, Switch} from "react-router-dom";
import React from "react";
import Contracts from "./views/contracts/contracts";
import ContractCreation from "./views/contracts/contractCreation";
import ContractDetails from "./views/contractDetails/contractDetails";
import SecuredRoute from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const ContractManagementRouter =(props)=> {

    return (
        <Switch>
            <Redirect
                exact={"true"}
                from={"/contract-management"}
                to={"/contract-management/list"}
            />

            {/* Routes to contractManagement List*/}
            <SecuredRoute
                path={"/contract-management/list"}
                component={Contracts}
                service={getServiceName('garageService')}
                feature={getFeatureName('contractManagementFeature')}
                action={getActionName('listAction')}
            />


            {/* Routes to new/updated contractManagement creation for now */}
            <SecuredRoute
                path={"/contract-management/create"}
                component={ContractCreation}
                service={getServiceName('garageService')}
                feature={getFeatureName('contractManagementFeature')}
                action={getActionName('createAction')}
            />

            {/* Routes to contractManagement details*/}
            <SecuredRoute
                path={"/contract-management/details/:contract_id"}
                component={ContractDetails}
                service={getServiceName('garageService')}
                feature={getFeatureName('contractManagementFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
