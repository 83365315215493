import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {
    Toolbar,
    FilterButton,
    FilterInput,
    Filter,
    FilterReactSelect,
    DataTable,
    DataTableContainer,
    SolInput
} from "../../../../components";
import { connect } from "react-redux";
import { actions } from "../../actions";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/styles";
import { requestCycle } from '../../utils'
import { LoadingSpinner } from '../../../../components/LoadingSpinnerForDataTable'
import { useSubheader } from "../../../../../_metronic/layout";
import { withRoles } from "../../../../router/SecuredRoute";
import Select from "react-select";
import { checkAuthorization } from "../../../auth/authorization";
import { getServiceName } from "../../../../utils/roleRelatedValues/serviceNames";
import { getFeatureName } from "../../../../utils/roleRelatedValues/featureNames";
import { getActionName } from "../../../../utils/roleRelatedValues/actionNames";
import { GenericModal } from "../../../../components/genericModal/genericModal";
import { SolTypography } from "../../../../components/utilityComponents/SOlTypography";
import { Controller, useForm } from "react-hook-form";
import { showNotifications } from "../../../../utils/notification";
import { toast } from "react-toastify";
import moment from "moment";
import { ExportToCsv } from "export-to-csv";
import { actions as commonActions } from '../../../commonReduxActions/actions';
import RentPerDayModalContent from './RentPerDayModalContent';
import RentDetailsModalContent from './RentDetailsModalContent';
import DateTimeFormat from '../../../../utils/dateFormat/DateTimeFormat';
import GarageDetailsModal from './GarageDetailsModal';
import {exportInExcel} from "../../../../utils/excelExport";
import Stack from "@mui/material/Stack";
import EventsHistory from "../../../../components/eventHistoryModal/eventHistoryModal";
import Calendar from "react-calendar";
import './Calendar_custom.css';
import '../../../borrowers/views/customSwitch.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {isNullorUndefined, parseParams} from "../../../../utils/utilityFunctions";
import {SolTextArea} from "../../../../components/SolStyledComponents/components";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const DeviceList = (props) => {
    const {control, register, setError, clearErrors, errors, setValue, handleSubmit, reset} = useForm();
    const [showFilter, setShowFilter] = useState(true);
    const [authorization, setAuthorization] = useState(null);
    const [borrowers, setBorrowers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [garageList, setGarageList] = useState([]);
    const [orgsList, setOrgsList] = useState([]);
    const [garageListForAssignment, setGarageListForAssignment] = useState([]);
    const [showRentDetailsModal, setShowRentDetailsModal] = useState(false);
    const [showGarageDetailsModal, setShowGarageDetailsModal] = useState(false);
    const [showDeviceStatusChangeModal, setShowDeviceStatusChangeModal] = useState(false);
    // const [deviceSerialNumber, setDeviceSerialNumber] = useState(null);
    const [currentRowData, setCurrentRowData] = useState(null);
    const [keyForTable, setKeyForTable] = useState(1);

    const [showUploadModal, setShowUploadModal] = useState(false);
    const [csvUploadInProgress, setCsvUploadInProgress] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [fileIsInvalid, setFileIsInvalid] = useState(false);
    const [uploadedContent, setUploadedContent] = useState([]);
    const [showFileMandatoryMessage, setShowFileMandatoryMessage] = useState(false);

    const [deviceList, setDeviceList] = useState([]);
    const [allDevicesList, setAllDevicesList] = useState(undefined);
    const [language, setLanguage] = useState(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(undefined);
    const [perPage, setPerPage] = useState(undefined);
    const [garage, setGarage] = useState(null);
    const [branch, setBranch] = useState(null);
    const [filter, setFilter] = useState(null);
    const subHeader = useSubheader();

    // URL parameter related
    const [queryParams, setQueryParams] = useState(null);
    const [initialfilterParam, setInitialfilterParam] = useState(null);
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null);
    const [urlIsEdited, setUrlIsEdited] = useState(false);
    const [garageFromUrl, setGarageFromUrl] = useState(null);
    const [branchFromUrl, setBranchFromUrl] = useState(null);
    const [filterApplied, setFilterApplied] = useState(false);

    // Selection related
    const [enableSmartBatterySelection, setEnableSmartBatterySelection] = useState(false);
    const [selectedDevices, setSelectedDevices] = useState([]);

    // Event History related
    const [showEventsHistoryModal, setShowEventsHistoryModal] = useState(false);
    const [numberOfSmartBatteries, setNumberOfSmartBatteries] = useState(0);

    // Garage Assignment related
    const [enableGarageAssignment, setEnableGarageAssignment] = useState(false);
    const [smartBatteryForGarageAssignment, setSmartBatteryForGarageAssignment] = useState('');
    const [showAssignGarageModal, setShowAssignGarageModal] = useState(false);
    const [selectedGarageForAssignment, setSelectedGarageForAssignment] = useState(null);
    const [showGarageNonSelectionMessage, setShowGarageNonSelectionMessage] = useState(false)
    const [batchgarageAssignment, setBatchGarageAssingment] = useState(false);

    // Active days calendar
    const [showActiveDaysDetailsModal, setShowActiveDaysDetailsModal] = useState(false);
    const [smartBatteryForActivitySummary, setSmartBatteryForActivitySummary] = useState('');
    const [dongleForActivitySummary, setDongleForActivitySummary] = useState('');
    const [batteryForActivitySummary, setBatteryForActivitySummary] = useState('');
    const [monthOfActivitySummary, setMonthOfActivitySummary] = useState('');
    const [viewRelatedValueOfTheCalendar, setViewRelatedValueOfTheCalendar] = useState(new Date());
    const [activeDays, setActiveDays] = useState(new Set());
    const [inactiveDays, setInactiveDays] = useState(new Set());
    const [currentMonthIsSelected, setCurrentMonthIsSelected] = useState(true); // By default, current month is shown
    const [value, setCalenderValue] = useState();
    const calendarRef = useRef(null);
    // Saving 281 as initial value, as we have seen the height of the calendar div as 281 at the laptop screen
    const [heightOfTheCalendar, setHeightOfTheCalendar] = useState(281)

    // Per rent payable related
    const [showRentPerDayModal, setShowRentPerDayModal] = useState(false);
    const [rentUpdateIsSuccessful, setRentUpdateIsSuccessful] = useState(false);

    const generalRequiredMessage = language === 'EN' ? 'Please provide above information!' : 'দয়া করে উপরের তথ্যটি দিন!'

    const operatingSinceTextGenerator = (date) => {
        let text = ''
        let operatingFromDate = moment(date)
        let currentDate = moment()
        let differenceInYears = parseInt(currentDate.diff(operatingFromDate, 'years', true))
        if (differenceInYears > 0) {
            operatingFromDate = operatingFromDate.add(differenceInYears, 'years')
            text += differenceInYears > 1 ? differenceInYears + ' years' : differenceInYears + ' year'
        }
        let differenceInMonths = parseInt(currentDate.diff(operatingFromDate, 'months', true))
        if (differenceInMonths > 0) {
            operatingFromDate = operatingFromDate.add(differenceInMonths, 'months')
            text += differenceInMonths > 1 ? ' ' + differenceInMonths + ' months' : ' ' + differenceInMonths + ' month'
        }
        let differenceInDays = parseInt(currentDate.diff(operatingFromDate, 'days', true))
        if (differenceInDays > 0) {
            text += differenceInDays > 1 ? ' ' + differenceInDays + ' days' : ' ' + differenceInDays + ' day'
        }
        return text
    }

    const excelExport = (data) => {
        let presentTime = moment()
        let dataLength = data.length
        let totalSmartBatteryText = "Total Smart Batteries: " + dataLength
        let exportText = "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A")
        let excelRows = [
            ["Smart Batteries", "", "", "", "", "", "", "", "", "", ""],
            [totalSmartBatteryText, "", "", "", "", "", "", "", "", "", ""],
            [exportText, "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", ""],
            ["Smart Battery ID", "Dongle", "Battery MFG Serial No", "Battery Details", "Cost (Tk)", "Loan ID", "Garage",
                "Operating From", "Repayment (Tk)", "# of Rents", "Can be Leased"]
        ]
        if (filter && Object.keys(filter).length > 0) {
            if (filter.contract_id) {
                let text = "Loan ID: " + filter.contract_id
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", ""])
            }
            if (filter.manufacturer_identification_number) {
                let text = "Battery MFG Serial No: " + filter.manufacturer_identification_number
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", ""])
            }
            if (branch) {
                let text = "Branch: " + branch.label
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", ""])
            }
            if (garage) {
                let text = "Borrower: " + garage.label
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", ""])
            }
            if (filter.dongle_serial_number) {
                let text = "SOLdongle ID: " + filter.dongle_serial_number
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", ""])
            }
            if (filter.serial_number) {
                let text = "Smart Battery ID: " + filter.serial_number
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", ""])
            }
        }
        for (let i = 0; i < dataLength; i++) {
            let dongleDetails = data[i].dongle_serial_number.trim()
            if (data[i].dongle_details) {
                dongleDetails += ', FW ' + data[i].dongle_details.firmware_version.trim() + ', HW ' + data[i].dongle_details.hardware_version.trim()
            }
            let batteryDetails = ''
            if (data[i].battery_details) {
                if (data[i].battery_details.battery_vendor) {
                    batteryDetails = data[i].battery_details.battery_vendor.trim() + ', ' +
                        data[i].battery_details.nameplate_voltage + 'V, ' + data[i].battery_details.nameplate_capacity +
                        'Ah, Warranty Till ' + moment(data[i].battery_details.warranty_end_date, "YYYY-MM-DD").format('MMM DD, YYYY')
                } else {
                    batteryDetails = data[i].battery_details.nameplate_voltage + 'V, ' + data[i].battery_details.nameplate_capacity +
                        'Ah, Warranty Till ' + moment(data[i].battery_details.warranty_end_date, "YYYY-MM-DD").format('MMM DD, YYYY')
                }
            }
            let repaymentText = ''
            if (data[i].contract_details) {
                if (data[i].contract_details.contract_type === 'rent') {
                    repaymentText = 'Tk ' + new Intl.NumberFormat('en-IN').format(data[i].contract_details.per_rent_payable.toFixed(2)) + ' Per Rent'
                } else {
                    if (data[i].contract_details.lease_payment_interval === 'weekly') {
                        repaymentText = 'Tk ' + new Intl.NumberFormat('en-IN').format(data[i].contract_details.per_installment_payable.toFixed(2)) + ' Per Week'
                    } else {
                        repaymentText = 'Tk ' + new Intl.NumberFormat('en-IN').format(data[i].contract_details.per_installment_payable.toFixed(2)) + ' Per Month'
                    }
                }
            }
            let garageName = data[i].garage_name ? data[i].garage_name : ''
            if (garageList && garageList.length > 0) {
                // Checking for zero length as garagesList will always be at-least an empty array
                let filteredData = garageList.filter(garage => garage.pk === data[i].garage_guid)
                if (filteredData.length > 0) {
                    garageName = filteredData[0].name
                }
            }
            excelRows.push(
                [
                    data[i].serial_number.trim(),
                    dongleDetails,
                    data[i].battery_details?.manufacturer_identification_number?.trim(),
                    batteryDetails,
                    data[i].battery_details.battery_cost_including_dongle ? new Intl.NumberFormat('en-IN').format(data[i].battery_details.battery_cost_including_dongle) : '',
                    data[i].contract_id,
                    garageName.trim(),
                    data[i].operating_from ? moment(data[i].operating_from).format("MMMM DD, YYYY") + ' (' + operatingSinceTextGenerator(data[i].operating_from) + ')' : '',
                    repaymentText,
                    data[i].total_number_of_rents,
                    data[i].can_be_rented? 'Yes':'No'
                ])
        }
        let noDataAvailableText = "No data available"
        dataLength > 0 ? excelRows.push(["", "", "", "", "", "", "", "", "", "", ""]) :
            excelRows.push(["", "", "", "", "", noDataAvailableText, "", "", "", "", ""])
        let fileName = 'smart_batteries_'
        exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
    }

    const deviceListColumns = [
        {
            field: 'serial_number',
            title: language === 'EN' ? 'Smart Battery' : 'ডিভাইস আইডি',
            emptyValue: () => {
                return "-"
            },
            cellStyle: {
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            width: "20%",
            render: (rowData) => {
                return <>
                    <h6><strong>{rowData.serial_number}</strong></h6>
                    <small style={{whiteSpace: 'nowrap'}}><strong>SOLdongle</strong></small><br/>
                    <small style={{whiteSpace: 'nowrap'}}><strong>#{rowData.dongle_serial_number},
                        FW {rowData?.dongle_details?.firmware_version ? `v${rowData?.dongle_details?.firmware_version}` : "--"},
                        HW {rowData?.dongle_details?.hardware_version ? `v${rowData?.dongle_details?.hardware_version}` : "--"}</strong></small><br/>
                    {rowData.battery_details ? <>
                        <small style={{whiteSpace: 'nowrap'}}><strong>Battery</strong></small><br/>
                        {rowData.battery_details.manufacturer_identification_number ? <><small
                            style={{whiteSpace: 'nowrap'}}><strong>{rowData.battery_details.manufacturer_identification_number}</strong></small><br/></> : ''}
                        <small
                            style={{whiteSpace: 'nowrap'}}><strong>{rowData?.battery_details?.battery_vendor} {rowData?.battery_details?.nameplate_voltage ? `${rowData?.battery_details?.nameplate_voltage}V,` : ""} {rowData?.battery_details?.nameplate_capacity ? `${rowData?.battery_details?.nameplate_capacity}Ah` : ""}</strong></small><br/>
                        <small style={{whiteSpace: 'nowrap'}}><strong>Warranty
                            Till {rowData?.battery_details?.warranty_end_date ? moment(rowData.battery_details?.warranty_end_date, "YYYY-MM-DD").format('MMM DD, YYYY') : "--"}</strong></small>
                    </> : ''}
                </>
            }
            // disableClick: true,
        },
        {
            field: 'garage_name',
            title: language === 'EN' ? 'Loan Contract' : 'ঋণ চুক্তি',
            emptyValue: (rowData) => {
                return <SolTypography.SubTitle>-</SolTypography.SubTitle>
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            render: (rowData) => {
                let operatingSinceText = operatingSinceTextGenerator(rowData.operating_from)
                return <>
                    {rowData.contract_id ? <><SolTypography.Text><h6><strong>Loan ID #{rowData.contract_id}</strong>
                    </h6></SolTypography.Text></> : null}
                    {rowData.garage_name ? <>
                        <SolTypography.SubTitle primary onClick={(e) => {
                            openGarageDetailModal(rowData)
                        }}
                                                style={{cursor: 'pointer'}}>{rowData.garageName}</SolTypography.SubTitle>
                        {rowData.operating_from? <>
                            <SolTypography.Text><small><strong>Operating Since</strong></small></SolTypography.Text>
                            <SolTypography.SubTitle>{moment(rowData.operating_from).format("MMMM DD, YYYY")}</SolTypography.SubTitle>
                            <SolTypography.SubTitle>{operatingSinceText}</SolTypography.SubTitle>
                        </>:null}
                    </> : null}
                </>
            }
            // disableClick: true,
        },
        {
            field: 'battery_details.battery_cost_including_dongle',
            title: language === 'EN' ? 'Cost (Tk)' : 'খরচ (টাকায়)',
            emptyValue: () => {
                return "-"
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            disableClick: true,
            render: (rowData) => {
                return <>
                    <SolTypography.Text>{new Intl.NumberFormat('en-IN').format(rowData.battery_details.battery_cost_including_dongle)}</SolTypography.Text>
                </>
            },
        },
        {
            field: 'contract_details',
            title: language === 'EN' ? 'Repayment (Tk)' : 'ঋণ পরিশোধের পরিমাণ (টাকায়)',
            emptyValue: (rowData) => {
                if (rowData.rent_per_day_amount) {
                    return <>
                        {checkAuthorization(props.authorization, deviceServiceName, deviceFeatureName, [updateActionName]) ? <>
                            <SolTypography.SubTitle primary onClick={(e) => {
                                openRentPerDayModal(rowData)
                            }} style={{cursor: 'pointer'}}>{rowData.rent_per_day_amount}</SolTypography.SubTitle>
                        </> : <>{rowData.rent_per_day_amount}</>}
                    </>
                } else {
                    return <>
                        {checkAuthorization(props.authorization, deviceServiceName, deviceFeatureName, [updateActionName]) ? <>
                            <SolTypography.SubTitle primary onClick={(e) => {
                                openRentPerDayModal(rowData)
                            }} style={{cursor: 'pointer'}}>{"Set rent amount"}</SolTypography.SubTitle>
                        </> : <>{"Rent amount is not set"}</>}
                    </>
                }
            },
            disableClick: true,
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            render: (rowData) => {
                return <>
                    {rowData.contract_details.contract_type === 'rent' ? <>
                        <span
                            style={{whiteSpace: 'nowrap'}}>Tk {new Intl.NumberFormat('en-IN').format(rowData.contract_details.per_rent_payable.toFixed(2))} Per Rent</span>
                    </> : <>
                        {rowData.contract_details.contract_type === 'lease' ? <>
                            {rowData.contract_details.lease_payment_interval === 'weekly' ? <>
                                <span
                                    style={{whiteSpace: 'nowrap'}}>Tk {new Intl.NumberFormat('en-IN').format(rowData.contract_details.per_installment_payable.toFixed(2))} Per Week</span>
                            </> : <>
                                <span
                                    style={{whiteSpace: 'nowrap'}}>Tk {new Intl.NumberFormat('en-IN').format(rowData.contract_details.per_installment_payable.toFixed(2))} Per Month</span>
                            </>}
                        </> : <>-</>}
                    </>}
                </>
            }
        },
        {
            field: 'total_number_of_rents',
            title: language === 'EN' ? '# of Rents' : 'ভাড়ার সংখ্যা',
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            emptyValue: () => {
                return "-"
            },
            disableClick: true,
            render: (rowData) => {
                return <>
                    <SolTypography.SubTitle primary onClick={(e) => { activeDaysDetails(rowData) }}
                                            style={{ cursor: 'pointer' }}>{rowData.total_number_of_rents}</SolTypography.SubTitle>
                </>
            }
        },
        {
            field: 'can_be_rented',
            title: language === 'EN' ? 'Can Be Leased?' : 'ভাড়া দেওয়া যাবে',
            emptyValue: () => {
                return "-"
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            disableClick: true,
            render: (rowData) => {
                return <>
                    <Form.Check
                        size="lg"
                        style={{cursor: 'pointer', whiteSpace: 'nowrap'}}
                        type="switch"
                        id={rowData.serial_number}
                        defaultChecked={rowData.can_be_rented}
                        label=""
                        onChange={(e) => {
                            setCurrentRowData(rowData)
                            setShowDeviceStatusChangeModal(true)
                            GET_ORGANISATIONS()
                        }}
                    />
                </>
            }
        }
    ]

    const {
        GET_DEVICE_LIST,
        GET_DEVICE_LIST_FOR_EXPORT,
        GET_DEVICE_LIST_FROM_GARAGE,
        GET_GARAGE_LIST,
        GET_ORGANISATIONS,
        GET_BRANCHES_LIST,
        COLLECT_BORROWERS,
        ASSIGN_GARAGE,
        ASSIGN_BATCH_GARAGE,
        ADD_MULTIPLE_DEVICES,
        CHANGE_RENTABLE_STATUS,
        COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY,
        COLLECT_GARAGE_LIST_FOR_ASSIGNMENT,
        RETURN_TO_INITIAL_STATE
    } = props;
    const classes = useStylesIconButton();

    const deviceServiceName = getServiceName('deviceService')
    const deviceFeatureName = getFeatureName('deviceFeature')
    const eventHistoryFeature = getFeatureName('eventHistoryFeature')
    const createActionName = getActionName('createAction')
    const updateActionName = getActionName('updateAction')
    const listActionName = getActionName('listAction')
    let fileReader;

    useEffect(() => {
        setLanguage(props.language)
        setAuthorization(props.authorization)

        if (!props.history.location.search) {
            setPage(1)
            props.history.push({
                search: 'page=1'
            })
        }
        COLLECT_BORROWERS()
        GET_BRANCHES_LIST()

        if (checkAuthorization(props.authorization, deviceServiceName, deviceFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={showUploadCsvModal} classes={classes} className={'ml-1'}>
                        <i className="fa fa-edit"/>{props.language === 'EN' ? 'Create Multiple Smart Batteries' : 'একাধিক যন্ত্র নিবন্ধন'}
                    </Button>
                    <Button variant="warning" size={'sm'} type="submit" style={{
                        backgroundColor: '#F18D00 !important'
                    }}
                            onClick={goToAddDevice} classes={classes} className={'ml-1'}>
                        <i className="fa fa-plus"/>{props.language === 'EN' ? 'Add Smart Battery' : 'যন্ত্র নিবন্ধন'}
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        subHeader.setBreadCrumbComponent(null)

        if (checkAuthorization(props.authorization, deviceServiceName, eventHistoryFeature, [createActionName]) ||
            checkAuthorization(props.authorization, deviceServiceName, eventHistoryFeature, [listActionName]) ||
            checkAuthorization(props.authorization, deviceServiceName, deviceFeatureName, [updateActionName])) {
            setEnableSmartBatterySelection(true)
        }

        return () => {
            subHeader.setActionButtons(null)
            subHeader.setBreadCrumbComponent(null)
            RETURN_TO_INITIAL_STATE();
        }
    }, []);

    // URL param related
    const validationKeyArray = ['page', 'serial_number', 'dongle_serial_number', 'garage_guid',
        'manufacturer_identification_number', 'contract_id', 'branch_guid']

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            // Page will always be there
            let filter = {}
            if (filterParamForApiCall.serial_number) {
                filter['serial_number'] = filterParamForApiCall.serial_number
            }
            if (filterParamForApiCall.dongle_serial_number) {
                filter['dongle_serial_number'] = filterParamForApiCall.dongle_serial_number
            }
            if (filterParamForApiCall.garage_guid) {
                filter['garage_guid'] = filterParamForApiCall.garage_guid
            }
            if (filterParamForApiCall.manufacturer_identification_number) {
                filter['manufacturer_identification_number'] = filterParamForApiCall.manufacturer_identification_number
            }
            if (filterParamForApiCall.contract_id) {
                filter['contract_id'] = filterParamForApiCall.contract_id
            }
            if (filterParamForApiCall.branch_guid) {
                filter['branch_guid'] = filterParamForApiCall.branch_guid
            }
            setFilter({...filter})
            filter["page"] = page
            GET_DEVICE_LIST({...filter, status: 'active'})
            GET_GARAGE_LIST()
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > -1) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    }, [props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (!isNullorUndefined(initialfilterParam)) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            if (initialfilterParam.serial_number) {
                // setValue('serial_number', initialfilterParam.serial_number, {shouldValidate: true})
                setValue('serial_number', initialfilterParam.serial_number)
            }
            if (initialfilterParam.dongle_serial_number) {
                setValue('dongle_serial_number', initialfilterParam.dongle_serial_number)
            }
            if (initialfilterParam.garage_guid) {
                setGarageFromUrl(initialfilterParam.garage_guid)
            } else {
                setGarageFromUrl('')
            }
            if (initialfilterParam.branch_guid) {
                setBranchFromUrl(initialfilterParam.branch_guid)
            } else {
                setBranchFromUrl('')
            }
            if (initialfilterParam.manufacturer_identification_number) {
                setValue('manufacturer_identification_number', initialfilterParam.manufacturer_identification_number)
            }
            if (initialfilterParam.contract_id) {
                setValue('contract_id', initialfilterParam.contract_id)
            }
            setInitialfilterParam(null)
            setUrlIsEdited(true)

            if (initialfilterParam.serial_number || initialfilterParam.dongle_serial_number || initialfilterParam.garage_guid || initialfilterParam.manufacturer_identification_number || initialfilterParam.contract_id) {
                setFilterApplied(true)
            } else {
                setFilterApplied(false)
            }
        }
    }, [initialfilterParam])

    const resetFilter = () => {
        // Hiding selected buttons
        setSelectedDevices([])
        setNumberOfSmartBatteries(0)
        // Clearing filter values and errors
        clearErrors()
        reset({
            serial_number: "",
            dongle_serial_number: "",
            garage_guid: "",
            manufacturer_identification_number: "",
            contract_id: "",
            branch_guid: "",
        })
        setGarage('')
        setGarageFromUrl('')
        setBranch('')
        setBranchFromUrl('')


        // Collecting fresh data, if previously data has been filtered
        if (filterApplied) {
            // Resetting the smart list
            setPage(1)
            props.history.push({
                search: 'page=1'
            })
        }
    }

    const filterTheList = (payload) => {
        // Hiding selected buttons
        setSelectedDevices([])
        setNumberOfSmartBatteries(0)
        // Collecting data for the first page
        setPage(1)
        let searchText = 'page=1'
        if (payload.serial_number) {
            searchText += '&serial_number=' + payload.serial_number
        }
        if (payload.dongle_serial_number) {
            searchText += '&dongle_serial_number=' + payload.dongle_serial_number
        }
        if (payload.garage_guid) {
            searchText += '&garage_guid=' + payload.garage_guid.value
        }
        if (payload.manufacturer_identification_number) {
            searchText += '&manufacturer_identification_number=' + payload.manufacturer_identification_number
        }
        if (payload.contract_id) {
            searchText += '&contract_id=' + payload.contract_id
        }
        if (payload.branch_guid) {
            searchText += '&branch_guid=' + payload.branch_guid.value
        }
        props.history.push({
            search: searchText
        })

        setGarage(payload.garage_guid ? payload.garage_guid : '')
        setBranch(payload.branch_guid ? payload.branch_guid : '')
    }

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        let searchText = 'page=' + newPage
        if (filterApplied) {
            if (filterParamForApiCall.serial_number) {
                searchText += '&serial_number=' + filterParamForApiCall.serial_number
            }
            if (filterParamForApiCall.dongle_serial_number) {
                searchText += '&dongle_serial_number=' + filterParamForApiCall.dongle_serial_number
            }
            if (filterParamForApiCall.garage_guid) {
                searchText += '&garage_guid=' + filterParamForApiCall.garage_guid
            }
            if (filterParamForApiCall.branch_guid) {
                searchText += '&branch_guid=' + filterParamForApiCall.branch_guid
            }
            if (filterParamForApiCall.manufacturer_identification_number) {
                searchText += '&manufacturer_identification_number=' + filterParamForApiCall.manufacturer_identification_number
            }
            if (filterParamForApiCall.contract_id) {
                searchText += '&contract_id=' + filterParamForApiCall.contract_id
            }
        }
        props.history.push({
            search: searchText
        })
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        if (props.deviceList) {
            const deviceList = props.deviceList.results;

            if (Array.isArray(deviceList) && deviceList.length > -1) {
                setDeviceList(deviceList.map((device, index) => {
                    let garageName = device.garage_name ? device.garage_name : ''
                    if (garageList && garageList.length > 0) {
                        // Checking for zero length as garagesList will always be at-least an empty array
                        let filteredData = garageList.filter(garage => garage.pk === device.garage_guid)
                        if (filteredData.length > 0) {
                            garageName = filteredData[0].name
                        }
                    }
                    return {
                        ...device,
                        serial: device.serial_number,
                        status: device.assignment_status,
                        garageName: garageName
                    }
                }))

                setCount(props.deviceList?.count);
                setPerPage(props.deviceList?.page_size);
            }

            setRentUpdateIsSuccessful(false)
        }
    }, [props.deviceList, garageList])

    useEffect(() => {
        if (props.smartBatteriesForExport) {
            const smartBatteries = props.smartBatteriesForExport;

            if (Array.isArray(smartBatteries)) {
                setAllDevicesList(smartBatteries.map((smartBattery, index) => {
                    return {
                        ...smartBattery,
                        serial: smartBattery.serial_number,
                        status: smartBattery.assignment_status
                    }
                }))
            }
        }
    }, [props.smartBatteriesForExport]);

    useEffect(() => {
        if (Array.isArray(props.garages)) {
            setGarageList(
                props.garages.map((item) => {
                    return {
                        ...item,
                        value: item.pk,
                        label: item.name
                    }
                })
            )
        }
    }, [props.garages])


    useEffect(() => {
        if (Array.isArray(props.organisations)) {
            setOrgsList(
                props.organisations.map((item) => {
                    return {
                        ...item,
                        value: item.pk,
                        label: item.name
                    }
                })
            )
        }
    }, [props.organisations])

    useEffect(() => {
        if (props.borrowers && props.borrowers.length > -1) {
            const borrowers = props.borrowers

            setBorrowers(borrowers.map((item) => {
                let garageName = item.garage_name
                if (garageList && garageList.length > -1) {
                    let filteredData = garageList.filter(garage => garage.pk === item.garage_guids[0])
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                    }
                }
                let label = garageName + ' (' + item.name + ')'

                // Making Borrower provided at the url selected
                if (garageFromUrl && garageFromUrl === item.garage_guids[0]) {
                    let garageForSelection = item
                    garageForSelection['label'] = label
                    garageForSelection['value'] = item.garage_guids[0]
                    setValue('garage_guid', garageForSelection)
                    setGarage(garageForSelection)
                }
                return {
                    ...item,
                    value: item.garage_guids[0],
                    label: label
                }
            }))
        }
    }, [props.borrowers, garageList])


    useEffect(() => {
        if (props.branches && Array.isArray(props.branches) ) {
            setBranches(props.branches.map((item)=>{
                // Making branch provided at the url selected
                if (branchFromUrl && branchFromUrl === item.pk) {
                    let branchForSelection = item
                    branchForSelection['label'] = item.name
                    branchForSelection['value'] = item.pk
                    setValue('branch_guid', branchForSelection)
                    setBranch(branchForSelection)
                }

                return {
                    ...item,
                    value: item.pk,
                    label: item.name
                }
            }))


        }
    }, [props.branches])

    useEffect(() => {
        if(Array.isArray(allDevicesList) && allDevicesList.length > -1){
            excelExport(allDevicesList)
        }
    }, [allDevicesList])

    useEffect(() => {
        if (props.rentableChanged === requestCycle.success) {
            toast.success("Rentable status of the Smart Battery " + currentRowData.serial_number + " is updated successfully!")
            setCurrentRowData(null)
            // Hiding selected buttons
            setSelectedDevices([])
            setNumberOfSmartBatteries(0)
            props.GET_DEVICE_LIST({page: page, status: 'active', ...filter})
            setShowDeviceStatusChangeModal(false)
        } else if (props.rentableChanged === requestCycle.failed) {
            toast.error("Failed to update the rentable status of the Smart Battery " + currentRowData.serial_number + "!")
        }
    }, [props.rentableChanged])

    const deviceListWithoutPagination = () => {
        let payload = {...filter, status: 'active'}
        // delete payload['page']
        props.GET_DEVICE_LIST_FOR_EXPORT(payload);

    }

    const goToAddDevice = () => {
        props.history.push('/devices/create')
    }

    // Garage Details Modal Feature begins
    const openGarageDetailModal = (rowData) => {
        setCurrentRowData(rowData);
        setShowGarageDetailsModal(true);
    }

    const closeGarageDetailModal = () => {
        setCurrentRowData(null);
        setShowGarageDetailsModal(false);
    }

    const garageDetailsModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                footer={false}
                footerButtonSize={'sm'}
                size={'lg'}
                footerCloseButtonText={language === 'EN' ? 'Cancel' : 'বাতিল করুন'}
                footerActionButtonText={language === 'EN' ? 'Confirm' : 'নিশ্চিত করুন'}
                hideModal={closeGarageDetailModal}
                centered={true}
                modal={showGarageDetailsModal}
                title={<><SolTypography.Title>{currentRowData?.garageName}</SolTypography.Title></>}
            >
                <GarageDetailsModal data={currentRowData}/>
            </GenericModal>
        </>
    }
    // Garage Details Modal Feature ends

    useEffect(() => {
        if (props.successMessageForMultipleDeviceCreation !== undefined) {
            if (language === 'EN') {
                showNotifications('success', props.successMessageForMultipleDeviceCreation)
            } else if (language === 'BN') {
                toast.success("সফলভাবে যন্ত্র নিবন্ধন সম্পন্ন হয়েছে!")
            }
        } else if (props.errorMessageForMultipleDeviceCreation !== undefined) {
            showNotifications('error', props.errorMessageForMultipleDeviceCreation, true)
            RETURN_TO_INITIAL_STATE()
        }
    }, [props.successMessageForMultipleDeviceCreation, props.errorMessageForMultipleDeviceCreation]);

    useEffect(() => {
        if (props.multipleDevicesCreated === requestCycle.success) {
            setShowUploadModal(false)
            // Hiding selected buttons
            setSelectedDevices([])
            setNumberOfSmartBatteries(0)
            props.GET_DEVICE_LIST({page: page, status: 'active', ...filter})
        }
    }, [props.multipleDevicesCreated])

    useEffect(() => {
        setCsvUploadInProgress(props.multipleDevicesInfoSubmitting)
    }, [props.multipleDevicesInfoSubmitting])

    const showUploadCsvModal = () => {
        setShowUploadModal(true)
    }

    const closeUploadCsvModal = () => {
        setShowUploadModal(false)
    }

    const fileUploadEvent = (e) => {
        let file = e.target.files[0]
        setShowFileMandatoryMessage(false)
        if (file) {
            if (file.type === "text/csv") {
                setFileIsInvalid(false)
                fileReader = new FileReader()
                fileReader.onloadend = processUploadedContent
                fileReader.readAsText(file)
                setFileUploaded(true)
            } else {
                setFileIsInvalid(true)
            }
        } else {
            setFileIsInvalid(false)
            setFileUploaded(false)
        }
    }

    const processUploadedContent = () => {
        let fileContent = fileReader.result
        let dataArray = fileContent.split(/\n/).slice(1)
        let uploadedData = []
        let notAllContentProvided = false
        for (let i = 0; i < dataArray.length - 1; i++) {
            let content = dataArray[i].split(',')
            if (content[0] === undefined || content[1] === undefined) {
                notAllContentProvided = true
                break
            } else {
                uploadedData.push({
                    'dongle_serial_number': content[0].trim(),
                    'battery_serial_number': content[1].trim()
                })
            }
        }
        if (notAllContentProvided) {
            setFileIsInvalid(true)
        } else {
            setFileIsInvalid(false)
            setUploadedContent(uploadedData)
        }
    }

    const bulkSmartDongleUpdate = () => {
        if (fileUploaded) {
            if (!fileIsInvalid) {
                ADD_MULTIPLE_DEVICES(uploadedContent)
            }
        } else {
            setShowFileMandatoryMessage(true)
        }
    }

    const downloadCsvTemplate = () => {
        let currentDate = moment().format("DD_MMM_YYYY")
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: ``,
            filename: `smart_batteries_` + currentDate,
            useTextFile: false,
            useBom: true,
            headers: ['Dongle serial number', 'Battery serial number'],
        }
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv([{}])
    }

    const renderCsvUploadModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                footer={true}
                footerButtonSize={'sm'}
                footerCloseButtonText={language === 'EN' ? 'Cancel' : 'বাতিল করুন'}
                footerActionButtonText={language === 'EN' ? 'Confirm' : 'নিশ্চিত করুন'}
                hideModal={closeUploadCsvModal}
                takeAction={bulkSmartDongleUpdate}
                centered={true}
                modal={showUploadModal}
                disableButton={csvUploadInProgress}
                title={<>{language === 'EN' ? 'Create smart batteries by uploading csv' : 'সিএসভি ফাইল আপলোডের মাধ্যমে যন্ত্র নিবন্ধন'}</>}>
                <p>A blank CSV template can be downloaded from <a href={"#"} style={{'color': '#F18D00'}}
                                                                  onClick={() => {
                                                                      downloadCsvTemplate()
                                                                  }}>here</a>.</p>
                <label className="form-label" htmlFor="customFile">{language === 'EN' ? 'Upload' : 'আপলোড'}</label>
                <input type="file" className="form-control" id="customFile" ref={register()} name={'smartBatteryCreateFileUploadField'}
                       onChange={fileUploadEvent}
                       onClick={(event) => {
                           // Clearing the file field value when user tries to re-upload
                           setValue('smartBatteryCreateFileUploadField', '')
                       }}/>
                {fileIsInvalid ? <span className="text-danger">Invalid file!</span> : <></>}
                {showFileMandatoryMessage ? <span className="text-danger">Please upload a file!</span> : <></>}
            </GenericModal>
        </>
    }

    const FilterForm = <>
        <h4><SolTypography.SubTitle>Filter</SolTypography.SubTitle></h4>
        <br/>

        <div className={'row'}>
            <div className={'col-md-12'}>
                <Form onSubmit={handleSubmit(filterTheList)}>
                    <div className={'row g-6'}>

                        <div className={'col-md-4'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Smart Battery
                                        ID</SolTypography.Text></Form.Label>
                                </div>
                                <FilterInput
                                    name={'serial_number'}
                                    type="text"
                                    placeholder={'Type Smart battery ID'}
                                    autoComplete={"off"}
                                    ref={register()}
                                />
                                {errors.serial_number ? <>
                                    <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.serial_number.message}
                                    </div>
                                </> : null}

                            </Form.Group>
                        </div>

                        <div className={'col-md-4'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>SOLdongle
                                        ID</SolTypography.Text></Form.Label>
                                </div>
                                <FilterInput
                                    name={'dongle_serial_number'}
                                    type="text"
                                    placeholder={'Type SOLdongle ID'}
                                    autoComplete={"off"}
                                    ref={register()}
                                />
                                {errors.dongle_serial_number ? <>
                                    <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.dongle_serial_number.message}
                                    </div>
                                </> : null}

                            </Form.Group>
                        </div>

                        <div className={'col-md-4'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Garage</SolTypography.Text></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"garage_guid"}
                                    rules={{}}
                                    render={({onChange, onBlur, value, name, ref},
                                             {invalid, isTouched, isDirty}) => (
                                        <FilterReactSelect
                                            name={name}
                                            placeholder={"Select a garage"}
                                            isDisabled={props.collectingBorrowers}
                                            isLoading={props.collectingBorrowers}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={borrowers}
                                            isSearchable={true}
                                            onChange={(selected, {action}) => {
                                                onChange(selected)
                                                return selected;
                                            }}
                                        />
                                    )}
                                />
                                {errors.garage_guid ? <>
                                    <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.garage_guid.message}
                                    </div>
                                </> : null}
                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row g-6'}>
                        <div className={'col-md-4'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Branch</SolTypography.Text></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"branch_guid"}
                                    rules={{}}
                                    render={({ onChange, onBlur, value, name, ref },
                                             { invalid, isTouched, isDirty }) => (
                                        <FilterReactSelect
                                            name={name}
                                            placeholder={"Select a branch"}
                                            isDisabled={props.collectingBorrowers}
                                            isLoading={props.collectingBorrowers}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={branches}
                                            isSearchable={true}
                                            onChange={(selected, { action }) => {
                                                onChange(selected)
                                                return selected;
                                            }}
                                        />
                                    )}
                                />
                                {errors.branch_guid ? <>
                                    <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.branch_guid.message}
                                    </div>
                                </> : null}
                            </Form.Group>
                        </div>
                        <div className={'col-md-4'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Battery MFG Serial No</SolTypography.Text></Form.Label>
                                </div>
                                <FilterInput
                                    name={'manufacturer_identification_number'}
                                    type="text"
                                    placeholder={'Type SL No..'}
                                    autoComplete={"off"}
                                    ref={register()}
                                />
                                {errors.manufacturer_identification_number ? <>
                                    <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.manufacturer_identification_number.message}
                                    </div>
                                </> : null}
                            </Form.Group>
                        </div>

                        <div className={'col-md-4'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Loan ID</SolTypography.Text></Form.Label>
                                </div>
                                <FilterInput
                                    name={'contract_id'}
                                    type="text"
                                    placeholder={'Type Loan ID..'}
                                    autoComplete={"off"}
                                    ref={register()}
                                />
                                {errors.contract_id ? <>
                                    <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.contract_id.message}
                                    </div>
                                </> : null}
                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={"col-md-12 no-padding"}>

                            <FilterButton variant="warning" size={'sm'} type="submit"
                                          disabled={props.listLoading}
                                          className={'ml-3'}>
                                Apply Filter
                            </FilterButton>

                            &nbsp;
                            &nbsp;

                            <FilterButton variant="outline-dark" size={'sm'} onClick={() => {
                                resetFilter()
                            }}>
                                Reset Filter
                            </FilterButton>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
        <hr/>
    </>

    // --- Smart Battery row selection event//
    const onSelection = (data) => {
        setSelectedDevices(data)
        setNumberOfSmartBatteries(data.length)
    }

    // --------------- Event History ------------------- //
    const handleEventHistoryButtonClick = () => {
        setShowEventsHistoryModal(true)
    }

    const hideEventHistoryModal = () => {
        setShowEventsHistoryModal(false)
    }

    const renderEventHistoryModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideEventHistoryModal}
                centered={false}
                modal={showEventsHistoryModal}
                title={
                    <h1>{numberOfSmartBatteries === 1 ? 'Events History of Smart Battery #' + selectedDevices[0]?.serial_number : 'Events History'}</h1>}>
                <EventsHistory feature={'smartBattery'} numberOfThings={numberOfSmartBatteries}
                               data={selectedDevices}></EventsHistory>
            </GenericModal>
        </>
    }

    useEffect(() => {
        if (props.successMessageForEventSave !== undefined) {
            language === 'EN' ? showNotifications('success', props.successMessageForEventSave) : toast.success("সফলভাবে ঘটনা সংরক্ষিত হয়েছে!")
        } else if (props.errorMessageForEventSave !== undefined) {
            showNotifications('error', props.errorMessageForEventSave)
        }
    }, [props.successMessageForEventSave, props.errorMessageForEventSave])


    // --------------- Last Rent Report ------------------- //
    const openLastRentDetailsModal = (rowData) => {
        setCurrentRowData(rowData[0]);
        setShowRentDetailsModal(true)
    }

    const closeLastRentDetailsModal = () => {
        setCurrentRowData(null);
        setShowRentDetailsModal(false)
    }

    const lastRentDetailsModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                footer={false}
                footerButtonSize={'sm'}
                size={'lg'}
                footerCloseButtonText={language === 'EN' ? 'Cancel' : 'বাতিল করুন'}
                footerActionButtonText={language === 'EN' ? 'Confirm' : 'নিশ্চিত করুন'}
                hideModal={closeLastRentDetailsModal}
                centered={true}
                modal={showRentDetailsModal}
                title={<><SolTypography.Title>Last Rent for device
                    #{currentRowData?.serial_number}</SolTypography.Title></>}
            >
                <RentDetailsModalContent data={currentRowData}/>
            </GenericModal>
        </>
    }

    // --- Assign Garage event --- //
    const garageAssignmentInitiation = () => {
        COLLECT_GARAGE_LIST_FOR_ASSIGNMENT()
        setShowAssignGarageModal(true)
        if (selectedDevices.length === 1) {
            setSmartBatteryForGarageAssignment(selectedDevices[0].serial_number)
        }
    }

    const closeGarageAssignmentModal = () => {
        setShowAssignGarageModal(false)
        setShowGarageNonSelectionMessage(false)
        setSelectedGarageForAssignment("")
    }

    const renderAssignGarageModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                footer={true}
                footerButtonSize={'sm'}
                footerCloseButtonText={language === 'EN' ? 'Cancel' : 'বাতিল করুন'}
                footerActionButtonText={language === 'EN' ? 'Confirm' : 'নিশ্চিত করুন'}
                hideModal={closeGarageAssignmentModal}
                takeAction={confirmGarageAssignment}
                centered={true}
                modal={showAssignGarageModal}
                disableButton={props.garageAssignmentInfoSubmitting}
                title={<>{language === 'EN' ? `Assigning garage ${batchgarageAssignment ? 'to ' + selectedDevices.length + ' smart batteries' : ' to smart battery ' + smartBatteryForGarageAssignment}` : 'গ্যারেজ সংযুক্ত করা হচ্ছে '}</>}>
                <div className={'row g-3'}>
                    <div className={"col-md-12"}>
                        <Form.Group>
                            <div>
                                <Form.Label>{language === "EN" ? "Garage" : "গ্যারেজ"}<span
                                    className="required text-danger">*</span></Form.Label>
                            </div>

                            <Controller
                                control={control}
                                name={"garage"}
                                rules={{required: generalRequiredMessage}}
                                render={({onChange, onBlur, value, name, ref},
                                         {invalid, isTouched, isDirty}) => (
                                    <Select
                                        name={`garage`}
                                        placeholder={language === 'EN' ? 'Select a garage' : 'গ্যারেজ বাছাই করুন'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.garageListForAssignmentCollectionInProgress}
                                        isLoading={props.garageListForAssignmentCollectionInProgress}
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={garageListForAssignment}
                                        isSearchable={true}
                                        onChange={(selected, {action}) => {
                                            if (action === "clear") {
                                                setSelectedGarageForAssignment("")
                                            }
                                            onChange(selected)
                                            setShowGarageNonSelectionMessage(false)
                                            if (selected) {
                                                setSelectedGarageForAssignment(selected)
                                            } else {
                                                setSelectedGarageForAssignment("")
                                            }
                                        }}
                                    />
                                )}
                            />
                            {showGarageNonSelectionMessage ? <><span className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}
                            </span></> : null}
                        </Form.Group>
                    </div>
                </div>
            </GenericModal>
        </>
    }


    const closeDeviceStatusChangeModal = () => {
        setShowDeviceStatusChangeModal(false)
        setCurrentRowData(null)
        setKeyForTable(keyForTable + 1)
    }

    const submitDeviceChangeAction = (data)=> {
        let payload = {
            device_serial_number: currentRowData.serial_number,
            can_be_rented: !currentRowData.can_be_rented,
            reason: data.reason.value,
            authorized_by_org: data.authorized_by_org.value,
            action_type: currentRowData.can_be_rented? "device_deactivation": "device_activation",
            remarks: data.remarks
        }
        CHANGE_RENTABLE_STATUS(payload)
    }

    const deactivationReasons = [{value: "Loan Default", label: "Loan Default"},{value: "Late Payment", label: "Late Payment"},{value: "Vehicle Stolen", label: "Vehicle Stolen"},{value: "Others", label: "Others"}]
    const activationReasons = [{value: "Loan Repayment", label: "Loan Repayment"},{value: "Due Paid", label: "Due Paid"},{value: "Vehicle Retrieved", label: "Vehicle Retrieved"},{value: "Others", label: "Others"}]

    const renderDeviceStatusChangeModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                hideModal={closeDeviceStatusChangeModal}
                modal={showDeviceStatusChangeModal}
                size={"lg"}
                title={<>{currentRowData?.can_be_rented?"Deactivating": "Activating"} Smart Battery <strong>{currentRowData?.serial_number}</strong></>}>
                <Form onSubmit={handleSubmit(submitDeviceChangeAction)}>
                    <div className={'row g-3'}>
                        <div className={"col-md-6"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Reason <span className="required text-danger">*</span></Form.Label>
                                </div>

                                <Controller
                                    control={control}
                                    name={"reason"}
                                    rules={{required: generalRequiredMessage}}
                                    render={({onChange, onBlur, value, name, ref},
                                             {invalid, isTouched, isDirty}) => (
                                        <Select
                                            name={`reason`}
                                            placeholder={language === 'EN' ? 'Select a reason' : 'গ্যারেজ বাছাই করুন'}
                                            classNamePrefix="react-select-sol-style"
                                            isDisabled={props.rentableStatusGettingUpdated}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={currentRowData?.status==='active'? deactivationReasons:activationReasons }
                                            isSearchable={true}
                                            onChange={(selected, {action}) => {
                                                onChange(selected)

                                            }}
                                        />
                                    )}
                                />
                                {errors.reason && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.reason.message}</div>}

                            </Form.Group>
                        </div>

                        <div className={"col-md-6"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Authorized By <span className="required text-danger">*</span></Form.Label>
                                </div>

                                <Controller
                                    control={control}
                                    name={"authorized_by_org"}
                                    rules={{required: generalRequiredMessage}}
                                    render={({onChange, onBlur, value, name, ref},
                                             {invalid, isTouched, isDirty}) => (
                                        <Select
                                            name={name}
                                            placeholder={language === 'EN' ? 'Select an Organization' : 'গ্যারেজ বাছাই করুন'}
                                            classNamePrefix="react-select-sol-style"
                                            isDisabled={props.orgListLoading}
                                            isLoading={props.orgListLoading}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={orgsList}
                                            isSearchable={true}
                                            onChange={(selected, {action}) => {
                                                onChange(selected)

                                            }}
                                        />
                                    )}
                                />
                                {errors.authorized_by_org && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.authorized_by_org.message}</div>}

                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row g-3'}>
                        <div className={"col-md-12"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Remarks <span className="required text-danger">*</span></Form.Label>
                                </div>

                                <SolTextArea
                                    name={"remarks"}
                                    rows={"3"}
                                    placeholder={"Provide remarks..."}
                                    autoComplete={"off"}
                                    ref={register({
                                        required: "Required"
                                    })}
                                />
                                {errors.remarks && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.remarks.message}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={"col-md-12"}>
                            <Button variant={currentRowData?.can_be_rented? "danger":"warning"} size={"md"}
                                    type="submit" disabled={props.rentableStatusGettingUpdated} className={'ml-3 float-right'}>
                                {props.rentableStatusGettingUpdated ? <Spinner animation="border" size={ 'sm'}
                                                                             variant="secondary"/>:''} {currentRowData?.can_be_rented?"Deactivate":"Activate"}
                            </Button>
                            <Button variant="outline-dark" size={'md'} disabled={props.rentableStatusGettingUpdated}
                                    onClick={closeDeviceStatusChangeModal} className={'float-right'}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Form>
            </GenericModal>
        </>
    }

    useEffect(() => {
        if (props.garageListForAssignment && props.garageListForAssignment.length > -1) {
            const garages = props.garageListForAssignment
            setGarageListForAssignment(
                garages.map((item) => {
                    return {
                        ...item,
                        value: item.pk,
                        label: item.name
                    }
                })
            )
        }
    }, [props.garageListForAssignment])

    const confirmGarageAssignment = () => {
        if (!selectedGarageForAssignment) {
            setShowGarageNonSelectionMessage(true)

            return;
        }

        setShowGarageNonSelectionMessage(false)
        let payload = null;
        if (batchgarageAssignment) {
            payload = {
                device_serial_numbers: selectedDevices.map((item) => {
                    return item.serial_number
                }),
                garage_guid: selectedGarageForAssignment.value
            }
            ASSIGN_BATCH_GARAGE(payload)

            return;
        }
        payload = {
            device_serial_number: selectedDevices[0].serial_number,
            garage_guid: selectedGarageForAssignment.value
        }
        ASSIGN_GARAGE(payload)
    }

    const confirmChangeDeviceStatus=()=>{

    }

    useEffect(() => {
        if (props.successMessageForAssignment !== undefined) {
            if (language === 'EN') {
                showNotifications('success', props.successMessageForAssignment)
            } else if (language === 'BN') {
                toast.success("সফলভাবে যন্ত্রকে গ্যারেজের সাথে সংযুক্ত করা হয়েছে!")
            }
        } else if (props.errorMessageForAssignment !== undefined) {
            showNotifications('error', props.errorMessageForAssignment, true)
            RETURN_TO_INITIAL_STATE()
        }
    }, [props.successMessageForAssignment, props.errorMessageForAssignment]);

    useEffect(() => {
        if (props.garageAssigned === requestCycle.success) {
            setShowAssignGarageModal(false)
            props.history.push('/devices')
        }
    }, [props.garageAssigned])

    useEffect(() => {
        if (Array.isArray(selectedDevices) && selectedDevices.length > 0) {
            let enableGarageAssignment = true
            for (let i = 0; i < selectedDevices.length; i++) {
                if (selectedDevices[i].contract_id) {
                    enableGarageAssignment = false
                    break
                }
            }
            setEnableGarageAssignment(enableGarageAssignment)
            if (selectedDevices.length > 1) {
                setBatchGarageAssingment(true)
            } else {
                setBatchGarageAssingment(false)
            }
        } else {
            setBatchGarageAssingment(false)
            setEnableGarageAssignment(false)
        }
    }, [selectedDevices])

    // -- Active days calendar -- //
    const activeDaysDetails = (data) => {
        setShowActiveDaysDetailsModal(true)
        let deviceSerial = data.serial_number
        setSmartBatteryForActivitySummary(deviceSerial)
        setDongleForActivitySummary(data.dongle_serial_number)
        setBatteryForActivitySummary(data.battery_serial_number)
        let dateFrom = moment().startOf('month').format("YYYY-MM-DD")
        if (moment().format("DD") !== '01') {
            let dateTo = moment().subtract(1, 'days').format("YYYY-MM-DD")
            setMonthOfActivitySummary(moment(dateFrom, "YYYY-MM-DD").format("MMMM, YYYY"))
            COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY(deviceSerial, {'date_from': dateFrom, 'date_to': dateTo})
        }
    }
    const hideActiveDaysDetailsModal = () => {
        setShowActiveDaysDetailsModal(false)
        setViewRelatedValueOfTheCalendar(new Date())
        setSmartBatteryForActivitySummary('')
        setDongleForActivitySummary('')
        setBatteryForActivitySummary('')
    }
    const onCalenderViewUpdate = (action, view, startDateOfTheCalendar) => {
        if (['next', 'prev', 'drillDown'].includes(action) && view === 'month') {
            let dateFrom = moment(startDateOfTheCalendar).format("YYYY-MM-DD")
            let dateTo = ''
            if (moment().format("MM") === moment(startDateOfTheCalendar).format("MM")) {
                // User has selected the current month
                dateTo = moment().subtract(1, 'days').format("YYYY-MM-DD")
                setCurrentMonthIsSelected(true)
            } else {
                dateTo = moment(startDateOfTheCalendar).endOf('month').format("YYYY-MM-DD")
                setCurrentMonthIsSelected(false)
            }
            if (moment().format("DD") !== '01') {
                setMonthOfActivitySummary(moment(dateFrom, "YYYY-MM-DD").format("MMMM, YYYY"))
                COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY(smartBatteryForActivitySummary, {
                    'date_from': dateFrom,
                    'date_to': dateTo
                })
            }
            setViewRelatedValueOfTheCalendar(startDateOfTheCalendar)
        } else if (view === 'year') {
            setViewRelatedValueOfTheCalendar(startDateOfTheCalendar)
        }
    }
    useEffect(() => {
        if (props.summary) {
            const summaryInfo = props.summary
            setActiveDays(new Set(summaryInfo.active_days))
            setInactiveDays(new Set(summaryInfo.inactive_days))
            if (calendarRef.current) {
                setHeightOfTheCalendar(document.getElementsByClassName('calendar')[0].clientHeight)
                let daysText = summaryInfo.active_days_total > 1 ? 'days' : 'day'
                let activeDaysWholeText = summaryInfo.active_days_total > 0 || summaryInfo.inactive_days_total > 0 ?
                    'Active for ' + summaryInfo.active_days_total + ' ' + daysText : 'No data available'
                let actualHeader = document.getElementsByClassName('react-calendar__navigation__label__labelText')[0].innerText
                // As two API calls are happening due to usage of 'onViewChange' and 'onActiveStartDateChange'
                // Hence, we are preventing loading two p tags
                // We have tried to prevent it by excluding 'onViewChange' but it was generating an error (First month of a year selection was not being caught by 'onActiveStartDateChange')
                if (document.querySelector('span.react-calendar__navigation__label__labelText p') === null) {
                    document.getElementsByClassName('react-calendar__navigation__label__labelText')[0].innerHTML = '<p>' + actualHeader +
                        '</p>' + '<p style="font-size: 70% !important;">' + activeDaysWholeText + '</p>'
                }
                if (currentMonthIsSelected) {
                    let allWeekDaysElements = document.getElementsByClassName('react-calendar__month-view__days__day--weekend')
                    for (let i = 0; i < allWeekDaysElements.length; i++) {
                        if (allWeekDaysElements[i].hasAttribute('disabled')) {
                            let styleValues = allWeekDaysElements[i].getAttribute('style')
                            styleValues += 'color: #6d6d6d !important'
                            allWeekDaysElements[i].setAttribute('style', styleValues)
                        }
                    }
                }
            }
        }
    }, [props.summary])
    const currentDate = moment().format("YYYY-MM-DD")
    const renderActiveDaysDetailsModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideActiveDaysDetailsModal}
                centered={true}
                modal={showActiveDaysDetailsModal}
                title={<>
                    <h1><strong>Smart Battery #{smartBatteryForActivitySummary} - Rent Days Overview</strong></h1>
                    <h6>SOLdongle #{dongleForActivitySummary}</h6>
                    <h6>Battery #{batteryForActivitySummary}</h6>
                </>}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Stack justifyContent="center" alignItems="center" spacing={3}>
                            {props.collectingDeviceActivitySummary ? <Stack
                                    style={{height: heightOfTheCalendar}} justifyContent="center" alignItems="center">
                                    <LoadingSpinner
                                        loadingSubText={'Active days of ' + monthOfActivitySummary}
                                        language={language}/>
                                </Stack> :
                                <div className={"calendar"}>
                                    <Calendar
                                        onChange={setCalenderValue}
                                        activeStartDate={viewRelatedValueOfTheCalendar}
                                        onActiveStartDateChange={(actionObject) => {
                                            onCalenderViewUpdate(actionObject.action, actionObject.view, actionObject.activeStartDate)
                                        }}
                                        onViewChange={(actionObject) => {
                                            onCalenderViewUpdate(actionObject.action, actionObject.view, actionObject.activeStartDate)
                                        }}
                                        value={value}
                                        tileClassName={({date, view}) => {
                                            if (view === 'month') {
                                                if (currentDate === moment(date).format("YYYY-MM-DD")) {
                                                    return 'bold'
                                                } else if (activeDays.has(moment(date).format("YYYY-MM-DD"))) {
                                                    return 'highlight'
                                                } else if (inactiveDays.has(moment(date).format("YYYY-MM-DD"))) {
                                                    return 'inActiveDays'
                                                }
                                            }
                                        }}
                                        maxDate={new Date()}
                                        nextLabel={<ArrowForwardIosIcon/>}
                                        prevLabel={<ArrowBackIosIcon/>}
                                        calendarType={"hebrew"}
                                        ref={calendarRef}
                                    />
                                    <Stack direction="row"
                                           justifyContent="center"
                                           alignItems="center"
                                           spacing={2}>
                                        <span style={{color: '#0b6e4f'}}><div className={'custom-box green'}></div>
                                            &nbsp;Rented</span>
                                        <span style={{color: '#cb152b'}}><div className={'custom-box red'}></div>
                                            &nbsp;Inactive</span>
                                    </Stack>
                                </div>}
                        </Stack>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-12'}>

                    </div>
                </div>
            </GenericModal>
        </>
    }

    // Per Rent Payable
    const openRentPerDayModal = (rowData) => {
        setCurrentRowData(rowData);
        setShowRentPerDayModal(true)
    }

    const closeRentModal = () => {
        setCurrentRowData(null);
        setShowRentPerDayModal(false)
        if (rentUpdateIsSuccessful) {
            setSelectedDevices([])
            setNumberOfSmartBatteries(0)
            props.GET_DEVICE_LIST({page: page, status: 'active', ...filter})
        }
    }

    const rentIsUpdated = (updated) => {
        if (updated) {
            setRentUpdateIsSuccessful(true)
        }
    }

    const rentPerDayModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                footer={false}
                footerButtonSize={'sm'}
                size={'lg'}
                footerCloseButtonText={language === 'EN' ? 'Cancel' : 'বাতিল করুন'}
                footerActionButtonText={language === 'EN' ? 'Confirm' : 'নিশ্চিত করুন'}
                hideModal={closeRentModal}
                centered={true}
                modal={showRentPerDayModal}
                disableButton={props.garageAssignmentInfoSubmitting}
                title={<><SolTypography.Title>Edit Battery per rent payable for Smart Battery
                    #{currentRowData?.serial_number}</SolTypography.Title></>}
            >
                <RentPerDayModalContent data={currentRowData} rentIsUpdated={rentIsUpdated}/>
            </GenericModal>
        </>
    }


    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Smart Batteries"}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{language === 'EN' ? 'Smart Batteries' : 'যন্ত্রের তালিকা'}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    {/* <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton> */}
                                    <IconButton classes={classes}
                                                onClick={() => {
                                                    deviceListWithoutPagination()
                                                }}
                                                disabled={props.collectingSmartBatteriesForExport || props.listLoading}>
                                        {props.collectingSmartBatteriesForExport ? <Spinner animation={"grow"}
                                                                                            variant={'warning'}
                                                                                            size={"md"}/> :
                                            <img src={require('../../../../utils/asset/download-Button.svg')}
                                                 alt="Download"/>}
                                    </IconButton>
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter ?
                    FilterForm : null}
                {<>
                    <div className={'row'}>
                        <div className={"col-md-12"} style={{
                            marginBottom: '1.85rem',
                            marginTop: '1.85rem',
                            visibility: Array.isArray(selectedDevices) && selectedDevices.length > 0 ? 'visible' : 'hidden'
                        }}>
                            <Stack direction={'row'} justifyContent="flex-start" spacing={1}>
                                {(checkAuthorization(props.authorization, deviceServiceName, eventHistoryFeature, [createActionName]) ||
                                    checkAuthorization(props.authorization, deviceServiceName, eventHistoryFeature, [listActionName])) ?
                                    <>
                                        <Button variant="warning" size={'sm'} type="button"
                                                onClick={() => handleEventHistoryButtonClick()}>
                                            Events History
                                        </Button>
                                    </> : null}
                                {selectedDevices.length === 1 ? <>
                                    <Button variant="warning" size={'sm'} type="button"
                                            onClick={() => openLastRentDetailsModal(selectedDevices)}>
                                        Last Rent Report
                                    </Button>
                                </> : null}
                                {(checkAuthorization(props.authorization, deviceServiceName, deviceFeatureName, [updateActionName]) &&
                                    enableGarageAssignment) ?
                                    <>
                                        <Button variant="warning" size={'sm'} type="button"
                                                onClick={() => garageAssignmentInitiation()}>
                                            Assign Garage
                                        </Button>
                                    </> : null}
                            </Stack>
                        </div>
                    </div>
                </>}
                <div className={'row'} key={keyForTable}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {props.listLoading !== true ? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন যন্ত্র নেই'}
                                columns={deviceListColumns}
                                data={deviceList}
                                showToolbar={false}
                                // Selection related
                                selection={enableSmartBatterySelection}
                                showSelectAllCheckbox={true}
                                onSelectionChange={onSelection}
                                // Pagination related
                                asyncPagination={true}
                                count={count}
                                itemsPerPage={perPage}
                                onChangePage={onChangePage}
                                page={page}
                            /> : <LoadingSpinner
                                loadingSubText={language === 'EN' ? 'Generating smart battery list ..' : 'যন্ত্রের তালিকা প্রস্তুত হচ্ছে'}
                                language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

                {renderCsvUploadModal()}
                {renderEventHistoryModal()}
                {lastRentDetailsModal()}
                {renderAssignGarageModal()}
                {garageDetailsModal()}
                {rentPerDayModal()}
                {renderActiveDaysDetailsModal()}
                {renderDeviceStatusChangeModal()}

            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    )
}

DeviceList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        isLoading: state.devicesReducer.isLoading,
        tableLoading: state.devicesReducer.tableLoading,
        statusCode: state.devicesReducer.statusCode,
        deviceList: state.devicesReducer.deviceList,
        filterTriggeredLoading: state.devicesReducer.filterTriggeredLoading,
        listLoading: state.devicesReducer.listLoading,
        iconLoading: state.devicesReducer.iconLoading,
        garages: state.commonReducer.garages,
        garageListLoading: state.commonReducer.collectingGarages,
        borrowers: state.commonReducer.borrowers,
        branches: state.commonReducer.branches,
        orgListLoading: state.commonReducer.orgListLoading,
        organisations: state.commonReducer.organisations,
        collectingBorrowers: state.commonReducer.collectingBorrowers,
        multipleDevicesCreated: state.devicesReducer.multipleDevicesCreated,
        successMessageForMultipleDeviceCreation: state.devicesReducer.successMessageForMultipleDeviceCreation,
        errorMessageForMultipleDeviceCreation: state.devicesReducer.errorMessageForMultipleDeviceCreation,
        multipleDevicesInfoSubmitting: state.devicesReducer.multipleDevicesInfoSubmitting,
        rentChangeHistory: state.devicesReducer.rentChangeHistory,
        modalListLoading: state.devicesReducer.modalListLoading,
        buttonLoading: state.devicesReducer.buttonLoading,
        lastRentDetails: state.devicesReducer.lastRentDetails,
        modaldataLoading: state.devicesReducer.modaldataLoading,
        rentableChanged: state.devicesReducer.rentableChanged,
        rentableStatusGettingUpdated: state.devicesReducer.rentableStatusGettingUpdated,
        smartBatteriesForExport: state.devicesReducer.smartBatteriesForExport,
        collectingSmartBatteriesForExport: state.devicesReducer.collectingSmartBatteriesForExport,
        eventSaved: state.commonReducer.eventSaved,
        successMessageForEventSave: state.commonReducer.successMessageForEventSave,
        errorMessageForEventSave: state.commonReducer.errorMessageForEventSave,
        summary: state.devicesReducer.summary,
        collectingDeviceActivitySummary: state.devicesReducer.collectingDeviceActivitySummary,
        // garagesForValidation: state.commonReducer.garages,
        garageAssigned: state.devicesReducer.garageAssigned,
        garageAssignmentInfoSubmitting: state.devicesReducer.garageAssignmentInfoSubmitting,
        successMessageForAssignment: state.devicesReducer.successMessageForAssignment,
        errorMessageForAssignment: state.devicesReducer.errorMessageForAssignment,
        garageListForAssignment: state.devicesReducer.garageListForAssignment,
        garageListForAssignmentCollectionInProgress: state.devicesReducer.garageListForAssignmentCollectionInProgress
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(DeviceList));
