import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Badge, Breadcrumb, Button, Form, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner} from '../../utils'
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable'
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import { useLocation } from "react-router";
import Select from "react-select";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../../auth/authorization";
import {Controller, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import moment from "moment";
import {ExportToCsv} from "export-to-csv";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const BatteryList = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const [showFilter, setShowFilter] = useState(true);
    const invalidDongleSerial = 'Invalid Dongle Serial!'
    const invalidBatterySerial = 'Invalid Battery Serial!'

    const [batterySuppliers, setBatterySuppliers] = useState([]);
    const [batterySupplierFromUrl, setBatterySupplierFromUrl] = useState("");
    const capacities = [{
        label: '80',
        value: 80
    }, {
        label: '100',
        value: 100
    }, {
        label: '120',
        value: 120
    }]
    const voltages = [{
        label: '51.2',
        value: 51.2
    }, {
        label: '64.0',
        value: 64.0
    }]

    const [batteryList, setBatteryList] = useState([]);
    const [language, setLanguage] = useState('EN');
    const [authorization, setAuthorization] = useState(null);
    const [role, setRole] = useState('');
    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);
    const [filterParametersWithoutPage, setFilterParametersWithoutPage] = useState({});
    const [queryParams, setQueryParams] = useState(null);
    const [initialfilterParam, setInitialfilterParam] = useState(null);
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null);
    const [urlIsEdited, setUrlIsEdited] = useState(false);
    const subHeader = useSubheader();
    const location = useLocation();

    // Permission related
    const [hasAccessToViewGraph, setHasAccessToViewGraph] = useState(false)
    const [hasAccessToUpdateBattery, setHasAccessToUpdateBattery] = useState(false)
    const batteryServiceName = getServiceName('batteryService')
    const batteryFeatureName = getFeatureName('batteryFeature')
    const historicalDataFeatureName = getFeatureName('historicalDataFeature')
    const listActionName = getActionName('listAction')
    const createActionName = getActionName('createAction')
    const updateActionName = getActionName('updateAction')

    const batteryListColumns = [
        {
            field: 'batterySerial',
            title: language === 'EN'? 'Battery Serial':'ব্যাটারী সিরিয়াল',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            // disableClick: true,
            render: (rowData) => {
                return (
                    <>
                        {hasAccessToViewGraph?
                            <Link style={{color: "#F18D00"}} target={'_blank'}
                                  to={`/batteries/battery_graph/${rowData.batterySerial}`}>
                                {rowData.batterySerial}
                            </Link>:rowData.batterySerial}
                    </>
                )
            }
        },
        {
            field: 'dongleSerial',
            title: language === 'EN'? 'Dongle Serial':'ডঙ্গল সিরিয়াল',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            }
            // disableClick: true,
        },
        {
            field: 'lastUpdateTime',
            title: 'Updated at',
            emptyValue: ()=>{
                return "-"
            },
            // disableClick: true,
        },
        {
            field: 'nameplateVoltage',
            title: language === 'EN'? 'Battery voltage (V)':'ব্যাটারী ভোল্টেজ (V)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'nameplateCapacity',
            title: language === 'EN'? 'Nameplate capacity (Ah)':'নেমপ্লেট ক্যাপাসিটি (Ah)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "-"
            },
            // disableClick: true
        },
        {
            field: 'referenceCapacity',
            title: language === 'EN'? 'Reference capacity by supplier (Ah)':'ব্যাটারী সরবরাহকারীর দেওয়া রেফারেন্স (Ah)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "-"
            },
            // disableClick: true
        },
        {
            field: 'dischargeDelivery',
            title: language === 'EN'? 'Guaranteed discharge delivery (Ah)':'প্রতিশ্রুত ডিসচার্জ ডেলিভারি (Ah)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "-"
            },
            // disableClick: true
        },
        {
            field: 'claimReferenceCapacity',
            title: language === 'EN'? 'Claim event reference capacity level (%)':'দাবী করা ইভেন্ট রেফারেন্স অনুসারে ক্যাপাসিটি লেভেল (%)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "-"
            },
            // disableClick: true
        },
        {
            field: 'manufacturer_identification_number',
            title: language === 'EN' ? 'Manufacturer ID number' : 'দাবী করা ইভেন্ট রেফারেন্স অনুসারে ক্যাপাসিটি লেভেল (%)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: () => {
                return "-"
            },
            // disableClick: true
        }
    ]

    const {
        GET_BATTERY_LIST,
        GET_BATTERY_SUPPLIER_LIST,
        GET_BATTERY_LIST_FOR_EXPORT,
        RETURN_TO_INITIAL_STATE
    } = props;
    const classes = useStylesIconButton();

    const resetFilter = () => {
        resetFilterValues()
        clearErrors()
        setFilterApplied(false)
        if (filterApplied) {
            // Resetting the battery list
            setPage(1)
            props.history.push({
                search: 'page=1'
            })
        }
    }

    const resetFilterValues = () => {
        setValue('batterySupplier', '')
        setValue('capacity', '')
        setValue('voltage', '')
        setValue('batterySerial', '')
        setValue('dongleSerial', '')
        setValue('manufacturerId', '')
    }

    const filterTheList = (data) => {
        setPage(1)
        let searchText = 'page=1'
        if (data.batterySupplier) {
            searchText += '&battery_vendor=' + data.batterySupplier.label
        }
        if (data.capacity) {
            searchText += '&nameplate_capacity=' + data.capacity.value
        }
        if (data.voltage) {
            searchText += '&nameplate_voltage=' + parseFloat(data.voltage.value).toFixed(1)
        }
        if (data.batterySerial) {
            searchText += '&serial_number=' + data.batterySerial
        }
        if (data.dongleSerial) {
            searchText += '&dongle_serial_number=' + data.dongleSerial
        }
        if (data.manufacturerId) {
            searchText += '&manufacturer_identification_number=' + data.manufacturerId
        }
        props.history.push({
            search: searchText
        })
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        setLanguage(props.language)
        setAuthorization(props.authorization)
        setRole(props.role)
        if (!props.history.location.search) {
            props.history.push({
                search: 'page=1'
            })
        }
        GET_BATTERY_SUPPLIER_LIST()

        if (checkAuthorization(props.authorization, batteryServiceName, batteryFeatureName, [createActionName]) ||
            checkAuthorization(props.authorization, batteryServiceName, batteryFeatureName, [updateActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    {/*--Batch battery button is shown if user has battery create or update access--*/}
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} classes={classes} onClick={goToBatchBattery}>
                        <i className="fa fa-random"/>{props.language === 'EN'? 'Battery Batch': 'একাধিক ব্যাটারী নিবন্ধন'}
                    </Button>
                    {/*--Add battery button is only shown when user has battery create access--*/}
                    {checkAuthorization(props.authorization, batteryServiceName, batteryFeatureName, [createActionName])? <>
                        <Button variant="warning" size={'sm'} type="button" style={{
                            backgroundColor: '#F18D00 !important',
                        }} onClick={goToAddBattery} classes={classes} className={'ml-1'}>
                            <i className="fa fa-plus"/>{props.language === 'EN'? 'Add battery': 'ব্যাটারী নিবন্ধন'}
                        </Button>
                    </>:null}
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        subHeader.setBreadCrumbComponent(null)

        setHasAccessToViewGraph(checkAuthorization(props.authorization, batteryServiceName, historicalDataFeatureName, [listActionName]))
        setHasAccessToUpdateBattery(checkAuthorization(props.authorization, batteryServiceName, batteryFeatureName, [updateActionName]))

        return ()=>{
            subHeader.setActionButtons(null)
            subHeader.setBreadCrumbComponent(null)
            RETURN_TO_INITIAL_STATE();
        }
    }, []);

    useEffect(() => {
        if (props.batterySuppliers) {
            const batterySuppliers = props.batterySuppliers
            setBatterySuppliers(batterySuppliers.map((supplier, index) => {
                if (batterySupplierFromUrl && batterySupplierFromUrl === supplier.name) {
                    let selectedSupplier = supplier
                    selectedSupplier['label'] = supplier.name
                    selectedSupplier['value'] = supplier.pk
                    setValue('batterySupplier', selectedSupplier)
                }
                return {
                    ...supplier,
                    label: supplier.name,
                    value: supplier.pk
                }
            }))
        }
    }, [props.batterySuppliers]);

    useEffect(() => {
        if (props.batteryList) {
            const batteries = props.batteryList.results;
            if (batteries && batteries.length > -1) {
                setBatteryList(batteries.map((battery, index) => {
                    return {
                        ...battery,
                        batterySerial: battery.serial_number,
                        dongleSerial: battery.dongle_serial_number,
                        lastUpdateTime: moment(battery.updated_at).format("DD MMM, YYYY hh:mma"),
                        capacity: battery.capacity,
                        voltage: battery.voltage,
                        nameplateCapacity: battery.nameplate_capacity,
                        nameplateVoltage: battery.nameplate_voltage,
                        capacityBySupplier: battery.capacity,
                        dischargeDelivery: battery.guaranteed_discharge_delivery,
                        // Comment for following
                        // Dealing with empty string value manually. Normally 'emptyValue' function at the column only deals with null
                        // Done this only for 'referenceCapacity' as we are getting empty string for this. But this might be done for other values also? It is up to you!
                        // - Noor Reza, August 23, 2022
                        referenceCapacity: battery.reference_capacity? battery.reference_capacity:'-',
                        claimReferenceCapacity: battery.claim_reference_capacity,
                        warranty_end_date: battery.warranty_end_date,
                        warranty_start_date: battery.warranty_start_date,
                        manufacturer_identification_number: battery.manufacturer_identification_number,
                    }
                }))
            }
        }
    }, [props.batteryList]);

    // ----- Begin: Section related to shareable link/handling query params ---- //
    const validationKeyArray = ['page', 'battery_vendor', 'nameplate_capacity', 'nameplate_voltage',
        'serial_number', 'dongle_serial_number', 'manufacturer_identification_number']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (initialfilterParam) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            if (initialfilterParam.battery_vendor) {
                let batterySupplierFromUrl = initialfilterParam.battery_vendor
                setBatterySupplierFromUrl(batterySupplierFromUrl)
            }
            if (initialfilterParam.nameplate_capacity) {
                let capacityFromUrl = initialfilterParam.nameplate_capacity
                let selectedCapacity = capacities.filter(item => item.value ===  parseFloat(capacityFromUrl))
                setValue('capacity', selectedCapacity[0]? selectedCapacity[0]:'')
            }
            if (initialfilterParam.nameplate_voltage) {
                let voltageFromUrl = initialfilterParam.nameplate_voltage
                let selectedVoltage = voltages.filter(item => item.value ===  parseFloat(voltageFromUrl).toFixed(1))
                setValue('voltage', selectedVoltage[0]? selectedVoltage[0]:'')
            }
            if (initialfilterParam.serial_number) {
                let serialNumberFromUrl = initialfilterParam.serial_number
                setValue('batterySerial', serialNumberFromUrl)
            }
            if (initialfilterParam.dongle_serial_number) {
                let dongleSerialFromUrl = initialfilterParam.dongle_serial_number
                setValue('dongleSerial', dongleSerialFromUrl)
            }
            if (initialfilterParam.manufacturer_identification_number) {
                let manufactureIdFromUrl = initialfilterParam.manufacturer_identification_number
                setValue('manufacturerId', manufactureIdFromUrl)
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            // Page will always be there
            let filter = {}
            let filterApplied = false
            if (filterParamForApiCall.battery_vendor) {
                filter['battery_vendor'] = filterParamForApiCall.battery_vendor
                filterApplied = true
            }
            if (filterParamForApiCall.nameplate_capacity) {
                filter['nameplate_capacity'] = filterParamForApiCall.nameplate_capacity
                filterApplied = true
            }
            if (filterParamForApiCall.nameplate_voltage) {
                filter['nameplate_voltage'] = filterParamForApiCall.nameplate_voltage
                filterApplied = true
            }
            if (filterParamForApiCall.serial_number) {
                filter['serial_number'] = filterParamForApiCall.serial_number
                filterApplied = true
            }
            if (filterParamForApiCall.dongle_serial_number) {
                filter['dongle_serial_number'] = filterParamForApiCall.dongle_serial_number
                filterApplied = true
            }
            if (filterParamForApiCall.manufacturer_identification_number) {
                filter['manufacturer_identification_number'] = filterParamForApiCall.manufacturer_identification_number
                filterApplied = true
            }
            setFilterApplied(filterApplied)
            setFilterParametersWithoutPage({...filter})
            filter['page'] = page
            GET_BATTERY_LIST({...filter})
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);
    // ----- End: Section related to shareable link/handling query params ---- //

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        let searchText = 'page=' + newPage
        if (filterApplied) {
            if (filterParamForApiCall.battery_vendor) {
                searchText += '&battery_vendor=' + filterParamForApiCall.battery_vendor
            }
            if (filterParamForApiCall.nameplate_capacity) {
                searchText += '&nameplate_capacity=' + filterParamForApiCall.nameplate_capacity
            }
            if (filterParamForApiCall.nameplate_voltage) {
                searchText += '&nameplate_voltage=' + filterParamForApiCall.nameplate_voltage
            }
            if (filterParamForApiCall.serial_number) {
                searchText += '&serial_number=' + filterParamForApiCall.serial_number
            }
            if (filterParamForApiCall.dongle_serial_number) {
                searchText += '&dongle_serial_number=' + filterParamForApiCall.dongle_serial_number
            }
            if (filterParamForApiCall.manufacturer_identification_number) {
                searchText += '&manufacturer_identification_number=' + filterParamForApiCall.manufacturer_identification_number
            }
        }
        props.history.push({
            search: searchText
        })
    }

    const goToAddBattery = () => {
        props.history.push({
            pathname: '/batteries/create',
            purpose: 'create'
        })
    }

    const goToBatchBattery = () => {
        props.history.push({
            pathname: '/batteries/batch',
            purpose: 'create'
        })
    }

    const goToUpdateBattery = (rowData) => {
        props.history.push({
            pathname: '/batteries/edit',
            purpose: 'edit',
            batteryData: rowData
        })
    }

    const viewBatteryLog = (rowData) => {
        props.history.push({
            pathname: `/batteries/battery_graph/${rowData.serial_number}`,
            purpose: 'details',
            batteryData: rowData
        })
    }

    // Battery List export related
    const exportBatteryList = () => {
        GET_BATTERY_LIST_FOR_EXPORT({...filterParametersWithoutPage})
    }

    useEffect(() => {
        if (props.batteryListForExport) {
            const batteries = props.batteryListForExport
            let batteryData = [{
                'serial_number': '',
                'nameplate_voltage': '',
                'nameplate_capacity': '',
                'reference_capacity': '',
                'claim_reference_capacity': '',
                'guaranteed_discharge_delivery': '',
                'warranty_start_date': '',
                'warranty_end_date': '',
                'bms_vendor': '',
                'bms_model': '',
                'battery_vendor': '',
                'battery_model': '',
                'manufacturer_identification_number': '',
                'battery_cost': '',
                'battery_cost_including_dongle': ''
            }]
            if (batteries.length > 0) {
                batteryData = batteries.map((battery) => {
                    return {
                        'serial_number': battery.serial_number,
                        'nameplate_voltage': parseFloat(battery.nameplate_voltage).toFixed(1),
                        'nameplate_capacity': battery.nameplate_capacity,
                        'reference_capacity': battery.reference_capacity,
                        'claim_reference_capacity': battery.claim_reference_capacity,
                        'guaranteed_discharge_delivery': battery.guaranteed_discharge_delivery,
                        'warranty_start_date': battery.warranty_start_date,
                        'warranty_end_date': battery.warranty_end_date,
                        'bms_vendor': battery.bms_vendor,
                        'bms_model': battery.bms_model,
                        'battery_vendor': battery.battery_vendor,
                        'battery_model': battery.battery_model,
                        'manufacturer_identification_number': battery.manufacturer_identification_number? battery.manufacturer_identification_number:'',
                        'battery_cost': battery.battery_cost? battery.battery_cost:'',
                        'battery_cost_including_dongle': battery.battery_cost_including_dongle? battery.battery_cost_including_dongle:''
                    }
                })
            }

            let currentDate = moment().format("DD_MMM_YYYY")
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: `` ,
                filename: `batteries_` + currentDate,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true
            }
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(batteryData)
        }
    },[props.batteryListForExport])

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Smart Dongles"}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{language === 'EN'? 'Batteries':'ব্যাটারীর তালিকা'}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                    <IconButton classes={classes} onClick={exportBatteryList}
                                                disabled={props.collectingBatteryListForExport}>
                                        {props.collectingBatteryListForExport? <Spinner animation={"grow"} variant={'warning'}
                                                                                        size={"lg"}/> :
                                            <img src={require('../../../../utils/asset/download-Button.svg')}
                                                 alt="Download"/>}
                                    </IconButton>
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form onSubmit={handleSubmit(filterTheList)}>
                                    {role !== 'Mpower'? <>
                                        <div className={'row g-3'}>
                                            <div className={'col-md-4'}>
                                                <Form.Group>
                                                    <div>
                                                        <Form.Label>{language === 'EN'? 'Supplier':'সরবরাহকারী'}</Form.Label>
                                                    </div>
                                                    <Controller
                                                        control={control}
                                                        name={"batterySupplier"}
                                                        render={( { onChange, onBlur, value, name, ref },
                                                                  { invalid, isTouched, isDirty }) => (

                                                            <Select
                                                                placeholder={language === 'EN'? 'Select a supplier':'একটি সরবরাহকারী বাছাই করুন'}
                                                                classNamePrefix="react-select-sol-style"
                                                                maxMenuHeight={200}
                                                                value={value}
                                                                isDisabled={props.batterySuppliersDropDownLoading}
                                                                isLoading={props.batterySuppliersDropDownLoading}
                                                                isClearable={true}
                                                                options={batterySuppliers}
                                                                isSearchable={true}
                                                                control={control}
                                                                inputRef={ref}
                                                                onChange={(selected, {action}) => {
                                                                    onChange(selected)
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.batterySupplier && <div className="text-danger">
                                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.batterySupplier.message}</div>}
                                                </Form.Group>
                                            </div>
                                            <div className={'col-md-4'}>
                                                <Form.Group>
                                                    <div>
                                                        <Form.Label>{language === 'EN'? 'Capacity':'সক্ষমতা'}</Form.Label>
                                                    </div>
                                                    <Controller
                                                        control={control}
                                                        name={"capacity"}
                                                        render={( { onChange, onBlur, value, name, ref },
                                                                  { invalid, isTouched, isDirty }) => (

                                                            <Select
                                                                placeholder={'Select capacity in Ah'}
                                                                classNamePrefix="react-select-sol-style"
                                                                maxMenuHeight={200}
                                                                value={value}
                                                                isDisabled={props.listLoading}
                                                                isLoading={props.listLoading}
                                                                isClearable={true}
                                                                options={capacities}
                                                                isSearchable={true}
                                                                control={control}
                                                                inputRef={ref}
                                                                onChange={(selected, {action}) => {
                                                                    onChange(selected)
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.capacity && <div className="text-danger">
                                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.capacity.message}</div>}
                                                </Form.Group>
                                            </div>
                                            <div className={'col-md-4'}>
                                                <Form.Group>
                                                    <div>
                                                        <Form.Label>{language === 'EN'? 'Voltage':'ভোল্টেজ'}</Form.Label>
                                                    </div>
                                                    <Controller
                                                        control={control}
                                                        name={"voltage"}
                                                        render={( { onChange, onBlur, value, name, ref },
                                                                  { invalid, isTouched, isDirty }) => (
                                                            <Select
                                                                placeholder={'Select Voltage in V'}
                                                                classNamePrefix="react-select-sol-style"
                                                                maxMenuHeight={200}
                                                                value={value}
                                                                isDisabled={props.listLoading}
                                                                isLoading={props.listLoading}
                                                                isClearable={true}
                                                                options={voltages}
                                                                isSearchable={true}
                                                                control={control}
                                                                inputRef={ref}
                                                                onChange={(selected, {action}) => {
                                                                    onChange(selected)
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.voltage && <div className="text-danger">
                                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.voltage.message}</div>}
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className={'row g-3'}>
                                            <div className={'col-md-4'}>
                                                <Form.Group>
                                                    <div>
                                                        <Form.Label>{language === 'EN'? 'Battery Serial':'ব্যাটারী সিরিয়াল'}</Form.Label>
                                                    </div>
                                                    <SolInput
                                                        type={"text"}
                                                        name={"batterySerial"}
                                                        placeholder={language === 'EN'? 'Ex: 55000001':'উদাহরণঃ 55000001'}
                                                        autoComplete={"off"}
                                                        ref={register({
                                                            validate: {
                                                                isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || invalidBatterySerial
                                                            }
                                                        })}
                                                    />
                                                    {errors.batterySerial && <div className="text-danger">
                                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.batterySerial.message}</div>}
                                                </Form.Group>
                                            </div>
                                            <div className={'col-md-4'}>
                                                <Form.Group>
                                                    <div>
                                                        <Form.Label>{language === 'EN'? 'Dongle Serial':'ডঙ্গল সিরিয়াল'}</Form.Label>
                                                    </div>
                                                    <SolInput
                                                        type={"text"}
                                                        name={"dongleSerial"}
                                                        placeholder={language === 'EN'? 'Ex: 44000001':'উদাহরণঃ 44000001'}
                                                        autoComplete={"off"}
                                                        ref={register({
                                                            validate: {
                                                                isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || invalidDongleSerial
                                                            }
                                                        })}
                                                    />
                                                    {errors.dongleSerial && <div className="text-danger">
                                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.dongleSerial.message}</div>}
                                                </Form.Group>
                                            </div>


                                            <div className={'col-md-4'}>
                                                <Form.Group>
                                                    <div>
                                                        <Form.Label>{language === 'EN' ? 'Manufacturer ID' : 'ভোল্টেজ'}</Form.Label>
                                                    </div>
                                                    <SolInput
                                                        type={"text"}
                                                        name={"manufacturerId"}
                                                        placeholder={language === 'EN' ? 'Enter Manufacturer ID' : 'উদাহরণঃ YUIHJYT@#$'}
                                                        autoComplete={"off"}
                                                        ref={register()}
                                                    />
                                                    {errors.manufacturerId && <div className="text-danger">
                                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.manufacturerId.message}</div>}
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </>:<>
                                        <div className={'row g-3'}>
                                            <div className={'col-md-4'}>
                                                <Form.Group>
                                                    <div>
                                                        <Form.Label>{language === 'EN' ? 'Manufacturer ID' : 'ভোল্টেজ'}</Form.Label>
                                                    </div>
                                                    <SolInput
                                                        type={"text"}
                                                        name={"manufacturerId"}
                                                        placeholder={language === 'EN' ? 'Enter Manufacturer ID' : 'উদাহরণঃ YUIHJYT@#$'}
                                                        autoComplete={"off"}
                                                        ref={register()}
                                                    />
                                                    {errors.manufacturerId && <div className="text-danger">
                                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.manufacturerId.message}</div>}
                                                </Form.Group>
                                            </div>
                                            <div className={'col-md-4'}>
                                                <Form.Group>
                                                    <div>
                                                        <Form.Label>{language === 'EN'? 'Capacity':'সক্ষমতা'}</Form.Label>
                                                    </div>
                                                    <Controller
                                                        control={control}
                                                        name={"capacity"}
                                                        render={( { onChange, onBlur, value, name, ref },
                                                                  { invalid, isTouched, isDirty }) => (

                                                            <Select
                                                                placeholder={'Select capacity in Ah'}
                                                                classNamePrefix="react-select-sol-style"
                                                                maxMenuHeight={200}
                                                                value={value}
                                                                isDisabled={props.listLoading}
                                                                isLoading={props.listLoading}
                                                                isClearable={true}
                                                                options={capacities}
                                                                isSearchable={true}
                                                                control={control}
                                                                inputRef={ref}
                                                                onChange={(selected, {action}) => {
                                                                    onChange(selected)
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.capacity && <div className="text-danger">
                                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.capacity.message}</div>}
                                                </Form.Group>
                                            </div>
                                            <div className={'col-md-4'}>
                                                <Form.Group>
                                                    <div>
                                                        <Form.Label>{language === 'EN'? 'Voltage':'ভোল্টেজ'}</Form.Label>
                                                    </div>
                                                    <Controller
                                                        control={control}
                                                        name={"voltage"}
                                                        render={( { onChange, onBlur, value, name, ref },
                                                                  { invalid, isTouched, isDirty }) => (
                                                            <Select
                                                                placeholder={'Select Voltage in V'}
                                                                classNamePrefix="react-select-sol-style"
                                                                maxMenuHeight={200}
                                                                value={value}
                                                                isDisabled={props.listLoading}
                                                                isLoading={props.listLoading}
                                                                isClearable={true}
                                                                options={voltages}
                                                                isSearchable={true}
                                                                control={control}
                                                                inputRef={ref}
                                                                onChange={(selected, {action}) => {
                                                                    onChange(selected)
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.voltage && <div className="text-danger">
                                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.voltage.message}</div>}
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className={'row g-3'}>
                                            <div className={'col-md-4'}>
                                                <Form.Group>
                                                    <div>
                                                        <Form.Label>{language === 'EN'? 'Battery Serial':'ব্যাটারী সিরিয়াল'}</Form.Label>
                                                    </div>
                                                    <SolInput
                                                        type={"text"}
                                                        name={"batterySerial"}
                                                        placeholder={language === 'EN'? 'Ex: 55000001':'উদাহরণঃ 55000001'}
                                                        autoComplete={"off"}
                                                        ref={register({
                                                            validate: {
                                                                isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || invalidBatterySerial
                                                            }
                                                        })}
                                                    />
                                                    {errors.batterySerial && <div className="text-danger">
                                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.batterySerial.message}</div>}
                                                </Form.Group>
                                            </div>
                                            <div className={'col-md-4'}>
                                                <Form.Group>
                                                    <div>
                                                        <Form.Label>{language === 'EN'? 'Dongle Serial':'ডঙ্গল সিরিয়াল'}</Form.Label>
                                                    </div>
                                                    <SolInput
                                                        type={"text"}
                                                        name={"dongleSerial"}
                                                        placeholder={language === 'EN'? 'Ex: 44000001':'উদাহরণঃ 44000001'}
                                                        autoComplete={"off"}
                                                        ref={register({
                                                            validate: {
                                                                isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || invalidDongleSerial
                                                            }
                                                        })}
                                                    />
                                                    {errors.dongleSerial && <div className="text-danger">
                                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.dongleSerial.message}</div>}
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </>}
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="warning" size={'sm'} type={"submit"}
                                                    disabled={props.listLoading}>
                                                {language === 'EN'? 'Apply filter':'তালিকায় খুঁজুন'}
                                            </Button>
                                            <Button variant="outline-dark" size={'sm'} type={'button'}
                                                    disabled={props.listLoading}
                                                    onClick={()=> {resetFilter()}} className={'ml-3'}>
                                                {language === 'EN'? 'Reset filter':'প্রাথমিক অবস্থায় ফিরে যান'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.listLoading? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন ব্যাটারী নেই'}
                                columns={batteryListColumns}
                                data={batteryList}
                                showToolbar={false}
                                asyncPagination={true}
                                isLoading={props.tableLoading}
                                count={props.batteryList?.count}
                                itemsPerPage={props.batteryList?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                                actionColumnIndex={-1}
                                overrideCustomActions={true}
                                actions={(hasAccessToViewGraph && hasAccessToUpdateBattery) ? [
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'info'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                                    View Graph
                                                </Button>
                                            },
                                            onClick: (e, row) => viewBatteryLog(row),
                                        })
                                    },
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'warning'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                                    Update
                                                </Button>
                                            },
                                            onClick: (e, row) => goToUpdateBattery(row),
                                        })
                                    }
                                ]:hasAccessToViewGraph? [
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'info'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                                    View Graph
                                                </Button>
                                            },
                                            onClick: (e, row) => viewBatteryLog(row),
                                        })
                                    }
                                ]:hasAccessToUpdateBattery? [
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'warning'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                                    Update
                                                </Button>
                                            },
                                            onClick: (e, row) => goToUpdateBattery(row),
                                        })
                                    }
                                ]:[]}
                            /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Collecting battery list ..': 'ব্যাটারীর তালিকা সংগ্রহ করা হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    );
};

BatteryList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        role: state.auth.role,
        isLoading: state.batteryReducer.isLoading,
        tableLoading: state.batteryReducer.tableLoading,
        statusCode: state.batteryReducer.statusCode,
        batteryList: state.batteryReducer.batteryList,
        filterTriggeredLoading: state.batteryReducer.filterTriggeredLoading,
        listLoading: state.batteryReducer.listLoading,
        iconLoading: state.batteryReducer.iconLoading,
        batterySuppliersDropDownLoading: state.batteryReducer.batterySuppliersDropDownLoading,
        batterySuppliers: state.batteryReducer.batterySuppliers,
        batteryListForExport: state.batteryReducer.batteryListForExport,
        collectingBatteryListForExport: state.batteryReducer.collectingBatteryListForExport
    }
}

export default connect(mapStateToProps, actions)(withRoles(BatteryList));
