import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_ORG_LIST: ()=>({
        [CALL_API]: {
            endpoint: '/organizations',
            method: 'GET',
            types: [
                actionTypes.GET_ORG_LIST_REQUEST,
                actionTypes.GET_ORG_LIST_SUCCESS,
                actionTypes.GET_ORG_LIST_FAILURE
            ]
        }
    }),
};
