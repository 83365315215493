export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',
    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',

    GET_DEVICE_LIST_REQUEST: 'GET_DEVICE_LIST_REQUEST',
    GET_DEVICE_LIST_SUCCESS: 'GET_DEVICE_LIST_SUCCESS',
    GET_DEVICE_LIST_FAILURE: 'GET_DEVICE_LIST_FAILURE',

    GET_DEVICE_LIST_FOR_EXPORT_REQUEST: 'GET_DEVICE_LIST_FOR_EXPORT_REQUEST',
    GET_DEVICE_LIST_FOR_EXPORT_SUCCESS: 'GET_DEVICE_LIST_FOR_EXPORT_SUCCESS',
    GET_DEVICE_LIST_FOR_EXPORT_FAILURE: 'GET_DEVICE_LIST_FOR_EXPORT_FAILURE',

    GET_FIRMWARE_LIST_REQUEST: 'GET_DEVICE_LIST_REQUEST',
    GET_FIRMWARE_LIST_SUCCESS: 'GET_DEVICE_LIST_SUCCESS',
    GET_FIRMWARE_LIST_FAILURE: 'GET_DEVICE_LIST_FAILURE',

    ADD_DEVICE_REQUEST: 'ADD_DEVICE_REQUEST',
    ADD_DEVICE_SUCCESS: 'ADD_DEVICE_SUCCESS',
    ADD_DEVICE_FAILURE: 'ADD_DEVICE_FAILURE',

    ADD_MULTIPLE_DEVICES_REQUEST: 'ADD_MULTIPLE_DEVICES_REQUEST',
    ADD_MULTIPLE_DEVICES_SUCCESS: 'ADD_MULTIPLE_DEVICES_SUCCESS',
    ADD_MULTIPLE_DEVICES_FAILURE: 'ADD_MULTIPLE_DEVICES_FAILURE',

    GET_GARAGES_REQUEST: 'GET_GARAGES_REQUEST',
    GET_GARAGES_SUCCESS: 'GET_GARAGES_SUCCESS',
    GET_GARAGES_FAILURE: 'GET_GARAGES_FAILURE',

    CHANGE_RENT_PER_DAY_FOR_DEVICE_REQUEST: "CHANGE_RENT_PER_DAY_FOR_DEVICE_REQUEST",
    CHANGE_RENT_PER_DAY_FOR_DEVICE_SUCCESS: "CHANGE_RENT_PER_DAY_FOR_DEVICE_SUCCESS",
    CHANGE_RENT_PER_DAY_FOR_DEVICE_FAILURE: "CHANGE_RENT_PER_DAY_FOR_DEVICE_FAILURE",

    GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE_REQUEST: "GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE_REQUEST",
    GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE_SUCCESS: "GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE_SUCCESS",
    GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE_FAILURE: "GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE_FAILURE",

    CLEAN_UP_RENT_CHANGE_MODAL: "CLEAN_UP_RENT_CHANGE_MODAL",

    GET_LAST_RENT_BY_DEVICE_REQUEST: "GET_LAST_RENT_BY_DEVICE_REQUEST",
    GET_LAST_RENT_BY_DEVICE_SUCCESS: "GET_LAST_RENT_BY_DEVICE_SUCCESS",
    GET_LAST_RENT_BY_DEVICE_FAILURE: "GET_LAST_RENT_BY_DEVICE_FAILURE",

    CLEAN_UP_LAST_RENT_MODAL: "CLEAN_UP_LAST_RENT_MODAL",

    CHANGE_RENTABLE_STATUS_REQUEST: "CHANGE_RENTABLE_STATUS_REQUEST",
    CHANGE_RENTABLE_STATUS_SUCCESS: "CHANGE_RENTABLE_STATUS_SUCCESS",
    CHANGE_RENTABLE_STATUS_FAILURE: "CHANGE_RENTABLE_STATUS_FAILURE",

    COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY_REQUEST: "COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY_REQUEST",
    COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY_SUCCESS: "COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY_SUCCESS",
    COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY_FAILURE: "COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY_FAILURE",

    ASSIGN_GARAGE_REQUEST: "ASSIGN_GARAGE_REQUEST",
    ASSIGN_GARAGE_SUCCESS: "ASSIGN_GARAGE_SUCCESS",
    ASSIGN_GARAGE_FAILURE: "ASSIGN_GARAGE_FAILURE",

    COLLECT_GARAGE_LIST_FOR_ASSIGNMENT_REQUEST: "COLLECT_GARAGE_LIST_FOR_ASSIGNMENT_REQUEST",
    COLLECT_GARAGE_LIST_FOR_ASSIGNMENT_SUCCESS: "COLLECT_GARAGE_LIST_FOR_ASSIGNMENT_SUCCESS",
    COLLECT_GARAGE_LIST_FOR_ASSIGNMENT_FAILURE: "COLLECT_GARAGE_LIST_FOR_ASSIGNMENT_FAILURE",

    RETURN_TO_INITIAL_STATE_FOR_SMART_BATTERY_CREATION: "RETURN_TO_INITIAL_STATE_FOR_SMART_BATTERY_CREATION"
};
