import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import {DataTable, SolInput} from '../../../../../components';
import { actions } from '../../../actions';
import { actions as commonActions } from '../../../../commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import moment from "moment/moment";
import {toast} from "react-toastify";
import {Button, Spinner, Form, Row, Col, OverlayTrigger, Popover} from "react-bootstrap";
import Stack from "@mui/material/Stack";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import {requestCycle} from "../../../../borrowers/utils";
import {showNotifications} from "../../../../../utils/notification";
import {SolTextArea} from "../../../../../components/SolStyledComponents/components";
import {DataTableContainerForLoanDetails} from "../../../utils";
import {DayPickerRangeController} from "react-dates";


const PauseOrResumeContract = ({ contractDetails, closeTheProcess, updateStatus, actionName, ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [language, setLanguage] = useState("EN");

    const [date, setDate] = useState(null);
    const [dateToShow, setDateToShow] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');

    const {
        PAUSE_OR_RESUME_CONTRACT,
        COLLECT_LOAN_DETAILS,
        CLEAR_STATES_RELATED_TO_PAUSE_OR_RESUME_CONTRACT_COMPONENT,
        RETURN_TO_INITIAL_STATE
    } = props;

    useEffect(() => {
        setLanguage(props.language)

        return ()=> {
            CLEAR_STATES_RELATED_TO_PAUSE_OR_RESUME_CONTRACT_COMPONENT()
        }
    }, [])

    const updateContractStatus = (data) => {
        let payload = {}
        if (actionName === 'Pause') {
            // Pausing
            payload = {
                "contract_id": contractDetails.contract_id,
                "pausing_reason": data.reason
            }
        } else {
            // Resuming
            payload = {
                "new_start_date": date.format("YYYY-MM-DD"),
                "contract_id": contractDetails.contract_id,
                "resuming_reason": data.reason
            }
        }
        PAUSE_OR_RESUME_CONTRACT(payload, actionName)
    }

    useEffect(() => {
        if (props.contractPausedOrResumed && props.contractPausedOrResumed === requestCycle.success) {
            toast.success(actionName === "Pause"? "Contract is paused successfully!":"Contract is resumed successfully!")
            closeTheProcess()
            COLLECT_LOAN_DETAILS(contractDetails.contract_id)
            RETURN_TO_INITIAL_STATE()
        }
    }, [props.contractPausedOrResumed])

    useEffect(() => {
        if (props.errorRelatedToContractPauseOrResume) {
            showNotifications('error', props.errorRelatedToContractPauseOrResume)
        }
    }, [props.errorRelatedToContractPauseOrResume])

    const disableDates = (current) => {
        const today = moment()
        if (current.isBefore(today, 'day')) {
            return true
        }
        return false
    }

    const onDatesChange = ({startDate, endDate}) => {
        if (startDate && !endDate) {
            setDate(startDate)
        } else if (startDate && endDate) {
            setDate(endDate)
        }
    }

    useEffect(() => {
        if (date) {
            setDateToShow(moment(date).format("MMMM DD, YYYY"))
            clearErrors("invoice_generation_date")
        } else {
            setDateToShow('')
        }
    }, [date])

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'} className={'custom-style'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={date}
                    endDate={date}
                    numberOfMonths={1}
                    minimumNights={0}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                    firstDayOfWeek={0}
                    isOutsideRange={disableDates}
                    initialVisibleMonth={() => moment()}
                />
            </Popover.Content>
        </Popover>
    )

    return (
        <>
            <Form onSubmit={handleSubmit(updateContractStatus)}>
                {actionName === "Resume"? <>
                    <div className={'row g-3'}>
                        <div className={'col-md-12'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><h6><strong>Generate Invoice on</strong> <span className="required text-danger">*</span></h6></Form.Label>
                                </div>
                                <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                overlay={popover}>
                                    <SolInput
                                        name={'invoice_generation_date'}
                                        readOnly={true}
                                        value={dateToShow}
                                        ref={register({
                                            required: 'Above information is required!'
                                        })}
                                        placeholder={"Select a date"}
                                    />
                                </OverlayTrigger>
                                {errors.invoice_generation_date && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.invoice_generation_date.message}</div>}
                            </Form.Group>
                        </div>
                    </div>
                </>:''}
                <div className={'row g-3'}>
                    <div className={'col-md-12'}>
                        <Form.Group>
                            <div>
                                <Form.Label><h6><strong>Reason</strong> <span className="required text-danger">*</span></h6></Form.Label>
                            </div>
                            <SolTextArea
                                name={"reason"}
                                rows={"5"}
                                placeholder={actionName === "Resume"? "Reason for resuming":"Reason for pausing"}
                                autoComplete={"off"}
                                disabled={props.updatingContractStatus}
                                ref={register({
                                    required: "Above information is required!"
                                })}
                            />
                            {errors.reason && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.reason.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <hr/>
                <div className={'row mt-3'}>
                    <div className={"col-md-12"}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Button variant={"outline-dark"} type={"button"} size={'lg'}
                                    disabled={props.updatingContractStatus}
                                    onClick={closeTheProcess}>
                                Cancel
                            </Button>
                            <Button variant="warning" size={'lg'} type="submit" disabled={props.updatingContractStatus}>
                                {props.updatingContractStatus? <><Spinner animation={'border'} size={'sm'} variant={'light'}/></>:null} {actionName}
                            </Button>
                        </Stack>
                    </div>
                </div>
            </Form>
        </>
    )
}

PauseOrResumeContract.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        contractPausedOrResumed: state.contractManagementReducer.contractPausedOrResumed,
        updatingContractStatus: state.contractManagementReducer.updatingContractStatus,
        errorRelatedToContractPauseOrResume: state.contractManagementReducer.errorRelatedToContractPauseOrResume
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(PauseOrResumeContract);