import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import EvList from "./views/evList/evList";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const EvsRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/evs"}
                to={"/evs/list"}
            />

            {/* Routes to Ev List*/}
            <SecuredRoute
                path={"/evs/list"}
                component={EvList}
                service={getServiceName('garageService')}
                feature={getFeatureName('evFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
