import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_SMART_DONGLE_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/devices/dongle`, filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_SMART_DONGLE_LIST_REQUEST,
                actionTypes.GET_SMART_DONGLE_LIST_SUCCESS,
                actionTypes.GET_SMART_DONGLE_LIST_FAILURE
            ]
        }
    }),

    GET_FIRMWARE_LIST: ()=>({
        [CALL_API]: {
            endpoint: '/firmwares',
            backend_slug: 'device_management',
            method: 'GET',
            types: [
                actionTypes.GET_FIRMWARE_LIST_REQUEST,
                actionTypes.GET_FIRMWARE_LIST_SUCCESS,
                actionTypes.GET_FIRMWARE_LIST_FAILURE
            ]
        }
    }),

    GET_HARDWARE_LIST: (payload = {}) => ({
        [CALL_API]: {
            endpoint: '/hardwares',
            method: 'POST',
            body: payload,
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_HARDWARE_LIST_REQUEST,
                actionTypes.GET_HARDWARE_LIST_SUCCESS,
                actionTypes.GET_HARDWARE_LIST_FAILURE,
            ]
        }
    }),

    ADD_DONGLE: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/devices/dongle',
            method: 'POST',
            backend_slug: 'device_management',
            body : payload,
            types: [
                actionTypes.ADD_DONGLE_REQUEST,
                actionTypes.ADD_DONGLE_SUCCESS,
                actionTypes.ADD_DONGLE_FAILURE,
            ]
        }
    }),

    UPDATE_DONGLE: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/devices/dongle',
            method: 'PUT',
            backend_slug: 'device_management',
            body : payload,
            types: [
                actionTypes.UPDATE_DONGLE_REQUEST,
                actionTypes.UPDATE_DONGLE_SUCCESS,
                actionTypes.UPDATE_DONGLE_FAILURE
            ]
        }
    }),

    UPDATE_BULK_SMART_DONGLE_INFO: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/devices/dongle/batch_update',
            method: 'POST',
            backend_slug: 'device_management',
            body : payload,
            types: [
                actionTypes.UPDATE_BULK_SMART_DONGLE_INFO_REQUEST,
                actionTypes.UPDATE_BULK_SMART_DONGLE_INFO_SUCCESS,
                actionTypes.UPDATE_BULK_SMART_DONGLE_INFO_FAILURE
            ]
        }
    }),

    GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/devices/dongle/batch_upload',
            method: 'POST',
            backend_slug: 'device_management',
            body : payload,
            types: [
                actionTypes.GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES_REQUEST,
                actionTypes.GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES_SUCCESS,
                actionTypes.GENERATE_SERIAL_NUMBERS_FOR_SMART_DONGLES_FAILURE
            ]
        }
    }),

    COLLECT_CHANGE_HISTORY: (filter) => ({
        [CALL_API]: {
            endpoint: paramsHandler(`/change_log`, filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.COLLECT_CHANGE_HISTORY_REQUEST,
                actionTypes.COLLECT_CHANGE_HISTORY_SUCCESS,
                actionTypes.COLLECT_CHANGE_HISTORY_FAILURE,
            ]
        }
    }),

    UPDATE_ORGANIZATIONAL_ACCESS: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/dongles/assign_to_organization',
            method: 'POST',
            backend_slug: 'device_management',
            body : payload,
            types: [
                actionTypes.UPDATE_ORGANIZATIONAL_ACCESS_REQUEST,
                actionTypes.UPDATE_ORGANIZATIONAL_ACCESS_SUCCESS,
                actionTypes.UPDATE_ORGANIZATIONAL_ACCESS_FAILURE
            ]
        }
    }),

    CLEAN_UP_STATES_RELATED_TO_ACCESS_MANAGEMENT_MODAL: () => ({ type: actionTypes.CLEAN_UP_STATES_RELATED_TO_ACCESS_MANAGEMENT_MODAL }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
