import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for rent log*/
const initialState= {
    totalPaid: undefined
};

/*Reducer for Rent Log's redux store*/
export const notificationsReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.COLLECT_TOTAL_PAYMENT_REQUEST:
            return {
                ...state,
                totalPaid: undefined,
                collectingPaymentInfo: true
            };
        case actionTypes.COLLECT_TOTAL_PAYMENT_SUCCESS:
            return {
                ...state,
                totalPaid: action.payload,
                collectingPaymentInfo: false
            };
        case actionTypes.COLLECT_TOTAL_PAYMENT_FAILURE:
            return {
                ...state,
                collectingPaymentInfo: false
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
