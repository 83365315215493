import React, {Fragment} from "react";
import DateTimeFormat from "../../utils/dateFormat/DateTimeFormat";
import styled from "styled-components";
import {Spinner} from "react-bootstrap";
import {SolTypography} from "../../components/utilityComponents/SOlTypography";
import {isNumber} from "../../utils/utilityFunctions";


export const DataTableContainer = styled.div`
  padding: 4rem 0 0 0;
  min-height: 25rem;
  height: auto;
`

export const requestCycle = {
    success: 'success',
    pending: 'pending',
    failed: 'failed',
}

export const layout = {
    "title": {
        // "text": "<b>Income inequality,by type of income measured,1979-2007</b>",
        "font": {
            "color": "",
            "family": "Arial, sans-serif",
            "size": 14
        }
    },
    "font": {
        "family": "Arial, sans-serif",
        "size": 12,
        "color": "#000"
    },
    "showlegend": true,
    "autosize": true,
    // "width": 750,
    "height": 500,
    "xaxis": {
        "title": {
            "text": "<b>Date and Time </b>",
            "font": {
                "color": "",
                "family": "Arial, sans-serif",
                "size": 14
            }
        },

    },
    "yaxis": {
        "title": {
            "text": "Gini Index"
        },
    },
    // "legend": {
    //     "x": -0.002406144799079538,
    //     "y": 1.071535433070866,
    //     "traceorder": "normal",
    //     "font": {
    //         "family": "",
    //         "size": 11,
    //         "color": ""
    //     },
    //     "bgcolor": "rgba(255, 255, 255, 0)",
    //     "bordercolor": "rgba(0, 0, 0, 0)",
    //     "borderwidth": 0.1,
    //     "xanchor": "auto",
    //     "yanchor": "auto"
    // },

    "margin": {
        "l": 70,
        "r": 40,
        "b": 150,
        "t": 90,
        "pad": 2,
        "autoexpand": true
    },
    // "paper_bgcolor": "rgb(245, 245, 247)",
    // "plot_bgcolor": "rgb(245, 245, 247)",
    // "hovermode": "x",
    // "dragmode": "zoom",
    // "barmode": "stack",
    // "bargap": 0.2,
    // "bargroupgap": 0,
    // "boxmode": "overlay",
    // "separators": ".",
    // "hidesources": false
}