import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import {organizationListColumns} from "./organizationListColumns";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Breadcrumb, Button, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner, LoadingSpinner} from '../../utils'
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const OrganizationList = (props) => {
    const [showFilter, setShowFilter] = useState(false);
    const [orgList, setOrgList] = useState([]);
    const [page, setPage] = useState(null);
    const [filterParameters, setFilterParameters] = useState(null);
    const subHeader = useSubheader();
    const [searchText, setSearchText] = useState(null);
    const [projectedParams, setProjectedParams] = useState(null);
    const { b2bOrgGuid , isSolshare } = props;

    const {
        GET_ORG_LIST
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        subHeader.setActionButtons(null)
        GET_ORG_LIST();
    }, [GET_ORG_LIST]);


    useEffect(() => {
        const orgList = props.orgList;
        if (orgList && orgList.length > -1) {
            setOrgList(orgList.map((org, index) => {

                return {
                    ...org,
                    name: org.name,
                    phone: org.phone_number,
                    address: org.address
                }
            }))
        }
    }, [props.orgList]);

    useEffect(() => {
        let temp = null;

        try {
            temp = parseParams(props.history?.location?.search);

            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                if ( props.isSolshare === false && props.b2bOrgGuid ){
                    delete temp.organization_guid;
                    Object.assign(temp, {organization_guid: props.b2bOrgGuid })
                }
                setProjectedParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    }, [props.history.location.search])

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        props.GET_ORG_LIST({page: newPage});
    }


    return (
        <div>
        <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                        serverError={false} permissionDenied={false} pageTitle={"Organizations"}
                        statusCode={props.statusCode}>

            <Toolbar>
                <Toolbar.Title>
                    <h1><b>Organizations</b></h1>
                </Toolbar.Title>
            </Toolbar>
            <DataTableContainer>
                {props.listLoading !== true ? <DataTable
                    columns={organizationListColumns}
                    data={orgList}
                    showToolbar={false}
                    asyncPagination={true}
                    isLoading={props.tableLoading}
                    count={props.orgList?.count}
                    itemsPerPage={props.orgList?.page_size}
                    onChangePage={onChangePage}
                    page={page}
                    // onRowClick={(e,rowData)=>onRowClick(rowData)}
                /> : <LoadingSpinner/>}
            </DataTableContainer>

        </ContentWrapper>

        <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
    </div>
    );
};

OrganizationList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        isLoading: state.organizationsReducer.isLoading,
        tableLoading: state.organizationsReducer.tableLoading,
        statusCode: state.organizationsReducer.statusCode,
        orgList: state.organizationsReducer.orgList,
        listLoading: state.organizationsReducer.listLoading
    }
}

export default connect(mapStateToProps, actions)(withRoles(OrganizationList));
