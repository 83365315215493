import {Redirect, Switch} from "react-router-dom";
import React from "react";
import Deposits from "./views/deposits/deposits";
import SecuredRoute from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const DepositsRouter =(props)=> {

    return (
        <Switch>
            <Redirect
                exact={"true"}
                from={"/deposits"}
                to={"/deposits/list"}
            />

            {/* Routes to deposits List*/}
            <SecuredRoute
                path={"/deposits/list"}
                component={Deposits}
                service={getServiceName('transactionService')}
                feature={getFeatureName('depositFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
