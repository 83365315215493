import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
    successMessage: undefined,
    errorMessage: undefined,
    tokenGenerationInProgress: undefined,
    tokenCancelled: undefined,
    successMessageForTokenCancel: undefined,
    tokenCancelletionInProgress: undefined
};

/*Reducer for Token's redux store*/
export const tokensReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_TOKEN_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                successMessage: undefined,
                errorMessage: undefined,
                tokenCancelled: undefined,
                successMessageForTokenCancel: undefined,
                errorMessageForTokenCancel: undefined
            };

        case actionTypes.GET_TOKEN_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                tokenList: action.payload
            };

        case actionTypes.GET_TOKEN_LIST_FAILURE:
            toast.error("Can't load the token list, because: " + action.payload.error.msg);
            return {
                ...state,
                listLoading: false
            };
            //post Data
        case actionTypes.GENERATE_TOKEN_REQUEST:
            return {
                ...state,
                tokenGenerated: undefined,
                tokenGenerationInProgress: true
            };
        case actionTypes.GENERATE_TOKEN_SUCCESS:
            toast.success("Token is generated successfully!");
            return {
                ...state,
                tokenGenerated: requestCycle.success,
                successMessage: action.payload,
                tokenGenerationInProgress: false
            };
        case actionTypes.GENERATE_TOKEN_FAILURE:
            return {
                ...state,
                tokenGenerated: requestCycle.failed,
                errorMessage: action.payload.error,
                tokenGenerationInProgress: false
            };

        case actionTypes.CANCEL_TOKEN_REQUEST:
            return {
                ...state,
                tokenCancelled: undefined,
                tokenCancelletionInProgress: true
            };
        case actionTypes.CANCEL_TOKEN_SUCCESS:
            return {
                ...state,
                tokenCancelled: requestCycle.success,
                successMessageForTokenCancel: action.payload,
                tokenCancelletionInProgress: false
            };
        case actionTypes.CANCEL_TOKEN_FAILURE:
            return {
                ...state,
                tokenCancelled: requestCycle.failed,
                errorMessageForTokenCancel: action.payload.error,
                tokenCancelletionInProgress: false
            };

        default:
            return state;
    }
};
