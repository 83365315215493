import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as routerHelpers from "../../../_metronic/_helpers/RouterHelpers";
import {actionTypes} from './actionTypes';
import {actions} from './actions';
import Api from "./Api";
import { parsePhoneNumberFromString as parseMobile } from 'libphonenumber-js/mobile';
import {toast} from "react-toastify";
import {takeEvery, put} from 'redux-saga/effects';
import { signInWithGoogle, getFirebaseIdToken  } from "./firebase_v9";
import { SET_ACCESS_TOKEN } from "redux-refresh-token";
import { GoogleAuthProvider } from "firebase/auth";

/*Initial State object*/
const initialAuthState = {
  user: undefined,
  authToken: undefined,
  confirmation: undefined,
  Token: undefined,
  isError: false,
  errorMsg: '',
  access_token: undefined,
  refresh_token: undefined,
  role: '',
  disabled: false,
  is_solshare: undefined,
  organization_guid: undefined,
  requesting_for_auth: false,
  not_authenticated: true,
  authentication_request_response: null,
  logoutInProgress: false
};

/*Reducer for login page*/
export const authReducer = persistReducer(
    { storage, key: "solshare-auth", whitelist: ["user", "authorizations", "language", "authToken", "Token", "access_token",
        "refresh_token", "role", "user_guid", 'is_solshare', 'organization_guid', 'is_solshare_user' ] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.LOGIN_REQUEST:
          return {
            ...state,
            requesting_for_auth: true,
          };

        case actionTypes.LOGIN_SUCCESS:
          return {
            ...state,
            requesting_for_auth: false,
            not_authenticated: false,
            access_token: action.payload['access_token'],
            refresh_token: action.payload['refresh_token'],
            user: action.payload,
            role: action.payload['role'],
            authorizations: action.payload.authorizations? action.payload.authorizations:{},
            language: action.payload.language? action.payload.language: 'EN',
            authentication_request_response: action.payload,
            organization_guid: action.payload['organization_guid'],
            // Following static guid is same at staging and production
            is_solshare_user: action.payload['organization_guid'] === '31f936cf-4bf9-4323-91e6-0a8de3c27718'
          };

        case actionTypes.LOGIN_FAILURE:
          return {
            ...state,
            requesting_for_auth: false,
            not_authenticated: true,
            authentication_request_response: action.payload
          };

        case actionTypes.LOGOUT_REQUEST: {
          return {
            ...state,
            logoutInProgress: true
          }
        }
        case actionTypes.LOGOUT_SUCCESS: {
          routerHelpers.forgotLastLocation();
          storage.removeItem('persist:solshare-auth')
          return initialAuthState;
        }
        case actionTypes.LOGOUT_FAILURE: {
          toast.error("Failed to logout!")
          return {
            ...state,
            logoutInProgress: false
          }
        }
        case actionTypes.VERIFY_AUTH: {
          return {...state};
        }
        case actionTypes.REFRESH_AUTH_TOKEN: {
          return { ...state };
        }
        case actionTypes.SET_TOKEN: {
          const { user, access_token, refresh_token } = action.payload;
          return { ...state, user: user, access_token: access_token, refresh_token: refresh_token, loading: false };
        }
        case "SET_ACCESS_TOKEN": {
          const {access_token, refresh_token} = action
          return { ...state , access_token: access_token, refresh_token: refresh_token};
        }
        case actionTypes.SET_LANGUAGE: {
          return { ...state , language: action.language? action.language: 'EN',};
        }

        case "USER_FETCH_SUCCEEDED": {
          return { ...state };
        }

        case "LOGGING_IN_WITH_GMAIL": {
          return { ...state};
        }

        default:
          return state;
      }
    }
);



function* signInWithEmail(action) {
  let id_token = undefined;
  let continue_login = true;
  // yield put({ type: "USER_FETCH_SUCCEEDED"});
  yield signInWithGoogle().then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    const id_token = credential.idToken;
    // The signed-in user info.
    const user = result.user;

    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorMessage)
    continue_login= false;

    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });

  if(continue_login){
    yield getFirebaseIdToken().then((Idtoken) => {
      id_token = Idtoken
    }).catch((error) => {
      console.log(error)
    })

    yield put(actions.LOGIN_TO_SYSTEM_WITH_GOOGLE_ID_TOKEN(id_token));
  }
}





export function* mySaga() {
  yield takeEvery("LOGGING_IN_WITH_GMAIL", signInWithEmail);
}


