export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_SUMMARY_INFO_REQUEST: 'COLLECT_SUMMARY_INFO_REQUEST',
    COLLECT_SUMMARY_INFO_SUCCESS: 'COLLECT_SUMMARY_INFO_SUCCESS',
    COLLECT_SUMMARY_INFO_FAILURE: 'COLLECT_SUMMARY_INFO_FAILURE',

    COLLECT_SUMMARY_INFO_TO_EXPORT_REQUEST: 'COLLECT_SUMMARY_INFO_TO_EXPORT_REQUEST',
    COLLECT_SUMMARY_INFO_TO_EXPORT_SUCCESS: 'COLLECT_SUMMARY_INFO_TO_EXPORT_SUCCESS',
    COLLECT_SUMMARY_INFO_TO_EXPORT_FAILURE: 'COLLECT_SUMMARY_INFO_TO_EXPORT_FAILURE',

    COLLECT_GARAGE_LIST_REQUEST: 'COLLECT_GARAGE_LIST_REQUEST',
    COLLECT_GARAGE_LIST_SUCCESS: 'COLLECT_GARAGE_LIST_SUCCESS',
    COLLECT_GARAGE_LIST_FAILURE: 'COLLECT_GARAGE_LIST_FAILURE',

    COLLECT_DRIVER_DETAILS_REQUEST: 'COLLECT_DRIVER_DETAILS_REQUEST',
    COLLECT_DRIVER_DETAILS_SUCCESS: 'COLLECT_DRIVER_DETAILS_SUCCESS',
    COLLECT_DRIVER_DETAILS_FAILURE: 'COLLECT_DRIVER_DETAILS_FAILURE',

    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',
};
