import {actionTypes} from "./actionTypes";
import {requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    hardwareList: undefined,
    hardwareListCollectionError: undefined,
    isLoading: false,
    successMessage: undefined,
    firmwares: undefined,
    errorRelatedToFirmwareCollection: undefined,
    hardwareCreated: undefined,
    firmwareCreated: undefined,
    errorMessageForFirmwareCreation: undefined,
    firmwareUpdated: undefined,
    errorMessageForFirmwareUpdate: undefined,
    hardwareUpdated: undefined,
    hardwareInfoSubmitting: undefined,
    errorMessageForHardwareUpdate: undefined,
    otaList: undefined,
    errorForOtaListCollection: undefined,
    compatibilities: undefined,
    errorRelatedToCompatibilitiesCollection: undefined,
    dongles: undefined,
    errorMessageForDongleCollection: undefined,
    donglesAssigned: undefined,
    errorMessageForDongleAssignment: undefined
};

const initialStateForFwCompatibilitiesModal= {
    compatibilities: undefined,
    errorRelatedToCompatibilitiesCollection: undefined
};

const initialStateForAssignDonglesComponent= {
    dongles: undefined,
    errorMessageForDongleCollection: undefined,
    donglesAssigned: undefined,
    errorMessageForDongleAssignment: undefined
};

/*Reducer for Device's redux store*/
export const otaReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_HARDWARE_LIST_REQUEST:
            return {
                ...state,
                hardwareListCollectionInProgress: true,
                hardwareList: undefined,
                hardwareListCollectionError: undefined,
                hardwareCreated: undefined,
                hardwareUpdated: undefined
            };
        case actionTypes.GET_HARDWARE_LIST_SUCCESS:
            return {
                ...state,
                hardwareListCollectionInProgress: false,
                hardwareList: action.payload
            };
        case actionTypes.GET_HARDWARE_LIST_FAILURE:
            // toast.error("Can't load the hardware list, because: " + action.payload.error.msg);
            return {
                ...state,
                hardwareListCollectionInProgress: false,
                hardwareListCollectionError: action.payload.error
            };

        case actionTypes.GET_FIRMWARE_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                firmwares: undefined,
                errorRelatedToFirmwareCollection: undefined,
                firmwareCreated: undefined,
                firmwareUpdated: undefined
            };
        case actionTypes.GET_FIRMWARE_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                firmwares: action.payload
            };
        case actionTypes.GET_FIRMWARE_LIST_FAILURE:
            // toast.error("Can't get the firmware list! Please contact with admin.");
            return {
                ...state,
                listLoading: false,
                errorRelatedToFirmwareCollection: action.payload.error
            };

        case actionTypes.GET_COMPATIBILITIES_OF_A_FIRMWARE_REQUEST:
            return {
                ...state,
                collectingCompatibilities: true,
                compatibilities: undefined,
                errorRelatedToCompatibilitiesCollection: undefined
            };
        case actionTypes.GET_COMPATIBILITIES_OF_A_FIRMWARE_SUCCESS:
            return {
                ...state,
                collectingCompatibilities: false,
                compatibilities: action.payload
            };
        case actionTypes.GET_COMPATIBILITIES_OF_A_FIRMWARE_FAILURE:
            return {
                ...state,
                collectingCompatibilities: false,
                errorRelatedToCompatibilitiesCollection: action.payload.error
            };

        case actionTypes.GET_FIRMWARE_UPDATE_LIST_REQUEST:
            return {
                ...state,
                collectingOtaList: true,
                otaList: undefined,
                errorForOtaListCollection: undefined
            };
        case actionTypes.GET_FIRMWARE_UPDATE_LIST_SUCCESS:
            return {
                ...state,
                collectingOtaList: false,
                otaList: action.payload,
            };
        case actionTypes.GET_FIRMWARE_UPDATE_LIST_FAILURE:
            toast.error("Can't load the OTA list! Please contact with admin.")
            return {
                ...state,
                collectingOtaList: false,
                errorForOtaListCollection: action.payload.error
            };

        case actionTypes.CREATE_FIRMWARE_LIST_REQUEST:
            return {
                ...state,
                firmwareCreated: undefined,
                errorMessageForFirmwareCreation: undefined,
                firmwareInfoSubmitting: true
            };
        case actionTypes.CREATE_FIRMWARE_LIST_SUCCESS:
            return {
                ...state,
                firmwareCreated: requestCycle.success,
                firmwareInfoSubmitting: false
            };
        case actionTypes.CREATE_FIRMWARE_LIST_FAILURE:
            return {
                ...state,
                errorMessageForFirmwareCreation: action.payload.error,
                firmwareInfoSubmitting: false
            };

        case actionTypes.UPDATE_FIRMWARE_REQUEST:
            return {
                ...state,
                firmwareUpdated: undefined,
                errorMessageForFirmwareUpdate: undefined,
                firmwareInfoSubmitting: true
            };
        case actionTypes.UPDATE_FIRMWARE_SUCCESS:
            return {
                ...state,
                firmwareUpdated: requestCycle.success,
                firmwareInfoSubmitting: false
            };
        case actionTypes.UPDATE_FIRMWARE_FAILURE:
            return {
                ...state,
                errorMessageForFirmwareUpdate: action.payload.error,
                firmwareInfoSubmitting: false
            };

        case actionTypes.CREATE_HARDWARE_LIST_REQUEST:
            return {
                ...state,
                hardwareCreated: undefined,
                hardwareInfoSubmitting: true
            };
        case actionTypes.CREATE_HARDWARE_LIST_SUCCESS:
            return {
                ...state,
                hardwareCreated: requestCycle.success,
                hardwareInfoSubmitting: false
            };
        case actionTypes.CREATE_HARDWARE_LIST_FAILURE:
            return {
                ...state,
                hardwareCreated: requestCycle.failed,
                errorMessageForHardwareCreation: action.payload.error.message,
                hardwareInfoSubmitting: false
            };

        case actionTypes.UPDATE_HARDWARE_REQUEST:
            return {
                ...state,
                hardwareUpdated: undefined,
                hardwareInfoSubmitting: true
            };
        case actionTypes.UPDATE_HARDWARE_SUCCESS:
            return {
                ...state,
                hardwareUpdated: requestCycle.success,
                hardwareInfoSubmitting: false
            };
        case actionTypes.UPDATE_HARDWARE_FAILURE:
            return {
                ...state,
                hardwareUpdated: requestCycle.failed,
                errorMessageForHardwareUpdate: action.payload.error.message,
                hardwareInfoSubmitting: false
            };

        case actionTypes.COLLECT_DONGLES_BASED_ON_OTA_ELIGIBILITY_REQUEST:
            return {
                ...state,
                dongles: undefined,
                errorMessageForDongleCollection: undefined,
                collectingDongles: true
            };
        case actionTypes.COLLECT_DONGLES_BASED_ON_OTA_ELIGIBILITY_SUCCESS:
            return {
                ...state,
                dongles: action.payload,
                collectingDongles: false
            };
        case actionTypes.COLLECT_DONGLES_BASED_ON_OTA_ELIGIBILITY_FAILURE:
            return {
                ...state,
                errorMessageForDongleCollection: action.payload.error,
                collectingDongles: false
            };

        case actionTypes.ASSIGN_FW_TO_DONGLES_REQUEST:
            return {
                ...state,
                donglesAssigned: undefined,
                errorMessageForDongleAssignment: undefined,
                dongleAssignmentIsInProgress: true
            };
        case actionTypes.ASSIGN_FW_TO_DONGLES_SUCCESS:
            return {
                ...state,
                donglesAssigned: requestCycle.success,
                dongleAssignmentIsInProgress: false
            };
        case actionTypes.ASSIGN_FW_TO_DONGLES_FAILURE:
            return {
                ...state,
                errorMessageForDongleAssignment: action.payload.error,
                dongleAssignmentIsInProgress: false
            };

        case actionTypes.RETURN_TO_INITIAL:
            return initialState

        case actionTypes.RETURN_TO_INITIAL_STATE_OF_FW_COMPATIBILITIES:
            return {
                ...state,
                ...initialStateForFwCompatibilitiesModal
            }

        case actionTypes.RETURN_TO_INITIAL_STATE_OF_ASSIGN_DONGLES_COMPONENT:
            return {
                ...state,
                ...initialStateForAssignDonglesComponent
            }

        default:
            return state;
    }
};
