import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../../smartDongles/views/components";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {SolInput} from "../../../components/SolStyledComponents/components";
import DataTable from "../../../components/dataTable/DataTable";
import {appEventActions} from "../actions";

import {DataTableContainer} from "../../rentLog/utils";
import {LoadingSpinner} from '../../../components/LoadingSpinnerForDataTable'
import {connect} from "react-redux";
import DateTimeFormat from "../../../utils/dateFormat/DateTimeFormat";
import {Button, Form, OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import Select from "react-select";
import {Controller, useForm} from "react-hook-form";
import {DatePicker} from "@material-ui/pickers";
import {ThemeProvider} from "@material-ui/styles";
import {theme, useStylesForDatePicker} from "../../../utils/muiPickerCustomStyle";
import moment from "moment";
import 'moment/locale/bn';
import 'moment/locale/en-gb';
import {toast} from "react-toastify";
import {DayPickerRangeController} from "react-dates";
import {convertToAnotherLang} from "../../../utils/convertLanguageOfNumbersWithCharacter";
import {toBengaliNumber} from "bengali-number";
import {exportInExcel} from "../../../utils/excelExport";
import {useSubheader} from "../../../../_metronic/layout";
import {showNotifications} from "../../../utils/notification";


const AppEventList = props => {
    const classes = useStylesForDatePicker();

    const [language, setLanguage] = useState(null);
    const [showFilter, setShowFilter] = useState(true);

    const [dongleSerial, setDongleSerial] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [eventType, setEventType] = useState("");
    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [filterData, setFilterData] = useState({})
    const [serialNumberNotNumericMessage, setSerialNumberNotNumericMessage] = useState(false);
    const [dongleSerialNotNumericMessage, setDongleSerialNotNumericMessage] = useState(false);

    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [page, setPage] = useState(1);
    const subHeader = useSubheader();

    const eventTypeOptions = [
        {
            'label': 'BATTERY_VOLTAGE',
            'value': 'BATTERY_VOLTAGE'
        },
        {
            'label': 'START_BATTERY_VOLTAGE',
            'value': 'START_BATTERY_VOLTAGE'
        },
        {
            'label': 'END_BATTERY_VOLTAGE',
            'value': 'END_BATTERY_VOLTAGE'
        },
        {
            'label': 'BATTERY_CHARGE',
            'value': 'BATTERY_CHARGE'
        },
        {
            'label': 'START_BATTERY_CHARGE',
            'value': 'START_BATTERY_CHARGE'
        },
        {
            'label': 'END_BATTERY_CHARGE',
            'value': 'END_BATTERY_CHARGE'
        },
        {
            'label': 'PAYG_TIME_DATA',
            'value': 'PAYG_TIME_DATA'
        },
        {
            'label': 'SET_PAYG_TIME',
            'value': 'SET_PAYG_TIME'
        },
        {
            'label': 'PAYG_TIME_BEFORE_SET',
            'value': 'PAYG_TIME_BEFORE_SET'
        },
        {
            'label': 'WEB_BLE_SOC',
            'value': 'WEB_BLE_SOC'
        },
        {
            'label': 'WEB_BLE_VOLTAGE',
            'value': 'WEB_BLE_VOLTAGE'
        },
        {
            'label': 'WEB_BLE_REMAINING_PAYG_TIME',
            'value': 'WEB_BLE_REMAINING_PAYG_TIME'
        }
    ]

    const pageTitle = language === 'EN'? 'Mobile App Events':'মোবাইল অ্যাপ সম্পর্কিত ঘটনাসমূহ'
    const column1 = language === 'EN'? 'Smart Battery':'যন্ত্র'
    const column2 = language === 'EN'? 'Event Time':'ঘটনার সময়'
    const column3 = language === 'EN'? 'Event Type':'ঘটনার ধরন'
    const column4 = language === 'EN'? 'Value':'মান'
    const column5 = language === 'EN'? 'Created By':'ঘটনা তৈরি হয়েছে যার দ্বারা'

    const fromDateFilterKeyName = 'from_date'
    const toDateFilterKeyName = 'to_date'
    const eventTypeFilterKeyName = 'app_event_type'
    const deviceFilterKeyName = 'device_serial_number'
    const dongleFilterKeyName = 'dongle_serial_number'

    const appEventListColumns = [
        {
            field: 'sl',
            title: language === 'EN'? 'Sl':'ক্রম',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left',
                width: "1%",
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left',
                width: "1%",
            },
            width: "1%",
            emptyValue: ()=>{
                return "N/A"
            }
        },
        {
            field: 'device_serial_number',
            title: column1,
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right',
                width: "5%",
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right',
                width: "5%",
            },
            width: "5%"
            // disableClick: true,
        },
        {
            field: 'app_event_time',
            title: column2,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            render: rowData => (rowData.app_event_time? moment(rowData.app_event_time).format("DD MMM,YYYY hh:mm A") : (language === "EN"? "No Data Found":"কোন তথ্য পাওয়া যায়নি"))
        },
        {
            field: 'app_event_type',
            title: column3,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
        },
        {
            field: 'app_event_value',
            title: column4,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            render: (rowData)=>{
                if (rowData.app_event_type === 'BATTERY_VOLTAGE'){
                    return rowData.app_event_value.toString()+ ' mV'
                } else if (['SET_PAYG_TIME', 'PAYG_TIME_BEFORE_SET'].includes(rowData.app_event_type)) {
                    if (!isNaN(rowData.app_event_value)) {
                        return parseFloat(rowData.app_event_value).toFixed(1)
                    } else {
                        return rowData.app_event_value
                    }
                } else {
                    return rowData.app_event_value
                }
            }
        },
        {
            field: 'created_by_name',
            title: column5,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            },
        },
    ]

    const {
        FETCH_EVENT_LIST,
        FETCH_EVENT_LIST_FOR_EXPORT,
        RETURN_TO_INITIAL,
        eventList,
    } = props

    const collectLastTwoDaysEvents = () => {
        let todaysDate = moment().format("YYYY-MM-DD")
        // We can directly subtract from the moment(), but I am doing it using the previous moment()/todaysDate as it
        // might make a difference when this is running at 11:59:59.9999. Probablity is less than 0.0000001, but yet I
        // have considered the case - Noor Reza, 22nd October, 2023 3:04 PM
        let oneDayEarlier = moment(todaysDate).subtract(1, 'd').format("YYYY-MM-DD")
        setStartDate(moment(oneDayEarlier))
        setEndDate(moment(todaysDate))
        let fromDate = props.language === 'EN'? oneDayEarlier:convertToAnotherLang(oneDayEarlier)
        let toDate = props.language === 'EN'? todaysDate:convertToAnotherLang(todaysDate)
        FETCH_EVENT_LIST({page: 1, [fromDateFilterKeyName]: fromDate, [toDateFilterKeyName]: toDate})
        setPage(1)
        setFilterData({
            [fromDateFilterKeyName]: fromDate,
            [toDateFilterKeyName]: toDate
        })
    }

    useEffect(()=>{
        setLanguage(props.language)
        collectLastTwoDaysEvents()
        subHeader.setActionButtons(null)
        subHeader.setBreadCrumbComponent(null)
        return ()=>{
            RETURN_TO_INITIAL()
        }
    },[])

    useEffect(()=>{
        if (props.eventListForExport) {
            let eventListForExport = props.eventListForExport
            let totalEvents = new Intl.NumberFormat('en-IN').format(eventListForExport.length)
            let totalEventText = language === 'EN'? "Total Events: " + totalEvents:"সর্বমোট ঘটনা: " + convertToAnotherLang(totalEvents, 'BN') + " টি"
            let presentTime = moment()
            let exportText = language === 'EN'? "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A"):"এক্সেল ফাইল ডাউনলোডের সময়: " + presentTime.format("Do MMMM,YYYY hh:mm A")
            let excelRows = [
                [pageTitle, "", "", "", ""],
                [totalEventText, "", "", "", ""],
                ["", "", "", "", ""],
                ["", "", "", "", ""],
                [column1, column2, column3, column4, column5]
            ]
            let filterText = language === 'EN'? "Date Range: " + moment(start).format("Do MMMM,YYYY") + "-" + moment(end).format("Do MMMM,YYYY"):"তারিখের সীমা: " + moment(start).format("Do MMMM,YYYY") + "-" + moment(end).format("Do MMMM,YYYY")
            excelRows.splice(1, 0, [filterText, "", "", "", ""])
            if (filterData.hasOwnProperty(deviceFilterKeyName)) {
                let filterText = language === 'EN'? "Smart Battery: " + serialNumber:"যন্ত্র: " + serialNumber
                excelRows.splice(1, 0, [filterText, "", "", "", ""])
            }
            if (filterData.hasOwnProperty(dongleFilterKeyName)) {
                let filterText = language === 'EN'? "SOLdongle: " + dongleSerial:"ডঙ্গল: " + dongleSerial
                excelRows.splice(1, 0, [filterText, "", "", "", ""])
            }
            if (filterData.hasOwnProperty(eventTypeFilterKeyName)) {
                let filterText = language === 'EN'? "Event Type: " + eventType.value:"ঘটনার ধরন: " + eventType.value
                excelRows.splice(1, 0, [filterText, "", "", "", ""])
            }
            for (let i=0; i<eventListForExport.length; i++) {
                let appEventTime = eventListForExport[i].app_event_time? moment(eventListForExport[i].app_event_time).format("DD MMM,YYYY hh:mm A") : (language === "EN"? "No Data Found":"কোন তথ্য পাওয়া যায়নি")
                excelRows.push([eventListForExport[i]["device_serial_number"], appEventTime,
                    eventListForExport[i]["app_event_type"], eventListForExport[i]["app_event_value"],
                    eventListForExport[i]["created_by_name"]])
            }
            if (eventListForExport.length === 0) {
                let noDataAvailableText =  language === 'EN'? "No data available":"কোন ঘটনা নেই"
                excelRows.push(["", "", noDataAvailableText, "", ""])
            }
            let fileName = language === 'EN'? 'mobile_app_events_':'মোবাইল_অ্যাপ_সম্পর্কিত_ঘটনাসমূহ_'
            exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
        }
    },[props.eventListForExport])

    useEffect(() => {
        if (props.errorForEventListCollection) {
            showNotifications('error', props.errorForEventListCollection)
        }
    }, [props.errorForEventListCollection])

    useEffect(() => {
        if (props.errorForEventListCollectionForExport) {
            showNotifications('error', props.errorForEventListCollectionForExport)
        }
    }, [props.errorForEventListCollectionForExport])

    useEffect(() => {
        clearAllErrorMessages()
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    },[start, end])

    const resetFilterValues = () => {
        setEventType("")
        setSerialNumber("")
        setDongleSerial("")
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        FETCH_EVENT_LIST({page: newPage, ...filterData})
    }

    const clearAllErrorMessages = () => {
        setSerialNumberNotNumericMessage(false)
        setDongleSerialNotNumericMessage(false)
    }

    const filterTheList = () => {
        if (!start && !end && !eventType && !serialNumber && !dongleSerial) {
            toast.error(language === 'EN'? 'Please provide at least one filter criteria!':'তালিকায় খোঁজার জন্য অন্তত একটি মান দিন!')
        } else {
            let filter = {page: 1, ...filterData}
            FETCH_EVENT_LIST(filter)
        }
    }

    const resetFilter = () => {
        collectLastTwoDaysEvents()
        resetFilterValues()
        clearAllErrorMessages()
    }

    const disableFutureDt = (current) => {
        const today = moment().add('1', 'days')
        return current.isAfter(today)
    }

    const [previousStart, setPreviousStartDate] = useState(null);
    const [previousEnd, setPreviousEndDate] = useState(null);
    const onDatesChange = ({startDate, endDate}) => {
        let fromDate, toDate
        if (previousEnd && previousEnd.isSame(endDate)) {
            setPreviousStartDate(endDate)
            setPreviousEndDate(endDate)

            setStartDate(endDate)
            setEndDate(endDate)

            fromDate = language === 'EN'? moment(endDate).format("YYYY-MM-DD"):convertToAnotherLang(moment(endDate).format("YYYY-MM-DD"))
            toDate = language === 'EN'? moment(endDate).format("YYYY-MM-DD"):convertToAnotherLang(moment(endDate).format("YYYY-MM-DD"))
        } else {
            setPreviousStartDate(startDate)
            setPreviousEndDate(endDate)

            setStartDate(startDate)
            setEndDate(endDate)

            fromDate = language === 'EN'? moment(startDate).format("YYYY-MM-DD"):convertToAnotherLang(moment(startDate).format("YYYY-MM-DD"))
            toDate = language === 'EN'? moment(endDate).format("YYYY-MM-DD"):convertToAnotherLang(moment(endDate).format("YYYY-MM-DD"))
        }
        setFilterData(data => ({
            ...data,
            [fromDateFilterKeyName]: fromDate,
            [toDateFilterKeyName]: toDate
        }))
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start}
                    endDate={end}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    )

    const exportData = () => {
        FETCH_EVENT_LIST_FOR_EXPORT({...filterData})
    }

    return (
        <ContentWrapper isLoading={false} pageTitle={"Mobile Application Events"} showBackButton={false} showCardHeader={false}>
            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>{pageTitle}</b></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            <IconButton onClick={filterToggle}>
                                <FilterListIcon color="disabled" fontSize="large"/>
                            </IconButton>
                            <IconButton onClick={exportData}
                                        disabled={props.listLoading || props.isLoading || props.iconLoading}>
                                {props.iconLoading === true ? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/> :
                                    <img src={require('../../../utils/asset/download-Button.svg')}
                                         alt="Download"/>}
                            </IconButton>
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>
                <hr/>
            </div>

            {showFilter?
                <>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <Form>
                                <div className={'row g-3'}>
                                    <div className={'col-md-3'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Date Range':'তারিখের সীমা'}</Form.Label>
                                            </div>
                                            <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                            overlay={popover}>
                                                <SolInput
                                                    name={'dateRangePickerInput'}
                                                    readOnly={true}
                                                    value={dateRange}
                                                    placeholder={language === 'EN'? 'Select a date range':'তারিখের সীমা বাছাই করুন'}
                                                />
                                            </OverlayTrigger>
                                        </Form.Group>
                                    </div>
                                    <div className={'col-md-3'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Event Type':'ঘটনার ধরন'}</Form.Label>
                                            </div>
                                            <Select
                                                name={`eventType`}
                                                placeholder={language === 'EN'? 'Select a event type':'একটি মানদণ্ড বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                maxMenuHeight={200}
                                                value={eventType}
                                                isClearable={true}
                                                options={eventTypeOptions}
                                                isSearchable={true}
                                                onChange={(selected) => {
                                                    clearAllErrorMessages()
                                                    if (selected) {
                                                        setEventType(selected)
                                                        setFilterData(data => ({
                                                            ...data,
                                                            [eventTypeFilterKeyName]: selected.value
                                                        }))
                                                    } else {
                                                        setEventType("")
                                                        if (filterData.hasOwnProperty(eventTypeFilterKeyName)) {
                                                            let data = filterData
                                                            delete data[eventTypeFilterKeyName]
                                                            setFilterData(data)
                                                        }
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-md-3'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Smart Battery':'যন্ত্র'}</Form.Label>
                                            </div>
                                            <SolInput
                                                name={'serialNumber'}
                                                type="text"
                                                placeholder={language === 'EN'? 'Type Smart Battery serial...':'যন্ত্রের ক্রমিক নাম্বার লিখুন...'}
                                                value={serialNumber}
                                                autoComplete={"off"}
                                                onChange = {(e) => {
                                                    clearAllErrorMessages()
                                                    if (e.target.value) {
                                                        let value = e.target.value;
                                                        if (isNaN(value) || value.includes('.')) {
                                                            setSerialNumberNotNumericMessage(true)
                                                        } else {
                                                            setSerialNumberNotNumericMessage(false)
                                                        }
                                                        setSerialNumber(value)
                                                        setFilterData(data => ({
                                                            ...data,
                                                            [deviceFilterKeyName]: value
                                                        }))
                                                    } else {
                                                        setSerialNumber("")
                                                        if (filterData.hasOwnProperty(deviceFilterKeyName)) {
                                                            let data = filterData
                                                            delete data[deviceFilterKeyName]
                                                            setFilterData(data)
                                                        }
                                                    }
                                                }}
                                            />
                                            {serialNumberNotNumericMessage? <><div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? "Smart Battery serial must contain only numbers!":"যন্ত্রের ক্রমিক নাম্বারে শুধু সংখ্যা থাকবে!"}</div></>:null}
                                        </Form.Group>
                                    </div>
                                    <div className={'col-md-3'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'SOLdongle':'ডঙ্গল'}</Form.Label>
                                            </div>
                                            <SolInput
                                                name={'dongle_serial_number'}
                                                type="text"
                                                placeholder={language === 'EN'? 'Type SOLdongle serial...':'ডঙ্গলের ক্রমিক নাম্বার লিখুন...'}
                                                value={dongleSerial}
                                                autoComplete={"off"}
                                                onChange = {(e) => {
                                                    clearAllErrorMessages()
                                                    if (e.target.value) {
                                                        let value = e.target.value;
                                                        if (isNaN(value) || value.includes('.')) {
                                                            setDongleSerialNotNumericMessage(true)
                                                        } else {
                                                            setDongleSerialNotNumericMessage(false)
                                                        }
                                                        setDongleSerial(value)
                                                        setFilterData(data => ({
                                                            ...data,
                                                            [dongleFilterKeyName]: value
                                                        }))
                                                    } else {
                                                        setDongleSerial("")
                                                        if (filterData.hasOwnProperty(dongleFilterKeyName)) {
                                                            let data = filterData
                                                            delete data[dongleFilterKeyName]
                                                            setFilterData(data)
                                                        }
                                                    }
                                                }}
                                            />
                                            {dongleSerialNotNumericMessage? <><div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? "SOLdongle serial must contain only numbers!":"ডঙ্গলের ক্রমিক নাম্বারে শুধু সংখ্যা থাকবে!"}</div></>:null}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={"col-md-12"}>
                                        <Button variant="warning" size={'sm'} type="button"
                                                disabled={serialNumberNotNumericMessage || dongleSerialNotNumericMessage || props.listLoading}
                                                onClick={()=> {filterTheList()}}>
                                            {language === 'EN'? 'Apply filter':'তালিকায় খুঁজুন'}
                                        </Button>
                                        <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}}
                                                className={'ml-3'} disabled={props.listLoading}>
                                            {language === 'EN'? 'Reset filter':'প্রাথমিক অবস্থায় ফিরে যান'}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <hr/></>:null}
            <div>

                <DataTableContainer>
                    {/* We will keep showing the loader, until eventList is available to avoid showing
                    "No Data Available" initially - Noor Reza, 22nd October, 9:51 pm */}
                    {!props.listLoading || !eventList ? <DataTable
                        language={language}
                        noDataAvailableMessageInBangla={'কোন ঘটনা নেই'}
                        columns={appEventListColumns}
                        asyncPagination={true}
                        isLoading={props.listLoading}
                        count={eventList?.count}
                        itemsPerPage={eventList?.page_size}
                        onChangePage={onChangePage}
                        page={page}
                        // TODO: format the values
                        // TODO: Check Backend
                        // TODO: If we do it at FE, than at first need to check if it is a valid number not '1.1.1' or not
                        // TODO: Than we will round it to 1
                        data={eventList?eventList.results.map((item,index)=>({...item, sl: index+1})):[]}
                    /> : <LoadingSpinner loadingSubText={'Collecting event information..'} language={language}/>}
                </DataTableContainer>

            </div>

        </ContentWrapper>
    );
};

AppEventList.propTypes = {
    
};

export default connect((state) => {
    return {
        language: state.auth.language,
        isLoading: state.appEventReducer.isLoading,
        eventList: state.appEventReducer.events,
        errorForEventListCollection: state.appEventReducer.errorForEventListCollection,
        listLoading: state.appEventReducer.listLoading,
        iconLoading: state.appEventReducer.iconLoading,
        eventListForExport: state.appEventReducer.eventsForExport,
        errorForEventListCollectionForExport: state.appEventReducer.errorForEventListCollectionForExport
    }
}, {...appEventActions}) (AppEventList);
