import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { actions } from '../../../actions';
import Avatar from '@mui/material/Avatar';
import SettingsIcon from "@mui/icons-material/Settings";
import {Dropdown, DropdownButton} from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import TurnRightIcon from '@mui/icons-material/TurnRight';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import EditContract from "../operations/editContract"
import UpdateInstallment from "../operations/updateInstallment"
import TerminateContract from "../operations/terminateContract"
import RescheduleInstallment from "../operations/rescheduleInstallment"
import PauseOrResumeContract from "../operations/pauseOrResumeContract"
import {GenericModal} from "../../../../../components/genericModal/genericModal";
import moment from "moment";


const Settings = ({ contractDetails, userCanEdit, userCanTerminate, userCanReschedule,
                      userCanUpdateInstallment, userCanPause, userCanResume, ...props }) => {
    const [language, setLanguage] = useState("EN");
    const [contractId, setContractId] = useState('');

    // Contract Edit related
    const [showContractEditModal, setShowContractEditModal] = useState(false)
    const [perRentAmountForEdit, setDefaultPerRentAmountForEdit] = useState(0)
    const [contractType, setContractType] = useState('');
    const [smartBattery, setSmartBattery] = useState('');
    const [smartBatteryDetails, setSmartBatteryDetails] = useState('');
    const [smartBatteryType, setSmartBatteryType] = useState('');
    const [autoPaymentEnabled, setAutoPaymentEnabled] = useState(false);

    // Contract Termination related
    const [showLoanTerminationModal, setShowTerminationModal] = useState(false)

    // Contract Rescheduling related
    const [showContractRescheduleModal, setShowContractRescheduleModal] = useState(false)

    // Installment update related
    const [installmentUpdateOptionIsHovered, setInstallmentUpdateOptionIsHovered] = useState(false)
    const [showInstallmentUpdateModal, setShowInstallmentUpdateModal] = useState(false)

    // Pause or Resume contract related
    const [showPauseOrResumeContractModal, setShowPauseOrResumeContractModal] = useState(false)
    const [actionCanBeTaken, setActionCanBeTaken] = useState('')


    const {
        // Will add action here, if needed
    } = props;

    useEffect(() => {
        setLanguage(props.language)
        setContractId(contractDetails.contract_id)
        setContractType(contractDetails.contract_type)
        setSmartBattery(contractDetails.device_serial_number)
        setSmartBatteryDetails(contractDetails.device_details?.battery_details? contractDetails.device_details.battery_details:'')
        setSmartBatteryType(contractDetails.battery_type)
        setAutoPaymentEnabled(contractDetails.enable_auto_payment)
        setActionCanBeTaken(contractDetails.status === 'active'? 'Pause':contractDetails.status === 'paused'? 'Resume':'')
    }, [])

    // ---- Begin: Related to contract edit ---- //
    const initiateContractEditProcess = () => {
        setDefaultPerRentAmountForEdit(contractDetails.per_rent_payable)
        setShowContractEditModal(true)
    }

    const hideContractEditModal = () => {
        setShowContractEditModal(false)
    }

    const renderContractEditModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideContractEditModal}
                modal={showContractEditModal}
                title={<h1><strong>Edit Contract - Contract ID #{contractId}</strong></h1>}>
                <EditContract contractID={contractId} contractType={contractType} smartBatteryOfTheContract={smartBattery}
                              defaultPerRentPayment={perRentAmountForEdit}
                              closeEditContractProcess={hideContractEditModal}
                              smartBatteryType={smartBatteryType}
                              autoPaymentEnabled={autoPaymentEnabled}
                              smartBatteryDetails={smartBatteryDetails}/>
            </GenericModal>
        </>
    }

    // ---- Edit: Related to contract edit ---- //

    // ---- Begin: Related to installment amount update ---- //
    const mouseHoverEventOnInstallmentUpdate = () => {
        setInstallmentUpdateOptionIsHovered(true)
    }

    const mouseOutEventOnInstallmentUpdate = () => {
        setInstallmentUpdateOptionIsHovered(false)
    }

    const initiateInstallmentUpdateProcess = () => {
        setShowInstallmentUpdateModal(true)
    }

    const hideInstallmentUpdateModal = () => {
        setShowInstallmentUpdateModal(false)
    }

    const renderInstallmentAmountUpdateModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideInstallmentUpdateModal}
                modal={showInstallmentUpdateModal}
                title={<h1><strong>Installment Update</strong></h1>}>
                <UpdateInstallment contractDetails={contractDetails} closeInstallmentUpdateProcess={hideInstallmentUpdateModal}/>
            </GenericModal>
        </>
    }

    // ---- Edit: Related to installment amount update ---- //

    // ---- Begin: Related to contract termination ---- //
    const initiateLoanTermination = () => {
        setShowTerminationModal(true)
    }
    const hideLoanTerminationModal = () => {
        setShowTerminationModal(false)
    }

    const renderLoanTerminationModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideLoanTerminationModal}
                modal={showLoanTerminationModal}
                title={<h1><strong>Please Confirm Contract Termination</strong></h1>}>
                <TerminateContract contractID={contractId} contractSmartBattery={smartBattery}
                                   closeContractTerminationProcess={hideLoanTerminationModal}/>
            </GenericModal>
        </>
    }
    // ---- End: Related to contract termination ---- //

    // ---- Begin: Related to contract reschedule ---- //
    const initiateContractRescheduling = () => {
        setShowContractRescheduleModal(true)
    }
    const hideContractRescheduleModal = () => {
        setShowContractRescheduleModal(false)
    }

    const renderContractRescheduleModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideContractRescheduleModal}
                modal={showContractRescheduleModal}
                title={<h1><strong>Reschedule</strong></h1>}>
                <RescheduleInstallment contractDetails={contractDetails}
                                       closeReschedulingProcess={hideContractRescheduleModal}/>
            </GenericModal>
        </>
    }
    // ---- End: Related to contract termination ---- //

    // ---- Begin: Related to pause or resume contract ---- //
    const initiatePauseOrResumeContractProcess = () => {
        setShowPauseOrResumeContractModal(true)
    }

    const hidePauseOrResumeContractModal = () => {
        setShowPauseOrResumeContractModal(false)
    }

    const renderPauseOrResumeContractModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hidePauseOrResumeContractModal}
                modal={showPauseOrResumeContractModal}
                title={<h1><strong>{actionCanBeTaken + ' Contract - ID # ' + contractId + ' / SB # ' + smartBattery}</strong></h1>}>
                <PauseOrResumeContract contractDetails={contractDetails}
                                       closeTheProcess={hidePauseOrResumeContractModal}
                                       actionName={actionCanBeTaken}/>
            </GenericModal>
        </>
    }
    // ---- End: Related to contract pause/resume ---- //

    return (
        <>
            <DropdownButton
                title={<span>{contractDetails.status === 'paused'? 'Paused':'Settings'} <SettingsIcon fontSize={"small"}/></span>}
                variant={"success"}
                id="sm-nested-dropdown">
                {userCanEdit? <>
                    <Dropdown.Item eventKey="1" size={'sm'}
                                   onClick={initiateContractEditProcess}>
                        <div className={'container d-flex justify-content-between pl-0 pr-0'}>
                            <span>Edit Contract</span>
                            <EditIcon fontSize={"small"}/>
                        </div>
                    </Dropdown.Item>
                    {userCanTerminate || ((((userCanReschedule || userCanUpdateInstallment) && contractDetails.status !== 'paused') ||
                        ((userCanPause || userCanResume) && !contractDetails.prepaid_payment_enabled && actionCanBeTaken))
                        && contractType !== 'rent')? <><Dropdown.Divider/></>:''}
                </>:''}
                {(userCanPause || userCanResume) && !contractDetails.prepaid_payment_enabled && actionCanBeTaken && contractType !== 'rent'? <>
                    {/* Note that, it is not possible to 'actionName' variable have both of the 'Pause' and 'Resume' values */}
                    {/* So either 'Pause Action' or 'Resume Action' will be shown to the user */}
                    {userCanPause && actionCanBeTaken === 'Pause'? <>
                        <Dropdown.Item eventKey="2" size={'sm'}
                                       onClick={initiatePauseOrResumeContractProcess}>
                            <div className={'container d-flex justify-content-between pl-0 pr-0'}>
                                <span>{actionCanBeTaken} Contract</span>
                                <PauseIcon fontSize={"small"}/>
                            </div>
                        </Dropdown.Item>
                    </>:''}
                    {userCanResume && actionCanBeTaken === 'Resume'? <>
                        <Dropdown.Item eventKey="2" size={'sm'}
                                       onClick={initiatePauseOrResumeContractProcess}>
                            <div className={'container d-flex justify-content-between pl-0 pr-0'}>
                                <span>{actionCanBeTaken} Contract</span>
                                <PlayArrowIcon fontSize={"small"}/>
                            </div>
                        </Dropdown.Item>
                    </>:''}
                    {userCanTerminate ||
                    ((userCanReschedule || userCanUpdateInstallment) && contractDetails.status !== 'paused' && contractType !== 'rent')? <><Dropdown.Divider/></>:''}
                </>:''}
                {userCanReschedule && contractDetails.status !== 'paused' && contractType !== 'rent'? <>
                    <Dropdown.Item eventKey="3" size={'sm'} onClick={initiateContractRescheduling}>
                        <div className={'container d-flex justify-content-between pl-0 pr-0'}>
                            <span>Reschedule Invoice</span>
                            <TurnRightIcon fontSize={"small"}/>
                        </div>
                    </Dropdown.Item>
                    {userCanTerminate ||
                    (userCanUpdateInstallment && contractDetails.status !== 'paused' && contractType !== 'rent')? <><Dropdown.Divider/></>:''}
                </>:''}
                {/*<Dropdown.Item eventKey="4" size={'sm'}*/}
                {/*               onClick={() => console.log("here")}>*/}
                {/*    <div className={'container d-flex justify-content-between pl-0 pr-0'}>*/}
                {/*        <span>Split Overdue</span>*/}
                {/*        <SplitscreenIcon fontSize={"small"}/>*/}
                {/*    </div>*/}
                {/*</Dropdown.Item>*/}
                {/*<Dropdown.Divider/>*/}
                {userCanUpdateInstallment && contractDetails.status !== 'paused' && contractType !== 'rent'? <>
                    <Dropdown.Item eventKey="5" size={'sm'}
                                   onClick={initiateInstallmentUpdateProcess}
                                   onMouseOver={mouseHoverEventOnInstallmentUpdate}
                                   onMouseOut={mouseOutEventOnInstallmentUpdate}>
                        <div className={'container d-flex justify-content-between pl-0 pr-0'}>
                            <span>Installment Update</span>
                            <Avatar sx={{ width: installmentUpdateOptionIsHovered? 16:18, height: installmentUpdateOptionIsHovered? 16:18 }}
                                    src={require(installmentUpdateOptionIsHovered? '../../../../../utils/asset/taka.svg':'../../../../../utils/asset/takaaaa.svg')}/>
                        </div>
                    </Dropdown.Item>
                    {userCanTerminate? <><Dropdown.Divider/></>:''}
                </>:''}
                {userCanTerminate? <>
                    <Dropdown.Item eventKey="7" size={'sm'}
                                   onClick={initiateLoanTermination}>
                        <div className={'container d-flex justify-content-between pl-0 pr-0'}>
                            <span>Terminate</span>
                            <CancelIcon fontSize={"small"}/>
                        </div>
                    </Dropdown.Item>
                </>:''}
            </DropdownButton>
            {renderContractEditModal()}
            {renderInstallmentAmountUpdateModal()}
            {renderLoanTerminationModal()}
            {renderContractRescheduleModal()}
            {renderPauseOrResumeContractModal()}
        </>
    )
}

Settings.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations
    }
}

export default connect(mapStateToProps, { ...actions})(Settings);