import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for transactions*/
const initialState= {
    transactions: undefined,
    transactionsForExport: undefined,
    mobileWalletTransactions: undefined,
    mobileWalletTransactionsForExport: undefined,
    settlementPayments: undefined,
    settlementPaymentsForExport: undefined,
    errorMessage: undefined,
    topupDetails: undefined,
    payments: undefined,
    errorMessageForPaymentsCollection: undefined
};

/*Reducer for Driver's redux store*/
export const transactionReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {

        case actionTypes.FETCH_PAYMENTS_REQUEST:
            return {
                ...state,
                collectingTransactions: true,
                transactions: undefined
            }
        case actionTypes.FETCH_PAYMENTS_SUCCESS:
            return {
                ...state,
                collectingTransactions: false,
                transactions: action.payload,
            }
        case actionTypes.FETCH_PAYMENTS_FAILURE:
            toast.error("Failed to collect transactions! Please contact with an admin.")
            return {
                ...state,
                collectingTransactions: false
            }

        case actionTypes.FETCH_PAYMENTS_EXPORT_REQUEST:
            return {
                ...state,
                collectingTransactionsForExport: true,
                transactionsForExport: undefined
            }
        case actionTypes.FETCH_PAYMENTS_EXPORT_SUCCESS:
            return {
                ...state,
                collectingTransactionsForExport: false,
                transactionsForExport: action.payload
            }
        case actionTypes.FETCH_PAYMENTS_EXPORT_FAILURE:
            toast.error("Failed to collect transactions for export!")
            return {
                ...state,
                collectingTransactionsForExport: false
            }
        case actionTypes.FETCH_INVOICE_PAYMENTS_FOR_EXPORT_REQUEST:
            return {
                ...state,
                collectingInvoicesForExport: true,
                invoicesForExport: undefined
            }
        case actionTypes.FETCH_INVOICE_PAYMENTS_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                collectingInvoicesForExport: false,
                invoicesForExport: action.payload,
            }
        case actionTypes.FETCH_INVOICE_PAYMENTS_FOR_EXPORT_FAILURE:
            toast.error("Failed to collect data for export! Please contact with an admin.")
            return {
                ...state,
                collectingInvoicesForExport: false
            }

        case actionTypes.FETCH_MOBILE_WALLET_PAYMENTS_REQUEST:
            return {
                ...state,
                collectingMobileWalletTransactions: true,
                mobileWalletTransactions: undefined
            }
        case actionTypes.FETCH_MOBILE_WALLET_PAYMENTS_SUCCESS:
            return {
                ...state,
                collectingMobileWalletTransactions: false,
                mobileWalletTransactions: action.payload,
            }
        case actionTypes.FETCH_MOBILE_WALLET_PAYMENTS_FAILURE:
            toast.error("Failed to collect mobile wallet transactions! Please contact with an admin.")
            return {
                ...state,
                collectingMobileWalletTransactions: false
            }

        case actionTypes.FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT_REQUEST:
            return {
                ...state,
                collectingMobileWalletTransactionsForExport: true,
                mobileWalletTransactionsForExport: undefined
            }
        case actionTypes.FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                collectingMobileWalletTransactionsForExport: false,
                mobileWalletTransactionsForExport: action.payload,
            }
        case actionTypes.FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT_FAILURE:
            toast.error("Failed to collect mobile wallet transactions! Please contact with an admin.")
            return {
                ...state,
                collectingMobileWalletTransactionsForExport: false
            }

        case actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_REQUEST:
            return {
                ...state,
                listLoading: true,
                settlementPayments: undefined
            }
        case actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_SUCCESS:
            return {
                ...state,
                listLoading: false,
                settlementPayments: action.payload,
            }
        case actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FAILURE:
            return {
                ...state,
                listLoading: false,
                settlementPayments: undefined
            }

        case actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_REQUEST:
            return {
                ...state,
                collectingTransactionsForExport: true,
                settlementPaymentsForExport: undefined
            }
        case actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                collectingTransactionsForExport: false,
                settlementPaymentsForExport: action.payload,
            }
        case actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_FAILURE:
            return {
                ...state,
                collectingTransactionsForExport: false,
                settlementPaymentsForExport: undefined
            }

        case actionTypes.SETTLE_PAYMENTS_REQUEST:
            return {
                ...state,
                errorMessage: undefined,
            }
        case actionTypes.SETTLE_PAYMENTS_SUCCESS:
            toast.success("Transactions settled")
            return {
                ...state,
                listLoading: false,
                
            }
        case actionTypes.SETTLE_PAYMENTS_FAILURE:
            toast.error("Something went wrong!")
            return {
                ...state,
                listLoading: false,
                errorMessage: action.error && action.payload?.error?.message ? action.payload?.error?.message : undefined,
            }

        case actionTypes.FETCH_PAYMENT_DETAILS_REQUEST:
            return {
                ...state,
                topupDetails: undefined,
                errorMessage: undefined,
            }
        case actionTypes.FETCH_PAYMENT_DETAILS_SUCCESS:
            return {
                ...state,
                topupDetails: action.payload,
                listLoading: false,
                
            }
        case actionTypes.FETCH_PAYMENT_DETAILS_FAILURE:
            toast.error("Something went wrong!")
            return {
                ...state,
                topupDetails: undefined,
                listLoading: false
            }

        case actionTypes.FETCH_INVOICE_PAYMENTS_REQUEST:
            return {
                ...state,
                payments: undefined,
                errorMessageForPaymentsCollection: undefined,
                collectingPayments: true,
            }
        case actionTypes.FETCH_INVOICE_PAYMENTS_SUCCESS:
            return {
                ...state,
                payments: action.payload,
                collectingPayments: false,
            }
        case actionTypes.FETCH_INVOICE_PAYMENTS_FAILURE:
            return {
                ...state,
                errorMessageForPaymentsCollection: action.payload.error,
                collectingPayments: false
            }
                    
        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
