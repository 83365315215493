import React, { useEffect, useState } from 'react';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import { FilterButton, Toolbar } from "../../../components";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DataTable, DataTableContainer, FilterInput, FilterReactSelect } from "../../../components";
import {SolInput} from "../../../components/SolStyledComponents/components";
import { transactionActions } from "../actions";
import { actions as commonActions } from '../../commonReduxActions/actions';
import { LoadingSpinner } from '../../../components'
import { connect } from "react-redux";
import { Button, Form, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { theme, useStylesForDatePicker } from "../../../utils/muiPickerCustomStyle";
import moment from "moment";
import { DayPickerRangeController, DayPickerSingleDateController } from "react-dates";
import { SolTypography } from '../../../components';
import { useSubheader } from "../../../../_metronic/layout";
import {findItemInAnObjectList, isNumber} from '../../../utils/utilityFunctions';
import {exportInExcel} from "../../../utils/excelExport";
import {toBengaliNumber} from "bengali-number";
import Stack from '@mui/material/Stack';
import Avatar from "@mui/material/Avatar";
import { useMediaQuery } from "react-responsive";
import Overlay from "react-bootstrap/Overlay";
import InfoIcon from "@mui/icons-material/Info";
import Select from "react-select";
import {customStylesForMultiSelectField} from "../../../utils/reactSelectRelated/customStylesForMultiSelect";
import {ValueContainer} from "../../../utils/reactSelectRelated/showCustomizedValueLabelForMultiSelect";
import {Option} from "../../../utils/reactSelectRelated/showOptionsWithCheckbox";
import {groupSelectedOptions} from "../../../utils/reactSelectRelated/groupSelectedOptionsForMultiSelect";
import {formulateTextFromSelectedItems} from "../../../utils/multiSelectValueFormation/formatForExportedFile";
import {formulateApiRequestDataFromSelectedItems} from "../../../utils/multiSelectValueFormation/formatForApiRequest";
import {customStylesForSingleSelectField} from "../../../utils/reactSelectRelated/customStylesForSingleSelect";
import {showNotifications} from "../../../utils/notification";

const Payments = props => {
    const classes = useStylesForDatePicker();
    const { clearErrors, setError, setValue, reset, handleSubmit, control, errors, register } = useForm()
    const subheader = useSubheader();
    const [authorization, setAuthorization] = useState(null);
    const [language, setLanguage] = useState("EN");
    const [showFilter, setShowFilter] = useState(true);
    const [filterApplied, setFilterApplied] = useState(false);

    const [filter, setFilter] = useState(null)
    const obj_filters = ['payment_channel', 'lease_type']

    const [borrowers, setBorrowers] = useState([]);
    const [garages, setGarages] = useState([]);
    const [financiers, setFinanciers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [borrower, setBorrower] = useState('');
    const [garage, setGarage] = useState('');
    const [financier, setFinancier] = useState('');
    const [branch, setBranch] = useState('');
    const [contractType, setContractType] = useState('');
    const [paymentChannel, setPaymentChannel] = useState('');
    const [page, setPage] = useState(1);
    const [payments, setPayments] = useState(undefined);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })


    // variables for Due Date
    const [targetForDueDateRangePicker, setTargetForDueDateRangePicker] = useState(null);
    const [showDueDateRangePicker, setShowDueDateRangePicker] = useState(false);
    const [dueDateRangeStart, setDueDateRangeStart] = useState(null);
    const [dueDateRangeEnd, setDueDateRangeEnd] = useState(null);
    const [dueDateRange, setDueDateRange] = useState('');
    const [focusedInputForDueDate, setFocusedInputForDueDate] = useState('startDate');

    const paymentsColumn = [

        {
            field: 'invoice_for',
            title: "Smart Battery ID",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {

                return <>
                    <span ><SolTypography.Title > SB # {`${rowData.device_sl}`} </SolTypography.Title></span>
                    <span style={{whiteSpace: "nowrap"}}>Invoice # {`${rowData.invoice_id}`}</span><br/>
                    <span style={{whiteSpace: "nowrap"}}>TRX # {rowData.payment_id}</span>
                </>
            }
        },
        {
            field: 'created_at',
            title: "Date",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return "-"
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: "nowrap"}}>{moment(rowData.created_at).format("MMM DD, YYYY")}</span>
                </>
            }
        },

        {
            field: 'borrower_guid',
            title: "Borrower's Name",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return "-"
            },
            render: (rowData) => {
                const garage = findItemInAnObjectList("pk", rowData.garage_guid, props.garages )
                return <>
                    {props.collectingGarages? <>
                        <span style={{whiteSpace: "nowrap"}}>....</span>
                    </>:<>
                        {garage? <>
                            <span style={{whiteSpace: "nowrap"}}>{garage?.garage_owner_name }</span><br/>
                            <>
                                <span style={{whiteSpace: "nowrap"}}>{garage?.name}</span><br/>
                            </>
                        </>:<>
                            N/A
                        </>}
                    </>}
                </>
            }
        },
        {
            field: 'financier_guid',
            title: "Financier's Name",
            cellStyle: {
                textAlign: 'left'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                const financier = findItemInAnObjectList("pk", rowData.financier_guid, props.financiers )
                return <>
                    <span style={{whiteSpace: "nowrap"}}>{financier?.name }</span><br/>
                    <>
                        <span style={{whiteSpace: "nowrap"}}>{financier?.division}</span><br/>
                    </>
                </>
            }
        },
        // {
        //     field: 'organization_name',
        //     title: "Channel",
        //     cellStyle: {
        //         paddingLeft: '0',
        //         textAlign: 'left'
        //     },
        //     headerStyle: {
        //         paddingLeft: '0',
        //         textAlign: 'left'
        //     },
        //     emptyValue: () => {
        //         return "-"
        //     },
        //     render: (rowData) => {
        //         return <>
        //             <span style={{whiteSpace: 'nowrap'}}>{"Digital Payment"}</span>
        //         </>
        //     }
        // },
        {
            field: 'paid_amount',
            title: "Amount",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=> {
                return <>
                    <h6 style={{whiteSpace: 'nowrap'}}><strong>{new Intl.NumberFormat('en-IN').format(rowData.paid_amount.toFixed(2))}</strong></h6>
                </>
            }
        },
        // {
        //     field: 'invoice_for',
        //     title: "Revenue Distribution (Financier - SOLshare)",
        //     cellStyle: {
        //         paddingLeft: '0',
        //         textAlign: 'right'
        //     },
        //     headerStyle: {
        //         paddingLeft: '0',
        //         textAlign: 'right'
        //     },
        //     render: (rowData) => {
        //         return <>
        //             {/*<span ><SolTypography.Title primary> SB # 05 </SolTypography.Title></span>*/}
        //             <h6 style={{whiteSpace: 'nowrap'}}><strong>{`${rowData.revenue_share.financier}%/${rowData.revenue_share.solshare}%`}</strong></h6>
        //             <span ><SolTypography.SubTitle secondary> Tk {(rowData.paid_amount*(rowData.revenue_share.financier/100)).toFixed(2)} / Tk {(rowData.paid_amount*(rowData.revenue_share.solshare/100)).toFixed(2)}</SolTypography.SubTitle></span>
        //         </>
        //     }
        // },
        // {
        //     field: 'invoice_status',
        //     title: 'Payment Status',
        //     headerStyle: {
        //         paddingRight: '0',
        //         textAlign: 'right'
        //     },
        //     cellStyle: {
        //         textAlign: 'right'
        //     },
        //     render: (rowData)=> {
        //         const DATA_MAP = {
        //             "due": { "cls": 'outline-danger', "text": "Due" },
        //             "overdue": { "cls": 'danger', "text": "Overdue" },
        //             "partial_paid": { "cls": "outline-warning", "text": "Partial" },
        //             "paid": { "cls": "outline-success", "text": "Paid" },
        //             "cancelled": { "cls": "outline-info", "text": "Cancelled" },
        //         }
        //
        //         // console.log(rowData?.invoice_status)
        //
        //         return <>
        //
        //             <Button variant={DATA_MAP[rowData?.payment_status].cls} size={'sm'}>{DATA_MAP[rowData?.payment_status].text}</Button>
        //         </>
        //     }
        // }
    ]

    const {

        COLLECT_PAYMENTS_LIST,
        FETCH_INVOICE_PAYMENTS_FOR_EXPORT,
        GET_GARAGE_LIST,
        COLLECT_BORROWERS,
        COLLECT_FINANCIERS_LIST,
        GET_BRANCHES_LIST,
        RETURN_TO_INITIAL_STATE,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER
    } = props

    const invoicesWithoutPagination = () => {
        let payload = { ...filter}
        FETCH_INVOICE_PAYMENTS_FOR_EXPORT({...payload})
        // COLLECT_INVOICES_LIST_FOR_EXPORT({...payload})
    }

    const showHelpfulText = () => {
        return <>
            <div className={'container'} style={{color: "#F18D00"}}><InfoIcon size={'sm'}/> Selection is completed
                after <strong>two clicks on a single date</strong> or <strong>single click on two dates.</strong></div>
        </>
    }
    useEffect(() => {
        setLanguage(props.language)
        setAuthorization(props.authorization)
        subheader.setActionButtons(null)
        subheader.setBreadCrumbComponent(null)
        COLLECT_BORROWERS()
        GET_GARAGE_LIST()
        if (props.isSolshareUser) {
            COLLECT_FINANCIERS_LIST()
        }
        GET_BRANCHES_LIST()
        fetchData()
        return () => {
            subheader.setActionButtons(null)
            RETURN_TO_INITIAL_STATE()
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
        }
    }, [])

    // Saving latest garage information
    useEffect(() => {
        if (props.garages && props.garages.length > -1) {
            const garages = props.garages;
            setGarages(garages.map((garage) => {
                return {
                    ...garage,
                    value: garage.pk,
                    label: garage.name
                }
            }))
        }
    }, [props.garages])

    useEffect(() => {
        if (props.errorMessageForGarageListCollection) {
            showNotifications('error', props.errorMessageForGarageListCollection)
        }
    }, [props.errorMessageForGarageListCollection])

    useEffect(() => {
        if (props.payments && props.payments.results && props.payments.results.length > -1) {
            const payments = props.payments.results;


            setPayments(payments.map((payment) => {

                // Code for revenue distribution
                let revenue_share = {
                    "solshare": 100,
                    "financier": 0,
                }
                if(payment.contract_details?.solshare_revenue==="platform_fee"){

                    revenue_share = {
                        "solshare": parseInt(payment.contract_details?.platform_fee_percentage),
                        "financier": 100 - parseInt(payment.contract_details?.platform_fee_percentage),
                    }
                }
                // Code for revenue distribution
                return {
                    ...payment,
                    revenue_share: {...revenue_share}
                }
            }))
        } else {
            setPayments(undefined)
        }
    }, [props.payments]);

    useEffect(() => {
        if (props.errorMessageForPaymentsCollection) {
            showNotifications('error', props.errorMessageForPaymentsCollection)
        }
    }, [props.errorMessageForPaymentsCollection])

    useEffect(() => {
        if (props.branches && Array.isArray(props.branches)) {
            setBranches(props.branches.map((branch) => {
                return {
                    ...branch,
                    value: branch.pk,
                    label: branch.name
                }
            }))
        } else {
            setBranches([])
        }
    }, [props.branches])

    useEffect(() => {
        if (props.invoicesForExport && props.invoicesForExport.length > -1) {
            const invoices = props.invoicesForExport
            let presentTime = moment()
            let invoicesLength = invoices.length
            let totalTransactionsText = language === 'EN'? "Total Transactions: " + invoicesLength:"চালানের সংখ্যা: " + toBengaliNumber(invoicesLength)
            let exportText = language === 'EN'? "Exported At: " + presentTime.format("Do MMMM,YYYY hh:mm A"):"এক্সেল ফাইল ডাউনলোডের সময়: " + presentTime.format("Do MMMM,YYYY hh:mm A")
            let excelRows = [
                ["Transactions", "", "", "", "", "", "", "", "", "", "", ""],
                [totalTransactionsText, "", "", "", "", "", "", "", "", "", "", ""],
                [exportText, "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", "", ""],
                ["Date", "Smart Battery ID", "Invoice ID", "Trx ID", "Garage", "Borrower", "Financier",
                    "Amount (Tk)", "Revenue Distribution (Financial- SOLshare)", "Contract Type", "Payment Channel"]
            ]
            if (filter) {
                if (filter.device_sl) {
                    let text = "Smart Battery: " + filter.device_sl
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.created_at_from && filter.created_at_to) {
                    let text = "Date: " + moment(filter.created_at_from).format("DD MMM,YYYY") +
                        " - " + moment(filter.created_at_to).format("DD MMM,YYYY")
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.borrower_guid && borrower) {
                    let text = formulateTextFromSelectedItems('Borrower', borrower)
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.garage_guid && garage) {
                    let text = formulateTextFromSelectedItems('Garage', garage)
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.financier_guid && financier) {
                    let text = formulateTextFromSelectedItems('Financier', financier)
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.branch_guid && branch) {
                    let text = formulateTextFromSelectedItems('Branch', branch)
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.lease_type) {
                    let text = "Contract Type: " + contractType.label
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.payment_channel) {
                    let text = "Payment Channel: " + paymentChannel.label
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
            }
            for (let i=0; i<invoices.length; i++) {
                // Code for revenue distribution
                let revenue_share = {
                    "solshare": 100,
                    "financier": 0,
                }
                if(invoices[i].contract_details?.solshare_revenue==="platform_fee"){

                    revenue_share = {
                        "solshare": parseInt(invoices[i].contract_details?.platform_fee_percentage),
                        "financier": 100 - parseInt(invoices[i].contract_details?.platform_fee_percentage),
                    }
                }
                // Code for revenue distribution

                excelRows.push([
                    moment(invoices[i].created_at).format("MMM DD, YYYY"),
                    invoices[i].device_sl,
                    invoices[i].invoice_id,
                    invoices[i].payment_id,
                    findItemInAnObjectList("pk", invoices[i].garage_guid, props.garages )?.name,
                    findItemInAnObjectList("pk", invoices[i].garage_guid, props.garages )?.garage_owner_name,
                    findItemInAnObjectList("pk", invoices[i].financier_guid, props.financiers )?.name,
                    new Intl.NumberFormat('en-IN').format(invoices[i].paid_amount.toFixed(2)),
                    `${revenue_share.financier}%/${revenue_share.solshare}% ` + "(Tk " + (invoices[i].paid_amount*(revenue_share.financier/100)).toFixed(2) + " / Tk " + (invoices[i].paid_amount*(revenue_share.solshare/100)).toFixed(2) + ")",
                    invoices[i].lease_type,

                ])
            }
            let noDataAvailableText =  language === 'EN'? "No data available":"কোন তথ্য নেই"
            invoicesLength > 0? excelRows.push(["", "", "", "", "", "", "", "", "", "", "", ""]):
                excelRows.push(["", "", "", "", "", noDataAvailableText, "", "", "", "", "", ""])
            let fileName = 'transactions_'
            exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
        }
    }, [props.invoicesForExport])

    useEffect(() => {
        if (props.borrowers && props.borrowers.length > -1) {
            const borrowers = props.borrowers;
            setBorrowers(borrowers.map((item) => {
                let borrowerName = item.name
                let garageName = item.garage_name
                if (garages && garages.length > 0) {
                    let filteredData = garages.filter(garage => garage.pk === item.garage_guids[0])
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                        borrowerName = filteredData[0].garage_owner_name
                    }
                }
                let label = borrowerName + ' (' + garageName + ')'

                if (borrower && borrower.value === item.pk) {
                    let selectedBorrower = borrower
                    selectedBorrower['label'] = label
                    selectedBorrower['value'] = item.pk
                    setValue('borrower', selectedBorrower)
                    setBorrower(selectedBorrower)
                }

                return {
                    ...item,
                    label: label,
                    value: item.pk
                }
            }))
        } else {
            setBorrowers([])
        }
    }, [props.borrowers, garages])

    useEffect(() => {
        if (props.financiers && props.financiers.length > -1) {

            setFinanciers(
                props.financiers.map((item) => {
                    return {
                        ...item,
                        value: item.pk,
                        label: item.name
                    }
                })
            )
        } else {
            setFinanciers([])
        }
    }, [props.financiers])

    const resetFilterValues = () => {
        setDueDateRangeStart(null)
        setDueDateRangeEnd(null)
        setBorrower('')
        setGarage('')

        if (props.isSolshareUser && financier) {
            setFinancier('')
            GET_BRANCHES_LIST()
        }

        setBranch('')
        setContractType('')
        setPaymentChannel('')
        reset({
            "lease_type": "",
            "payment_channel": "",
            "transaction_date": "",
            "device_sl": ""
        })
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        fetchData(newPage)
        // GET_GARAGE_LIST()
    }

    const makeFilter =(data)=>{
        obj_filters.forEach((item)=>{
            data[item] = data[item]?.value
        })

        if (data.transaction_date) {
            let dueDateAfterSplit = data.transaction_date.split('-')
            data["created_at_from"] = moment(dueDateAfterSplit[0]).format("YYYY-MM-DD")
            data["created_at_to"] = moment(dueDateAfterSplit[1]).format("YYYY-MM-DD")
            delete data['transaction_date']
        }
        // TODO: Need to update here ?
        if (borrower) {
            data['borrower_guid'] = formulateApiRequestDataFromSelectedItems(borrower)
        }
        if (garage) {
            data['garage_guid'] = formulateApiRequestDataFromSelectedItems(garage)
        }
        if (financier) {
            data['financier_guid'] = formulateApiRequestDataFromSelectedItems(financier)
        }
        if (branch) {
            data['branch_guid'] = formulateApiRequestDataFromSelectedItems(branch)
        }
    }

    const filterTheList = (data) => {
        makeFilter(data)
        setPage(1)
        setFilter(data)
        setFilterApplied(true)
        fetchData(1, data)
        // GET_GARAGE_LIST()
    }

    const resetFilter = () => {
        if (filter && Object.keys(filter).length > 0) {
            // Resetting the approved transactions list
            setPage(1)
            setFilter(null)
            fetchData(1, {})
            // GET_GARAGE_LIST()
        }
        resetFilterValues()
        setFilterApplied(false)
        clearErrors()
    }

    const fetchData=(page= 1, filterData= null)=>{
        if(filterData){
            COLLECT_PAYMENTS_LIST({...filterData, page: page})
            return
        }
        COLLECT_PAYMENTS_LIST({...filter, page: page})

    }
    const [clickCountForDueDateRangePicker, setClickCountForDueDateRangePicker] = useState(0)

    const onDatesChangeAtDueDate = ({startDate, endDate}) => {
        setClickCountForDueDateRangePicker(clickCountForDueDateRangePicker + 1)
        if (startDate && endDate) {
            setDueDateRangeStart(startDate)
            setDueDateRangeEnd(endDate)
            if (clickCountForDueDateRangePicker === 1) {
                setClickCountForDueDateRangePicker(0)
                setShowDueDateRangePicker(false)
            }
        } else if (startDate && !endDate) {
            setDueDateRangeStart(startDate)
            setDueDateRangeStart(startDate)
        }
    }

    const handleFocusChangeForDueDate = (input) => {
        if (!input) {
            setFocusedInputForDueDate('startDate')
        } else {
            setFocusedInputForDueDate(input)
        }
    }
    useEffect(() => {
        setDueDateRange(dueDateRangeStart && dueDateRangeEnd ? `${moment(dueDateRangeStart).format("DD MMM, YYYY")} - ${moment(dueDateRangeEnd).format("DD MMM, YYYY")}` : '');
    }, [dueDateRangeStart, dueDateRangeEnd])
    const invoiceDueDatePopover = () => {
        return <>
            <Overlay
                show={showDueDateRangePicker}
                target={targetForDueDateRangePicker}
                placement="bottom-start"
            >
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerRangeController
                            startDate={dueDateRangeStart}
                            endDate={dueDateRangeEnd}
                            numberOfMonths={2}
                            minimumNights={0}
                            firstDayOfWeek={0}
                            onDatesChange={onDatesChangeAtDueDate}
                            focusedInput={focusedInputForDueDate}
                            onFocusChange={(focusedInput) => handleFocusChangeForDueDate(focusedInput)}
                            renderKeyboardShortcutsButton={showHelpfulText}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "installment_due_on") {
                                    setShowDueDateRangePicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }

    const FilterForm = <>
        <div className={'row'}>
            <div className={'col-md-12'}>
                <Form onSubmit={handleSubmit(filterTheList)}>

                    <div className={'row g-3'}>
                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Smart Battery ID</SolTypography.Text></Form.Label>
                                </div>
                                <SolInput
                                    name={'device_sl'}
                                    type="text"
                                    placeholder={'Type Smart battery ID'}
                                    autoComplete={"off"}
                                    ref={register()}
                                />
                            </Form.Group>
                        </div>

                        <div className={'col-md-3'}>
                            <Form.Group>
                                <Form.Label><SolTypography.Text secondary>Transaction Date</SolTypography.Text></Form.Label>
                                <SolInput
                                    name={'transaction_date'}
                                    readOnly={true}
                                    value={dueDateRange}
                                    placeholder={"Select date range"}
                                    ref={register()}
                                    onClick={(e) => {
                                        setTargetForDueDateRangePicker(e.target)
                                        setShowDueDateRangePicker(!showDueDateRangePicker)
                                    }}
                                />
                            </Form.Group>
                        </div>

                        {invoiceDueDatePopover()}

                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Borrower</SolTypography.Text></Form.Label>
                                </div>
                                <Select
                                    name={"borrower"} // This name is used to show "n items selected text"
                                    placeholder={"Select Borrower"}
                                    isDisabled={props.collectingBorrowers}
                                    isLoading={props.collectingBorrowers}
                                    maxMenuHeight={200}
                                    value={borrower}
                                    isClearable={true}
                                    options={borrowers}
                                    isSearchable={true}
                                    isMulti={true}
                                    hideSelectedOptions={false}
                                    styles={customStylesForMultiSelectField}
                                    components={{ ValueContainer, Option }}
                                    onChange={(selected, { action }) => {
                                        if (action === "clear") {
                                            setBorrower('')
                                        }
                                        if (selected) {
                                            setBorrower(selected)
                                            setBorrowers(groupSelectedOptions(selected, borrowers))
                                        }
                                    }}
                                />
                                {errors.borrower? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.borrower.message}</div></> : null}
                            </Form.Group>
                        </div>

                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Garage</SolTypography.Text></Form.Label>
                                </div>
                                <Select
                                    name={'garage'} // This name is used to show 'n items selected' text
                                    placeholder={"Select Garage"}
                                    isDisabled={props.collectingBorrowers}
                                    isLoading={props.collectingBorrowers}
                                    maxMenuHeight={200}
                                    value={garage}
                                    isClearable={true}
                                    options={garages}
                                    isSearchable={true}
                                    isMulti={true}
                                    hideSelectedOptions={false}
                                    styles={customStylesForMultiSelectField}
                                    components={{ ValueContainer, Option }}
                                    onChange={(selected, { action }) => {
                                        if (action === "clear") {
                                            setGarage('')
                                        }
                                        if (selected) {
                                            setGarage(selected)
                                            setGarages(groupSelectedOptions(selected, garages))
                                        }
                                    }}
                                />
                                {errors.garage? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.garage.message}</div></> : null}
                            </Form.Group>
                        </div>
                    </div>

                    <div className={'row g-3'}>
                        {props.isSolshareUser? <>
                            <div className={'col-md-3'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label><SolTypography.Text secondary>Financier</SolTypography.Text></Form.Label>
                                    </div>
                                    <Select
                                        name={'financier'} // This name will be used to show 'n items selected' text
                                        placeholder={"Select Financier"}
                                        isDisabled={props.collectingFinanciers}
                                        isLoading={props.collectingFinanciers}
                                        maxMenuHeight={200}
                                        value={financier}
                                        isClearable={true}
                                        options={financiers}
                                        isSearchable={true}
                                        isMulti={true}
                                        hideSelectedOptions={false}
                                        styles={customStylesForMultiSelectField}
                                        components={{ ValueContainer, Option }}
                                        onChange={(selected, { action }) => {
                                            if (action === "clear") {
                                                setFinancier('')
                                                GET_BRANCHES_LIST()
                                            }
                                            setBranch('')
                                            if (selected) {
                                                setFinancier(selected)
                                                setFinanciers(groupSelectedOptions(selected, financiers))
                                                GET_BRANCHES_LIST({'organization_guid': formulateApiRequestDataFromSelectedItems(selected)})
                                            }
                                        }}
                                    />
                                    {errors.financier ? <><div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.financier.message}</div></> : null}
                                </Form.Group>
                            </div>
                        </>:null}

                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Branch</SolTypography.Text></Form.Label>
                                </div>
                                <Select
                                    name={'branch'} // This name will be used to show 'n items selected' text
                                    placeholder={"Select Branch"}
                                    isDisabled={props.collectingBranches}
                                    isLoading={props.collectingBranches}
                                    maxMenuHeight={200}
                                    value={branch}
                                    isClearable={true}
                                    options={branches}
                                    isSearchable={true}
                                    isMulti={true}
                                    hideSelectedOptions={false}
                                    styles={customStylesForMultiSelectField}
                                    components={{ ValueContainer, Option }}
                                    noOptionsMessage={() => "No branches available"}
                                    onChange={(selected, { action }) => {
                                        if (action === "clear") {
                                            setBranch('')
                                        }
                                        if (selected) {
                                            setBranch(selected)
                                            setBranches(groupSelectedOptions(selected, branches))
                                        }
                                    }}
                                />
                                {errors.branch ? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.branch.message}</div></> : null}
                            </Form.Group>
                        </div>

                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Contract Type</SolTypography.Text></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"lease_type"}
                                    rules={{}}
                                    render={({ onChange, onBlur, value,defaultValue, name, ref },
                                             { invalid, isTouched, isDirty }) => (
                                        <Select
                                            name={name}
                                            placeholder={"Select Contract Type"}
                                            maxMenuHeight={200}
                                            value={value}
                                            defaultValue={defaultValue}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={[
                                                { label: "Loan", value: "loan" },
                                                { label: "Lease", value: "lease" },
                                                { label: "Rent", value: "rent" }
                                            ]}
                                            isSearchable={true}
                                            styles={customStylesForSingleSelectField}
                                            onChange={(selected, { action }) => {
                                                if (action === "clear") {
                                                    setContractType('')
                                                }
                                                if (selected) {
                                                    setContractType(selected)
                                                }
                                                onChange(selected)
                                            }}
                                        />
                                    )}
                                />
                                {/*{errors.is_settled ? <><div className="text-danger">*/}
                                {/*    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.is_settled.message}</div></> : null}*/}
                            </Form.Group>
                        </div>


                        {/*<div className={'col-md-3'}>*/}
                        {/*    <Form.Group>*/}
                        {/*        <div>*/}
                        {/*            <Form.Label><SolTypography.Text secondary>Payment Channel</SolTypography.Text></Form.Label>*/}
                        {/*        </div>*/}
                        {/*        <Controller*/}
                        {/*            control={control}*/}
                        {/*            name={"payment_channel"}*/}
                        {/*            rules={{}}*/}
                        {/*            render={({ onChange, onBlur, value,defaultValue, name, ref },*/}
                        {/*                     { invalid, isTouched, isDirty }) => (*/}
                        {/*                <Select*/}
                        {/*                    name={name}*/}
                        {/*                    placeholder={"Select Channel Type"}*/}
                        {/*                    maxMenuHeight={200}*/}
                        {/*                    value={value}*/}
                        {/*                    defaultValue={defaultValue}*/}
                        {/*                    isClearable={true}*/}
                        {/*                    control={control}*/}
                        {/*                    inputRef={ref}*/}
                        {/*                    options={[*/}
                        {/*                        { label: "Digital Payment", value: "digital" },*/}
                        {/*                        { label: "Hybrid Payment", value: "hybrid" },*/}
                        {/*                        { label: "Cash", value: "cash"},*/}
                        {/*                        { label: "Unknown", value: "unknown"},*/}
                        {/*                    ]}*/}
                        {/*                    isSearchable={true}*/}
                        {/*                    styles={customStylesForSingleSelectField}*/}
                        {/*                    onChange={(selected, { action }) => {*/}
                        {/*                        if (action === "clear") {*/}
                        {/*                            setPaymentChannel('')*/}
                        {/*                        }*/}
                        {/*                        if (selected) {*/}
                        {/*                            setPaymentChannel(selected)*/}
                        {/*                        }*/}
                        {/*                        onChange(selected)*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*        /!*{errors.is_settled ? <><div className="text-danger">*!/*/}
                        {/*        /!*    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.is_settled.message}</div></> : null}*!/*/}
                        {/*    </Form.Group>*/}
                        {/*</div>*/}

                        {/*<div className={'col-md-3'}>*/}
                        {/*    <Form.Group>*/}
                        {/*        <div>*/}
                        {/*            <Form.Label><SolTypography.Text secondary>Payment Status</SolTypography.Text></Form.Label>*/}
                        {/*        </div>*/}
                        {/*        <Controller*/}
                        {/*            control={control}*/}
                        {/*            name={"invoice_status"}*/}
                        {/*            rules={{}}*/}
                        {/*            render={({ onChange, onBlur, value,defaultValue, name, ref },*/}
                        {/*                     { invalid, isTouched, isDirty }) => (*/}
                        {/*                <FilterReactSelect*/}
                        {/*                    name={name}*/}
                        {/*                    placeholder={"Select Payment Status"}*/}
                        {/*                    maxMenuHeight={200}*/}
                        {/*                    value={value}*/}
                        {/*                    defaultValue={defaultValue}*/}
                        {/*                    isClearable={true}*/}
                        {/*                    control={control}*/}
                        {/*                    inputRef={ref}*/}
                        {/*                    options={[*/}
                        {/*                        { label: "Due", value: "due" },*/}
                        {/*                        { label: "Partially Paid", value: "partial_paid"},*/}
                        {/*                        { label: "Paid", value: "paid"},*/}
                        {/*                        { label: "Overdue", value: "overdue"},*/}
                        {/*                    ]}*/}
                        {/*                    isSearchable={true}*/}
                        {/*                    onChange={(selected, { action }) => {*/}
                        {/*                        // if (action === "clear") {*/}
                        {/*                        //     setPaymentStatus('')*/}
                        {/*                        // }*/}
                        {/*                        onChange(selected)*/}
                        {/*                        // if (selected) {*/}
                        {/*                        //     setPaymentStatus(selected)*/}
                        {/*                        // }*/}
                        {/*                        return selected;*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*        /!*{errors.is_settled ? <><div className="text-danger">*!/*/}
                        {/*        /!*    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.is_settled.message}</div></> : null}*!/*/}
                        {/*    </Form.Group>*/}
                        {/*</div>*/}
                    </div>

                    <div className={'row'}>
                        <div className={"col-md-12"}>
                            <Button variant="warning" size={'sm'} type="submit"
                                    disabled={props.collectingPayments}>
                                Apply Filter
                            </Button>
                            <Button variant="outline-dark" size={'sm'} disabled={props.collectingPayments}
                                    onClick={() => { resetFilter() }}
                                    className={'ml-3'}>
                                Reset Filter
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
        <hr /></>

    return (
        <ContentWrapper isLoading={false} pageTitle={"Mobile Application Events"} showBackButton={false} showCardHeader={false}>
            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>Transactions</b></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            <IconButton onClick={filterToggle}>
                                <FilterListIcon color="disabled" fontSize="large" />
                            </IconButton>
                            <IconButton
                                onClick={invoicesWithoutPagination}
                                disabled={props.collectingInvoicesForExport || props.collectingInvoices}>
                                {props.collectingInvoicesForExport? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/>:
                                    <img src={require('../../../utils/asset/download-Button.svg')} alt="Download" />}
                            </IconButton>
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>

                <hr />
            </div>

            {showFilter ?
                FilterForm : null}

            <div className={isTabletOrMobile ? 'row' : 'row mt-7'}>
                <div className={'col-md-4'}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        spacing={1}
                    >
                        <Avatar alt={'TK'} src={require('../../../utils/asset/upward-graph.svg')} variant={'square'} />
                        <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                            <div><h5 style={{ "display": "inline" }}> <b>{`TK ${new Intl.NumberFormat('en-IN').format(props.payments?.total_payment?.toFixed(2) || 0)}`}</b> </h5></div>
                            <><small>Total Transaction Amount</small></>
                        </Stack>
                    </Stack>
                </div>
                <div className={isTabletOrMobile ? 'col-md-4 mt-5' : 'col-md-4'}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        spacing={1}
                    >
                        <Avatar alt={'TOTAL'} src={require('../../../utils/asset/star.svg')} variant={'square'} />
                        <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                            <div><h5 style={{ "display": "inline" }}> <b>{`TK ${new Intl.NumberFormat('en-IN').format(props.payments?.solshare_revenue?.toFixed(2) || 0)}`}</b> </h5></div>
                            <><small>SaaS Fee</small></>
                        </Stack>
                    </Stack>
                </div>
                <>
                    <div className={isTabletOrMobile ? 'col-md-4 mt-5' : 'col-md-4'}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-end"
                            spacing={1}
                        >
                            <Avatar alt={'ACTIVE'} src={require('../../../utils/asset/exclamation-mark.svg')} variant={'square'} />
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <div><h5 style={{ "display": "inline" }}> <b>{`TK ${Intl.NumberFormat('en-IN').format(props.payments?.financier_revenue?.toFixed(2) || 0)}`}</b> </h5></div>
                                <><small>Financier's Share</small></>
                            </Stack>
                        </Stack>
                    </div>
                </>
            </div>
            <br />
            <br />

            <div>

                {/* <div className={'row'}>
                    <div className={'col-lg-6'}><h1><b>Total transactions</b>&nbsp;{totalPayments}</h1></div>
                    <div className={'col-lg-6'}><h1><b>Total payments</b>&nbsp;{totalAmounts}</h1></div>
                </div> */}
                <DataTableContainer>
                    {!props.collectingPayments && payments ? <DataTable
                        columns={paymentsColumn}
                        isLoading={props.collectingPayments}
                        data={payments}
                        asyncPagination={true}
                        count={props.payments?.count}
                        itemsPerPage={props.payments?.page_size}
                        onChangePage={onChangePage}
                        page={page}
                    /> : <LoadingSpinner language={language} loadingSubText={"Collecting payments ..."} />}
                </DataTableContainer>

            </div>

        </ContentWrapper>
    );
};

Payments.propTypes = {

};

export default connect((state) => {
    return {
        authorization: state.auth.authorizations,
        language: state.auth.language,
        isSolshareUser: state.auth.is_solshare_user,
        isLoading: state.transactionReducer.isLoading,
        collectingPayments: state.transactionReducer.collectingPayments,
        collectingBorrowers: state.commonReducer.collectingBorrowers,
        borrowers: state.commonReducer.borrowers,
        garages: state.commonReducer.garages,
        collectingGarages: state.commonReducer.collectingGarages,
        errorMessageForGarageListCollection: state.commonReducer.errorMessageForGarageListCollection,
        collectingFinanciers: state.commonReducer.collectingFinanciers,
        financiers: state.commonReducer.financiers,
        payments: state.transactionReducer.payments,
        errorMessageForPaymentsCollection: state.transactionReducer.errorMessageForPaymentsCollection,
        invoicesForExport: state.transactionReducer.invoicesForExport,
        collectingInvoicesForExport: state.transactionReducer.collectingInvoicesForExport,
        branches: state.commonReducer.branches,
        collectingBranches: state.commonReducer.collectingBranches
    }
}, { ...transactionActions, ...commonActions })(Payments);