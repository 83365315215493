import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import VerifiedIcon from '@mui/icons-material/Verified';
import PendingIcon from '@mui/icons-material/Pending';
import {theme, useStylesForDatePicker} from "../../../utils/muiPickerCustomStyle";
import 'moment/locale/bn';
import 'moment/locale/en-gb';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContent from "react-bootstrap/TabContent";

import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {manualTransactionActions} from "../actions";
import {actions as commonActions} from "../../commonReduxActions/actions";
import ApprovedTransactions from './approvedTransactions'
import PendingTransactions from './pendingTransactions'
import {useSubheader} from "../../../../_metronic/layout";
import {Button, Form, Spinner} from "react-bootstrap";
import {Toolbar} from "../../evs/views/components";
import {DataTableContainer} from "../../evs/utils";
import DataTable from "../../../components/dataTable/DataTable";
import {LoadingSpinner} from "../../../components/LoadingSpinnerForDataTable";
import Select from "react-select";
import {SolInput} from "../../../components/SolStyledComponents/components";
import {useForm} from "react-hook-form";
import DateTimeFormat from "../../../utils/dateFormat/DateTimeFormat";
import {csvToArray, requestCycle} from "../../../utils/utilityFunctions";
import {toast} from "react-toastify";
import moment from "moment/moment";
import {ExportToCsv} from "export-to-csv";


const UploadTransactions = props => {
    const classes = useStylesForDatePicker();
    const subHeader = useSubheader();
    const [language, setLanguage] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [processedTransactions, setProcessedTransactions] = useState([]);
    const [key, setKey] = useState("approved");
    const subheader= useSubheader();
    const {errors, handleSubmit, setError,register} = useForm()
    const {UPLOAD_MANUAL_TRANSACTIONS, GET_DEVICE_LIST} = props;

    const wrongFileTypeMessage = language === "EN"? "Please provide a csv file!":"দয়া করে একটি সিএসভি ফাইল দিন!"

    const uploadTransactions = (payload) => {
        const file_csv = payload.csv_file[0]
        const reader = new FileReader();

        reader.onload = function (event) {
            let battaries = csvToArray(event.target.result);
            setTransactions(battaries)
        };
        reader.readAsText(file_csv)
    }

    useEffect(()=>{
        setLanguage(props.language)
        subHeader.setActionButtons(null)
        GET_DEVICE_LIST()
        // return ()=>{
        //     RETURN_TO_INITIAL()
        // }
    },[])


    useEffect(()=>{
        const processed_data = transactions.map((item)=>{
            const garage_name = getGarageName(item)
            return  {
                "bkash_transaction_id": item["Bkash Transaction ID"],
                "pay_amount": item["Amount in BDT"],
                "revenue_type": item["Revenue Type"],
                "dongle_id": item["SOLDongle ID"],
                "payment_date": item["Payment date"],
                "payment_mode": item["Payment mode"],
                "remarks": item["Remarks"],
                "garage_name": garage_name,
                "error": checkForError(item, garage_name)
            }
        })
        setProcessedTransactions(processed_data)
    },[transactions])

    const getGarageName=(item)=>{
        if (item["SOLDongle ID"] && Array.isArray(props.deviceList) && props.deviceList.some((device)=>device?.dongle_serial_number===item["SOLDongle ID"] && device.status==="active" && device.assignment_status==='assigned') ){
            return props.deviceList.find((device)=>device?.dongle_serial_number===item["SOLDongle ID"])["garage_name"]
        }
        return null
    }

    const checkForError=(item, garage_name)=>{
        const valid_keys = [
            "Bkash Transaction ID",
            "Amount in BDT",
            "Revenue Type",
            "SOLDongle ID",
            "Payment date",
            "Payment mode",
            "Remarks"]
        const revenue_type = ["Gross client collection", "Leasing Fee", "Gross client collection", "Leasing fee as gross client collection", "Net client collection"];
        const payment_mode = ["bKash", "Cash"];
        let msg = '';

        valid_keys.forEach((key) => {

            if (key!=="Bkash Transaction ID" && key!=="Remarks" && !item[key]){

                msg =msg.concat(`${key} required`);
            }
            if (key ==="Revenue Type" && revenue_type.indexOf(item[key])===-1 ){
                msg = msg.concat(`${key} must be of one of 5 pre-defined values`);
            }

            if (key==="SOLDongle ID" && Array.isArray(props.deviceList) && !props.deviceList.some((device)=>device?.dongle_serial_number===item[key] && device.status==="active" && device.assignment_status==='assigned') ){
                msg = msg.concat(`dongle ${item[key]} could not be found`);
            }

            const dateFormatRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i
            if(key ==="Payment date" && !item[key].match(dateFormatRegex)){
                msg = msg.concat("Invalid Date format");
            }

            if (key==="Bkash Transaction ID" && item["Payment mode"]==="bKash" && !item[key]){

                msg =msg.concat(`Bkash Transaction is not found`);
            }

            if (key==="Payment mode" && payment_mode.indexOf(item["Payment mode"])===-1 ){

                msg =msg.concat(`Invalid payment mode`);
            }

        })
        return msg

    }

    const columns = [
        {
            field: 'bkash_transaction_id',
            title: language === "EN"? "Bkash Transaciton Id":"নামফলকে থাকা ভোল্টেজ",
            // emptyValue: ()=>{
            //     return  <b style={{color: "red"}}>{"Required"}</b>
            // },
            render: (rowData)=>{
                if (rowData.error?.includes("Bkash Transaction is not found")){
                    return  <b style={{color: "red"}}>{"Bkash Transaction is not found"}</b>
                }
                if (Array.isArray(props.duplicates) && props.duplicates.indexOf(rowData.bkash_transaction_id)!==-1){
                    return  <b style={{color: "red"}}>{rowData.bkash_transaction_id}</b>
                }
                return  rowData.bkash_transaction_id
            }

            // disableClick: true
        },{
            field: 'dongle_id',
            title: language === "EN"? "Dongle Serial Number":"নামফলকে থাকা ভোল্টেজ",
            emptyValue: ()=>{
                return  <b style={{color: "red"}}>{"Required"}</b>
            },
            render: (rowData)=>{
                if (rowData.error?.includes(rowData.dongle_id)  ){
                    return  <b style={{color: "red"}}>{rowData.dongle_id}</b>
                }
                return  rowData.dongle_id
            }

            // disableClick: true
        },{
            field: 'garage_name',
            title: language === "EN"? "Garage":"নামফলকে থাকা ভোল্টেজ",
            emptyValue: ()=>{
                return <b style={{color: "red"}}>{"Garage not found"}</b>
            },

            // disableClick: true
        },
        {
            field: 'pay_amount',
            title: "Pay Amount",
            headerStyle: {
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return  <b style={{color: "red"}}>{"Required"}</b>
            },

            // disableClick: true
        },
        {
            field: 'payment_mode',
            title: "Payment mode",
            headerStyle: {
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return  <b style={{color: "red"}}>{"Required"}</b>
            },
            render: (rowData)=>{
                if (rowData.error?.includes("Invalid payment mode")  ){
                    return  <b style={{color: "red"}}>{"Invalid payment mode"}</b>
                }
                return  rowData.payment_mode
            }

            // disableClick: true
        },{
            field: 'payment_date',
            title: "Payment Date",
            emptyValue: ()=>{
                return  <b style={{color: "red"}}>{"Required"}</b>
            },
            // disableClick: true
            render: (rowData)=>{
                if (rowData.error?.includes("Invalid Date format")  ){
                    return  <b style={{color: "red"}}>{rowData.payment_date}</b>
                }
                return  rowData.payment_date
            }
        },
        {
            field: 'revenue_type',
            title: "Revenue Type",
            emptyValue: ()=>{
                return  <b style={{color: "red"}}>{"Required"}</b>
            },
            render: (rowData)=>{
                const revenue_type = ["Gross client collection", "Leasing Fee", "Gross client collection", "Leasing fee as gross client collection", "Net client collection"]
                if (revenue_type.indexOf(rowData.revenue_type)===-1  ){
                    return  <b style={{color: "red"}}>{rowData.revenue_type}</b>
                }
                return  rowData.revenue_type
            }

            // disableClick: true
        },

        {
            field: 'remarks',
            title: "Remarks",
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },


    ]

    const downloadCsvTemplate = () => {
        let currentDate = moment().format("DD_MMM_YYYY")
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: `` ,
            filename: `manual_transactions_` + currentDate,
            useTextFile: false,
            useBom: true,
            headers: ["Payment date", "Amount in BDT", "Revenue Type", "Payment mode", "Bkash Transaction ID", "SOLDongle ID", "Garage", "Remarks"]
        }
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv([{}])
    }

    const uploadTransaction=()=>{
        if (Array.isArray(processedTransactions) && processedTransactions.length>0){

            let errorFlag= false;
            processedTransactions.forEach((item)=>{
                if (item.error){

                    errorFlag = true;

                }
            });
            if (errorFlag) {
                toast.error("Please resolve the errors !")
                return
            }

            UPLOAD_MANUAL_TRANSACTIONS(processedTransactions)
        }
    }

    const renderTable=()=>{
        return <>
            <div className={"row mb-12 mt-12 no-padding"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>
                                <Button variant={'warning'} onClick={uploadTransaction} disabled={props.buttonLoading}>
                                    {props.buttonLoading===true? <><Spinner animation={'border'} size={'sm'} variant={'light'}/> </>:null}
                                    {language === "EN"? "Confirm":"নিশ্চিত করুন"}
                                </Button>
                            </b></h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
                <div className={"col-md-12"}>
                    <DataTableContainer>
                        <DataTable columns={columns} data={processedTransactions}>

                        </DataTable>
                    </DataTableContainer>
                </div>

            </div>
        </>
    }

    return (
        <ContentWrapper isLoading={false} pageTitle={language === "EN"? "Save manual transactions in a batch":"একসাথে একাধিক লেনদেন সংরক্ষণ করুন"} showBackButton={false} showCardHeader={true}>
            <div className={'row pl-5'}>
                <p className={'text-mute'}>A blank csv template can be downloaded from <a href={"#"} style={{'color': '#F18D00'}} onClick={() => {downloadCsvTemplate()}}>here</a>.</p>
            </div>
            <div className={'row pl-5'}>
                <h5>{language === 'EN'? 'Upload the transaction CSV file':'লেনদেনের সিএসভি ফাইল আপলোড করুন'}</h5>
                <br/>
                <br/>
                <div className={'col-md-12'}>
                    <Form onSubmit={handleSubmit(uploadTransactions)}>
                        <div className={'row'}>
                            {<div className={'row'}>
                                <div className={"col-md-12"}>
                                    <Form.Group>
                                        <Form.Control
                                            type="file"
                                            name={'csv_file'}
                                            ref={register({
                                                required: language === 'EN'? 'Please upload a file!':'ফাইলটি দিতে হবে!',
                                                validate: {
                                                    isCsv: value => value[0].type === 'text/csv' || wrongFileTypeMessage
                                                }
                                            })}
                                        />
                                        {errors.csv_file && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.csv_file.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>}
                        </div>
                        <div className={'row'}>
                            <Button variant="outline-dark" size={'sm'} onClick={()=> {props.history.goBack()}}>
                                {language === 'EN'? 'Cancel':'বাতিল করুন'}
                            </Button>
                            <Button variant="warning" size={'sm'} type="submit"
                                    disabled={props.buttonLoading}
                                    className={'ml-3'}
                            >
                                {language === 'EN'? 'Upload':'আপলোড'}
                            </Button>
                        </div>
                    </Form>
                </div>



            </div>
            {Array.isArray(processedTransactions) && processedTransactions.length>0?renderTable():null}
        </ContentWrapper>
    );
};

UploadTransactions.propTypes = {

};

export default connect((state) => {
    return {
        language: state.auth.language,
        isLoading: state.manualTransactionsReducer.isLoading,
        eventList: state.manualTransactionsReducer.events,
        listLoading: state.manualTransactionsReducer.listLoading,
        buttonLoading: state.manualTransactionsReducer.buttonLoading,
        iconLoading: state.manualTransactionsReducer.iconLoading,
        duplicates: state.manualTransactionsReducer.duplicates,
        deviceListLoading: state.commonReducer.listLoading,
        deviceList: state.commonReducer.deviceList,

    }
}, {...manualTransactionActions, ...commonActions}) (UploadTransactions);
