import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    settlements: undefined,
    settlementsForExport: undefined,
    summaryOfInvoices: undefined,
    settlementStatus: undefined,
    errorMessageForNewSettlement: undefined
};

/*Reducer for Loan's redux store*/
export const settlementReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.COLLECT_SETTLEMENT_LIST_REQUEST:
            return {
                ...state,
                settlements: undefined,
                settlementCollectionInProgress: true
            };
        case actionTypes.COLLECT_SETTLEMENT_LIST_SUCCESS:
            return {
                ...state,
                settlements: action.payload,
                settlementCollectionInProgress: false
            };
        case actionTypes.COLLECT_SETTLEMENT_LIST_FAILURE:
            toast.error("Can't collect the settlements! Please contact with an admin.");
            return {
                ...state,
                settlementCollectionInProgress: false
            };

        case actionTypes.COLLECT_SETTLEMENT_LIST_FOR_EXPORT_REQUEST:
            return {
                ...state,
                settlementsForExport: undefined,
                exportDataCollectionInProgress: true
            };
        case actionTypes.COLLECT_SETTLEMENT_LIST_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                settlementsForExport: action.payload,
                exportDataCollectionInProgress: false
            };
        case actionTypes.COLLECT_SETTLEMENT_LIST_FOR_EXPORT_FAILURE:
            toast.error("Can't collect data for export! Please contact with an admin.");
            return {
                ...state,
                exportDataCollectionInProgress: false
            };

        case actionTypes.COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT_REQUEST:
            return {
                ...state,
                summaryOfInvoices: undefined,
                summaryDataCollectionInProgress: true
            };
        case actionTypes.COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT_SUCCESS:
            return {
                ...state,
                summaryOfInvoices: action.payload,
                summaryDataCollectionInProgress: false
            };
        case actionTypes.COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT_FAILURE:
            toast.error("Can't collect invoices summary! Please contact with an admin.");
            return {
                ...state,
                summaryDataCollectionInProgress: false
            };

        case actionTypes.SETTLE_REQUEST:
            return {
                ...state,
                settlementStatus: undefined,
                settlementInProgress: true
            };
        case actionTypes.SETTLE_SUCCESS:
            return {
                ...state,
                settlementStatus: requestCycle.success,
                settlementInProgress: false
            };
        case actionTypes.SETTLE_FAILURE:
            return {
                ...state,
                settlementStatus: requestCycle.failed,
                settlementInProgress: false,
                errorMessageForNewSettlement: action.payload.error
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
