import React, {useEffect, useState, useRef} from 'react';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {connect, useSelector} from "react-redux";
import {useSubheader} from "../../../../_metronic/layout";
import Stack from "@mui/material/Stack";
import moment from "moment";
import {actions} from "../actions";
import {withRoles} from "../../../router/SecuredRoute";
import SummaryInfoForOverview from "./summaryInfo/summaryInfo";
import PerformanceInfoForOverview from "./performanceInfo/performanceInfo";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { styled } from "@mui/material/styles";
import {useMediaQuery} from "react-responsive";
import {LoadingSpinner} from "../../../components";
import {showNotifications} from "../../../utils/notification";
import {toast} from "react-toastify";
import {checkAuthorization} from "../../auth/authorization";
import {getServiceName} from "../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../utils/roleRelatedValues/actionNames";
// import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../_metronic/_partials/controls";


const Overview = props => {
    const subHeader = useSubheader();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [parentCardHeight, setParentCardHeight] = useState(0)
    const [summaryInfoFromGarage, setSummaryInfoFromGarage] = useState({})
    const [summaryInfoFromTransaction, setSummaryInfoFromTransaction] = useState({})
    const [highPerformerInfo, setHighPerformerInfo] = useState([])
    const [lowPerformerInfo, setLowPerformerInfo] = useState([])
    const parentCard = useRef(null)

    // Related to permissions
    const garageServiceName = getServiceName('garageService')
    const transactionServiceName = getServiceName('transactionService')

    const garageDashboardOverviewFeatureName = getFeatureName('dashboardOverviewFeatureForGarage')
    const transactionDashboardOverviewFeatureName = getFeatureName('dashboardOverviewFeatureForTransaction')

    const listActionName = getActionName('listAction')
    const rentedForTodayActionName = getActionName('overviewRentedForTodayAction')
    const kmTravelledActionName = getActionName('overviewKmTravelledAction')
    const co2EmissionActionName = getActionName('overviewCo2EmissionAction')
    const latePaymentActionName = getActionName('overviewLatePaymentAction')
    const overdueActionName = getActionName('overviewOverdueAction')
    const performanceActionName = getActionName('overviewPerformanceAction')

    const [showRentedForToday, setShowRentedForToday] = useState(false)
    const [showLatePayment, setShowLatePayment] = useState(false)
    const [showTravelledKm, setShowTravelledKm] = useState(false)
    const [showCo2Emission, setShowCo2Emission] = useState(false)
    const [showOverdue, setShowOverdue] = useState(false)
    const [showPerformance, setShowPerformance] = useState(false)


    const {
        COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE,
        COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE
    } = props;

    useEffect(() => {
        subHeader.setActionButtons(null)
        subHeader.setBreadCrumbComponent(null)

        setShowRentedForToday(checkAuthorization(props.authorization, garageServiceName,
            garageDashboardOverviewFeatureName, [rentedForTodayActionName]))
        setShowTravelledKm(checkAuthorization(props.authorization, garageServiceName,
            garageDashboardOverviewFeatureName, [kmTravelledActionName]))
        setShowCo2Emission(checkAuthorization(props.authorization, garageServiceName,
            garageDashboardOverviewFeatureName, [co2EmissionActionName]))

        if (checkAuthorization(props.authorization, transactionServiceName, transactionDashboardOverviewFeatureName, [listActionName])) {
            setShowLatePayment(checkAuthorization(props.authorization, transactionServiceName,
                transactionDashboardOverviewFeatureName, [latePaymentActionName]))
            setShowOverdue(checkAuthorization(props.authorization, transactionServiceName,
                transactionDashboardOverviewFeatureName, [overdueActionName]))
            setShowPerformance(checkAuthorization(props.authorization, transactionServiceName,
                transactionDashboardOverviewFeatureName, [performanceActionName]))
        }

        setParentCardHeight(parentCard.current.clientHeight)
        COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE()
        if (checkAuthorization(props.authorization, transactionServiceName, transactionDashboardOverviewFeatureName, [listActionName]) &&
            (checkAuthorization(props.authorization, transactionServiceName, transactionDashboardOverviewFeatureName, [latePaymentActionName]) ||
                checkAuthorization(props.authorization, transactionServiceName, transactionDashboardOverviewFeatureName, [overdueActionName]) ||
                checkAuthorization(props.authorization, transactionServiceName, transactionDashboardOverviewFeatureName, [performanceActionName]))) {
            COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE()
        }
    },[])

    useEffect(() => {
        if (props.summaryDataFromGarage) {
            setSummaryInfoFromGarage(props.summaryDataFromGarage)
        }
    },[props.summaryDataFromGarage])

    useEffect(() => {
        if (props.summaryDataFromTransaction) {
            setSummaryInfoFromTransaction(props.summaryDataFromTransaction)
            setHighPerformerInfo(props.summaryDataFromTransaction.top_performer_list)
            setLowPerformerInfo(props.summaryDataFromTransaction.under_performer_list)
        }
    },[props.summaryDataFromTransaction])

    useEffect(() => {
        if (!props.collectingSummaryDataFromGarage && !props.collectingSummaryDataFromTransaction) {
            if (props.summaryDataCollectionErrorFromGarage && props.summaryDataCollectionErrorFromTransaction) {
                toast.error("Failed to collect the overview data! Please contact with an admin.")
            } else if (!props.summaryDataCollectionErrorFromGarage && props.summaryDataCollectionErrorFromTransaction) {
                showNotifications('error', props.summaryDataCollectionErrorFromTransaction)
            } else if (props.summaryDataCollectionErrorFromGarage && !props.summaryDataCollectionErrorFromTransaction) {
                showNotifications('error', props.summaryDataCollectionErrorFromGarage)
            }
        }
    }, [props.summaryDataCollectionErrorFromGarage, props.summaryDataCollectionErrorFromTransaction,
        props.collectingSummaryDataFromGarage, props.collectingSummaryDataFromTransaction])

    return (
        <Card sx={!isTabletOrMobile? {'width': '100%', 'height': '100%'}:{}} ref={parentCard}>
            <CardHeader title={moment().format("MMMM DD, YYYY")} subheader={moment().format("hh:mm a")}/>
            <CardContent ref={el => {
                if (el && !isTabletOrMobile) {
                    el.style.setProperty('pb', '1px', 'important')
                }
            }}>
                <div className={'row'}>
                    <div className={showPerformance? 'col-xl-8':'col-xl-12'}>
                        <SummaryInfoForOverview
                            showRentedForToday={showRentedForToday}
                            showLatePayment={showLatePayment}
                            showTravelledKm={showTravelledKm}
                            showCo2Emission={showCo2Emission}
                            showOverdue={showOverdue}
                            showPerformance={showPerformance}
                            summaryDataFromGarage={summaryInfoFromGarage}
                            summaryDataFromTransaction={summaryInfoFromTransaction}
                        ></SummaryInfoForOverview>
                    </div>
                    {showPerformance? <>
                        <div className={'col-xl-4'} style={{minHeight: '200px'}}>
                            <PerformanceInfoForOverview parentCardHeight={parentCardHeight}
                                                        highPerformerData={highPerformerInfo}
                                                        lowPerformerData={lowPerformerInfo}></PerformanceInfoForOverview>
                        </div>
                    </>:''}
                </div>
            </CardContent>
        </Card>
    );
}

Overview.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        summaryDataFromGarage: state.overviewReducer.summaryDataFromGarage,
        collectingSummaryDataFromGarage: state.overviewReducer.collectingSummaryDataFromGarage,
        summaryDataCollectionErrorFromGarage: state.overviewReducer.summaryDataCollectionErrorFromGarage,
        summaryDataFromTransaction: state.overviewReducer.summaryDataFromTransaction,
        collectingSummaryDataFromTransaction: state.overviewReducer.collectingSummaryDataFromTransaction,
        summaryDataCollectionErrorFromTransaction: state.overviewReducer.summaryDataCollectionErrorFromTransaction
    }
}

export default connect(mapStateToProps, {...actions})(withRoles(Overview))
