import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Switch} from "react-router-dom";
import SecuredRoute from "../../router/SecuredRoute";
import PCBQaTestingResults from "./view/qaTestResults";
import UploadTransactions from "./view/uploadQaTestResults";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";

export const PcbQaTestingResultsRouter = props => {

    return (
        <div>
            <Switch>
                <Redirect exact={"true"} from={'/pcb-qa-testing-results'} to={'/pcb-qa-testing-results/list'} />

                <SecuredRoute
                    path={'/pcb-qa-testing-results/list'}
                    component={PCBQaTestingResults}
                    service={getServiceName('batteryService')}
                    feature={getFeatureName('productionLogFeature')}
                    action={getActionName('listAction')}
                />

                <SecuredRoute
                    path={"/pcb-qa-testing-results/create"}
                    component={UploadTransactions}
                    service={getServiceName('batteryService')}
                    feature={getFeatureName('productionLogFeature')}
                    action={getActionName('createAction')}
                />
                <Redirect to="/error/error-v1" />
            </Switch>
        </div>
    );
};
