import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import {DataTable, LoadingSpinner, SolTypography} from '../../../../components';
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {Toolbar} from "../components";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/styles";
import Stack from '@mui/material/Stack';
import moment from "moment/moment";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {toBengaliNumber} from "bengali-number";
import {convertToAnotherLang} from "../../../../utils/convertLanguageOfNumbersWithCharacter";
import {exportInExcel} from "../../../../utils/excelExport";

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const ProvidedSmartBatteriesInfo = ({ data, ...props }) => {
    const [language, setLanguage] = useState("EN");
    const classes = useStylesIconButton();
    const [smartBatteries, setSmartBatteries] = useState([]);

    const {
        COLLECT_PROVIDED_SMART_BATTERIES_INFO,
    } = props;

    useEffect(()=>{
        setLanguage(props.language)
        COLLECT_PROVIDED_SMART_BATTERIES_INFO(data.pk);
    }, [])

    useEffect(()=>{
        if (props.providedSmartBatteries) {
            const smartBatteries = props.providedSmartBatteries
            if (smartBatteries && smartBatteries.length > -1) {
                setSmartBatteries(smartBatteries.map((smartBattery) => {
                    return {
                        ...smartBattery
                    }
                }))
            }

        }
    }, [props.providedSmartBatteries])

    const SMART_BATTERY_INFO_COLUMNS = [
        {
            field: 'serial_number',
            title: language === 'EN' ? 'Smart Battery' : 'স্মার্ট ব্যাটারী',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    <h6><strong>{rowData.serial_number}</strong></h6>
                    <span style={{whiteSpace: 'nowrap'}}>SOLdongle #{rowData.dongle_serial_number}</span><br/>
                    <span style={{whiteSpace: 'nowrap'}}>Battery SL {rowData.battery_serial_number}</span><br/>
                    {rowData.battery_details? <>
                        {rowData.battery_details.battery_vendor && rowData.battery_details.nameplate_voltage && rowData.battery_details.nameplate_capacity?
                            <><span style={{whiteSpace: 'nowrap'}}>{rowData.battery_details.battery_vendor} {rowData.battery_details.nameplate_voltage}V, {rowData.battery_details.nameplate_capacity}AH</span><br/></>:
                            rowData.battery_details.battery_vendor && rowData.battery_details.nameplate_voltage? <><span style={{whiteSpace: 'nowrap'}}>{rowData.battery_details.battery_vendor} {rowData.battery_details.nameplate_voltage}V</span><br/></>:
                                rowData.battery_details.battery_vendor && rowData.battery_details.nameplate_capacity? <><span style={{whiteSpace: 'nowrap'}}>{rowData.battery_details.battery_vendor} {rowData.battery_details.nameplate_capacity}AH</span><br/></>:
                                    rowData.battery_details.nameplate_voltage && rowData.battery_details.nameplate_capacity? <><span style={{whiteSpace: 'nowrap'}}>{rowData.battery_details.nameplate_voltage}V, {rowData.battery_details.nameplate_capacity}AH</span><br/></>:
                                        rowData.battery_details.battery_vendor? <><span style={{whiteSpace: 'nowrap'}}>{rowData.battery_details.battery_vendor}</span><br/></>:
                                            rowData.battery_details.nameplate_voltage? <><span style={{whiteSpace: 'nowrap'}}>{rowData.battery_details.nameplate_voltage}V</span><br/></>:
                                                rowData.battery_details.nameplate_capacity? <><span style={{whiteSpace: 'nowrap'}}>{rowData.battery_details.nameplate_capacity}AH</span><br/></>:null}
                        {rowData.battery_details.warranty_end_date? <>
                            <span style={{whiteSpace: 'nowrap'}}>Warranty Till {moment(rowData.battery_details.warranty_end_date, "YYYY-MM-DD").format('MMM YYYY')}</span>
                        </>:null}
                    </>:null}
                </>
            }
        },
        {
            field: 'operating_from',
            title: language === 'EN' ? 'Operating From' : 'সক্রিয় হওয়ার তারিখ',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                let operatingSinceText = ''
                let operatingFromDate = moment(rowData.operating_from)
                let currentDate = moment()
                let differenceInYears = parseInt(currentDate.diff(operatingFromDate, 'years', true))
                if (differenceInYears > 0) {
                    operatingFromDate = operatingFromDate.add(differenceInYears, 'years')
                    operatingSinceText += differenceInYears > 1? differenceInYears + ' years':differenceInYears + ' year'
                }
                let differenceInMonths = parseInt(currentDate.diff(operatingFromDate, 'months', true))
                if (differenceInMonths > 0) {
                    operatingFromDate = operatingFromDate.add(differenceInMonths, 'months')
                    operatingSinceText += differenceInMonths > 1? ' ' + differenceInMonths + ' months':' ' + differenceInMonths + ' month'
                }
                let differenceInDays = parseInt(currentDate.diff(operatingFromDate, 'days', true))
                if (differenceInDays > 0) {
                    operatingSinceText += differenceInDays > 1? ' ' + differenceInDays + ' days':' ' + differenceInDays + ' day'
                }
                return <>
                    <span style={{whiteSpace: 'nowrap'}}>{moment(rowData.operating_from).format('MMMM DD, YYYY')}</span><br/>
                    <small><strong>{operatingSinceText}</strong></small>
                </>
            }
        },
        {
            field: 'contract_details',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            title: language === 'EN' ? 'Lease Payment (Tk)' : 'ঋণ পরিশোধ (টাকায়)',
            emptyValue: (rowData) => {
                return <>-</>
            },
            render: (rowData) => {
                return <>
                    {rowData.contract_details.contract_type === 'rent'? <>
                        <h6 style={{whiteSpace: 'nowrap'}}><strong>Tk {new Intl.NumberFormat('en-IN').format(rowData.contract_details.per_rent_payable.toFixed(2))} Per Rent</strong></h6>
                        <h6 style={{whiteSpace: 'nowrap'}}><strong>Loan Contract #{rowData.contract_details.contract_id}</strong></h6>
                    </>:<>
                        {rowData.contract_details.lease_payment_interval === 'weekly'? <>
                            <h6 style={{whiteSpace: 'nowrap'}}><strong>Tk {new Intl.NumberFormat('en-IN').format(rowData.contract_details.per_installment_payable.toFixed(2))} Per Week</strong></h6>
                            <h6 style={{whiteSpace: 'nowrap'}}><strong>Loan Contract #{rowData.contract_details.contract_id}</strong></h6>
                        </>:<>
                            <h6 style={{whiteSpace: 'nowrap'}}><strong>Tk {new Intl.NumberFormat('en-IN').format(rowData.contract_details.per_installment_payable.toFixed(2))} Per Month</strong></h6>
                            <h6 style={{whiteSpace: 'nowrap'}}><strong>Loan Contract #{rowData.contract_details.contract_id}</strong></h6>
                        </>}
                    </>}
                </>
            },
        },
        {
            field: 'total_number_of_rents',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            title: language === 'EN' ? '# of Rents' : 'ভাড়ার সংখ্যা',
            render: (rowData) => {
                return <>
                    {rowData.total_number_of_rents? <h6><strong>{new Intl.NumberFormat('en-IN').format(rowData.total_number_of_rents)}</strong></h6>:
                        rowData.total_number_of_rents === 0? <h6><strong>0</strong></h6>:<h6><strong>-</strong></h6>}
                </>
            },
        },
        // {
        //     field: 'active_days',
        //     cellStyle: {
        //         textAlign: 'right'
        //     },
        //     headerStyle: {
        //         paddingRight: '0',
        //         textAlign: 'right'
        //     },
        //     title: language === 'EN' ? 'Active days' : 'সক্রিয় দিনের সংখ্যা',
        //     render: () => {
        //         return <>
        //             <h4>TBD</h4>
        //         </>
        //     },
        // }
    ]

    const exportData = () => {
        let presentTime = moment()
        let totalSmartBatteries = smartBatteries.length
        let garage = data.name
        if (data.upazila && data.district) {
            garage += ', ' + data.upazila + ', ' + data.district
        } else if (data.district) {
            garage += ', ' + data.district
        } else if (data.upazila) {
            garage += ', ' + data.upazila
        }
        let totalSmartBatteriesText = "Total: " + smartBatteries.length
        let exportText = "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A")
        let excelRows = [
            ["Smart Batteries", "", "", "", "", "", "", "", "", "", ""],
            ["Garage: " + garage, "", "", "", "", "", "", "", "", "", ""],
            [totalSmartBatteriesText, "", "", "", "", "", "", "", "", "", ""],
            [exportText, "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", "", ""],
            ["Smart Battery", "SOLdongle", "Battery", "Operating from", "Lease Payment (Tk)", "Loan Ref/ID",
                "Number of rents", "Battery Vendor", "Battery nameplate voltage",
                "Battery nameplate capacity", "Warranty till"]
        ]
        for (let i=0; i<totalSmartBatteries; i++) {
            let leasePaymentText = ''
            if (smartBatteries[i].contract_details && smartBatteries[i].contract_details.contract_type === 'rent') {
                // Rent
                leasePaymentText = 'Tk ' + new Intl.NumberFormat('en-IN').format(smartBatteries[i].contract_details.per_rent_payable.toFixed(2)) + ' Per Rent'
            } else {
                // Lease
                if (smartBatteries[i].contract_details && smartBatteries[i].contract_details.lease_payment_interval === 'weekly') {
                    leasePaymentText = 'Tk ' + new Intl.NumberFormat('en-IN').format(smartBatteries[i].contract_details.per_installment_payable.toFixed(2)) + ' Per Week'
                } else if (smartBatteries[i].contract_details && smartBatteries[i].contract_details.lease_payment_interval === 'monthly') {
                    leasePaymentText = 'Tk ' + new Intl.NumberFormat('en-IN').format(smartBatteries[i].contract_details.per_installment_payable.toFixed(2)) + ' Per Month'
                }
            }
            excelRows.push(
                [
                    smartBatteries[i].serial_number,
                    smartBatteries[i].dongle_serial_number,
                    smartBatteries[i].battery_serial_number,
                    moment(smartBatteries[i].operating_from).format('MMMM DD, YYYY'),
                    leasePaymentText,
                    smartBatteries[i].contract_details?.contract_id? smartBatteries[i].contract_details.contract_id:'',
                    smartBatteries[i].total_number_of_rents? new Intl.NumberFormat('en-IN').format(smartBatteries[i].total_number_of_rents):smartBatteries[i].total_number_of_rents === 0? '0':'-',
                    smartBatteries[i].battery_details && smartBatteries[i].battery_details.battery_vendor? smartBatteries[i].battery_details.battery_vendor:'-',
                    smartBatteries[i].battery_details && smartBatteries[i].battery_details.nameplate_voltage? smartBatteries[i].battery_details.nameplate_voltage:'-',
                    smartBatteries[i].battery_details && smartBatteries[i].battery_details.nameplate_capacity? smartBatteries[i].battery_details.nameplate_capacity:'-',
                    smartBatteries[i].battery_details && smartBatteries[i].battery_details.warranty_end_date? moment(smartBatteries[i].battery_details.warranty_end_date, "YYYY-MM-DD").format('MMM YYYY'):'-'
                ])
        }
        let noDataAvailableText =  language === 'EN'? "No data available":"কোন তথ্য নেই"
        totalSmartBatteries > 0? excelRows.push(
            ["", "", "", "", "", "", "", "", "", "", ""]
        ):excelRows.push(["", "", "", "", "", noDataAvailableText, "", "", "", "", ""])
        let fileName = 'provided_smart_batteries_'
        exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
    }

    return (
        <div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <Toolbar>
                        <Toolbar.Title>
                            <Stack>
                                <span>{data.name}</span>
                                {data.upazila && data.district? <span>{data.upazila}, {data.district}</span>:data.district? <span>{data.district}</span>:data.upazila? <span>{data.upazila}</span>:null}
                            </Stack>
                        </Toolbar.Title>
                        <Toolbar.ToolbarContainer>
                            <Toolbar.ToolbarContainer.Items>
                                <IconButton classes={classes} onClick={exportData}
                                            disabled={props.smartBatteryInfoCollectionInProgress}>
                                        <img src={require('../../../../utils/asset/download-Button.svg')}
                                             alt="Download"/>
                                </IconButton>
                            </Toolbar.ToolbarContainer.Items>
                        </Toolbar.ToolbarContainer>
                    </Toolbar>
                </div>
            </div>
            <div className={'row mt-10'}>
                <div className={'col-md-12'}>
                    {!props.smartBatteryInfoCollectionInProgress? <>
                        <DataTable
                            language={language}
                            noDataAvailableMessageInBangla={'কোন যন্ত্র নেই'}
                            columns={SMART_BATTERY_INFO_COLUMNS}
                            data={smartBatteries}
                            showToolbar={false}
                            asyncPagination={false}
                            isLoading={props.tableLoading}
                            pagination={true}
                            pageSize={3}
                        />
                    </> : <LoadingSpinner
                        loadingSubText={language === 'EN' ? 'Fetching smart batteries info ..' : 'গ্যারেজকে দেওয়া যন্ত্রের তথ্য সংগ্রহ হচ্ছে ..'}
                        language={language} />}
                </div>
            </div>
        </div>
    );
};

ProvidedSmartBatteriesInfo.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        providedSmartBatteries: state.borrowersReducer.providedSmartBatteries,
        smartBatteryInfoCollectionInProgress: state.borrowersReducer.smartBatteryInfoCollectionInProgress,
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(ProvidedSmartBatteriesInfo));