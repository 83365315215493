import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import React, {Suspense, lazy, useEffect, useState} from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../_metronic/layout";
import DashboardPage from "../pages/DashboardPage";
import { DemoRouter } from "../features/demoComponent/router";
import { UsersRouter } from "../features/users/router";
import { RolesRouter } from "../features/roles/router";
import { OrganizationsRouter } from "../features/organizations/router";
import {TokensRouter} from "../features/tokens/router";
import { DriversRouter } from "../features/drivers/router";
import {RentLogRouter} from "../features/rentLog/router";
import { TransactionRouter } from "../features/transactions/router";
import { SmartDonglesRouter } from "../features/smartDongles/router";
import { BatteriesRouter } from "../features/battery/router";
import { BatteryLogRouter } from "../features/batteryLog/router";
import { DevicesRouter } from "../features/devices/router";
import { EvsRouter } from "../features/evs/router";
import { ManualTransactionsRouter } from "../features/manualTransactions/router";
import { useSelector } from "react-redux";
import AppEventsRouter from "../features/appEvents/router";
import {OtaRouter} from "../features/ota/router";
import {PcbQaTestingResultsRouter} from "../features/pcbQaTestingResults/router";
import {HistoricalLogRouter} from "../features/historicalLog/router";
import {SummaryReportRouter} from "../features/summary/router";
import {BorrowersRouter} from "../features/borrowers/router";
import {ContractManagementRouter} from "../features/contractManagement/router";
import {InvoicesRouter} from "../features/invoices/router";
import {settlementRouter} from "../features/settlements/router";
import {OverviewRouter} from "../features/overview/router";
import {HomeRouter} from "../features/home/router";
import {SearchResultRouter} from "../features/search/router";
import {DepositsRouter} from "../features/deposits/router";
import {NotificationsRouter} from "../features/notifications/router";
import {FinancierRouter} from "../features/financiers/router";
import Home from "../features/home/Home";
import {getFeatureName} from "../utils/roleRelatedValues/featureNames";
import {getActionName} from "../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../features/auth/authorization";
import {getServiceName} from "../utils/roleRelatedValues/serviceNames";

export default function BasePage() {
    // const user = useSelector(state => state.auth)
    // const [role, setRole] = useState(null)
    // useEffect(() => {
    //     if (user) {
    //         setRole(user.user.role)
    //     }
    // }, [])
    const authorizations = useSelector(state => state.auth.authorizations? state.auth.authorizations:{})
    const garageService = getServiceName('garageService')
    // const overviewFeatureGarage = getFeatureName('dashboardOverviewFeatureForGarage')
    const dashboardLandingPageFeature = getFeatureName('dashboardLandingPageFeature')
    const listAction = getActionName('listAction')

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {/* Redirect from root URL to /dashboard. */}
                {/* Garage dashboard overview access is enough as "Total Batteries Deployed" card which represents data
                from Garage Service, will remain always visible, if user has access to overview page. Access to
                transaction overview access doesn't matter here - Noor Reza, 16th Jan, 2024 10:21 pm */}
                {/*<Redirect exact from="/" to={checkAuthorization(authorizations, garageService, overviewFeatureGarage, [listAction])? "/overview":"/home"}/>*/}
                <Redirect exact from="/" to={checkAuthorization(authorizations, garageService, dashboardLandingPageFeature, [listAction])? "/overview":"/home"}/>
                <ContentRoute path="/overview" component={OverviewRouter}/>
                <ContentRoute path="/home" component={HomeRouter}/>
                <ContentRoute path="/search" component={SearchResultRouter}/>
                <ContentRoute path="/users" component={UsersRouter}/>
                <ContentRoute path="/roles" component={RolesRouter}/>
                {/*<ContentRoute path="/tokens" component={TokensRouter}/>*/}
                <ContentRoute path="/organizations" component={OrganizationsRouter}/>
                <ContentRoute path="/rents" component={RentLogRouter}/>
                {/* Following url/path is added as it has been used in the automated mail throughout the company*/}
                <ContentRoute path="/checkout/list" component={RentLogRouter}/>
                <ContentRoute path="/payments" component={TransactionRouter}/>
                <ContentRoute path="/drivers" component={DriversRouter}/>
                <ContentRoute path="/dongles" component={SmartDonglesRouter}/>
                <ContentRoute path="/batteries" component={BatteriesRouter}/>
                <ContentRoute path="/battery-logs" component={BatteryLogRouter}/>
                <ContentRoute path="/devices" component={DevicesRouter}/>
                <ContentRoute path="/evs" component={EvsRouter}/>
                <ContentRoute path="/ota" component={OtaRouter}/>
                <ContentRoute path="/app-events" component={AppEventsRouter}/>
                <ContentRoute path="/transactions" component={ManualTransactionsRouter}/>
                <ContentRoute path="/pcb-qa-testing-results" component={PcbQaTestingResultsRouter}/>
                <ContentRoute path="/historical-data" component={HistoricalLogRouter}/>
                <ContentRoute path="/summary-report" component={SummaryReportRouter}/>
                <ContentRoute path="/borrowers" component={BorrowersRouter}/>
                <ContentRoute path="/contract-management" component={ContractManagementRouter}/>
                <ContentRoute path="/invoices" component={InvoicesRouter}/>
                <ContentRoute path="/settlements" component={settlementRouter}/>
                <ContentRoute path="/deposits" component={DepositsRouter}/>
                <ContentRoute path="/notifications" component={NotificationsRouter}/>
                <ContentRoute path="/financiers" component={FinancierRouter}/>
                <Redirect to="/error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
