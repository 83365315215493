export const CLEAR_REFRESH_TOKEN_PROMISE = 'CLEAR_REFRESH_TOKEN_PROMISE'
export const SAVE_REFRESH_TOKEN_PROMISE = 'SAVE_REFRESH_TOKEN_PROMISE'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const INCREASE_COUNTER = 'INCREASE_COUNTER';
export const CLEAR_COUNTER = 'CLEAR_COUNTER';

export const clearRefreshTokenPromise = () => ({
  type: CLEAR_REFRESH_TOKEN_PROMISE
})

export const saveRefreshTokenPromise = promise => ({
  type: SAVE_REFRESH_TOKEN_PROMISE,
  promise
})

export const increaseCounter = () => ({
  type: INCREASE_COUNTER
})

export const clearCounter = () => ({
  type: CLEAR_COUNTER,
})

export const setAccessToken = ({ access_token, refresh_token, expires_in }) => ({
  type: SET_ACCESS_TOKEN,
  access_token,
  refresh_token,
  expires_in
})
