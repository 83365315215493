import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */

    COLLECT_DEPOSITS: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/topup', filter),
            backend_slug: 'transaction_management',
            method: 'GET',
            types: [
                actionTypes.COLLECT_DEPOSITS_REQUEST,
                actionTypes.COLLECT_DEPOSITS_SUCCESS,
                actionTypes.COLLECT_DEPOSITS_FAILURE,
            ]
        }
    }),

    COLLECT_DEPOSITS_FOR_EXPORT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/topup', filter),
            backend_slug: 'transaction_management',
            method: 'GET',
            types: [
                actionTypes.COLLECT_DEPOSITS_FOR_EXPORT_REQUEST,
                actionTypes.COLLECT_DEPOSITS_FOR_EXPORT_SUCCESS,
                actionTypes.COLLECT_DEPOSITS_FOR_EXPORT_FAILURE,
            ]
        }
    }),

    COLLECT_DEPOSITS_DETAILS: (depositGuid)=>({
        [CALL_API]: {
            endpoint: '/cash_payment/details/' + depositGuid,
            backend_slug: 'transaction_management',
            method: 'GET',
            types: [
                actionTypes.COLLECT_DEPOSITS_DETAILS_REQUEST,
                actionTypes.COLLECT_DEPOSITS_DETAILS_SUCCESS,
                actionTypes.COLLECT_DEPOSITS_DETAILS_FAILURE,
            ]
        }
    }),

    COLLECT_GARAGE_LIST: ()=>({
        [CALL_API]: {
            endpoint: '/garages',
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_GARAGE_LIST_REQUEST,
                actionTypes.COLLECT_GARAGE_LIST_SUCCESS,
                actionTypes.COLLECT_GARAGE_LIST_FAILURE,
            ]
        }
    }),

    COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT: () => ({
        [CALL_API]: {
            endpoint: '/users?user_type=garage_owner',
            method: 'GET',
            types: [
                actionTypes.COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT_REQUEST,
                actionTypes.COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT_SUCCESS,
                actionTypes.COLLECT_BORROWERS_FOR_NEW_CASH_DEPOSIT_FAILURE,
            ]
        }
    }),

    COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT: () => ({
        [CALL_API]: {
            endpoint: '/devices?assignment_status=assigned',
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT_REQUEST,
                actionTypes.COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT_SUCCESS,
                actionTypes.COLLECT_SMART_BATTERIES_FOR_NEW_CASH_DEPOSIT_FAILURE,
            ]
        }
    }),

    COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/invoices', filter),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT_REQUEST,
                actionTypes.COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT_SUCCESS,
                actionTypes.COLLECT_INVOICES_FOR_NEW_CASH_DEPOSIT_FAILURE
            ]
        }
    }),

    SAVE_NEW_CASH_DEPOSIT: (payload)=>({
        [CALL_API]: {
            endpoint: '/cash_payment',
            method: 'POST',
            formDataFlag: true,
            body: payload,
            backend_slug: 'transaction_management_v2',
            types: [
                actionTypes.SAVE_NEW_CASH_DEPOSIT_REQUEST,
                actionTypes.SAVE_NEW_CASH_DEPOSIT_SUCCESS,
                actionTypes.SAVE_NEW_CASH_DEPOSIT_FAILURE
            ]
        }
    }),

    COLLECT_CURRENT_BALANCE: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/entity_balance', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_CURRENT_BALANCE_REQUEST,
                actionTypes.COLLECT_CURRENT_BALANCE_SUCCESS,
                actionTypes.COLLECT_CURRENT_BALANCE_FAILURE
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE}),
    RETURN_TO_INITIAL_STATE_FOR_NEW_CASH_DEPOSIT: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE_FOR_NEW_CASH_DEPOSIT})
};
