import React from "react";
import {Badge} from "react-bootstrap";

export const organizationListColumns = [

    {
        field: 'name',
        title: "Name",
        emptyValue: ()=>{
            return "N/A"
        },
        cellStyle: {
            color: '#F18D00',
            paddingLeft: '0'
        },
        headerStyle: {
            paddingLeft: '0'
        },
        // disableClick: true,
    },
    {
        field: 'phone',
        title: "Phone",
        emptyValue: ()=>{
            return "N/A"
        },
        disableClick: true,
    },
    {
        field: 'address',
        title: "Address",
        emptyValue: ()=>{
            return "N/A"
        },
        disableClick: true,
    }
]
