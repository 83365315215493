import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    evs: undefined,
    errorMessageForEvListCollection: undefined,
    evDetails: undefined,
    transactions: undefined,
    errorMessageForTransactionsCollection: undefined,
    topUpDetails: undefined,
    errorMessageForTopUpDetails: undefined,
    rentList: undefined,
    errorMessageForRentListCollection: undefined,
    driverDetails: undefined,
    errorMessageForDriverDetails: undefined,
    locationData: undefined,
    errorMessageForLocationDataCollection: undefined
};

/*Reducer for Loan's redux store*/
export const searchResultReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.COLLECT_EV_LIST_REQUEST:
            return {
                ...state,
                evs: undefined,
                errorMessageForEvListCollection: undefined,
                collectingEvs: true
            };
        case actionTypes.COLLECT_EV_LIST_SUCCESS:
            return {
                ...state,
                evs: action.payload,
                collectingEvs: false
            };
        case actionTypes.COLLECT_EV_LIST_FAILURE:
            return {
                ...state,
                collectingEvs: false,
                errorMessageForEvListCollection: action.payload.error
            };

        case actionTypes.COLLECT_EV_DETAILS_REQUEST:
            return {
                ...state,
                evDetails: undefined,
                errorMessageForEvDetailsCollection: undefined,
                collectingEvDetails: true
            };
        case actionTypes.COLLECT_EV_DETAILS_SUCCESS:
            return {
                ...state,
                evDetails: action.payload,
                collectingEvDetails: false
            };
        case actionTypes.COLLECT_EV_DETAILS_FAILURE:
            return {
                ...state,
                collectingEvDetails: false,
                errorMessageForEvDetailsCollection: action.payload.error
            };

        case actionTypes.COLLECT_TRANSACTIONS_REQUEST:
            return {
                ...state,
                transactions: undefined,
                errorMessageForTransactionsCollection: undefined,
                collectingTransactions: true
            };
        case actionTypes.COLLECT_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                transactions: action.payload,
                collectingTransactions: false
            };
        case actionTypes.COLLECT_TRANSACTIONS_FAILURE:
            return {
                ...state,
                collectingTransactions: false,
                errorMessageForTransactionsCollection: action.payload.error
            };

        case actionTypes.COLLECT_PAYMENT_DETAILS_REQUEST:
            return {
                ...state,
                topUpDetails: undefined,
                errorMessageForTopUpDetails: undefined,
                collectingTopUpDetails: true
            };
        case actionTypes.COLLECT_PAYMENT_DETAILS_SUCCESS:
            return {
                ...state,
                topUpDetails: action.payload,
                collectingTopUpDetails: false
            };
        case actionTypes.COLLECT_PAYMENT_DETAILS_FAILURE:
            return {
                ...state,
                collectingTopUpDetails: false,
                errorMessageForTopUpDetails: action.payload.error
            };

        case actionTypes.COLLECT_RENT_LIST_REQUEST:
            return {
                ...state,
                rentList: undefined,
                errorMessageForRentListCollection: undefined,
                collectingRents: true
            };
        case actionTypes.COLLECT_RENT_LIST_SUCCESS:
            return {
                ...state,
                rentList: action.payload,
                collectingRents: false
            };
        case actionTypes.COLLECT_RENT_LIST_FAILURE:
            return {
                ...state,
                collectingRents: false,
                errorMessageForRentListCollection: action.payload.error
            };

        case actionTypes.COLLECT_DRIVER_DETAILS_REQUEST:
            return {
                ...state,
                driverDetails: undefined,
                errorMessageForDriverDetails: undefined,
                collectingDriverDetails: true
            };
        case actionTypes.COLLECT_DRIVER_DETAILS_SUCCESS:
            return {
                ...state,
                driverDetails: action.payload,
                collectingDriverDetails: false
            };
        case actionTypes.COLLECT_DRIVER_DETAILS_FAILURE:
            return {
                ...state,
                collectingDriverDetails: false,
                errorMessageForDriverDetails: action.payload.error
            };

        case actionTypes.COLLECT_LOCATIONS_REQUEST:
            return {
                ...state,
                locationData: undefined,
                errorMessageForLocationDataCollection: undefined,
                collectingLocationData: true
            };
        case actionTypes.COLLECT_LOCATIONS_SUCCESS:
            return {
                ...state,
                locationData: action.payload,
                collectingLocationData: false
            };
        case actionTypes.COLLECT_LOCATIONS_FAILURE:
            return {
                ...state,
                locationData: false,
                errorMessageForLocationDataCollection: action.payload.error
            };

        case actionTypes.RETURN_TO_INITIAL_STATE_FOR_SEARCH:
            return initialState

        default:
            return state;
    }
};
