/**
 * Check if the user is authorized to access a certain feature
 * if any of the actions [passed in an array] is available for a feature under a certain service
 * within the permission/authorization JSON
 * @param {json} authorizations - Authorization JSON.
 * @param {string} serviceName - Name of the service.
 * @param {string} featureName - Name of the feature.
 * @param {array} actionName - List of actions.
 */
export function checkAuthorization (authorizations, serviceName, featureName, actionName) {
    let authorized = (authorizations && authorizations.hasOwnProperty(serviceName) &&
        authorizations[serviceName].hasOwnProperty(featureName) &&
        authorizations[serviceName][featureName].some(e => actionName.includes(e)))
    return authorized
}
