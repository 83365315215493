export const manualTransactionActionTypes = {
    FETCH_MANUAL_TRANSACTION_BATCH_REQUEST: "FETCH_MANUAL_TRANSACTION_BATCH_REQUEST",
    FETCH_MANUAL_TRANSACTION_BATCH_SUCCESS: "FETCH_MANUAL_TRANSACTION_BATCH_SUCCESS",
    FETCH_MANUAL_TRANSACTION_BATCH_FAILURE: "FETCH_MANUAL_TRANSACTION_BATCH_FAILURE",

    FETCH_MANUAL_TRANSACTION_PAYMENTS_REQUEST: "FETCH_MANUAL_TRANSACTION_PAYMENTS_REQUEST",
    FETCH_MANUAL_TRANSACTION_PAYMENTS_SUCCESS: "FETCH_MANUAL_TRANSACTION_PAYMENTS_SUCCESS",
    FETCH_MANUAL_TRANSACTION_PAYMENTS_FAILURE: "FETCH_MANUAL_TRANSACTION_PAYMENTS_FAILURE",

    UPLOAD_MANUAL_TRANSACTIONS_REQUEST: "UPLOAD_MANUAL_TRANSACTIONS_REQUEST",
    UPLOAD_MANUAL_TRANSACTIONS_SUCCESS: "UPLOAD_MANUAL_TRANSACTIONS_SUCCESS",
    UPLOAD_MANUAL_TRANSACTIONS_FAILURE: "UPLOAD_MANUAL_TRANSACTIONS_FAILURE",

    FETCH_MANUAL_PAYMENTS_REQUEST: "FETCH_MANUAL_PAYMENTS_REQUEST",
    FETCH_MANUAL_PAYMENTS_SUCCESS: "FETCH_MANUAL_PAYMENTS_SUCCESS",
    FETCH_MANUAL_PAYMENTS_FAILURE: "FETCH_MANUAL_PAYMENTS_FAILURE",


    APPROVE_TRANSACTION_REQUEST: "APPROVE_TRANSACTION_REQUEST",
    APPROVE_TRANSACTION_SUCCESS: "APPROVE_TRANSACTION_SUCCESS",
    APPROVE_TRANSACTION_FAILURE: "APPROVE_TRANSACTION_FAILURE",

    RETURN_TO_INITIAL: "RETURN_TO_INITIAL"
}
