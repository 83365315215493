import {actionTypes} from "./actionTypes";
import {requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    listLoading: false,
    historicalData: undefined,
    codeDescriptionCollectionInProgress: false,
    codeDescriptions: undefined
};

/*Reducer for Device's redux store*/
export const historicalDataReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_HISTORICAL_DATA_REQUEST:
            return {
                ...state,
                listLoading: true,
                historicalData: undefined
            };
        case actionTypes.GET_HISTORICAL_DATA_SUCCESS:
            return {
                ...state,
                listLoading: false,
                historicalData: action.payload
            };
        case actionTypes.GET_HISTORICAL_DATA_FAILURE:
            toast.error("Can't collect historical data! Please contact with admin.");
            return {
                ...state,
                historicalData: undefined
            };

        case actionTypes.COLLECT_CODE_DESCRIPTION_REQUEST:
            return {
                ...state,
                codeDescriptionCollectionInProgress: true,
                codeDescriptions: undefined
            };
        case actionTypes.COLLECT_CODE_DESCRIPTION_SUCCESS:
            return {
                ...state,
                codeDescriptionCollectionInProgress: false,
                codeDescriptions: action.payload
            };
        case actionTypes.COLLECT_CODE_DESCRIPTION_FAILURE:
            toast.error("Can't collect description of different codes! Please contact with an admin.");
            return {
                ...state,
                codeDescriptionCollectionInProgress: false,
                codeDescriptions: undefined
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
