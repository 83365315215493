/**
 * Convert number with characters ('_', ',' etc.) from English to Bengali or Bengali to English
 * @param {string} lang - Language to be achieved (By default it is English)
 * @param {string} num - Number to be converted
 */
export function convertToAnotherLang (num, lang='EN') {
    let numbers
    if (lang === 'EN') {
        numbers = {
            '০': 0,
            '১': 1,
            '২': 2,
            '৩': 3,
            '৪': 4,
            '৫': 5,
            '৬': 6,
            '৭': 7,
            '৮': 8,
            '৯': 9
        }
    } else {
        numbers = {
            0:'০',
            1:'১',
            2:'২',
            3:'৩',
            4:'৪',
            5:'৫',
            6:'৬',
            7:'৭',
            8:'৮',
            9:'৯'
        }
    }

    let output = [];
    for (let i = 0; i < num.length; ++i) {
        if (numbers.hasOwnProperty(num[i])) {
            output.push(numbers[num[i]]);
        } else {
            output.push(num[i]);
        }
    }
    return output.join('')
}
