import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useForm} from "react-hook-form";
import {Button, Form, InputGroup, OverlayTrigger, Spinner} from "react-bootstrap";
import Select from "react-select";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {DatePicker} from '@material-ui/pickers/'
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {actions} from "../../actions";
import {connect} from "react-redux";
import {upazillas} from "../../../../utils/countryData/3dListBangladesh";
import {toast} from "react-toastify";
import moment from "moment";
import {requestCycle} from "../../utils";
import * as bdData from "../../../../utils/countryData/3dListBangladesh";
import {useSubheader} from "../../../../../_metronic/layout";

const useStylesForDatePicker = makeStyles({
    root: {
        marginTop: '0',
        marginBottom: '0',
        border: 'none',
        width: '100%',
        '& input': {
            color: 'black',
            padding: "0 10px 0 10px",
            height: "38px",
            outline: "none",
        },
        '& input:hover, input:focus, input:active': {
            border: "1px solid #F18D00",
            boxShadow: "0px 0px 0px 3.2px r gba(241, 141, 0, 0.1)",
            outline: "none",
        }
    },
});
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#ffffff',
        }
    },
});
const CreateOrganization = props => {
    const {register, handleSubmit, errors, setError, clearErrors,control} = useForm();
    const [divisions, setDivisions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [upazillas, setUpazillas] = useState([]);
    const subHeader = useSubheader();

    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [organisations, setOrganisations] = useState([]);
    const [operator, setOperator] = useState(null);
    const [country, setCountry] = useState(null);
    const [calendarView] = useState(['date','month','year']);
    const [monthYear, setMonthYear] = useState(null);
    const [areaManagers, setAreaManagers] = useState([]);
    const [sam, setSam] = useState(null);
    const [currencies, setCurrencies] = useState([]);
    const [tradingCurrency, setTradingCurrency] = useState({label: "BDT", value: "BDT"});
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState(null);
    const [subDistricts, setSubDistricts] = useState([]);
    const [ selectedSubDistrict , setSelectedSubDistrict] = useState(null);
    const classes = useStylesForDatePicker();

    const currenciesOptions= [
        {
            label: "BDT",
            value: "BDT",
        }
    ]

    const onGridCreateSubmit=(data)=>{
        if (validateForm()===true){
            const payload = {
                name : data.grid_name,
                grid_operator : operator?.id,
                item_type : 'SOLgrid',
                product_line : 'SOLgrid',
                default_buy_price : data.buy,
                default_sell_price : data.sell,
                profit_share_percentage : parseInt(data.trading_fee_share),
                current_am_guid : sam?.value,
                area_manager : sam?.id,
                project : project?.value,
                country_code : country?.value,
                trading_currency : tradingCurrency.value,
                installation_date : moment(monthYear).format('YYYY-MM-DD'),
                time_zone : "UTC+6",
                grid_map_link : data.grid_map_link,
                survey_data_link : data.survey_data_file_link,
                agreement_doc_link : data.service_agreement_doc_link,
                installation_incharge : data.installation_incharge,


                /*Extra Fields not documented*/
                // grid_operator_guid: operator?.value,
                // grid_owner: operator?.value,

                address: {
                    block : data.road,
                    village : data.village,
                    upazila : selectedSubDistrict.value,
                    division : selectedDivision.value,
                    district : selectedDistrict.value,
                    postal_code : data.postal_code,
                    latitude : data.latitude,
                    longitude : data.longitude,
                }

            }

            props.POST_GRID_INFO(payload);
        }


    };

    const {
        POST_GRID_INFO,
        GET_SAM_LIST,
        GET_PROJECT_LIST,
        GET_GRID_OWNERS_LIST,
    } = props;


    const callError=(fieldname,msg)=>{
        setError(fieldname, {
            type: "manual",
            message: msg
        });
    }

    const validateForm=(data)=>{
        if (!sam || !operator || !tradingCurrency || !country || !monthYear  || !selectedSubDistrict || !selectedDistrict || !selectedDivision)
        {
            const temp = [
                {
                    fieldName: 'area_manager',
                    slug: "Area manager",
                    decider: !!sam,
                },
                {
                    fieldName: 'country',
                    slug: "Country",
                    decider: !!country,
                },
                {
                    fieldName: 'grid_operator',
                    slug: "Grid operator",
                    decider: !!operator,
                },
                {
                    fieldName: 'trading_currency',
                    slug: "Trading Currency",
                    decider: !!tradingCurrency,
                },
                {
                    fieldName: 'time',
                    slug: "Date",
                    decider: !!monthYear,
                },
                {
                    fieldName: 'thana',
                    slug: "Thana/Upazilla",
                    decider: !!selectedSubDistrict,
                },
                {
                    fieldName: 'division',
                    slug: "Division",
                    decider: !!selectedDivision,
                },
                {
                    fieldName: 'district',
                    slug: "District",
                    decider: !!selectedDistrict,
                }
            ];
            temp.forEach((item)=>{
                if (!item.decider){
                    callError(item.fieldName ,`${item.slug} is Required`);
                }
            })

            return false
        }else{
            return true
        }
    }

    useEffect(()=>{
        setDivisions(bdData.divisions.map((division)=>{
            return {
                ...division,
                label: division.name,
                value: division.name,
            }
        }));

    },[bdData])



    useEffect(()=>{

        setSelectedDistrict(null);
        setSelectedSubDistrict(null);
        if (selectedDivision)
        {
            setDistricts(

                bdData.districts.reduce((filtered, district)=>{
                    if (selectedDivision.id === district.division_id){
                        filtered.push({
                            ...district,
                            label: district.name,
                            value: district.name,
                        })
                    }
                    return filtered
                },[])

            );
        }else {
            setDistricts(bdData.districts.map((district)=>{
                return {
                    ...district,
                    label: district.name,
                    value: district.name,
                }
            }));
        }


    },[selectedDivision])

    useEffect(()=>{
        setSelectedSubDistrict(null);
        if (selectedDistrict){

            setUpazillas(

                bdData.upazillas.reduce((filtered, upazilla)=>{
                    if (selectedDistrict.id === upazilla.district_id){
                        filtered.push({
                            ...upazilla,
                            label: upazilla.name,
                            value: upazilla.name,
                        })
                    }
                    return filtered
                },[])

            );
        }else{

            setUpazillas(bdData.upazillas.map((upazilla)=>{
                return {
                    ...upazilla,
                    label: upazilla.name,
                    value: upazilla.name,
                }
            }));
        }


    },[selectedDistrict])


    useEffect(()=>{
       if (props.gridInfoSuccess===requestCycle.success){
           props.SET_GRID_DATA_FETCHING_CONFIRMATION_FLAGS_TO_UNDEFINED();
           props.history.push('/grids/list/');
       }

    },[props.gridInfoSuccess])


    useEffect(()=>{
        subHeader.setActionButtons(null)
        GET_GRID_OWNERS_LIST();
        GET_SAM_LIST();
        GET_PROJECT_LIST();
    },[
        POST_GRID_INFO,
        GET_SAM_LIST,
        GET_PROJECT_LIST
    ])

    useEffect(()=>{
        const operators = props.gridOwnerList;
        const sams = props.samList;
        const projects = props.projectList;

        if (operators && operators.length >= 0) {
            setOrganisations(operators.map((operator) => {
                return {
                    ...operator,
                    label: operator.organization_name,
                    value: operator.organization_guid,
                }
            }));
        }
        setSubDistricts(upazillas.map((upazilla)=>{
            return {
                ...upazilla,
                label: upazilla.name,
                value: upazilla.name,
            }
        }))


        if (sams && sams.length >= 0) {
            setAreaManagers(sams.map((sam) => {
                return {
                    ...sam,
                    label: sam.name,
                    value: sam.guid,
                }
            }));
        }

        if (projects && projects.length >= 0) {
            setProjects(projects.map((project) => {
                return {
                    ...project,
                    label: project.name,
                    value: project.id,
                }
            }));
        }


    },[props.gridOwnerList, props.samList, props.projectList])


    return (
       <ContentWrapper showCardHeader={false} pageTitle={null} showBackButton={false} isLoading={false}>
           <div>
               <div className={"col-md-12"}>
                   <Form onSubmit={handleSubmit(onGridCreateSubmit)}>
                       <div>
                           <div className={"col-md-3 no-padding"}>
                               <Form.Group>
                                   <Form.Label>Name<span className="required text-danger">*</span></Form.Label>
                                   <SolInput
                                       type="text"
                                       name={"grid_name"}
                                       ref={register({
                                           required: 'Required',
                                       })}
                                       placeholder={"Name of the Grid"}
                                       autoComplete={"off"}
                                   />
                                   {errors.grid_name && <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.grid_name.message}</div>}
                               </Form.Group>
                           </div>

                           <div className={'col-md-3 no-padding'}>
                               <br/>
                               <SolTypography.Text style={{
                                   color: "#8C8C9B"
                               }}>
                                   Location
                               </SolTypography.Text>
                               <br/>
                               <br/>
                           </div>

                           <div className={'row '}>
                               <div className={"col-md-3"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>Latitude<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <SolInput
                                           name={'latitude'}
                                           ref={register({
                                               required: 'Required',
                                               // pattern: {
                                               //     value: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/i,
                                               //     message: "Invalid Latitude"
                                               // }
                                           })}
                                           placeholder={'Type latitude'}
                                           autoComplete={"off"}
                                       />
                                       {errors.latitude && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.latitude.message}</div>}

                                   </Form.Group>
                               </div>
                               <div className={"col-md-3"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>Longitude<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <SolInput
                                           name={'longitude'}
                                           ref={register({
                                               required: 'Required',
                                               // pattern: {
                                               //     value: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/i,
                                               //     message: "Invalid longitude"
                                               // }
                                           })}
                                           placeholder={'Type Longitude'}
                                           autoComplete={"off"}
                                       />
                                       {errors.longitude && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.longitude.message}</div>}

                                   </Form.Group>
                               </div>
                           </div>

                           <div className={"col-md-3 no-padding"}>
                               <Form.Group>
                                   <Form.Label>Country<span className="required text-danger">*</span></Form.Label>
                                   <Select
                                       name={`status`}
                                       placeholder={`country`}
                                       classNamePrefix="react-select-sol-style"
                                       isDisabled={props.filterDropdownLoading}
                                       isLoading={props.filterDropdownLoading}
                                       maxMenuHeight={200}
                                       // value={status}
                                       isClearable={true}
                                       control={control}
                                       options={[{label: 'Bangladesh', value: 'BD'}]}
                                       isSearchable={true}
                                       onChange={(selected) => {
                                           setCountry(selected)
                                           clearErrors();
                                           return selected
                                       }}
                                   />
                                   {errors.country && <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.country.message}</div>}
                               </Form.Group>
                           </div>

                           <div className={'row '}>
                               <div className={"col-md-3"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>Division<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <Select
                                           name={`status`}
                                           placeholder={`division`}
                                           classNamePrefix="react-select-sol-style"
                                           isDisabled={props.filterDropdownLoading}
                                           isLoading={props.filterDropdownLoading}
                                           maxMenuHeight={200}
                                           value={selectedDivision}
                                           isClearable={true}
                                           control={control}
                                           options={divisions}
                                           isSearchable={true}
                                           onChange={(selected) => {
                                               setSelectedDivision(selected)
                                               clearErrors();
                                               // return selected
                                           }}
                                       />
                                       {errors.division && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.division.message}</div>}

                                   </Form.Group>
                               </div>
                               <div className={"col-md-3"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>Districts<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <Select
                                           name={`status`}
                                           placeholder={`district`}
                                           classNamePrefix="react-select-sol-style"
                                           isDisabled={props.filterDropdownLoading}
                                           isLoading={props.filterDropdownLoading}
                                           maxMenuHeight={200}
                                           value={selectedDistrict}
                                           isClearable={true}
                                           control={control}
                                           options={districts}
                                           isSearchable={true}
                                           onChange={(selected) => {
                                               setSelectedDistrict(selected);
                                               clearErrors();
                                               // return selected
                                           }}
                                       />
                                       {errors.district && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.district.message}</div>}

                                   </Form.Group>
                               </div>
                           </div>

                           <div className={'row '}>
                               <div className={"col-md-3"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>Village/House No<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <SolInput
                                           name={'village'}
                                           placeholder={'Select Village/House'}
                                           ref={register({
                                               required: "Required"
                                           })}
                                           autoComplete={"off"}
                                       />
                                       {errors.village && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.village.message}</div>}

                                   </Form.Group>
                               </div>
                               <div className={"col-md-3"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>Road/Block/Sector<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <SolInput
                                           name={'road'}
                                           placeholder={'Type Road/Block/Sector'}
                                           ref={register({
                                               required: "Required"
                                           })}
                                           autoComplete={"off"}
                                       />
                                       {errors.road && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.road.message}</div>}

                                   </Form.Group>
                               </div>
                           </div>






                           <div className={'row '}>
                               <div className={"col-md-3"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>Upazila<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <Select
                                           name={`thana`}
                                           placeholder={`Upazila`}
                                           classNamePrefix="react-select-sol-style"
                                           isDisabled={props.filterDropdownLoading}
                                           isLoading={props.filterDropdownLoading}
                                           maxMenuHeight={200}
                                           value={selectedSubDistrict}
                                           isClearable={true}
                                           control={control}
                                           options={upazillas}
                                           isSearchable={true}
                                           onChange={(selected) => {
                                               setSelectedSubDistrict(selected)
                                               clearErrors();
                                               // return selected
                                           }}
                                       />
                                       {errors.thana && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.thana.message}</div>}

                                   </Form.Group>
                               </div>
                               <div className={"col-md-3"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>Postal Code<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <SolInput
                                           type={'number'}
                                           name={'postal_code'}
                                           placeholder={'Postal Code'}
                                           ref={register({
                                               required: "Required"
                                           })}
                                           autoComplete={"off"}
                                       />
                                       {errors.postal_code && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.postal_code.message}</div>}

                                   </Form.Group>
                               </div>
                           </div>


                           <div className={'col-md-3 no-padding'}>
                               <br/>
                               <SolTypography.Text style={{
                                   color: "#8C8C9B"
                               }}>
                                   Operations
                               </SolTypography.Text>
                               <br/>
                               <br/>
                           </div>


                           <div className={"col-md-3 no-padding"}>
                               <Form.Group>
                                   <Form.Label>Grid Operator<span className="required text-danger">*</span></Form.Label>
                                   <Select
                                       name={`grid_operator`}
                                       placeholder={`Select Grid Operator`}
                                       classNamePrefix="react-select-sol-style"
                                       isDisabled={props.filterDropdownLoading}
                                       isLoading={props.filterDropdownLoading}
                                       maxMenuHeight={200}
                                       // value={status}
                                       isClearable={true}
                                       control={control}
                                       options={organisations}
                                       isSearchable={true}
                                       onChange={(selected) => {
                                           setOperator(selected)
                                           clearErrors();
                                           return selected
                                       }}
                                   />
                                   {errors.grid_operator && <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.grid_operator.message}</div>}
                               </Form.Group>
                           </div>

                           <div className={"col-md-3 no-padding"}>
                               <Form.Group>
                                   <Form.Label>Installation Date<span className="required text-danger">*</span></Form.Label>
                                   <ThemeProvider theme={theme}>
                                       <DatePicker
                                           margin='normal'
                                           name={'time'}
                                           inputVariant={'outlined'}
                                           placeholder={"DD/MM/YYYY"}
                                           className={classes.root}
                                           value={monthYear}
                                           disableFuture={true}
                                           views={calendarView}
                                           control={control}
                                           format={'dd/MM/yyyy'}
                                           clearable={true}
                                           onChange={ (value) => {
                                               setMonthYear(value)
                                               clearErrors()
                                           }}
                                           KeyboardButtonProps={{
                                               'aria-label': 'change date',
                                           }}
                                       />
                                   </ThemeProvider>

                                   {errors.time && <div className={"text-danger"}>
                                       <i className={"flaticon-warning kt-font-brand"}/>&nbsp;&nbsp;{errors.time.message}</div>}
                               </Form.Group>
                           </div>
                           <div className={"col-md-3 no-padding"}>
                               <Form.Group>
                                   <Form.Label>Installation Incharge<span className="required text-danger">*</span></Form.Label>
                                   <SolInput
                                       type="text"
                                       name={"installation_incharge"}
                                       ref={register({
                                           required: 'Required',
                                       })}
                                       placeholder={"Installation Incharge"}
                                       autoComplete={"off"}
                                   />
                                   {errors.installation_incharge && <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.installation_incharge.message}</div>}
                               </Form.Group>
                           </div>

                           <div className={"col-md-3 no-padding"}>
                               <Form.Group>
                                   <Form.Label>Site Area Manager (SAM) <span className="required text-danger">*</span></Form.Label>
                                   <Select
                                       name={`area_manager`}
                                       placeholder={`Select SAM`}
                                       classNamePrefix="react-select-sol-style"
                                       isDisabled={props.filterDropdownLoading}
                                       isLoading={props.filterDropdownLoading}
                                       maxMenuHeight={200}
                                       // value={status}
                                       isClearable={true}
                                       options={areaManagers}
                                       isSearchable={true}
                                       onChange={(selected) => {
                                           setSam(selected)
                                           clearErrors();
                                           return selected
                                       }}
                                   />
                                   {errors.area_manager && <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.area_manager.message}</div>}
                               </Form.Group>
                           </div>

                           <div className={'col-md-3 no-padding'}>
                               <br/>
                               <br/>
                           </div>

                           <div className={"col-md-3 no-padding"}>
                               <Form.Group>
                                   <Form.Label>Grid Map Link<span className="required text-danger">*</span></Form.Label>
                                   <SolInput
                                       type="text"
                                       name={"grid_map_link"}
                                       ref={register({
                                           required: 'Required',
                                           pattern: {
                                               value: /^(ftp|http|https):\/\/[^ "]+$/i,
                                               message: "Invalid URL"
                                           }
                                       })}
                                       placeholder={"https://drive.google.com/solshare..."}
                                       autoComplete={"off"}
                                   />
                                   {errors.grid_map_link && <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.grid_map_link.message}</div>}
                               </Form.Group>
                           </div>


                           <div className={"col-md-3 no-padding"}>
                               <Form.Group>
                                   <Form.Label>Survey Data File Link<span className="required text-danger">*</span></Form.Label>
                                   <SolInput
                                       type="text"
                                       name={"survey_data_file_link"}
                                       ref={register({
                                           required: 'Required',
                                           pattern: {
                                               value: /^(ftp|http|https):\/\/[^ "]+$/i,
                                               message: "Invalid URL"
                                           }
                                       })}
                                       placeholder={"https://drive.google.com/solshare..."}
                                       autoComplete={"off"}
                                   />
                                   {errors.survey_data_file_link && <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.survey_data_file_link.message}</div>}
                               </Form.Group>
                           </div>



                           {/*<div className={"col-md-3 no-padding"}>*/}
                           {/*    <Form.Group>*/}
                           {/*        <Form.Label>Service Agreement Doc Link<span className="required text-danger">*</span></Form.Label>*/}
                           {/*        <SolInput*/}
                           {/*            type="text"*/}
                           {/*            name={"service_agreement_doc_link"}*/}
                           {/*            ref={register({*/}
                           {/*                required: 'Required',*/}
                           {/*                pattern: {*/}
                           {/*                    value: /^(ftp|http|https):\/\/[^ "]+$/i,*/}
                           {/*                    message: "Invalid URL"*/}
                           {/*                }*/}
                           {/*            })}*/}
                           {/*            placeholder={"https://drive.google.com/solshare..."}*/}
                           {/*            autoComplete={"off"}*/}
                           {/*        />*/}
                           {/*        {errors.service_agreement_doc_link && <div className="text-danger">*/}
                           {/*            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.service_agreement_doc_link.message}</div>}*/}
                           {/*    </Form.Group>*/}
                           {/*</div>*/}



                           <div className={'col-md-3 no-padding'}>
                               <br/>
                               <SolTypography.Text style={{
                                   color: "#8C8C9B"
                               }}>
                                   Finance
                               </SolTypography.Text>
                               <br/>
                               <br/>
                           </div>




                           <div className={"col-md-3 no-padding"}>
                               <Form.Group>
                                   <Form.Label>Trading Currency<span className="required text-danger">*</span></Form.Label>
                                   <Select
                                       name={`trading_currency`}
                                       placeholder={`Trading Currency`}
                                       classNamePrefix="react-select-sol-style"
                                       isDisabled={true}
                                       isLoading={props.filterDropdownLoading}
                                       maxMenuHeight={200}
                                       // value={status}
                                       isClearable={true}
                                       defaultValue={{label: "BDT", value: "BDT"}}
                                       options={currenciesOptions}
                                       isSearchable={true}
                                       onChange={(selected) => {
                                           setTradingCurrency(selected)
                                           clearErrors();
                                           return selected
                                       }}
                                   />
                                   {errors.trading_currency && <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.trading_currency.message}</div>}
                               </Form.Group>
                           </div>

                           {/*<div className={"col-md-3 no-padding"}>*/}
                           {/*    <Form.Group>*/}
                           {/*        <Form.Label>Project</Form.Label>*/}
                           {/*        <Select*/}
                           {/*            name={`selected_project`}*/}
                           {/*            placeholder={`Select project`}*/}
                           {/*            classNamePrefix="react-select-sol-style"*/}
                           {/*            isDisabled={false}*/}
                           {/*            isLoading={false}*/}
                           {/*            maxMenuHeight={200}*/}
                           {/*            // value={status}*/}
                           {/*            isClearable={true}*/}
                           {/*            options={projects}*/}
                           {/*            isSearchable={true}*/}
                           {/*            onChange={(selected) => {*/}
                           {/*                setProject(selected)*/}
                           {/*                clearErrors();*/}
                           {/*                return selected*/}
                           {/*            }}*/}
                           {/*        />*/}
                           {/*        {errors.selected_project && <div className="text-danger">*/}
                           {/*            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.selected_project.message}</div>}*/}
                           {/*    </Form.Group>*/}
                           {/*</div>*/}

                           { operator && operator?.label.toString().toLowerCase()!=="solshare"?<div className={"col-md-3 no-padding"}>
                               <Form.Group>
                                   <Form.Label>Partner’s Trading Profit Share<span className="required text-danger">*</span></Form.Label>
                                   <InputGroup >
                                       <Form.Control
                                           as={'input'}
                                           type="number"
                                           name={"trading_fee_share"}
                                           ref={register({
                                               required: 'Required',
                                           })}
                                           aria-describedby="basic-addon2"
                                           step="0.01"
                                       />
                                       <InputGroup.Append>
                                           <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                       </InputGroup.Append>
                                   </InputGroup>
                                   {errors.trading_fee_share && <div className="text-danger">
                                       <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.trading_fee_share.message}</div>}
                               </Form.Group>
                           </div>: null}



                           <div className={'row '}>
                               <div className={"col-md-3"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>Buy Tariff (Tk per Wh)<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <SolInput
                                           type={'number'}
                                           name={'buy'}
                                           placeholder={'0.00'}
                                           ref={register({
                                               required: 'Required',

                                           })}
                                           step="0.01"
                                       />
                                       {errors.buy && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.buy.message}</div>}

                                   </Form.Group>
                               </div>
                               <div className={"col-md-3"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>Sell Tariff (Tk per Wh)<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <SolInput
                                           type={'number'}
                                           name={'sell'}
                                           placeholder={'0.00'}
                                           ref={register({
                                               required: 'Required',
                                           })}
                                           step="0.01"
                                       />
                                       {errors.sell && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.sell.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>


                       </div>

                       <div className={"col-md-3 no-padding"}>
                           <Button variant="warning" type="submit">
                               {props.gridInfoLoading===true? <><Spinner animation={'border'} size={'sm'} variant={'light'}/> </>:null} Create Grid
                           </Button>
                           &nbsp;
                           &nbsp;
                           &nbsp;
                           <Button variant="dark" onClick={()=>props.history.goBack()} style={{
                               backgroundColor: '#8C8C9B',
                               outline: '#8C8C9B',
                               border: '#8C8C9B',
                           }}>
                               Cancel
                           </Button>
                       </div>

                   </Form>
               </div>
           </div>
           <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
       </ContentWrapper>
    );
};

CreateOrganization.propTypes = {

};


const mapStateToProps=(state)=>{

    return {
        isLoading: state.gridsReducer.isLoading,
        gridOwnerList: state.gridsReducer.gridOwnerList,
        samList: state.gridsReducer.samList,
        projectList: state.gridsReducer.projectList,
        filterDropdownLoading: state.gridsReducer.filterDropdownLoading,
        gridInfoSuccess: state.gridsReducer.gridInfoSuccess,
        gridInfoLoading: state.gridsReducer.gridInfoLoading,
    }
}

export default connect(mapStateToProps, actions) (CreateOrganization);
