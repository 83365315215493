import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */

    COLLECT_EV_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/ev`, filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_EV_LIST_REQUEST,
                actionTypes.COLLECT_EV_LIST_SUCCESS,
                actionTypes.COLLECT_EV_LIST_FAILURE
            ]
        }
    }),

    COLLECT_EV_DETAILS: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/ev/details', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_EV_DETAILS_REQUEST,
                actionTypes.COLLECT_EV_DETAILS_SUCCESS,
                actionTypes.COLLECT_EV_DETAILS_FAILURE
            ]
        }
    }),

    COLLECT_TRANSACTIONS:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/activities',payload),
            method: 'GET',
            backend_slug: 'transaction_management_v2',
            types: [
                actionTypes.COLLECT_TRANSACTIONS_REQUEST,
                actionTypes.COLLECT_TRANSACTIONS_SUCCESS,
                actionTypes.COLLECT_TRANSACTIONS_FAILURE,
            ]
        }
    }),

    COLLECT_PAYMENT_DETAILS:(topUpGuid)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/cash_payment/details/' + topUpGuid),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_PAYMENT_DETAILS_REQUEST,
                actionTypes.COLLECT_PAYMENT_DETAILS_SUCCESS,
                actionTypes.COLLECT_PAYMENT_DETAILS_FAILURE,
            ]
        }
    }),

    COLLECT_RENT_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/rents', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_RENT_LIST_REQUEST,
                actionTypes.COLLECT_RENT_LIST_SUCCESS,
                actionTypes.COLLECT_RENT_LIST_FAILURE
            ]
        }
    }),

    COLLECT_DRIVER_DETAILS: (phoneNumber)=>({
        [CALL_API]: {
            endpoint: '/drivers/' + phoneNumber,
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_DRIVER_DETAILS_REQUEST,
                actionTypes.COLLECT_DRIVER_DETAILS_SUCCESS,
                actionTypes.COLLECT_DRIVER_DETAILS_FAILURE,
            ]
        }
    }),

    COLLECT_LOCATIONS:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/driver_location_history',payload),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_LOCATIONS_REQUEST,
                actionTypes.COLLECT_LOCATIONS_SUCCESS,
                actionTypes.COLLECT_LOCATIONS_FAILURE,
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE_FOR_SEARCH: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE_FOR_SEARCH})
};
