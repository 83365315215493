import React, {useEffect, useRef, useState} from 'react';
import {connect, useSelector} from "react-redux";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {actions} from "../../actions";
import {withRoles} from "../../../../router/SecuredRoute";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { ReactSmartScroller } from 'react-smart-scroller';
import {useMediaQuery} from "react-responsive";
import Skeleton from "@mui/material/Skeleton";
import "../customStyleForOverview.css";


const PerformanceInfoForOverview = ({parentCardHeight, highPerformerData, lowPerformerData, ...props}) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [highPerformer, setHighPerformer] = useState([])
    const [lowPerformer, setLowPerformer] = useState([])
    const [performanceCardHeight, setPerformanceCardHeight] = useState(0)
    const performanceCard = useRef(null)

    // Source of the letter Avatar (with following two functions): https://mui.com/material-ui/react-avatar/ - Noor Reza, 4th October, 6:48 PM
    // Though We have updated it by adding a checking for the one word name
    const stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const stringAvatar = (name) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1]? name.split(' ')[1][0]:''}`,
        }
    }

    useEffect(() => {
        setPerformanceCardHeight(performanceCard.current.clientHeight)
    },[])

    useEffect(() => {
        setHighPerformer(highPerformerData)
    },[highPerformerData])

    useEffect(() => {
        setLowPerformer(lowPerformerData.sort((a, b) => {
            return b.overdue_amount - a.overdue_amount || b.lately_payment_rate - a.lately_payment_rate
        }))
    },[lowPerformerData])

    const renderHighPerformanceSection = () => {
        let rows = []
        for (let i=0; i< highPerformer.length; i++) {
            rows.push(<div key={'high-' + i}>
                <div className={i===0 ? 'mt-5':''}>
                    <div style={{display: "flex", justifyContent: "flex-end"}} className={'ml-2 mr-2'}>
                        <div style={{marginRight: "auto"}}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >
                                {/*<Avatar alt={highPerformer[i].borrower_name} src={''} />*/}
                                <Avatar {...stringAvatar(highPerformer[i].borrower_name)} />
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <Typography variant={"body1"} gutterBottom>
                                        {highPerformer[i].borrower_name}
                                    </Typography>
                                    <Typography variant={"body1"} gutterBottom>
                                        {highPerformer[i].garage_name}{highPerformer[i].garage_district? ', ' + highPerformer[i].garage_district:''}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </div>
                        <div style={{marginLeft: "auto"}}>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                                spacing={0}
                            >
                                <Typography variant={"subtitle2"}>
                                    99%
                                </Typography>
                                <Typography variant={"subtitle2"} gutterBottom>
                                    ON TIME
                                </Typography>
                            </Stack>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div>)
        }
        return rows
    }

    const renderLowPerformanceSection = () => {
        let rows = []
        for (let j=0; j<lowPerformer.length; j++) {
            if (lowPerformer[j].overdue_amount) {
                rows.push(<div key={'low-'+j}><div className={j===0? 'mt-5':''}>
                    <div style={{display: "flex", justifyContent: "flex-end"}} className={'ml-2 mr-2'}>
                        <div style={{marginRight: "auto"}}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >
                                {/*<Avatar alt={lowPerformer[j].borrower_name} src={''}/>*/}
                                <Avatar {...stringAvatar(lowPerformer[j].borrower_name)} />
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <Typography variant={"body1"} gutterBottom>
                                        {lowPerformer[j].borrower_name}
                                    </Typography>
                                    <Typography variant={"body1"} gutterBottom>
                                        {lowPerformer[j].garage_name}{lowPerformer[j].garage_district? ', ' + lowPerformer[j].garage_district:''}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </div>
                        <div style={{marginLeft: "auto"}}>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                                spacing={0}
                            >
                                <Typography variant={"subtitle2"}>
                                    {new Intl.NumberFormat('en-IN').format(lowPerformer[j].overdue_amount.toFixed(2)) + ' Tk'}
                                </Typography>
                                <Typography variant={"subtitle2"} gutterBottom>OVERDUE</Typography>
                            </Stack>
                        </div>
                    </div>
                    <hr/>
                </div></div>)
            }
        }
        return rows
    }

    return (
        <>
            <Card sx={{ width: '100%', height: '100%' }} ref={performanceCard}>
                {props.collectingSummaryDataFromTransaction? <>
                    <Skeleton animation="wave" className={"d-flex flex-column justify-content-center align-items-center custom-loading-screen-style"} variant="rectangular" width={'100%'} height={'100%'}>
                        Borrower's performance
                    </Skeleton>
                </>:<>
                    <CardContent>
                        <ReactSmartScroller
                            vertical
                            draggable={true}
                            style={!isTabletOrMobile? {height: parentCardHeight + 'px'}:{height: performanceCardHeight + 'px'}}
                            thumb={<div style={{width: 10, height: 100, backgroundColor: '#F18D00'}}/>}
                        >
                            <>
                                <div style={{display: "flex", justifyContent: "flex-end"}} className={'ml-2 mr-2'}>
                                    <Typography variant={"h5"} component={"div"} sx={{marginRight: "auto"}}>
                                        Top performing
                                    </Typography>
                                    {/*<Typography variant={"subtitle1"} gutterBottom sx={{marginLeft: "auto"}}>*/}
                                    {/*    <a style={{color: "#F18D00"}} onClick={() => {console.log("here")}}>*/}
                                    {/*        See All*/}
                                    {/*    </a>*/}
                                    {/*</Typography>*/}
                                </div>
                                <Typography variant={"subtitle2"} gutterBottom className={'ml-2 mt-1'}>
                                    Late payment rate is less than 5%
                                </Typography>
                            </>
                            <>
                                {highPerformer.length? renderHighPerformanceSection():<>
                                    <div className={'ml-2 mt-5'}>
                                        <Typography variant={"body1"} gutterBottom>
                                            No top performer available
                                        </Typography>
                                    </div></>}
                            </>
                            <>
                                <div className={'mt-7'}>
                                    <div style={{display: "flex", justifyContent: "flex-end"}} className={'ml-2 mr-2'}>
                                        <Typography variant={"h5"} component={"div"} sx={{marginRight: "auto"}}>
                                            Under performing
                                        </Typography>
                                        {/*<Typography variant={"subtitle1"} gutterBottom sx={{marginLeft: "auto"}}>*/}
                                        {/*    <a style={{color: "#F18D00"}} onClick={() => {console.log("here")}}>*/}
                                        {/*        See All*/}
                                        {/*    </a>*/}
                                        {/*</Typography>*/}
                                    </div>
                                    <Typography variant={"subtitle2"} gutterBottom className={'ml-2 mt-1'}>
                                        Has overdue, and late payment rate more than 5%
                                    </Typography>
                                </div>
                            </>
                            <>
                                {lowPerformer.length? renderLowPerformanceSection():<>
                                    <div className={'ml-2 mt-5'}>
                                        <Typography variant={"body1"} gutterBottom>
                                            No low performer available
                                        </Typography>
                                    </div>
                                </>}
                            </>
                        </ReactSmartScroller>
                    </CardContent>
                </>}
            </Card>
        </>
    )
}

PerformanceInfoForOverview.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        districts: state.overviewReducer.districts,
        collectingSummaryDataFromTransaction: state.overviewReducer.collectingSummaryDataFromTransaction
    }
}

export default connect(mapStateToProps, {...actions})(withRoles(PerformanceInfoForOverview))
