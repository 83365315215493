import {Redirect, Switch} from "react-router-dom";
import React from "react";
import Settlements from "./views/settlements/settlements";
import NewSettlement from "./views/newSettlement/newSettlement";
import SecuredRoute from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const settlementRouter =(props)=> {

    return (
        <Switch>
            {/*<Redirect*/}
            {/*    exact={"true"}*/}
            {/*    from={"/settlements"}*/}
            {/*    to={"/settlements/list"}*/}
            {/*/>*/}

            {/* Routes to settlements List*/}
            {/*<SecuredRoute*/}
            {/*    path={"/settlements/list"}*/}
            {/*    component={Settlements}*/}
            {/*    service={getServiceName('transactionService')}*/}
            {/*    feature={getFeatureName('invoiceSettlementFeature')}*/}
            {/*    action={getActionName('listAction')}*/}
            {/*/>*/}

            {/* Routes to new settlement page*/}
            {/*<SecuredRoute*/}
            {/*    path={"/settlements/new"}*/}
            {/*    component={NewSettlement}*/}
            {/*    service={getServiceName('transactionService')}*/}
            {/*    feature={getFeatureName('invoiceSettlementFeature')}*/}
            {/*    action={getActionName('createAction')}*/}
            {/*/>*/}

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
