import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const transactionActions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */

    FETCH_PAYMENTS:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/activities',payload),
            method: 'GET',
            backend_slug: 'transaction_management_v2',
            types: [
                actionTypes.FETCH_PAYMENTS_REQUEST,
                actionTypes.FETCH_PAYMENTS_SUCCESS,
                actionTypes.FETCH_PAYMENTS_FAILURE,
            ]
        }
    }),

    COLLECT_PAYMENTS_LIST:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/invoice_payments',payload),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.FETCH_INVOICE_PAYMENTS_REQUEST,
                actionTypes.FETCH_INVOICE_PAYMENTS_SUCCESS,
                actionTypes.FETCH_INVOICE_PAYMENTS_FAILURE,
            ]
        }
    }),

    FETCH_INVOICE_PAYMENTS_FOR_EXPORT:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/invoice_payments',payload),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.FETCH_INVOICE_PAYMENTS_FOR_EXPORT_REQUEST,
                actionTypes.FETCH_INVOICE_PAYMENTS_FOR_EXPORT_SUCCESS,
                actionTypes.FETCH_INVOICE_PAYMENTS_FOR_EXPORT_FAILURE,
            ]
        }
    }),

    FETCH_PAYMENTS_FOR_EXPORT:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/activities',payload),
            method: 'GET',
            backend_slug: 'transaction_management_v2',
            types: [
                actionTypes.FETCH_PAYMENTS_EXPORT_REQUEST,
                actionTypes.FETCH_PAYMENTS_EXPORT_SUCCESS,
                actionTypes.FETCH_PAYMENTS_EXPORT_FAILURE,
            ]
        }
    }),

    FETCH_MOBILE_WALLET_PAYMENTS:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/bkash_payment',payload),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.FETCH_MOBILE_WALLET_PAYMENTS_REQUEST,
                actionTypes.FETCH_MOBILE_WALLET_PAYMENTS_SUCCESS,
                actionTypes.FETCH_MOBILE_WALLET_PAYMENTS_FAILURE,
            ]
        }
    }),

    FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/bkash_payment',payload),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT_REQUEST,
                actionTypes.FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT_SUCCESS,
                actionTypes.FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT_FAILURE,
            ]
        }
    }),

    // FETCH_PAYMENTS_FOR_MOBILE_WALLET:(payload={})=>({
    //     [CALL_API]: {
    //         endpoint: paramsHandler('/bkash_payment',payload),
    //         method: 'GET',
    //         backend_slug: 'transaction_management',
    //         types: [
    //             actionTypes.FETCH_PAYMENTS_REQUEST,
    //             actionTypes.FETCH_PAYMENTS_SUCCESS,
    //             actionTypes.FETCH_PAYMENTS_FAILURE,
    //         ]
    //     }
    // }),

    FETCH_PAYMENT_DETAILS:(topup_guid)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/cash_payment/details/'+topup_guid),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.FETCH_PAYMENT_DETAILS_REQUEST,
                actionTypes.FETCH_PAYMENT_DETAILS_SUCCESS,
                actionTypes.FETCH_PAYMENT_DETAILS_FAILURE,
            ]
        }
    }),


    FETCH_BATTERY_SETTLEMENT_PAYMENTS: (payload = {}) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/payment', payload),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_REQUEST,
                actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_SUCCESS,
                actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FAILURE,
            ]
        }
    }),

    FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT: (payload = {}) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/payment', payload),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_REQUEST,
                actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_SUCCESS,
                actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_FAILURE,
            ]
        }
    }),

    SETTLE_PAYMENTS: (payload, filter={}) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/payment', filter),
            method: 'PUT',
            body: payload,
            backend_slug: 'transaction_management',
            types: [
                actionTypes.SETTLE_PAYMENTS_REQUEST,
                actionTypes.SETTLE_PAYMENTS_SUCCESS,
                actionTypes.SETTLE_PAYMENTS_FAILURE,
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
