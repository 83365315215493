import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import BatteryList from "./views/batteryList/batteryList";
import CreateBattery from "./views/newBattery/CreateBattery";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";
import BatchBattery from "./views/batchBattery/BatchBattery";
import BatteryGraph from "./views/BatteryGraph";
import BatteryFleetPerformance from "./views/BatteryFleetPerformance";
import CreateSupplierInfomation from "./views/vendor/CreateSupplierInfo";
import VendorList from "./views/vendor/SupplierInfo";
import Model from "./views/vendor/Models";
import {checkAuthorization} from "../auth/authorization";


export const BatteriesRouter =(props)=> {

   const batteryServiceName = getServiceName('batteryService')

   const batteryFeatureName = getFeatureName('batteryFeature')
   const historicalDataFeatureName = getFeatureName('historicalDataFeature')
   const vendorFeatureName = getFeatureName('vendorFeature')
   const modelFeatureName = getFeatureName('modelFeature')

   const listActionName = getActionName('listAction')
   const createActionName = getActionName('createAction')
   const updateActionName = getActionName('updateAction')

    return (
        <Switch>
            {/*Redirects the battery if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/batteries"}
                to={"/batteries/list"}
            />

            {/* Routes to Batteries List*/}
            <SecuredRoute
                path={"/batteries/list"}
                component={BatteryList}
                service={batteryServiceName}
                feature={batteryFeatureName}
                action={listActionName}
            />

            <SecuredRoute
                path={"/batteries/create"}
                component={CreateBattery}
                service={batteryServiceName}
                feature={batteryFeatureName}
                action={createActionName}
            />

            <SecuredRoute
                path={"/batteries/batch"}
                component={BatchBattery}
                service={batteryServiceName}
                feature={batteryFeatureName}
                action={checkAuthorization(props.authorization, batteryServiceName, batteryFeatureName, [createActionName])? createActionName:updateActionName}
            />

            <SecuredRoute
                path={"/batteries/edit"}
                component={CreateBattery}
                service={batteryServiceName}
                feature={batteryFeatureName}
                action={updateActionName}
            />

            <SecuredRoute
                path={"/batteries/battery_graph/:battery_serial_number"}
                component={BatteryGraph}
                service={batteryServiceName}
                feature={historicalDataFeatureName}
                action={listActionName}
            />
            <SecuredRoute
                path={"/batteries/battery_fleet"}
                component={BatteryFleetPerformance}
                service={batteryServiceName}
                feature={historicalDataFeatureName}
                action={listActionName}
            />

            <SecuredRoute
                path={"/batteries/vendors/list"}
                component={VendorList}
                service={batteryServiceName}
                feature={vendorFeatureName}
                action={listActionName}
            />

            <SecuredRoute
                path={"/batteries/models/list"}
                component={Model}
                service={batteryServiceName}
                feature={modelFeatureName}
                action={listActionName}
            />

            <SecuredRoute
                path={"/batteries/vendors/create"}
                component={CreateSupplierInfomation}
                service={batteryServiceName}
                feature={vendorFeatureName}
                action={createActionName}
            />

            <SecuredRoute
                path={"/batteries/vendors/model/update/:pk"}
                component={CreateSupplierInfomation}
                service={batteryServiceName}
                feature={modelFeatureName}
                action={updateActionName}
            />



            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
