import React, {useEffect, useState} from 'react';
import { useLocation } from "react-router";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import {Toolbar} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {Button, Form, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, requestCycle} from '../../utils'
import {GenericModal, LoadingSpinner, SolInput} from '../../../../components';
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import Select, {components} from "react-select";
import {toast} from "react-toastify";
import {Controller, useForm} from "react-hook-form";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import Stack from "@mui/material/Stack";
import {showNotifications} from "../../../../utils/notification";
import {SolTextArea} from "../../../../components/SolStyledComponents/components";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BranchAddEdit from '../../../../components/branchRelatedComponents/branchAddEditForm';
import {checkAuthorization} from "../../../auth/authorization";
import {csvToArray} from "../../../../utils/utilityFunctions";
import moment from "moment/moment";
import {ExportToCsv} from "export-to-csv";
import {isValidPhoneNumber} from "libphonenumber-js";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const FinancierCreation = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const [language, setLanguage] = useState(null);

    const [divisions, setDivisions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [subDistricts, setSubDistricts] = useState([]);
    const [tradeLicenseType, setTradeLicenseType] = useState('limited');

    const [showBranchAdditionModal, setShowBranchAdditionModal] = useState(false);
    const [branchCount, setBranchCount] = useState(1);
    const [initialPageForBranchTable, setInitialPageForBranchTable] = useState(0);
    const [branches, setBranches] = useState([{
        "sl": 1,
        "name": "Head Office",
        "address_details": "-",
        "division": "-",
        "district": "-",
        "upazila": "-",
        "zone": "-",
        "area": "-",
        "contact_person_designation": "-",
        "contact_person_name": "-",
        "contact_person_phone": "-",
        "action": "add",
        "deletable": false
    }]);
    // const [showBranchActionButtons, setShowBranchActionButtons] = useState(false);
    const [showBranchActionButtons, setShowBranchActionButtons] = useState(true);
    const [keyForBranchTable, setKeyForBranchTable] = useState(1);
    const [editableBranchData, setEditableBranchData] = useState({});
    const [editBranch, setEditBranch] = useState(false);
    const [mainBranchIsEdited, setMainBranchIsEdited] = useState(false);
    const [showBranchDeletionModal, setShowBranchDeletionModal] = useState(false);
    const [branchDeletionData, setBranchDeletionData] = useState({});

    const [zoneAreaCsvData, setZoneAreaCsvData] = useState({});
    const [makeApiCallToCollectZoneArea, setMakeApiCallToCollectZoneArea] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false);

    const [edit, setEdit] = useState(false);
    const [orgGuid, setOrgGuid] = useState('');
    const [previousFinancierData, setPreviousFinancierData] = useState({});
    const [pageTitle, setPageTitle] = useState('New Financier');
    const subHeader = useSubheader();
    const location = useLocation();

    const userServiceName = getServiceName('userService')
    const financierFeatureName = getFeatureName('financierFeature')
    const listActionName = getActionName('listAction')
    const createActionName = getActionName('createAction')
    const updateAction = getActionName('updateAction')

    const informationIsRequiredText = 'Above information is required!'
    const zoneAreaCsvColumns = ['Zone', 'Area']

    const {
        COLLECT_DIVISIONS_FOR_FINANCIER,
        COLLECT_DISTRICTS_FOR_FINANCIER,
        COLLECT_SUB_DISTRICTS_FOR_FINANCIER,
        COLLECT_ZONE_AREA_FOR_FINANCIER,
        CREATE_NEW_ORGANIZATION,
        EDIT_NEW_ORGANIZATION
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setBreadCrumbComponent(null)
        subHeader.setActionButtons(null)
        COLLECT_DIVISIONS_FOR_FINANCIER()
        // setShowBranchActionButtons(checkAuthorization(props.authorization, userServiceName, financierFeatureName, [updateAction]) ||
        //     checkAuthorization(props.authorization, userServiceName, financierFeatureName, [createActionName]))

        if (location.pathname === '/financiers/new') {
            // Dealing two cases,
            // Case 1:
            // Step 1 - user visits the financier edit page,
            // Step 2 - user clicks the browser back button and returns to the financier list
            // Step 3 - user clicks the "Create Financier" button
            // Case 2:
            // Step 1 - user visits the financier edit page and manually update the url from "edit" to "new"
            // - Noor Reza, 6th December, 2023, 5:18 PM
            setEdit(false)
            setPreviousFinancierData({})
            sessionStorage.clear()
        } else {
            setEdit(true)
            if (location.financierData) {
                let financierData = location.financierData
                sessionStorage.setItem('financierData', JSON.stringify(financierData))
                setPreviousFinancierData(financierData)
                setPageTitle('Edit Financier: ' + financierData.name)
                setValue('name', financierData.name)
                setValue('phone_number', financierData.phone_number)
                setValue('address', financierData.address)
                setTradeLicenseType(financierData.trade_license_type)
                setBranchCount(financierData.branch_list.length)
                setBranches(financierData.branch_list.map((item, index) => {
                    return {
                        ...item,
                        sl: index + 1,
                        deletableBranch: false
                    }
                }))
                setOrgGuid(financierData.pk)
                setMakeApiCallToCollectZoneArea(true)
            } else if (sessionStorage.getItem('financierData')) {
                let financierData = JSON.parse(sessionStorage.getItem('financierData'))
                setPreviousFinancierData(financierData)
                setPageTitle('Edit Financier: ' + financierData.name)
                setValue('name', financierData.name)
                setValue('phone_number', financierData.phone_number)
                setValue('address', financierData.address)
                setTradeLicenseType(financierData.trade_license_type)
                setBranchCount(financierData.branch_list.length)
                setBranches(financierData.branch_list.map((item, index) => {
                    return {
                        ...item,
                        sl: index + 1,
                        deletableBranch: false
                    }
                }))
                setOrgGuid(financierData.pk)
                setMakeApiCallToCollectZoneArea(true)
            } else {
                toast.warning('Please select a financier at first!')
                if (checkAuthorization(props.authorization, userServiceName, financierFeatureName, [listActionName])) {
                    props.history.push({
                        pathname: '/financiers/list'
                    })
                }
            }
        }

        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
            props.RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER();
        }
    }, []);

    // ---- Begin: Division, district and upazila list ---- //
    useEffect(() => {
        if (props.divisions && props.divisions.length > -1) {
            const divisions = props.divisions
            setDivisions(divisions.map((item) => {
                let division = {
                    ...item,
                    value: item.id,
                    label: item.name
                }
                if (edit && Object.keys(previousFinancierData).length >0 && previousFinancierData.division === division.name) {
                    setValue('division', division)
                    COLLECT_DISTRICTS_FOR_FINANCIER(division.value)
                }

                return division
            }))
        } else {
            setDivisions([])
        }
    }, [props.divisions])

    useEffect(() => {
        if (props.districts && props.districts.length > -1) {
            const districts = props.districts
            setDistricts(districts.map((item) => {
                let district = {
                    ...item,
                    value: item.id,
                    label: item.name
                }
                if (edit && Object.keys(previousFinancierData).length >0 && previousFinancierData.district === district.name) {
                    setValue('district', district)
                    COLLECT_SUB_DISTRICTS_FOR_FINANCIER(district.value)
                }

                return district
            }))
        } else {
            setDistricts([])
        }
    }, [props.districts])

    useEffect(() => {
        if (props.subDistricts && props.subDistricts.length > -1) {
            const subDistricts = props.subDistricts
            setSubDistricts(subDistricts.map((item) => {
                let upazila = {
                    ...item,
                    value: item.id,
                    label: item.name
                }
                if (edit && Object.keys(previousFinancierData).length >0 && previousFinancierData.upazila === upazila.name) {
                    setValue('upazila', upazila)
                }

                return upazila
            }))
        } else {
            setSubDistricts([])
        }
    }, [props.subDistricts])
    // ---- End: Division, district and upazila list ---- //

    // ---- Begin: Trade License Type selection handling ---- //
    const saveTradeLicenseTypeSelection = (event) => {
        setTradeLicenseType(event.target.value)
    }
    // ---- End: Trade License Type selection handling ---- //

    // ---- Begin: Branch add, edit and delete --- //
    const branchListColumns =  [
        {
            field: 'sl',
            title: "SL",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'name',
            title: "Name",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'division',
            title: "Division",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'district',
            title: "District",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'zone',
            title: "Zone / Region",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'area',
            title: "Area",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'upazila',
            title: "Sub-District/City Corporation",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        }
    ]

    const initiateBranchAddition = () => {
        setEditBranch(false)
        setEditableBranchData({})
        setShowBranchAdditionModal(true)
    }

    const hideBranchAdditionModal = () => {
        setShowBranchAdditionModal(false)
    }

    const saveBranch = (data) => {
        let newBranchInfo = {...data, 'zone': data.zone?.label, 'area': data.area?.label,
            'division': data.division.label, 'district': data.district.label,
            'upazila': data.upazila.label, "action": "add"}
        let updatedBranches = branches
        let numberOfBranches = branches.length
        if (!editBranch) {
            newBranchInfo['sl'] = updatedBranches[numberOfBranches - 1]['sl'] + 1
            // Making the new branch always deletable
            newBranchInfo['deletableBranch'] = true
            updatedBranches.push(newBranchInfo)
            setBranchCount(numberOfBranches + 1)
            // Initiating the branch dataTable at the last page to show the added branch
            // Formula is actually: Math.floor(((numberOfBranches + 1) - 1) / 5)
            setInitialPageForBranchTable(Math.floor((numberOfBranches) / 5))
            setBranches(updatedBranches)
        } else {
            // In case of editing branch, passing the information as is
            setBranches(updatedBranches.map((item) => {
                if (newBranchInfo['sl'] === item['sl']) {
                    // Initiating the branch dataTable at the page where the branch is edited
                    setInitialPageForBranchTable(Math.floor((newBranchInfo['sl'] - 1) / 5))
                    return {
                        ...newBranchInfo
                    }
                }
                return {
                    ...item
                }
            }))
            if (newBranchInfo['sl'] === 1) {
                setMainBranchIsEdited(true)
            }
        }
        setKeyForBranchTable(keyForBranchTable + 1)
        setShowBranchAdditionModal(false)
    }

    const initiateBranchDeletion = (data) => {
        setBranchDeletionData(data)
        setShowBranchDeletionModal(true)
    }

    const hideBranchDeletionModal = () => {
        setShowBranchDeletionModal(false)
    }

    const deleteBranch = () => {
        if ((!edit && branchDeletionData['sl'] !== 1) || (edit && !(branchDeletionData['sl'] === 1 && branches.length === 1))) {
            let remainingBranches = branches.filter(item => item['sl'] !== branchDeletionData['sl'])
            let deletedBranchIndex = branchDeletionData['sl'] - 1
            if (deletedBranchIndex % 5 === 0 && deletedBranchIndex === branches.length - 1) {
                // When last item of a page is deleted we initiate the branch dataTable at the previous page
                setInitialPageForBranchTable(Math.floor(deletedBranchIndex / 5) - 1)
            } else {
                // Otherwise, the table is initiated at the same page
                setInitialPageForBranchTable(Math.floor(deletedBranchIndex / 5))
            }
            setBranches(remainingBranches.map((item, index) => {
                return {
                    ...item,
                    sl: index + 1
                }
            }))
            setBranchCount(branchCount - 1)
            setShowBranchDeletionModal(false)
        }
    }

    const renderBranchDeletionModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideBranchDeletionModal}
                modal={showBranchDeletionModal}
                title={<h1><strong>Deleting branch: {branchDeletionData.name}</strong></h1>}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <h4>Are you sure?</h4>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            spacing={1}
                        >
                            <Button style={{
                                backgroundColor: '#6c757d',
                                border: 'none'
                            }} size={'md'} onClick={hideBranchDeletionModal}>
                                No
                            </Button>
                            <Button variant="warning" size={'md'} type="button" onClick={deleteBranch}>
                                Yes
                            </Button>
                        </Stack>
                    </div>
                </div>
            </GenericModal>
        </>
    }

    const initiateEditBranch = (data) => {
        setEditBranch(true)
        setEditableBranchData({...data})
        setShowBranchAdditionModal(true)
    }

    const renderBranchCreationModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideBranchAdditionModal}
                modal={showBranchAdditionModal}
                title={<h1><strong>Branch Details</strong></h1>}>
                <BranchAddEdit submitFormData={saveBranch} closeForm={hideBranchAdditionModal} modalFooter={true}
                               editable={editBranch} branchData={editableBranchData} zoneAreaData={zoneAreaCsvData}
                               makeApiCallForZoneArea={makeApiCallToCollectZoneArea} orgGuid={orgGuid}/>
            </GenericModal>
        </>
    }
    // ---- End: Branch add, edit and delete --- //

    // ---- Begin: Section for organization info ---- //
    const csvExporter = (fileName, dataToExport=[]) => {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: '',
            filename: fileName,
            useTextFile: false,
            useBom: true,
            headers: zoneAreaCsvColumns
        }
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(dataToExport)
    }
    const downloadBlankCsvTemplate = () => {
        csvExporter('zone_area_demo_csv')
    }

    const downloadCurrentZoneAreaCsv = () => {
        COLLECT_ZONE_AREA_FOR_FINANCIER(orgGuid)
    }

    useEffect(() => {
        if (props.zoneAreas && props.zoneAreas.zone_areas) {
            const zoneAreas = props.zoneAreas.zone_areas
            const zones = Object.keys(zoneAreas)
            if (zones.length > 0) {
                let zoneAreaListForExport = []
                for (let i=0; i<zones.length; i++) {
                    let areasUnderThisZone = zoneAreas[zones[i]]
                    for (let j=0; j<areasUnderThisZone.length; j++) {
                        zoneAreaListForExport.push({
                            'Zone': zones[i],
                            'Area': areasUnderThisZone[j]
                        })
                    }
                }
                csvExporter('zone_area_current_csv', zoneAreaListForExport)
            } else {
                csvExporter('zone_area_current_csv')
            }
        }
    }, [props.zoneAreas])
    const parseUploadedCsv = (file) => {
        const reader = new FileReader();

        reader.onload = function (e) {
            let uploadedInformation = csvToArray(e.target.result)
            let uploadedCsvColumns = Object.keys(uploadedInformation[0])
            let uploadedCsvHasAllExpectedColumns = uploadedCsvColumns.every(column => zoneAreaCsvColumns.includes(column))
            if (uploadedCsvColumns.length !== 2 || !uploadedCsvHasAllExpectedColumns) {
                // Dealing with the cases:
                //  - More or less than two distinct columns
                //  - Having columns other than 'Zone' and 'Area'
                setError('zone_area_csv', {'message': 'CSV should contain only two columns "Zone" and "Area"!'})
            } else if (uploadedCsvColumns[0] !== 'Zone' || uploadedCsvColumns[1] !== 'Area') {
                // Forcing user to maintain the order of the columns at csv
                setError('zone_area_csv', {'message': 'Columns should be in this order - 1. Zone, 2. Area!'})
            } else {
                let dataIsOk = true
                // Deleting the last row of the csv which has been added by default by the Linux OS
                // At last row, normally the first column has empty string and second column (Area) has the 'undefined' value
                let filteredUploadedInformation = uploadedInformation.filter(info => info.Area !== undefined)
                let onlyZones = []
                let zoneAreaInfo = {}
                for (let i=0; i<filteredUploadedInformation.length; i++) {
                    let zone = filteredUploadedInformation[i]['Zone']
                    let area = filteredUploadedInformation[i]['Area']
                    if (!zone || !area) {
                        // That means, not all data are provided
                        setError('zone_area_csv', {'message': 'Csv should not have empty Zone/Area name!'})
                        dataIsOk = false
                        break
                    } else if (!/^[a-zA-Z0-9]([a-zA-Z0-9 ])+$/.test(zone) || !/^[a-zA-Z0-9]([a-zA-Z0-9 ])+$/.test(area)) {
                        // That means, one of the Zone/Area name is invalid
                        setError('zone_area_csv', {'message': 'Invalid Zone/Area name provided!'})
                        dataIsOk = false
                        break
                    } else {
                        // We could use Object.keys(zoneAreaInfo) but in that case we would have to run
                        // Object.keys each time and then check with includes() - Noor Reza, 12th March, 2024 11:01 am
                        if (i === 0 || !onlyZones.includes(zone)) {
                            onlyZones.push(zone)
                            zoneAreaInfo[zone] = [area]
                        } else {
                            zoneAreaInfo[zone].push(area)
                        }
                    }
                }
                if (dataIsOk) {
                    setZoneAreaCsvData(zoneAreaInfo)
                    setMakeApiCallToCollectZoneArea(false)
                }
            }
        };
        reader.readAsText(file)
    }

    const saveOrganizationInfo = (data) => {
        let organizationData = {...data, "zone_area_csv": zoneAreaCsvData, "trade_license_type": tradeLicenseType,
            "division": data.division.label, "district": data.district.label, "upazila": data.upazila.label}
        if (!edit) {
            if (branches[0]["name"] === "Head Office") {
                branches[0]["name"] = "Head Office - " + organizationData["name"]
            }
            let payload = {...organizationData, "branch_list": branches.map((item) => {
                    // 'sl' and 'tableData' keys are being deleted from payload here
                    // If we delete 'tableData' using 'delete item["tableData"]' page gets crashed as this key is needed to be
                    // in 'branches' for being used at dataTable - Noor Reza, 4th December, 2023 7:05 PM
                    return {
                        name: item["name"],
                        address_details: item["address_details"],
                        division: item["division"],
                        district: item["district"],
                        upazila: item["upazila"],
                        zone: item["zone"],
                        area: item["area"],
                        contact_person_designation: item["contact_person_designation"],
                        contact_person_name: item["contact_person_name"],
                        contact_person_phone: item["contact_person_phone"],
                        action: item["action"]
                    }
                })}
            CREATE_NEW_ORGANIZATION(payload)
        } else {
            let branchList = previousFinancierData.branch_list.map((branch) => {
                // Checking if a branch has been deleted
                let info = {
                    pk: branch["pk"],
                    name: branch["name"],
                    address_details: branch["address_details"],
                    division: branch["division"],
                    district: branch["district"],
                    upazila: branch["upazila"],
                    zone: branch["zone"],
                    area: branch["area"],
                    contact_person_designation: branch["contact_person_designation"],
                    contact_person_name: branch["contact_person_name"],
                    contact_person_phone: branch["contact_person_phone"],
                    action: "update"
                }
                let checkTheBranchStillExists = branches.filter(item => item.pk === branch.pk )
                if (!checkTheBranchStillExists[0]) {
                    // That means, branch has been deleted
                    info["action"] = "remove"
                } else {
                    // That means, branch information may have been updated
                    info["name"] = checkTheBranchStillExists[0]["name"]
                    info["address_details"] = checkTheBranchStillExists[0]["address_details"]
                    info["division"] = checkTheBranchStillExists[0]["division"]
                    info["district"] = checkTheBranchStillExists[0]["district"]
                    info["upazila"] = checkTheBranchStillExists[0]["upazila"]
                    info["zone"] = checkTheBranchStillExists[0]["zone"]
                    info["area"] = checkTheBranchStillExists[0]["area"]
                    info["contact_person_designation"] = checkTheBranchStillExists[0]["contact_person_designation"]
                    info["contact_person_name"] = checkTheBranchStillExists[0]["contact_person_name"]
                    info["contact_person_phone"] = checkTheBranchStillExists[0]["contact_person_phone"]
                }
                return {...info}
            })
            for (let i=0; i<branches.length; i++) {
                // Checking if a branch has been added
                if (!branches[i].pk) {
                    branchList.push({
                        name: branches[i]["name"],
                        address_details: branches[i]["address_details"],
                        division: branches[i]["division"],
                        district: branches[i]["district"],
                        upazila: branches[i]["upazila"],
                        zone: branches[i]["zone"],
                        area: branches[i]["area"],
                        contact_person_designation: branches[i]["contact_person_designation"],
                        contact_person_name: branches[i]["contact_person_name"],
                        contact_person_phone: branches[i]["contact_person_phone"],
                        action: "add"
                    })
                }
            }
            let payload = {...organizationData, "branch_list": branchList}
            EDIT_NEW_ORGANIZATION(previousFinancierData.pk, payload)
        }
    }

    useEffect(() => {
        if (props.orgCreated === requestCycle.success) {
            toast.success("Financier is created successfully!")
            closeTheForm()
            props.history.push({pathname: "/financiers/list"})
        }
    }, [props.orgCreated])

    useEffect(() => {
        if (props.errorMessageForOrgCreation) {
            showNotifications('error', props.errorMessageForOrgCreation)
        }
    }, [props.errorMessageForOrgCreation])

    useEffect(() => {
        if (props.orgEdited === requestCycle.success) {
            toast.success("Financier is edited successfully!")
            closeTheForm()
            props.history.push({pathname: "/financiers/list"})
        }
    }, [props.orgEdited])

    useEffect(() => {
        if (props.errorMessageForOrgEdit) {
            showNotifications('error', props.errorMessageForOrgEdit)
        }
    }, [props.errorMessageForOrgEdit])
    // ---- End: Section for organization info ---- //

    const closeTheForm = () => {
        sessionStorage.clear()
        props.history.push({pathname: "/financiers/list"})
    }

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={pageTitle}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{pageTitle}</b></h1>
                            </Toolbar.Title>
                        </Toolbar>
                    </div>
                </div>
                <hr/>

                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Form onSubmit={handleSubmit(saveOrganizationInfo)}>
                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Name&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolInput
                                            name={'name'}
                                            type={'text'}
                                            placeholder={'Type name...'}
                                            autoComplete={"off"}
                                            ref={register({
                                                required: informationIsRequiredText,
                                                validate: {
                                                    // We are allowing all bengali characters including bengali numeric - Noor Reza 30th November, 2023 12:22 PM
                                                    // Unicode source - https://github.com/lifeparticle/Bengali-Alphabet
                                                    isValidName: value => (/^[a-zA-Z0-9-'. \u0980-\u09FF]+$/.test(value) || !value) || "Invalid Financier Name!"
                                                }
                                            })}
                                        />
                                        {errors.name && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.name.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>

                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Contact Number&nbsp;<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolInput
                                            name={'phone_number'}
                                            type={'text'}
                                            placeholder={'Ex: 01676366488'}
                                            autoComplete={"off"}
                                            ref={register({
                                                required: informationIsRequiredText,
                                                validate: {
                                                    isValidPhoneNumber: value => (isValidPhoneNumber(value, 'BD') || !value) || "Invalid contact number!"
                                                }
                                            })}
                                        />
                                        {errors.phone_number && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.phone_number.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>

                            <div className={'row g-3 mt-7'}>
                                <div className={'col-md-12'}>
                                    <h3><strong>Location</strong></h3>
                                </div>
                            </div>
                            <div className={'row g-3 mt-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Address <span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolTextArea
                                            name={"address"}
                                            placeholder={"House Number, Road Number etc."}
                                            autoComplete={"off"}
                                            rows={"3"}
                                            ref={register({
                                                required: informationIsRequiredText,
                                                validate: {
                                                    addressValidityCheck: value => (/^[a-zA-Z0-9\s,/'() -]+$/.test(value) || !value) || "Invalid address"
                                                }
                                            })}
                                        />
                                        {errors.address && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.address.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-2'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Division <span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"division"}
                                            rules={{ required: informationIsRequiredText }}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select Division'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.divisionsCollectionInProgress}
                                                    isLoading={props.divisionsCollectionInProgress}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={divisions}
                                                    isSearchable={true}
                                                    noOptionsMessage={() => "No divisions"}
                                                    onChange={(selected, {action}) => {
                                                        if (action === 'clear') {
                                                            setValue('district', '')
                                                            setDistricts([])

                                                            setValue('upazila', '')
                                                            setSubDistricts([])

                                                            if (!edit && !mainBranchIsEdited) {
                                                                branches[0]['division'] = '-'
                                                                branches[0]['district'] = '-'
                                                                branches[0]['upazila'] = '-'
                                                            }
                                                        }
                                                        onChange(selected)
                                                        if (selected) {
                                                            setValue('district', '')
                                                            COLLECT_DISTRICTS_FOR_FINANCIER(selected.value)

                                                            setValue('upazila', '')
                                                            setSubDistricts([])

                                                            if (!edit && !mainBranchIsEdited) {
                                                                branches[0]['division'] = selected.label
                                                                branches[0]['district'] = '-'
                                                                branches[0]['upazila'] = '-'
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.division && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.division.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-2'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>District <span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"district"}
                                            rules={{ required: informationIsRequiredText }}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select District'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.districtsCollectionInProgress}
                                                    isLoading={props.districtsCollectionInProgress}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={districts}
                                                    isSearchable={true}
                                                    noOptionsMessage={() => "No districts"}
                                                    onChange={(selected, {action}) => {
                                                        if (action === 'clear') {
                                                            setValue('upazila', '')
                                                            setSubDistricts([])

                                                            if (!edit && !mainBranchIsEdited) {
                                                                branches[0]['district'] = '-'
                                                                branches[0]['upazila'] = '-'
                                                            }
                                                        }
                                                        onChange(selected)
                                                        if (selected) {
                                                            setValue('upazila', '')
                                                            COLLECT_SUB_DISTRICTS_FOR_FINANCIER(selected.value)

                                                            if (!edit && !mainBranchIsEdited) {
                                                                branches[0]['district'] = selected.label
                                                                branches[0]['upazila'] = '-'
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.district && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.district.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-2'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Sub-District <span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"upazila"}
                                            rules={{ required: informationIsRequiredText }}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select Sub-District'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.subDistrictsCollectionInProgress}
                                                    isLoading={props.subDistrictsCollectionInProgress}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={subDistricts}
                                                    isSearchable={true}
                                                    noOptionsMessage={() => "No sub-districts"}
                                                    onChange={(selected, {action}) => {
                                                        onChange(selected)
                                                        if (!edit && !mainBranchIsEdited) {
                                                            if (action === 'clear') {
                                                                branches[0]['upazila'] = '-'
                                                            }
                                                            // We are re-drawing the table as sometimes info is not getting
                                                            // updated at the table from here - Noor Reza, 4th December, 2023
                                                            setKeyForBranchTable(keyForBranchTable + 1)
                                                            if (selected) {
                                                                branches[0]['upazila'] = selected.label
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.upazila && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.upazila.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={!edit? 'col-md-4':'col-md-2'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Upload CSV for Zone & Area</Form.Label>
                                        </div>
                                        <SolInput
                                            type={"file"}
                                            name={"zone_area_csv"}
                                            autoComplete={"off"}
                                            ref={register()}
                                            onClick={(e) => {
                                                // Clearing the file field value when user tries to re-upload
                                                setValue('zone_area_csv', '')
                                            }}
                                            onChange={(e) => {
                                                if (e.target.files) {
                                                    let value = e.target.files[0]
                                                    if (value) {
                                                        if (value.type !== 'text/csv') {
                                                            setError('zone_area_csv', {'message': 'Wrong file type!'})
                                                            setDisableSaveButton(true)
                                                        } else if (value.size/(1024 * 1024) > 10) {
                                                            setError('zone_area_csv', {'message': 'File size shouldn"t exceed 10MB!'})
                                                            setDisableSaveButton(true)
                                                        } else {
                                                            clearErrors('zone_area_csv')
                                                            setDisableSaveButton(false)
                                                            parseUploadedCsv(value)
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                        {errors.zone_area_csv && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.zone_area_csv.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-2'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>&nbsp;</Form.Label>
                                        </div>
                                        <a style={{color: "#F18D00"}} onClick={downloadBlankCsvTemplate}>Download CSV Template</a>
                                    </Form.Group>
                                </div>
                                {edit? <>
                                    <div className={'col-md-2'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>&nbsp;</Form.Label>
                                            </div>
                                            <a style={{color: "#F18D00"}} onClick={downloadCurrentZoneAreaCsv}>{!props.zoneAreaCollectionInProgress? 'Download Current CSV':'Downloading current csv...'}</a>
                                        </Form.Group>
                                    </div>
                                </>:''}
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <div>
                                        <Form.Label>Trade License <span className="required text-danger">*</span></Form.Label>
                                    </div>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-12'}>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={1.5}
                                    >
                                        <Stack
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={1}
                                        >
                                            <div className="form-check form-check-inline">
                                                <SolRadioInput
                                                    {...register("trade_license_type")}
                                                    id="individual"
                                                    value={"individual"}
                                                    handleChange={(event) => saveTradeLicenseTypeSelection(event)}
                                                    checked={tradeLicenseType === "individual"}
                                                    htmlFor="individual"
                                                    labelName="Individual (sole proprietorship)"
                                                />
                                            </div>
                                            {/*<div className="small ml-6"><strong>Daily rent fee is collected when a rent is created</strong></div>*/}
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={1}
                                        >
                                            <div className="form-check form-check-inline">
                                                <SolRadioInput
                                                    {...register("trade_license_type")}
                                                    id="limited"
                                                    value={"limited"}
                                                    handleChange={(event) => saveTradeLicenseTypeSelection(event)}
                                                    checked={tradeLicenseType === "limited"}
                                                    htmlFor="limited"
                                                    labelName="Limited Company"
                                                />
                                            </div>
                                            {/*<div className="small ml-6"><strong>A fixed lease payment weekly or monthly</strong></div>*/}
                                        </Stack>
                                    </Stack>
                                </div>
                            </div>

                            <div className={'row g-3 mt-10'}>
                                <div className={'col-md-12'}>
                                    {/* display: inline removes bottom spacing, reference: https://stackoverflow.com/questions/1235134/remove-padding-beneath-heading-tag */}
                                    {/* Noor Reza, 1st December, 2023 8:16 PM */}
                                    <h3 style={{display: "inline"}}><strong>Branches</strong> {showBranchActionButtons? <>
                                        <Button variant={"outline-warning"} size={"sm"} type={"button"} onClick={initiateBranchAddition}>Add</Button>
                                    </>:''}</h3>
                                    <br/><span>{branchCount} {branchCount > 1? 'branches':'branch'}</span>
                                </div>
                            </div>
                            <div className={'row g-3'} key={keyForBranchTable}>
                                <div className={'col-md-12'}>
                                    <DataTableContainer>
                                         <DataTable
                                            language={language}
                                            noDataAvailableMessageInEnglish={'No branches available'}
                                            columns={branchListColumns}
                                            data={branches}
                                            showToolbar={false}
                                            asyncPagination={false}
                                            pagination={true}
                                            pageSize={5}
                                            actionColumnIndex={-1}
                                            overrideCustomActions={true}
                                            initialPage={initialPageForBranchTable}
                                            actions={showBranchActionButtons? [
                                                {
                                                    position: "row",
                                                    action: (rowData) => ({
                                                        icon: () => {
                                                            return <>
                                                                <IconButton name={"test"}
                                                                            classes={classes}>
                                                                    <EditOutlinedIcon style={{ color: "#F18D00" }}/>
                                                                </IconButton>
                                                            </>
                                                        },
                                                        onClick: (e, row) => {initiateEditBranch(row)},
                                                    })
                                                },
                                                {
                                                    position: "row",
                                                    action: (rowData) => ({
                                                        icon: () => {
                                                            return <>
                                                                {rowData.deletableBranch? <>
                                                                    <IconButton name={"test"}
                                                                                classes={classes}>
                                                                        <DeleteOutlineIcon style={{ color: "#F18D00" }}/>
                                                                    </IconButton>
                                                                </>:<></>}
                                                            </>
                                                        },
                                                        onClick: (e, row) => {initiateBranchDeletion(row)},
                                                    })
                                                }
                                            ]:[]}
                                        />
                                    </DataTableContainer>
                                </div>
                            </div>

                            <div className={'row mt-7'}>
                                <div className={"col-md-12"}>
                                    <Button variant="warning" size={'md'} type="submit"
                                            disabled={props.orgCreationInProgress || props.orgEditionInProgress || disableSaveButton}>
                                        {props.orgCreationInProgress || props.orgEditionInProgress? <><Spinner animation={'border'} size={'sm'} variant={'light'}/>&nbsp;</>:null}Save
                                    </Button>
                                    <Button variant="outline-dark" size={'md'} disabled={props.orgCreationInProgress || props.orgEditionInProgress}
                                            className={'ml-3'} onClick={closeTheForm}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>

                {renderBranchDeletionModal()}
                {renderBranchCreationModal()}
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    );
};

FinancierCreation.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        divisions: state.financierReducer.divisions,
        divisionsCollectionInProgress: state.financierReducer.divisionsCollectionInProgress,
        districts: state.financierReducer.districts,
        districtsCollectionInProgress: state.financierReducer.districtsCollectionInProgress,
        subDistricts: state.financierReducer.subDistricts,
        subDistrictsCollectionInProgress: state.financierReducer.subDistrictsCollectionInProgress,
        zoneAreas: state.financierReducer.zoneAreas,
        zoneAreaCollectionInProgress: state.financierReducer.zoneAreaCollectionInProgress,
        orgCreated: state.financierReducer.orgCreated,
        orgCreationInProgress: state.financierReducer.orgCreationInProgress,
        errorMessageForOrgCreation: state.financierReducer.errorMessageForOrgCreation,
        orgEdited: state.financierReducer.orgEdited,
        orgEditionInProgress: state.financierReducer.orgEditionInProgress,
        errorMessageForOrgEdit: state.financierReducer.errorMessageForOrgEdit
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(FinancierCreation));
