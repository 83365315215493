export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_FINANCIERS_REQUEST: 'COLLECT_FINANCIERS_REQUEST',
    COLLECT_FINANCIERS_SUCCESS: 'COLLECT_FINANCIERS_SUCCESS',
    COLLECT_FINANCIERS_FAILURE: 'COLLECT_FINANCIERS_FAILURE',

    COLLECT_BRANCHES_OF_A_FINANCIER_REQUEST: 'COLLECT_BRANCHES_OF_A_FINANCIER_REQUEST',
    COLLECT_BRANCHES_OF_A_FINANCIER_SUCCESS: 'COLLECT_BRANCHES_OF_A_FINANCIER_SUCCESS',
    COLLECT_BRANCHES_OF_A_FINANCIER_FAILURE: 'COLLECT_BRANCHES_OF_A_FINANCIER_FAILURE',

    COLLECT_DIVISIONS_FOR_FINANCIER_REQUEST: 'COLLECT_DIVISIONS_FOR_FINANCIER_REQUEST',
    COLLECT_DIVISIONS_FOR_FINANCIER_SUCCESS: 'COLLECT_DIVISIONS_FOR_FINANCIER_SUCCESS',
    COLLECT_DIVISIONS_FOR_FINANCIER_FAILURE: 'COLLECT_DIVISIONS_FOR_FINANCIER_FAILURE',

    COLLECT_DISTRICTS_FOR_FINANCIER_REQUEST: 'COLLECT_DISTRICTS_FOR_FINANCIER_REQUEST',
    COLLECT_DISTRICTS_FOR_FINANCIER_SUCCESS: 'COLLECT_DISTRICTS_FOR_FINANCIER_SUCCESS',
    COLLECT_DISTRICTS_FOR_FINANCIER_FAILURE: 'COLLECT_DISTRICTS_FOR_FINANCIER_FAILURE',

    COLLECT_SUB_DISTRICTS_FOR_FINANCIER_REQUEST: 'COLLECT_SUB_DISTRICTS_FOR_FINANCIER_REQUEST',
    COLLECT_SUB_DISTRICTS_FOR_FINANCIER_SUCCESS: 'COLLECT_SUB_DISTRICTS_FOR_FINANCIER_SUCCESS',
    COLLECT_SUB_DISTRICTS_FOR_FINANCIER_FAILURE: 'COLLECT_SUB_DISTRICTS_FOR_FINANCIER_FAILURE',

    COLLECT_ZONE_AREA_FOR_FINANCIER_REQUEST: 'COLLECT_ZONE_AREA_FOR_FINANCIER_REQUEST',
    COLLECT_ZONE_AREA_FOR_FINANCIER_SUCCESS: 'COLLECT_ZONE_AREA_FOR_FINANCIER_SUCCESS',
    COLLECT_ZONE_AREA_FOR_FINANCIER_FAILURE: 'COLLECT_ZONE_AREA_FOR_FINANCIER_FAILURE',

    CREATE_NEW_ORGANIZATION_REQUEST: 'CREATE_NEW_ORGANIZATION_REQUEST',
    CREATE_NEW_ORGANIZATION_SUCCESS: 'CREATE_NEW_ORGANIZATION_SUCCESS',
    CREATE_NEW_ORGANIZATION_FAILURE: 'CREATE_NEW_ORGANIZATION_FAILURE',

    EDIT_NEW_ORGANIZATION_REQUEST: 'EDIT_NEW_ORGANIZATION_REQUEST',
    EDIT_NEW_ORGANIZATION_SUCCESS: 'EDIT_NEW_ORGANIZATION_SUCCESS',
    EDIT_NEW_ORGANIZATION_FAILURE: 'EDIT_NEW_ORGANIZATION_FAILURE',

    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',
};
