export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_EV_LIST_REQUEST: 'COLLECT_EV_LIST_REQUEST',
    COLLECT_EV_LIST_SUCCESS: 'COLLECT_EV_LIST_SUCCESS',
    COLLECT_EV_LIST_FAILURE: 'COLLECT_EV_LIST_FAILURE',

    COLLECT_EV_DETAILS_REQUEST: 'COLLECT_EV_DETAILS_REQUEST',
    COLLECT_EV_DETAILS_SUCCESS: 'COLLECT_EV_DETAILS_SUCCESS',
    COLLECT_EV_DETAILS_FAILURE: 'COLLECT_EV_DETAILS_FAILURE',

    COLLECT_TRANSACTIONS_REQUEST: 'COLLECT_TRANSACTIONS_REQUEST',
    COLLECT_TRANSACTIONS_SUCCESS: 'COLLECT_TRANSACTIONS_SUCCESS',
    COLLECT_TRANSACTIONS_FAILURE: 'COLLECT_TRANSACTIONS_FAILURE',

    COLLECT_PAYMENT_DETAILS_REQUEST: 'COLLECT_PAYMENT_DETAILS_REQUEST',
    COLLECT_PAYMENT_DETAILS_SUCCESS: 'COLLECT_PAYMENT_DETAILS_SUCCESS',
    COLLECT_PAYMENT_DETAILS_FAILURE: 'COLLECT_PAYMENT_DETAILS_FAILURE',

    COLLECT_RENT_LIST_REQUEST: 'COLLECT_RENT_LIST_REQUEST',
    COLLECT_RENT_LIST_SUCCESS: 'COLLECT_RENT_LIST_SUCCESS',
    COLLECT_RENT_LIST_FAILURE: 'COLLECT_RENT_LIST_FAILURE',

    COLLECT_DRIVER_DETAILS_REQUEST: 'COLLECT_DRIVER_DETAILS_REQUEST',
    COLLECT_DRIVER_DETAILS_SUCCESS: 'COLLECT_DRIVER_DETAILS_SUCCESS',
    COLLECT_DRIVER_DETAILS_FAILURE: 'COLLECT_DRIVER_DETAILS_FAILURE',

    COLLECT_LOCATIONS_REQUEST: 'COLLECT_LOCATIONS_REQUEST',
    COLLECT_LOCATIONS_SUCCESS: 'COLLECT_LOCATIONS_SUCCESS',
    COLLECT_LOCATIONS_FAILURE: 'COLLECT_LOCATIONS_FAILURE',

    RETURN_TO_INITIAL_STATE_FOR_SEARCH: 'RETURN_TO_INITIAL_STATE_FOR_SEARCH',
};
