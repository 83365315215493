import React, {useEffect, useState} from 'react';
import { useLocation } from "react-router";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {Button, Form, Spinner} from "react-bootstrap";
import {makeStyles} from "@material-ui/styles";
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import {Controller, useForm} from "react-hook-form";
import '../customSwitch.css';
import {SolInput, SolTextArea} from "../../../../components/SolStyledComponents/components";
import {requestCycle} from "../../utils";
import {toast} from "react-toastify";
import {commonReducer} from "../../../commonReduxActions/commonReducer";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import Stack from "@mui/material/Stack";
import {showNotifications} from "../../../../utils/notification";
import {isValidPhoneNumber} from "libphonenumber-js";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const BorrowerRegistration = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const subHeader = useSubheader();
    const location = useLocation();
    const classes = useStylesIconButton();
    const [language, setLanguage] = useState(null);
    const [disableSaveButton, setDisableSaveButton] = useState(false);

    const [organizations, setOrganizations] = useState([]);
    const [branches, setBranches] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [upazilas, setUpazilas] = useState([]);
    const [upazilasForGarageInfo, setUpazilasForGarageInfo] = useState([]);
    const [thanas, setThanas] = useState([]);
    const [thanasForGarageInfo, setThanasForGarageInfo] = useState([]);
    const [showThanaSelectionForPersonalInfo, setShowThanaSelectionForPersonalInfo] = useState(false);
    const [showThanaSelectionForGarageInfo, setShowThanaSelectionForGarageInfo] = useState(false);
    const [garageDeactivationProcess, setGarageDeactivationProcess] = useState('deactivate_whole_garage');
    const [leaseModelEnabled, setLeaseModelEnabled] = useState('yes');

    // Edit related
    const [edit, setEdit] = useState(!!sessionStorage.getItem('edit'));
    const [garageGuid, setGarageGuid] = useState(sessionStorage.getItem('borrowerGarageGuid') ? sessionStorage.getItem('borrowerGarageGuid') : "");
    const [borrowerName, setBorrowerName] = useState(sessionStorage.getItem('borrowerName') ? sessionStorage.getItem('borrowerName') : "");
    const [garageName, setGarageName] = useState(sessionStorage.getItem('garageName') ? sessionStorage.getItem('garageName') : "");

    const informationIsRequired = language === 'EN'? 'This information is required!':'এই তথ্যটা জরুরী!'
    const invalidNumberLength = language === 'EN'? 'Contact Number should contain 11 digits!':'কন্টাক্ট নাম্বারে ১১টি সংখ্যা থাকা উচিত!'
    const invalidFileType = language === 'EN'? 'Invalid file format!':'এই ফরম্যাটের ফাইল গ্রহণযোগ্য নয়!'
    const invalidSize = language === 'EN'? 'File size shouldn"t exceeds 10MB!':'ফাইলের সাইজ ১০ মেগাবাইটের বেশি হওয়া উচিত না!'

    const {
        COLLECT_DISTRICTS,
        COLLECT_UPAZILAS_FOR_ALL,
        COLLECT_UPAZILAS_FOR_BORROWER,
        COLLECT_UPAZILAS_FOR_GARAGE,
        COLLECT_THANAS_FOR_BORROWER,
        COLLECT_THANAS_FOR_GARAGE,
        REGISTER_BORROWER,
        EDIT_BORROWER,
        GET_ORGANISATIONS,
        GET_BRANCHES_LIST
    } = props;

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setActionButtons(null)
        subHeader.setBreadCrumbComponent(null)

        GET_ORGANISATIONS()
        COLLECT_DISTRICTS('all')
        COLLECT_UPAZILAS_FOR_ALL("all")

        if (location.purpose && location.purpose === 'registration') {
            setEdit(false)
            sessionStorage.setItem('edit', false)
        }

        if (location.borrowerData) {
            sessionStorage.setItem('edit', true)
            setEdit(true)

            // Borrower Garage GUID
            let borrowerGarageGuid = location.borrowerData.pk
            sessionStorage.setItem('borrowerGarageGuid', borrowerGarageGuid)
            setGarageGuid(borrowerGarageGuid)

            // Borrower Name
            let borrowerName = location.borrowerData.garage_owner_name
            sessionStorage.setItem('borrowerName', borrowerName)
            setValue('borrowerName', borrowerName)
            setBorrowerName(borrowerName)

            // Borrower Contact Number
            let borrowerContact = location.borrowerData.garage_phone_number
            sessionStorage.setItem('borrowerContact', borrowerContact)
            setValue('contactNumber', borrowerContact)

            // Borrower NID
            let borrowerNid = location.borrowerData.garage_owner_nid_number
            sessionStorage.setItem('borrowerNid', borrowerNid? borrowerNid:'')
            setValue('nidNumber', borrowerNid? borrowerNid:'')

            // Borrower home address
            let borrowerHomeAddress = location.borrowerData.garage_owner_address
            sessionStorage.setItem('borrowerHomeAddress', borrowerHomeAddress? borrowerHomeAddress:'')
            setValue('homeAddress', borrowerHomeAddress? borrowerHomeAddress:'')

            // Borrower district
            sessionStorage.setItem('borrowerDistrict', location.borrowerData.garage_owner_district)

            // Borrower upazila
            sessionStorage.setItem('borrowerUpazila', location.borrowerData.garage_owner_upazila)

            // Borrower thana
            sessionStorage.setItem('borrowerThana', location.borrowerData.garage_owner_thana)

            // Garage Name
            let garageName = location.borrowerData.name
            sessionStorage.setItem('garageName', garageName)
            setValue('garageName', garageName)
            setGarageName(garageName)

            // Organization
            let organization = location.borrowerData.organization_guid
            sessionStorage.setItem('organization', organization)

            // Branch
            let branch = location.borrowerData.branch_guid
            sessionStorage.setItem('branch', branch)

            // Trade License Number
            let tradeLicenseNumber = location.borrowerData.trade_licence_number
            sessionStorage.setItem('tradeLicenseNumber', tradeLicenseNumber? tradeLicenseNumber:'')
            setValue('tradeLicenseNumber', tradeLicenseNumber? tradeLicenseNumber:'')

            // Garage Address
            let garageAddress = location.borrowerData.address
            sessionStorage.setItem('garageAddress', garageAddress? garageAddress:'')
            setValue('garageAddress', garageAddress? garageAddress:'')

            // Garage district
            sessionStorage.setItem('garageDistrict', location.borrowerData.district)

            // Garage upazila
            sessionStorage.setItem('garageUpazila', location.borrowerData.upazila)

            // Garage thana
            sessionStorage.setItem('garageThana', location.borrowerData.thana)

            // Google Maps Link
            let googleMapLink = location.borrowerData.google_map_link
            sessionStorage.setItem('googleMapLink', googleMapLink? googleMapLink:'')
            setValue('googleMapLink', googleMapLink? googleMapLink:'')

            // Due Limit
            let dueLimit = location.borrowerData.total_due_limit
            sessionStorage.setItem('dueLimit', dueLimit)
            setValue('dueLimit', dueLimit)

            // Lease Payment Enabled
            let leaseEnabled = location.borrowerData.leasing_model_enabled
            sessionStorage.setItem('leaseEnabled', leaseEnabled)
            setLeaseModelEnabled(leaseEnabled? 'yes':'no')

            // Comment
            let comment = location.borrowerData.comment
            sessionStorage.setItem('comment', comment? comment:'')
            setValue('comment', comment? comment:'')
        }

        if (sessionStorage.getItem('edit') === 'true') {
            // Borrower Name
            let borrowerName = sessionStorage.getItem('borrowerName')
            setValue('borrowerName', borrowerName)

            // Borrower Contact Number
            let borrowerContact = sessionStorage.getItem('borrowerContact')
            setValue('contactNumber', borrowerContact)

            // Borrower NID
            let borrowerNid = sessionStorage.getItem('borrowerNid')
            setValue('nidNumber', borrowerNid)

            // Borrower home address
            let borrowerHomeAddress = sessionStorage.getItem('borrowerHomeAddress')
            setValue('homeAddress', borrowerHomeAddress)

            // Garage Name
            let garageName = sessionStorage.getItem('garageName')
            setValue('garageName', garageName)

            // Trade License Number
            let tradeLicenseNumber = sessionStorage.getItem('tradeLicenseNumber')
            setValue('tradeLicenseNumber', tradeLicenseNumber)

            // Garage Address
            let garageAddress = sessionStorage.getItem('garageAddress')
            setValue('garageAddress', garageAddress)

            // Google Maps Link
            let googleMapLink = sessionStorage.getItem('googleMapLink')
            setValue('googleMapLink', googleMapLink)

            // Due Limit
            let dueLimit = sessionStorage.getItem('dueLimit')
            setValue('dueLimit', dueLimit)

            // Lease Payment Enabled
            let leaseEnabled = sessionStorage.getItem('leaseEnabled')
            setLeaseModelEnabled(leaseEnabled === 'true'? 'yes':'no')

            // Comment
            let comment = sessionStorage.getItem('comment')
            setValue('comment', comment)
        } else {
            sessionStorage.clear()
            sessionStorage.setItem('edit', false)
        }

        return ()=> {
            props.RETURN_TO_INITIAL_STATE()
            props.RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
        }
    }, []);


    const registerBorrower = (data) => {
        let payload = {
            "name": data.garageName,
            "name_bangla": data.garageName,
            "trade_licence_number": data.tradeLicenseNumber,
            "address": data.garageAddress,
            "district": data.garageDistrict.label,
            "upazila": data.garageUpazila.label,
            "thana": data.garageThana?.label? data.garageThana.label:'',
            "garage_owner_name": data.borrowerName,
            "garage_phone_number": data.contactNumber,
            "garage_owner_nid_number": data.nidNumber,
            "garage_owner_address": data.homeAddress,
            "garage_owner_district": data.district.label,
            "garage_owner_upazila": data.upazila.label,
            "garage_owner_thana": data.thana?.label? data.thana.label:'',
            "google_map_link": data.googleMapLink? data.googleMapLink:'',
            "organization_guid": data.organization.value,
            "branch_guid": data.branch?.value,
            "total_due_limit": data.dueLimit? parseInt(data.dueLimit):0,
            "leasing_model_enabled": leaseModelEnabled === 'yes',
            "comment": data.comment
        }
        if (!edit) {
            payload['battery_wise_payment'] = garageDeactivationProcess === 'deactivate_only_due_batteries'
            REGISTER_BORROWER(payload)
        } else {
            EDIT_BORROWER(garageGuid, payload)
        }
    }

    const cancelBorrowerRegistration = () => {
        if (edit) {
            props.history.goBack()
        } else {
            props.history.push('/borrowers/list')
        }
    }

    useEffect(() => {
        if (props.upazilasForAll) {
            const upazilas = props.upazilasForAll;
            if (upazilas.length > -1) {
                let upazilaFromResponse = upazilas.map((upazila) => {
                    if (edit && sessionStorage.getItem('borrowerUpazila') && upazila.name === sessionStorage.getItem('borrowerUpazila')) {
                        setValue('upazila', {
                            ...upazila,
                            label: upazila.name,
                            value: upazila.id
                        })
                        if (upazila.is_city_corporation) {
                            setShowThanaSelectionForPersonalInfo(true)
                            COLLECT_THANAS_FOR_BORROWER(upazila.id)
                        }
                    }
                    if (edit && sessionStorage.getItem('garageUpazila') && upazila.name === sessionStorage.getItem('garageUpazila')) {
                        setValue('garageUpazila', {
                            ...upazila,
                            label: upazila.name,
                            value: upazila.id
                        })
                        if (upazila.is_city_corporation) {
                            setShowThanaSelectionForGarageInfo(true)
                            COLLECT_THANAS_FOR_GARAGE(upazila.id)
                        }
                    }
                    return {
                        ...upazila,
                        label: upazila.name,
                        value: upazila.id
                    }
                })
                setUpazilas(upazilaFromResponse)
                setUpazilasForGarageInfo(upazilaFromResponse)
            }
        }
    }, [props.upazilasForAll])

    useEffect(() => {
        if (props.upazilasForBorrower) {
            const upazilas = props.upazilasForBorrower;
            if (upazilas.length > -1) {
                let upazilaFromResponse = upazilas.map((upazila) => {
                    if (edit && sessionStorage.getItem('borrowerUpazila') && upazila.name === sessionStorage.getItem('borrowerUpazila')) {
                        setValue('upazila', {
                            ...upazila,
                            label: upazila.name,
                            value: upazila.id
                        })
                        if (upazila.is_city_corporation) {
                            setShowThanaSelectionForPersonalInfo(true)
                            COLLECT_THANAS_FOR_BORROWER(upazila.id)
                        }
                    }
                    return {
                        ...upazila,
                        label: upazila.name,
                        value: upazila.id
                    }
                })
                setUpazilas(upazilaFromResponse)
            }
        }
    }, [props.upazilasForBorrower])

    useEffect(() => {
        if (props.upazilasForGarage) {
            const upazilas = props.upazilasForGarage;
            if (upazilas.length > -1) {
                let upazilaFromResponse = upazilas.map((upazila) => {
                    if (edit && sessionStorage.getItem('garageUpazila') && upazila.name === sessionStorage.getItem('garageUpazila')) {
                        setValue('garageUpazila', {
                            ...upazila,
                            label: upazila.name,
                            value: upazila.id
                        })
                        if (upazila.is_city_corporation) {
                            setShowThanaSelectionForGarageInfo(true)
                            COLLECT_THANAS_FOR_GARAGE(upazila.id)
                        }
                    }
                    return {
                        ...upazila,
                        label: upazila.name,
                        value: upazila.id
                    }
                })
                setUpazilasForGarageInfo(upazilaFromResponse)
            }
        }
    }, [props.upazilasForGarage])

    useEffect(() => {
        if (props.districts) {
            const districts = props.districts;
            if (districts.length > -1) {
                setDistricts(districts.map((district) => {
                    if (edit && sessionStorage.getItem('borrowerDistrict') && district.name === sessionStorage.getItem('borrowerDistrict')) {
                        setValue('district', {
                            ...district,
                            label: district.name,
                            value: district.id
                        })
                        COLLECT_UPAZILAS_FOR_BORROWER(district.id)
                    }
                    if (edit && sessionStorage.getItem('garageDistrict') && district.name === sessionStorage.getItem('garageDistrict')) {
                        setValue('garageDistrict', {
                            ...district,
                            label: district.name,
                            value: district.id
                        })
                        COLLECT_UPAZILAS_FOR_GARAGE(district.id)
                    }
                    return {
                        ...district,
                        label: district.name,
                        value: district.id
                    }
                }))
            }
        }
    }, [props.districts])

    useEffect(() => {
        if (props.thanasForBorrower) {
            const thanasForSelection = props.thanasForBorrower;
            if (thanasForSelection.length > -1) {
                let thanaFromResponse = thanasForSelection.map((item) => {
                    if (edit && sessionStorage.getItem('borrowerThana') && item.name === sessionStorage.getItem('borrowerThana')) {
                        setValue('thana', {
                            ...item,
                            label: item.name,
                            value: item.id
                        })
                    }
                    return {
                        ...item,
                        label: item.name,
                        value: item.id
                    }
                })
                setThanas(thanaFromResponse)
            }
        }
    }, [props.thanasForBorrower])

    useEffect(() => {
        if (props.thanasForGarage) {
            const thanasForSelection = props.thanasForGarage;
            if (thanasForSelection.length > -1) {
                let thanaFromResponse = thanasForSelection.map((item) => {
                    if (edit && sessionStorage.getItem('garageThana') && item.name === sessionStorage.getItem('garageThana')) {
                        setValue('garageThana', {
                            ...item,
                            label: item.name,
                            value: item.id
                        })
                    }
                    return {
                        ...item,
                        label: item.name,
                        value: item.id
                    }
                })
                setThanasForGarageInfo(thanaFromResponse)
            }
        }
    }, [props.thanasForGarage])

    useEffect(() => {
        if (props.borrowerSaved && props.borrowerSaved === requestCycle.success) {
            toast.success("Borrower is registered successfully!")
            props.history.push({
                pathname: '/borrowers/list'
            })
        }
    }, [props.borrowerSaved])

    useEffect(() => {
        if (props.errorMessageForBorrowerRegistration) {
            showNotifications('error', props.errorMessageForBorrowerRegistration)
        }
    }, [props.errorMessageForBorrowerRegistration])

    useEffect(() => {
        if (props.borrowerEdited && props.borrowerEdited === requestCycle.success) {
            toast.success("Borrower is edited successfully!")
            props.history.goBack()
        } else if (props.borrowerEdited && props.borrowerEdited === requestCycle.failed) {
            toast.error("Borrower edit process has failed!")
        }
    }, [props.borrowerEdited])

    useEffect(() => {
        if (props.organisations) {
            const organisations = props.organisations
            if (organisations && organisations.length > -1) {
                setOrganizations(organisations.map((org) => {
                    if (edit && sessionStorage.getItem('organization') && org.pk === sessionStorage.getItem('organization')) {
                        setValue('organization', {
                            ...org,
                            label: org.name,
                            value: org.pk
                        })
                    }
                    return {
                        ...org,
                        label: org.name,
                        value: org.pk
                    }
                }))
            }
        } else {
            setOrganizations([])
        }
    }, [props.organisations])

    useEffect(() => {
        if (props.branches) {
            const branches = props.branches
            if (branches.length > -1) {
                setBranches(branches.map((branch) => {
                    if (edit && sessionStorage.getItem('branch') && branch.pk === sessionStorage.getItem('branch')) {
                        setValue('branch', {
                            ...branch,
                            label: branch.name,
                            value: branch.pk
                        })
                    }
                    return {
                        ...branch,
                        label: branch.name,
                        value: branch.pk
                    }
                }))
            }
        } else {
            setBranches([])
        }
    }, [props.branches])

    return (
        <div>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={'Borrower info'}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{!edit? 'Borrower Registration':'Borrower: ' + borrowerName + ' - ' + garageName}</b></h1>
                            </Toolbar.Title>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Form onSubmit={handleSubmit(registerBorrower)}>
                            {/* --- Begin: Personal Information --- */}
                            <div className={'row g-3'}>
                                <h6 className={'ml-4'}><strong>Personal Information</strong></h6>
                            </div>
                            <div className={'row g-3 mt-5'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Name':'নাম'}<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolInput
                                            type={"text"}
                                            name={"borrowerName"}
                                            placeholder={language === "EN"? "Type Borrower's Name...":"ঋণগ্রহীতার নাম লিখুন..."}
                                            autoComplete={"off"}
                                            ref={register({
                                                required: informationIsRequired,
                                                validate: {
                                                    // We are allowing all bengali characters including bengali numeric
                                                    // Unicode source - https://github.com/lifeparticle/Bengali-Alphabet
                                                    isValidName: value => (/^[a-zA-Z0-9-'. \u0980-\u09FF]+$/.test(value) || !value) || "Invalid Borrower Name!"
                                                }
                                            })}
                                        />
                                        {errors.borrowerName && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.borrowerName.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Contact Number':'যোগাযোগের নাম্বার'}<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolInput
                                            type={"text"}
                                            name={"contactNumber"}
                                            placeholder={language === "EN"? "Type Contact Number...":"যোগাযোগের নাম্বার লিখুন..."}
                                            autoComplete={"off"}
                                            ref={register({
                                                required: informationIsRequired,
                                                validate: {
                                                    lengthIsCorrect: value => (value.length === 11 || !value) || invalidNumberLength,
                                                    isValidPhone: (value) => (isValidPhoneNumber(value, 'BD') || !value) || "Invalid phone number!"
                                                }
                                            })}
                                        />
                                        {errors.contactNumber && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.contactNumber.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'NID':'এনআইডি'}<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolInput
                                            type={"text"}
                                            name={"nidNumber"}
                                            placeholder={language === "EN"? "Type NID Number...":"এনআইডি নাম্বার লিখুন..."}
                                            autoComplete={"off"}
                                            ref={register({
                                                required: informationIsRequired,
                                                validate: {
                                                    containOnlyNumbers: value => (/^[0-9]+$/.test(value) || !value) || "Invalid NID"
                                                }
                                            })}
                                        />
                                        {errors.nidNumber && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.nidNumber.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'NID upload (*.pdf, *jpeg, *png)':'এনআইডি আপলোড (*.pdf, *jpeg, *png)'}</Form.Label>
                                        </div>
                                        <SolInput
                                            type={"file"}
                                            name={"nidFile"}
                                            placeholder={language === "EN"? "Type NID Number...":"এনআইডি নাম্বার লিখুন..."}
                                            autoComplete={"off"}
                                            ref={register()}
                                            onChange={(e) => {
                                                if (e.target.files) {
                                                    let value = e.target.files[0]
                                                    if (!["application/pdf", "image/png", "image/jpeg"].includes(value.type)) {
                                                        setError('nidFile', {'message': invalidFileType})
                                                        setDisableSaveButton(true)
                                                    } else if (value.size/(1024 * 1024) > 10) {
                                                        setError('nidFile', {'message': invalidSize})
                                                        setDisableSaveButton(true)
                                                    } else {
                                                        clearErrors('nidFile')
                                                        setDisableSaveButton(false)
                                                    }
                                                }
                                            }}
                                        />
                                        {errors.nidFile && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.nidFile.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Home Address':'বাসার ঠিকানা'}<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolTextArea
                                            name={"homeAddress"}
                                            placeholder={language === "EN"? "Type Home Address...":"বাসার ঠিকানা লিখুন..."}
                                            autoComplete={"off"}
                                            rows={"3"}
                                            ref={register({
                                                required: informationIsRequired,
                                                validate: {
                                                    addressValidityCheck: value => (/^[a-zA-Z0-9\s,/'() -]+$/.test(value) || !value) || "Invalid address"
                                                }
                                            })}
                                        />
                                        {errors.homeAddress && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.homeAddress.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'District':'জেলা'}<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"district"}
                                            rules={{ required:informationIsRequired }}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={language === 'EN'? 'Select District':'জেলা বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.collectingDistricts}
                                                    isLoading={props.collectingDistricts}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={districts}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        if (action === "clear") {
                                                            setValue("district", "")

                                                            setValue("upazila", "")
                                                            COLLECT_UPAZILAS_FOR_BORROWER("all")
                                                        }
                                                        onChange(selected)
                                                        if (!edit) {
                                                            setValue("thana", "")
                                                            setShowThanaSelectionForPersonalInfo(false)
                                                        }
                                                        setThanas([])
                                                        if (selected) {
                                                            setValue("upazila", "")
                                                            COLLECT_UPAZILAS_FOR_BORROWER(selected.id)
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.district && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.district.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Sub District / Upazila / City Corporaton':'উপজেলা'}<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"upazila"}
                                            rules={{ required:informationIsRequired }}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={language === 'EN'? 'Select Sub District':'উপজেলা বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.collectingUpazilasForBorrower || props.collectingUpazilasForAll}
                                                    isLoading={props.collectingUpazilasForBorrower || props.collectingUpazilasForAll}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={upazilas}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        if (action === "clear") {
                                                            setValue("upazila", "")

                                                            setValue("thana", "")
                                                            setShowThanaSelectionForPersonalInfo(false)
                                                            setThanas([])
                                                        }
                                                        onChange(selected)
                                                        if (selected) {
                                                            setValue("thana", "")
                                                            if (selected.is_city_corporation) {
                                                                setShowThanaSelectionForPersonalInfo(true)
                                                                COLLECT_THANAS_FOR_BORROWER(selected.id)
                                                            } else {
                                                                setShowThanaSelectionForPersonalInfo(false)
                                                                setThanas([])
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.upazila && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.upazila.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            {showThanaSelectionForPersonalInfo? <>
                                <div className={'row g-3'}>
                                    <div className={'col-md-6'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Thana':'থানা'}</Form.Label>
                                            </div>
                                            <Controller
                                                control={control}
                                                name={"thana"}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        placeholder={language === 'EN'? 'Select Thana':'থানা বাছাই করুন'}
                                                        classNamePrefix="react-select-sol-style"
                                                        isDisabled={props.collectingThanasForBorrower}
                                                        isLoading={props.collectingThanasForBorrower}
                                                        maxMenuHeight={200}
                                                        value={value}
                                                        isClearable={true}
                                                        control={control}
                                                        inputRef={ref}
                                                        options={thanas}
                                                        isSearchable={true}
                                                        onChange={(selected, {action}) => {
                                                            if (action === "clear") {
                                                                setValue("thana", "")
                                                            }
                                                            onChange(selected)
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.thana && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.thana.message}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                            </>:null}
                            {/* --- End: Personal Information --- */}

                            {/* --- Begin: Garage/Dealer Information --- */}
                            <div className={'row g-3'}>
                                <h6 className={'ml-4'}><strong>Garage/Dealer Information</strong></h6>
                            </div>
                            <div className={'row g-3 mt-5'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Name':'নাম'}<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolInput
                                            type={"text"}
                                            name={"garageName"}
                                            placeholder={language === "EN"? "Type Garage's Name...":"গ্যারেজের নাম লিখুন..."}
                                            autoComplete={"off"}
                                            ref={register({
                                                required: informationIsRequired,
                                                validate: {
                                                    // TODO: There might be a better solution used at the "Contract Creation" -> "Associated Garage" field
                                                    // We are allowing all bengali characters - Noor Reza 7th November, 2023 3:03 PM
                                                    // Unicode source - https://github.com/lifeparticle/Bengali-Alphabet
                                                    isValidName: value => (/^[a-zA-Z0-9-'. \u0980-\u09FF]+$/.test(value) || !value) || "Invalid garage name!"
                                                }
                                            })}
                                        />
                                        {errors.garageName && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.garageName.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Parent Organization':'প্রতিষ্ঠান'}<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"organization"}
                                            rules={{ required:informationIsRequired }}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={language === 'EN'? 'Select Organization':'প্রতিষ্ঠান বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.orgListLoading}
                                                    isLoading={props.orgListLoading}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={organizations}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        if (action === 'clear') {
                                                            setBranches([])
                                                            setValue('branch', '')
                                                        }
                                                        onChange(selected)
                                                        if (selected) {
                                                            GET_BRANCHES_LIST({'organization_guid':selected.value})
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.organization && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.organization.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Branch</Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"branch"}
                                            rules={{ required:false }}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={'Select branch'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.collectingBranches}
                                                    isLoading={props.collectingBranches}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={branches}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        onChange(selected)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.branch && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.branch.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Trade License Number':'ট্রেড লাইসেন্স নাম্বার'}<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolInput
                                            type={"text"}
                                            name={"tradeLicenseNumber"}
                                            placeholder={language === "EN"? "Type Trade License Number...":"্রেড লাইসেন্সের নাম্বার লিখুন..."}
                                            autoComplete={"off"}
                                            ref={register({
                                                required: informationIsRequired,
                                                validate: {
                                                    containNumericsOnly: value => (/^[0-9]+$/.test(value) || !value) || "Invalid Trade License Number!"
                                                }
                                            })}
                                        />
                                        {errors.tradeLicenseNumber && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.tradeLicenseNumber.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Trade License Image upload (*.pdf, *jpeg, *png)':'ট্রেড লাইসেন্সের ছবি আপলোড (*.pdf, *jpeg, *png)'}</Form.Label>
                                        </div>
                                        <SolInput
                                            type={"file"}
                                            name={"tradeLicenseFile"}
                                            autoComplete={"off"}
                                            ref={register()}
                                            onChange={(e) => {
                                                if (e.target.files) {
                                                    let value = e.target.files[0]
                                                    if (!["application/pdf", "image/png", "image/jpeg"].includes(value.type)) {
                                                        setError('tradeLicenseFile', {'message': invalidFileType})
                                                        setDisableSaveButton(true)
                                                    } else if (value.size/(1024 * 1024) > 10) {
                                                        setError('tradeLicenseFile', {'message': invalidSize})
                                                        setDisableSaveButton(true)
                                                    } else {
                                                        clearErrors('tradeLicenseFile')
                                                        setDisableSaveButton(false)
                                                    }
                                                }
                                            }}
                                        />
                                        {errors.tradeLicenseFile && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.tradeLicenseFile.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Address':'ঠিকানা'}<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <SolTextArea
                                            name={"garageAddress"}
                                            placeholder={language === "EN"? "Type Address...":"ঠিকানা লিখুন..."}
                                            autoComplete={"off"}
                                            rows={"3"}
                                            ref={register({
                                                required: informationIsRequired,
                                                validate: {
                                                    addressValidityCheck: value => (/^[a-zA-Z0-9\s,/'() -]+$/.test(value) || !value) || "Invalid address"
                                                }
                                            })}
                                        />
                                        {errors.garageAddress && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.garageAddress.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'District':'জেলা'}<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"garageDistrict"}
                                            rules={{ required:informationIsRequired }}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={language === 'EN'? 'Select District':'জেলা বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.collectingDistricts}
                                                    isLoading={props.collectingDistricts}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={districts}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        if (action === "clear") {
                                                            setValue("garageDistrict", "")

                                                            setValue("garageUpazila", "")
                                                            COLLECT_UPAZILAS_FOR_GARAGE("all")
                                                        }
                                                        onChange(selected)
                                                        if (!edit) {
                                                            setValue("garageThana", "")
                                                            setShowThanaSelectionForGarageInfo(false)
                                                        }
                                                        if (selected) {
                                                            setValue("garageUpazila", "")
                                                            COLLECT_UPAZILAS_FOR_GARAGE(selected.id)
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.garageDistrict && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.garageDistrict.message}</div>}
                                    </Form.Group>
                                </div>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Sub District / Upazila / City Corporaton':'উপজেলা'}<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={"garageUpazila"}
                                            rules={{ required:informationIsRequired }}
                                            render={( { onChange, onBlur, value, name, ref },
                                                      { invalid, isTouched, isDirty }) => (
                                                <Select
                                                    placeholder={language === 'EN'? 'Select Sub District':'উপজেলা বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.collectingUpazilasForGarage || props.collectingUpazilasForAll}
                                                    isLoading={props.collectingUpazilasForGarage || props.collectingUpazilasForAll}
                                                    maxMenuHeight={200}
                                                    value={value}
                                                    isClearable={true}
                                                    control={control}
                                                    inputRef={ref}
                                                    options={upazilasForGarageInfo}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        if (action === "clear") {
                                                            setValue("garageUpazila", "")

                                                            setValue("garageThana", "")
                                                            setShowThanaSelectionForGarageInfo(false)
                                                            setThanas([])
                                                        }
                                                        onChange(selected)
                                                        if (selected) {
                                                            setValue("garageThana", "")
                                                            if (selected.is_city_corporation) {
                                                                setShowThanaSelectionForGarageInfo(true)
                                                                COLLECT_THANAS_FOR_GARAGE(selected.id)
                                                            } else {
                                                                setShowThanaSelectionForGarageInfo(false)
                                                                setThanas([])
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.garageUpazila && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.garageUpazila.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            {showThanaSelectionForGarageInfo? <>
                                <div className={'row g-3'}>
                                    <div className={'col-md-6'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Thana':'থানা'}</Form.Label>
                                            </div>
                                            <Controller
                                                control={control}
                                                name={"garageThana"}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        placeholder={language === 'EN'? 'Select Thana':'থানা বাছাই করুন'}
                                                        classNamePrefix="react-select-sol-style"
                                                        isDisabled={props.collectingThanasForGarage}
                                                        isLoading={props.collectingThanasForGarage}
                                                        maxMenuHeight={200}
                                                        value={value}
                                                        isClearable={true}
                                                        control={control}
                                                        inputRef={ref}
                                                        options={thanasForGarageInfo}
                                                        isSearchable={true}
                                                        onChange={(selected, {action}) => {
                                                            if (action === "clear") {
                                                                setValue("garageThana", "")
                                                            }
                                                            onChange(selected)
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.garageThana && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.garageThana.message}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                            </>:null}
                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Google Maps Link':'গুগল ম্যাপের লিংক'}</Form.Label>
                                        </div>
                                        <SolInput
                                            type={"text"}
                                            name={"googleMapLink"}
                                            placeholder={"https://www.google.com/maps/place/ME+SOLshare+Ltd./@23.8167333,90.4158296,19z.."}
                                            autoComplete={"off"}
                                            ref={register()}
                                        />
                                        {errors.googleMapLink && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.googleMapLink.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            {/* --- End: Garage/Dealer Information --- */}

                            {/* --- Begin: Settings --- */}
                            <div className={'row g-3'}>
                                <h6 className={'ml-4'}><strong>Settings</strong></h6>
                            </div>
                            <div className={'row g-3 mt-5'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Due Limit':'ঋণগ্রহণের সীমা'}</Form.Label>
                                        </div>
                                        <SolInput
                                            type={"number"}
                                            name={"dueLimit"}
                                            step={"1"}
                                            min={"0"}
                                            placeholder={language === "EN"? "Type Due Limit of the borrower...":"ঋণগ্রহীতার জন্য ঋণগ্রহণের সীমা নির্ধারণ করুন..."}
                                            autoComplete={"off"}
                                            ref={register({
                                                max: 1000000
                                            })}
                                            onWheel={(event) => event.target.blur()}
                                        />
                                        {errors.dueLimit && errors.dueLimit.type === "max" && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;Max due limit 10,00,000 exceeded!</div>}

                                        {errors.dueLimit &&  errors.dueLimit.type !== "max" && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.dueLimit.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            {!edit? <>
                                <div className={'row g-3'}>
                                    <div className={'col-md-6'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>Garage Deactivation<span className="required text-danger">*</span></Form.Label>
                                            </div>
                                            <Stack
                                                direction="column"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                spacing={1.5}
                                            >
                                                <div className="form-check form-check-inline">
                                                    <SolRadioInput
                                                        {...register("garage_deactivation_process")}
                                                        id="deactivate_whole_garage"
                                                        value={`deactivate_whole_garage`}
                                                        handleChange={(event) => setGarageDeactivationProcess(event.target.value)}
                                                        checked={garageDeactivationProcess === "deactivate_whole_garage"}
                                                        htmlFor="deactivate_whole_garage"
                                                        labelName="Garage-wise Payment (Deactivate whole garage if there is any due)"
                                                    />
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <SolRadioInput
                                                        {...register("garage_deactivation_process")}
                                                        id="deactivate_only_due_batteries"
                                                        value={`deactivate_only_due_batteries`}
                                                        handleChange={(event) => setGarageDeactivationProcess(event.target.value)}
                                                        checked={garageDeactivationProcess === "deactivate_only_due_batteries"}
                                                        htmlFor="deactivate_only_due_batteries"
                                                        labelName="Battery-wise Payment (If a specific battery invoice due is cleared, allow using that battery)"
                                                    />
                                                </div>
                                            </Stack>
                                        </Form.Group>
                                    </div>
                                </div>
                            </>:null}
                            <div className={'row g-3'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>Lease Model Enabled<span className="required text-danger">*</span></Form.Label>
                                        </div>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={1.5}
                                        >
                                            <div className="form-check form-check-inline">
                                                <SolRadioInput
                                                    {...register("lease_model_activation")}
                                                    id="yes"
                                                    value={"yes"}
                                                    handleChange={(event) => setLeaseModelEnabled(event.target.value)}
                                                    checked={leaseModelEnabled === "yes"}
                                                    htmlFor="yes"
                                                    labelName="Yes"
                                                />
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <SolRadioInput
                                                    {...register("lease_model_activation")}
                                                    id="no"
                                                    value={"no"}
                                                    handleChange={(event) => setLeaseModelEnabled(event.target.value)}
                                                    checked={leaseModelEnabled === "no"}
                                                    htmlFor="no"
                                                    labelName="No"
                                                />
                                            </div>
                                        </Stack>
                                    </Form.Group>
                                </div>
                            </div>
                            {/* --- End: Settings --- */}

                            {/* --- Begin: Additional Info --- */}
                            <div className={'row g-3'}>
                                <h6 className={'ml-4'}><strong>Additional Info</strong></h6>
                            </div>
                            <div className={'row g-3 mt-5'}>
                                <div className={'col-md-6'}>
                                    <Form.Group>
                                        <div>
                                            <Form.Label>{language === 'EN'? 'Comment':'কমেন্ট'}</Form.Label>
                                        </div>
                                        <SolTextArea
                                            name={"comment"}
                                            placeholder={language === "EN"? "Type comment, if any...":"কমেন্ট লিখুন..."}
                                            autoComplete={"off"}
                                            rows={"3"}
                                            ref={register()}
                                        />
                                        {errors.comment && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.comment.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>
                            {/* --- End: Additional Info --- */}

                            <div className={'row'}>
                                <div className={"col-md-12"}>
                                    <Button variant={"dark"} size={'md'}
                                            onClick={() => {
                                                cancelBorrowerRegistration()
                                            }} style={{ backgroundColor: '#8C8C9B', outline: '#8C8C9B', border: '#8C8C9B' }}>
                                        <i className='fa fa-times' aria-hidden='true'/>&nbsp;{language === "EN" ? "Cancel" : "বাতিল করুন"}
                                    </Button>
                                    <Button variant={"warning"} type={"submit"} size={"md"} className={"ml-3"}
                                            disabled={disableSaveButton || props.borrowerRegistrationInProgress || props.borrowerEditInProgress}>
                                        {props.borrowerRegistrationInProgress || props.borrowerEditInProgress? <><Spinner animation={'border'} size={'sm'} variant={'light'}/></> : null}
                                        <i className='fa fa-paper-plane' aria-hidden='true' />&nbsp;{language === "EN"? "Save" : "সংরক্ষণ করুন"}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

BorrowerRegistration.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        orgListLoading: state.commonReducer.orgListLoading,
        organisations: state.commonReducer.organisations,
        districts: state.borrowersReducer.districts,
        upazilasForAll: state.borrowersReducer.upazilas,
        upazilasForBorrower: state.borrowersReducer.upazilasForBorrower,
        upazilasForGarage: state.borrowersReducer.upazilasForGarage,
        thanasForBorrower: state.borrowersReducer.thanas,
        thanasForGarage: state.borrowersReducer.thanasForGarage,
        collectingThanasForBorrower: state.borrowersReducer.collectingThanas,
        collectingThanasForGarage: state.borrowersReducer.collectingThanasForGarage,
        collectingUpazilasForAll: state.borrowersReducer.collectingUpazilas,
        collectingUpazilasForBorrower: state.borrowersReducer.collectingUpazilasForBorrower,
        collectingUpazilasForGarage: state.borrowersReducer.collectingUpazilasForGarage,
        collectingDistricts: state.borrowersReducer.collectingDistricts,
        borrowerSaved: state.borrowersReducer.borrowerSaved,
        borrowerRegistrationInProgress: state.borrowersReducer.borrowerRegistrationInProgress,
        errorMessageForBorrowerRegistration: state.borrowersReducer.errorMessageForBorrowerRegistration,
        borrowerEdited: state.borrowersReducer.borrowerEdited,
        borrowerEditInProgress: state.borrowersReducer.borrowerEditInProgress,
        collectingGarages: state.commonReducer.collectingGarages,
        branches: state.commonReducer.branches
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(BorrowerRegistration));
