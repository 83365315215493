import React from "react";
import {components} from "react-select";
import {FormCheck} from "react-bootstrap";

export const Option = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            <FormCheck defaultChecked={props.isSelected} label={props.data.label} style={props.isSelected? {accentColor: '#F18D00'}:{}}/>
        </components.Option>
    )
}