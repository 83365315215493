import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "../rentLog/actionTypes";
import {manualTransactionActionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";

export const manualTransactionActions = {

    UPLOAD_MANUAL_TRANSACTIONS:(payload={}, params={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/payment/manual_payment',params),
            body: payload,
            method: 'POST',
            backend_slug: 'transaction_management',
            types: [
                manualTransactionActionTypes.UPLOAD_MANUAL_TRANSACTIONS_REQUEST,
                manualTransactionActionTypes.UPLOAD_MANUAL_TRANSACTIONS_SUCCESS,
                manualTransactionActionTypes.UPLOAD_MANUAL_TRANSACTIONS_FAILURE,
            ]
        }
    }),

    FETCH_MANUAL_PAYMENTS:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/payment/manual_payments/payments',payload),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                manualTransactionActionTypes.FETCH_MANUAL_PAYMENTS_REQUEST,
                manualTransactionActionTypes.FETCH_MANUAL_PAYMENTS_SUCCESS,
                manualTransactionActionTypes.FETCH_MANUAL_PAYMENTS_FAILURE,
            ]
        }
    }),

    FETCH_MANUAL_TRANSACTION_BATCH:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/payment/manual_payment',payload),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                manualTransactionActionTypes.FETCH_MANUAL_TRANSACTION_BATCH_REQUEST,
                manualTransactionActionTypes.FETCH_MANUAL_TRANSACTION_BATCH_SUCCESS,
                manualTransactionActionTypes.FETCH_MANUAL_TRANSACTION_BATCH_FAILURE,
            ]
        }
    }),

    FETCH_MANUAL_TRANSACTION_PAYMENTS:(pk,payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/payment/manual_payment/${pk}`,payload),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                manualTransactionActionTypes.FETCH_MANUAL_TRANSACTION_PAYMENTS_REQUEST,
                manualTransactionActionTypes.FETCH_MANUAL_TRANSACTION_PAYMENTS_SUCCESS,
                manualTransactionActionTypes.FETCH_MANUAL_TRANSACTION_PAYMENTS_FAILURE,
            ]
        }
    }),
    APPROVE_TRANSACTION:(pk,payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/payment/manual_payment/${pk}`,payload),
            method: 'PUT',
            backend_slug: 'transaction_management',
            types: [
                manualTransactionActionTypes.APPROVE_TRANSACTION_REQUEST,
                manualTransactionActionTypes.APPROVE_TRANSACTION_SUCCESS,
                manualTransactionActionTypes.APPROVE_TRANSACTION_FAILURE,
            ]
        }
    }),

    RETURN_TO_INITIAL: ()=>({
        type: manualTransactionActionTypes.RETURN_TO_INITIAL
    })

}
