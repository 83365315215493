import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for rent log*/
const initialState= {
    rentList: undefined,
    errorMessageForRentListCollection: undefined,
    rentsFromRDS: undefined,
    errorMessageForRentsCollectionFromRDS: undefined,
    errorMessageForRentListExport: undefined,
    rentListForExport: undefined,
    rentFromRDSForExport: undefined,
    errorMessageForRentsExportFromRDS: undefined,
    driverDetails: undefined,
    driverCheckout: undefined,
    successMessage: undefined,
    errorMessage: undefined,
    successMessageForCheckIn: undefined,
    errorMessageForCheckIn: undefined,
    driverCheckIn: undefined,
    checkInProcessInProgress: undefined,
    garages: undefined,
    evs: undefined,
    errorMessageForEvListCollection: undefined,
    inactiveBatteries: undefined,
    errorMessageForInactiveBatteryListCollection: undefined,
    activities: undefined,
    errorMessageForSmartBatteryActivityCollection: undefined
};

const initialStateForDriverDetails= {
    driverDetails: undefined
};

/*Reducer for Driver's redux store*/
export const rentLogReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.COLLECT_RENT_LIST_V1_REQUEST:
            return {
                ...state,
                listLoading: true,
                rentList: undefined,
                driverCheckout: undefined,
                driverVerified: undefined,
                deviceVerified: undefined,
                successMessage: undefined,
                errorMessage: undefined,
                errorMessageForRentListCollection: undefined,
                successMessageForCheckIn: undefined,
                errorMessageForCheckIn: undefined,
                driverCheckIn: undefined
            };
        case actionTypes.COLLECT_RENT_LIST_V1_SUCCESS:
            return {
                ...state,
                listLoading: false,
                rentList: action.payload
            };
        case actionTypes.COLLECT_RENT_LIST_V1_FAILURE:
            return {
                ...state,
                listLoading: false,
                errorMessageForRentListCollection: action.payload.error
            };

        case actionTypes.COLLECT_RENT_LIST_V3_REQUEST:
            return {
                ...state,
                collectingRentsFromRDS: true,
                rentsFromRDS: undefined,
                errorMessageForRentsCollectionFromRDS: undefined,
            };
        case actionTypes.COLLECT_RENT_LIST_V3_SUCCESS:
            return {
                ...state,
                collectingRentsFromRDS: false,
                rentsFromRDS: action.payload
            };
        case actionTypes.COLLECT_RENT_LIST_V3_FAILURE:
            return {
                ...state,
                collectingRentsFromRDS: false,
                errorMessageForRentsCollectionFromRDS: action.payload.error
            };

        case actionTypes.GET_RENT_LIST_TO_EXPORT_V1_REQUEST:
            return {
                ...state,
                iconLoading: true,
                rentListForExport: undefined,
                errorMessageForRentListExport: undefined
            };
        case actionTypes.GET_RENT_LIST_TO_EXPORT_V1_SUCCESS:
            return {
                ...state,
                iconLoading: false,
                rentListForExport: action.payload
            };
        case actionTypes.GET_RENT_LIST_TO_EXPORT_V1_FAILURE:
            return {
                ...state,
                iconLoading: false,
                errorMessageForRentListExport: action.payload.error
            };

        case actionTypes.GET_RENT_LIST_TO_EXPORT_V3_REQUEST:
            return {
                ...state,
                collectingRentsFromRDSForExport: true,
                rentFromRDSForExport: undefined,
                errorMessageForRentsExportFromRDS: undefined
            };
        case actionTypes.GET_RENT_LIST_TO_EXPORT_V3_SUCCESS:
            return {
                ...state,
                collectingRentsFromRDSForExport: false,
                rentFromRDSForExport: action.payload
            };
        case actionTypes.GET_RENT_LIST_TO_EXPORT_V3_FAILURE:
            return {
                ...state,
                collectingRentsFromRDSForExport: false,
                errorMessageForRentsExportFromRDS: action.payload.error
            };

        case actionTypes.COLLECT_TOTAL_PAYMENT_REQUEST:
            return {
                ...state,
                totalPaid: undefined,
                collectingPaymentInfo: true
            };
        case actionTypes.COLLECT_TOTAL_PAYMENT_SUCCESS:
            return {
                ...state,
                totalPaid: action.payload,
                collectingPaymentInfo: false
            };
        case actionTypes.COLLECT_TOTAL_PAYMENT_FAILURE:
            toast.error("Can't collect the paid amount! Please contact with an admin.")
            return {
                ...state,
                collectingPaymentInfo: false
            };

            //post Data
        case actionTypes.VERIFY_DRIVER_REQUEST:
            return {
                ...state,
                driverVerified: undefined,
                driverDetails: undefined,
                errorMessageForDriverVerification: undefined,
                driverIsGettingVerified: true
            };
        case actionTypes.VERIFY_DRIVER_SUCCESS:
            return {
                ...state,
                driverVerified: true,
                driverDetails: action.payload,
                errorMessageForDriverVerification: undefined,
                driverIsGettingVerified: false
            };
        case actionTypes.VERIFY_DRIVER_FAILURE:
            return {
                ...state,
                driverVerified: false,
                driverDetails: undefined,
                errorMessageForDriverVerification: action.payload.error,
                driverIsGettingVerified: false
            };

        case actionTypes.VERIFY_DEVICE_REQUEST:
            return {
                ...state,
                deviceVerified: undefined,
                deviceDetails: undefined,
                errorMessageForDeviceVerification: undefined,
                deviceIsGettingVerified: true
            };
        case actionTypes.VERIFY_DEVICE_SUCCESS:
            return {
                ...state,
                deviceVerified: true,
                deviceDetails: action.payload,
                errorMessageForDeviceVerification: undefined,
                deviceIsGettingVerified: false
            };
        case actionTypes.VERIFY_DEVICE_FAILURE:
            return {
                ...state,
                deviceVerified: false,
                deviceDetails: undefined,
                errorMessageForDeviceVerification: action.payload.error.msg,
                deviceIsGettingVerified: false
            };

        case actionTypes.CHECKOUT_DRIVER_REQUEST:
            return {
                ...state,
                driverCheckout: undefined,
                checkOutProcessInProgress: true
            };
        case actionTypes.CHECKOUT_DRIVER_SUCCESS:
            return {
                ...state,
                driverCheckout: requestCycle.success,
                successMessage: action.payload,
                checkOutProcessInProgress: false
            };
        case actionTypes.CHECKOUT_DRIVER_FAILURE:
            return {
                ...state,
                driverCheckout: requestCycle.failed,
                errorMessage: action.payload.error,
                checkOutProcessInProgress: false
            };

        case actionTypes.COLLECT_GARAGE_LIST_REQUEST:
            return {
                ...state,
                garages: undefined,
                garageCollectionInProgress: true
            };
        case actionTypes.COLLECT_GARAGE_LIST_SUCCESS:
            return {
                ...state,
                garages: action.payload,
                garageCollectionInProgress: false
            };
        case actionTypes.COLLECT_GARAGE_LIST_FAILURE:
            toast.error("Can't collect the garage list! Please contact with an admin.");
            return {
                ...state,
                garageCollectionInProgress: false
            };

        case actionTypes.COLLECT_DRIVER_DETAILS_REQUEST:
            return {
                ...state,
                driverDetails: undefined,
                driverDetailsCollectionInProgress: true
            };
        case actionTypes.COLLECT_DRIVER_DETAILS_SUCCESS:
            return {
                ...state,
                driverDetails: action.payload,
                driverDetailsCollectionInProgress: false
            };
        case actionTypes.COLLECT_DRIVER_DETAILS_FAILURE:
            toast.error("Can't collect details of the driver! Please contact with an admin.");
            return {
                ...state,
                driverDetailsCollectionInProgress: false
            };

        case actionTypes.COLLECT_EV_LIST_REQUEST:
            return {
                ...state,
                evs: undefined,
                errorMessageForEvListCollection: undefined,
                collectingEvs: true
            };
        case actionTypes.COLLECT_EV_LIST_SUCCESS:
            return {
                ...state,
                evs: action.payload,
                collectingEvs: false
            };
        case actionTypes.COLLECT_EV_LIST_FAILURE:
            return {
                ...state,
                collectingEvs: false,
                errorMessageForEvListCollection: action.payload.error
            };

        case actionTypes.COLLECT_INACTIVE_BATTERY_LIST_REQUEST:
            return {
                ...state,
                inactiveBatteries: undefined,
                errorMessageForInactiveBatteryListCollection: undefined,
                collectingInactiveBatteries: true
            };
        case actionTypes.COLLECT_INACTIVE_BATTERY_LIST_SUCCESS:
            return {
                ...state,
                inactiveBatteries: action.payload,
                collectingInactiveBatteries: false
            };
        case actionTypes.COLLECT_INACTIVE_BATTERY_LIST_FAILURE:
            return {
                ...state,
                collectingInactiveBatteries: false,
                errorMessageForInactiveBatteryListCollection: action.payload.error
            };

        case actionTypes.COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY_REQUEST:
            return {
                ...state,
                activities: undefined,
                errorMessageForSmartBatteryActivityCollection: undefined,
                collectingSmartBatteryActivityCollection: true
            };
        case actionTypes.COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY_SUCCESS:
            return {
                ...state,
                activities: action.payload,
                collectingSmartBatteryActivityCollection: false
            };
        case actionTypes.COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY_FAILURE:
            return {
                ...state,
                collectingSmartBatteryActivityCollection: false,
                errorMessageForSmartBatteryActivityCollection: action.payload.error
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        case actionTypes.RETURN_TO_INITIAL_STATE_FOR_DRIVER_DETAILS_MODAL:
            return {
                ...state,
                ...initialStateForDriverDetails
            }

        default:
            return state;
    }
};
