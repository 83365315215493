import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useForm, Controller} from "react-hook-form";
import { useLocation } from "react-router";
import {Button, Form, InputGroup, OverlayTrigger, Spinner} from "react-bootstrap";
import Select from "react-select";
import {SolInput, SolTextArea} from "../../../../components/SolStyledComponents/components";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {DatePicker} from '@material-ui/pickers/'
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {connect} from "react-redux";
import {upazillas} from "../../../../utils/countryData/3dListBangladesh";
import {toast} from "react-toastify";
import moment from "moment";
import {requestCycle} from "../../../../utils/utilityFunctions";
import * as bdData from "../../../../utils/countryData/3dListBangladesh";
import {devicesReducer} from "../../reducer";
import {Toolbar} from "../components";
import {showNotifications} from "../../../../utils/notification";
import {useSubheader} from "../../../../../_metronic/layout";

const useStylesForDatePicker = makeStyles({
    root: {
        marginTop: '0',
        marginBottom: '0',
        border: 'none',
        width: '100%',
        '& input': {
            color: 'black',
            padding: "0 10px 0 10px",
            height: "38px",
            outline: "none",
        },
        '& input:hover, input:focus, input:active': {
            border: "1px solid #F18D00",
            boxShadow: "0px 0px 0px 3.2px r gba(241, 141, 0, 0.1)",
            outline: "none",
        }
    },
});
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#ffffff',
        }
    },
});
const CreateSmartDongle = props => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue} = useForm();
    const [language, setLanguage] = useState('EN');
    const [firmwares, setFirmwares] = useState([]);
    const [hardwares, setHardwares] = useState([]);
    const subHeader = useSubheader();

    const location = useLocation();
    const [edit, setEdit] = useState(!!sessionStorage.getItem('edit'));
    const [serialNumber, setSerialNumber] = useState(sessionStorage.getItem('serialNumber')? sessionStorage.getItem('serialNumber'):"");
    const [assignmentStatus, setAssignmentStatus] = useState(sessionStorage.getItem('assignmentStatus')? sessionStorage.getItem('assignmentStatus'):"");

    const [errorMessageForSerialNumberStartingDigit, setErrorMessageForSerialNumberStartingDigit] = useState(false);
    const [errorMessageForSerialNumberFirstTwoDigit, setErrorMessageForSerialNumberFirstTwoDigit] = useState(false);

    const generalErrorMessage = language === "EN"? "Please provide above information!":"দয়া করে উপরের তথ্যটি দিন!"

    const specialCharsWithoutColon = /[`!@#$%^&*()_+\-=\[\]{};'"\\|,.<>\/?~]/

    const {
        GET_FIRMWARE_LIST,
        GET_HARDWARE_LIST,
        UPDATE_DONGLE,
        ADD_DONGLE,
    } = props;

    useEffect(()=>{
        setLanguage(props.language)
        if (location.purpose && location.purpose === 'create') {
            setEdit(false)
            sessionStorage.setItem('edit', false)
        }

        if (location.dongleData) {
            sessionStorage.setItem('edit', true)
            setEdit(true)

            let serialNumber = location.dongleData.serial_number
            sessionStorage.setItem('serialNumber', serialNumber)
            setSerialNumber(serialNumber)

            let macAddress = location.dongleData.mac_address
            sessionStorage.setItem('macAddress', macAddress? macAddress:"")
            setValue('macAddress', macAddress)

            let hardware = location.dongleData.hardware_guid
            sessionStorage.setItem('hardware', hardware)

            let firmware = location.dongleData.firmware_guid
            sessionStorage.setItem('firmware', firmware)

            let assignmentStatus = location.dongleData.assignment_status
            sessionStorage.setItem('assignmentStatus', assignmentStatus)
            setAssignmentStatus(assignmentStatus)
        }

        if (sessionStorage.getItem('edit') === 'true') {
            // Saving Mac address
            setValue('macAddress', sessionStorage.getItem('macAddress'))
        } else {
            sessionStorage.clear()
            sessionStorage.setItem('edit', false)
        }

        GET_FIRMWARE_LIST()
        GET_HARDWARE_LIST()
        subHeader.setActionButtons(null)

        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
        }

    },[])

    useEffect(()=>{
        if (sessionStorage.getItem('edit') === 'false') {
            setSerialNumber("")
            setValue('macAddress', "")
            setValue('firmware', "")
            setValue('hardware', "")
            clearSessionStorages()
        }
    },[edit])

    const clearSessionStorages = () => {
        sessionStorage.removeItem('edit')
        sessionStorage.removeItem('serialNumber')
        sessionStorage.removeItem('macAddress')
        sessionStorage.removeItem('hardware')
        sessionStorage.removeItem('firmware')
        sessionStorage.removeItem('assignmentStatus')
    }

    useEffect(()=>{
        if (props.dongleCreated === requestCycle.success){
            props.history.push('/dongles');
        }
    },[props.dongleCreated])

    useEffect(()=>{
        if (props.dongleUpdated === requestCycle.success){
            clearSessionStorages()
            props.history.goBack()
        }
    },[props.dongleUpdated])

    useEffect(()=>{
        const firmwares = props.firmwares;

        if (firmwares && firmwares.length >= 0) {
            setFirmwares(firmwares.map((firmware) => {
                if (edit && sessionStorage.getItem('firmware') && firmware.pk === sessionStorage.getItem('firmware'))
                {
                    let selectedFirmware = firmware
                    firmware['label'] = firmware.version
                    firmware['value'] = firmware.pk
                    setValue('firmware', selectedFirmware)
                }
                return {
                    ...firmware,
                    label: firmware.version,
                    value: firmware.pk,
                }
            }));
        }
    },[props.firmwares])

    useEffect(()=>{
        const hardwares = props.hardwareList;

        if (hardwares && hardwares.length >= 0) {
            setHardwares(hardwares.map((hardware) => {
                if (edit && sessionStorage.getItem('hardware') && hardware.pk === sessionStorage.getItem('hardware'))
                {
                    let selectedHardware = hardware
                    hardware['label'] = hardware.version
                    hardware['value'] = hardware.pk
                    setValue('hardware', selectedHardware)
                }
                return {
                    ...hardware,
                    label: hardware.version,
                    value: hardware.pk
                }
            }));
        }
    },[props.hardwareList])

    const submit=(data)=>{
        const payload = {
            "serial_number": serialNumber,
            "mac_address": data.macAddress,
            "hardware_version": data.hardware.label,
            "firmware_version": data.firmware.label,
            "firmware_guid": data.firmware.value,
            "hardware_guid": data.hardware.value
        }
        if (edit) {
            payload["assignment_status"] = assignmentStatus
            UPDATE_DONGLE(payload, serialNumber)
        } else {
            ADD_DONGLE(payload)
        }
    }

    useEffect(() => {
        if (props.successMessage !== undefined) {
            language === 'EN'? showNotifications('success', props.successMessage):toast.success("সফলভাবে স্মার্ট ডংগল নিবন্ধন সম্পন্ন হয়েছে!")
        } else if (props.errorMessage !== undefined) {
            showNotifications('error', props.errorMessage)
        }
    }, [props.successMessage, props.errorMessage])


    useEffect(() => {
        if (props.successMessageForUpdate !== undefined) {
            language === 'EN'? showNotifications('success', props.successMessageForUpdate):toast.success("সফলভাবে স্মার্ট ডংগল সম্পাদনা হয়েছে!")
        } else if (props.errorMessageForUpdate !== undefined) {
            showNotifications('error', props.errorMessageForUpdate)
        }
    }, [props.successMessageForUpdate, props.errorMessageForUpdate])


    return (
       <ContentWrapper showCardHeader={false} pageTitle={'Add device'} showBackButton={false} isLoading={false}>
               <div className={"row mb-6"}>
                   <div className={"col-md-12"}>
                       <Toolbar>
                           <Toolbar.Title>
                               <h1><b>{!edit? (language === 'EN'? 'Add Smart Dongle':'স্মার্ট ডংগল নিবন্ধন করুন'):(language === 'EN'? 'Edit Smart Dongle ' + serialNumber:'স্মার্ট ডংগল সম্পাদনা করুন ' + serialNumber)}</b></h1>
                           </Toolbar.Title>
                       </Toolbar>
                   </div>
               </div>
               <div className={"row"}>
                   <div className={"col-md-12"}>
                       <Form onSubmit={handleSubmit(submit)}>
                           {/* <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <Form.Label>{language === "EN"? "Serial number":"ক্রমিক নাম্বার"}<span className="required text-danger">*</span></Form.Label>
                                       <SolInput
                                           type={"text"}
                                           name={"serialNumber"}
                                           placeholder={language === "EN"? "Serial number of the smart dongle. Ex: 44001100":"স্মার্ট ডংগলের ক্রমিক নাম্বার। উদাহরণঃ 44001100"}
                                           autoComplete={"off"}
                                           ref={register({
                                               required: generalErrorMessage,
                                               pattern: {
                                                   value: /^(0|[1-9][0-9]*)$/i,
                                                   message: language === "EN"? "Please Enter Numbers Only!":"ক্রমিক নাম্বার হিসেবে শুধু সংখ্যা গ্রহণযোগ্য!"
                                               },
                                               minLength: {
                                                   value: 8,
                                                   message: language === "EN"? "Length of the serial number must be 8!":"ক্রমিক নাম্বারের দৈর্ঘ্য অবশ্যই ৮ হতে হবে!",
                                               },
                                               maxLength: {
                                                   value: 8,
                                                   message: language === "EN"? "Length of the serial number must be 8!":"ক্রমিক নাম্বারের দৈর্ঘ্য অবশ্যই ৮ হতে হবে!",
                                               },
                                           })}
                                           onChange={(e) => {
                                               let value = e.target.value
                                               if (value) {
                                                   setSerialNumber(value)
                                                   if (value.startsWith('4')) {
                                                       setErrorMessageForSerialNumberStartingDigit(false)
                                                       if (value.length === 2 && value.slice(0, 2) !== '44') {
                                                           setErrorMessageForSerialNumberFirstTwoDigit(true)
                                                       } else if (value.length === 2 && value.slice(0, 2) === '44') {
                                                           setErrorMessageForSerialNumberFirstTwoDigit(false)
                                                       }
                                                   } else {
                                                       setErrorMessageForSerialNumberStartingDigit(true)
                                                   }
                                               } else {
                                                   setErrorMessageForSerialNumberStartingDigit(false)
                                                   setErrorMessageForSerialNumberFirstTwoDigit(false)
                                                   setSerialNumber("")
                                               }
                                           }}
                                       />

                                       {errorMessageForSerialNumberStartingDigit && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Serial number should starts with 4!':'ক্রমিক নাম্বার অবশ্যই 4 দিয়ে শুরু করতে হবে!'}</div>}
                                       {errorMessageForSerialNumberFirstTwoDigit && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Serial number should starts with 44!':'ক্রমিক নাম্বার অবশ্যই 44 দিয়ে শুরু করতে হবে!'}</div>}
                                       {!errorMessageForSerialNumberStartingDigit && !errorMessageForSerialNumberFirstTwoDigit && errors.serialNumber && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.serialNumber.message}</div>}
                                   </Form.Group>
                               </div>
                           </div> */}

                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <Form.Label>{language === "EN"? "Mac address":"ম্যাক এড্রেস"}<span className="required text-danger">*</span></Form.Label>
                                       <SolInput
                                           type={"text"}
                                           name={"macAddress"}
                                           placeholder={language === "EN"? "Mac address of the dongle. Ex: F7:83:A7:E5:4A:83":"স্মার্ট ডংগলের ম্যাক এড্রেস।যেমনঃ F7:83:A7:E5:4A:83"}
                                           autoComplete={"off"}
                                           ref={register({
                                               required: generalErrorMessage,
                                               minLength: {
                                                   value: 17,
                                                   message: language === "EN"? "Length of the mac address must be 17!":"ম্যাক এড্রেসের দৈর্ঘ্য অবশ্যই ১৭ হতে হবে!",
                                               },
                                               validate: {
                                                   isValidMacAddress: value => (/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(value) || !value) || "Invalid Mac address!"
                                               }
                                           })}
                                       />
                                       {errors.macAddress && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.macAddress.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>

                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>{language === "EN"? "Hardware":"হার্ডওয়্যার"}<span className="required text-danger">*</span></Form.Label>
                                       </div>

                                       <Controller
                                           control={control}
                                           name={"hardware"}
                                           rules= {{ required: generalErrorMessage }}
                                           render={( { onChange, onBlur, value, name, ref },
                                                     { invalid, isTouched, isDirty }) => (
                                               <Select
                                                   name={`hardware`}
                                                   placeholder={language === 'EN'? 'Select a hardware':'হার্ডওয়্যার বাছাই করুন'}
                                                   classNamePrefix="react-select-sol-style"
                                                   isDisabled={props.hardwareListLoading}
                                                   isLoading={props.hardwareListLoading}
                                                   maxMenuHeight={200}
                                                   value={value}
                                                   isClearable={true}
                                                   control={control}
                                                   inputRef={ref}
                                                   options={hardwares}
                                                   isSearchable={true}
                                                   onChange={(selected, {action}) => {
                                                       onChange(selected)
                                                   }}
                                               />
                                           )}
                                       />

                                       {errors.hardware && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.hardware.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>

                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>{language === "EN"? "Firmware":"ফার্মওয়্যার"}<span className="required text-danger">*</span></Form.Label>
                                       </div>

                                       <Controller
                                           control={control}
                                           name={"firmware"}
                                           rules= {{ required: generalErrorMessage }}
                                           render={( { onChange, onBlur, value, name, ref },
                                                     { invalid, isTouched, isDirty }) => (
                                               <Select
                                                   name={"firmware"}
                                                   placeholder={language === "EN"? "Select a firmware":"ফার্মওয়্যার বাছাই করুন"}
                                                   classNamePrefix="react-select-sol-style"
                                                   isDisabled={props.firmwareListLoading}
                                                   isLoading={props.firmwareListLoading}
                                                   maxMenuHeight={200}
                                                   value={value}
                                                   isClearable={true}
                                                   control={control}
                                                   inputRef={ref}
                                                   options={firmwares}
                                                   isSearchable={true}
                                                   onChange={(selected, {action}) => {
                                                       onChange(selected)
                                                   }}
                                               />
                                           )}
                                       />
                                       {errors.firmware && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.firmware.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>


                           <div className={'row'}>
                               <div className={"col-md-12"}>
                                   <Button variant="dark" size={'md'}
                                           onClick={()=> {
                                               clearSessionStorages()
                                               props.history.goBack()
                                           }} style={{backgroundColor: '#8C8C9B', outline: '#8C8C9B', border: '#8C8C9B'}}>
                                       <i className='fa fa-times' aria-hidden='true'/>&nbsp;{language === "EN"? "Cancel":"বাতিল করুন"}
                                   </Button>
                                   <Button variant="warning" type="submit"
                                           className={"ml-3"}
                                           disabled={!!(props.dongleInfoSubmitting || errorMessageForSerialNumberStartingDigit ||
                                               errorMessageForSerialNumberFirstTwoDigit || props.firmwareListLoading ||
                                               props.hardwareListLoading)}>
                                       {props.dongleInfoSubmitting===true? <><Spinner animation={'border'} size={'sm'} variant={'light'}/> </>:null}
                                       <i className='fa fa-paper-plane' aria-hidden='true'/>&nbsp;{language === "EN"? "Submit":"সংরক্ষণ করুন"}
                                   </Button>
                               </div>
                           </div>
                       </Form>
                   </div>
               </div>
           <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
       </ContentWrapper>
    );
};

CreateSmartDongle.propTypes = {};


const mapStateToProps=(state)=>{

    return {
        isLoading: state.smartDongleReducer.isLoading,
        language: state.auth.language,
        firmwareListLoading: state.smartDongleReducer.firmwareListLoading,
        hardwareListLoading: state.smartDongleReducer.hardwareListLoading,
        hardwareList: state.smartDongleReducer.hardwares,
        firmwares: state.smartDongleReducer.firmwares,
        dongleCreated: state.smartDongleReducer.dongleCreated,
        dongleUpdated: state.smartDongleReducer.dongleUpdated,
        dongleInfoSubmitting: state.smartDongleReducer.dongleInfoSubmitting,
        successMessage: state.smartDongleReducer.successMessage,
        errorMessage: state.smartDongleReducer.errorMessage,
        successMessageForUpdate: state.smartDongleReducer.successMessageForUpdate,
        errorMessageForUpdate: state.smartDongleReducer.errorMessageForUpdate
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions}) (CreateSmartDongle);
