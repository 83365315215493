import React, {useEffect, useState, useRef} from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import {Filter, Toolbar} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {Button, Form, Spinner, OverlayTrigger, ListGroup} from "react-bootstrap";
import Image from 'react-bootstrap/Image'
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer} from '../../utils'
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import moment from "moment";
import {toast} from "react-toastify";
import {DayPickerRangeController} from "react-dates";
import {exportInExcel} from "../../../../utils/excelExport";
import {convertToAnotherLang} from "../../../../utils/convertLanguageOfNumbersWithCharacter";
import {toBengaliNumber} from "bengali-number";
import {isNullorUndefined} from "../../../../utils/utilityFunctions";
import {parseParams} from "../../../../utils/utilityFunctions";
import {Controller, useForm} from "react-hook-form";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import { SolTypography } from '../../../../components';
import Stack from '@mui/material/Stack';
import Avatar from "@mui/material/Avatar";
import Popover from '@mui/material/Popover';
import Typography from "@mui/material/Typography";
import {useMediaQuery} from "react-responsive";
import {notificationsReducer} from "../../reducer";
import {showNotifications} from "../../../../utils/notification";
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded';
import "./customStylesForNotifications.css";
import parse from "html-react-parser";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {ReactSmartScroller} from "react-smart-scroller";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const NotificationsForDesktop = ({...props}) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const [showNotifications, setShowNotifications] = useState(false);
    const [targetForNotificationBar, setTargetForNotificationBar] = useState(null);
    const scrollerHeight = 370; // First section height: 50 and than four section height: 80 * 4
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const subHeader = useSubheader();

    const [language, setLanguage] = useState(null);

    const {
        COLLECT_TOTAL_PAYMENT
    } = props;

    const handleNotificationIconClick = (event) => {
        setShowNotifications(true)
        setTargetForNotificationBar(event.target)
    }

    const notificationBar = () => {
        return <>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={showNotifications}
                anchorEl={targetForNotificationBar}
                onClose={(event) => {
                    setShowNotifications(false)
                    setTargetForNotificationBar(null)
                }}
                disableScrollLock={true}
            >
                <div className={'row'} style={{color: "#FFFFFF", backgroundColor: "#F18D00", width: '500px', height: '50px', display: 'flex', alignItems: 'center'}}>
                    <div className={'col-sm-12 ml-10'}>
                        <h5>Notifications</h5>
                    </div>
                </div>
                <ReactSmartScroller
                    vertical
                    draggable={true}
                    style={{height: scrollerHeight + 'px'}}
                    thumb={<div style={{width: 10, height: 45, backgroundColor: '#F18D00'}}/>}
                >
                    <div className={'row'} key={1} style={{backgroundColor: "#F4F4F6", borderBottom: "1px #DCDCE5 solid"}}>
                        <div className={'col-sm-12'}>
                            <div className={'ml-8 mt-3'}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <div className={"custom-icon-holder"} style={{backgroundColor: '#FDEDEE'}}>
                                        <Avatar src={require("../../../../utils/asset/Transactions.svg")} sx={{width: 35, height: 35}}/>
                                    </div>
                                    <div className={'container'}>
                                        <div className={'d-flex justify-content-between'} style={{color: '#8C8C9B'}}>
                                            <span><h6>INVOICE DUE</h6></span>
                                            <span>Aug 27,2023 9:00 AM</span>
                                        </div>
                                        <div className={'d-flex justify-content-between'}>
                                            <span><strong>10 invoices due this week</strong></span>
                                        </div>
                                        <div className={'d-flex justify-content-start'}>
                                            <Button size={'sm'} type={"button"} variant={'light'}>See Invoices Due <ChevronRightIcon size={'sm'}/></Button>
                                        </div>
                                    </div>
                                </Stack>
                            </div>
                        </div>
                    </div>
                    <div className={'row'} key={2} style={{backgroundColor: "#F4F4F6", borderBottom: "1px #DCDCE5 solid"}}>
                        <div className={'col-sm-12'}>
                            <div className={'ml-8 mt-3'}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <div className={"custom-icon-holder"} style={{backgroundColor: '#ECFDF8'}}>
                                        <Avatar src={require("../../../../utils/asset/Received_Transactions.svg")} sx={{width: 35, height: 35}}/>
                                    </div>
                                    <div className={'container'}>
                                        <div className={'d-flex justify-content-between'} style={{color: '#8C8C9B'}}>
                                            <span><h6>REVENUE RECEIVED</h6></span>
                                            <span>Aug 27,2023 9:00 AM</span>
                                        </div>
                                        <div className={'d-flex justify-content-between'}>
                                            <span><strong>Tk 3,500 revenue received last week</strong></span>
                                        </div>
                                        <div className={'d-flex justify-content-start'}>
                                            <Button size={'sm'} type={"button"} variant={'light'}>See Collection <ChevronRightIcon size={'sm'}/></Button>
                                        </div>
                                    </div>
                                </Stack>
                            </div>
                        </div>
                    </div>
                    <div className={'row'} key={3}>
                        <div className={'col-sm-12'}>
                            <div className={'ml-8 mt-3'}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <div className={"custom-icon-holder"} style={{backgroundColor: '#FDEDEE'}}>
                                        <Avatar src={require("../../../../utils/asset/Transactions.svg")} sx={{width: 35, height: 35}}/>
                                    </div>
                                    <div className={'container'}>
                                        <div className={'d-flex justify-content-between'} style={{color: '#8C8C9B'}}>
                                            <span><h6>OVERDUE</h6></span>
                                            <span>Aug 27,2023 9:00 AM</span>
                                        </div>
                                        <div className={'d-flex justify-content-between'}>
                                            <span><strong>You have 5 overdue invoices last week</strong></span>
                                        </div>
                                        <div className={'d-flex justify-content-start'}>
                                            <Button size={'sm'} type={"button"} variant={'light'}>See Overdue Invoices <ChevronRightIcon size={'sm'}/></Button>
                                        </div>
                                    </div>
                                </Stack>
                            </div>
                        </div>
                    </div>
                    <div className={'row'} key={4}>
                        <div className={'col-sm-12'}>
                            <div className={'ml-8 mt-3'}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <div className={"custom-icon-holder"} style={{backgroundColor: '#F0F8FA'}}>
                                        <Avatar src={require("../../../../utils/asset/Cloud download.svg")} sx={{width: 35, height: 35}}/>
                                    </div>
                                    <div className={'container'}>
                                        <div className={'d-flex justify-content-between'} style={{color: '#8C8C9B'}}>
                                            <span><h6>NEW FIRMWARE</h6></span>
                                            <span>Aug 27,2023 9:00 AM</span>
                                        </div>
                                        <div className={'d-flex justify-content-between'}>
                                            <span><strong>v2.3 firmware is available for 40 SOLdongles</strong></span>
                                        </div>
                                        <div className={'d-flex justify-content-start'}>
                                            <Button size={'sm'} type={"button"} variant={'light'}>See Eligible SOLdongles <ChevronRightIcon size={'sm'}/></Button>
                                        </div>
                                    </div>
                                </Stack>
                            </div>
                        </div>
                    </div>
                    <div className={'row'} key={5}>
                        <div className={'col-sm-12'}>
                            <div className={'ml-8 mt-3'}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <div className={"custom-icon-holder"} style={{backgroundColor: '#F0F8FA'}}>
                                        <Avatar src={require("../../../../utils/asset/Phone.svg")} sx={{width: 35, height: 35}}/>
                                    </div>
                                    <div className={'container'}>
                                        <div className={'d-flex justify-content-between'} style={{color: '#8C8C9B'}}>
                                            <span><h6>MOBILE APP UPDATE</h6></span>
                                            <span>Aug 27,2023 9:00 AM</span>
                                        </div>
                                        <div className={'d-flex justify-content-between'}>
                                            <span><strong>CholoGarage v4.2 is released</strong></span>
                                        </div>
                                        <div className={'d-flex justify-content-start'}>
                                            <Button size={'sm'} type={"button"} variant={'light'}>See Eligible Garage Owners <ChevronRightIcon size={'sm'}/></Button>
                                        </div>
                                    </div>
                                </Stack>
                            </div>
                        </div>
                    </div>
                    <div className={'row'} key={6}>
                        <div className={'col-sm-12'}>
                            <div className={'ml-8 mt-3'}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <div className={"custom-icon-holder"} style={{backgroundColor: '#F0F8FA'}}>
                                        <Avatar src={require("../../../../utils/asset/Map.svg")} sx={{width: 35, height: 35}}/>
                                    </div>
                                    <div className={'container'}>
                                        <div className={'d-flex justify-content-between'} style={{color: '#8C8C9B'}}>
                                            <span><h6>LOCATION TRACKING</h6></span>
                                            <span>Aug 27,2023 9:00 AM</span>
                                        </div>
                                        <div className={'d-flex justify-content-between'}>
                                            <span><strong>Location tracking is available for 3 vehicles</strong></span>
                                        </div>
                                        <div className={'d-flex justify-content-start'}>
                                            <Button size={'sm'} type={"button"} variant={'light'}>Track Location <ChevronRightIcon size={'sm'}/></Button>
                                        </div>
                                    </div>
                                </Stack>
                            </div>
                        </div>
                    </div>
                </ReactSmartScroller>
            </Popover>
        </>
    }

    return (
        <>
            {/* TODO: [Desktop] Unread notification icon style - {width: "100%", height: "60%", background: "#F0F8FA", borderRadius: "15px"} */}
            {/* TODO: [Desktop] Read notification icon style - {background: "#F0F8FA"} */}
            <div style={{width: "100%", height: "60%", background: "#F0F8FA", borderRadius: "15px"}}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-end"
                    spacing={0}
                >
                    <Avatar src={require("../../../../utils/asset/Unread-Indiciator.svg")} sx={{ width: 10, height: 10 }}/>
                    <Avatar src={require("../../../../utils/asset/Bell.svg")} sx={{ width: 24, height: 24 }} onClick={handleNotificationIconClick}/>
                </Stack>
                {notificationBar()}
            </div>
        </>
    );
};

NotificationsForDesktop.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        totalPaid: state.notificationsReducer.totalPaid,
        garagesForValidation: state.commonReducer.garages
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(NotificationsForDesktop));
