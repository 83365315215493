import styled from "styled-components";
import React from "react";
import Select from 'react-select';
import { Button } from "react-bootstrap";

var input_font_size = ".96em"
var button_font_size = ".96em"
var input_height = "30px"
var input_padding_around_text = "6px 0px 6px 8px"
var button_padding_around_text = ".4em .8em"

export const FilterInput = styled.input`
    padding: ${input_padding_around_text};
    height: ${input_height} !important;
    width: 100%;
    font-size: ${input_font_size};
    /* Global/09. White */
    background-color: #FFFFFF;
    /* Components/Forms/Input border */
    border: 1px solid #BCBCCD;
    box-sizing: border-box;
    border-radius: 4px;
    
    
    
    :hover, :focus, :active {
      border: 1px solid #F18D00;
      box-shadow: 0px 0px 0px 3.2px rgba(241, 141, 0, 0.1);
      outline: none;
    }  
`

const StyledSelect = styled(Select)`
    box-sizing: border-box;
    border: 1px solid #bcbccd;
    border-radius: 4px;


    .Select__control {
        height: auto;
        padding: 0px 0px 0px 6px;
        min-height: 28px;
        width: 100%;
        font-size: ${input_font_size};
        /* Global/09. White */
        background-color: #ffffff;
        /* Components/Forms/Input border */
        box-sizing: border-box;
        border: 0px solid #bcbccd;
        border-radius: 4px;
        overflow: hidden;
    }

    .Select__value-container {
        padding: 0;
        overflow: hidden;
    }

    .Select__indicators {
        padding: 0 35px 0 0;
        width: 2rem;
        height: 28px;
    }


    .Select__control:hover {
        border: 1px solid #f18d00;
        box-shadow: 0px 0px 0px 3.2px rgba(241, 141, 0, 0.1);
        outline: none;
    }

    .Select__control--is-focused {
        border: 1px solid #f18d00;
        box-shadow: 0px 0px 0px 3.2px rgba(241, 141, 0, 0.1);
        outline: none;
    } 
`
export const FilterReactSelect = (props) => (
    <StyledSelect
        classNamePrefix="Select"
        {...props}
    />
);


export const FilterButton = styled(Button)`
    padding: ${button_padding_around_text};
    font-size: ${button_font_size}
`

