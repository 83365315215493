import React, {useEffect, useState, useRef} from 'react';
import {connect} from "react-redux";
import {actions} from "../../../../actions";
import {actions as commonActions} from "../../../../../commonReduxActions/actions";
import {makeStyles} from "@material-ui/styles";
import {withRoles} from "../../../../../../router/SecuredRoute";
import {useMediaQuery} from "react-responsive";
import EastIcon from '@mui/icons-material/East';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableContainer from '@mui/material/TableContainer';
import moment from "moment";
import {LoadingSpinner, SolTypography} from "../../../../../../components";
import {searchResultReducer} from "../../../../reducer";
import {showNotifications} from "../../../../../../utils/notification";
import {Button, ListGroup} from "react-bootstrap";
import {GenericModal} from "../../../../../../components/genericModal/genericModal";
import Image from "react-bootstrap/Image";
import Avatar from "@mui/material/Avatar";


const LastRents = ({rentData, evGuid, ...props}) => {
    const [garages, setGarages] = useState(undefined);
    const [language, setLanguage] = useState(null);
    // Driver details modal
    const [showDriverDetailsModal, setShowDriverDetailsModal] = useState(false);
    const [driverName, setDriverName] = useState('');
    const [driverNIDImageUrl, setDriverNIDImageUrl] = useState('');
    const [driverUserID, setDriverUserID] = useState('');
    const [driverLastEvID, setDriverLastEvID] = useState('');
    const [driverNID, setDriverNID] = useState('N/A');
    const [driverMobileNumber, setDriverMobileNumber] = useState('N/A');
    const [driverAddress, setDriverAddress] = useState('N/A');
    const [driverGarageName, setDriverGarageName] = useState('');
    const [driverGarageAddress, setDriverGarageAddress] = useState('');
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const {
        COLLECT_DRIVER_DETAILS
    } = props;

    useEffect(() => {
        setLanguage(props.language)
    }, []);

    const showDriverDetails = (data) => {
        setDriverName(data.driver_details.driver_name)
        setDriverMobileNumber(data.driver_details.driver_phone)
        setDriverLastEvID(data.ev_details.ev_sl)
        setDriverGarageName(data.garageName)
        let garageAddress = ''
        if (data.garageDistrict && data.garageUpazila) {
            garageAddress = data.garageUpazila + ', ' + data.garageDistrict
        } else if (!data.garageDistrict && data.garageUpazila) {
            garageAddress = data.garageUpazila
        } else if (data.garageDistrict && !data.garageUpazila) {
            garageAddress = data.garageDistrict
        }
        setDriverGarageAddress(garageAddress)
        setShowDriverDetailsModal(true)
        COLLECT_DRIVER_DETAILS(data['driver_details']['driver_phone'])
    }

    const hideDriverDetailsModal = () => {
        setShowDriverDetailsModal(false)
    }

    // Source of the letter Avatar (with following two functions): https://mui.com/material-ui/react-avatar/ - Noor Reza, 4th October, 6:48 PM
    // Though We have updated it by adding a checking for the one word name
    const stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const stringAvatar = (name) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1]? name.split(' ')[1][0]:''}`,
        }
    }

    const renderDriverDetailsModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideDriverDetailsModal}
                centered={false}
                modal={showDriverDetailsModal}
                title={<>{driverName}</>}>
                <div className={'row'}>
                    {props.collectingDriverDetails?
                        <>
                            <div className={'col-md-4'}></div>
                            <div className={'col-md-4'}>
                                <LoadingSpinner
                                    loadingSubText={language === 'EN'? 'Generating driver details..': 'চালকের বিস্তারিত তথ্য প্রস্তুত হচ্ছে..'}
                                    language={language}/>
                            </div>
                            <div className={'col-md-4'}></div>
                        </>:<>
                            <div className={'col-md-6'}>
                                {driverNIDImageUrl? <>
                                    <Avatar size={"lg"}
                                            variant="square"
                                            src={driverNIDImageUrl}
                                            alt={driverName}
                                            ref={el => {
                                                if (el) {
                                                    el.style.setProperty('width', '100%', 'important')
                                                    el.style.setProperty('height', '100%', 'important')
                                                }
                                            }}/>
                                </>:<>
                                    <Avatar size={"lg"}
                                            variant="square"
                                            {...stringAvatar(driverName)}
                                            ref={el => {
                                                if (el) {
                                                    el.style.setProperty('width', '100%', 'important')
                                                    el.style.setProperty('height', '100%', 'important')
                                                    el.style.setProperty('font-size', '70px', 'important')
                                                }
                                            }}/>
                                </>}
                            </div>
                            <div className={'col-md-6'}>
                                <ListGroup variant={"flush"}>
                                    {driverUserID? <>
                                        <ListGroup.Item className={"border-bottom-0"}>
                                            <small>User ID</small><br/>
                                            <h4><strong>{driverUserID}</strong></h4>
                                        </ListGroup.Item>
                                    </>:''}
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small>Last EV</small><br/>
                                        <h4><strong>{driverLastEvID? '#'+ driverLastEvID + ' - Easy Bike':'N/A'}</strong></h4>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small>NID</small><br/>
                                        <h4><strong>{driverNID}</strong></h4>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small>Mobile</small><br/>
                                        <h4><strong>{driverMobileNumber}</strong></h4>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small>Address</small><br/>
                                        <h4><strong>{driverAddress}</strong></h4>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small>Garage</small><br/>
                                        <h4><strong>{driverGarageName && driverGarageAddress? driverGarageName + ', ' + driverGarageAddress:driverGarageName? driverGarageName:'N/A'}</strong></h4>
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </>}
                </div>
            </GenericModal>
        </>
    }


    useEffect(() => {
        if (props.driverDetails) {
            const driverDetails = props.driverDetails
            setDriverNIDImageUrl(driverDetails.identity_doc_picture)
            setDriverNID(driverDetails.identity_number)
            let driverAddressText = ''
            let village = driverDetails.village
            let upazila = driverDetails.upazila
            if (village && upazila) {
                driverAddressText = village + ', ' + upazila
            } else if (!village && upazila) {
                driverAddressText = upazila
            } else if (village && !upazila) {
                driverAddressText = village
            }
            if (driverAddressText) {
                setDriverAddress(driverAddressText)
            }
        }
    }, [props.driverDetails]);

    useEffect(() => {
        if (props.errorMessageForDriverDetails) {
            showNotifications('error', props.errorMessageForDriverDetails)
        }
    }, [props.errorMessageForDriverDetails])

    // Table header styling
    const tableHeaderLeftAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        paddingLeft: "0",
        textAlign: "left",
        boxSizing: "borderBox"
    }

    const tableHeaderRightAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        paddingLRight: "0px",
        textAlign: "right",
        boxSizing: "borderBox"
    }

    // Table cell related styling
    const tableLeftAlignedContentStyling = {
        color: "inherit",
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        paddingLeft: "0",
        textAlign: "left"
    }

    const tableRightAlignedContentStyling = {
        color: "inherit",
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        textAlign: "right"
    }

    return (
        <>
            <div className={isTabletOrMobile? 'row mt-4':'row ml-1 mt-10'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}><h5><strong>Last 5 Rents</strong></h5></div>
            {rentData && rentData.length > 0? <>
                <div className={isTabletOrMobile? 'row':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                    <a href={"rents/logs?page=1&ev_guid=" + evGuid} target={"_blank"}><h5><strong>See All <EastIcon style={{'color': '#F18D00'}} size={'lg'}/></strong></h5></a>
                </div>
                <div className={isTabletOrMobile? 'row':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>
                    <TableContainer>
                        <Table bordered={true} aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Rent ID</TableCell>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Rented By</TableCell>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Rented On</TableCell>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Rent Ended On</TableCell>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Payment</TableCell>
                                    <TableCell style={tableHeaderLeftAlignedColumnStyling}>Rent Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rentData.map((rent) => {
                                    let rentStartTimeMomentObj = moment(rent.start_time)
                                    let rentExpireTimeMomentObj = moment(rent.expire_time)
                                    let rentDurationInHours = rentExpireTimeMomentObj.diff(rentStartTimeMomentObj, 'hours')
                                    let rentEndedByUser = rent.last_user_name? rent.last_user_name:''
                                    let presentTimeMomentOj = ''
                                    let rentBeingActiveFor = ''
                                    let rentBeingActiveForHoursMinutesBreakDown = ''
                                    let rentTimeRemainingText = ''
                                    if (rent.status !== 'done') {
                                        presentTimeMomentOj = moment()
                                        rentBeingActiveFor = rentExpireTimeMomentObj.diff(presentTimeMomentOj, 'minutes')
                                        rentBeingActiveForHoursMinutesBreakDown = Math.floor(rentBeingActiveFor / 60) + ':' + rentBeingActiveFor % 60
                                        rentTimeRemainingText = rentBeingActiveForHoursMinutesBreakDown.split(':')[1] !== '00'?
                                            rentBeingActiveForHoursMinutesBreakDown.split(':')[0] + 'h ' +
                                            rentBeingActiveForHoursMinutesBreakDown.split(':')[1] + 'm remaining':
                                            rentBeingActiveForHoursMinutesBreakDown.split(':')[0] + 'h remaining'
                                    }

                                    return <>
                                        <TableRow>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                {rent.rent_id}
                                            </TableCell>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                <div className="d-flex p-3">
                                                    {rent.driver_details? <>
                                                        <p className="mb-0"><a onClick={() => showDriverDetails(rent)}>{rent.driver_details.driver_name}</a></p>
                                                    </>:''}
                                                    <p className="mb-0" style={{whiteSpace: "nowrap"}}>{rent.garageName}</p>
                                                    <p className="mb-0" style={{whiteSpace: "nowrap"}}><small><strong>{rent.garageUpazila && rent.garageDistrict? rent.garageUpazila + ", " + rent.garageDistrict:rent.garageDistrict}</strong></small></p>
                                                </div>
                                            </TableCell>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                <span style={{whiteSpace: 'nowrap'}}>{rentStartTimeMomentObj.format("MMM D, YYYY hh:mma")} for {rentDurationInHours}h</span><br/>
                                                <small style={{whiteSpace: 'nowrap'}}><strong>{rent.start_battery_charge}%&nbsp;SOC,&nbsp;{rent.start_battery_voltage}V</strong></small><br/>
                                                {rent.rent_version? <>
                                                    <small style={{whiteSpace: 'nowrap'}}><strong>CholoGarage v{rent.rent_version}</strong></small>
                                                </>:null}
                                            </TableCell>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                {rent.status === 'done'? <>
                                                    <span style={{whiteSpace: 'nowrap'}}>{moment(rent.end_time).format("MMM D, YYYY hh:mma")} {rentEndedByUser? 'by ' + rentEndedByUser:''}</span><br/>
                                                    {rentEndedByUser !== 'System'? <>
                                                        <small style={{whiteSpace: 'nowrap'}}><strong>{rent.end_battery_charge}%&nbsp;SOC,&nbsp;{rent.end_battery_voltage}V</strong></small>
                                                    </>:''}
                                                </>:<>
                                                    <span style={{whiteSpace: 'nowrap'}}>{rentTimeRemainingText}</span>
                                                </>}
                                            </TableCell>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                {rent.rent_payment_status === 'due'? <>
                                                    <Button variant={'outline-danger'} size={'sm'}>Due</Button>
                                                </>:<>
                                                    <Button variant={'outline-success'} size={'sm'}>Paid</Button>
                                                </>}
                                            </TableCell>
                                            <TableCell style={tableLeftAlignedContentStyling}>
                                                {rent.status === 'done'? <>
                                                    <Button variant={'outline-success'} size={'sm'}>Completed</Button>
                                                </>:<>
                                                    <Button variant={'outline-danger'} size={'sm'}>Active</Button>
                                                </>}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {renderDriverDetailsModal()}
            </>:<>
                <div className={isTabletOrMobile? 'row':'row ml-1'} style={isTabletOrMobile? {marginLeft: '0.1px'}:{}}>No rents available</div>
            </>}
        </>
    );
};

LastRents.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        collectingBorrowers: state.commonReducer.collectingBorrowers,
        driverDetails: state.searchResultReducer.driverDetails,
        collectingDriverDetails: state.searchResultReducer.collectingDriverDetails,
        errorMessageForDriverDetails: state.searchResultReducer.errorMessageForDriverDetails
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(LastRents));
