import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {useLocation} from 'react-router';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import {actions} from "../../actions";
import {showNotifications} from "../../../../utils/notification";


const AutoCompleteComponent = ({fieldId, placeholder, ...props }) => {
    const {register, setValue} = useForm();
    const [language, setLanguage] = useState("EN");
    const [valueForAutoCompleteField, setValueForAutoCompleteField] = useState(JSON.stringify({serial_number: ""}));
    const [makeApiCall, setMakeApiCall] = useState(true);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [evList, setEvList] = useState([]);
    const history = useHistory()
    const location = useLocation()

    const {
        COLLECT_EV_LIST
    } = props;

    useEffect(() => {
        if (props.evs && props.evs.length > -1) {
            const evs = props.evs
            setEvList(evs)
        } else {
            setEvList([])
        }
    }, [props.evs]);

    useEffect(() => {
        if (props.errorMessageForEvListCollection) {
            showNotifications('error', props.errorMessageForEvListCollection)
        }
    }, [props.errorMessageForEvListCollection]);

    useEffect(()=> {
        if (location.pathname !== '/search') {
            setValueForAutoCompleteField(JSON.stringify({serial_number: ""}))
            sessionStorage.removeItem("searchParam")
            sessionStorage.removeItem("searchValue")
        }
    }, [location])

    return (
        <>
            <Autocomplete
                id={fieldId}
                open={showSuggestions}
                value={valueForAutoCompleteField}
                onInputChange={(event, value) => {
                    setShowSuggestions(!!value)
                    if (event && event.type === 'change' && makeApiCall) {
                        COLLECT_EV_LIST({status: 'active'})
                        setMakeApiCall(false)
                    } else if (event && event.type === 'blur') {
                        setMakeApiCall(true)
                        setShowSuggestions(false)
                    }
                }}
                onChange={(event, value, reason) => {
                    if (reason === 'selectOption' && value) {
                        // We are using sessionStorage to deal with the search page reload case - Noor Reza, September 12, 2:17 pm
                        // If search feature has been used in the news-letter or email then we will add url parameter
                        sessionStorage.setItem("searchParam", JSON.parse(value).serial_number)
                        sessionStorage.setItem("searchValue", value)
                        history.push({
                            pathname: '/search',
                            searchParam: JSON.parse(value).serial_number,
                            searchValue: value
                        })
                        setMakeApiCall(true)
                        setShowSuggestions(false)
                    }
                }}
                options={evList.map((option) => JSON.stringify(option))}
                filterOptions={(options, state) =>
                    options.filter((option) => state.inputValue && JSON.parse(option).serial_number.includes(state.inputValue))
                }
                getOptionLabel={(option) => JSON.parse(option).serial_number}
                noOptionsText={props.collectingEvs? "Collecting EV list....":"Doesn't matched with any EV"}
                clearOnBlur={true}
                autoHighlight={true}
                disableClearable
                handleHomeEndKeys={true}
                renderInput={(params) =>
                    <div ref={params.InputProps.ref}>
                        <input type={"text"} placeholder={placeholder} {...params.inputProps}/>
                    </div>}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={JSON.parse(option).pk}>
                        <div className={'container p-2'}>
                            <div className={'row justify-content-between'}>
                                <div>EV {JSON.parse(option).serial_number}</div>
                                <div>
                                    {JSON.parse(option).type_of_vehicle === 'mishuk'? <>
                                        <Avatar alt={"Mishuk"}
                                                src={require("../../../../utils/asset/ic-mishuk.svg")}
                                                sx={{ width: 24, height: 24 }}/>
                                    </>:<>
                                        {JSON.parse(option).type_of_vehicle === 'easy_bike'? <>
                                            <Avatar alt={"EasyBike"}
                                                    src={require("../../../../utils/asset/ic-easybike.svg")}
                                                    sx={{ width: 24, height: 24 }}/>
                                        </>:<>
                                            {JSON.parse(option).type_of_vehicle === 'rickshaw'? <>
                                                <Avatar alt={"Rickshaw"}
                                                        src={require("../../../../utils/asset/ic-mishuk.svg")}
                                                        sx={{ width: 24, height: 24 }}/>
                                            </>:''}
                                        </>}
                                    </>}
                                </div>
                            </div>
                            <div className={'row justify-content-start'}>
                                <small><strong>Smart Battery #{JSON.parse(option).device_serial_number}</strong></small>
                            </div>
                            <div className={'row justify-content-start'}>
                                <small><strong>{JSON.parse(option).garage_name}</strong></small>
                            </div>
                        </div>
                    </li>
                )}
            />
        </>
    );
};

AutoCompleteComponent.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        evs: state.searchResultReducer.evs,
        collectingEvs: state.searchResultReducer.collectingEvs,
        errorMessageForEvListCollection: state.searchResultReducer.errorMessageForEvListCollection
    }
}

export default connect(mapStateToProps, {...actions})(AutoCompleteComponent);