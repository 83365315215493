import {manualTransactionActionTypes} from "./actionTypes";
import {toast} from "react-toastify";
import { requestCycle } from "../../utils/utilityFunctions";

const initialState={
    pendingTransactionListLoading: false,
    pendingTransactions: undefined,
    transactionsApprovalInProgress: false,
    listLoading: false,
    payments: undefined
}

export const manualTransactionsReducer =(state = initialState, currentAction)=>{
    // TODO: Need to add translations for all messages

    switch (currentAction.type){
        case manualTransactionActionTypes.FETCH_MANUAL_PAYMENTS_REQUEST:
            return {
                ...state,
                listLoading: true,
                payments: undefined
            }
        case manualTransactionActionTypes.FETCH_MANUAL_PAYMENTS_SUCCESS:
            return {
                ...state,
                listLoading: false,
                payments: currentAction.payload,
            }
        case manualTransactionActionTypes.FETCH_MANUAL_PAYMENTS_FAILURE:
            return {
                ...state,
                listLoading: false,
                payments: undefined
            }
        case manualTransactionActionTypes.FETCH_MANUAL_TRANSACTION_BATCH_REQUEST:
            return {
                ...state,
                pendingTransactionListLoading: true,
                pendingTransactions: undefined
            }
        case manualTransactionActionTypes.FETCH_MANUAL_TRANSACTION_BATCH_SUCCESS:
            return {
                ...state,
                pendingTransactionListLoading: false,
                pendingTransactions: currentAction.payload,
            }
        case manualTransactionActionTypes.FETCH_MANUAL_TRANSACTION_BATCH_FAILURE:
            return {
                ...state,
                pendingTransactionListLoading: false,
                pendingTransactions: undefined
            }
        case manualTransactionActionTypes.FETCH_MANUAL_TRANSACTION_PAYMENTS_REQUEST:
            return {
                ...state,
                pendingTransactionByBatchListLoading: true,
                payments: undefined
            }
        case manualTransactionActionTypes.FETCH_MANUAL_TRANSACTION_PAYMENTS_SUCCESS:
            return {
                ...state,
                pendingTransactionByBatchListLoading: false,
                payments: currentAction.payload,
            }
        case manualTransactionActionTypes.FETCH_MANUAL_TRANSACTION_PAYMENTS_FAILURE:
            return {
                ...state,
                pendingTransactionByBatchListLoading: false,
                payments: undefined
            }

        case manualTransactionActionTypes.UPLOAD_MANUAL_TRANSACTIONS_REQUEST:
            return {
                ...state,
                buttonLoading: true,
                duplicates: undefined,
                transactionUploaded: undefined,
            }
        case manualTransactionActionTypes.UPLOAD_MANUAL_TRANSACTIONS_SUCCESS:
            toast.success("Transactions Uploaded")
            return {
                ...state,
                buttonLoading: false,
                duplicates: undefined,
                transactionUploaded: requestCycle.success,
            }
        case manualTransactionActionTypes.UPLOAD_MANUAL_TRANSACTIONS_FAILURE:
            // toast.success("Transactions could not be uploaded")
            if (currentAction?.error && typeof currentAction.payload?.error === 'object' && currentAction.payload?.error?.duplicates){

                toast.error(currentAction.payload?.error?.message)

                return {
                    ...state,
                    buttonLoading: false,
                    duplicates: currentAction.payload?.error.duplicates

                }
            }
            else if (currentAction?.error && currentAction.payload?.error?.message){
                toast.error(currentAction.payload?.error?.message)
            }else {
                toast.error("Transactions could not be uploaded")
            }
            return {
                ...state,
                buttonLoading: false,
                duplicates: undefined

            }
        case manualTransactionActionTypes.APPROVE_TRANSACTION_REQUEST:
            return {
                ...state,
                transactionsAreApproved: undefined,
                transactionsApprovalInProgress: true
            }
        case manualTransactionActionTypes.APPROVE_TRANSACTION_SUCCESS:
            toast.success("Transactions approved")
            return {
                ...state,
                transactionsAreApproved: true,
                transactionsApprovalInProgress: false
            }
        case manualTransactionActionTypes.APPROVE_TRANSACTION_FAILURE:
            toast.success("Transactions could not be approved")
            return {
                ...state,
                transactionsAreApproved: false,
                transactionsApprovalInProgress: false
            }

        case manualTransactionActionTypes.RETURN_TO_INITIAL:
            return initialState
        default:
            return initialState

    }
}
