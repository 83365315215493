import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_DEVICE_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/devices`, filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_DEVICE_LIST_REQUEST,
                actionTypes.GET_DEVICE_LIST_SUCCESS,
                actionTypes.GET_DEVICE_LIST_FAILURE
            ]
        }
    }),

    GET_DEVICE_LIST_FOR_EXPORT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/devices`, filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_DEVICE_LIST_FOR_EXPORT_REQUEST,
                actionTypes.GET_DEVICE_LIST_FOR_EXPORT_SUCCESS,
                actionTypes.GET_DEVICE_LIST_FOR_EXPORT_FAILURE
            ]
        }
    }),
    
    GET_DEVICE_LIST_FROM_GARAGE: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/devices`, filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.GET_DEVICE_LIST_REQUEST,
                actionTypes.GET_DEVICE_LIST_SUCCESS,
                actionTypes.GET_DEVICE_LIST_FAILURE
            ]
        }
    }),

    GET_FIRMWARE_LIST: ()=>({
        [CALL_API]: {
            endpoint: '/devices',
            method: 'GET',
            types: [
                actionTypes.GET_FIRMWARE_LIST_REQUEST,
                actionTypes.GET_FIRMWARE_LIST_SUCCESS,
                actionTypes.GET_FIRMWARE_LIST_FAILURE
            ]
        }
    }),

    ADD_DEVICE: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/devices',
            backend_slug: 'device_management',
            method: 'POST',
            body : payload,
            types: [
                actionTypes.ADD_DEVICE_REQUEST,
                actionTypes.ADD_DEVICE_SUCCESS,
                actionTypes.ADD_DEVICE_FAILURE,
            ]
        }
    }),

    CHANGE_RENT_PER_DAY_FOR_DEVICE: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/devices/change_rent_per_day',
            backend_slug: 'device_management',
            method: 'POST',
            body : payload,
            types: [
                actionTypes.CHANGE_RENT_PER_DAY_FOR_DEVICE_REQUEST,
                actionTypes.CHANGE_RENT_PER_DAY_FOR_DEVICE_SUCCESS,
                actionTypes.CHANGE_RENT_PER_DAY_FOR_DEVICE_FAILURE,
            ]
        }
    }),

    GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE: (payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/devices/rent_per_day_change_log_history', payload),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE_REQUEST,
                actionTypes.GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE_SUCCESS,
                actionTypes.GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE_FAILURE,
            ]
        }
    }),
    CLEAN_UP_RENT_CHANGE_MODAL: ()=>({type: actionTypes.CLEAN_UP_RENT_CHANGE_MODAL}),

    CHANGE_RENTABLE_STATUS: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/devices/change_rentable_status',
            backend_slug: 'device_management',
            method: 'PUT',
            body : payload,
            types: [
                actionTypes.CHANGE_RENTABLE_STATUS_REQUEST,
                actionTypes.CHANGE_RENTABLE_STATUS_SUCCESS,
                actionTypes.CHANGE_RENTABLE_STATUS_FAILURE,
            ]
        }
    }),

    GET_LAST_RENT_BY_DEVICE: (device_id, payload={})=>({
        [CALL_API]: {
            endpoint: `/rent/last_data/${device_id}`,
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.GET_LAST_RENT_BY_DEVICE_REQUEST,
                actionTypes.GET_LAST_RENT_BY_DEVICE_SUCCESS,
                actionTypes.GET_LAST_RENT_BY_DEVICE_FAILURE,
            ]
        }
    }),
    
    CLEAN_UP_LAST_RENT_MODAL: () => ({ type: actionTypes.CLEAN_UP_LAST_RENT_MODAL }),

    GET_GARAGES: (payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/garages`, payload),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.GET_GARAGES_REQUEST,
                actionTypes.GET_GARAGES_SUCCESS,
                actionTypes.GET_GARAGES_FAILURE
            ]
        }
    }),

    ADD_MULTIPLE_DEVICES: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/devices/batch_create',
            backend_slug: 'device_management',
            method: 'POST',
            body : payload,
            types: [
                actionTypes.ADD_MULTIPLE_DEVICES_REQUEST,
                actionTypes.ADD_MULTIPLE_DEVICES_SUCCESS,
                actionTypes.ADD_MULTIPLE_DEVICES_FAILURE,
            ]
        }
    }),

    COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY: (deviceSerial, filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/rent/active_days_summary/` + deviceSerial, filter),
            backend_slug: 'garage_management',
            method: 'GET',
            types: [
                actionTypes.COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY_REQUEST,
                actionTypes.COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY_SUCCESS,
                actionTypes.COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY_FAILURE
            ]
        }
    }),

    ASSIGN_GARAGE: (payload={})=>({
        [CALL_API]: {
            endpoint: `/devices/assign_to_garage`,
            method: 'POST',
            backend_slug: 'garage_management',
            body : payload,
            types: [
                actionTypes.ASSIGN_GARAGE_REQUEST,
                actionTypes.ASSIGN_GARAGE_SUCCESS,
                actionTypes.ASSIGN_GARAGE_FAILURE
            ]
        }
    }),

    ASSIGN_BATCH_GARAGE: (payload = {}) => ({
        [CALL_API]: {
            endpoint: `/devices/batch_assign_to_garage`,
            method: 'POST',
            backend_slug: 'garage_management',
            body: payload,
            types: [
                actionTypes.ASSIGN_GARAGE_REQUEST,
                actionTypes.ASSIGN_GARAGE_SUCCESS,
                actionTypes.ASSIGN_GARAGE_FAILURE
            ]
        }
    }),

    COLLECT_GARAGE_LIST_FOR_ASSIGNMENT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/garages', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_GARAGE_LIST_FOR_ASSIGNMENT_REQUEST,
                actionTypes.COLLECT_GARAGE_LIST_FOR_ASSIGNMENT_SUCCESS,
                actionTypes.COLLECT_GARAGE_LIST_FOR_ASSIGNMENT_FAILURE
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE_FOR_SMART_BATTERY_CREATION: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE_FOR_SMART_BATTERY_CREATION}),
    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
