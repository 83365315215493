import {Redirect, Switch} from "react-router-dom";
import React from "react";
import Borrowers from "./views/borrowers/borrowers";
import BorrowerRegistration from "./views/borrowers/borrowerRegistration";
import SecuredRoute from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const BorrowersRouter =(props)=> {

    return (
        <Switch>
            <Redirect
                exact={"true"}
                from={"/borrowers"}
                to={"/borrowers/list"}
            />

            {/* Routes to borrowers List*/}
            <SecuredRoute
                path={"/borrowers/list"}
                component={Borrowers}
                service={getServiceName('garageService')}
                feature={getFeatureName('garageFeature')}
                action={getActionName('listAction')}
            />

            {/* Routes to borrowers Registration Page */}
            <SecuredRoute
                path={"/borrowers/create"}
                component={BorrowerRegistration}
                service={getServiceName('garageService')}
                feature={getFeatureName('garageFeature')}
                action={getActionName('createActionName')}
            />

            {/* Routes to borrowers Edit Page */}
            <SecuredRoute
                path={"/borrowers/edit"}
                component={BorrowerRegistration}
                service={getServiceName('garageService')}
                feature={getFeatureName('garageFeature')}
                action={getActionName('updateActionName')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
