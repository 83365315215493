import {Redirect, Switch} from "react-router-dom";
import React from "react";
import SummaryReports from "./views/summaryReports";
import SecuredRoute from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const SummaryReportRouter =(props)=> {

    return (
        <Switch>
            <Redirect
                exact={"true"}
                from={"/summary-report"}
                to={"/summary-report/list"}
            />

            {/* Routes to summary report */}
            <SecuredRoute
                path={"/summary-report/list"}
                component={SummaryReports}
                service={getServiceName('garageService')}
                feature={getFeatureName('batteryRentFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
