import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    listLoading: false,
    smartBatteriesForExport: undefined,
    deviceCreated: undefined,
    deviceInfoSubmitting: false,
    successMessage: undefined,
    errorMessage: undefined,
    garageListLoading: false,
    multipleDevicesCreated: undefined,
    multipleDevicesInfoSubmitting: false,
    successMessageForMultipleDeviceCreation: undefined,
    errorMessageForMultipleDeviceCreation: undefined,
    summary: undefined,
    rentChanged: undefined,
    garageListForAssignment: undefined,
    rentableChanged: undefined
};

const initialStateForSmartBatteryCreation= {
    deviceCreated: undefined,
    successMessage: undefined,
    errorMessage: undefined
};

/*Reducer for Device's redux store*/
export const devicesReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_DEVICE_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                rentChanged: undefined
            };
        case actionTypes.GET_DEVICE_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                deviceList: action.payload
            };
        case actionTypes.GET_DEVICE_LIST_FAILURE:
            toast.error("Can't load the smart battery list, because: " + action.payload.error.msg);
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.GET_DEVICE_LIST_FOR_EXPORT_REQUEST:
            return {
                ...state,
                collectingSmartBatteriesForExport: true,
                smartBatteriesForExport: undefined
            };
        case actionTypes.GET_DEVICE_LIST_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                collectingSmartBatteriesForExport: false,
                smartBatteriesForExport: action.payload
            };
        case actionTypes.GET_DEVICE_LIST_FOR_EXPORT_FAILURE:
            toast.error("Can't collect smart battery data for export, because: " + action.payload.error.msg);
            return {
                ...state,
                collectingSmartBatteriesForExport: false
            };

        case actionTypes.GET_FIRMWARE_LIST_REQUEST:
            return {
                ...state,
                listLoading: true
            };
        case actionTypes.GET_FIRMWARE_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                firmwares: action.payload
            };
        case actionTypes.GET_FIRMWARE_LIST_FAILURE:
            toast.error("Can't collect the firmware list, because: " + action.payload.error.msg);
            return {
                ...state,
                listLoading: false
            };
            //post Data
        case actionTypes.ADD_DEVICE_REQUEST:
            return {
                ...state,
                deviceCreated: undefined,
                deviceInfoSubmitting: true
            };
        case actionTypes.ADD_DEVICE_SUCCESS:
            return {
                ...state,
                deviceCreated: requestCycle.success,
                successMessage: action.payload,
                deviceInfoSubmitting: false
            };
        case actionTypes.ADD_DEVICE_FAILURE:
            return {
                ...state,
                deviceCreated: requestCycle.failed,
                errorMessage: action.payload.error,
                deviceInfoSubmitting: false
            };

        case actionTypes.GET_GARAGES_REQUEST:
            return {
                ...state,
                garages: undefined,
                garageListLoading: true
            };
        case actionTypes.GET_GARAGES_SUCCESS:
            return {
                ...state,
                garages: action.payload,
                garageListLoading: false
            };
        case actionTypes.GET_GARAGES_FAILURE:
            toast.error("Can't load the garage list, because: " + action.payload.error.msg);
            return {
                ...state,
                garageListLoading: false
            };

        case actionTypes.ADD_MULTIPLE_DEVICES_REQUEST:
            return {
                ...state,
                multipleDevicesCreated: undefined,
                multipleDevicesInfoSubmitting: true
            };
        case actionTypes.ADD_MULTIPLE_DEVICES_SUCCESS:
            return {
                ...state,
                multipleDevicesCreated: requestCycle.success,
                successMessageForMultipleDeviceCreation: action.payload,
                multipleDevicesInfoSubmitting: false
            };
        case actionTypes.ADD_MULTIPLE_DEVICES_FAILURE:
            return {
                ...state,
                multipleDevicesCreated: requestCycle.failed,
                errorMessageForMultipleDeviceCreation: action.payload.error,
                multipleDevicesInfoSubmitting: false
            };

        case actionTypes.CHANGE_RENT_PER_DAY_FOR_DEVICE_REQUEST:
            return {
                ...state,
                buttonLoading: true,
                rentChanged: undefined
            };
        case actionTypes.CHANGE_RENT_PER_DAY_FOR_DEVICE_SUCCESS:
            return {
                ...state,                
                buttonLoading: false,
                rentChanged: true
            };
        case actionTypes.CHANGE_RENT_PER_DAY_FOR_DEVICE_FAILURE:
            return {
                ...state,
                buttonLoading: false,
                rentChanged: false
            };

        case actionTypes.GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE_REQUEST:
            return {
                ...state,
                modalListLoading: true,
                rentChanged: undefined
            };
        case actionTypes.GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE_SUCCESS:

            return {
                ...state,
                rentChangeHistory: action.payload,
                modalListLoading: false
            };
        case actionTypes.GET_RENT_PER_DAY_CHANGE_LOG_HISTORY_FOR_DEVICE_FAILURE:
            toast.error("Change history could not be fetched");
            return {
                ...state,
                modalListLoading: false
            };

        case actionTypes.GET_LAST_RENT_BY_DEVICE_REQUEST:
            return {
                ...state,
                modaldataLoading: true
            };
        case actionTypes.GET_LAST_RENT_BY_DEVICE_SUCCESS:

            return {
                ...state,
                lastRentDetails: action.payload,
                modaldataLoading: false
            };
        case actionTypes.GET_LAST_RENT_BY_DEVICE_FAILURE:
            toast.error("Could not fetch data");
            return {
                ...state,
                modaldataLoading: false
            };
        
        case actionTypes.CLEAN_UP_RENT_CHANGE_MODAL:
            return {
                ...state,
                rentChangeHistory: undefined,
                modalListLoading: undefined,
                buttonLoading: undefined,
            };
        case actionTypes.CLEAN_UP_LAST_RENT_MODAL:
            return {
                ...state,
                lastRentDetails: undefined,
                modaldataLoading: undefined,
                buttonLoading: undefined,
            };

        case actionTypes.CHANGE_RENTABLE_STATUS_REQUEST:
            return {
                ...state,
                rentableChanged: undefined,
                rentableStatusGettingUpdated: true
            };
        case actionTypes.CHANGE_RENTABLE_STATUS_SUCCESS:
            return {
                ...state,   
                rentableChanged: requestCycle.success,
                rentableStatusGettingUpdated: false
            };
        case actionTypes.CHANGE_RENTABLE_STATUS_FAILURE:
            return {
                ...state,
                rentableChanged: requestCycle.failed,
                rentableStatusGettingUpdated: false
            };

        case actionTypes.COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY_REQUEST:
            return {
                ...state,
                summary: undefined,
                collectingDeviceActivitySummary: true
            };
        case actionTypes.COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY_SUCCESS:
            return {
                ...state,
                summary: action.payload,
                collectingDeviceActivitySummary: false
            };
        case actionTypes.COLLECT_DEVICE_ACTIVE_DAYS_SUMMARY_FAILURE:
            toast.error("Failed to collect smart battery's activity summary!");
            return {
                ...state,
                collectingDeviceActivitySummary: false
            };
        case actionTypes.ASSIGN_GARAGE_REQUEST:
            return {
                ...state,
                garageAssigned: undefined,
                garageAssignmentInfoSubmitting: true
            };
        case actionTypes.ASSIGN_GARAGE_SUCCESS:
            return {
                ...state,
                garageAssigned: requestCycle.success,
                garageAssignmentInfoSubmitting: false,
                successMessageForAssignment: action.payload
            };
        case actionTypes.ASSIGN_GARAGE_FAILURE:
            return {
                ...state,
                garageAssigned: requestCycle.failed,
                garageAssignmentInfoSubmitting: false,
                errorMessageForAssignment: action.payload.error
            };

        case actionTypes.COLLECT_GARAGE_LIST_FOR_ASSIGNMENT_REQUEST:
            return {
                ...state,
                garageListForAssignment: undefined,
                garageListForAssignmentCollectionInProgress: true
            };
        case actionTypes.COLLECT_GARAGE_LIST_FOR_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                garageListForAssignment: action.payload,
                garageListForAssignmentCollectionInProgress: false
            };
        case actionTypes.COLLECT_GARAGE_LIST_FOR_ASSIGNMENT_FAILURE:
            return {
                ...state,
                garageListForAssignmentCollectionInProgress: false
            };

        case actionTypes.RETURN_TO_INITIAL_STATE_FOR_SMART_BATTERY_CREATION:
            return initialStateForSmartBatteryCreation

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
