import React, {useEffect, useState} from 'react';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../../smartDongles/views/components";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {SolInput} from "../../../components/SolStyledComponents/components";
import DataTable from "../../../components/dataTable/DataTable";
import {manualTransactionActions} from "../actions";

import {DataTableContainer} from "../../rentLog/utils";
import {LoadingSpinner} from '../../../components/LoadingSpinnerForDataTable'
import {connect} from "react-redux";
import DateTimeFormat from "../../../utils/dateFormat/DateTimeFormat";
import {Button, Form, OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import Select from "react-select";
import {Controller, useForm} from "react-hook-form";
import {DatePicker} from "@material-ui/pickers";
import {ThemeProvider} from "@material-ui/styles";
import {theme, useStylesForDatePicker} from "../../../utils/muiPickerCustomStyle";
import moment from "moment";
import 'moment/locale/bn';
import 'moment/locale/en-gb';
import {toast} from "react-toastify";
import {DayPickerRangeController} from "react-dates";
import {convertToAnotherLang} from "../../../utils/convertLanguageOfNumbersWithCharacter";
import {toBengaliNumber} from "bengali-number";
import {exportInExcel} from "../../../utils/excelExport";
import {useSubheader} from "../../../../_metronic/layout";
import dateTimeFormat from "../../../utils/dateFormat/DateTimeFormat";


const ApprovedTransactions = props => {
    const classes = useStylesForDatePicker();
    const {clearErrors, setError, reset, handleSubmit, control, errors} = useForm()

    const [language, setLanguage] = useState(null);
    const [showFilter, setShowFilter] = useState(true);
    const [filterApplied, setFilterApplied] = useState(false);

    const [dongleSerialNumber, setDongleSerialNumber] = useState("");
    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [revenueType, setRevenueType] = useState(null);
    const [serialNumberNotNumericMessage, setSerialNumberNotNumericMessage] = useState(false);
    const [serialNumberLengthMessage, setSerialNumberLengthMessage] = useState(false);
    const [totalPayments, setTotalPayments] = useState(0);
    const [totalAmounts, setTotalAmounts] = useState(0);
    const [approvedTransactions, setApprovedTransactions] = useState([]);

    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [page, setPage] = useState(1);

    const pageTitle = language === 'EN'? 'Approved transactions':'অনুমোদিত লেনদেনসমূহ'

    const fromDateFilterKeyName = 'from_date'
    const toDateFilterKeyName = 'to_date'
    const deviceFilterKeyName = 'device_serial_number'

    const approvedTransactionsColumns = [
        {
            field: 'updated_at',
            title: language === "EN"? "Approved at":"অনুমোদনের তারিখ",
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            render: (rowdata)=>{
                return rowdata.updated_at? moment(rowdata.updated_at).local().format('Do MMM,YYYY h:mm a'):''
            }
        },
        {
            field: 'pay_amount',
            title: language === 'EN'? 'Amount (BDT)':'পরিমাণ (টাকায়)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            }
        },
        {
            field: 'payment_date',
            title: language === "EN"?  "Payment date":"লেনদেনের তারিখ",
            cellStyle: {
                textAlign: 'left'
            },
            headerStyle: {
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            render: (rowdata)=>{
                return rowdata.payment_date? moment(rowdata.payment_date).format('Do MMM,YYYY'):''
            }
        },
        {
            field: 'payment_mode',
            title: language === "EN"? "Payment mode":"লেনদেনের মোড",
            cellStyle: {
                textAlign: 'left'
            },
            headerStyle: {
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            }
        },
        {
            field: 'bkash_transaction_id',
            title: language === 'EN'? 'bKash ID':'বিকাশ আইডি',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            // disableClick: true,
        },
        {
            field: 'manual_payment_dongle_id',
            title: language === 'EN'? 'Dongle':'ডঙ্গল',
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
        },
        {
            field: 'garage_name',
            title: language === "EN"? "Garage":"গগ্যারেজ",
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
        },
        {
            field: 'revenue_type',
            title: language === "EN"? "Revenue Type":"রেভেনিউয়ের ধরন",
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
        },
        {
            field: 'remarks',
            title: language === "EN"? "Uploader remarks":"মন্তব্য",
            headerStyle: {
                textAlign: 'left'
            },
            cellStyle: {
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'created_by_name',
            title: language === "EN"? "Uploaded by":"আপলোড করেছেন",
            headerStyle: {
                paddingRight: 0,
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            }
        },
    ]

    // TODO: Need to add Bengali translation ?
    const revenueTypeOptions = [
        {
            label: 'Gross client collection',
            value: 'Gross client collection'
        },
        {
            label: 'Leasing Fee',
            value: 'Leasing Fee'
        },
        {
            label: 'Leasing fee as gross client collection',
            value: 'Leasing fee as gross client collection'
        },
        {
            label: 'Net client collection',
            value: 'Net client collection'
        }
    ]

    const {
        FETCH_MANUAL_PAYMENTS,
        RETURN_TO_INITIAL
    } = props

    useEffect(()=>{
        setLanguage(props.language)

        FETCH_MANUAL_PAYMENTS({status: "completed"})
        return ()=>{
            RETURN_TO_INITIAL()
        }
    },[])

    useEffect(()=>{
        if (props.payments) {
            let payments = props.payments
            let totalPayments = 0
            let totalAmounts = 0
            if (payments && payments.length > -1) {
                setApprovedTransactions(payments.map((payment) => {
                    let paidAmount = new Intl.NumberFormat('en-IN').format(payment.pay_amount)
                    totalAmounts = parseInt(payment.pay_amount) + totalAmounts
                    totalPayments = totalPayments + 1
                    return {
                        ...payment,
                        pay_amount: paidAmount
                    }
                }))
                totalPayments = new Intl.NumberFormat('en-IN').format(totalPayments)
                totalAmounts = new Intl.NumberFormat('en-IN').format(totalAmounts)
                setTotalPayments(totalPayments)
                setTotalAmounts(totalAmounts)
            }
        }
    },[props.payments])

    useEffect(() => {
        clearAllErrorMessages()
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    },[start, end])

    const resetFilterValues = () => {
        setStartDate(null)
        setEndDate(null)
        setDongleSerialNumber("")
        setRevenueType("")
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        // if (filterApplied) {
        //     FETCH_EVENT_LIST({page: newPage, ...filterData})
        // } else {
        //     FETCH_EVENT_LIST({page: newPage})
        // }
    }

    const clearAllErrorMessages = () => {
        // TODO: Will add something when we use in dom error messages
    }

    const filterTheList = () => {
        if (dongleSerialNumber && dongleSerialNumber.length !== 8) {
            // TODO: Lets use in dom error messages instead of toast later
            // setSerialNumberLengthMessage(true)
            toast.error('Serial Number must be of 8 digits')
        } else {
            // TODO: Will construct the approved transactions
            // let filter = {page: 1, ...filterData}
            let filter = {
                status: "completed",
            }
            if (start && end){
                filter['created_at_from'] = start.format('YYYY-MM-DD');
                filter['created_at_to'] = end.format('YYYY-MM-DD');
            }
            if (dongleSerialNumber){
                filter["manual_payment_dongle_id"] = dongleSerialNumber;
            }
            if (revenueType){
                filter["revenue_type"] = revenueType.value;
            }
            FETCH_MANUAL_PAYMENTS(filter)
            setFilterApplied(true)
        }
    }

    const resetFilter = () => {
        if (filterApplied) {
            // Resetting the approved transactions list
            setPage(1)
            FETCH_MANUAL_PAYMENTS({status: "completed"})
        }
        resetFilterValues()
        setFilterApplied(false)
        clearAllErrorMessages()
    }

    const disableFutureDt = (current) => {
        const today = moment().add('1', 'days')
        return current.isAfter(today)
    }

    const onDatesChange = ({startDate, endDate}) => {
        setStartDate(startDate)
        setEndDate(endDate)
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start}
                    endDate={end}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    )

    return (
        <ContentWrapper isLoading={false} pageTitle={"Approved transactions"} showBackButton={false} showCardHeader={false}>
            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>{pageTitle}</b></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            <IconButton onClick={filterToggle}>
                                <FilterListIcon color="disabled" fontSize="large"/>
                            </IconButton>
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>
                <hr/>
            </div>


            {showFilter?
                <>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <Form>
                                <div className={'row g-3'}>
                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Date range':'তারিখের সীমা'}</Form.Label>
                                            </div>
                                            <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                            overlay={popover}>
                                                <SolInput
                                                    name={'dateRangePickerInput'}
                                                    readOnly={true}
                                                    value={dateRange}
                                                    placeholder={language === 'EN'? 'Select a date range':'তারিখের সীমা বাছাই করুন'}
                                                />
                                            </OverlayTrigger>
                                        </Form.Group>
                                    </div>

                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Revenue Type':'আয়ের ধরন'}</Form.Label>
                                            </div>
                                            <Select
                                                name={`revenueType`}
                                                placeholder={language === 'EN'? 'Select a revenue type':'একটি আয়ের ধরন বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                maxMenuHeight={200}
                                                value={revenueType}
                                                isClearable={true}
                                                options={revenueTypeOptions}
                                                isSearchable={true}
                                                onChange={(selected, {action}) => {
                                                    // clearAllErrorMessages()
                                                    if (action === "clear") {
                                                        setRevenueType("")
                                                    }
                                                    if (selected) {
                                                        setRevenueType(selected)
                                                    } else {
                                                        setRevenueType('')
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Dongle serial number':'যন্ত্রের ক্রমিক নম্বর'}</Form.Label>
                                            </div>
                                            <SolInput
                                                name={'serialNumber'}
                                                type="text"
                                                placeholder={language === 'EN'? 'Ex: 44000001':'উদাহরণঃ 44000001'}
                                                value={dongleSerialNumber}
                                                autoComplete={"off"}
                                                onChange = {(e) => {
                                                    clearAllErrorMessages()
                                                    if (e.target.value) {
                                                        let value = e.target.value;
                                                        if (isNaN(value) || value.includes('.')) {
                                                            setSerialNumberNotNumericMessage(true)
                                                        } else {
                                                            setSerialNumberNotNumericMessage(false)
                                                        }
                                                        setDongleSerialNumber(value)
                                                    } else {
                                                        setDongleSerialNumber("")
                                                    }
                                                }}
                                            />
                                            {serialNumberNotNumericMessage? <><div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? "Device serial number must contain only numbers!":"যন্ত্রের ক্রমিক নাম্বারে শুধু সংখ্যা থাকবে!"}</div></>:null}
                                            {serialNumberLengthMessage? <><div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? "Device serial number must be of 8 digits!":"যন্ত্রের ক্রমিক নাম্বার ৮ সংখ্যার হতে হবে!"}</div></>:null}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={"col-md-12"}>
                                        <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}}>
                                            {language === 'EN'? 'Reset filter':'প্রাথমিক অবস্থায় ফিরে যান'}
                                        </Button>
                                        <Button variant="warning" size={'sm'} type="button"
                                                disabled={props.listLoading}
                                                onClick={()=> {filterTheList()}} className={'ml-3'}>
                                            {language === 'EN'? 'Apply filter':'তালিকায় খুঁজুন'}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <hr/></>:null}
            <div>

                <div className={'row'}>
                    <div className={'col-lg-6'}><h1><b>{props.language === 'EN'? 'Total transactions:':'সর্বমোট লেনদেন:'}</b>&nbsp;{language === 'EN'? totalPayments:toBengaliNumber(totalPayments)}</h1></div>
                    <div className={'col-lg-6'}><h1><b>{props.language === 'EN'? 'Total payments:':'লেনদেনের পরিমাণ'}</b>&nbsp;{language === 'EN'? totalAmounts:toBengaliNumber(totalAmounts)}&nbsp;{language === 'EN'? 'BDT':'টাকা'}</h1></div>
                </div>
                <DataTableContainer>
                    {props.listLoading !== true ? <DataTable
                        language={language}
                        noDataAvailableMessageInBangla={'কোন লেনদেন  নেই'}
                        columns={approvedTransactionsColumns}
                        asyncPagination={true}
                        isLoading={props.listLoading}
                        data={approvedTransactions}
                    /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Generating approved transaction list..': 'অনুমোদিত লেনদেনের তালিকা প্রস্তুত হচ্ছে..'} language={language}/>}
                </DataTableContainer>

            </div>

        </ContentWrapper>
    );
};

ApprovedTransactions.propTypes = {

};

export default connect((state) => {
    return {
        language: state.auth.language,
        isLoading: state.manualTransactionsReducer.isLoading,
        eventList: state.manualTransactionsReducer.events,
        listLoading: state.manualTransactionsReducer.listLoading,
        buttonLoading: state.manualTransactionsReducer.buttonLoading,
        iconLoading: state.manualTransactionsReducer.iconLoading,
        payments: state.manualTransactionsReducer.payments
    }
}, {...manualTransactionActions}) (ApprovedTransactions);
