import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import SmartDongleList from "./views/smartDongleList/smartDongleList";
import CreateSmartDongle from "./views/newSmartDongle/CreateSmartDongle";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";

// TODO: Need to update authorization related checking

export const SmartDonglesRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the dongle if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/dongles"}
                to={"/dongles/list"}
            />

            {/* Routes to Dongles List*/}
            <SecuredRoute
                path={"/dongles/list"}
                component={SmartDongleList}
                service={getServiceName('deviceService')}
                feature={getFeatureName('smartDongleFeature')}
                action={getActionName('listAction')}
            />

            <SecuredRoute
                path={"/dongles/create"}
                component={CreateSmartDongle}
                service={getServiceName('deviceService')}
                feature={getFeatureName('smartDongleFeature')}
                action={getActionName('createAction')}
            />

            <SecuredRoute
                path={"/dongles/edit"}
                component={CreateSmartDongle}
                service={getServiceName('deviceService')}
                feature={getFeatureName('smartDongleFeature')}
                action={getActionName('updateAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
