export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_TOTAL_PAYMENT_REQUEST: 'COLLECT_TOTAL_PAYMENT_REQUEST',
    COLLECT_TOTAL_PAYMENT_SUCCESS: 'COLLECT_TOTAL_PAYMENT_SUCCESS',
    COLLECT_TOTAL_PAYMENT_FAILURE: 'COLLECT_TOTAL_PAYMENT_FAILURE',

    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE'
};
