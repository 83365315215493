import React, {useEffect, useState} from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {Badge, Button, Form, OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import {makeStyles, ThemeProvider} from "@material-ui/styles";
import {DataTableContainer} from '../../utils'
import {FilterInput, LoadingSpinner, SolInput, SolTypography} from '../../../../components';
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import moment from "moment";
import {toast} from "react-toastify";
import {Controller, useForm} from "react-hook-form";
import createMuiTheme from "@material-ui/core/styles/createTheme";
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {DayPickerRangeController, SingleDatePickerWrapper} from "react-dates";
import {SolTextArea} from "../../../../components/SolStyledComponents/components";
import {requestCycle} from "../../utils";
import {showNotifications} from "../../../../utils/notification";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoIcon from "@mui/icons-material/Info";
import Overlay from "react-bootstrap/Overlay";


const theme= createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#F18D00',
        },
    },
});

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const NewSettlement = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const subHeader = useSubheader();
    const [language, setLanguage] = useState(null);
    const [showSummarySection, setShowSummarySection] = useState(false);
    const [showShowMoreButton, setShowShowMoreButton] = useState(false);
    const [showAllContracts, setShowAllContracts] = useState(false);
    const [contractIds, setContractIds] = useState([]);
    const [summaryData, setSummaryData] = useState(undefined);
    const [contracts, setContracts] = useState([]);
    const [allContractHtmlWithShowMoreButton, setAllContractHtmlWithShowMoreButton] = useState([]);
    const [allContractHtmlWithoutShowMoreButton, setAllContractHtmlWithoutShowMoreButton] = useState([]);
    const [twoContractHtml, setTwoContractHtml] = useState([]);
    const [totalContracts, setTotalContracts] = useState('');
    const [totalPaid, setTotalPaid] = useState('');
    const [settleAmount, setSettleAmount] = useState('');
    const [bKashCharges, setBkashCharges] = useState('');
    const [platformFee, setPlatformFee] = useState('');
    const [batteryFinancierCharges, setBatteryFinancierCharges] = useState('');

    // Filter related
    const [financiers, setFinanciers] = useState([]);
    const [financier, setFinancier] = useState('');
    const [targetForSettlementPeriodDateRangeField, setTargetForSettlementPeriodDateRangeField] = useState(null);
    const [showSettlementPeriodDatepicker, setShowSettlementPeriodDatepicker] = useState(false);
    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const settlementTypes= [
        {label: 'Collection', value: 'collection'},
        {label: 'Disbursement', value: 'disbursement'}
    ];
    const [settlementType, setSettlementType] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');

    const informationIsRequired = 'Above information is required!'

    const {
        SETTLE,
        COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT,
        GET_ORGANISATIONS
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setActionButtons(null)
        GET_ORGANISATIONS()
        subHeader.setBreadCrumbComponent(
            <>
                <h5 className="text-dark font-weight-bold my-2 mr-5">Settle</h5>
            </>
        )

        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
            props.RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER();
        }
    }, []);

    const toggleContractDataRepresentation = () => {
        setShowAllContracts(!showAllContracts)
    }

    // Summary table header related styling
    const summaryTableHeaderLeftAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        paddingLeft: "0px",
        textAlign: "left",
        width: 'calc(6.16667px)',
        boxSizing: "borderBox"
    }

    const summaryTableHeaderRightAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        paddingLRight: "0px",
        textAlign: "right",
        boxSizing: "borderBox"
    }

    // Summary table cell related styling
    const summaryTableLeftAlignedContentStyling = {
        color: "inherit",
        // width: calc(112.5px),
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        paddingLeft: "0px",
        width: 'calc(47.333px)',
        textAlign: "left",
        whiteSpace: 'nowrap'
    }

    const summaryTableRightAlignedContentStyling = {
        color: "inherit",
        // width: calc(112.5px),
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        // paddingRight: "0px",
        width: 'calc(47.333px)',
        textAlign: "right",
        whiteSpace: 'nowrap'
    }

    const summaryTableCenterAlignedContentStyling = {
        color: "inherit",
        // width: calc(112.5px),
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        paddingTop: "0px",
        width: 'calc(47.333px)',
        textAlign: "center",
        whiteSpace: 'nowrap'
    }

    useEffect(() => {
        if (props.financiers && props.financiers.length > -1) {
            const financiers = props.financiers
            setFinanciers(financiers.map((item) => {
                return {
                    ...item,
                    label: item.name,
                    value: item.pk
                }
            }))
        }
    }, [props.financiers])

    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const [clickCount, setClickCount] = useState(0)
    const onDatesChange = ({startDate, endDate}) => {
        setClickCount(clickCount + 1)
        if (startDate && endDate) {
            setStartDate(startDate)
            setEndDate(endDate)
            if (clickCount === 1) {
                setClickCount(0)
                setShowSettlementPeriodDatepicker(false)
            }
        } else if (startDate && !endDate) {
            setStartDate(startDate)
            setEndDate(startDate)
        }
    }

    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    }, [start, end])

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const showHelpfulText = () => {
        return <>
            <div className={'container'} style={{color: "#F18D00"}}><InfoIcon size={'sm'}/> Selection is completed
                after <strong>two clicks on a single date</strong> or <strong>single click on two dates.</strong></div>
        </>
    }

    const popoverForSettlementPeriodSelection = () => {
        return <>
            <Overlay
                show={showSettlementPeriodDatepicker}
                target={targetForSettlementPeriodDateRangeField}
                placement="bottom-start"
            >
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerRangeController
                            startDate={start}
                            endDate={end}
                            numberOfMonths={2}
                            minimumNights={0}
                            firstDayOfWeek={0}
                            isOutsideRange={disableFutureDt}
                            onDatesChange={onDatesChange}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                            renderKeyboardShortcutsButton={showHelpfulText}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "settlementPeriod") {
                                    setShowSettlementPeriodDatepicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }

    const collectFilteredSummary = (data) => {
        let filter = {"data_format": "summary"}
        if (data.financier) {
            filter['financier_guid'] = data.financier.value
            setFinancier(data.financier)
        }
        if (data.settlementType) {
            filter['settlement_type'] = data.settlementType.value
            setSettlementType(data.settlementType)
        }
        if (start && end) {
            filter['settlement_start_date'] = moment(start).format("YYYY-MM-DD")
            filter['settlement_till_date'] = moment(end).format("YYYY-MM-DD")
        }
        COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT({...filter})
        setShowSummarySection(true)
    }

    const resetFilter = () => {
        clearErrors()
        if (showSummarySection) {
            setShowSummarySection(false)
            setSummaryData(undefined)
        }

        setStartDate(null)
        setEndDate(null)
        setValue("financier", "")
        setValue("settlementType", "")
    }

    useEffect(() => {
        if (props.summaryOfInvoices && Object.keys(props.summaryOfInvoices).length > -1) {
            const summary = props.summaryOfInvoices
            const contractList = summary.contract_summary
            const contractIdList = Object.keys(contractList)
            setContractIds(contractIdList)
            const contractListLength = contractIdList.length
            setSummaryData(summary)
            setTotalContracts(contractListLength)
            setTotalPaid(summary.paid_total)
            setSettleAmount(summary.settled_amount)
            setBkashCharges(summary.mfs_charge_amount)
            setPlatformFee(summary.platform_fee_amount)
            setBatteryFinancierCharges(summary.adjustment_amount)
            setContracts(contractList)
            if (contractListLength > 5) {
                setShowShowMoreButton(true)
            } else {
                setShowAllContracts(true)
            }
        }
    }, [props.summaryOfInvoices])

    useEffect(() => {
        if (Object.keys(contracts).length > 0) {
            if (!showShowMoreButton && showAllContracts) {
                let html = []
                contractIds.map((item) => {
                    let contract= contracts[item]
                    html.push(<>
                        <TableRow>
                            <TableCell style={summaryTableLeftAlignedContentStyling}>{item}</TableCell>
                            <TableCell style={summaryTableRightAlignedContentStyling}>{contract.device_sl}</TableCell>
                            <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.paid_amount.toFixed(2))}</TableCell>
                            <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.financier_revenue.toFixed(2))}</TableCell>
                            <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.solshare_revenue.toFixed(2))}</TableCell>
                            <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.prev_settled_amount.toFixed(2))}</TableCell>
                            <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.new_settled_amount.toFixed(2))}</TableCell>
                        </TableRow>
                    </>)
                })
                setAllContractHtmlWithoutShowMoreButton(html)
            } else if (showShowMoreButton && !showAllContracts) {
                let html = []
                for (let i=0; i<totalContracts; i++) {
                    let contractId= contractIds[i]
                    let contract= contracts[contractId]
                    if (i === 0) {
                        html.push(<>
                            <TableRow>
                                <TableCell style={summaryTableLeftAlignedContentStyling}>{contractId}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>{contract.device_sl}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.paid_amount.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.financier_revenue.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.solshare_revenue.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.prev_settled_amount.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.new_settled_amount.toFixed(2))}</TableCell>
                            </TableRow>
                        </>)
                    } else if (i === 1) {
                        html.push(<>
                            <TableRow>
                                <TableCell style={summaryTableLeftAlignedContentStyling}>{contractId}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>{contract.device_sl}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.paid_amount.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.financier_revenue.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.solshare_revenue.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.prev_settled_amount.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.new_settled_amount.toFixed(2))}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={7} style={summaryTableCenterAlignedContentStyling}>
                                    <Button variant="secondary" size={'sm'} type="button" onClick={toggleContractDataRepresentation}>
                                        Show More <ExpandMoreIcon size={'sm'}/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </>)
                    } else {
                        break
                    }
                }
                setTwoContractHtml(html)
            } else if (showShowMoreButton && showAllContracts) {
                let html = []
                contractIds.map((item, index) => {
                    let contract= contracts[item]
                    if (index === contractIds.length - 1) {
                        html.push(<>
                            <TableRow>
                                <TableCell style={summaryTableLeftAlignedContentStyling}>{item}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>{contract.device_sl}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.paid_amount.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.financier_revenue.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.solshare_revenue.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.prev_settled_amount.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.new_settled_amount.toFixed(2))}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={7} style={summaryTableCenterAlignedContentStyling}>
                                    <Button variant="secondary" size={'sm'} type="button" onClick={toggleContractDataRepresentation}>
                                        Show Less <ExpandLessIcon size={'sm'}/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </>)
                    } else {
                        html.push(<>
                            <TableRow>
                                <TableCell style={summaryTableLeftAlignedContentStyling}>{item}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>{contract.device_sl}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.paid_amount.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.financier_revenue.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.solshare_revenue.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.prev_settled_amount.toFixed(2))}</TableCell>
                                <TableCell style={summaryTableRightAlignedContentStyling}>Tk {new Intl.NumberFormat('en-IN').format(contract.new_settled_amount.toFixed(2))}</TableCell>
                            </TableRow>
                        </>)
                    }
                })
                setAllContractHtmlWithShowMoreButton(html)
            }
        }
    }, [contracts, showAllContracts])

    const saveSettlementInfo = (data) => {
        if (settleAmount !== 0) {
            let payload = {
                "settlement_start_date": moment(start).format("YYYY-MM-DD"),
                "settlement_till_date": moment(end).format("YYYY-MM-DD"),
                "financier_guid": financier.value,
                "settlement_type": settlementType.value,
                "settlement_status": "settled",
                "notes": data.notes? data.notes:'',
                "reference": 'EFT',
                "paid_total": totalPaid,
                "settled_amount": settleAmount,
                "mfs_charge_amount": bKashCharges,
                "platform_fee_amount": platformFee,
                "received_amount": batteryFinancierCharges
            }
            SETTLE(payload)
        } else {
            toast.error("Settlement amount can't be zero!")
        }
    }

    useEffect(() => {
        if (props.settlementStatus && props.settlementStatus === requestCycle.success) {
            toast.success("Successfully settled!")
            props.history.push({
                pathname: '/settlements/list'
            })
        }
    }, [props.settlementStatus])

    useEffect(() => {
        if (props.errorMessageForNewSettlement) {
            showNotifications('error', props.errorMessageForNewSettlement)
        }
    }, [props.errorMessageForNewSettlement])

    return (
        <div>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={'Settle'}
                            statusCode={props.statusCode}>
                <>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <Form onSubmit={handleSubmit(collectFilteredSummary)}>
                                <div className={'row g-3'}>
                                    <div className={'col-md-3'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>Settlement Period <span className={'text-danger'}>*</span></Form.Label>
                                            </div>
                                            <SolInput
                                                name={'settlementPeriod'}
                                                readOnly={true}
                                                value={dateRange}
                                                placeholder={"Select a date range"}
                                                ref={register({
                                                    required: informationIsRequired
                                                })}
                                                onClick={(e) => {
                                                    setTargetForSettlementPeriodDateRangeField(e.target)
                                                    setShowSettlementPeriodDatepicker(!showSettlementPeriodDatepicker)
                                                }}
                                            />
                                            {errors.settlementPeriod && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.settlementPeriod.message}</div>}
                                        </Form.Group>
                                    </div>
                                    <div className={'col-md-3'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>Battery Financier <span className={'text-danger'}>*</span></Form.Label>
                                            </div>
                                            <Controller
                                                control={control}
                                                name={"financier"}
                                                rules={{required: informationIsRequired}}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        placeholder={'Select a financier'}
                                                        classNamePrefix="react-select-sol-style"
                                                        isDisabled={props.financierListLoading}
                                                        isLoading={props.financierListLoading}
                                                        maxMenuHeight={200}
                                                        value={value}
                                                        isClearable={true}
                                                        control={control}
                                                        inputRef={ref}
                                                        options={financiers}
                                                        isSearchable={true}
                                                        onChange={(selected, {action}) => {
                                                            onChange(selected)
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.financier && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.financier.message}</div>}
                                        </Form.Group>
                                    </div>
                                    <div className={'col-md-3'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>Settlement Type <span className={'text-danger'}>*</span></Form.Label>
                                            </div>
                                            <Controller
                                                control={control}
                                                name={"settlementType"}
                                                rules={{required: informationIsRequired}}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        placeholder={'Select a type'}
                                                        classNamePrefix="react-select-sol-style"
                                                        maxMenuHeight={200}
                                                        value={value}
                                                        isClearable={true}
                                                        control={control}
                                                        inputRef={ref}
                                                        options={settlementTypes}
                                                        isSearchable={true}
                                                        onChange={(selected, {action}) => {
                                                            onChange(selected)
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.settlementType && <div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.settlementType.message}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={"col-md-12"}>
                                        <Button variant="warning" size={'sm'} type="submit"
                                                disabled={props.summaryDataCollectionInProgress}>
                                            {!showSummarySection? 'Show Transactions Summary':'Filter'}
                                        </Button>
                                        <Button disabled={props.summaryDataCollectionInProgress}
                                                variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}}
                                                className={'ml-3'}>
                                            Reset
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    {showSummarySection? <>
                        {!props.summaryDataCollectionInProgress? <>
                            {summaryData? <>
                                <div className={'row'} style={{marginTop: '4rem'}}>
                                    <div className={'col-md-12'}>
                                        <TableContainer>
                                            <Table aria-label="spanning table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={summaryTableHeaderLeftAlignedColumnStyling}>Total Contracts</TableCell>
                                                        <TableCell style={summaryTableHeaderRightAlignedColumnStyling}>{settlementType.value === 'collection'? 'Collected Amount (Tk)':'Paid (Tk)'}</TableCell>
                                                        <TableCell style={summaryTableHeaderRightAlignedColumnStyling}>{settlementType.value === 'collection'? 'Total Receivable (Tk)':'Total Payable (Tk)'}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell style={summaryTableLeftAlignedContentStyling}><h6><strong>{totalContracts}</strong></h6></TableCell>
                                                        <TableCell style={summaryTableRightAlignedContentStyling}><h6><strong>{new Intl.NumberFormat('en-IN').format(totalPaid.toFixed(2))}</strong></h6></TableCell>
                                                        <TableCell style={summaryTableRightAlignedContentStyling}><h6><strong>{new Intl.NumberFormat('en-IN').format(settleAmount.toFixed(2))}</strong></h6></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <TableContainer>
                                            <Table aria-label="spanning table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={summaryTableHeaderLeftAlignedColumnStyling}>Contract ID</TableCell>
                                                        <TableCell style={summaryTableHeaderRightAlignedColumnStyling}>Smart Battery ID</TableCell>
                                                        <TableCell style={summaryTableHeaderRightAlignedColumnStyling}>Paid Amount</TableCell>
                                                        <TableCell style={summaryTableHeaderRightAlignedColumnStyling}>Financier's Share</TableCell>
                                                        <TableCell style={summaryTableHeaderRightAlignedColumnStyling}>Platform fee</TableCell>
                                                        <TableCell style={summaryTableHeaderRightAlignedColumnStyling}>{settlementType.value === 'collection'? 'Already Received by SOLshare':'Already Paid by SOLshare'}</TableCell>
                                                        <TableCell style={summaryTableHeaderRightAlignedColumnStyling}>{settlementType.value === 'collection'? 'Receivable Amount':'Payable Amount'}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {contractIds.length > 0? <>
                                                        {showShowMoreButton && showAllContracts? <>
                                                            {allContractHtmlWithShowMoreButton}
                                                        </>:<>
                                                            {showShowMoreButton && !showAllContracts? <>
                                                                {twoContractHtml}
                                                            </>:<>
                                                                {/* it will always exceute when !showShowMoreButton && showAllContracts */}
                                                                {/* because !showShowMoreButton && !showAllContracts is not logically possible*/}
                                                                {allContractHtmlWithoutShowMoreButton}
                                                            </>}
                                                        </>}
                                                    </>:<>
                                                        <TableRow>
                                                            <TableCell colSpan={7} style={summaryTableCenterAlignedContentStyling}>
                                                                <h6><strong>No contracts available</strong></h6>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>}
                                                    <TableRow>
                                                        <TableCell colSpan={6} style={summaryTableRightAlignedContentStyling}>bKash Charges (1.5%)</TableCell>
                                                        <TableCell style={summaryTableRightAlignedContentStyling}>{new Intl.NumberFormat('en-IN').format(bKashCharges.toFixed(2))}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={6} style={summaryTableRightAlignedContentStyling}>SOLshare Platform Fee (3%)</TableCell>
                                                        <TableCell style={summaryTableRightAlignedContentStyling}>{new Intl.NumberFormat('en-IN').format(platformFee.toFixed(2))}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={6} style={summaryTableRightAlignedContentStyling}><h6><strong>Receivable By Battery Financier After Charges</strong></h6></TableCell>
                                                        <TableCell style={summaryTableRightAlignedContentStyling}><h6><strong>{new Intl.NumberFormat('en-IN').format(batteryFinancierCharges.toFixed(2))}</strong></h6></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                <Form onSubmit={handleSubmit(saveSettlementInfo)}>
                                    <div className={'row mt-20'}>
                                        <div className={'col-md-5'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label><strong>Settlement Notes</strong></Form.Label>
                                                </div>
                                                <SolTextArea
                                                    name={"notes"}
                                                    placeholder={"Provide notes, if any"}
                                                    autoComplete={"off"}
                                                    rows={"3"}
                                                    ref={register()}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="warning" size={'md'} type="submit"
                                                    disabled={props.settlementInProgress}>
                                                {props.settlementInProgress? <><Spinner animation="border"  size={'sm'} variant="light"/>&nbsp;&nbsp;</>:null}Save
                                            </Button>
                                            <Button variant="outline-dark" size={'md'}
                                                    onClick={()=> {
                                                        props.history.push({
                                                            pathname: '/settlements/list'
                                                        })
                                                    }}
                                                    className={'ml-3'}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </>:<>
                                <DataTableContainer>
                                    <div className={'row'}>
                                        <div className={'col-md-12'}>
                                            <div className={'d-flex justify-content-center'}>
                                                <h1>
                                                    <Badge variant="secondary">No Summary Available</Badge>
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </DataTableContainer>
                            </>}
                        </>:<LoadingSpinner loadingSubText={'Collecting invoices summary ..'} language={language}/>}
                    </>:null}

                    {popoverForSettlementPeriodSelection()}
                </>
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

NewSettlement.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        financierListLoading: state.commonReducer.orgListLoading,
        financiers: state.commonReducer.organisations,
        summaryOfInvoices: state.settlementReducer.summaryOfInvoices,
        summaryDataCollectionInProgress: state.settlementReducer.summaryDataCollectionInProgress,
        settlements: state.settlementReducer.settlements,
        settlementCollectionInProgress: state.settlementReducer.settlementCollectionInProgress,
        settlementInProgress: state.settlementReducer.settlementInProgress,
        settlementStatus: state.settlementReducer.settlementStatus,
        errorMessageForNewSettlement: state.settlementReducer.errorMessageForNewSettlement
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(NewSettlement));
