import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {DataTableContainer} from "../../utils";
import DataTable from "../../../../components/dataTable/DataTable";
import {Toolbar} from "../../../users/views/components";
import {Button, Form, Spinner} from "react-bootstrap";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import Select from "react-select";
import {useForm, Controller} from "react-hook-form";
import {actions} from "../../actions";
import {requestCycle, csvToArray} from "../../../../utils/utilityFunctions";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../../auth/authorization";
import { DayPickerSingleDateController } from "react-dates";
import { Popover } from "react-bootstrap";
import moment from "moment";
import Overlay from "react-bootstrap/Overlay";

const BatchBattery = props => {
    /*
    * Todo:
    * Generate Batch Battery upto 100 and option to download csv and automatically download the created CSV
    * option to upload csv from Mpower. csv file validation. show all the data in a table
    * add a "Update batteries" button to send the update request
    * once the request completes , show the rejected rows as red
    * option to download the rejected list as csv
    *
    * */

    /*
    *
    *
    * */
    const {handleSubmit, errors, setError, control, register, clearErrors, reset} = useForm()
    const [batteries, setBatteries] = useState([])
    const [language, setLanguage] = useState('EN')
    const [battery_voltages, setBatteryVoltage] = useState([{'label': '51.2', 'value': '51.2'}, {'label': '64.0', 'value': '64.0'}])
    const [vendors, setVendors] = useState([])
    const [batteryModels, setBatteryModels] = useState([]);
    const [bmsVendors, setBmsVendors] = useState([]);
    const [bmsModels, setBmsModels] = useState([]);


    const [wStartDate, setWStartDate] = useState(moment());
    const [targetForWarrantyStartDatePicker, setTargetForWarrantyStartDatePicker] = useState(null);
    const [showWarrantyStartDatePicker, setShowWarrantyStartDatePicker] = useState(false);
    const [wEndDate, setWEndDate] = useState(moment());
    const [targetForWarrantyEndDatePicker, setTargetForWarrantyEndDatePicker] = useState(null);
    const [showWarrantyEndDatePicker, setShowWarrantyEndDatePicker] = useState(false);
    const [wStartDisplayDate, setwStartDisplayDate] = useState("");
    const [wEndDisplayDate, setwEndDisplayDate] = useState("");
    const [focusedForWarrantyStartDate, setFocusedForWarrantyStartDate] = useState(true);
    const [focusedForWarrantyEndDate, setFocusedForWarrantyEndDate] = useState(true);

    const [batteryVendor, setSelectedVendor] = useState(null)
    const [batteryModel, setBatteryModel] = useState(null);
    const [bmsVendor, setBmsVendor] = useState(null);
    const [bmsModel, setBmsModel] = useState(null);
    const [nominalVoltage, setNominalVoltage] = useState(null);

    const [showTable, setShowTable] = useState(false)
    const [rejectedBatteries, setRejectedBatteries] = useState([])
    const [phase, setPhase] = useState("")

    const batteryServiceName = getServiceName('batteryService')
    const batteryFeatureName = getFeatureName('batteryFeature')
    const createActionName = getActionName('createAction')
    const updateActionName = getActionName('updateAction')
    const [hasCreateAccess, setHasCreateAccess] = useState(false)
    const [hasUpdateAccess, setHasUpdateAccess] = useState(false)

    const generalErrorMessage = language === "EN"? "Please provide above information!":"দয়া করে উপরের তথ্যটি দিন!"
    const wrongFileTypeMessage = language === "EN"? "Please provide a csv file!":"দয়া করে একটি সিএসভি ফাইল দিন!"

    const {
        GET_BATTERY_MODEL_LIST,
        GET_BATTERY_VENDOR_LIST,
        GET_BMS_VENDOR_LIST,
        GET_BMS_MODEL_LIST,
        RETURN_TO_INITIAL_STATE,
        GENERATE_BATTERY_BATCH,
        UPDATE_BATCH_BATTERIES,
        batteriesUpdated
    } = props;

    useEffect(()=>{
        setLanguage(props.language)
        GET_BATTERY_VENDOR_LIST()
        GET_BATTERY_MODEL_LIST()
        GET_BMS_VENDOR_LIST()
        GET_BMS_MODEL_LIST()

        setHasCreateAccess(checkAuthorization(props.authorization, batteryServiceName, batteryFeatureName, [createActionName]))
        setHasUpdateAccess(checkAuthorization(props.authorization, batteryServiceName, batteryFeatureName, [updateActionName]))

        return ()=>{
            RETURN_TO_INITIAL_STATE()
        }

    },[])

    useEffect(() => {
        if (hasCreateAccess && !hasUpdateAccess) {
            setPhase("generate")
        } else if (!hasCreateAccess && hasUpdateAccess) {
            setPhase("update")
        } else {
            // if there are both accesses or no access
            setPhase("")
        }
    },[hasCreateAccess, hasUpdateAccess])


    useEffect(()=>{
        const batteryVendors = props.batteryVendors
        if (Array.isArray(batteryVendors) && batteryVendors.length>=0){
            setVendors(batteryVendors.map((item)=>{
                return {
                    ...item,
                    value: item.pk,
                    label: item.name
                }
            }))
        }
    },[props.batteryVendors])

    useEffect(()=>{
        const batteryModels = props.batteryModels;
        if (batteryModels && batteryModels.length >= 0) {
            setBatteryModels(batteryModels.map((batteryModel) => {
                return {
                    ...batteryModel,
                    label: batteryModel.model_name,
                    value: batteryModel.pk,
                }
            }));
        }
    },[props.batteryModels])

    useEffect(()=>{
        const bmsVendors = props.bmsVendors;
        if (bmsVendors && bmsVendors.length >= 0) {
            setBmsVendors(bmsVendors.map((bmsVendor) => {
                return {
                    ...bmsVendor,
                    label: bmsVendor.name,
                    value: bmsVendor.pk
                }
            }));
        }
    },[props.bmsVendors])

    useEffect(()=>{
        const bmsModels = props.bmsModels;
        if (bmsModels && bmsModels.length >= 0) {
            setBmsModels(bmsModels.map((bmsModel) => {
                return {
                    ...bmsModel,
                    label: bmsModel.model_name,
                    value: bmsModel.pk
                }
            }));
        }
    },[props.bmsModels])

    useEffect(()=>{

        const isBatteryGenerated = props.isBatteryGenerated
        const generatedBatteries = props.generatedBatteries
        if (isBatteryGenerated === requestCycle.success && Array.isArray(generatedBatteries) ){
            setBatteries(generatedBatteries)
        }
    }, [props.isBatteryGenerated])

    useEffect(()=>{
        if (props.rejectedBatteries){
            setRejectedBatteries(props.rejectedBatteries)
        }
    }, [props.rejectedBatteries])

    const onStartDatesChange = (sel_date) => {
        setWStartDate(sel_date)
        setwStartDisplayDate(moment.utc(sel_date).local().format('YYYY-MM-DD'))
        setShowWarrantyStartDatePicker(false)
        clearErrors("warranty_start_date")
    }

    const onEndDatesChange = (sel_date) => {
        setWEndDate(sel_date)
        setwEndDisplayDate(moment.utc(sel_date).local().format('YYYY-MM-DD'))
        setShowWarrantyEndDatePicker(false)
        clearErrors("warranty_end_date")
    }


    const strtPopover = () => {
        return <>
            <Overlay show={showWarrantyStartDatePicker} target={targetForWarrantyStartDatePicker} placement="bottom-start">
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerSingleDateController
                            date={wStartDate}
                            onDateChange={onStartDatesChange}
                            numberOfMonths={1}
                            focused={focusedForWarrantyStartDate}
                            onFocusChange={(e) => {
                                setFocusedForWarrantyStartDate(e.focused)
                            }}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "warranty_start_date") {
                                    setShowWarrantyStartDatePicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }


    const endPopover = () => {
        return <>
            <Overlay show={showWarrantyEndDatePicker} target={targetForWarrantyEndDatePicker} placement="bottom-start">
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerSingleDateController
                            date={wEndDate}
                            onDateChange={onEndDatesChange}
                            numberOfMonths={1}
                            focused={focusedForWarrantyEndDate}
                            onFocusChange={(e) => {
                                setFocusedForWarrantyEndDate(e.focused)
                            }}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "warranty_end_date") {
                                    setShowWarrantyEndDatePicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }

    const onSubmit=(data, e)=>{
        let payload = {
            "entity_type": "battery",
            "number_of_battery": data.number_of_battery,
            "nameplate_capacity": data.nameplate_capacity,
            "reference_capacity": data.reference_capacity,
            "claim_reference_capacity": data.claim_reference_capacity,
            "nameplate_voltage": nominalVoltage.value,
            "guaranteed_discharge_delivery": data.guaranteed_discharge_delivery,
            "warranty_start_date": data.warranty_start_date,
            "warranty_end_date": data.warranty_end_date,
            "battery_vendor_guid": batteryVendor.pk,
            "battery_model_guid": batteryModel.pk,
            "bms_vendor_guid": bmsVendor.pk,
            "bms_model_guid": bmsModel.pk,
            "battery_vendor": batteryVendor.name,
            "battery_model": batteryModel.model_name,
            "bms_vendor": bmsVendor.name,
            "bms_model": bmsModel.model_name,
            "created_by": props.user.user_guid,
            "created_by_name": props.user.name
        }
        GENERATE_BATTERY_BATCH(payload)
    }

    const uploadCsv=(payload)=>{
        const file_csv = payload.csv_file[0]
        const reader = new FileReader();

        reader.onload = function (event) {
            let batteries = csvToArray(event.target.result);
            // Excluding rows which don't have serial_number
            setBatteries(batteries.filter(item => !!item.serial_number))
            setShowTable(true)
        };
        reader.readAsText(file_csv)
    }

    const updateBatteries = () => {
        const payload = batteries.reduce((temp, item) => {
            const checker = Object.keys(item).some((k) => {
                return item[k] && k !== "tableData" ? true : false
            })
            if (!checker) return temp
            const item_temp = {
                ...item,
                battery_cost: item.battery_cost ? parseFloat(item.battery_cost) : 0,
                battery_cost_including_dongle: item.battery_cost_including_dongle ? parseFloat(item.battery_cost_including_dongle) : 0,
            }
            delete item_temp.tableData
            temp.push(item_temp)
            return temp
        }, [])
        UPDATE_BATCH_BATTERIES(payload)
    }

    const downloadAsCsv=(targetArray, file_name="Generated_batteries.csv") => {
        const generatedBatteries = targetArray.map((item)=>{
            return {
                serial_number : item.serial_number,
                nameplate_voltage : item.nameplate_voltage,
                nameplate_capacity : item.nameplate_capacity,
                reference_capacity : item.reference_capacity,
                claim_reference_capacity: item.claim_reference_capacity,
                guaranteed_discharge_delivery: item.guaranteed_discharge_delivery,
                warranty_start_date: item.warranty_start_date,
                warranty_end_date: item.warranty_end_date,
                bms_vendor: item.bms_vendor,
                bms_model: item.bms_model,
                battery_vendor: item.battery_vendor,
                battery_model: item.battery_model,
                manufacturer_identification_number: item.manufacturer_identification_number,
                battery_cost: item.battery_cost,
                battery_cost_including_dongle: item.battery_cost_including_dongle,

            }
        });

        const keys = generatedBatteries.length> 0? Object.keys(generatedBatteries[0]): null;

        let rows = []
        rows.push(keys);
        generatedBatteries.forEach((item)=>{
            rows.push(Object.values(item))
        })


        rows.forEach(function(rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\n";
        });
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        // window.open(encodedUri);

        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${file_name}_${new DateTimeFormat(new Date()).localDisplayWithoutTime}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click();

    }

    const submit_csv_form =()=>{
        return <>
            <div className={"col-md-12 mb-12 no-padding"}>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>
                            {language === 'EN'? 'Upload Batteries CSV':'ব্যাটারী সম্পর্কিত সিএসভি আপলোড করুন'}
                        </b></h1>
                    </Toolbar.Title>
                </Toolbar>
            </div>
            <Form onSubmit={handleSubmit(uploadCsv)}>

                {<div className={'row g-3'}>
                    <div className={"col-md-6"}>
                        <Form.Group>
                            <Form.Control
                                type="file"
                                name={'csv_file'}
                                ref={register({
                                    required: language === 'EN'? 'Required':'ফাইলটি দিতে হবে!',
                                    validate: {
                                        isCsv: value => value[0].type === 'text/csv' || wrongFileTypeMessage
                                    }
                                })}
                            />
                            {errors.csv_file && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.csv_file.message}</div>}
                        </Form.Group>
                    </div>
                </div>}




                <div className={'row'}>
                    <div className={"col-md-12 no-padding"}>
                        <Button variant="warning" type="submit" className={"ml-3"} disabled={props.buttonLoading}>
                            {language === "EN"? "Upload":"আপলোড করুন"}
                        </Button>
                    </div>
                </div>
            </Form>

            {showTable? renderTable(): null}

        </>
    }

    const generate_serial_number_form =()=>{
        return <>
            <div className={"col-md-12 mb-12 no-padding"}>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>
                            {language === "EN"? "Generate Batteries":"ব্যাটারীর ক্রমিক নাম্বার উৎপন্ন করুন"}
                        </b></h1>
                    </Toolbar.Title>
                </Toolbar>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>

                <div className={"row"}>

                    <div className={"col-md-6"}>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>Number of Battery<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type="number"
                                        name={"number_of_battery"}
                                        max={10}
                                        min={1}
                                        step={1}
                                        // value={version}
                                        ref={register({
                                            required: language === 'EN'? 'Required':'উপরের তথ্যটি আবশ্যক!'
                                        })}
                                        placeholder={language === "EN"? "Enter Number of Battery":"ব্যাটারী এর সংখ্যা দিন"}
                                        autoComplete={"off"}

                                    />
                                    {errors.number_of_battery && errors.number_of_battery.type === "maxLength" && <div className="text-danger">Max length exceeded! You can generate 99 batteries at a single time.</div> }
                                    {errors.number_of_battery && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.number_of_battery.message}</div>}
                                </Form.Group>
                            </div>
                        </div>


                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === "EN"? "Nameplate Capacity":"বিদ্যুৎ"} (Ah)<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type="number"
                                        name={"nameplate_capacity"}
                                        defaultValue={100}
                                        // value={version}
                                        ref={register({
                                            required: language === 'EN'? 'Required':'উপরের তথ্যটি আবশ্যক!',
                                        })}
                                        placeholder={language === "EN"? "Enter Nameplate Capacity value":"বিদ্যুতের পরিমাণ উল্লেখ করুন"}
                                        autoComplete={"off"}
                                    />
                                    {errors.nameplate_capacity && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.nameplate_capacity.message}</div>}

                                </Form.Group>
                            </div>
                        </div>


                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === "EN"? "Reference capacity by supplier (Ah)":"Reference capacity by supplier"}<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type="number"
                                        name={"reference_capacity"}
                                        // value={version}
                                        ref={register({
                                            required: language === 'EN'? 'Required':'উপরের তথ্যটি আবশ্যক!',
                                        })}
                                        placeholder={language === "EN"? "Enter Reference capacity by supplier":"Enter Reference capacity by supplier"}
                                        autoComplete={"off"}
                                    />
                                    {errors.reference_capacity && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.reference_capacity.message}</div>}

                                </Form.Group>
                            </div>
                        </div>



                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === "EN"? "Claim event reference capacity level (%)":"Claim event reference capacity level (%)"}<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type="number"
                                        name={"claim_reference_capacity"}
                                        defaultValue={80}
                                        // value={version}
                                        ref={register({
                                            required: language === 'EN'? 'Required':'উপরের তথ্যটি আবশ্যক!',
                                        })}
                                        placeholder={language === "EN"? "Enter Claim event reference capacity level (%)":"Enter Claim event reference capacity level (%)"}
                                        autoComplete={"off"}
                                    />
                                    {errors.claim_reference_capacity && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.claim_reference_capacity.message}</div>}

                                </Form.Group>
                            </div>
                        </div>




                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === "EN"? "BMS vendor":"বিএমএস বিক্রেতা"}<span className="required text-danger">*</span></Form.Label>
                                    </div>

                                    <Controller
                                        control={control}
                                        name={"bmsVendor"}
                                        rules= {{ required: generalErrorMessage }}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                name={`bmsVendor`}
                                                placeholder={language === 'EN'? 'Select a BMS vendor':'বিএমএস বিক্রেতা বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.bmsVendorListDropDownLoading}
                                                isLoading={props.bmsVendorListDropDownLoading}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={bmsVendors}
                                                isSearchable={true}
                                                onChange={(selected, {action}) => {
                                                    if (action === "clear") {
                                                        setBmsVendor("")
                                                    }
                                                    onChange(selected)
                                                    if (selected) {
                                                        setBmsVendor(selected)
                                                    } else {
                                                        setBmsVendor("")
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                    {errors.bmsVendor && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.bmsVendor.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === "EN"? "BMS model":"বিএমএস মডেল"}<span className="required text-danger">*</span></Form.Label>
                                    </div>

                                    <Controller
                                        control={control}
                                        name={"bmsModel"}
                                        rules= {{ required: generalErrorMessage }}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                name={`bmsModel`}
                                                placeholder={language === 'EN'? 'Select a BMS model':'বিএমএস মডেল বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.bmsModelListDropDownLoading}
                                                isLoading={props.bmsModelListDropDownLoading}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={bmsModels}
                                                isSearchable={true}
                                                onChange={(selected, {action}) => {
                                                    if (action === "clear") {
                                                        setBmsModel("")
                                                    }
                                                    onChange(selected)
                                                    if (selected) {
                                                        setBmsModel(selected)
                                                    } else {
                                                        setBmsModel("")
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                    {errors.bmsModel && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.bmsModel.message}</div>}
                                </Form.Group>
                            </div>
                        </div>


                    </div>


                    <div className={"col-md-6"}>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === "EN"? "Battery nominal voltage (V)":"Battery nominal voltage (V)"} <span className="required text-danger">*</span></Form.Label>

                                    <Controller
                                        control={control}
                                        name={"nameplate_voltage"}
                                        rules= {{ required: generalErrorMessage }}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                name={`nameplate_voltage`}
                                                placeholder={language === 'EN'? 'Enter Battery nominal voltage (V)':'Enter Battery nominal voltage (V)'}
                                                classNamePrefix="react-select-sol-style"
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={battery_voltages}
                                                isSearchable={true}
                                                onChange={(selected, {action}) => {
                                                    if (action === "clear") {
                                                        setNominalVoltage("")
                                                    }
                                                    onChange(selected)
                                                    if (selected) {
                                                        setNominalVoltage(selected)
                                                    } else {
                                                        setNominalVoltage("")
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.nameplate_voltage && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.nameplate_voltage.message}</div>}

                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === "EN"? "Guaranteed discharge delivery (Ah)":"Guaranteed discharge delivery (Ah)"} <span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type="number"
                                        name={"guaranteed_discharge_delivery"}
                                        defaultValue={200000}
                                        ref={register({
                                            required: language === 'EN'? 'Required':'উপরের তথ্যটি আবশ্যক!',
                                        })}
                                        placeholder={language === "EN"? "Enter Guaranteed discharge delivery (Ah)":"Guaranteed discharge delivery (Ah)"}
                                        autoComplete={"off"}
                                    />
                                    {errors.guaranteed_discharge_delivery && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.guaranteed_discharge_delivery.message}</div>}

                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === "EN"? "Warranty start date (YYYY-MM-DD)":"Warranty start date (YYYY-MM-DD)"} <span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        name={'warranty_start_date'}
                                        readOnly={true}
                                        value={wStartDisplayDate}
                                        placeholder={language === 'EN' ? "Warranty start date" : "Warranty start date"}
                                        ref={register({
                                            required: generalErrorMessage
                                        })}
                                        onClick={(e) => {
                                            setTargetForWarrantyStartDatePicker(e.target)
                                            setShowWarrantyStartDatePicker(!showWarrantyStartDatePicker)
                                        }}
                                    />
                                    {errors.warranty_start_date && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.warranty_start_date.message}</div>}

                                </Form.Group>
                            </div>
                        </div>


                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <Form.Label>{language === "EN"? "Warranty end date (YYYY-MM-DD)":"Warranty end date (YYYY-MM-DD)"} <span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        name={'warranty_end_date'}
                                        readOnly={true}
                                        value={wEndDisplayDate}
                                        placeholder={language === 'EN' ? "Warranty end date" : "Warranty end date"}
                                        ref={register({
                                            required: generalErrorMessage
                                        })}
                                        onClick={(e) => {
                                            setTargetForWarrantyEndDatePicker(e.target)
                                            setShowWarrantyEndDatePicker(!showWarrantyEndDatePicker)
                                        }}
                                    />
                                    {errors.warranty_end_date && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.warranty_end_date.message}</div>}

                                </Form.Group>
                            </div>
                        </div>


                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === "EN"? "Battery Vendor":"Battery Vendor"}<span className="required text-danger">*</span></Form.Label>
                                    </div>

                                    <Controller
                                        control={control}
                                        name={"batteryVendor"}
                                        rules= {{ required: generalErrorMessage }}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                name={`batteryVendor`}
                                                placeholder={language === 'EN'? 'Select a battery vendor':'Battery Vendor বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.batteryVendorListDropDownLoading}
                                                isLoading={props.batteryVendorListDropDownLoading}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={vendors}
                                                isSearchable={true}
                                                onChange={(selected, {action}) => {
                                                    if (action === "clear") {
                                                        setSelectedVendor("")
                                                    }
                                                    onChange(selected)
                                                    if (selected) {
                                                        setSelectedVendor(selected)
                                                    } else {
                                                        setSelectedVendor("")
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                    {errors.batteryVendor && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.batteryVendor.message}</div>}

                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-10"}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>{language === "EN"? "Battery model":"ব্যাটারি মডেল"}<span className="required text-danger">*</span></Form.Label>
                                    </div>

                                    <Controller
                                        control={control}
                                        name={"batteryModel"}
                                        rules= {{ required: generalErrorMessage }}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                name={`batteryModel`}
                                                placeholder={language === 'EN'? 'Select a battery model':'ব্যাটারি মডেল বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.batteryModelListDropDownLoading}
                                                isLoading={props.batteryModelListDropDownLoading}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={batteryModels}
                                                isSearchable={true}
                                                onChange={(selected, {action}) => {
                                                    if (action === "clear") {
                                                        setBatteryModel("")
                                                    }
                                                    onChange(selected)
                                                    if (selected) {
                                                        setBatteryModel(selected)
                                                    } else {
                                                        setBatteryModel("")
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                    {errors.batteryModel && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.batteryModel.message}</div>}

                                </Form.Group>
                            </div>
                        </div>

                    </div>
                </div>


                <div className={'row'}>
                    <div className={"col-md-12 no-padding"}>
                        <Button variant="warning" type="submit" className={"ml-3"} disabled={props.buttonLoading}>
                            {props.buttonLoading===true? <><Spinner animation={'border'} size={'md'}
                                                                    variant={'light'}/> </>:null}
                            <i className='fa fa-check' aria-hidden='true'/>&nbsp;{language === "EN"? "Generate Batteries":"নিশ্চিত করুন"}
                        </Button>
                    </div>
                </div>
            </Form>
            {strtPopover()}
            {endPopover()}
        </>

    }

    const generate_batteries = () =>{
        return props.isBatteryGenerated === requestCycle.success && Array.isArray(batteries) ?
            <>
                {renderTable()}
            </>
            : <div className={"row"}>
                <div className={"col-md-12"}>
                    {generate_serial_number_form()}
                </div>
            </div>

    }

    const renderTable=()=>{
        return <>
            <div className={"row mb-12 mt-12 no-padding"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>
                                {phase==="generate"?<Button variant={'warning'} onClick={() => downloadAsCsv(props.generatedBatteries)}>{language === "EN"? "Download as Csv":"সিএসভি ফরম্যাটে ডাউনলোড করুন"}</Button>: null}
                                {phase==="update"?<Button variant={'warning'} onClick={() => updateBatteries()} disabled={props.buttonLoading}> {props.buttonLoading===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}&nbsp;{language === "EN"? "Update Batteries":"ব্যাটারীর তথ্য সম্পাদনা করুন"}</Button>: null}
                                {/*{phase==="update" &&  batteriesUpdated === requestCycle.success?<Button variant={'warning'} onClick={() => updateBatteries()}>Update</Button>: null}*/}
                                &nbsp;&nbsp;
                                {phase==="update" && rejectedBatteries.length > 0?<Button variant={'warning'} onClick={() => downloadAsCsv(rejectedBatteries, "rejected_batteries.csv")}>{props.buttonLoading===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}&nbsp;{language === "EN"? "Download Rejected Batteries":"সম্পাদনায় ব্যর্থ হওয়া ব্যাটারীর তালিকা ডাউনলোড করুন"}</Button>: null}
                            </b></h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
                <div className={"col-md-12"}>
                    <DataTableContainer>
                        <DataTable columns={battery_columns} data={batteries}>

                        </DataTable>
                    </DataTableContainer>
                </div>

            </div>
        </>
    }

    const changeAction=(e)=>{
        RETURN_TO_INITIAL_STATE()
        setPhase(e.target.value)
        if (e.target.value ==='generate'){
            GET_BATTERY_VENDOR_LIST()
        }
    }

    const battery_columns = [
        {
            field: 'serial_number',
            title: language === "EN"? "Serial Number (*)":"ক্রমিক নাম্বার (*)",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '0',
                color: 'red'
            },
            render: (value)=>{
                if (props.rejectedBatteries && Array.isArray(props.rejectedBatteries)){
                    let isRejected =  props.rejectedBatteries.some((item)=>{
                        return item.serial_number === value.serial_number
                    })
                    if (isRejected){
                        return <>
                            <b style={{color: "red"}}>{value.serial_number}</b>
                        </>
                    }else {
                        return <>
                            <b style={{color: "green"}}>{value.serial_number}</b>
                        </>
                    }

                }

                return value.serial_number
            }
            // disableClick: true,
        },
        {
            field: 'nameplate_voltage',
            title: language === "EN"? "Nameplate Voltage (V)":"নামফলকে থাকা ভোল্টেজ",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return rowData.nameplate_voltage ?`${rowData.nameplate_voltage}`:null
            }
            // disableClick: true
        },
        {
            field: 'nameplate_capacity',
            title: language === "EN"? "Nameplate Capacity (Ah)":"নামফলকে থাকা ধারণক্ষমতা",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return rowData.nameplate_capacity ?`${rowData.nameplate_capacity}`:null
            }
            // disableClick: true
        },
        {
            field: 'reference_capacity',
            title: language === "EN"? "Reference Capacity (Ah)":"রেফারেন্স ধারণক্ষমতা",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return rowData.reference_capacity ?`${rowData.reference_capacity}`: null
            }
            // disableClick: true
        },{
            field: 'claim_reference_capacity',
            title: language === "EN"? "Claim Reference Capacity (%)":"রেফারেন্স ধারণক্ষমতা",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'guaranteed_discharge_delivery',
            title: language === "EN"? "Guaranteed Discharge Delivery (Ah)":"রেফারেন্স ধারণক্ষমতা",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'warranty_start_date',
            title: language === "EN"? "Warranty Start Date":"রেফারেন্স ধারণক্ষমতা",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '0'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'warranty_end_date',
            title: language === "EN"? "Warranty End Date":"রেফারেন্স ধারণক্ষমতা",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '0'
            }
            // disableClick: true
        },
        {
            field: 'bms_vendor',
            title: language === "EN"? "BMS Vendor":"রেফারেন্স ধারণক্ষমতা",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '0'
            }
            // disableClick: true
        },
        {
            field: 'bms_model',
            title: language === "EN"? "BMS Model":"রেফারেন্স ধারণক্ষমতা",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '0'
            }
            // disableClick: true
        },
        {
            field: 'battery_vendor',
            title: language === "EN"? "Battery Vendor":"রেফারেন্স ধারণক্ষমতা",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '0'
            }
            // disableClick: true
        },
        {
            field: 'battery_model',
            title: language === "EN"? "Battery Model":"রেফারেন্স ধারণক্ষমতা",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '0'
            }
            // disableClick: true
        },
        {
            field: 'manufacturer_identification_number',
            title: language === "EN"? "Manufacturer identification number (*)":"ম্যানুফ্যাকচারার আইডেন্টিফিকেশান নাম্বার (*)",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                textAlign: 'left',
                paddingLeft: '0',
                color: 'red'
            }
            // disableClick: true
        },
        {
            field: 'battery_cost',
            title: language === "EN" ? "Battery Cost" : "রেফারেন্স ধারণক্ষমতা",
            emptyValue: () => {
                return "N/A"
            },
            cellStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            headerStyle: {
                textAlign: 'right',
                paddingRight: '0'
            }
            // disableClick: true
        },
        {
            field: 'battery_cost_including_dongle',
            title: language === "EN" ? "Battery Cost Including Dongle" : "রেফারেন্স ধারণক্ষমতা",
            emptyValue: () => {
                return "N/A"
            },
            cellStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            headerStyle: {
                textAlign: 'right',
                paddingRight: '0'
            }
            // disableClick: true
        },
    ]
    return (
        <ContentWrapper isLoading={false} showBackButton={false} showCardHeader={false} >

            <div className={'col-md-6 no-padding'}>
                <Form.Group>
                    <Form.Control as="select" size="lg" onChange={changeAction} disabled={(hasCreateAccess && !hasUpdateAccess) || (!hasCreateAccess && hasUpdateAccess)}>
                        {(hasCreateAccess && hasUpdateAccess)? <>
                            <option value={''}>{language === "EN"? "Select Action":"যা করতে চান তা বাছাই করুন"}</option>
                            <option value={'generate'}>{language === "EN"? "Generate Batteries":"ব্যাটারী উৎপন্ন করুন"}</option>
                            <option value={'update'}>{language === "EN"? "Update Batteries":"ব্যাটারী সম্পাদনা করুন"}</option>
                        </>:hasCreateAccess? <option value={'generate'} selected>{language === "EN"? "Generate Batteries":"ব্যাটারী উৎপন্ন করুন"}</option>:
                            <option value={'update'} selected>{language === "EN"? "Update Batteries":"ব্যাটারী সম্পাদনা করুন"}</option>}
                    </Form.Control>
                    <br />
                </Form.Group>
            </div>
            {phase==='update'?submit_csv_form():null}
            {phase==='generate'?generate_batteries():null}
        </ContentWrapper>
    );
};

BatchBattery.propTypes = {

};

export default connect((state) => {
    return {
        user: state.auth.user,
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.batteryReducer.isLoading,
        listLoading: state.batteryReducer.listLoading,
        batteryModelListDropDownLoading: state.batteryReducer.batteryModelListDropDownLoading,
        batteryModels: state.batteryReducer.batteryModels,
        batteryVendorListDropDownLoading: state.batteryReducer.batteryVendorListDropDownLoading,
        batteryVendors: state.batteryReducer.batteryVendors,
        bmsVendorListDropDownLoading: state.batteryReducer.bmsVendorListDropDownLoading,
        bmsVendors: state.batteryReducer.bmsVendors,
        bmsModelListDropDownLoading: state.batteryReducer.bmsModelListDropDownLoading,
        bmsModels: state.batteryReducer.bmsModels,
        generatedBatteries: state.batteryReducer.generatedBatteries,
        isBatteryGenerated: state.batteryReducer.batteryGenerated,
        batteriesUpdated: state.batteryReducer.batteriesUpdated,
        updatedBatteries: state.batteryReducer.updatedBatteries,
        rejectedBatteries: state.batteryReducer.rejectedBatteries,
        buttonLoading: state.batteryReducer.buttonLoading,
    }
}, {...actions}) (BatchBattery);

