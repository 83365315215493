import {Redirect, Switch} from "react-router-dom";
import React from "react";
import Financiers from "./views/financiers/financiers";
import FinancierCreation from "./views/financiers/financierCreation";
import BranchCreation from "./views/branches/branchCreation";
import SecuredRoute from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const FinancierRouter = (props) => {

    return (
        <Switch>
            <Redirect
                exact={"true"}
                from={"/financiers"}
                to={"/financiers/list"}
            />

            {/* Routes to financiers List*/}
            <SecuredRoute
                path={"/financiers/list"}
                component={Financiers}
                service={getServiceName('userService')}
                feature={getFeatureName('financierFeature')}
                action={getActionName('listAction')}
            />

            {/* Routes to financier create*/}
            <SecuredRoute
                path={"/financiers/new"}
                component={FinancierCreation}
                service={getServiceName('userService')}
                feature={getFeatureName('financierFeature')}
                action={getActionName('createAction')}
            />

            {/* Routes to financier edit*/}
            <SecuredRoute
                path={"/financiers/edit"}
                component={FinancierCreation}
                service={getServiceName('userService')}
                feature={getFeatureName('financierFeature')}
                action={getActionName('updateAction')}
            />

            {/* Routes to branch create*/}
            <SecuredRoute
                path={"/financiers/new-branch"}
                component={BranchCreation}
                service={getServiceName('userService')}
                feature={getFeatureName('financierFeature')}
                action={getActionName('updateAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
