import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Breadcrumb, Button, Form, Spinner, Badge} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner} from '../../utils'
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable'
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import moment from "moment";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../../auth/authorization";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const DriverList = (props) => {
    const [showFilter, setShowFilter] = useState(false);
    const [filterCriteria, setFilterCriteria] = useState(false);
    const [showFilterCriteriaMandatoryMessage, setShowFilterCriteriaMandatoryMessage] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [textFieldLabel, setTextFieldLabel] = useState(defaultTextFieldLabel);
    const [textFieldPlaceholder, setTextFieldPlaceholder] = useState(defaultTextFieldPlaceholder);
    const [requiredErrorMessage, setRequiredErrorMessage] = useState(false);
    const [numberValidationMessage, setNumberValidationMessage] = useState(false);
    const [phoneNumberIsInvalid, setPhoneNumberIsInvalid] = useState(false);

    const [driverList, setDriverList] = useState([]);
    const [page, setPage] = useState(1);
    const [gridOwnersList, setGridOwnersList] = useState([]);
    const [filterParameters, setFilterParameters] = useState(null);
    const [filterApplied, setFilterApplied] = useState(false);
    const subHeader = useSubheader();
    const [projectedParams, setProjectedParams] = useState(null);
    const [authorization, setAuthorization] = useState(null);
    const [language, setLanguage] = useState('EN');
    const [driverServiceName, setDriverServiceName] = useState(getServiceName('driverService'));
    const [driverFeatureName, setDriveratureName] = useState(getFeatureName('driverFeature'));
    const [createActionName, setCreateActionName] = useState(getActionName('createAction'));
    const [updateActionName, setUpdateActionName] = useState(getActionName('updateAction'));

    const filterCriteriaMandatoryMessage = language === 'EN'? 'Please select a filter criteria first!':'দয়া করে আগে একটি মানদণ্ড বাছাই করুন!'
    const phoneNumberFieldLabel = language === 'EN'? 'Phone Number':'ফোন নাম্বার'
    const phoneNumberFieldPlaceHolder = language === 'EN'? 'Provide phone number. Ex: 01676366473':'ফোন নম্বর লিখুন। উদাহরণঃ 01676366473'
    const generalRequiredMessage = language === 'EN'? 'Please provide above information!':'দয়া করে উপরের তথ্যটি দিন!'
    const defaultTextFieldLabel = language === 'EN'? 'Filter value':'মান'
    const defaultTextFieldPlaceholder = language === 'EN'? 'Provide a value to filter the list':'তালিকায় চালক খোঁজার জন্য একটি মান লিখুন'

    const driverListColumns = [
        {
            field: 'name',
            title: language == 'EN'? 'Name': 'নাম',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
        },
        {
            field: 'name_bangla',
            title: language == 'EN'? 'Name(Bangla)': 'বাংলা নাম',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
        },
        {
            field: 'phone',
            title: language == 'EN'? 'Phone': 'ফোন',
            emptyValue: ()=>{
                return "N/A"
            },
            disableClick: true,
        },
        {
            field: 'rent_per_day',
            title: language == 'EN'? 'Rent per Day (BDT)': 'প্রতিদিনের ভাড়া (টাকা)',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            disableClick: true
        },
        {
            field: 'address',
            title: language == 'EN'? 'Address': 'ঠিকানা',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            disableClick: true,
        },
        {
            field: 'registrationDate',
            title: language == 'EN'? 'Registration Date': 'নিবন্ধনের তারিখ',
            emptyValue: ()=>{
                return "N/A"
            },
            disableClick: true
        }
    ]

    const {
        GET_DRIVER_LIST
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setAuthorization(props.authorization)
        setLanguage(props.language)
        GET_DRIVER_LIST({page: page});
        subHeader.setActionButtons(null);

        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
        }
    }, []);

    useEffect(() => {
        setFilterCriteria(filterCriteriaOptions[0])
        setTextFieldLabel(phoneNumberFieldLabel)
        setTextFieldPlaceholder(phoneNumberFieldPlaceHolder)
    }, [language]);

    useEffect(() => {
        if (props.driverList) {
            const driverList = props.driverList.results;
            if (driverList && driverList.length > -1) {
                setDriverList(driverList.map((driver, index) => {
                    let address = ''
                    // Following checking has been done as old data don't have any village or upazila
                    if (driver.village && driver.upazila && driver.district) {
                        address = driver.village + ', ' + driver.upazila + ', ' + driver.district
                    } else if (!driver.village && driver.upazila && driver.district) {
                        address = driver.upazila + ', ' + driver.district
                    } else {
                        address = driver.district
                    }
                    let dailyIncome = new Intl.NumberFormat('en-IN', {currency: 'BDT'}).format(driver.daily_income)
                    return {
                        ...driver,
                        name: driver.name,
                        phone: driver.phone_number,
                        address: address,
                        vehicleType: driver.type_of_vehicle,
                        dailyIncome: dailyIncome,
                        registrationDate: moment(driver.registration_date).format("DD MMMM,YYYY hh:mm a")
                    }
                }))
            }
        }
    }, [props.driverList]);

    const filterCriteriaOptions = [
        {
            'label': language === 'EN'? 'Phone': 'ফোন',
            'value': 'phone_number'
        }
    ]

    const clearAllErrorMessages = () => {
        setShowFilterCriteriaMandatoryMessage(false)
        setRequiredErrorMessage(false)
        setNumberValidationMessage(false)
        setPhoneNumberIsInvalid(false)
    }

    const resetFilter = () => {
        if (filterApplied) {
            // Resetting the driver list
            setPage(1)
            GET_DRIVER_LIST({page: 1})
        }
        setTextFieldLabel(defaultTextFieldLabel)
        setTextFieldPlaceholder(defaultTextFieldPlaceholder)
        resetFilterValues()
        setFilterApplied(false)
        clearAllErrorMessages()
    }

    const resetFilterValues = () => {
        setFilterCriteria(null)
        setSearchText("")
    }

    const filterTheList = () => {
        if (!filterCriteria || !searchText) {
            setRequiredErrorMessage(true)
        } else {
            setRequiredErrorMessage(false)
            if (filterCriteria.value === 'phone_number' && searchText.length !== 11) {
                setPhoneNumberIsInvalid(true)
            } else {
                setPhoneNumberIsInvalid(false)
                let filter = {page: 1}
                filter[filterCriteria.value] = searchText
                GET_DRIVER_LIST(filter)
                setFilterApplied(true)
            }
        }
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        if (filterCriteria && searchText) {
            let filter = {page: newPage}
            filter[filterCriteria.value] = searchText
            GET_DRIVER_LIST(filter)
        } else {
            props.GET_DRIVER_LIST({page: newPage});
        }
    }

    const gotToCreateDriver = () => {
        props.history.push({
            pathname: '/drivers/create',
            purpose: 'create'
        })
    }

    const goToUpdateDriver = (rowData) => {
        props.history.push({
            pathname: '/drivers/edit',
            purpose: 'edit',
            driverData: rowData
        })
    }

    return (
        <div>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Drivers"}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{language === 'EN'? 'Drivers':'চালকদের তালিকা'}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                    {/* {checkAuthorization(authorization, driverServiceName, driverFeatureName, [createActionName])? <>
                                        <Button variant="warning" type="button" size={'sm'} style={{
                                            backgroundColor: '#F18D00 !important',
                                        }} onClick={gotToCreateDriver} classes={classes} >
                                            {language === 'EN'? <i className="fa fa-plus" aria-hidden="true"></i>:null}
                                            {language === 'EN'? 'Register Driver':'চালক নিবন্ধন করুন'}
                                        </Button>
                                    </>:null} */}
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form>
                                    <div className={'row g-3'}>
                                        <div className={"col-md-6"}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Filter criteria':'তালিকায় চালক খোঁজার মানদণ্ড'}<span className="required text-danger">*</span></Form.Label>
                                                </div>
                                                <Select
                                                    name={`filterCriteria`}
                                                    placeholder={language === 'EN'? 'Select a filter criteria':'একটি মানদণ্ড বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.filterDropdownLoading}
                                                    isLoading={props.filterDropdownLoading}
                                                    maxMenuHeight={200}
                                                    value={filterCriteria}
                                                    isClearable={true}
                                                    options={filterCriteriaOptions}
                                                    isSearchable={true}
                                                    onChange={(selected) => {
                                                        setFilterCriteria(selected)
                                                        clearAllErrorMessages()
                                                        if (selected) {
                                                            setSearchText("")
                                                            setTextFieldLabel(phoneNumberFieldLabel)
                                                            setTextFieldPlaceholder(phoneNumberFieldPlaceHolder)
                                                        } else {
                                                            setTextFieldLabel(defaultTextFieldLabel)
                                                            setTextFieldPlaceholder(defaultTextFieldPlaceholder)
                                                            setSearchText("")
                                                        }
                                                    }}
                                                />
                                                {(requiredErrorMessage && !filterCriteria)? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}
                                            </Form.Group>
                                        </div>
                                        <div className={"col-md-6"}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{textFieldLabel}<span className="required text-danger">*</span></Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'searchText'}
                                                    type="text"
                                                    placeholder={textFieldPlaceholder}
                                                    value={searchText}
                                                    autoComplete={"off"}
                                                    onChange = {(e) => {
                                                        if (!filterCriteria) {
                                                            setShowFilterCriteriaMandatoryMessage(true)
                                                        } else {
                                                            if (e.target.value) {
                                                                let value = e.target.value;
                                                                setPhoneNumberIsInvalid(false)
                                                                setRequiredErrorMessage(false)
                                                                if (isNaN(value) || value.includes('.')) {
                                                                    setNumberValidationMessage(true)
                                                                } else {
                                                                    setNumberValidationMessage(false)
                                                                    setSearchText(value)
                                                                }
                                                            } else {
                                                                setNumberValidationMessage(false)
                                                                setSearchText("")
                                                            }
                                                        }
                                                    }}
                                                />
                                                {showFilterCriteriaMandatoryMessage? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{filterCriteriaMandatoryMessage}</div></>:null}
                                                {requiredErrorMessage? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}
                                                {numberValidationMessage? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Please provide only numbers!':'দয়া করে শুধু সংখ্যা লিখুন!'}</div></>:null}
                                                {phoneNumberIsInvalid? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Phone number must be of 11 digits!':'ফোন নাম্বার অবশ্যই ১১ সংখ্যার হতে হবে!'}</div></>:null}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}}>
                                                {language === 'EN'? 'Reset filter':'পুনরায় মানদণ্ড বাছাই করুন'}
                                            </Button>
                                            <Button variant="warning" size={'sm'} type="button"
                                                    disabled={(numberValidationMessage || requiredErrorMessage)? true:false}
                                                    onClick={()=> {filterTheList()}} className={'ml-3'}>
                                                {props.deviceInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}
                                                {language === 'EN'? 'Apply filter':'চালক খুঁজুন'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}

                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {props.listLoading !== true ? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন চালক নেই'}
                                columns={driverListColumns}
                                data={driverList}
                                showToolbar={false}
                                asyncPagination={true}
                                isLoading={props.tableLoading}
                                count={props.driverList?.count}
                                itemsPerPage={props.driverList?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                                actionColumnIndex={-1}
                                // actions={checkAuthorization(authorization, driverServiceName, driverFeatureName, [updateActionName])? [{}]:[]}
                                actionButtonVariant={['warning']}
                                actionButtonSize={'sm'}
                                actionButtonClickEvent={[goToUpdateDriver]}
                                actionButtonText={language === 'EN'? ['Update']:['তথ্য সম্পাদনা করুন']}
                            /> : <LoadingSpinner loadingSubText={language == 'EN'? 'Generating driver list ..': 'চালকদের তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

DriverList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.driversReducer.isLoading,
        tableLoading: state.driversReducer.tableLoading,
        statusCode: state.driversReducer.statusCode,
        driverList: state.driversReducer.driverList,
        gridOwnerList: state.driversReducer.gridOwnerList,
        filterTriggeredLoading: state.driversReducer.filterTriggeredLoading,
        listLoading: state.driversReducer.listLoading,
        iconLoading: state.driversReducer.iconLoading
    }
}

export default connect(mapStateToProps, actions)(withRoles(DriverList));
