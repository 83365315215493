import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
    successMessage: undefined,
    errorMessage: undefined,
    errorInVerification: undefined,
    driverInfoSubmitting: undefined
};

/*Reducer for Driver's redux store*/
export const driversReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_DRIVER_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                driverList: undefined,
                phoneVerified: undefined,
                errorInVerification: undefined,
                successMessage: undefined,
                errorMessage: undefined,
                driverCreated: undefined,
                driverUpdated: undefined,
                successMessageForEdit: undefined,
                errorMessageForEdit: undefined,
            };
        case actionTypes.GET_DRIVER_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                driverList: action.payload
            };
        case actionTypes.GET_DRIVER_LIST_FAILURE:
            toast.error("Can't load the driver list! Please contact with admin.")
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.COLLECT_DISTRICT_LIST_REQUEST:
            return {
                ...state,
                districtListLoading: true
            };
        case actionTypes.COLLECT_DISTRICT_LIST_SUCCESS:
            return {
                ...state,
                districtListLoading: false,
                districts: action.payload
            };
        case actionTypes.COLLECT_DISTRICT_LIST_FAILURE:
            toast.error("Can't load the district options!");
            return {
                ...state,
                districtListLoading: false
            };

        case actionTypes.COLLECT_UPAZILA_LIST_REQUEST:
            return {
                ...state,
                upazilaListLoading: true,
                disableUpazilaSelection: true
            };
        case actionTypes.COLLECT_UPAZILA_LIST_SUCCESS:
            return {
                ...state,
                upazilaListLoading: false,
                disableUpazilaSelection: false,
                upazilas: action.payload
            };
        case actionTypes.COLLECT_UPAZILA_LIST_FAILURE:
            toast.error("Can't load the upazilla options!");
            return {
                ...state,
                upazilaListLoading: false,
                disableUpazilaSelection: true
            };

        case actionTypes.VERIFY_PHONE_REQUEST:
            return {
                ...state,
                phoneVerified: undefined,
                phoneNumberVerifying: true
            };
        case actionTypes.VERIFY_PHONE_SUCCESS:
            return {
                ...state,
                phoneVerified: requestCycle.success,
                phoneNumberVerifying: false
            };
        case actionTypes.VERIFY_PHONE_FAILURE:
            return {
                ...state,
                phoneVerified: requestCycle.failed,
                errorInVerification: action.payload.error,
                phoneNumberVerifying: false
            };

        case actionTypes.CREATE_DRIVER_REQUEST:
            return {
                ...state,
                driverCreated: undefined,
                driverInfoSubmitting: true
            };
        case actionTypes.CREATE_DRIVER_SUCCESS:
            return {
                ...state,
                driverCreated: requestCycle.success,
                successMessage: action.payload,
                driverInfoSubmitting: false
            };
        case actionTypes.CREATE_DRIVER_FAILURE:
            return {
                ...state,
                driverCreated: requestCycle.failed,
                errorMessage: action.payload.error,
                driverInfoSubmitting: false
            };

        case actionTypes.EDIT_DRIVER_REQUEST:
            return {
                ...state,
                driverUpdated: undefined,
                driverInfoSubmitting: true
            };
        case actionTypes.EDIT_DRIVER_SUCCESS:
            return {
                ...state,
                driverUpdated: requestCycle.success,
                successMessageForEdit: action.payload,
                driverInfoSubmitting: false
            };
        case actionTypes.EDIT_DRIVER_FAILURE:
            return {
                ...state,
                driverUpdated: requestCycle.failed,
                errorMessageForEdit: action.payload.error,
                driverInfoSubmitting: false
            };
        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState;

        default:
            return state;
    }
};
