import React, { useEffect, useState } from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import { FilterButton, Toolbar } from "../../../../components";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DataTable, DataTableContainer, FilterInput, FilterReactSelect } from "../../../../components";
import { transactionActions } from "../../actions";
import { actions as commonActions } from '../../../commonReduxActions/actions';
import { LoadingSpinner } from '../../../../components/LoadingSpinnerForDataTable'
import { connect } from "react-redux";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import { Button, Form, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { theme, useStylesForDatePicker } from "../../../../utils/muiPickerCustomStyle";
import moment from "moment";
import 'moment/locale/bn';
import 'moment/locale/en-gb';
import { toast } from "react-toastify";
import { DayPickerRangeController } from "react-dates";
import { SolTypography } from '../../../../components/utilityComponents/SOlTypography';
import { registerLocale } from 'react-datepicker';
import { da } from 'date-fns/locale';
import {toBengaliNumber} from "bengali-number";
import {exportInExcel} from "../../../../utils/excelExport";
import {convertToAnotherLang} from "../../../../utils/convertLanguageOfNumbersWithCharacter";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import {useSubheader} from "../../../../../_metronic/layout";


const MobileWalletList = props => {
    const classes = useStylesForDatePicker();
    const { clearErrors, setError, setValue, reset, handleSubmit, control, errors, register } = useForm()
    const [language, setLanguage] = useState("EN");
    const [showFilter, setShowFilter] = useState(true);
    const [filterApplied, setFilterApplied] = useState(false);
    const [dateRangeChanged, setDateRangeChanged] = useState(false);
    const [filterData, setFilterData] = useState({});
    const subHeader = useSubheader()

    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [serialNumberNotNumericMessage, setSerialNumberNotNumericMessage] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [walletTransactions, setWalletTransactions] = useState([]);
    const [walletTransactionsForExport, setWalletTransactionsForExport] = useState(undefined);
    const [borrowers, setBorrowers] = useState([]);
    const [garages, setGarages] = useState(undefined);

    // Tracking filter values
    const [mobileWallet, setMobileWallet] = useState('');
    const [borrower, setBorrower] = useState('');


    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [page, setPage] = useState(1);
    const defaultFilterParam = {
        page: 1
    }


    const exportData = (data) => {
        let presentTime = moment()
        let dataLength = data.length
        let totalTransactionText = "Total Transactions: " + dataLength
        let exportText = "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A")
        let excelRows = [
            ["Mobile Wallet Transactions", "", "", "", "", "", "", "", "", ""],
            [totalTransactionText, "", "", "", "", "", "", "", "", ""],
            [exportText, "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", ""],
            ["TrxID", "Date", "Time", "From (Name)", "From (Phone)", "From (Garage)", "To (Merchant Account)", "Method",
                "Reference", "Amount"]
        ]
        if (filterApplied) {
            if (borrower) {
                let text = "Borrower: " + borrower.label
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
            if (start && end) {
                let from_date = moment(start).format("MMM DD, YYYY")
                let end_date = moment(end).format("MMM DD, YYYY")
                let text = "Transaction Date: " + from_date + " - " + end_date
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
            if (mobileWallet) {
                let text = "Mobile Wallet: " + mobileWallet
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
        }
        for (let i=0; i<dataLength; i++) {
            excelRows.push(
                [
                    data[i].pk,
                    data[i].created_at ? moment(data[i].created_at).format("MM/DD/YY") : '',
                    data[i].created_at ? moment(data[i].created_at).format("hh:mm A") : '',
                    data[i].user_name,
                    data[i].sender,
                    data[i].garageName,
                    data[i].payment_source === 'bkash_checkout'? '01759407944':'01708458778',
                    'bKash',
                    data[i].reference,
                    data[i].pay_amount
                ])
        }
        let noDataAvailableText =  "No data available"
        dataLength > 0? excelRows.push(
            ["", "", "", "", "", "", "", "", "", ""]
        ):excelRows.push(["", "", "", "", noDataAvailableText, "", "", "", "", ""])
        let fileName = 'mobile_wallet_'
        exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
    }

    const transactionColumn = [
        {
            field: 'pk',
            title: "TrxID",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return "-"
            }
        },
        {
            field: 'created_at',
            title: "Date",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return "N/A"
            },
            render: (rowdata) => {
                return rowdata.created_at ? new DateTimeFormat(rowdata.created_at).localDisplayTime : ''
            }
        },
        {
            field: 'sender',
            title: "From",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            width: '2.5rem',
            emptyValue: () => {
                return "N/A"
            },
            render: (rowdata) => {
                return <>
                    <b><SolTypography.SubTitle className={"mb-5"}>{rowdata.user_name}</SolTypography.SubTitle></b>
                    <SolTypography.SubTitle>{rowdata.sender}</SolTypography.SubTitle>
                    <SolTypography.Text secondary>{rowdata.garageName}</SolTypography.Text>
                </>
            }
        },
        {
            field: 'receiver',
            title: "To",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            width: '2.5rem',
            emptyValue: () => {
                return "N/A"
            },
            render: (rowdata) => {
                return <>
                    <SolTypography.SubTitle>{rowdata.payment_source === 'bkash_checkout'? '01759407944':'01708458778'}</SolTypography.SubTitle>
                </>
            }
        },
        {
            field: 'payment_source',
            title: "Method",
            emptyValue: () => {
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            // disableClick: true,
            render: (rowdata) => {
                return <>
                    <b><SolTypography.SubTitle>bKash</SolTypography.SubTitle></b>
                </>
            }
        },
        {
            field: 'reference',
            title: "Reference",
            emptyValue: () => {
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowdata) => {
                return <>
                    <b><SolTypography.SubTitle>bKash#{rowdata.reference}</SolTypography.SubTitle></b>
                </>
            }
            // disableClick: true,
        },
        {
            field: 'pay_amount',
            title: "Amount (Tk)",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: () => {
                return "N/A"
            },
            render: (rowdata) => {
                return <>{new Intl.NumberFormat('en-IN').format(rowdata.pay_amount)}</>
            }
        }
    ]

    const {
        FETCH_MOBILE_WALLET_PAYMENTS,
        FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT,
        GET_GARAGE_LIST,
        COLLECT_BORROWERS,
        RETURN_TO_INITIAL_STATE,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER
    } = props


    const collectLastSevenDaysData =  () => {
        setPage(1)
        let filter = {}
        let todaysDate = moment().format("YYYY-MM-DD")
        let sixDaysEarlier = moment(todaysDate).subtract(6, 'd').format("YYYY-MM-DD")
        setStartDate(moment(sixDaysEarlier))
        setEndDate(moment(todaysDate))
        let from_date = props.language === 'EN'? sixDaysEarlier:convertToAnotherLang(sixDaysEarlier)
        let to_date = props.language === 'EN'? todaysDate:convertToAnotherLang(todaysDate)
        filter['from_date'] =  from_date
        filter['to_date'] =  to_date
        setFilterData(filter)
        setFilterApplied(true)
        FETCH_MOBILE_WALLET_PAYMENTS({...defaultFilterParam, ...filter})
        GET_GARAGE_LIST()
    }

    useEffect(() => {
        setLanguage(props.language)
        // TODO: Clear the header
        subHeader.setBreadCrumbComponent(null)
        subHeader.setActionButtons(null)
        collectLastSevenDaysData()
        COLLECT_BORROWERS()
        return () => {
            RETURN_TO_INITIAL_STATE()
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
        }
    }, [])

    useEffect(() => {
        if (props.garages && props.garages.length > -1) {
            const garages = props.garages
            setGarages(garages.map((garage) => {
                return {
                    ...garage
                }
            }))
        }
    }, [props.garages])

    useEffect(() => {
        if (props.borrowers && props.borrowers.length > -1) {
            const borrowers = props.borrowers
            setBorrowers(borrowers.map((item) => {
                let label = item.name
                let garageName = item.garage_name
                if (garages && garages.length > -1) {
                    let filteredData = garages.filter(garage => garage.pk === item.garage_guids[0])
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                    }
                }
                label += ' (' + garageName + ')'

                if (borrower && borrower.value === item.garage_guids[0]) {
                    let selectedBorrower = borrower
                    selectedBorrower['label'] = label
                    selectedBorrower['value'] = item.garage_guids[0]
                    setValue('borrower', selectedBorrower)
                    setBorrower(selectedBorrower)
                }

                return {
                    ...item,
                    label: label,
                    value: item.garage_guids[0]
                }
            }))
        }
    }, [props.borrowers, garages])

    useEffect(() => {
        if (props.mobileWalletTransactions) {
            setTotalAmount(props.mobileWalletTransactions.recharge_total)
            let payments = props.mobileWalletTransactions.results
            if (payments && payments.length > -1) {
                setWalletTransactions(payments.map((payment) => {
                    let garageName = payment.garage_name
                    if (garages && garages.length > -1) {
                        let filteredData = garages.filter(garage => garage.pk === payment.garage_guid)
                        if (filteredData.length > 0) {
                            garageName = filteredData[0].name
                        }
                    }
                    return {
                        ...payment,
                        garageName: garageName
                    }
                }))
            }
        }
    }, [props.mobileWalletTransactions, garages])

    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    }, [start, end])

    const resetFilterValues = () => {
        setStartDate(null)
        setEndDate(null)
        setBorrower("")
        reset({
            "sender": "",
            "borrower": ""
        })
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        if (filterApplied) {
            FETCH_MOBILE_WALLET_PAYMENTS({page: newPage, ...filterData})
        } else {
            FETCH_MOBILE_WALLET_PAYMENTS({page: newPage})
        }
        GET_GARAGE_LIST()
    }

    const filterTheList = (data) => {
        let filter = {}
        if (data?.sender) {
            filter['sender_mobile_no'] = data.sender
            setMobileWallet(data.sender)
        }
        if (data?.borrower?.value) {
            filter['garage_guid'] = data.borrower.value
            setBorrower(data.borrower)
        }
        if ((start && end) && dateRangeChanged) {
            filter['from_date'] = moment(start).format('YYYY-MM-DD')
            filter['to_date'] = moment(end).format('YYYY-MM-DD')
        }
        if (Object.keys(filter).length > 0) {
            setFilterData(filter)
            FETCH_MOBILE_WALLET_PAYMENTS({...defaultFilterParam, ...filter})
            GET_GARAGE_LIST()
            setFilterApplied(true)
        }
    }

    const resetFilter = () => {
        if (filterApplied) {
            // Resetting the mobile wallet list
            setPage(1)
            setFilterData({})
            FETCH_MOBILE_WALLET_PAYMENTS({...defaultFilterParam})
        }
        setFilterApplied(false)
        resetFilterValues()
    }

    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const [previousStart, setPreviousStartDate] = useState(null);
    const [previousEnd, setPreviousEndDate] = useState(null);
    const onDatesChange = ({startDate, endDate}) => {
        setDateRangeChanged(true)
        if (previousEnd && previousEnd.isSame(endDate)) {
            setPreviousStartDate(endDate)
            setPreviousEndDate(endDate)

            setStartDate(endDate)
            setEndDate(endDate)
        } else {
            setPreviousStartDate(startDate)
            setPreviousEndDate(endDate)

            setStartDate(startDate)
            setEndDate(endDate)
        }
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const CustomDataTableContainer = styled.div`
      padding: 1rem 0 0 0;
      min-height: 25rem;
      height: auto;
    `

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start}
                    endDate={end}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    )

    const FilterForm = <>
        <h4><SolTypography.SubTitle >Filter</SolTypography.SubTitle></h4>
        <br />

        <div className={'row'}>
            <div className={'col-md-12'}>
                <Form onSubmit={handleSubmit(filterTheList)}>
                    <div className={'row g-6'}>
                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Mobile Wallet</SolTypography.Text></Form.Label>
                                </div>
                                <FilterInput
                                    name={'sender'}
                                    type="text"
                                    placeholder={'Type Mobile Number'}
                                    ref={register()}
                                />
                                {errors.sender ? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.sender.message}</div></> : null}

                            </Form.Group>
                        </div>

                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Transaction Date</SolTypography.Text></Form.Label>
                                </div>
                                <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                overlay={popover}>
                                    <FilterInput
                                        name={'dateRangePickerInput'}
                                        readOnly={true}
                                        value={dateRange}
                                        placeholder={"Select a date range"}
                                    />
                                </OverlayTrigger>
                            </Form.Group>
                        </div>

                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Borrower</SolTypography.Text></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"borrower"}
                                    rules={{}}
                                    render={({ onChange, onBlur, value, name, ref },
                                             { invalid, isTouched, isDirty }) => (
                                        <FilterReactSelect
                                            name={name}
                                            placeholder={"Select a borrower"}
                                            isDisabled={props.collectingBorrowers}
                                            isLoading={props.collectingBorrowers}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={borrowers}
                                            isSearchable={true}
                                            onChange={(selected, { action }) => {
                                                onChange(selected)
                                                return selected;
                                            }}
                                        />
                                    )}
                                />
                                {errors.borrower? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.borrower.message}</div></>:null}
                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={"col-md-12 no-padding"}>

                            <FilterButton variant="warning" size={'sm'} type="submit"
                                          disabled={props.collectingMobileWalletTransactions}
                                          className={'ml-3'}>
                                Apply Filter
                            </FilterButton>

                            &nbsp;
                            &nbsp;

                            <FilterButton variant="outline-dark" size={'sm'} onClick={() => { resetFilter() }}>
                                Reset Filter
                            </FilterButton>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
        <hr /></>

    const collectWalletDataForExport = () => {
        FETCH_MOBILE_WALLET_PAYMENTS_FOR_EXPORT({...filterData})
        GET_GARAGE_LIST()
    }

    useEffect(() => {
        // This process has one drawback, if the garage list API call fails, data will not be exported.
        // But we have to set a checking based on the "props.garages", otherwise data will be exported for twice
        // - Noor Reza, 8:59 PM, September 3, 2023
        if (walletTransactionsForExport && walletTransactionsForExport.length > -1 && props.garages) {
            let dataForExport = walletTransactionsForExport.map((payment) => {
                let garageName = payment.garage_name
                if (props.garages.length > 0) {
                    let filteredData = garages.filter(garage => garage.pk === payment.garage_guid)
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                    }
                }
                return {
                    ...payment,
                    garageName: garageName
                }
            })
            exportData(dataForExport)
            // Doing this to avoid repeated download as "props.garages" will always check this whenever the garages list API call is made
            setWalletTransactionsForExport(undefined)
        }
    }, [walletTransactionsForExport, props.garages])

    useEffect(() => {
        if (props.mobileWalletTransactionsForExport && props.mobileWalletTransactionsForExport.length > -1) {
            setWalletTransactionsForExport(props.mobileWalletTransactionsForExport)
        }
    }, [props.mobileWalletTransactionsForExport])

    return (
        <ContentWrapper isLoading={false} pageTitle={"Mobile Wallet Transactions"} showBackButton={false} showCardHeader={false}>

            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>Mobile Wallet Transactions</b></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            <IconButton
                                onClick={collectWalletDataForExport}
                                disabled={props.collectingMobileWalletTransactions}>
                                {props.collectingMobileWalletTransactionsForExport? <Spinner animation={"grow"} variant={'warning'}
                                                                         size={"lg"}/>:<img
                                    src={require('../../../../utils/asset/download-Button.svg')} alt="Download"/>}
                            </IconButton>
                            <IconButton onClick={filterToggle}>
                                <FilterListIcon color="disabled" fontSize="large" />
                            </IconButton>
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>

                <hr />
            </div>


            {showFilter ?
                FilterForm : null}
            <div>
                {props.collectingMobileWalletTransactions !== true ? <>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        style={{color: "inherit"}}
                        className={'mr-3'}
                    >
                        <Stack
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            spacing={0}
                        >
                            <span>TOTAL AMOUNT</span>
                            <span><strong>{'TK ' + new Intl.NumberFormat('en-IN').format(totalAmount)}</strong></span>
                        </Stack>
                    </Stack>
                    <CustomDataTableContainer>
                        <DataTable
                            columns={transactionColumn}
                            isLoading={props.collectingMobileWalletTransactions}
                            data={walletTransactions}
                            showToolbar={false}
                            asyncPagination={true}
                            count={props.mobileWalletTransactions?.count}
                            itemsPerPage={props.mobileWalletTransactions?.page_size}
                            onChangePage={onChangePage}
                            page={page}
                        />
                    </CustomDataTableContainer>
                </>:<LoadingSpinner language={language} loadingSubText={"Fetching mobile wallet transactions.."} />}
            </div>

        </ContentWrapper>
    );
};

MobileWalletList.propTypes = {

};

export default connect((state) => {
    return {
        language: state.auth.language,
        isLoading: state.transactionReducer.isLoading,
        collectingMobileWalletTransactions: state.transactionReducer.collectingMobileWalletTransactions,
        mobileWalletTransactions: state.transactionReducer.mobileWalletTransactions,
        mobileWalletTransactionsForExport: state.transactionReducer.mobileWalletTransactionsForExport,
        collectingMobileWalletTransactionsForExport: state.transactionReducer.collectingMobileWalletTransactionsForExport,
        collectingBorrowers: state.commonReducer.collectingBorrowers,
        borrowers: state.commonReducer.borrowers,
        garages: state.commonReducer.garages
    }
}, { ...transactionActions, ...commonActions })(MobileWalletList);
