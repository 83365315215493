import React from "react";
import styled from 'styled-components';


export const SolTypography = styled.div`
  padding: 2rem 0 2rem 0;
`


SolTypography.Heading = styled.h1`
  color: ${props =>props.primary?"#F18D00":"#12100C"};
  display: flex;
  align-items: center;
  font-weight: 600;  
  margin-bottom: 1rem;
  letter-spacing: 0.15px;
`

SolTypography.Title = styled.h3`
  color: ${props =>props.primary?"#F18D00": props.secondary?"#8C8C9B":"#12100C"};
  letter-spacing: 0.15px;
`


SolTypography.SubTitle = styled.p`
  color: ${props =>props.primary?"#F18D00": props.secondary?"#8C8C9B":"#12100C"};
  font-size: 1rem;
  margin-bottom: 0 !important;
  white-space: nowrap;
`


SolTypography.Text = styled.mark`
  color: ${props =>props.primary?"#F18D00": props.secondary?"#8C8C9B":"#12100C"};
  display: contents;
`

export const OnlineStatus = styled.div`
  display: inline-block;
  align-items: center;
  height: 7px;
  width: 7px;
  background-color: ${props =>props.online?"#0B6E4F":"#CB152B"};
  padding: 4px;
  border-radius: 50%;
  box-shadow: ${props =>props.online?"0 0 4px rgba(11, 110, 79, 0.4)":"0px 0px 4px rgba(203, 21, 43, 0.4)"};
`

export const CustomerProfileContainer= styled.div`
  min-height: 15rem;
  height: auto;
`

export const CustomerType= ({children})=> <span style={
    {
        padding: '4px 2px 4px 2px',
        backgroundColor:'#2E798A',
        color: "#fff",
        fontSize: ".95rem",
        borderRadius: "3px",
    }}
>
    <b>{children}</b>
</span>