import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import VerifiedIcon from '@mui/icons-material/Verified';
import PendingIcon from '@mui/icons-material/Pending';
import { theme, useStylesForDatePicker } from "../../../../utils/muiPickerCustomStyle";
import 'moment/locale/bn';
import 'moment/locale/en-gb';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContent from "react-bootstrap/TabContent";
import {Alert} from 'react-bootstrap';
import ErrorIcon from '@mui/icons-material/Error';

import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import { transactionActions as actions } from "../../actions";
import { actions as commonActions } from "../../../commonReduxActions/actions";
import { useSubheader } from "../../../../../_metronic/layout";
import { Button, Form, Spinner } from "react-bootstrap";
import { DataTableContainer, Toolbar, DataTable, LoadingSpinner, SolInput, SolTypography } from "../../../../components";
import Select from "react-select";
import { useForm } from "react-hook-form";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import { csvToArray, isNumber, requestCycle } from "../../../../utils/utilityFunctions";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { ExportToCsv } from "export-to-csv";
import Error from '@mui/icons-material/Error';
import * as XLSX from 'xlsx'
import readXlsxFile from 'read-excel-file'

const key_map = {
    pay_amount: "Paid by Garage",
    platform_fee: "Platform Fee",
    mfg_receivable_amount: "Recievable by MFG",
    solshare_ref: "SOLshare Ref"
}
const UploadPayments = props => {
    const classes = useStylesForDatePicker();
    const subHeader = useSubheader();
    const [language, setLanguage] = useState(null);
    const [uploadedTransactions, setUploadedTransactions] = useState([]);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [processedTransactions, setProcessedTransactions] = useState([]);
    const { errors, handleSubmit, setError, register } = useForm()
    const { SETTLE_PAYMENTS, GET_DEVICE_LIST } = props;

    const wrongFileTypeMessage = language === "EN" ? "Please provide an excel file!" : "দয়া করে একটি এক্সেল ফাইল দিন!"

    const uploadTransactions = (payload) => {
        const file_csv = payload.csv_file[0]
        readXlsxFile(file_csv).then((rows) => {
            setUploadedTransactions(rows.slice(1, rows.length))
        })
        setFileUploaded(true)
    }

    useEffect(() => {
        if (fileUploaded && uploadedTransactions.length > 0) {
           setTransactions(uploadedTransactions.map((transaction) => {
                return {
                    "Driver Name": transaction[8]?.toString() || "",
                    "From Garage": transaction[9]?.toString() || "",
                    "Garage Name": transaction[4]?.toString() || "",
                    "Garage Phone Number": transaction[5]?.toString() || "",
                    "ID": transaction[0]?.toString() || "",
                    "Paid by Garage": transaction[10] || transaction[10] === 0? transaction[10].toString():"",
                    "Payment By": transaction[3]?.toString() || "",
                    "Payment From": transaction[2]?.toString() || "",
                    "Platform Fee": transaction[11] || transaction[11] === 0? transaction[11].toString():"",
                    "MFS Charge": transaction[12] || transaction[12] === 0? transaction[12].toString():"",
                    "Receivable by MFG": transaction[13] || transaction[13] === 0? transaction[13].toString():"",
                    "Rent Ended At": transaction[7]?.toString() || "",
                    "Rent Started At": transaction[6]?.toString() || "",
                    "SOLshare Ref": transaction[14] || transaction[14] === 0? transaction[14].toString():"",
                    "Smart Battery ID": transaction[1] || transaction[1] === 0? transaction[1].toString():""
                }
            }))
        } else if (fileUploaded && uploadedTransactions.length === 0) {
            toast.error("Please upload at least one transaction to settle!")
        }
    }, [uploadedTransactions])

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setActionButtons(null)
        // GET_DEVICE_LIST()
        // return ()=>{
        //     RETURN_TO_INITIAL()
        // }
    }, [])


    useEffect(() => {
        const processed_data = transactions.reduce((filtered,item) => {
            // const garage_name = getGarageName(item)
            const valid_keys = [
                "ID",
                "Smart Battery ID",
                "Payment From",
                "Payment By",
                "Garage Name",
                "Garage Phone Number",
                "Rent Started At",
                "Rent Ended At",
                "Driver Name",
                "From Garage",
                "Paid by Garage",
                "Platform Fee",
                "MFS Charge",
                "Receivable by MFG",
                "SOLshare Ref"
            ]
            if (valid_keys.some((item_key) => {
                return !!item[item_key]

            })) {
                filtered.push({
                    "payment_id": item["ID"].trim() || null,
                    "device_serial_number": item["Smart Battery ID"]?.trim() || null,
                    "payment_from": item["Payment From"]?.trim() || null,
                    "payment_by": item["Payment By"]?.trim() || null,
                    "garage_name": item["Garage Name"]?.trim() || null,
                    "garage_phone_number": item["Garage Phone Number"]?.trim() || null,
                    "start_time": item["Rent Started At"]?.trim() || null,
                    "expire_time": item["Rent Ended At"]?.trim() || null,
                    "driver_name": item["Driver Name"]?.trim() || null,
                    "pay_amount": item["Paid by Garage"]?.trim() || null,
                    "platform_fee": item["Platform Fee"]?.trim() || null,
                    "mfs_charge_amount": item["MFS Charge"]?.trim() || null,
                    "mfg_receivable_amount": item["Receivable by MFG"]?.trim() || null,
                    "solshare_ref": item["SOLshare Ref"]?.trim()?.replace('#', '') || null,
                    "error": checkForError(item)
                })
            }

            return filtered
        }, [])
        setProcessedTransactions(processed_data)
    }, [transactions])

    const checkForError = (item, garage_name) => {
        const valid_keys = [
            "ID",
            // "Smart Battery ID",
            // "From",
            // "Garage Name",
            // "Garage Phone Number",
            // "Rent Started At",
            // "Rent Ended At",
            // "Driver Name",
            // "From Garage",
            "Paid by Garage",
            "Platform Fee",
            "MFS Charge",
            "Receivable by MFG",
            "SOLshare Ref"
        ]
        const revenue_type = ["Gross client collection", "Leasing Fee", "Gross client collection", "Leasing fee as gross client collection", "Net client collection"];
        const payment_mode = ["bKash", "Cash"];
        let msg = ""

        valid_keys.forEach((key) => {
            if (key === "SOLshare Ref" && !item[key]){
                msg = msg.concat(`${key} Required`);
            }

            if (key !== "SOLshare Ref" && !isNumber(item[key])) {

                msg =msg.concat(`${key} Invalid`);
            }
        })
        return msg

    }

    const columns = [
        {
            field: 'payment_id',
            title: "ID",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return <b style={{ color: "red" }}>{"Required"}</b>
            },
            render: (rowData) => {
                if (rowData.error?.includes(key_map["payment_id"])) {
                    return <b style={{ color: "red" }}>Invalid</b>
                }
               
                return rowData.payment_id
            }

        },
        {
            field: 'device_serial_number',
            title: "Smart Battery",
            // headerStyle: {
            //     paddingRight: 0,
            //     textAlign: 'right'
            // },
            // cellStyle: {
            //     textAlign: 'right'
            // },
            emptyValue: () => {
                return <b>-</b>
            },
            // render: (rowdata) => {
            //     return <>
            //         <b><SolTypography.SubTitle className={"mb-5"}>{rowdata.rent_details?.ev_details?.device_sl || "-"}</SolTypography.SubTitle></b>
            //     </>
            // }

        },
        {
            field: 'payment_by',
            title: "From",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            width: '2.5rem',

            emptyValue: () => {
                return <b>-</b>
            },
            render: (rowdata) => {
                return <>
                    <b><SolTypography.SubTitle className={"mb-5"} style={{ whiteSpace: 'nowrap' }}>{rowdata.payment_by || "-"}</SolTypography.SubTitle></b>
                    <SolTypography.SubTitle secondary style={{ whiteSpace: 'nowrap' }}>{rowdata.garage_name || "-"}</SolTypography.SubTitle>
                    <SolTypography.Text secondary style={{ whiteSpace: 'nowrap' }}>{rowdata.garage_phone_number || "-"}</SolTypography.Text>
                </>
            }
        },
        {
            field: 'expire_time',
            title: "Rent Period",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return <b>-</b>
            },
            // render: (rowdata) => {
            //     return <span style={{
            //         whiteSpace: "nowrap"
            //     }}>{rowdata.start_time ? new DateTimeFormat(rowdata.start_time).localDisplayTime : ''} - {rowdata.expire_time ? new DateTimeFormat(rowdata.expire_time).localDisplayTime : ''}</span>
            // }
        },
        {
            field: 'driver_name',
            title: "Driver",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            width: '2.5rem',
            emptyValue: () => {
                return <b>-</b>
            }
        },
        {
            field: 'pay_amount',
            title: "Paid by Garage/Dealer (Tk)",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            // disableClick: true,
            emptyValue: () => {
                return <b style={{ color: "red" }}>{"Required"}</b>
            },
            render: (rowData) => {
                if (!rowData.pay_amount) {
                    return <b style={{ color: "red" }}>Invalid</b>
                }
                return new Intl.NumberFormat('en-IN').format(rowData.pay_amount)
            }
        },
        {
            field: 'mfg_receivable_amount',
            title: "Recievable by Battery MFG (Tk)",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: () => {
                return <b style={{ color: "red" }}>{"Required"}</b>
            },
            render: (rowData) => {
                if (rowData.error?.includes(key_map["mfg_receivable_amount"])) {
                    return <b style={{ color: "red" }}>Invalid</b>
                }
                return new Intl.NumberFormat('en-IN').format(rowData.mfg_receivable_amount)
            }
        },
        {
            field: 'platform_fee',
            title: "Platform Fee (Tk)",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            // disableClick: true,
            emptyValue: () => {
                return <b style={{ color: "red" }}>{"Required"}</b>
            },
            render: (rowData) => {
                if (rowData.error?.includes(key_map["platform_fee"])) {
                    return <b style={{ color: "red" }}>Invalid</b>
                }
                return new Intl.NumberFormat('en-IN').format(rowData.platform_fee)
            }
        },
        {
            field: 'mfs_charge_amount',
            title: "MFS Charge",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            // disableClick: true,
            emptyValue: () => {
                return <b style={{ color: "red" }}>{"Required"}</b>
            },
            render: (rowData) => {
                if (rowData.error?.includes(key_map["mfs_charge_amount"])) {
                    return <b style={{ color: "red" }}>Invalid</b>
                }
                return new Intl.NumberFormat('en-IN').format(rowData.mfs_charge_amount)
            }
        },
        {
            field: 'solshare_ref',
            title: "SOlshare Ref",
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            emptyValue: () => {
                return <b style={{ color: "red" }}>{"Required"}</b>
            },
            render: (rowData) => {
                if (rowData.error?.includes(key_map["solshare_ref"])) {
                    return <b style={{ color: "red" }}>Invalid</b>
                }
                return rowData.solshare_ref
            }
        }
    ]

    const uploadTransaction = () => {
        if (Array.isArray(processedTransactions) && processedTransactions.length > 0) {

            let errorFlag = false;
            processedTransactions.forEach((item) => {
                if (item.error) {
                    console.log(item.error + '\n')
                    errorFlag = true;

                }
            });
            if (errorFlag) {
                
                toast.error("Please resolve the errors !")
                return
            }
            const payload = processedTransactions.map((item)=>{
                return {
                    "payment_id": item.payment_id,
                    "platform_fee": parseFloat(item.platform_fee),
                    "mfs_charge_amount": parseFloat(item.mfs_charge_amount),
                    "mfg_receivable_amount": parseFloat(item.mfg_receivable_amount),
                    "solshare_ref": item.solshare_ref
                }
            })

            SETTLE_PAYMENTS(payload)
        }
    }

    const renderTable = () => {
        return <>
            <div className={"row mb-12 mt-12 no-padding"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>
                                <Button variant={'warning'} onClick={uploadTransaction} disabled={props.buttonLoading}>
                                    {props.buttonLoading === true ? <><Spinner animation={'border'} size={'sm'} variant={'light'} /> </> : null}
                                    {language === "EN" ? "Settle Transactons" : "নিশ্চিত করুন"}
                                </Button>
                            </b></h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
                <div className={"col-md-12"}>
                    <DataTableContainer>
                        <DataTable columns={columns} data={processedTransactions}>

                        </DataTable>
                    </DataTableContainer>
                </div>

            </div>
        </>
    }

    return (
        <ContentWrapper isLoading={false} pageTitle={language === "EN" ? "Upload Settled Transactions" : "একসাথে একাধিক লেনদেন সংরক্ষণ করুন"} showBackButton={false} showCardHeader={true}>
            {/* <div className={'row pl-5'}>
                <p className={'text-mute'}>A blank csv template can be downloaded from <a href={"#"} style={{'color': '#F18D00'}} onClick={() => {downloadCsvTemplate()}}>here</a>.</p>
            </div> */}
            <div className={'row pl-5'}>
                <h5>{language === 'EN' ? 'Upload the transactions Excel file' : 'লেনদেনের এক্সেল ফাইল আপলোড করুন'}</h5>
                <br />
                <br />
                <div className={'col-md-12'}>
                    <Form onSubmit={handleSubmit(uploadTransactions)}>
                        <div className={'row'}>
                            {<div className={'row'}>
                                <div className={"col-md-12"}>
                                    <Form.Group>
                                        <Form.Control
                                            type="file"
                                            name={'csv_file'}
                                            ref={register({
                                                required: language === 'EN' ? 'Please upload a file!' : 'ফাইলটি দিতে হবে!',
                                                validate: {
                                                    isExcel: value => value[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || wrongFileTypeMessage
                                                }
                                            })}
                                        />
                                        {errors.csv_file && <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.csv_file.message}</div>}
                                    </Form.Group>
                                </div>
                            </div>}
                        </div>
                        <div className={'row'}>
                            <Button variant="outline-dark" size={'sm'} onClick={() => { props.history.goBack() }}>
                                {language === 'EN' ? 'Cancel' : 'বাতিল করুন'}
                            </Button>
                            <Button variant="warning" size={'sm'} type="submit"
                                disabled={props.buttonLoading}
                                className={'ml-3'}
                            >
                                {language === 'EN' ? 'Upload' : 'আপলোড'}
                            </Button>
                        </div>
                    </Form>
                </div>
 
            </div>
            {props.errorMessage ? <div className='col-md-12 mt-10 no-padding'>
                <Alert variant={"danger"}>
                    <Error />&nbsp;&nbsp;{props.errorMessage}
                </Alert>

            </div>: null}
            {Array.isArray(processedTransactions) && processedTransactions.length > 0 ? renderTable() : null}
        </ContentWrapper>
    );
};

UploadPayments.propTypes = {

};

export default connect((state) => {
    return {
        language: state.auth.language,
        isLoading: state.transactionReducer.isLoading,
        eventList: state.transactionReducer.events,
        listLoading: state.transactionReducer.listLoading,
        buttonLoading: state.transactionReducer.buttonLoading,
        iconLoading: state.transactionReducer.iconLoading,
        duplicates: state.transactionReducer.duplicates,

        errorMessage: state.transactionReducer.errorMessage,

    }
}, { ...actions, ...commonActions })(UploadPayments);
