export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_SETTLEMENT_LIST_REQUEST: 'COLLECT_SETTLEMENT_LIST_REQUEST',
    COLLECT_SETTLEMENT_LIST_SUCCESS: 'COLLECT_SETTLEMENT_LIST_SUCCESS',
    COLLECT_SETTLEMENT_LIST_FAILURE: 'COLLECT_SETTLEMENT_LIST_FAILURE',

    COLLECT_SETTLEMENT_LIST_FOR_EXPORT_REQUEST: 'COLLECT_SETTLEMENT_LIST_FOR_EXPORT_REQUEST',
    COLLECT_SETTLEMENT_LIST_FOR_EXPORT_SUCCESS: 'COLLECT_SETTLEMENT_LIST_FOR_EXPORT_SUCCESS',
    COLLECT_SETTLEMENT_LIST_FOR_EXPORT_FAILURE: 'COLLECT_SETTLEMENT_LIST_FOR_EXPORT_FAILURE',

    COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT_REQUEST: 'COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT_REQUEST',
    COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT_SUCCESS: 'COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT_SUCCESS',
    COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT_FAILURE: 'COLLECT_INVOICE_SUMMARY_FOR_SETTLEMENT_FAILURE',

    SETTLE_REQUEST: 'SETTLE_REQUEST',
    SETTLE_SUCCESS: 'SETTLE_SUCCESS',
    SETTLE_FAILURE: 'SETTLE_FAILURE',

    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',
};
