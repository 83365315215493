import React, {useEffect, useState} from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import {Toolbar} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {Button, Form, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer} from '../../utils'
import {GenericModal, LoadingSpinner, SolInput} from '../../../../components';
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import Select, {components} from "react-select";
import moment from "moment";
import {toast} from "react-toastify";
import {exportInExcel} from "../../../../utils/excelExport";
import {toBengaliNumber} from "bengali-number";
import {isNullorUndefined} from "../../../../utils/utilityFunctions";
import { parseParams} from "../../../../utils/utilityFunctions";
import {Controller, useForm} from "react-hook-form";
import {contractManagementReducer, financierReducer} from "../../reducer";
import {checkAuthorization} from "../../../auth/authorization";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import { calculateTimeRemaining } from '../../../../utils/dateFormat/DateTimeFormat'
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import DoNotDisturbAltRoundedIcon from "@mui/icons-material/DoNotDisturbAltRounded";
import {useMediaQuery} from "react-responsive";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {showNotifications} from "../../../../utils/notification";
import {SolTextArea} from "../../../../components/SolStyledComponents/components";
import {isValidPhoneNumber} from "libphonenumber-js";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const Financiers = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [language, setLanguage] = useState(null);

    const [financiers, setFinanciers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [orgNameForModal, setOrgNameForModal] = useState('');
    const [showBranchModal, setShowBranchModal] = useState(false);
    const [showOrgEditActionButtons, setShowOrgEditActionButtons] = useState(false);
    const subHeader = useSubheader();

    const userServiceName = getServiceName('userService')
    const financierFeatureName = getFeatureName('financierFeature')
    const createActionName = getActionName('createAction')
    const updateAction = getActionName('updateAction')

    const pageTitle = 'Organization - Financiers'

    const financierListColumns =  [
        {
            field: 'name',
            title: "Name",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'branch_list',
            title: 'Branches',
            emptyValue: ()=>{
                return "0"
            },
            // If we right align (this numerical content), like other tables, it doesn't look good - Noor Reza, 28 Nov, 2023, 8:05 PM
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return (
                    <>
                        <a onClick={() => showBranches(rowData)}>{rowData.branch_list.length}</a>
                    </>
                )
            }
        }
    ]

    const {
        COLLECT_FINANCIERS,
        COLLECT_BRANCHES_OF_A_FINANCIER
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setBreadCrumbComponent(null)
        COLLECT_FINANCIERS()
        setShowOrgEditActionButtons(checkAuthorization(props.authorization, userServiceName, financierFeatureName, [updateAction]))
        if (checkAuthorization(props.authorization, userServiceName, financierFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={goToFinancierCreationPage} classes={classes} className={'ml-1'}>
                        <i className="fa fa-plus"/>Create new financier
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
            props.RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER();
        }
    }, []);

    // ---- Begin: Financier list ---- //
    useEffect(() => {
        if (props.financiers && props.financiers.length > -1) {
            const financiers = props.financiers
            setFinanciers(financiers.map((item) => {
                return {
                    ...item
                }
            }))
        } else {
            setFinanciers(undefined)
        }
    }, [props.financiers])

    useEffect(() => {
        if (props.errorMessageForFinanciers) {
            showNotifications('error', props.errorMessageForFinanciers)
        }
    }, [props.errorMessageForFinanciers])
    // ---- End: Financier list ---- //

    // ----- Begin: Section related to showing branches under an organization ---- //
    const showBranches = (data) => {
        COLLECT_BRANCHES_OF_A_FINANCIER({'organization_guid': data.pk})
        setOrgNameForModal(data.name)
        setShowBranchModal(true)
    }

    useEffect(() => {
        if (props.branches && props.branches.length > -1) {
            const branches = props.branches
            setBranches(branches.map((item) => {
                return {
                    ...item
                }
            }))
        } else {
            setBranches(undefined)
        }
    }, [props.branches])

    useEffect(() => {
        if (props.errorMessageForBranchCollection) {
            showNotifications('error', props.errorMessageForBranchCollection)
        }
    }, [props.errorMessageForBranchCollection])

    const hideBranchModal = () => {
        setShowBranchModal(false)
    }

    const branchListColumns = [
        {
            field: 'name',
            title: "Name",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return (
                    <>
                        <span className={'text-nowrap'}>{rowData.name}</span>
                    </>
                )
            }
        },
        {
            field: 'contact_person_phone',
            title: "Contact",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return (
                    <>
                        {/* If user create branches from the dashboard, following information will be available */}
                        {/* But we may create branches by directly entering info into database.  */}
                        {/* Hence, some extra checkings have been imposed - Noor Reza, 29th Nov, 2 PM  */}

                        {/* If a user creates a financier from dashboard and doesn't edit the information of the Head
                        office, all information may have value '-' (hyphen) - Noor Reza, 5th Dec, 6:29 PM  */}

                        {/* Note that, contact_person_phone will always have value, either '-' (hyphen) or
                        a valid phone number (which is required during branch edit/add) - Noor Reza, 6th Dec, 1:15 AM  */}

                        {rowData.contact_person_name && rowData.contact_person_name !== '-'? <><span className={'text-nowrap'}>{rowData.contact_person_name}</span><br/></>:''}
                        {rowData.contact_person_designation && rowData.contact_person_designation !== '-'? <><span className={'text-nowrap'}>{rowData.contact_person_designation}</span><br/></>:''}
                        <span className={'text-nowrap'}>{rowData.contact_person_phone}</span>
                    </>
                )
            }
        },
        {
            field: 'district',
            title: "Address",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return (
                    <>
                        {/* If user create branches from the dashboard, following information will be available */}
                        {/* But we may create branches by directly entering info into database.  */}
                        {/* Hence, some extra checkings have been imposed - Noor Reza, 29th Nov, 2 PM  */}

                        {/* If a user creates a financier from dashboard and doesn't edit the information of the Head
                        office, address details may have value '-' (hyphen) - Noor Reza, 5th Dec, 6:29 PM  */}

                        {/* Note that, as users have to select division, district and upazila during org registration,
                         there will always be district and upazila - Noor Reza, 6th Dec, 1:15 AM  */}

                        {rowData.address_details && rowData.address_details !== '-'? <><span className={'text-nowrap'}>{rowData.address_details}</span><br/></>:''}
                        {rowData.district && rowData.upazila? <><span className={'text-nowrap'}>{rowData.upazila}, {rowData.district}</span></>:<><span>{rowData.district}</span></>}
                    </>
                )
            }
        }
    ]

    const branchesUnderAFinancier = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideBranchModal}
                modal={showBranchModal}
                title={<h1><strong>Branches of {orgNameForModal}</strong></h1>}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.branchCollectionInProgress && branches? <DataTable
                                language={language}
                                noDataAvailableMessageInEnglish={'No branches available'}
                                columns={branchListColumns}
                                data={branches}
                                showToolbar={false}
                                asyncPagination={false}
                                pagination={true}
                                pageSize={5}
                            /> : <LoadingSpinner loadingSubText={'Collecting branches ..'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>
            </GenericModal>
        </>
    }
    // ----- End: Section related to showing branches under an organization ---- //

    // ----- Begin: Section related to create and edit financier ---- //
    const goToFinancierCreationPage = () => {
        props.history.push({
            pathname: '/financiers/new',
            purpose: 'financier-create'
        })
    }

    const goToFinancierEditPage = (data) => {
        props.history.push({
            pathname: '/financiers/edit',
            financierData: data,
            purpose: 'financier-edit'
        })
    }
    // ----- End: Section related to create and edit financier ---- //

    // ----- Begin: Section related to create branch ---- //
    const goToBranchCreationPage = (data) => {
        props.history.push({
            pathname: '/financiers/new-branch',
            orgData: data
        })
    }
    // ----- End: Section related to create branch ---- //

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={pageTitle}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{pageTitle}</b></h1>
                            </Toolbar.Title>
                        </Toolbar>
                    </div>
                </div>
                <hr/>

                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.collectingFinanciers && financiers? <DataTable
                                language={language}
                                noDataAvailableMessageInEnglish={'No financiers available'}
                                columns={financierListColumns}
                                data={financiers}
                                showToolbar={false}
                                asyncPagination={false}
                                pagination={true}
                                pageSize={10}
                                actionColumnIndex={-1}
                                overrideCustomActions={true}
                                actions={showOrgEditActionButtons? [
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'warning'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                                    Add branch
                                                </Button>
                                            },
                                            onClick: (e, row) => {goToBranchCreationPage(row)},
                                        })
                                    },
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'warning'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                                    Edit
                                                </Button>
                                            },
                                            onClick: (e, row) => {goToFinancierEditPage(row)},
                                        })
                                    }
                                ]:[]}
                                // actions={[
                                //     {
                                //         position: "row",
                                //         action: (rowData) => ({
                                //             icon: () => {
                                //                 return <Button variant={'warning'}
                                //                                size={'sm'}
                                //                                style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                //                     Add branch
                                //                 </Button>
                                //             },
                                //             onClick: (e, row) => {goToBranchCreationPage(row)},
                                //         })
                                //     },
                                //     {
                                //         position: "row",
                                //         action: (rowData) => ({
                                //             icon: () => {
                                //                 return <Button variant={'warning'}
                                //                                size={'sm'}
                                //                                style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                //                     Edit
                                //                 </Button>
                                //             },
                                //             onClick: (e, row) => {goToFinancierEditPage(row)},
                                //         })
                                //     }
                                // ]}
                            /> : <LoadingSpinner loadingSubText={'Collecting financiers ..'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

                {branchesUnderAFinancier()}
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    );
};

Financiers.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        financiers: state.financierReducer.financiers,
        errorMessageForFinanciers: state.financierReducer.errorMessageForFinanciers,
        collectingFinanciers: state.financierReducer.collectingFinanciers,
        branches: state.financierReducer.branches,
        errorMessageForBranchCollection: state.financierReducer.errorMessageForBranchCollection,
        branchCollectionInProgress: state.financierReducer.branchCollectionInProgress
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(Financiers));
