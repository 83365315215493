import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../../../smartDongles/views/components";
import {DataTableContainer} from "../../../rentLog/utils";
import DataTable from "../../../../components/dataTable/DataTable";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Button, Spinner, Badge} from "react-bootstrap";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../../auth/authorization";
import {useSubheader} from "../../../../../_metronic/layout";
import moment from "moment";
import {Form} from "react-bootstrap";
import Select from 'react-select';
import {Controller, useForm} from "react-hook-form";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {makeStyles} from "@material-ui/styles";
import {customStylesForSingleSelectField} from "../../../../utils/reactSelectRelated/customStylesForSingleSelect";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import FwCompatibilities from "./FwCompatibilities"
import {otaReducer} from "../../reducer";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import {showNotifications} from "../../../../utils/notification";
import {exportInExcel} from "../../../../utils/excelExport";


/**
 * NewFirmwareList Component
 *
 * This component renders a list of firmware versions and their details,
 * including firmware versions release time, status, and upload information.
 * It also provides functionalities for creating new firmware, filtering the list,
 * and viewing compatibility details via a modal.
 *
 * Dependencies:
 * - React
 * - react-hook-form (for form handling)
 * - react-redux (for state management)
 * - moment (for date formatting)
 * - react-bootstrap, material-ui (for UI elements)
 *
 * @component
 */
const FirmwareList = props => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [authorization, setAuthorization] = useState(null)
    const [language, setLanguage] = useState(null)
    const useStylesIconButton = makeStyles({
        root: {
            marginTop: '0',
            paddingTop: '4px',
        },
    })
    const classes = useStylesIconButton()
    const location = useLocation()
    const subHeader = useSubheader()

    // Filter related
    const [showFilterForm, setShowFilterForm] = useState(true)
    const [firmwareStatus, setFirmwareStatus] = useState("")
    const firmwareTypes = [
        { value: "firmware", label: "Operation Firmware" },
        { value: "bootloader", label: "Bootloader Firmware" },
        { value: "test_firmware", label: "Test Firmware" },
        { value: "initial_firmware", label: "Initial Firmware" }
    ]
    const [filterParams, setFilterParams] = useState({})

    // Permission related
    const deviceServiceName = getServiceName('deviceService')
    const firmwareFeatureName = getFeatureName('firmwareFeature')
    const createActionName = getActionName('createAction')
    const updateActionName = getActionName('updateAction')
    const assignFwActionName = getActionName('assignFwAction')
    const [userCanAssignFW, setUserCanAssignFW] = useState(false)
    const [userCanUpdateFW, setUserCanUpdateFW] = useState(false)

    // Firmware list related
    const [firmwares, setFirmwares] = useState(undefined)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [loaderText, setLoaderText] = useState("")
    const [showCompatibilityDetailsModal, setShowCompatibilityDetailsModal] = useState(false);
    const [showHwDetailsModal, setShowHwDetailsModal] = useState(false);
    const [hwVersionsForModal, setHwVersionsForModal] = useState([]);
    const [appVersionsForModal, setAppVersionsForModal] = useState("");
    const [showAppVersionsModal, setShowAppVersionsModal] = useState(false);
    const [fwTitleForModal, setFwTitleForModal] = useState('');
    const [initialPageForFwList, setInitialPageForFwList] = useState(0);
    const firmwareListColumns = [
        {
            field: 'version',
            title: 'Version',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.version}</strong></h6>
                </>
            }
        },
        {
            field: 'hardware_version_list',
            title: 'Compatible H.W.',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=> {
                let [hwText, remainingHardwares] = generateTexts(rowData.hardware_version_list)
                return  <>
                    {remainingHardwares? <>{hwText} and <a onClick={() => {showHwDetails(rowData)}}>{remainingHardwares} more</a>
                </>:<>
                    {hwText}
                </>}</>
            }
        },
        {
            field: 'compatible_app_version_list',
            title: 'Compatible App Versions',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                let [appVersionText, remainingAppVersions] = generateTexts(rowData.compatible_app_version_list, false)
                return  <>
                    {remainingAppVersions? <>{appVersionText} and <a onClick={() => {showAppVersions(rowData)}}>{remainingAppVersions} more</a>
                    </>:<>
                        {appVersionText}
                    </>}</>
            }
        },
        {
            field: 'version',
            title: 'Compatible Dongles',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'center'
            },
            headerStyle: {
                textAlign: 'center'
            },
            render: (rowData)=> {
                return  <>
                    <a onClick={() => {showCompatibilityDetails(rowData)}}>View</a>
                </>
            }
        },
        {
            field: 'created_at',
            title: 'Released On',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    {moment(rowData.created_at).format("MMM DD,YYYY hh:mm a")}
                </>
            }
        },
        {
            field: 'created_by_name',
            title: 'Uploaded By',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return <>{rowData.created_by_name}</>
            }
        },
        {
            field: 'status',
            title: 'Status',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                const statusMapping = {
                    "active": { "backgroundColor": "#0b6e4f", "text": "Active" },
                    "inactive": { "backgroundColor": "#8C8C9B", "text": "Inactive" }
                }

                return <>
                    <Badge style={{"backgroundColor":statusMapping[rowData.status]["backgroundColor"],
                        "color":"#ffffff"}}>
                        {statusMapping[rowData.status]["text"]}
                    </Badge>
                </>
            }
        }
    ]
    const assignFwButton = {
        position: "row",
        action: (rowData) => ({
            icon: () => {
                return <>
                    <Button variant={'warning'} size={'sm'} style={{ whiteSpace: 'nowrap' }}
                            disabled={rowData.status === 'inactive'}>
                        <AddIcon fontSize={"small"}/> Assign
                    </Button>
                </>
            },
            onClick: (e, row) => {goToAssignDonglePage(row)},
        })
    }
    const editFwButton = {
        position: "row",
        action: (rowData) => ({
            icon: () => {
                return <Button variant={'warning'}
                               size={'sm'}
                               style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                    <EditIcon fontSize={"small"}/> Edit
                </Button>
            },
            onClick: (e, row) => {goToEditFirmwarePage(row)},
        })
    }

    const {
        GET_FIRMWARE_LIST,
        RETURN_TO_INITIAL
    } = props


    /**
     * Initializes authorization, language, and the firmware list when the component mounts.
     *
     * This effect sets up action buttons for creating new firmware if the user has proper
     * authorization and fetches the initial list of firmwares. It also cleans up by resetting
     * the firmware list when the component unmounts.
     */
    useEffect(()=>{
        setAuthorization(props.authorization)
        setLanguage(props.language)
        subHeader.setBreadCrumbComponent(null)

        if (checkAuthorization(props.authorization, deviceServiceName, firmwareFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant={"warning"} size={"sm"}
                            onClick={goToCreateFirmwarePage}>
                        <i className="fa fa-plus"/>{props.language === 'EN'? 'Create Firmware':'ফার্মওয়্যার তৈরি করুন'}
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        setUserCanAssignFW(checkAuthorization(props.authorization, deviceServiceName, firmwareFeatureName, [assignFwActionName]))
        setUserCanUpdateFW(checkAuthorization(props.authorization, deviceServiceName, firmwareFeatureName, [updateActionName]))

        // Following validation will not pass location.initialPage = 0 but it is not needed to be passed as default "initialPageForFwList" is 0
        if (location.initialPage) {
            // If a page serial number has found, table will be initiated at that page (usually, when users are redirected from FW edit page)
            setInitialPageForFwList(location.initialPage)
        }
        GET_FIRMWARE_LIST()
        return ()=>{
            RETURN_TO_INITIAL()
        }
    },[]) // Empty dependency array to run only once after mount

    // ----- Begin: Button action related ---- //
    /**
     * Redirects the user to the firmware creation page.
     *
     * This function is triggered when the 'Create Firmware' button is clicked,
     * and it redirects the user to the firmware creation form with the 'create' purpose.
     *
     * @function goToCreateFirmwarePage
     * @returns {void}
     */
    const goToCreateFirmwarePage = () => {
        props.history.push({
            pathname: '/ota/firmwares/new',
            purpose: 'create'
        })
    }

    /**
     * Redirects the user to the firmware assignment to Dongle page.
     *
     * This function is triggered when the 'Assign' button is clicked,
     * and it redirects the user to the fw assignment to dongle page with the details data of the firmware.
     *
     * @function goToAssignDonglePage
     * @returns {void}
     */
    const goToAssignDonglePage = (data) => {
        props.history.push({
            pathname: '/ota/firmwares/assign-dongles',
            fwData: data,
            page: Math.floor(data.tableData.id / itemsPerPage) // Calculating the current page and keeping track of it
        })
    }

    /**
     * Redirects the user to the firmware edit page.
     *
     * This function is triggered when the 'Edit' button is clicked,
     * and it redirects the user to the firmware edit form with the 'edit' purpose and the details of the firmware.
     *
     * @function goToEditFirmwarePage
     * @returns {void}
     */
    const goToEditFirmwarePage = (data) => {
        props.history.push({
            pathname: '/ota/firmwares/edit',
            firmwareData: data,
            page: Math.floor(data.tableData.id / itemsPerPage), // Calculating the current page and keeping track of it
            purpose: 'update'
        })
    }
    // ----- End: Button action related ---- //

    // ----- Begin: Firmware list related ---- //
    /**
     * Save firmware list from API response each time a new FW list API call has been made.
     *
     */
    useEffect(()=> {
        if (props.firmwares) {
            setLoaderText("Preparing collected data for representation ...")
            setFirmwares(props.firmwares)
        } else {
            setLoaderText("Collecting firmwares list ...")
            setFirmwares(undefined)
        }
    },[props.firmwares]) // Run each time props.firmwares gets updated or during each FW list collection API call

    /**
     * Show error message related to firmware list API call, if any
     *
     */
    useEffect(() => {
        if (props.errorRelatedToFirmwareCollection) {
            showNotifications('error', props.errorRelatedToFirmwareCollection)
            setFirmwares([])
        }
    }, [props.errorRelatedToFirmwareCollection]) // Run each time props.errorRelatedToFirmwareCollection gets updated, normally during each FW list collection API call

    /**
     * Generates a summarized text display of items, indicating additional items if there are more than two.
     *
     * @param {string[] | string} items - An array of items (e.g., versions) or a comma-separated string of items.
     * @param {boolean} [inputIsAnArray=true] - Indicates if `items` is an array. If `false`, `items` is treated as a comma-separated string.
     * @returns {[string, number]} - A tuple where:
     *   - The first element is a string containing up to the first two items and a summary of additional items if present.
     *   - The second element is the count of remaining items not shown in the summary.
     *
     * @example
     * // Returns ["2.0 and 1.8", 0]
     * generateTexts(["2.0", "1.8"]);
     *
     * @example
     * // Returns ["2.0, 1.9", 3]
     * generateTexts("2.0, 1.9, 1.8, 1.7, 1.6", false);
     *
     * @author Noor Reza
     * @date October 29, 2024
     */
    const generateTexts = (items, inputIsAnArray=true) => {
        // If number of arrayItems (Hardware versions) or a string of comma separated items (app versions) are 2 or less, we are showing them directly
        // Otherwise, we are showing first two items and an additional text "X more"
        // Users can see the whole list by clicking on the text "X more"
        // Example 1: "2.0 and 1.8" [Where items is equal to ["2.0", "3.0"] or "2.0, 3.0"]
        // Example 2: "2.0, 1.9 and 3 more" [Where items is equal to ["2.0", "1.9", "1.8", "1.7", "1.6"] or "2.0, 1.9, 1.8, 1.7, 1.6"]
        // - Noor Reza, 29th October, 2024 3:38 PM
        items = inputIsAnArray? items:items.split(", ")
        let numberOfItems = items.length
        let mainText = ''
        let remainingItems = 0
        if (numberOfItems <= 2) {
            if (numberOfItems === 1) {
                mainText += items[0]
            } else if (numberOfItems === 2) {
                mainText += items[0] + " and " + items[1]
            }
        } else {
            mainText += items.slice(0, 2).join(", ")
            remainingItems = items.length - 2
        }
        return [mainText, remainingItems]
    }

    /**
     * Displays compatibile HW details for the selected firmware.
     *
     * This function sets the title and opens a modal with Hardware
     * compatible with the selected firmware.
     *
     * @function showHwDetails
     * @param {Object} data - The firmware data containing version and hardware compatibility.
     * @returns {void}
     */
    const showHwDetails = (data) => {
        setFwTitleForModal(data.version)
        setHwVersionsForModal(data.hardware_version_list)
        setShowHwDetailsModal(true)
    }

    const hideHwDetailsModal = () => {
        setShowHwDetailsModal(false)
    }

    const renderHwDetailsModal = () => {
        // We are showing hardware versions in unordered list in two columns
        // That's why, we are slicing the HW version list in two halves
        // Example 1: [X, Y, Z] -> [X, Y] and [Z]
        // Example 2: [W, X, Y, Z] -> [W, X] and [Y, Z]
        const middleIndexOfTheHwList = Math.ceil(hwVersionsForModal.length / 2) // Calculate the middle index
        const firstColumnHws = hwVersionsForModal.slice(0, middleIndexOfTheHwList) // First half of the HW versions
        const secondColumnHws = hwVersionsForModal.slice(middleIndexOfTheHwList) // Second half of the HW versions
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideHwDetailsModal}
                modal={showHwDetailsModal}
                title={<h3>Compatible hardwares of FW <strong style={{color: "#F18D00"}}>{fwTitleForModal}</strong></h3>}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <ul>
                            {firstColumnHws.map((hw) => (
                                <li><h6>{hw}</h6></li>
                            ))}
                        </ul>
                    </div>
                    <div className={"col-md-6"}>
                        <ul>
                            {secondColumnHws.map((hw) => (
                                <li><h6>{hw}</h6></li>
                            ))}
                        </ul>
                    </div>
                </div>
                <hr/>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <Button variant={"outline-dark"} size={"sm"} onClick={hideHwDetailsModal}
                                className={'float-right'}>
                            Close
                        </Button>
                    </div>
                </div>
            </GenericModal>
        </>
    }

    /**
     * Displays compatibile app versions for the selected firmware.
     *
     * This function sets the title and opens a modal with App Version
     * compatible with the selected firmware.
     *
     * @function showAppVersions
     * @param {Object} data - The firmware data containing version and app versions.
     * @returns {void}
     */
    const showAppVersions = (data) => {
        setFwTitleForModal(data.version)
        setAppVersionsForModal(data.compatible_app_version_list)
        setShowAppVersionsModal(true)
    }

    const hideAppVersionsModal = () => {
        setShowAppVersionsModal(false)
    }

    const renderAppVersionsModal = () => {
        // We are showing app versions in unordered list in two columns
        // That's why, we are slicing the App version list in two halves
        // Example 1: [X, Y, Z] -> [X, Y] and [Z]
        // Example 2: [W, X, Y, Z] -> [W, X] and [Y, Z]
        const arrayOfAppVersions = appVersionsForModal.split(",")
        const middleIndexOfTheAppVersionsList = Math.ceil(arrayOfAppVersions.length / 2) // Calculate the middle index
        const firstColumnAppVersions = arrayOfAppVersions.slice(0, middleIndexOfTheAppVersionsList) // First half of the HW versions
        const secondColumnAppVersions = arrayOfAppVersions.slice(middleIndexOfTheAppVersionsList) // Second half of the HW versions
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideAppVersionsModal}
                modal={showAppVersionsModal}
                title={<h3>Compatible app versions of FW <strong style={{color: "#F18D00"}}>{fwTitleForModal}</strong></h3>}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <ul>
                            {firstColumnAppVersions.map((appVersion) => (
                                <li><h6>{appVersion}</h6></li>
                            ))}
                        </ul>
                    </div>
                    <div className={"col-md-6"}>
                        <ul>
                            {secondColumnAppVersions.map((appVersion) => (
                                <li><h6>{appVersion}</h6></li>
                            ))}
                        </ul>
                    </div>
                </div>
                <hr/>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <Button variant={"outline-dark"} size={"sm"} onClick={hideAppVersionsModal}
                                className={'float-right'}>
                            Close
                        </Button>
                    </div>
                </div>
            </GenericModal>
        </>
    }

    /**
     * Displays compatibility details for the selected firmware.
     *
     * This function sets the title and opens a modal with SOldongles
     * compatible with the selected firmware.
     *
     * @function showCompatibilityDetails
     * @param {Object} data - The firmware data containing version and SOLdongle (hardware) compatibility.
     * @returns {void}
     */
    const showCompatibilityDetails = (data) => {
        setFwTitleForModal(data.version)
        setShowCompatibilityDetailsModal(true)
    }

    const hideCompatibilityDetailsModal = () => {
        setShowCompatibilityDetailsModal(false)
    }

    const renderCompatibilityDetailsModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideCompatibilityDetailsModal}
                modal={showCompatibilityDetailsModal}
                title={<h3>Compatible dongles for FW <strong style={{color: "#F18D00"}}>{fwTitleForModal}</strong></h3>}>
                <FwCompatibilities fwVersion={fwTitleForModal}/>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <Button variant={"outline-dark"} size={"sm"} onClick={hideCompatibilityDetailsModal}
                                className={'float-right'}>
                            Close
                        </Button>
                    </div>
                </div>
            </GenericModal>
        </>
    }
    // ----- End: Firmware list related ---- //

    // ----- Begin: Data export related ---- //
    const exportData = () => {
        let presentTime = moment()
        let exportText = "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A")
        let excelRows = [["Firmwares"], [exportText], [], [],
            ["Version", "Compatible H.W", "Compatible App Versions", "Released On", "Uploaded By", "Status"]
        ]
        const statusMapping = {
            "active": { "text": "Active" },
            "inactive": { "text": "Inactive" }
        }
        if (Object.keys(filterParams).length > 0) {
            if (filterParams.status) {
                let text = "Status: " + statusMapping[filterParams.status]["text"]
                excelRows.splice(1, 0, [text])
            }
            if (filterParams.firmware_type) {
                let text = "Type: " + firmwareTypes.filter(item => item.value === filterParams.firmware_type)[0].label
                excelRows.splice(1, 0, [text])
            }
        }
        for (let i=0; i< firmwares.length; i++) {
            excelRows.push(
                [
                    firmwares[i].version,
                    firmwares[i].hardware_version_list? firmwares[i].hardware_version_list.join(", "):"",
                    firmwares[i].compatible_app_version_list,
                    moment(firmwares[i].created_at).format("MMM DD,YYYY hh:mm a"),
                    firmwares[i].created_by_name,
                    statusMapping[firmwares[i].status]["text"]
                ])
        }
        let noDataAvailableText =  "No firmwares available"
        firmwares.length > 0? excelRows.push([]):excelRows.push(["", "", noDataAvailableText, "", "", ""])
        let fileName = 'firmwares_'
        exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
    }
    // ----- End: Data export related ---- //


    // ----- Begin: Filtering process related ---- //
    const filterToggle = () => {
        setShowFilterForm(!showFilterForm)
    }

    const handleFirmwareStatusSelectionChange = (event) => {
        setFirmwareStatus(event.target.value)
    }

    const filterForm = () => {
        return <>
            <Form onSubmit={handleSubmit(filterTheList)}>
                <div className={"row g-3"}>
                    <div className={"col-md-3"}>
                        <Form.Group>
                            <div>
                                <Form.Label>Firmware Type</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"firmware_type"}
                                rules={{}}
                                render={({ onChange, onBlur, value, name, ref },
                                         { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select Type'}
                                        classNamePrefix="react-select-sol-style"
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={firmwareTypes}
                                        isSearchable={true}
                                        styles={customStylesForSingleSelectField}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </div>
                    <div className={"col-md-3"}>
                        <Form.Group>
                            <div>
                                <Form.Label>Firmware Status</Form.Label>
                            </div>
                            <div className="form-check form-check-inline mt-2">
                                <SolRadioInput
                                    {...register("firmware_status")}
                                    id="active"
                                    value={"active"}
                                    handleChange={(event) => handleFirmwareStatusSelectionChange(event)}
                                    checked={firmwareStatus === "active"}
                                    htmlFor="active"
                                    labelName="Active"
                                />
                            </div>
                            <div className="form-check form-check-inline mt-2">
                                <SolRadioInput
                                    {...register("firmware_status")}
                                    id="inactive"
                                    value={"inactive"}
                                    handleChange={(event) => handleFirmwareStatusSelectionChange(event)}
                                    checked={firmwareStatus === "inactive"}
                                    htmlFor="inactive"
                                    labelName="Inactive"
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-12"}>
                        <Button variant="warning" size={'sm'} type="submit"
                                disabled={props.listLoading}>
                            Apply filter
                        </Button>
                        <Button variant="outline-dark" size={'sm'} disabled={props.listLoading}
                                onClick={resetFilter} className={'ml-3'}>
                            Reset filter
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    }

    const resetFilter = () => {
        setValue('firmware_type', '')
        setFirmwareStatus("")
        if (Object.keys(filterParams).length > 0) {
            setFilterParams({})
            // Resetting the firmware list
            GET_FIRMWARE_LIST()
        }
    }

    const filterTheList = (data) => {
        let filter = {}
        if (data.firmware_type) {
            filter['firmware_type'] = data.firmware_type.value
        }
        if (firmwareStatus) {
            filter['status'] = firmwareStatus
        }
        setFilterParams(filter)
        GET_FIRMWARE_LIST(filter)
    }
    // ----- End: Filtering process related ---- //

    return (
        <ContentWrapper isLoading={false} pageTitle={"Firmware List"} showBackButton={false} showCardHeader={false}>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><strong>Firmwares</strong></h1>
                        </Toolbar.Title>
                        <Toolbar.ToolbarContainer>
                            <Toolbar.ToolbarContainer.Items>
                                <IconButton onClick={filterToggle} classes={classes}>
                                    <FilterListIcon color="disabled" fontSize="large"/>
                                </IconButton>
                                <IconButton classes={classes} onClick={exportData}
                                            disabled={false}>
                                        <img src={require('../../../../utils/asset/download-Button.svg')} alt="Download"/>
                                </IconButton>
                            </Toolbar.ToolbarContainer.Items>
                        </Toolbar.ToolbarContainer>
                    </Toolbar>
                    <hr/>
                </div>
            </div>
            {/* Filter form */}
            {showFilterForm? <>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        {filterForm()}
                        <hr/>
                    </div>
                </div>
            </>:''}
            {/* Table */}
            <div className={"row"}>
                <div className={"col-md-12"}>
                    {!props.listLoading && firmwares? <>
                        <DataTableContainer>
                            <DataTable
                                language={language}
                                noDataAvailableMessageInEnglish={'No firmwares available'}
                                columns={firmwareListColumns}
                                data={firmwares}
                                showToolbar={false}
                                asyncPagination={false}
                                pagination={true}
                                pageSize={itemsPerPage}
                                initialPage={initialPageForFwList}
                                actionColumnIndex={-1}
                                overrideCustomActions={true}
                                actions={["firmware", "bootloader"].includes(filterParams.firmware_type) || !filterParams.firmware_type?
                                    userCanAssignFW && userCanUpdateFW? [assignFwButton, editFwButton]:
                                        userCanAssignFW && !userCanUpdateFW? [assignFwButton]:
                                            !userCanAssignFW && userCanUpdateFW? [editFwButton]:[]:
                                    userCanUpdateFW? [editFwButton]:[]}
                            />
                        </DataTableContainer>
                    </>:<>
                        <Typography variant="h5">
                            <Skeleton className={"d-flex justify-content-center align-items-center"}
                                      variant="rect" sx={{ marginTop: '30px', color: '#F18D00' }} width={'100%'}
                                      height={528}>
                                {loaderText}
                            </Skeleton>
                        </Typography>
                    </>}
                </div>
            </div>
            {renderHwDetailsModal()}
            {renderAppVersionsModal()}
            {renderCompatibilityDetailsModal()}
        </ContentWrapper>
    );
};

FirmwareList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        listLoading: state.otaReducer.listLoading,
        firmwares: state.otaReducer.firmwares,
        errorRelatedToFirmwareCollection: state.otaReducer.errorRelatedToFirmwareCollection
    }
}

export default connect(mapStateToProps, actions)(FirmwareList);
