import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
    evList: []
};

/*Reducer for Ev's redux store*/
export const evsReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_EV_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                evList: [],
            };
        case actionTypes.GET_EV_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                evList: action.payload
            };
        case actionTypes.GET_EV_LIST_FAILURE:
            toast.error("Can't load the ev list! Please contact with admin.")
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState;

        default:
            return state;
    }
};
