import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {actions} from "../../../../actions";
import {actions as commonActions} from "../../../../../commonReduxActions/actions";
import {withRoles} from "../../../../../../router/SecuredRoute";
import {useMediaQuery} from "react-responsive";
import moment from "moment";
import {LoadingSpinner, SolTypography} from "../../../../../../components";
import {searchResultReducer} from "../../../../reducer";
import {ReactSmartScroller} from "react-smart-scroller";
import Stack from '@mui/material/Stack';
import parse from 'html-react-parser';
import {showNotifications} from "../../../../../../utils/notification";


const EventHistory = ({scrollerHeight, ...props}) => {
    const [garages, setGarages] = useState(undefined);
    const [language, setLanguage] = useState(null);
    const [events, setEvents] = useState(undefined);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const {
        COLLECT_DRIVER_DETAILS
    } = props;

    useEffect(() => {
        setLanguage(props.language)
    }, []);

    useEffect(() => {
        if (props.events) {
            setEvents(props.events)
        } else {
            setEvents(undefined)
        }
    }, [props.events])

    useEffect(() => {
        if (props.errorWhileCollectingEvents) {
            showNotifications('error', props.errorWhileCollectingEvents)
        }
    }, [props.errorWhileCollectingEvents])

    return (
        <>
            <div>
                <div className={isTabletOrMobile? 'row mt-4':'row'} style={{marginLeft: '0.1px'}}><h5><strong>Events History</strong></h5></div>
                {props.collectingEventHistory || !events? <>
                    <div className={isTabletOrMobile? 'row mt-4':'row'} style={{display: 'flex', width: '100%', justifyContent: 'center', marginLeft: '0.1px'}}>
                        <LoadingSpinner
                            loadingSubText={'Collecting event histories...'}
                            language={'EN'} size={'sm'}/>
                    </div>
                </>:<>
                    {events && events.length > 0? <>
                        <ReactSmartScroller
                            vertical
                            draggable={true}
                            style={isTabletOrMobile? {height: '153px'}:{height: scrollerHeight + 'px'}}
                            thumb={<div style={{width: 10, height: 100, backgroundColor: '#F18D00'}}/>}
                        >
                            {events.map((event, index) => {
                                // React Smart Scroller needs unique keys on (parent) divs within it to enable scrolling
                                // Hence, we need to generate four unique keys used in four divs for each event
                                // index 0: (0 * 4) + 1 = 1, Keys: 1, 2, 3, 4
                                // index 1: (1 * 4) + 1 = 5, Keys: 5, 6, 7, 8
                                // index 2: (2 * 4) + 1 = 9, Keys: 9, 10, 11, 12
                                // index 3: (3 * 4) + 1 = 13, Keys: 13, 14, 15, 16
                                // index 4: (4 * 4) + 1 = 17, Keys: 17, 18, 19, 20
                                // - Noor Reza 1st February, 2024 6:04 pm
                                let initialKey = (index * 4) + 1
                                return (
                                    <>
                                        <div key={initialKey} className={index === 1? 'd-flex justify-content-between mt-2':'d-flex justify-content-between mt-3'} style={event.status === 'failed'? {backgroundColor: '#FDEDED', marginLeft: '0.1px'}:{marginLeft: '0.1px'}}>
                                            <span><strong>{moment(event.created_at).format("MMM DD, YYYY hh:mm A")}</strong></span>
                                            <span><strong>{event.event_category_info? event.event_category_info.name.toUpperCase():'-'}</strong></span>
                                        </div>
                                        <div key={initialKey + 1} className={'d-flex justify-content-between'} style={event.status === 'failed'? {backgroundColor: '#FDEDED', marginLeft: '0.1px'}:{marginLeft: '0.1px'}}>
                                            <span><small><strong>{event.created_by_name}</strong></small></span>
                                            <span><small><strong>{event.status === 'in_progress'? 'In progress':event.status === 'success' || event.status === 'active'? 'Successful':'Failed'}</strong></small></span>
                                        </div>
                                        <div key={initialKey + 2} className={'d-flex justify-content-start'} style={event.status === 'failed'? {backgroundColor: '#FDEDED', marginLeft: '0.1px'}:{marginLeft: '0.1px'}}>
                                            <span><small><strong>Contract ID: {event.ev_info?.contract_id? event.ev_info.contract_id:'-'}</strong></small></span>
                                        </div>
                                        <div key={initialKey + 3} className={'d-flex justify-content-start'} style={event.status === 'failed'? {backgroundColor: '#FDEDED', marginLeft: '0.1px'}:{marginLeft: '0.1px'}}>
                                            <span>{parse(event.event_description)}</span>
                                        </div>
                                    </>
                                )
                            })}
                        </ReactSmartScroller>
                    </>:<>
                        <div className={isTabletOrMobile? 'row mt-4':'row'} style={{marginLeft: '0.1px'}}>No events available</div>
                    </>}
                </>}
            </div>
        </>
    );
};

EventHistory.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        events: state.commonReducer.events,
        collectingEventHistory: state.commonReducer.collectingEventHistory,
        errorWhileCollectingEvents: state.commonReducer.errorWhileCollectingEvents
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(EventHistory));
