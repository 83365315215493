import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import {useForm} from 'react-hook-form';
import { Form, Button, Spinner } from 'react-bootstrap';
import { SolInput, DataTable, LoadingSpinner } from '../../../../components';
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import Stack from '@mui/material/Stack';
import {toast} from "react-toastify";
import moment from "moment";

const UpdateDueLimitOfAGarage = ({ from, data, updateDueLimitRepresentation, ...props }) => {
    const { control, register, setError, errors, setValue, handleSubmit } = useForm();
    const [language, setLanguage] = useState("EN");
    const [garageGuid, setGarageGuid] = useState('');
    const [dueLimitChangeHistory, setDueLimitChangeHistory] = useState([]);
    const [dueLimit, setDueLimit] = useState('');
    const invalidValue = language === 'EN'? 'Invalid value!':'ভুল মান!'

    const {
        UPDATE_DUE_LIMIT,
        DUE_LIMIT_CHANGE_HISTORY
    } = props;

    useEffect(()=>{
        setLanguage(props.language)
        if (from === 'transactionModal') {
            setGarageGuid(data.pk)
            setValue('due_limit', data.total_due_limit? data.total_due_limit:0)
            DUE_LIMIT_CHANGE_HISTORY(data.pk)
        } else if (from === 'activitiesModal' || from === 'directDueLimitEdit') {
            setGarageGuid(data[0].pk)
            setValue('due_limit', data[0].total_due_limit? data[0].total_due_limit:0)
            DUE_LIMIT_CHANGE_HISTORY(data[0].pk)
        }
    }, [])

    const CHANGE_HISTORY_COLUMNS = [
        {
            field: 'sk',
            title: language === 'EN' ? 'Date' : 'তারিখ',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: 'nowrap'}}>{moment(rowData.sk).format("MMM DD, YYYY hh:mm a")}</span>
                </>
            }
        },
        {
            field: 'previous_due_limit',
            title: language === 'EN' ? 'Previous Amount (Tk)' : 'আগের পরিমাণ (টাকায়)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return <>
                    <h4>{new Intl.NumberFormat('en-IN').format(rowData.previous_due_limit)}</h4>
                </>
            }
        },
        {
            field: 'changed_to',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            title: language === 'EN' ? 'Changed to (Tk)' : 'পরিবর্তিত পরিমাণ (টাকায়)',
            render: (rowData) => {
                return <>
                    <h4>{new Intl.NumberFormat('en-IN').format(rowData.current_due_limit)}</h4>
                </>
            },
        },
        {
            field: 'changed_by',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            title: language === 'EN' ? 'Changed by' : 'পরিবর্তন করেছেন',
            render: (rowData) => {
                return <>
                    <span>{rowData.changed_by_name}</span>
                </>
            },
        }
    ]

    useEffect(()=>{
        if (props.changeLogs) {
            const changeLogs = props.changeLogs
            if (changeLogs.length > -1) {
                setDueLimitChangeHistory(changeLogs)
            }
        }
    }, [props.changeLogs])

    const updateDueLimit = (data) => {
        setDueLimit(data.due_limit)
        let payload = {
            'total_due_limit': parseInt(data.due_limit),
            'garage_guid': garageGuid
        }
        UPDATE_DUE_LIMIT(payload)
    }

    useEffect(()=>{
        if (props.garageDueLimitUpdated) {
            toast.success("Due limit has been updated successfully!")
            updateDueLimitRepresentation(dueLimit)
            DUE_LIMIT_CHANGE_HISTORY(garageGuid)
        }
    }, [props.garageDueLimitUpdated])

    return (
        <div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <Stack direction={"column"} color={{color: 'inherit'}}>
                        {from === 'transactionModal'? <>
                            <span>{data.garage_owner_name}</span>
                            <span>{data.name}</span>
                            {data.upazila && data.district? <span>{data.upazila}, {data.district}</span>:data.district? <span>{data.district}</span>:null}
                        </>:<>
                            {from === 'activitiesModal' || from === 'directDueLimitEdit'? <>
                                <span>{data[0].garage_owner_name}</span>
                                <span>{data[0].name}</span>
                                {data[0].upazila && data[0].district? <span>{data[0].upazila}, {data[0].district}</span>:data[0].district? <span>{data[0].district}</span>:null}
                            </>:null}
                        </>}
                    </Stack>
                </div>
            </div>
            <div className={'row mt-5'}>
                <div className={'col-md-12'}>
                    <Form onSubmit={handleSubmit(updateDueLimit)}>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                        >
                            <span style={{color: 'inherit'}}>Due Limit (Tk)</span>
                            <Stack direction={'row'}
                                   justifyContent={'flex-start'}
                                   alignItems={'flex-start'} spacing={2}>
                                <Form.Group>
                                    <SolInput
                                        type={"text"}
                                        name={"due_limit"}
                                        placeholder={"Provide due limit..."}
                                        autoComplete={"off"}
                                        ref={register({
                                            validate: {
                                                isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || invalidValue
                                            },
                                            required: "Please provide due limit!",
                                            max: 1000000
                                        })}
                                    />
                                    {errors.due_limit && errors.due_limit.type === "max" && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;Max due limit 10,00,000 exceeded!</div>}
                                    {errors.due_limit && errors.due_limit.type !== "max" && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.due_limit.message}</div>}
                                </Form.Group>
                                <Button variant="warning" size={'md'} type="submit"
                                        disabled={props.garageDueLimitIsBeingUpdated}>
                                    {props.garageDueLimitIsBeingUpdated===true? <><Spinner animation={'border'} size={'sm'} variant={'light'}/> </>:null}
                                    Change
                                </Button>
                            </Stack>
                        </Stack>
                    </Form>
                </div>
            </div>
            <div className={'row mt-10'}>
                <div className={'col-md-12'}>
                    {!props.collectingChangeLogs? <>
                        {dueLimitChangeHistory.length > 0? <>
                            <Stack direction={'column'}>
                                <span color={{color: 'inherit'}}><strong>Change History</strong></span>
                                <DataTable
                                    language={language}
                                    columns={CHANGE_HISTORY_COLUMNS}
                                    data={dueLimitChangeHistory}
                                    showToolbar={false}
                                    asyncPagination={false}
                                    isLoading={props.tableLoading}
                                    pagination={true}
                                    pageSize={3}
                                />
                            </Stack>
                        </>:<span color={{color: 'inherit'}}><strong>No change history available</strong></span>}
                    </> : <LoadingSpinner
                        loadingSubText={language === 'EN' ? 'Collecting change history ..' : 'লিমিট বদলানোর লগ প্রস্তুত হচ্ছে ..'}
                        language={language} />}
                </div>
            </div>
        </div>
    );
};

UpdateDueLimitOfAGarage.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        garageDueLimitUpdated: state.borrowersReducer.garageDueLimitUpdated,
        garageDueLimitIsBeingUpdated: state.borrowersReducer.garageDueLimitIsBeingUpdated,
        changeLogs: state.borrowersReducer.changeLogs,
        collectingChangeLogs: state.borrowersReducer.collectingChangeLogs
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(UpdateDueLimitOfAGarage));