const serviceNames = {
    driverService : 'driver_service',
    deviceService: 'device_service',
    tokenService: 'token_service',
    batteryService: 'battery_service',
    transactionService: 'transaction_service',
    garageService: 'garage_service',
    userService: 'user_service'
};

export const getServiceName = (service) => {
    return serviceNames.hasOwnProperty(service)? serviceNames[service]: undefined;
};
